import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { CategoryCard } from '../../modules/cs/main/components/category-card';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Countdown from 'react-countdown';
import { AshTrace } from '../../modules/ash/components/ash-trace';
import { AshCharacter } from '../../modules/ash/components/ash-character';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';

const AshIndexPage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderer1 = ({ days, hours, minutes, completed }) => {
    if (completed) {
      return <span className="time">Event ended</span>;
    } else {
      return (
        <span className="time">
          {days >= 1 ? (
            <>
              {days}d {hours}h
            </>
          ) : (
            <>
              {hours}h {minutes}m
            </>
          )}
        </span>
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderer2 = ({ days, hours, minutes, completed }) => {
    if (completed) {
      return <span className="time">Event started</span>;
    } else {
      return (
        <span className="time">
          {days >= 1 ? (
            <>
              {days}d {hours}h
            </>
          ) : (
            <>
              {hours}h {minutes}m
            </>
          )}
        </span>
      );
    }
  };
  return (
    <DashboardLayout className={'home-page ash '} game="ash">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Ash Echoes wiki and tier list</h1>
        <h2>
          Prydwen.gg is a wiki for Ash Echoes Global - a gacha game published by{' '}
          <OutboundLink href="https://ae.neocraftstudio.com/en" target="_blank">
            Neocraft
          </OutboundLink>
          . Check our guides, tier lists and reviews for characters available in
          the game.
        </h2>
        <p>For the official Ash Echoes Global website go here:</p>
        <div className="centered-links">
          <div>
            <OutboundLink
              href="https://ae.neocraftstudio.com/en"
              target="_blank"
            >
              <StaticImage
                src="../../images/ash/neocraft_logo.webp"
                alt="A-Level"
                className="partner-logo"
              />
            </OutboundLink>
          </div>
          <div>
            <OutboundLink
              href="https://ae.neocraftstudio.com/download"
              target="_blank"
            >
              <Button variant="white">
                <FontAwesomeIcon icon={faDownload} width="18" /> Download Ash
                Echoes
              </Button>
            </OutboundLink>
          </div>
        </div>
        <p className="with-margin-top">
          <strong>The links above don't work for you?</strong> If you can't
          access the links posted above, you can directly download Ash Echoes
          for your mobile phone{' '}
          <a
            href="https://accounts.neocraftstudio.com/download?op_id=2117&game_id=199"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          (via APK) and the official PC client from{' '}
          <a
            href="https://static.neocraftstudio.com/static/download/AE/seed_setup_us_pro_1.5.7.exe"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Important Content" />
      <div className="promoted-content">
        <div className="banner-promo boreas">
          <h4>Should you pull Boreas?</h4>
          <Link to="/ash-echoes/guides/should-you-pull-boreas">
            <Button variant="white">Read more</Button>
          </Link>
        </div>
        <div className="banner-promo feng">
          <h4>Should you pull Feng Qingxue?</h4>
          <Link to="/ash-echoes/guides/should-you-pull-feng-qingxue">
            <Button variant="white">Read more</Button>
          </Link>
        </div>
        <div className="banner-promo event">
          <h4>Joint Training: Shriek Party</h4>
          <Link to="/ash-echoes/guides/joint-training-shriek-party">
            <Button variant="white">Read more</Button>
          </Link>
        </div>
      </div>
      <SectionHeader title={'Event Timeline'} />
      <Row xs={1} xl={2}>
        <Col>
          <div className="tracker-header">
            <h5>Current</h5>
          </div>
          <Accordion className="event-tracker">
            <Accordion.Item className="banner-11" eventKey="1023">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">
                  Into The Fantasy (Memory Banner)
                </div>
                <div className="countdown">
                  <Countdown date={1737622800000} renderer={renderer1} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: December 26, 2024, 04:00 –
                  January 23, 2025, 03:59 (UTC-5)
                </p>
                <p className="featured">
                  Featured <span className="ash-rar rarity-SSR">SSR</span>{' '}
                  Memory Trace:
                </p>
                <div className="ash-trace-box featured-item">
                  <AshTrace name="Into the Fantasy" mode="data" />
                </div>
                {/* <p className="featured">
                  Featured <span className="ash-rar rarity-SR">SR</span> Memory
                  Traces:
                </p>
                <div className="ash-trace-box featured-item">
                  <AshTrace name="Reunion" mode="data" />
                </div>
                <div className="ash-trace-box featured-item">
                  <AshTrace name="Lawful Cage" mode="data" />
                </div> */}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="event-10" eventKey="1031">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Non-Voluntary Survivor</div>
                <div className="countdown">
                  <Countdown date={1737622800000} renderer={renderer1} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: December 26, 2024, 04:00 –
                  January 23, 2025, 03:59 (UTC-5)
                </p>
                <p className="description">
                  <span>Event description</span>: The chaos and battle fire
                  experienced in the Southern Hemisphere all seem so far away
                  now that we're back in Hailin. But life hasn't returned to its
                  previous calm state. Su Xiao and I went to visit the
                  hospitalized Bai Ji, where we unexpectedly ran into Boreas.
                  However, the strange frequency signal later discovered by
                  S.E.E.D. turned out to be intricately linked to Boreas...
                </p>
                <p className="description">
                  <span>Rewards</span>: [SSR - Memory Traces: Silent Yesterday],
                  O.E. Coins, X Prticle, etc. and more
                </p>
                <p className="featured">
                  Featured <span className="ash-rar rarity-SSR">SSR</span>{' '}
                  Memory Trace reward:
                </p>
                <div className="ash-trace-box featured-item">
                  <AshTrace name="Silent Yesterday" mode="data" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="banner-2" eventKey="1014">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">
                  Truth Under The Night Sky (Banner)
                </div>
                <div className="countdown">
                  <Countdown date={1737622800000} renderer={renderer1} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: January 9, 2024, 04:00 – January
                  23, 2025, 03:59 (UTC-5)
                </p>
                <p className="featured">
                  Featured <span className="ash-rar rarity-6">6★</span>{' '}
                  character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <AshCharacter
                      slug="boreas"
                      mode="card"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="ash-rar rarity-5">5★</span>{' '}
                  characters:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <AshCharacter
                      slug="shu"
                      mode="card"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <AshCharacter
                      slug="aceo"
                      mode="card"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <AshCharacter
                      slug="lydia"
                      mode="card"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="project-2" eventKey="1030">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Project Vein (Battle Pass)</div>
                <div className="countdown">
                  <Countdown date={1737968400000} renderer={renderer1} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: December 23, 2024, 04:00 -
                  January 27, 2025, 3:59 (UTC-5).
                </p>
                <p className="description">
                  <span>Event description</span>: During this event, Directors
                  can level up Project Vein Lv. by completing tasks in PROJECT
                  VEIN to gain abundant rewards. Unlock [Advance Plan] to level
                  up your Project Vein Lv. by 10, get the limited SR Memory
                  Trace [Lucky Find], Tea Time Universal Invitations, and
                  advanced supplies!
                </p>
                <p className="featured">
                  Featured <span className="ash-rar rarity-SR">SR</span> Memory
                  Trace reward:
                </p>
                <div className="ash-trace-box featured-item">
                  <AshTrace name="Lucky Find" mode="data" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col>
          <div className="tracker-header">
            <h5>Upcoming</h5>
          </div>
          <Accordion className="event-tracker">
            <p>Coming soon!</p>
          </Accordion>
        </Col>
      </Row>
      <SectionHeader title="Guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/ash-echoes/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/ash/categories/category_intro.webp"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Beginner Guide"
          link="/ash-echoes/guides/beginner-guide"
          image={
            <StaticImage
              src="../../images/ash/categories/category_beginner.webp"
              alt="Beginner Guide"
            />
          }
        />
        <CategoryCard
          title="Reroll Guide"
          link="/ash-echoes/guides/reroll"
          image={
            <StaticImage
              src="../../images/ash/categories/category_reroll.webp"
              alt="Reroll Guide"
            />
          }
        />
        <CategoryCard
          title="Elements & Reactions"
          link="/ash-echoes/guides/elements-and-reactions"
          image={
            <StaticImage
              src="../../images/ash/categories/category_elements.webp"
              alt="Elements & Reactions"
            />
          }
        />
        <CategoryCard
          title="Team Building"
          link="/ash-echoes/guides/team-building"
          image={
            <StaticImage
              src="../../images/ash/categories/category_teams.webp"
              alt="Team Building"
            />
          }
        />
        <CategoryCard
          title="Characters"
          link="/ash-echoes/characters"
          image={
            <StaticImage
              src="../../images/ash/categories/category_characters.webp"
              alt="Characters"
            />
          }
        />
        <CategoryCard
          title="Tier List"
          link="/ash-echoes/tier-list"
          image={
            <StaticImage
              src="../../images/ash/categories/category_tier.webp"
              alt="Tier List"
            />
          }
        />
        <CategoryCard
          title="Tea Time Drinks"
          link="/ash-echoes/tea-time"
          image={
            <StaticImage
              src="../../images/ash/categories/category_tea.webp"
              alt="Tea Time Drinks"
            />
          }
        />
        <CategoryCard
          title="Game Modes"
          link="/ash-echoes/guides/game-modes"
          image={
            <StaticImage
              src="../../images/ash/categories/category_modes.webp"
              alt="Game Modes"
            />
          }
        />
        <CategoryCard
          title="Echoing Nexus Overview"
          link="/ash-echoes/guides/echoing-nexus"
          image={
            <StaticImage
              src="../../images/ash/categories/category_nexus.webp"
              alt="Echoing Nexus Overview"
            />
          }
        />
        <CategoryCard
          title="Memory Traces"
          link="/ash-echoes/memory-traces"
          image={
            <StaticImage
              src="../../images/ash/categories/category_traces.webp"
              alt="Memory Traces"
            />
          }
        />
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default AshIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Ash Echoes Wiki & Database | Prydwen Institute"
    description="Prydwen.gg is a wiki for Ash Echoes. Check our guides, tier lists and reviews for characters available in the game."
    game="ash"
  />
);
