/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';
import ScrollspyNav from 'react-scrollspy-nav';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import '../../../dynamic-pages/hsr-character-dynamic.scss';
import './hsr-guides.scss';
import { Alert, Col, Row } from 'react-bootstrap';
import { HSRSkill } from '../../../modules/hsr/skills/hsr-skill';
import { HSRStat } from '../../../modules/hsr/common/components/hsr-stat';
import { HSRRelicSet } from '../../../modules/hsr/common/components/hsr-relic-set';
import { HSRLightCone } from '../../../modules/hsr/common/components/hsr-light-cone';
import { HSRCharacter } from '../../../modules/hsr/common/components/hsr-character';
import {
  faBriefcaseMedical,
  faFlask,
  faHandFist
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProfileHertaDPS } from '../../../modules/hsr/profile/the-herta-calcs';

const SRGuidesRappa: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page hsr-guide'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>The Herta - First Impressions</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_herta.webp"
            alt="The Herta - First impressions"
          />
        </div>
        <div className="page-details">
          <h1>The Herta - First Impressions</h1>
          <h2>
            Check the early access first impressions of The Herta - a new
            character coming soon to Honkai: Star Rail!
          </h2>
          <p>
            Last updated: <strong>10/01/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      {/* <div className="page-content">
        <SectionHeader title="Disclaimer" />
        <p>Coming soon!</p>
      </div> */}
      <div className="page-content character-hsr-v2 special-guide ">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Disclaimer" />
              <Alert variant="primary alert-red">
                <p>
                  The information you can find in this article{' '}
                  <strong>
                    come from the Creator Experience server and as such are
                    subject to change
                  </strong>{' '}
                  - so while you can check out The Herta's kit details a bit
                  early, please keep that in mind. A full guide for The Herta
                  will be released as usual once the patch introducing her goes
                  live.
                </p>
              </Alert>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Introduction" />
              <StaticImage
                src="../../../images/starrail/robin/intro.webp"
                alt="The Herta"
              />
              <h6>The Herta's Profile</h6>
              <p>
                <strong className={`Ice`}>The Herta</strong> is a{' '}
                <strong className={`rarity-5`}>5★</strong> character from the{' '}
                <strong className={`Ice`}>Ice</strong> element who follows the{' '}
                <strong className={`Erudition`}>Path of Erudition</strong>. The
                writers from the Intelligentsia Guild wanted to give me an extra
                title. Something like "Herta Prime" to separate me from my
                puppets. How banal. Are the puppets not "me" as well? So, I gave
                them a suggestion — if they dared to write that, then I would
                call myself THE Herta. It's short, simple, straight to the
                point, and elegant.Esteemed member #83 of the Genius Society,
                human, female, young, beautiful, attractive. It's said that she
                lives in the far edge of the Cosmos, almost never leaving.
                Sounds like her appearance this time... must be to deal with an
                issue that has to be handled herself, right?
              </p>
              <h6>The Herta's Intro</h6>
              <p>
                <strong>
                  Herta's finally making her true self known and it's as
                  magnificent as expected.
                </strong>{' '}
                The smug mahou shoujo has plenty of reasons to be amused with
                herself and we will go over them all in this early impressions
                article.
              </p>
              <p>
                <strong>
                  The real Madam Herta has strong and consistent damage for
                  single-target and AoE alike.
                </strong>{' '}
                She does this while gaining an absurd stacking damage multiplier
                to her Enhanced Skill leading to some of the highest DMG
                multipliers ever seen in this game. As an Emanator of Erudition,
                she gains huge bonuses to damage and ramp-up if she is paired
                with at least one other Erudition unit, similar to how Acheron's
                passive works, and she shares the damage bonuses with the entire
                party.{' '}
                <strong>
                  This also means she can act as a powerful support DPS for
                  future Erudition units, and pairing this with her extremely
                  generalist damage profile, Big Herta is an exceptionally
                  compelling character to pick up.
                </strong>
              </p>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Kit" />
              <p>
                First, let's go over The Herta's kit - so stats, skills, major
                traces and minor traces - and explain how they work.
              </p>
              <h6>The Herta's Stats (level 80)</h6>
              <Row xs={1} xxl={4} className="va-section">
                <Col>
                  <div className="info-list-row">
                    <div className="category">HP</div>
                    <div className="details">1164</div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">DEF</div>
                    <div className="details">485</div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">ATK</div>
                    <div className="details">679</div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">Speed</div>
                    <div className="details">99</div>
                  </div>
                </Col>
              </Row>
              <h6>The Herta's Skills</h6>
              <div className={`skills Ice`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="140101"
                        maxLevel={7}
                        element="Ice"
                        gen="20"
                        break_primary="30"
                        break_secondary=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Just a simple one-target Basic Attack like most characters have.
              </p>
              <div className={`skills Ice`}>
                <Row xs={1} xl={1} xxl={2}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="140102"
                        maxLevel={12}
                        element="Ice"
                        gen="30"
                        break_primary="45"
                        break_secondary="15"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="140108"
                        maxLevel={12}
                        element="Ice"
                        gen="30"
                        break_primary="60"
                        break_secondary="15"
                        isEnhanced={true}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Herta's Skill is a three-fold propagating AoE attack; it starts
                as a single-target hit, then becomes a Blast AoE for the second
                hit, and then a full AoE for the third hit — the last hit is
                technically two Blast AoEs overlapped, so keep in mind if you
                are not targeting the middle foe, you will not hit everyone on
                the field. The Skill can deal up to 70% ATK per strike, for a
                total of 210% to the primary target, 140% to adjacent foes, and
                70% to the enemies at the edges.
              </p>
              <p>
                This is already quite impressive numerically, but she also has
                an Enhanced Skill, functioning the same way as the normal Skill
                but dealing up to 80% ATK per hit instead, and then landing a
                fourth fully AoE hit of 40% ATK. This in total becomes 280% ATK
                for single-target, 200% for adjacent, and 120% against the
                remaining enemies.
              </p>
              <div className={`skills Ice`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="140103"
                        maxLevel={12}
                        element="Ice"
                        gen="5"
                        break_primary="60"
                        break_secondary=""
                        ult_cost={220}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Herta's Ultimate rearranges the number of Interpretation stacks
                on all enemies, prioritising placing more stacks on Elite or
                higher level enemies. She gains up to 80% ATK increase for 3
                turns, and then deals up to 200% ATK to all enemies. After using
                her Ultimate, Herta fully Action Advances herself, and she gains
                1 stack of Inspiration, allowing a single use of her Enhanced
                Skill. She can hold up to 4 stacks of Inspiration, but this will
                very rarely apply outside of the Simulated Universe.
              </p>
              <p>
                Even though the Ultimate itself doesn't deal insanely over the
                top damage like her Enhanced Skill, it is what grants access to
                it, and as such is a very important part of Herta's kit. Even
                more so because it costs a whopping 220 Energy just to use it
                once. The fact it also fully Advances Herta's turn means you can
                deal immense damage back to back. Just be wary of external buff
                durations when using this.
              </p>
              <div className={`skills Ice`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="140104"
                        maxLevel={12}
                        element="Ice"
                        gen=""
                        break_primary=""
                        break_secondary=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                When enemies enter battle, Herta automatically inflicts 1 stack
                of Interpretation on them, and Interpretation can stack up to 42
                times (based Hitchhiker's Guide to the Galaxy reference). At the
                start of each wave, 25 stacks of Interpretation are applied to a
                random enemy, prioritising Elite enemies or above. When enemies
                with Interpretation leave the field or are defeated, their
                Interpretation stacks are transferred to another enemy,
                prioritising Elites or above. These stacks of Interpretation
                interact with Herta's Enhanced Skill to increase the DMG
                multiplier of the Enhanced Skill by up to 8% for the primary
                target, and 4% for other targets. This is calculated based on
                the number of Interpretation stacks on the primary target ONLY.
              </p>
              <p>
                This means that attacking an enemy with 42 stacks of
                Interpretation will cause the Enhanced Skill's DMG multiplier to
                go up by 336%, for a new total of 616% single, 368% adjacent,
                and 288% remaining. Regrettably, Herta's Skill counts as 'one
                hit', so despite having four separate sets of damage numbers
                show up, they comprise one singular attack, so this increase to
                multiplier is applied at the end when Herta hits with the final
                fourth flick of her wrist. Even still, this is already an absurd
                multiplier to be throwing out.
              </p>
              <p>
                But wait, there's more! Should your party contain a second
                Erudition character, the multiplier increase is further boosted
                by 8% and 4%, for a total of 16% and 8% per stack of
                Interpretation. If you've been keeping up thus far, this indeed
                results in a total multiplier increase of 672%. 672%, added on
                to the Enhanced Skill, now dealing 952% to single, 536% to
                adjacent, and 456% to remaining. If you are hitting 5 enemies,
                this means this Enhanced Skill's total damage multiplier is —
                brace yourselves — 2936%. Yeah. Keep in mind as well that this
                is an increase to the multiplier, so it does not get diluted by
                buffs!
              </p>
              <div className={`skills Ice`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="140107"
                        maxLevel={1}
                        element="Ice"
                        gen=""
                        break_primary=""
                        break_secondary=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                The Herta's Technique is a 60% ATK buff for 2 turns at the start
                of battle, so pretty simple. But the second, non-combat part of
                the Technique is pretty interesting as it allows Herta to mark
                nearby chests - now Numby finally has competition when it comes
                to chest hunting!
              </p>
              <h6>The Herta's Major Traces</h6>
              <div className={`skills traces Ice`}>
                <Row xs={1} xl={2} xxl={3}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Ice`}>
                          A2
                          <span>Major trace</span>
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">Aloofly Honest</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring Ice`}>
                        When ally targets attack, inflicts <b>1</b> stack of
                        Interpretation on the hit enemy target. After attacking,
                        for every 1 target hit by this attack, regenerates{' '}
                        <b>3</b> fixed Energy for The Herta, counting up to a
                        maximum of 5 targets. When using Enhanced Skill and if
                        the primary target's Interpretation stack reaches 42,
                        increases Ice DMG dealt by The Herta by <b>50%</b> until
                        the end of this attack.
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Ice`}>
                          A4
                          <span>Major trace</span>
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">
                            Message From Beyond the Veil
                          </p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring Ice`}>
                        When entering battle, if the team has 2 or more
                        characters following the Path of Erudition, then
                        increases all allies' CRIT DMG by <b>80%</b>, calculates
                        at least 3 targets when the Trace Aloofly Honest
                        calculates hit targets, applies <b>1</b> stack(s) of
                        Interpretation to the target that has the highest
                        current Interpretation stacks among the hit enemy
                        targets after attacking, and additionally applies{' '}
                        <b>2</b> stack(s) of Interpretation if the attacker is a
                        character following the Path of Erudition.
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Ice`}>
                          A6
                          <span>Major trace</span>
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">
                            Starved Landscape of Vacua
                          </p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring Ice`}>
                        For every 1 stack of Interpretation inflicted on enemy
                        targets, The Herta gains <b>1</b> stack of Answer up to{' '}
                        <b>99</b> stack(s). When using the Ultimate, every stack
                        of Answer increases the Ultimate's DMG multiplier by{' '}
                        <b>1%</b>.
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Her Bonus Abilities are extremely useful and here's what they
                do:
              </p>
              <ul>
                <li>
                  <strong>A2</strong> allows allies to assist in stacking
                  Interpretation, inflicting 1 stack on enemies hit by their
                  attack. For every 1 enemy hit by the ally attack, 3 fixed
                  Energy is recovered for Herta, counting up to 5 targets for a
                  max Energy restore of 15 fixed Energy. Additionally, if the
                  Enhanced Skill's primary target has 42 stacks of
                  Interpretation, Herta will gain a 50% Ice DMG boost for the
                  duration of the attack.
                </li>
                <li>
                  <strong>A4</strong> is very similar to Acheron's A4; if there
                  are 2 or more characters following the Path of Erudition in
                  the party, Herta will increase all allies' CRIT DMG by a
                  whopping 80%. The rest of the effects of A4 actually boost the
                  A2, but it's poorly worded, so let's break it down.
                </li>
                <ul>
                  <li>
                    When allies attack enemies and trigger the effects of
                    Herta's A2, it will always count as hitting at least 3
                    enemies if you hit 2 or fewer enemies, meaning Herta will
                    always restore at least 9 Energy from each ally attack.
                    Allies will apply an extra stack of Interpretation to the
                    enemy with the highest stack if hitting multiple enemies,
                    and if the attacking ally character is on the Path of
                    Erudition, you will apply a further 2 stacks of
                    Interpretation in all cases.
                  </li>
                </ul>
                <li>
                  <strong>A6</strong> will grant Herta a permanent stacking buff
                  called Answer; for each stack of Interpretation that is
                  applied to enemies, Herta gains a stack of Answer. Each stack
                  of Answer will increase Herta's Ultimate DMG multiplier by 1%.
                  This stacks up to 99, for a total multiplier increase of 99%
                  to Herta's Ultimate, for a new total of 299%.
                </li>
              </ul>
              <h6>The Herta's Minor Traces</h6>
              <div className="smaller-traces">
                <Row xs={1} xxl={3}>
                  <Col>
                    <div className="box trace">
                      <HSRStat stat="Ice DMG" />{' '}
                      <span className="value">+22.4%</span>
                    </div>
                  </Col>
                  <Col>
                    <div className="box trace">
                      <HSRStat stat="Speed" /> <span className="value">+5</span>
                    </div>
                  </Col>
                  <Col>
                    <div className="box trace">
                      <HSRStat stat="ATK%" />{' '}
                      <span className="value">+18%</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Eidolons" />
              <p>
                Now let's check The Herta's Eidolons and how they improve her
                performance.
              </p>
              <h6>The Herta's Eidolons</h6>
              <div className="skills eidolons">
                <Row xs={1} xxl={2}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Ice`}>E1</div>
                        <div className="skill-info">
                          <p className="skill-name">Night at Shorefall</p>
                          <p className="skill-type">Eidolon 1</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring eidolon Ice`}>
                        <p>
                          When Enhanced Skill calculates "Interpretation,"
                          additionally calculates 50% of the "Interpretation"
                          stacks on the <b>1</b> target with the highest stacks
                          out of the primary target and adjacent targets. When
                          using Enhanced Skill to reset "Interpretation," reset
                          the stack number to <b>15</b> instead.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Ice`}>E2</div>
                        <div className="skill-info">
                          <p className="skill-name">Wind Through Keyhole</p>
                          <p className="skill-type">Eidolon 2</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring eidolon Ice`}>
                        <p>
                          After The Herta enters battle and uses her Ultimate,
                          she additionally gains <b>1</b> stack of
                          "Inspiration." After using Enhanced Skill, The Herta's
                          subsequent action is advanced by <b>35%</b>.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Ice`}>E4</div>
                        <div className="skill-info">
                          <p className="skill-name">The Sixteenth Key</p>
                          <p className="skill-type">Eidolon 4</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring eidolon Ice`}>
                        <p>
                          The SPD of characters following the Path of Erudition
                          in the team increases by <b>12%</b>.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Ice`}>E6</div>
                        <div className="skill-info">
                          <p className="skill-name">Sweet Lure of Answer</p>
                          <p className="skill-type">Eidolon 6</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring eidolon Ice`}>
                        <p>
                          The Herta's Ice RES PEN increases by <b>20%</b>. When
                          the number of enemy targets on the field is 3 (or
                          more)/2/1, Ultimate's DMG multiplier increases by{' '}
                          <b>140%/250%/400%</b>.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <h6>Eidolons reviewed:</h6>
              <ul>
                <li>
                  <strong>E1</strong> will reapply 15 stacks of Interpretation
                  after the Enhanced Skill consumes them. Additionally, the
                  enemy with the highest Interpretation stacks out of your
                  primary target and its adjacent foes will have 50% of their
                  stacks factored in when using the Enhanced Skill. This means
                  using the Enhanced Skill on an enemy with 42 stacks of
                  Interpretation will calculate damage as if they had 63 stacks
                  instead. This results in a huge increase to damage, especially
                  since it's a DMG multiplier increase and is not diluted by
                  buffs.
                </li>
                <ul>
                  <li>
                    The additional stacks of interpretation provided by Eidolon
                    1 can be counted towards the 42 layers required for the
                    Trace “Aloofly Honest”. That is, once the main target has
                    reached 28 stacks of interpretation, it will definitely
                    trigger the increased Ice DMG effect of the Trace “Aloofly
                    Honest”.
                  </li>
                </ul>
                <li>
                  <strong>E2</strong> grants an additional stack of Inspiration
                  when she uses her Ultimate, granting another use of the
                  Enhanced Skill. After using Enhanced Skill, Herta Advances her
                  Action by 35%. This is the really really broken one, in case
                  there was any doubt.
                </li>
                <li>
                  <strong>E4</strong> will increase the SPD of all Erudition
                  characters in the team by 12%. Kinda boring, but will help
                  with hitting Speed breakpoints,
                </li>
                <li>
                  <strong>E6</strong> will grant Herta a permanent 20% Ice RES
                  PEN, and additionally increasing the Ultimate's DMG multiplier
                  inversely proportional to the number of enemies on the field;
                  +140% if there are 3 or more, +250% if there are 2 enemies,
                  and +400% if there is only one enemy.
                </li>
              </ul>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Relics and Light Cones" />
              <div className={`section-build Ice `}>
                <div className="build-stats">
                  <h6 className="with-margin">Recommended Main and Substats</h6>
                  <Row xs={1} xl={2} xxl={2} className="main-stats">
                    <Col>
                      <div className="box">
                        <div className="stats-header">
                          <span>Body</span>
                        </div>
                        <div className="list-stats">
                          <HSRStat stat="CRIT Rate" />{' '}
                          <span className="order">&gt;</span>{' '}
                          <HSRStat stat="CRIT DMG" />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="box">
                        <div className="stats-header">
                          <span>Feet</span>
                        </div>
                        <div className="list-stats">
                          <HSRStat stat="Speed" />{' '}
                          <span className="order">=</span>{' '}
                          <HSRStat stat="ATK%" />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="box">
                        <div className="stats-header">
                          <span>Planar Sphere</span>
                        </div>
                        <div className="list-stats">
                          <HSRStat stat="Ice DMG" />{' '}
                          <span className="order">&gt;</span>{' '}
                          <HSRStat stat="ATK%" />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="box">
                        <div className="stats-header">
                          <span>Link Rope</span>
                        </div>
                        <div className="list-stats">
                          <HSRStat stat="ATK%" />{' '}
                          <span className="order">&gt;=</span>{' '}
                          <HSRStat stat="Energy Regen Rate" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="box sub-stats">
                        <span>Substats:</span>
                        <p>
                          SPD (Until Desired Breakpoint) &gt; CRIT RATE &gt;
                          CRIT DMG &gt; ATK%
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <h6>Recommended Endgame Stats (while buffed in combat)</h6>
                <ul>
                  <li>
                    ATK: <strong>2500 - 2900+</strong>
                  </li>
                  <li>
                    HP: <strong>2800+</strong>
                  </li>
                  <li>
                    DEF: <strong>850+</strong>
                  </li>
                  <li>
                    CRIT Rate: <strong>90-100%</strong>
                  </li>
                  <li>
                    CRIT DMG: <strong>100-120%</strong>+
                  </li>
                  <li>
                    SPD: The Herta has many different Speed Breakpoints she can
                    pursue, depending on the game mode, her Relic choices and
                    teammates. Here are some of the notable ones:
                  </li>
                  <ul>
                    <li>
                      <strong>Base Speed</strong> (With Hyperspeed Action
                      Advancer or 4P Mourning Collapse)
                    </li>
                    <li>
                      <strong>134</strong> Speed (Generic Endgame Breakpoint)
                    </li>
                    <li>
                      <strong>160</strong> Speed (Higher Endgame Speed
                      Breakpoint)
                    </li>
                    <li>
                      Also worth mentioning is that Jade, one of The Herta's
                      more powerful teammates, will grant her 30 Speed which
                      also needs to be taken into account.
                    </li>
                  </ul>
                </ul>
                <hr />
                <p>More info about the stats:</p>
                <ul>
                  <li>
                    <strong>CRIT RATE & CRIT DMG</strong>: The Herta gains a
                    free 80% CRIT DMG from her kit. Because of this stacking
                    more CRIT RATE is worth for her more compared to other DPS.
                  </li>
                  <li>
                    <strong>ENERGY REGEN</strong>: Much of The Herta's power
                    stems from her Ultimate and how much access she has to it.
                    This is especially true in Pure Fiction and even Apocalyptic
                    Shadow to some extent. Because of this, despite the fact her
                    Talent energy does not scale with Energy Regen it can still
                    be worth building on her for faster or additional Ultimates.
                    When it comes to total damage it's rarely better than ATK%
                    but the additional actions, Toughness damage and AoE
                    potential can make up for that.
                  </li>
                </ul>
                <div className="build-relics">
                  <h6>Recommended Relic Sets</h6>
                  <div className="detailed-cones moc extra planar">
                    <div className={`single-cone Ice`}>
                      <div className={`percentage default`}>
                        <p>100%</p>
                      </div>
                      <HSRRelicSet
                        name="Scholar Lost in Erudition"
                        compactMode
                        comment="4PC"
                      />
                    </div>
                    <div className="information">
                      <p>
                        Best set for The Herta by a significant margin thanks to
                        it providing CRIT RATE (her best stat) and a tremendous
                        amount of DMG% to the abilities she cares most about.
                      </p>
                    </div>
                    <div className={`single-cone Ice`}>
                      <div className={`percentage default`}>
                        <p>92.74%</p>
                      </div>
                      <HSRRelicSet
                        name="Hunter of Glacial Forest"
                        compactMode
                        comment="4PC"
                      />
                    </div>
                    <div className="information">
                      <p>
                        While not an outstanding set, The Herta can make better
                        use of it than most as the majority of her damage comes
                        from her Ultimate and the Enhanced skill that directly
                        follows it.
                      </p>
                    </div>
                    <div className={`single-cone with-notes Ice`}>
                      <div className={`percentage`}>
                        <p>92.10%</p>
                      </div>
                      <div className="double-set">
                        <HSRRelicSet
                          name="Scholar Lost in Erudition"
                          compactMode
                          comment="2PC"
                        />
                        <>
                          <div className="flex-placeholder">Flex</div>
                        </>
                      </div>
                    </div>
                    <div className={`information Ice`}>
                      <p>
                        If you can't put together a top recommended 4P set with
                        satisfactory sub/main stats, mix and match the 2P
                        Bonuses from the following instead:
                      </p>
                      <ul className="with-sets">
                        <li>
                          <HSRRelicSet
                            name="Hunter of Glacial Forest"
                            compactMode
                            smol
                          />{' '}
                          <span className="value">[Ice DMG +10%]</span>
                        </li>
                        <li>
                          <HSRRelicSet
                            name="Musketeer of Wild Wheat"
                            compactMode
                            smol
                          />{' '}
                          <span className="value">[ATK +12%]</span>
                        </li>
                        <li>
                          <HSRRelicSet
                            name="Prisoner in Deep Confinement"
                            compactMode
                            smol
                          />{' '}
                          <span className="value">[ATK +12%]</span>
                        </li>
                        <li>
                          <HSRRelicSet
                            name="Hero of Triumphant Song"
                            compactMode
                            smol
                          />{' '}
                          <span className="value">[ATK +12%]</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <h6>Special Relic Sets</h6>
                  <div className="detailed-cones moc extra planar">
                    <div className={`single-cone Ice`}>
                      <HSRRelicSet
                        name="Poet of Mourning Collapse"
                        compactMode
                        comment="4PC"
                      />
                    </div>
                    <div className="information">
                      <p>
                        <strong>
                          A usable set on The Herta but only under very strict
                          conditions.
                        </strong>{' '}
                        Requires the wearer to build no/minimum Speed on relics
                        and NOT to allocate The Herta's Speed stat Traces. This
                        allows The Herta to enter the battle with &lt;95 Speed
                        fully activating the 32% CRIT RATE bonus. With this
                        combo, The Herta can use ATK% boots and also reclaim
                        some of her lost Speed in combat through teaming up with
                        Jade who offers 30. This set is obscure and very niche
                        but for some min-maxers may be of interest over more
                        traditional options. It is only better if you build
                        incredibly specifically around it.
                      </p>
                    </div>
                  </div>
                  <h6 className="with-margin">Recommended Planetary Sets</h6>
                  <div className="detailed-cones moc extra planar">
                    <div className={`single-cone with-notes Ice`}>
                      <div className={`percentage`}>
                        <p>100%</p>
                      </div>
                      <HSRRelicSet
                        name="Izumo Gensei and Takama Divine Realm"
                        compactMode
                      />
                    </div>
                    <div className={`information Ice`}>
                      <p>
                        The Herta always wants to be paired with another
                        Erudition character, because of that this set has no
                        downside while also having a large amount of CRIT RATE
                        her best stat.
                      </p>
                    </div>
                    <div className={`single-cone with-notes Ice`}>
                      <div className={`percentage`}>
                        <p>98.44%</p>
                      </div>
                      <HSRRelicSet name="Rutilant Arena" compactMode />
                    </div>
                    <div className={`information Ice`}>
                      <p>
                        Generic, solid, planar set for CRIT-based damage dealers
                        who focus on Basic ATK and Skill damage (or both).
                      </p>
                    </div>
                    <div className={`single-cone with-notes Ice`}>
                      <div className={`percentage`}>
                        <p>95.66%</p>
                      </div>
                      <HSRRelicSet
                        name="Firmament Frontline: Glamoth"
                        compactMode
                      />
                    </div>
                    <div className={`information Ice`}>
                      <p>
                        Competitive set for almost all damaging characters that
                        can benefit from ATK% and satisfy at least the first of
                        its activation requirements.
                      </p>
                    </div>
                  </div>
                  <h6 className="with-margin">Special Planetary Sets</h6>
                  <div className="detailed-cones moc extra planar">
                    <div className={`single-cone with-notes Ice`}>
                      <div className={`percentage`}>
                        <p>101.88%</p>
                      </div>
                      <HSRRelicSet
                        name="Sigonia, the Unclaimed Desolation"
                        compactMode
                      />
                    </div>
                    <div className={`information Ice`}>
                      <p>
                        Alternative to Izumo that has a ramp-up time as you need
                        to kill 10 enemies to get the full benefit which will be
                        easy to do in Pure Fiction, but harder in other modes,
                        making the set not as generic as Izumo - the
                        calculations assume it's fully stacked.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="build-cones">
                  <h6>Recommended Light Cones</h6>
                  <>
                    <div className="detailed-cones moc">
                      <div className={`single-cone Ice`}>
                        <div className="percentage">
                          <p>114.35%</p>
                        </div>
                        <HSRLightCone
                          slug="into-the-unreachable-veil"
                          mode="profile"
                          superImp="1"
                        />
                      </div>
                      <div className="information">
                        <p>
                          Best option for The Herta in terms of both damage and
                          utility. The Skill Point granted by this Light Cone is
                          invaluable for The Herta. Firstly, as she's almost
                          always played in double damage teams that are skill
                          point hungry, and secondly as it opens up her sustain
                          and support team building options drastically.
                        </p>
                      </div>
                      <div className={`single-cone Ice`}>
                        <div className="percentage">
                          <p>100%</p>
                        </div>
                        <HSRLightCone
                          slug="today-is-another-peaceful-day"
                          mode="profile"
                          superImp="5"
                        />
                      </div>
                      <div className="information">
                        <p>
                          The Herta can easily cap out the full energy bonus of
                          this Light Cone, making it a strong option for her in
                          most scenarios.
                        </p>
                      </div>
                      <div className={`single-cone Ice`}>
                        <div className="percentage">
                          <p>97.42%</p>
                        </div>
                        <HSRLightCone
                          slug="night-on-the-milky-way"
                          mode="profile"
                          superImp="1"
                        />
                      </div>
                      <div className="information">
                        <p>
                          This Light Cones bonuses are accessible in all endgame
                          modes but only fully utilized in Pure Fiction and
                          against enemies the wearers element can break.
                        </p>
                      </div>
                      <div className={`single-cone Ice`}>
                        <div className="percentage">
                          <p>96.95%</p>
                        </div>
                        <HSRLightCone
                          slug="before-dawn"
                          mode="profile"
                          superImp="1"
                        />
                      </div>
                      <div className="information">
                        <p>
                          Jing Yuan's signature is generic and non-conditional,
                          making it a solid pick on The Herta if you have none
                          of the options that rank above it or want to avoid
                          conditional effects.
                        </p>
                      </div>
                      <div className={`single-cone Ice`}>
                        <div className="percentage">
                          <p>94.99%</p>
                        </div>
                        <HSRLightCone
                          slug="geniuses-repose"
                          mode="profile"
                          superImp="5"
                        />
                      </div>
                      <div className="information">
                        <p>
                          This Light Cone requires the killing of an enemy every
                          3 actions for its full effect. To achieve its listed
                          performance, you'll need to keep close to 100% uptime.
                        </p>
                      </div>
                      <div className={`single-cone Ice`}>
                        <div className="percentage">
                          <p>93.31%</p>
                        </div>
                        <HSRLightCone
                          slug="eternal-calculus"
                          mode="profile"
                          superImp="5"
                        />
                      </div>
                      <div className="information">
                        <p>
                          This Light Cone bonuses are accessible in all endgame
                          modes but only fully utilized in Pure Fiction.
                        </p>
                      </div>
                      <div className={`single-cone Ice`}>
                        <div className="percentage">
                          <p>92.91%</p>
                        </div>
                        <HSRLightCone
                          slug="today-is-another-peaceful-day"
                          mode="profile"
                          superImp="1"
                        />
                      </div>
                      <div className="information">
                        <p>
                          The Herta can easily cap out the full energy bonus of
                          this Light Cone, making it a strong option for her in
                          most scenarios.
                        </p>
                      </div>
                      <div className={`single-cone Ice`}>
                        <div className="percentage">
                          <p>89.19%</p>
                        </div>
                        <HSRLightCone
                          slug="the-seriousness-of-breakfast"
                          mode="profile"
                          superImp="5"
                        />
                      </div>
                      <div className="information">
                        <p>
                          This Light Cone requires killing 3 enemies to be in
                          full effect. Results listed assume you achieve this
                          fast and early on in the fight - if you can't this
                          Cone's performance will suffer.
                        </p>
                      </div>
                    </div>
                  </>
                </div>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="Signature vs Eidolons" />
              <ProfileHertaDPS />
              <br />
              <h5>What to prioritize?</h5>
              <p>
                If you're someone looking to invest more into The Herta and want
                to know whether to pull the Signature or shoot for E1 here is
                what to consider.
              </p>
              <p>
                E1 grants more overall damage and most importantly allows The
                Herta easier access to Interpretation Stacks thanks to it
                refunding 15 each use of her enhanced skill making achieving 42
                consistently much easier in modes where it's not always a
                guarantee like Apocalytic Shadow and Memory of Chaos. E1
                naturally is the gateway to E2 and The Herta has one of the
                strongest E2's in the game - if you have any plans for getting
                it, at all this is a huge point in E1's favor.
              </p>
              <p>
                S1 grants less overall damage, but still a reasonable amount,
                and also helps remedy The Herta's higher than average Skill
                Point consumption. This allows The Herta to be played with a
                wider variety of characters (Such as Sunday) and allows her
                fellow Erudition teammates more opportunity to use their own
                skills.
              </p>
              <p>
                Summing it up, if you want greater team building options and
                more team flexibility in and outside of combat S1 is a strong
                pick. If you have any plans for E2 at all, want greater damage
                or an insurance you're going to hit 42 stacks in all modes
                almost all the time go for E1.
              </p>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Synergy" />
              <ul className="bigger-margin">
                <li>
                  <HSRCharacter mode="inline" slug="jade" enablePopover />{' '}
                  offers The Herta some of the most consistent and frequent
                  stack and Energy generation of all her Erudition options; on
                  top of also granting her 30 Speed thanks to her Skill. Jade
                  can also dish out very respectable damage especially in Pure
                  Fiction.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="herta" enablePopover /> -
                  "Mini Herta" is a reasonable alternative to Jade in modes that
                  feature weak and or respawnable enemies for her to trigger her
                  Follow-Up talent against such as Pure Fiction. Unfortunately
                  Mini Herta's follow-up's only count once for the purpose of
                  The Herta's stacks and Energy generation no matter how many
                  times she spins back to back, making her mostly suitable for
                  Pure Fiction or for those without other erudition options.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="serval" enablePopover /> can
                  be played standard or alternatively built as a dedicated Stack
                  and Energy generator for The Herta by using the Passkey Light
                  Cone and the Eagle of Twilight Line set. This build allows
                  Serval to move quickly and spam her Ultimate but at the cost
                  of most of her damage potential.
                </li>
                <li>
                  <HSRCharacter
                    mode="inline"
                    slug="trailblazer-remembrance"
                    enablePopover
                  />{' '}
                  - Unlike other Supports, the Remembrance Trailblazer's
                  Ultimate, as well as their companion Mem, can attack enemies
                  in an AoE to Regenerate Energy and apply "Interpretation"
                  stacks for The Herta. The Herta's additional Energy
                  Regeneration allows for faster Charges on Mem, and her Max
                  Energy is high enough to max out the Additional True DMG
                  Multiplier. Mem then provides CRIT, Action Advance, and
                  Additional True DMG buffs for The Herta.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="sunday" enablePopover /> -
                  Grants The Herta the -1 Speed or Hyper Speed playstyle
                  alongside fantastic CRIT-related buffs and most importantly a
                  constant flow of Energy. Sunday himself scales off CRIT DMG
                  allowing him to grant the target of his Skill a % of his own
                  CRIT DMG - when The Herta buffs him with her 80% CRIT DMG he
                  receives it, then can give it right back to The Herta,
                  allowing her to gain even more benefit from her own buffs. One
                  of the best supports for The Herta but is Skill Point
                  intensive without his Signature - this stacked on top of The
                  Herta's skill point woes without her own signature, make using
                  the pair more challenging without investment.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="robin" enablePopover /> -
                  Excellent choice for The Herta and her teams. Robin is a team
                  wide buffer, meaning both The Herta and her Erudition partner
                  are buffed and capable of dealing more damage. Robin's Action
                  advance is powerful for the same reason, but also even
                  stronger because of the fact the additional actions it grants
                  also grant The Herta even more Energy and Stacks, resulting in
                  even more Ultimates.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="lingsha" enablePopover /> &{' '}
                  <HSRCharacter mode="inline" slug="aventurine" enablePopover />{' '}
                  - are two sustains that are capable of attacking each turn
                  using their Basic ATK/Skill, have a damaging Ultimate and an
                  additional source of attack outside of their standard actions.
                  This is important as all of these actions will generate The
                  Herta Stacks and Energy. This factor alone allows Aventurine
                  and Lingsha to pull substantially ahead of most other sustain
                  options for The Herta.
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-7">
              <SectionHeader title="Teams" />
              <div className={`character-teams box Ice`}>
                <div className="team-header">
                  <p>Premium Team</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="the-herta"
                          enablePopover
                        />
                      </div>
                      <div className="role dps">
                        <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter mode="icon" slug="jade" enablePopover />
                      </div>
                      <div className="role dps">
                        <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character multi-equal small">
                        <HSRCharacter mode="icon" slug="sunday" enablePopover />
                        <HSRCharacter mode="icon" slug="robin" enablePopover />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character multi-equal small">
                        <HSRCharacter
                          mode="icon"
                          slug="lingsha"
                          enablePopover
                        />
                        <HSRCharacter
                          mode="icon"
                          slug="aventurine"
                          enablePopover
                        />
                      </div>
                      <div className="role sustain">
                        <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                        Sustain
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>F2P Team</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="the-herta"
                          enablePopover
                        />
                      </div>
                      <div className="role dps">
                        <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main multi-equal small">
                        <HSRCharacter mode="icon" slug="herta" enablePopover />
                        <HSRCharacter mode="icon" slug="serval" enablePopover />
                      </div>
                      <div className="role dps">
                        <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character multi-equal small">
                        <HSRCharacter
                          mode="icon"
                          slug="trailblazer-remembrance"
                          enablePopover
                        />
                        <HSRCharacter mode="icon" slug="pela" enablePopover />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="gallagher"
                          enablePopover
                        />
                      </div>
                      <div className="role sustain">
                        <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                        Sustain
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-8">
              <SectionHeader title="What's Next?" />
              <p>
                That's the end of the early impressions article for The Herta
                and we hope you did enjoy it! As we mentioned in the
                introduction section,{' '}
                <strong>
                  full The Herta guide will be released and placed on her
                  profile once she's properly available in the game
                </strong>{' '}
                and then she will be also added to our tier list.
              </p>
              <p>Till then!</p>
              <StaticImage
                src="../../../images/starrail/robin/outro.webp"
                alt="The Herta"
              />
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6',
                'section-7',
                'section-8'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Disclaimer</a>
                </li>
                <li>
                  <a href="#section-1">Introduction</a>
                </li>
                <li>
                  <a href="#section-2">Kit</a>
                </li>
                <li>
                  <a href="#section-3">Eidolons</a>
                </li>
                <li>
                  <a href="#section-4">Relics & Light Cones</a>
                </li>
                <li>
                  <a href="#section-5">Signature vs Eidolons</a>
                </li>
                <li>
                  <a href="#section-6">Synergy</a>
                </li>
                <li>
                  <a href="#section-7">Teams</a>
                </li>
                <li>
                  <a href="#section-8">What's Next?</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SRGuidesRappa;

export const Head: React.FC = () => (
  <Seo
    title="The Herta - First Impressions | Honkai: Star Rail | Prydwen Institute"
    description="Check the early access first impressions of The Herta - a new character coming soon to Honkai: Star Rail!"
  />
);
