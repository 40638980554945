import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NikkeGuidesMariMakinami: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide '} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Mari Makinami Illustrious guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_mari.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Mari Makinami Illustrious Guide & Review</h1>
          <h2>
            A guide & review for Mari Makinami Illustrious in NIKKE: Goddess of
            Victory.
          </h2>
          <p>
            Last updated: <strong>05/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Mari Makinami Illustrious" />
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon"
                  slug="mari-makinami-illustrious"
                  enablePopover
                />
              </div>
              <p>
                Crown, Naga, Blanc, Summer Rosanna—recently, we have been
                flooded with so many good B2 we struggle to deploy them all.
                And, who would have thought we would be given another choice?
                Meet Mari, an operative in bewitching pink, a support unit who
                can enhance the team's Pierce DMG and ATK DMG. Her fantastic
                buffing capabilities will leave you speechless.
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Mari is an exceptional generalist with the potential to thrive
                even further in the right teams. Her main role is empowering
                units with Pierce capabilities, such as Red Hood, Maxwell, Snow
                White, Laplace, Asuka, and Alice, to name a few. With them, she
                reaches her maximum buffing potential, and she can even beat
                Crown in terms of raw damage buffing in certain teams.
              </p>
              <p>
                However, Crown increases the team's survivability and gives
                Reload Speed, which are what makes Crown extremely powerful and
                dependable, whereas Mari is just a full-time damage buffer (like
                S. Rosanna). We explicitly state this because some units, like
                Alice, benefit more from the increased Reload Speed despite
                Mari's theoretically bigger numbers. But, nothing stops us from
                using them together should we want to utilize Mari's Pierce DMG
                as well as Crown's divine buffs at the same time, but in this
                case we do waste one of their Burst Skills. Do note that she can
                only buff the team's Pierce DMG if she can regularly hit any
                core. In PvP or against bosses like Crystal Chamber, you are
                locked out.
              </p>
              <p>
                What is amazing about Mari is her compatibility with many
                different units and teams, including those with the most
                ridiculous quirks. Even in cases when her signature Pierce DMG
                is underutilized, she already outperforms many other offensive
                buffers. That is because ATK and ATK DMG are both universal
                buffs, and the values are high enough to surpass the likes of
                Summer Rosanna and Blanc & Noir (unless dilution is in play).
              </p>
              <p>
                We believe she will be useful in Raids, easing some burden off
                Crown & Tia Naga and allowing you to maximize the potential of
                leftover DPS in your ensemble. Another feasible deployment
                location is in Anomaly Interception against Ultra where she
                serves as an equal or worse alternative to Summer Helm. If you
                happen to have no access to Crown, for example, you can also use
                Mari for literally any content. She allows the adoption of 1-1-3
                formation, whereas Tia & Naga and the Bunny duo restrict you to
                2 Main DPS setups. She can be a bit situational, though, since
                her lack of survivability buffs may lead to slot pressure, but
                let's not judge too early, shall we?
              </p>
              <h5>Should I pull this unit?</h5>
              <p>
                Given that she is a limited collab unit, pulling for at least
                one copy is advised. In terms of raw offensive buffing in Pierce
                teams, she stands on the summit. She is also an awesome
                generalist, and players without a decent B2 buffer will likely
                benefit from getting her. She can replace your Dolla in
                Campaign, for instance.
              </p>
              <p>
                Now, is she still worth pulling for people with an already
                diverse roster? Well, YES. In fact, you will need her to deal
                maximum damage in Raids. It is not a choice but rather a
                requirement. Not only that, her presence will allow you to
                experiment with different team compositions. We are mainly
                interested in assessing her integrability when joined by Red
                Hood, Maxwell, Snow White or even Laplace(Treasure) in
                situations. Even having her as a situational pick against some
                bosses is enough a reason for veterans. Also, get one for
                future-proofing purposes. Who knows ShiftUp will release some
                broken Pierce characters in the future?
              </p>
              <p>
                As for dupes, Mari is a buffer. Her caster's ATK buff has 50%
                uptime and is not major enough to warrant extra copies,
                especially seeing that anniversary is coming soon and that tere
                is a chance that ShiftUp will toy with us by releasing an OP
                unit just before then (like Tia & Naga).
              </p>
              <p>
                Therefore, unless you want her MLB lobby screen, we believe one
                copy is enough. Dupes will help if you want to min-max her
                damage to the fullest. If you are competitive in Solo Raids, you
                may splurge on her banner. In PvP, her small nuke may come into
                play versus some teams, and the extra CP boost will help make
                incorporating her more seamless.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <p>
                Mari's winding in-game name is a real mouthful, but so are her
                buffs. She buffs Pierce DMG better than other units, and that is
                her niche. She also periodically grants herself Pierce, which
                can be useful for triggering her S1 buff if the enemy's core is
                behind shields or inside a boss's body. Are the sights of Water
                Alteisen, SR Gravedigger, Chatterbox's Head, and Kraken's Main
                Body reminiscent to you? Pierce is also useful for speeding up
                the team's burst generation speed at times.
              </p>
              <p>
                As a generalist buffer, she provides allies with ATK buffs and a
                highly appreciated ATK DMG buff. All of these combined make her
                a highly usable unit in all game modes, occasionally being part
                of the top tier meta, especially in Solo Raids. The only thing
                she lacks to compete with the very top buffers are survivability
                skills.
              </p>
              <h5>A Piercer's Best Friend</h5>
              <blockquote>
                <p>
                  ■ Activates when landing an attack with Full Charge. Affects
                  all allies.
                </p>
                <p>Damage dealt to Shield ▲ 100.09% for 3 sec.</p>
                <p>
                  (It only affects the damage dealt to the shield, not to the
                  Rapture itself)
                </p>
                <p>
                  ■ Activates when hitting the target's core. Affects all
                  allies.
                </p>
                <p>Pierce Damage ▲ 40.99% for 10 sec.</p>
              </blockquote>
              <p>
                In contrast to Asuka's and Rei's personal shieldbreaking
                aptitude, Mari takes a slightly different route by boosting the
                team's shieldbreaking capacity instead. To distribute this buff,
                you will need to full-charge your shot. As we explained in
                Asuka's review, this gimmick is useful for breaking
                barriers/shields in Campaign and against particular bosses. For
                Raids, generally, you want to split your resources such that no
                team is bottlenecked. While it may be hard to split up Rei and
                Asuka due to their strong synergy together, Mari can easily
                offer another team her shield-breaking capability!
              </p>
              <Alert variant="primary">
                <p>
                  Faster shieldbreak may help you overcome Campaign stages
                  inundated with barriers/shields if you do not have Asuka or
                  Rei. Crown and Naga are still the kings of supports, but not
                  everyone has access to them.
                </p>
              </Alert>
              <p>
                The second component of Mari's S1 is a high value, virtually
                permanent "Pierce Damage ▲" buff. The condition to activate this
                buff is that a core needs to exist, and Mari needs to hit it,
                hence the 100% uptime. We have seen a similar buff before on
                D:Wife, Diesel (Treasure), and more recently on Zwei. None of
                them can compare to the values being provided by Mari for this
                buff. This is where Mari gives herself a niche in which she
                excels.
              </p>
              <Alert variant="primary">
                <p>
                  A large portion of Zwei's Pierce DMG is tied to Burst Skill,
                  and D:Wife's is only applicable to Sniper Rifle Nikkes.
                  Diesel's Pierce DMG is more reliable that it does not need
                  using Burst Skill or demand an existing core. This is one
                  advantage she has over Mari, but her value is smaller and her
                  offensive side is notably weaker.
                </p>
              </Alert>
              <p>
                Pierce DPS such as Red Hood, Snow White, Maxwell, Alice, Asuka
                and LaplaceTr can make full use of Mari's Pierce DMG buff. As
                you can see, these are some reputable names. Most of these
                Nikkes are considered the best in business when it comes to
                dealing Pierce damage! We will talk more about team-building and
                synergy with these units in the Usage Analysis section below.
              </p>
              <h5>It's Futile to Hide Your Core</h5>
              <blockquote>
                <p>■ Affects self.</p>
                <p>Gain Pierce for 5 sec.</p>
                <p>ATK ▲ 30.78% for 5 sec.</p>
                <p>■ Affects all allies.</p>
                <p>ATK ▲ 30.78% of caster's ATK for 5 sec.</p>
              </blockquote>
              <p>
                Every 10 seconds, Mari will gain the ability to Pierce for 5
                seconds. As a result, she will be able to hunt down hidden or
                protected cores to activate her Skill 1 or generate higher burst
                energy in PvE if not in Full Burst. She also provides herself an
                offensive ~30% caster's ATK buff and additionally provides the
                same value ATK buff to the entire team. In other words, Mari
                herself gains 2× the ATK buff she gives the team. Together with
                her Pierce Damage buff, at least within these 5-second windows,
                Mari will perhaps be able to deal some bonus damage!
              </p>
              <Alert variant="primary">
                <p>
                  ATK buffs with predictable uptime like this are often rated
                  highly for Burst Units like Snow White and Maxwell. The reason
                  is that their damage is concentrated in their Burst Skills,
                  and when the timing is right, she can make full use of this
                  intermittent ATK buffs and not be affected by the 50% uptime.
                  There is a reason why Yulha is used with Snow White.
                </p>
              </Alert>
              <p>
                ATK buffs are usually more prone to dilution and are more
                effective on units without too much ATK buff. There are only a
                few Piercers who does not exhibit this trait, namely Snow White
                or LaplaceTr, but that does not mean it is useless for the likes
                of Red Hood or Asuka. It still boosts their damage.
              </p>
              <h5>Let's Make Her More Universal</h5>
              <blockquote>
                <p>■ Affects all enemies.</p>
                <p>Deals 639. 36% of final ATK as damage.</p>
                <p>■ Affects all allies.</p>
                <p>Attack damage ▲ 40.99% for 10 sec.</p>
              </blockquote>
              <p>
                The first part of her Burst Skill is a complementary nuke that
                deals decent damage, but the damage varies depending on whether
                she has the ATK buffs from S2.
              </p>
              <p>
                {' '}
                Since Mari is not a damage dealer, do not expect her to one-shot
                mobs in Campaign, especially not when her S2 is inactive. You
                can, however, combine hers with other wipes to fulfill the
                remaining damage. You are more likely to engage this in PvP
                alongside Scarlet and Rosanna. That being said, while her wipe
                can help annihilate critters, we believe it is designed more for
                clearing those annoying projectiles hostiles randomly hurl.
              </p>
              <p>
                The second part of this skill is Mari's her highlight. The jobs
                of the famous Blanc & Noir, Rosanna: Chic Ocean, and Rem are put
                into question as Mari eclipses them with a generic 'Attack
                Damage ▲' buff. The value of this buff matches the value of the
                Pierce Damage buff in S1, and both of them together make Mari a
                superior damage buffer for Pierce DPS than Crown. This 1-to-1
                comparison disregards Crown's Reload Speed & Survivability
                Skills and the fact that the choice for the fifth unit is
                consequently more restrictive for Mari.
              </p>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Beware of
                  Dilution!
                </h6>
                <p>
                  'Attack Damage ▲' and 'Pierce Damage ▲' are additive buffs to
                  each other, so beware of dilution with Asuka's ATK DMG and
                  Dorothy's Part Damage (which is also additive to these Damage
                  ▲ buffs). These buffs, however, are multiplicative to other
                  brackets such as ATK, Damage Taken, etc., and are hence more
                  efficient for units whose teams and kits are usually filled
                  with these other buffs.
                </p>
                <p>
                  Regardless, Mari's numbers are so massive that even with
                  dilution, she might still be stronger than the most efficient
                  options if minmaxing is unnecessary (such as outside Raids).
                  Even Asuka deals more damage with Mari than with Blanc or S.
                  Rosanna.
                </p>
              </blockquote>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Usage Analysis and Tier List" />

              <h5>
                Campaign (LD) - <strong className="ss">SS</strong> | Campaign
                (HD) - <strong className="ss">SS</strong>
              </h5>
              <p>
                Strong buffs + strong synergy with the very best units in the
                market (Alice, Red Hood, Asuka) make her a stellar pick in
                Campaign. The problem is her position in B2 is overshadowed by
                the dignified Crown. Crown offers much more than just damage.
                Her taunt + Immunity, alongside Reload Speed, come in clutch so
                many times. If you do not have Crown, Mari is often a good
                alternative, rivalling Tia + Naga in that sense with a different
                focus and different weaknesses.
              </p>
              <p>
                What about replacing Naga in Crown + Naga comps? That highly
                depends. Naga's healing can perpetuate Crown's S2 ATK DMG and
                expedite her taunting. Naga also buffs the team's Core Damage
                considerably and keeps the team healthy. Meanwhile, Mari buffs
                the team's ATK and Pierce Damage. Against enemies without a
                persistent core or sports many hitboxes, Mari's offense is
                theoretically better, but under other circumstances, Naga is
                better, both offensively and defensively.
              </p>
              <p>
                Note that Core Damage only buffs the damage against the core but
                not other parts, assuming you hit multiple at once. An example
                of a deployment site where Mari is likely better is against
                Ultra in Anomaly Interception. Endgame players who already have
                too much damage against the boss's core can make better use of
                Mari or SHelm by overkilling the core and parts and hitting its
                body at the same time.
              </p>
              <p>
                In both Bossing and Campaign, the direct competitions to Mari
                are Blanc & Noir and S. Rosanna, who both provide an undiluted
                Damage Taken Debuff compared to Mari's possibly diluted ATK, ATK
                DMG, and Pierce DMG buffs. When non-pierce DPS units are being
                used, both of them can come close to Mari in usability depending
                on the dilution of buffs on the units. However, when Pierce
                units are being used, this conclusion will shift.
              </p>
              <p>
                For units such as Modernia, Blanc & Noir can prove to be better,
                but remember you are forced to bring Noir (which is considered
                slot pressure in today's era). If you want to use another B3,
                let's say in Asuka + Rei + Modernia comp, Blanc & Noir is out of
                the question. Your top choice would be Crown, and then Mari will
                come right after her. However, Mari does not have survivability
                skills, unlike Blanc + Noir and Crown. You get one, you lose
                one.
              </p>
              <p>
                In summary, Mari is more useful for beginners who have no access
                to Crown's heavenly blessings.
              </p>
              <h5>
                Boss (Solo) - <strong className="ss">SS</strong> | Boss (Adds) -{' '}
                <strong className="ss">SS</strong>
              </h5>
              <p>
                In Campaign, we only need one team. However, against bosses, we
                are expected to deploy up to 5 teams. As the number of teams
                increases, Mari has dramatically increased chances of being used
                and possibly benches S. Rosanna out of rotation who requires
                regular destruction of parts for her ATK buff.
              </p>
              <p>
                Her possible synergy with Snow White (and Maxwell) deserves a
                spotlight mention, since she can also perhaps bench Yulha and
                break the long-lasting day one SW-Yulha relationship. If a
                B1-CDR unit is used, there is possibly no need to even bench
                Yulha since now the need for Dolla vanishes for the team. Also,
                her intermittent ATK buffs are more effective on Burst Units
                like her and Maxwell, as we explained in Kit Analysis.
              </p>
              <p>
                Snow White is not the only units worth mentioning here. We have
                seen both Red Hood and Maxwell relegated into S. Rosanna teams
                in the past couple Solo Raids. While S. Rosanna still remains
                the ideal buffer for S. Sakura, against elements other than
                Iron, she will likely not see prevalent use.
              </p>
              <p>
                Another possible pairing here is of LaplaceTr. When it comes to
                Iron-Weak raids, she might need a team to fit into and Mari can
                perform this task better than other B2s. LaplaceTr also will not
                need to have her own proprietary buffer and can fit right into
                the meta alongside Red Hood or Maxwell.
              </p>
              <p>
                Even in Union Raids, the possibility of Mari being used in Team
                3 is very realistic. With Tia + Naga and Crown monopolizing the
                top two teams, the competition for the third best team becomes
                extremely fierce. Blanc & Noir, S. Rosanna, and Tove with her
                SGs show up from time to time. However with Mari in play, the
                leftover Pierce units can definitely enjoy higher buffs with
                her. Don't forget, for higher sync players, Mari is an absolute
                all-rounder of a unit. The third slot does not need to be a
                healer and can be an extra DPS or support for the team, making
                Mari undoubtedly better than both the Bunny Duo AND S. Rosanna!
              </p>
              <p>
                Even better, it is possible to use Mari as an off-burst support!
                While you do lose the benefits of her Burst Skill, her Pierce
                DMG + ATK buffs are insane. We explained this briefly in the
                Campaign section above, where she can partner with Crown against
                Ultra.
              </p>
              <p>
                Also, let's not forget that she also strengthens the team's
                shieldbreaking capacity! A niche effect, but when its time
                comes, you will be delighted!
              </p>
              <h5>
                PVP - <strong className="s">S</strong>
              </h5>
              <p>
                At first glance, Mari is a simple generic buffer who can be used
                for strengthening AoE nukers such as Scarlet, PrivM, etc. She is
                a direct sidegrade to Crown and S. Rosanna in PvP in this sense
                and is probably better since she does not feed the opponent
                Jackal or Scarlet.
              </p>
              <p>
                However, a bonus advantage that she possesses over her
                'competition' is that she has an AoE nuke herself in her Burst.
                She can assist the snowballing potency of other wipers/nukers to
                confirm their kills, especially against weaker AoE blocks of Bay
                and Moran. She can effectively be considered as a hard counter
                to Bay's AoE block since she is able to remove Bay's cover
                before the actual strong AoE hits! To achieve this feat vs a Bay
                team, you would need to burst slower otherwise Bay will be able
                to delay her cover redirect enough so that Mari doesn't hit it.
                Hence, Mari is considered a good unit to use in PvP, but in the
                overall meta she is a highly situational unit. You either need
                super fast 2RL teams for her, or situationally slower teams. All
                of this makes it feel like Mari is more of an attacking side
                unit than a defensive side unit.
              </p>
              <p>
                Do note that Mari's Pierce DMG buff does not activate in PvP!
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                The main purpose of OL-ing Mari's gear is to increase her base
                attack stat through upgrades and levels. This will improve her
                S2's buffing potency. The gain is rather minute, so that is your
                decision whether 4 Custom Modules + EXP Materials are worth the
                difference.
              </p>
              <p>
                Rerolling her lines, on the other hand, is another question
                because her DPS is not exactly impressive, but better lines also
                result in more damage. Her DPS is more important for Campaign
                and PvP wiping, but also be mindful of CP padding. One Max Ammo
                for spamming quick shots helps with smoother burst rotations.
              </p>

              <ul>
                <li>
                  <strong>Essential:</strong>
                </li>
                <li>
                  <strong>Ideal:</strong> 1× Max Ammo, 4× ATK, 4× ELE
                </li>
                <li>
                  <strong>Passable:</strong> 3× Max Ammo
                </li>
                <li>
                  <strong>Priority:</strong> Low
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <ul>
                <li>
                  <strong>Skill 1: 7~10</strong>
                </li>
                <ul>
                  <li> High-value Pierce DMG buff. Regular scaling.</li>
                </ul>
                <li>
                  <strong>Skill 2: 4~7</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    Less valuable than the other two because the value is
                    comparatively smaller and it only has 50% uptime. Also prone
                    to ATK dilution, resulting in less perceived gain.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill: 7~10</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    Hefty ATK DMG that is universal and thunderous. A
                    game-changing ability. Regular scaling except on the wipe
                    multiplier (1.76×).
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="mari-makinami-illustrious" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_3_sm.png"
                alt="Guide"
              />
              <p>
                We usually recommend Resilience Cube for Sniper Rifles because
                the shorter reload helps with smoother quick shots for burst
                gen. This is more true if you don't have any Max Ammo OL.
                Meanwhile, Bastion Cube technically allows her to shoot more
                shots over 3 minutes.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: Snow White + Miranda Variation</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="miranda" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="mari-makinami-illustrious"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="snow-white"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="helm" enablePopover />
                    <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                In this version, Snow White bursts first and easily gets her
                final extra 7th burst off, compared to the rush you have to deal
                with in the SW-Yulha combo. Delaying the first burst to get both
                Mari's and Dolla's ATK buffs is a valid strategy to min-max
                damage. S. Helm substitutes Dolla against Iron-Weak for even
                more damage! Mari and Helm provide enough burst gen for the
                team.
              </p>
              <h5>Team #2: Snow White + B1 CDR Variation</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="placeholder-box">B1-CDR</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="mari-makinami-illustrious"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="snow-white"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="helm" enablePopover />
                    <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="yulha" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                If you straight up just want to maximize base damage and feel
                like ditching Miranda's ~40% ATK buff and CRIT buffs is fine,
                this team is the way to go. Complete with Yulha's huge ATK buffs
                and clutch damage share, Mari's insane overall buffs, and Helm's
                heal. This team has EVERYTHING Snow White dreams of! All she
                awaits now is an Iron-Weak boss or a boss with enough
                pierce-able spots. It's time for "Seven Dwarves: I" to deal
                deathly damage to the boss! Snow White is possibly and probably
                the best pairing for Mari in the current meta.
              </p>
              <h5>Team #3: The Normal Pierce Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="mari-makinami-illustrious"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="alice" enablePopover />
                    <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="laplace"
                      enablePopover
                      showTreasure
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                This team has everything: 2 strong DPS who scale stupidly hard
                with Mari's buffs, a CDR, a B2 buffer, and one more slot
                reserved for healing/protection or off-burst DPS/support. If you
                use Privaty and Dorothy instead of D here, you can have a budget
                Crown Naga team. Asuka may also fit in but will be less
                effective compared to the above mentioned units. It is also
                worth mentioning that Alice is a better fit with Crown and is
                even better fitted alongside her best buddy, Scarlet: Black
                Shadow, who needs her Charge Speed to drastically increase her
                already insane DPS. Hence, we might not see Alice together with
                Mari unless it is in Campaign and you do not have Crown or
                Tia-Naga.
              </p>
              <p>
                Since it is possible to use Mari as an off-burst buffer, you can
                also insert Crown here and use Crown's Burst Skill instead. This
                strategy might be effective against Ultra, where you need to
                reach Stage 4 before or right upon destroying the boss's first
                core (time gated).
              </p>
              <h5>Team #4: Crownless Asuka + Alice</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="placeholder-box">B1-CDR</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="mari-makinami-illustrious"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="alice" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                As the title suggests, if you do not have Crown, then Asuka +
                Alice can make a great combination for Fire Weak Solo Bosses.
                Asuka buffs Alice's Core Damage, and Mari pushes her further.
                May not be ideal in Raids since we are most likely coupling
                Alice with SBS, but everywhere else, this team can be used with
                peace. Tia + Naga should be better against Wind enemies, though,
                due to the fact that you want Individual Shield to trigger
                Asuka's Elemental DMG.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvP Team Compositions" />
              <h5>3RL Nuke</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="mari-makinami-illustrious"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rosanna" enablePopover />
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Mari is used as a simple damage buffer for nukes. Scarlet or Rei
                can be replaced by any fast nuker DPS. RH is used as a B1 unit
                to buff both Mari's and the nuker's AoE. The special assist from
                Mari comes when she uses her Burst Skill!
              </p>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          A universal generalist who blooms like a flower with
                          Pierce DPS.{' '}
                        </li>
                        <li>
                          An all-rounder kit, despite the lack of survivability.
                        </li>
                        <li>Possible to run as an off-burst buffer.</li>
                        <li>Provides competitive ATK and ATK DMG.</li>
                        <li>
                          Has Pierce, so can hit core behind shields/parts or
                          inside the boss's body.
                        </li>
                        <li>Buffs the team shield-breaking capacity.</li>
                        <li>
                          Decent AoE nuke that can be buffed by B1 units. Highly
                          usable in PvP.
                        </li>
                        <li>
                          According to Snake, she has the best design and is the
                          best girl among the 3 SSR units in this collab.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Limited collab character. Now or never. Can't get
                          copies in the future.
                        </li>
                        <li>
                          Meta buffer for Piercers. Outside her niche, she has
                          competition.
                        </li>
                        <li>
                          ATK buff is intermittent, so only some units can fully
                          utilize it; everyone else is impacted by its 50%
                          uptime.
                        </li>
                        <li>
                          If enemy has no core, she cannot provide her extra
                          Pierce Damage buff.
                        </li>
                        <li>
                          Similar to S. Rosanna, her lack of survivability
                          skills makes her harder to integrate into some comps
                          and somewhat less viable than the top B2.
                        </li>
                        <li>
                          According to Kisenix, he disagrees with Snake's
                          sentiment on Mari being the best girl.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesMariMakinami;

export const Head: React.FC = () => (
  <Seo
    title="Mari Makinami Illustrious Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Mari Makinami Illustrious in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
