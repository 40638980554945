import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Button, Col, Row } from 'react-bootstrap';

import '../generic-page.scss';
import './index.scss';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { YoutubeEmbed } from '../../modules/common/components/youtube-embed';

const HSRPCPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page play-on-pc-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>Play on Mac</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/starrail/categories/category_characters.png"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Play Honkai: Star Rail on Mac!</h1>
          <h2>
            Start your Adventures in Honkai: Star Rail on Mac Devices with
            BlueStacks Air!
          </h2>
        </div>
      </div>
      <div className="page-content">
        <div className="banner-hsr-mac">
          <div className="cta">
            <h4>Play Honkai: Star Rail on Mac!</h4>
            <OutboundLink href="https://bstk.me/kHAAc94mA" target="_blank">
              <Button variant="primary">Play now on Mac</Button>
            </OutboundLink>
          </div>
        </div>
        <SectionHeader title="Play on Mac" />
        <StaticImage src="../../images/starrail/mac_1.webp" alt="Play on Mac" />
        <p>
          The world of gaming is continually evolving, and with innovations like
          BlueStacks Air, playing your favorite Android games on Mac devices has
          never been more accessible or enjoyable. One such game that benefits
          immensely from this technology is Honkai: Star Rail. This turn-based
          role-playing game, developed by HoYoverse, immerses players in a
          visually stunning and narratively rich universe. Thanks to BlueStacks
          Air, Mac users can now dive into the action without compromising on
          quality! Learn how to play the game effortlessly on your Mac devices
          below.
        </p>
        <SectionHeader title="Why Choose to Play Honkai: Star Rail on Mac with BlueStacks Air?" />
        <Row xs={1} xxl={2}>
          <Col>
            <YoutubeEmbed embedId="xy2eNUIAJoQ" />
          </Col>
        </Row>
        <p>
          BlueStacks Air is a platform that bridges the gap between Android
          gaming and Mac devices. With its Apple Silicon optimization, seamless
          integration, and stunning visuals, it provides an unparalleled gaming
          experience for Mac users. Check out some core benefits that players
          can reap by playing Honkai: Star Rail on BlueStacks Air:
        </p>
        <ul>
          <li>
            Native Android Experience: BlueStacks Air offers a native Android
            gaming experience tailored specifically for Mac, ensuring smooth
            performance and responsive controls.
          </li>
          <li>
            Apple Silicon Optimization: Fully optimized for M1, M2, M3, and M4
            chips, the platform guarantees lightning-fast performance, even
            during the most demanding gameplay moments.
          </li>
          <li>
            Retina Display Support: The game’s breathtaking visuals and
            intricate details come to life on Mac’s Retina display, enhancing
            the immersive experience of Honkai: Star Rail.
          </li>
          <li>
            Pre-Configured Controls: BlueStacks Air comes with pre-configured
            controls for the Mac trackpad and keyboard, making it easy to jump
            right into the action without needing custom setups.
          </li>
          <li>
            Massive Game Library: In addition to Honkai: Star Rail, BlueStacks
            Air provides access to a library of over 2 million Android games,
            all optimized for Mac.
          </li>
        </ul>
        <SectionHeader title="Benefits of Playing Honkai: Star Rail on Mac with BlueStacks Air" />
        <StaticImage src="../../images/starrail/mac_2.webp" alt="Play on Mac" />
        <p>
          BlueStacks Air takes advantage of Mac devices’ powerful hardware to
          provide superior performance. Players can not only enjoy a powered-up,
          buttery smooth gameplay experience, but they can also use the
          BlueStacks gaming platform’s various utility tools to enhance their
          daily activities within the game. Check out some core benefits that
          all players can avail by playing Honkai: Star Rail on BlueStacks Air:
        </p>
        <ul>
          <li>
            Turn-Based Combat: The game’s strategic combat system shines on Mac,
            with precise controls ensuring flawless execution of your tactics.
          </li>
          <li>
            Expansive Universe: The larger screen and enhanced resolution of Mac
            devices bring the game’s interstellar environments and detailed
            character designs to life.
          </li>
          <li>
            Customizable Controls: Players can enjoy the customized control
            schemes that are tailored to your Mac device, providing an enriching
            experience when controlling your character in an open-world setting
            like Honkai: Star Rail boasts.
          </li>
          <li>
            Events and Updates: Enjoy real-time updates and participate in
            seasonal events with ease, as BlueStacks Air ensures compatibility
            with the latest game versions.
          </li>
        </ul>
        <SectionHeader title="How to Install and Start Playing Honkai: Star Rail on BlueStacks Air" />
        <StaticImage src="../../images/starrail/mac_3.webp" alt="Play on Mac" />
        <p>
          Now that you know about the benefits of playing your favourite games
          on a Mac device using BlueStacks Air, it’s time to download and
          install the software. Getting started with BlueStacks Air is quite
          simple and straightforward. Players can follow the steps given below
          to start playing Honkai: Star Rail on Mac within minutes:
        </p>
        <ul>
          <li>
            Download BlueStacks Air: Visit the official BlueStacks website{' '}
            <a
              href="https://bstk.me/kHAAc94mA"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{' '}
            . Click on the “Download BlueStacks Air” button to get the
            installer.
          </li>
          <li>
            Install BlueStacks Air: Open the downloaded .dmg file. Drag and drop
            the BlueStacks icon into your Applications folder.{' '}
          </li>
          <li>
            Launch and Sign-In: Open BlueStacks Air from the Launchpad or
            Applications folder. Sign in with your Google account to access the
            Play Store.
          </li>
          <li>
            Install Honkai: Star Rail: Search for Honkai: Star Rail in the Play
            Store and install it.
          </li>
          <li>
            Enjoy Playing! Launch the game and start your interstellar journey!
          </li>
        </ul>
        <SectionHeader title="Recommended configuration" />
        <p>
          The following specifications are recommended for playing one or two
          instances:
        </p>
        <ul>
          <li>
            OS: Microsoft Windows 7 or above, macOS 11 (Big Sur) or above,
          </li>
          <li>Processor: Intel, AMD or Apple Silicon Processor</li>
          <li>Ram: at least 4GB</li>
          <li>HDD: 10GB Free Disk Space</li>
        </ul>
        <SectionHeader title="Play Honkai: Star Rail and support Prydwen" />
        <div className="banner-hsr-mac">
          <div className="cta">
            <h4>Play Honkai: Star Rail on Mac!</h4>
            <OutboundLink href="https://bstk.me/kHAAc94mA" target="_blank">
              <Button variant="primary">Play now on Mac</Button>
            </OutboundLink>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default HSRPCPage;

export const Head: React.FC = () => (
  <Seo
    title="Play on Mac | Honkai: Star Rail | Prydwen Institute"
    description="Start your Adventures in Honkai: Star Rail on Mac Devices with BlueStacks Air"
  />
);
