export const RevTagsArray = [
  { label: 'Any Tag', value: 'all', description: '' },
  {
    label: 'Blood Burn',
    value: 'Blood Burn',
    description: '-'
  },
  {
    label: 'Burst DMG',
    value: 'Burst DMG',
    description: '-'
  },
  {
    label: 'Burn',
    value: 'Burn',
    description: '-'
  },
  {
    label: 'Control',
    value: 'Control',
    description: '-'
  },
  {
    label: 'Debuff',
    value: 'Debuff',
    description: '-'
  },
  {
    label: 'DEF',
    value: 'DEF',
    description: '-'
  },
  {
    label: 'Dispeller',
    value: 'Dispeller',
    description: '-'
  },
  {
    label: 'DPS',
    value: 'DPS',
    description: '-'
  },
  {
    label: 'Extra Action',
    value: 'Extra Action',
    description: '-'
  },
  {
    label: 'Follow-up Attack',
    value: 'Follow-up Attack',
    description: '-'
  },
  {
    label: 'Fragile',
    value: 'Fragile',
    description: '-'
  },
  {
    label: 'Immunity',
    value: 'Immunity',
    description: '-'
  },
  {
    label: 'Inspiration',
    value: 'Inspiration',
    description: '-'
  },
  {
    label: 'Heal',
    value: 'Heal',
    description: '-'
  },
  {
    label: 'Nasty Wound',
    value: 'Nasty Wound',
    description: '-'
  },
  {
    label: 'Poison',
    value: 'Poison',
    description: '-'
  },
  {
    label: 'Purify',
    value: 'Purify',
    description: '-'
  },
  {
    label: 'Rank Up',
    value: 'Rank Up',
    description: '-'
  },
  {
    label: 'Reaper',
    value: 'Reaper',
    description: '-'
  },
  {
    label: 'Riposte',
    value: 'Riposte',
    description: '-'
  },
  {
    label: 'Self-healing',
    value: 'Self-healing',
    description: '-'
  },
  {
    label: 'Shield',
    value: 'Shield',
    description: '-'
  },
  {
    label: 'Shift',
    value: 'Shift',
    description: '-'
  },
  {
    label: 'Support',
    value: 'Support',
    description: '-'
  },
  {
    label: 'Weakness',
    value: 'Weakness',
    description: '-'
  }
];
