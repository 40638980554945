import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import '../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const EndfieldGearsPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page endfield'} game="endfield">
      <ul className="breadcrumb">
        <li>
          <Link to="/arknights-endfield/">Arknights: Endfield</Link>
        </li>
        <li className="divider">/</li>
        <li>Gear List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/endfield/categories/category_gears.jpg"
            alt="Gear"
          />
        </div>
        <div className="page-details">
          <h1>Arknights: Endfield Gear List</h1>
          <h2>
            List of all gear/equipment available in Arknights: Endfield (based
            on current CBT).
          </h2>
          <p>
            Last updated: <strong>23/01/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Gear" />
      <p>Coming soon!</p>
      <SectionHeader title="Work in progress" />
      <div className="banner countdown">
        <div className="cta">
          <h4>Our Arknights: Endfield Database is currently being created!</h4>
          <p>
            We're gathering information on the current CBT now and the site will
            be updated soon!
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default EndfieldGearsPage;

export const Head: React.FC = () => (
  <Seo
    title="Gear | Arknights: Endfield | Prydwen Institute"
    description="List of all gear/equipment available in Arknights: Endfield (based on current CBT)."
    game="endfield"
  />
);
