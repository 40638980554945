export const AKESkills = {
  End_Basic: {
    name: 'Destructive Sequence',
    description_basic:
      '<b>5</b>-sequence slash attack that deals Physical DMG. As the controlled operator, Final Strike also deals <u>[20 Stagger]</u>.',
    description_dive:
      'Basic attack performed in mid-air becomes a dive attack that deals Physical DMG to nearby enemies.',
    description_dodge:
      'Basic attack performed right after a dodge becomes a dodge attack that deals Physical DMG.',
    description_finisher:
      'Basic attack performed near a Staggered enemy becomes a finisher attack that deals massive Physical DMG and restores some SP.',
    multi_basic: [51, 61, 68, 78, 90],
    multi_dodge: 101,
    multi_dive: 124,
    multi_finisher: 563
  },
  End_Skill: {
    name: 'Constructive Sequence',
    description:
      'A forceful burst of Originium that attacks enemies within the area of effect. Deals Physical DMG and <u>[15 Stagger]</u>, and Knocks Back the target.',
    cost: 100,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 347,
        isPercent: true
      }
    ]
  },
  End_Combo: {
    name: 'Flash Pulverization',
    description:
      "Rushes to the enemy's side to deal Physical DMG and <u>[10 Stagger]</u>. Also seals the enemy temporarily by encasing them in Originium crystals. Skills that deal Vulnerable or other Physical Status will shatter the crystals and deal additional Physical DMG.<br/>The Strength (STR) attribute can extend the duration of the Originium crystals.",
    trigger: "When another operator's combo skill deals DMG.",
    cooldown: 25,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 225,
        isPercent: true
      },
      {
        name: 'Crystal Shattering DMG Multiplier',
        value: 360,
        isPercent: true
      },
      {
        name: 'Base Seal Duration (s)',
        value: 4,
        isPercent: false
      },
      {
        name: 'Seal Extension per STR (s)',
        value: 0.01,
        isPercent: false
      }
    ]
  },
  End_Ultimate: {
    name: 'Bombardment Sequence',
    description:
      'Unleashes the Originium Arts to cause Originium crystals to erupt from the ground, dealing massive Physical DMG and <u>[25 Stagger]</u> to all enemies within a fan-shaped area. If the target has Originium crystals on them, this skill shatters the crystals and deals <b>1</b> more hit of bonus Physical DMG.',
    cost: 100,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 810,
        isPercent: true
      },
      {
        name: 'Bonus DMG Multiplier',
        value: 405,
        isPercent: true
      }
    ]
  },
  Ember_Basic: {
    name: 'Art of the Crusading Sword',
    description_basic:
      '<b>4</b>-sequence slash that deals Physical DMG. As the controlled operator, Final Strike also deals <u>[35 Stagger]</u>.',
    description_dive:
      'Basic attack performed in mid- air becomes a dive attack that deals Physical DMG to nearby enemies.',
    description_dodge:
      'Basic attack performed right after a dodge becomes a dodge attack that deals Physical DMG.',
    description_finisher:
      'Basic attack performed near a Staggered enemy becomes a finisher attack that deals  massive Physical DMG to the said enemy and restores some SP.',
    multi_basic: [86, 120, 149, 184],
    multi_dodge: 127,
    multi_dive: 124,
    multi_finisher: 563
  },
  Ember_Skill: {
    name: 'Crusade of Cinders',
    description:
      'A powerful slam that deals Heat DMG, <u>[15 Stagger]</u>, and Knock Down to all enemies in a fan-shaped area. Also deals Heat Infliction to any unit Knocked Down by this skill.',
    cost: 100,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 505,
        isPercent: true
      }
    ]
  },
  Ember_Combo: {
    name: "Judgement's End",
    description:
      'Performs a leaping slam attack at the target enemy that deals Physical DMG, <u>[10 Stagger]</u>, and Knock Down. The skill also gives some HP Treatment to the controlled operator. Will can increase the amount of HP Treatment given.',
    trigger: 'When the controlled operator is attacked.',
    cooldown: 15,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 270,
        isPercent: true
      },
      {
        name: 'Base Treatment',
        value: 170,
        isPercent: false
      },
      {
        name: 'Bonus per WILL',
        value: 1.02,
        isPercent: false
      }
    ]
  },
  Ember_Ultimate: {
    name: 'Undying Oath',
    description:
      'A ground slam that gives instant HP Treatment and temporary All DMG Reduction to all operators. Also deals Heat DMG, <u>[20 Stagger]</u>, and a temporary DEF reduction to all enemies nearby. Will can increase the amount of HP Treatment given.',
    cost: 110,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 820,
        isPercent: true
      },
      {
        name: 'Duration (s)',
        value: 8,
        isPercent: false
      },
      {
        name: 'DMG Taken Reduction',
        value: 75,
        isPercent: true
      },
      {
        name: 'DEF Reduction',
        value: 33,
        isPercent: true
      },
      {
        name: 'Base Treatment',
        value: 707,
        isPercent: false
      },
      {
        name: 'Bonus per WILL',
        value: 4.24,
        isPercent: false
      }
    ]
  },
  Gilberta_Basic: {
    name: 'Arcane Staff: Beam Cohesion Arts',
    description_basic:
      'A continuous <b>4</b>-sequence attack that deals Nature DMG. As the controlled operator, Final Strike also deals <u>[16 Stagger]</u>.',
    description_dive:
      'Basic attack performed in mid-air becomes a dive attack that deals Nature DMG to nearby enemies.',
    description_dodge:
      'Basic attack performed right after a dodge becomes a dodge attack that deals Nature DMG.',
    description_finisher:
      'Basic attack performed near a Staggered enemy becomes a finisher attack that deals massive Nature DMG to the said enemy and restores some SP.',
    multi_basic: [74, 87, 93, 124],
    multi_dodge: 115,
    multi_finisher: 563,
    multi_dive: 124
  },
  Gilberta_Skill: {
    name: 'Arcane Staff: Gravity Mode',
    description:
      'Channels the Arts to create an anomalous gravity well. The well pulls in nearby enemies and deals Nature DMG per second to them. The well then implodes after a while to deal Nature DMG, <u>[10 Stagger]</u>, and Nature Infliction to enemies in the area of effect.',
    cost: 100,
    multipliers: [
      {
        name: 'Gravity Pull DMG Multiplier',
        value: 59,
        isPercent: true
      },
      {
        name: 'Explosion DMG Multiplier',
        value: 158,
        isPercent: true
      }
    ]
  },
  Gilberta_Combo: {
    name: 'Arcane Staff: Matrix Displacement',
    description:
      'After performing quick casting gestures, apply Gravitational Arts that deal Nature DMG and Force Lifts the target and nearby enemies.',
    trigger: 'When an Arts Reaction is dealt to the enemy.',
    cooldown: 23,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 315,
        isPercent: true
      }
    ]
  },
  Gilberta_Ultimate: {
    name: 'Arcane Staff: Gravity Field',
    description:
      'Creates an anomalous gravity field that instantly deals a single hit of Nature DMG, <u>[20 Stagger]</u>, and Nature Infliction to all enemies within the circular area of effect. Enemies in the gravity field move slower and suffer increased Arts DMG Taken, while Lifted enemies in the gravity field remain Lifted and suffer even greater Arts DMG Taken.<br/>The Intellect (INT) attribute can increase the Arts DMG Taken debuff.',
    cost: 100,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 750,
        isPercent: true
      },
      {
        name: 'Duration (s)',
        value: 5,
        isPercent: false
      },
      {
        name: 'Movement Speed Slow',
        value: 80,
        isPercent: true
      },
      {
        name: 'Increase to Arts DMG Taken',
        value: 30,
        isPercent: true
      },
      {
        name: 'Bonus increase per INT',
        value: 0.1,
        isPercent: true
      },
      {
        name: 'Increase to Arts DMG Taken (Lifted)',
        value: 60,
        isPercent: true
      },
      {
        name: 'Bonus increase per INT (Lifted)',
        value: 0.2,
        isPercent: true
      }
    ]
  },
  Lifeng_Basic: {
    name: 'Illuminated Shattering',
    description_basic:
      '<b>5</b>-sequence slash attack that deals Physical DMG. As the controlled operator, Final Strike also deals <u>[27 Stagger]</u>.',
    description_dive:
      'Basic attack performed in mid-air becomes a dive attack that deals Physical DMG to nearby enemies.',
    description_dodge:
      'Basic attack performed right after a dodge becomes a dodge attack that deals Physical DMG.',
    description_finisher:
      'Basic attack performed near a Staggered enemy becomes a finisher attack that deals massive Physical DMG and restores some SP.',
    multi_basic: [55, 65, 82, 109, 164],
    multi_dodge: 101,
    multi_finisher: 563,
    multi_dive: 124
  },
  Lifeng_Skill: {
    name: 'Turbid Avatar',
    description:
      'Swings the polearm <b>2</b> times while advancing, with each swing dealing <b>1</b> hit of Physical DMG. Then slams the ground to deal Physical DMG, <u>[15 Stagger]</u>, and Knock Down to enemies in the area of effect.<br/>Apply the mark of Turbidity that lasts for several seconds to the target struck by the final hit of the skill (marked enemies also suffer reduced Physical RES).',
    cost: 100,
    multipliers: [
      {
        name: 'Seq 1 DMG Multiplier',
        value: 149,
        isPercent: true
      },
      {
        name: 'Seq 2 DMG Multiplier',
        value: 149,
        isPercent: true
      },
      {
        name: 'Seq 3 DMG Multiplier',
        value: 446,
        isPercent: true
      },
      {
        name: 'Physical RES Reduction',
        value: 10,
        isPercent: true
      },
      {
        name: 'RES Reduction Duration (s)',
        value: 12,
        isPercent: false
      }
    ]
  },
  Lifeng_Combo: {
    name: 'Unfounded Adversity',
    description:
      'Unleashes an avatar and thrusts the enemy with the spear to deal massive Physical DMG and <u>[10 Stagger]</u>.',
    trigger:
      'When controlled operator performs Final Strike on an enemy with active Turbidity.',
    cooldown: 10,
    multipliers: [
      {
        name: 'Seq 1 DMG Multiplier',
        value: 180,
        isPercent: true
      },
      {
        name: 'Seq 2 DMG Multiplier',
        value: 540,
        isPercent: true
      }
    ]
  },
  Lifeng_Ultimate: {
    name: 'Heart of the Unmoving',
    description:
      'Unleashes an avatar who performs the Immovable Vajra Slam, dealing massive Physical DMG, <u>[10 Stagger]</u>, and Knock Down to all enemies within a large area of effect (AOE). All enemies are then pulled towards the center of the AOE.<br/>After a while, all enemies still within the Vajra Slam AOE will take another hit of massive Physical DMG, <u>[10 Stagger]</u>, and Knock Down.',
    cost: 120,
    multipliers: [
      {
        name: 'Seq 1 DMG Multiplier',
        value: 506,
        isPercent: true
      },
      {
        name: 'Seq 2 DMG Multiplier',
        value: 1013,
        isPercent: true
      }
    ]
  },
  Wulfgard_Basic: {
    name: 'Speedfire Kata',
    description_basic:
      '<b>4</b>-sequence attack that deals Physical DMG. As the controlled operator, Final Strike also deals <u>[18 Stagger]</u>.',
    description_dive:
      'Basic attack performed in mid-air becomes a dive attack that deals Physical DMG to nearby enemies.',
    description_dodge:
      'Basic attack performed right after a dodge becomes a dodge attack that deals Physical DMG.',
    description_finisher:
      'Basic attack performed near a Staggered enemy becomes a finisher attack that deals massive Physical DMG to the said enemy and restores some SP.',
    multi_basic: [74, 92, 130, 161],
    multi_dodge: 96,
    multi_finisher: 563,
    multi_dive: 124
  },
  Wulfgard_Skill: {
    name: 'Targeted Termination',
    description:
      'Fires multiple shots at the target, dealing some Heat DMG and <u>[5 Stagger]</u>. If the target has an active Arts Reaction, consume the Arts Reaction and fire a sniper shot that deals massive Heat DMG and <u>[5 Stagger]</u>.',
    cost: 100,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 297,
        isPercent: true
      },
      {
        name: 'Bonus DMG Multiplier',
        value: 891,
        isPercent: true
      }
    ]
  },
  Wulfgard_Combo: {
    name: 'Custom Grenade β',
    description:
      "Throws an incendiary grenade at the enemy's feet. The grenade explodes upon hitting the ground, dealing Heat DMG, <u>[10 Stagger]</u>, and Heat Infliction to enemies in the area of effect.",
    trigger: 'When an enemy suffers an Arts Infliction.',
    cooldown: 30,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 135,
        isPercent: true
      }
    ]
  },
  Wulfgard_Ultimate: {
    name: 'Wolven Fury',
    description:
      'Unleashes a rapid barrage of shots and unleashes a Wolf Spirit to attack nearby enemies, dealing 5 hits of Heat DMG, <u>[20 Stagger]</u> in total, and Combust.',
    cost: 120,
    multipliers: [
      {
        name: 'DMG Multiplier per SEQ',
        value: 61,
        isPercent: true
      }
    ]
  },
  Xaihi_Basic: {
    name: 'Injection Attack',
    description_basic:
      '<b>5</b>-sequence attack that deals Cryo DMG. As the controlled operator, Final Strike also deals <u>[15 Stagger]</u>.',
    description_dive:
      'Basic attack performed in mid-air becomes a dive attack that deals Cryo DMG to nearby enemies.',
    description_dodge:
      'Basic attack performed right after a dodge becomes  a dodge attack that deals Cryo DMG.',
    description_finisher:
      'Basic attack performed near a Staggered enemy becomes a finisher attack that deals  massive Cryo DMG to the said enemy and restores some SP.',
    multi_basic: [33, 39, 55, 77, 132],
    multi_dodge: 97,
    multi_finisher: 563,
    multi_dive: 124
  },
  Xaihi_Skill: {
    name: 'Distributed DoS',
    description:
      'Summons an Auxiliary Crystal that gives an ATK buff to all operators while it is present. INT can slightly increase the ATK buff. The Auxiliary Crystal disappears after some time.',
    cost: 100,
    multipliers: [
      {
        name: 'ATK Increase',
        value: 37,
        isPercent: true
      },
      {
        name: 'Bonus increase per INT',
        value: 0.12,
        isPercent: true
      },
      {
        name: 'Duration (s)',
        value: 20,
        isPercent: false
      }
    ]
  },
  Xaihi_Combo: {
    name: 'Stress Testing',
    description:
      'Quickly charges up the Auxiliary Crystal and launches it at the enemy to deal Cryo DMG, <u>[10 Stagger]</u>, and Cryo Infliction.',
    trigger: 'When the Auxiliary Crystal is about to disappear.',
    cooldown: 10,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 450,
        isPercent: true
      }
    ]
  },
  Xaihi_Ultimate: {
    name: 'Stack Overflow',
    description:
      "Gives HP Treatment (amount if based on Xaihi's ATK) and a temporary ATK buff to all operators.",
    cost: 85,
    multipliers: [
      {
        name: 'Base Treatment',
        value: 1463,
        isPercent: false
      },
      {
        name: 'Treatment Multiplier',
        value: 36,
        isPercent: true
      },
      {
        name: 'ATK increase',
        value: 55,
        isPercent: true
      },
      {
        name: 'Duration (s)',
        value: 10,
        isPercent: false
      }
    ]
  },
  Chen_Basic: {
    name: 'Soaring Break',
    description_basic:
      '<b>5</b>-sequence slash attack that deals Physical DMG. As the controlled operator, Final Strike also deals <u>[20 Stagger]</u>.',
    description_dive:
      'Basic attack performed in mid-air becomes a dive attack that deals Physical DMG to nearby enemies.',
    description_dodge:
      'Basic attack performed right after a dodge becomes  a dodge attack that deals Physical DMG.',
    description_finisher:
      'Basic attack performed near a Staggered enemy becomes a finisher attack that deals massive Physical DMG and restores some SP.',
    multi_basic: [49, 58, 66, 73, 97],
    multi_dodge: 96,
    multi_finisher: 563,
    multi_dive: 124
  },
  Chen_Skill: {
    name: 'Ascending Strike',
    description:
      'Performs an uppercut slash at the target enemy, dealing Physical DMG, <u>[20 Stagger]</u>, and Lift. STR extends the Lift status inflicted upon the target.',
    cost: 100,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 446,
        isPercent: true
      },
      {
        name: 'Lift Duration (s)',
        value: 1,
        isPercent: false
      },
      {
        name: 'Lift extension per STR (s)',
        value: 0.01,
        isPercent: false
      }
    ]
  },
  Chen_Combo: {
    name: 'Stellar Gash',
    description:
      'A slashing dash that passes through the target enemy and deals Physical DMG, <u>[10 Stagger]</u>, and Lift to all enemies along the way.',
    trigger: 'When there are <b>2</b> Vulnerable enemies on the battlefield.',
    cooldown: 25,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 270,
        isPercent: true
      }
    ]
  },
  Chen_Ultimate: {
    name: 'Blade Gale',
    description:
      'A <b>7</b>-sequence slash attack that deals Physical DMG with each sequence. The final slash sequence deals even more DMG. The first and final slash also deals <u>[20 Stagger]</u>.',
    cost: 85,
    multipliers: [
      {
        name: 'Slash Multiplier',
        value: 81,
        isPercent: true
      },
      {
        name: 'Final Slash Multiplier',
        value: 1023,
        isPercent: true
      }
    ]
  },
  Arclight_Basic: {
    name: 'Arc Razor: Split',
    description_basic:
      '<b>5</b>-sequence slash attack that deals Physical DMG. As the controlled operator, Final Strike also deals <u>[20 Stagger]</u>.',
    description_dive:
      'Basic attack performed in mid-air becomes a dive attack that deals Physical DMG to nearby enemies.',
    description_dodge:
      'Basic attack performed right after a dodge becomes a dodge attack that deals Physical DMG.',
    description_finisher:
      'Basic attack performed near a Staggered enemy becomes a finisher attack that deals massive Physical DMG and restores some SP.',
    multi_basic: [45, 56, 68, 84, 113],
    multi_dodge: 91,
    multi_finisher: 563,
    multi_dive: 124
  },
  Arclight_Skill: {
    name: 'Flash Blade: Bolt',
    description:
      "Instantly blinks to the enemy's side to deal a <b>2</b>-sequence slash that deals some Physical DMG and <u>[5 Stagger]</u>. If the enemy has ongoing Electrification, consume the reaction to deal another Electric DMG and <u>[5 Stagger]</u>, and then restore <b>35</b> SP.",
    cost: 100,
    multipliers: [
      {
        name: 'SEQ 1 DMG Multiplier',
        value: 124,
        isPercent: true
      },
      {
        name: 'SEQ 2 DMG Multiplier',
        value: 124,
        isPercent: true
      },
      {
        name: 'Bonus SEQ DMG Multiplier',
        value: 495,
        isPercent: true
      }
    ]
  },
  Arclight_Combo: {
    name: 'Glaive: Wreath',
    description:
      "Instantly blinks to the enemy's side to perform multiple slashes that deal Physical DMG and <u>[10 Stagger]</u>, and then restores <b>10</b> SP.",
    trigger: 'When any operator performs a skill that restores SP.',
    cooldown: 5,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 189,
        isPercent: true
      }
    ]
  },
  Arclight_Ultimate: {
    name: 'Scimitar: Thunderer',
    description:
      'Transforms into an arc of electricity that dashes for a short distance and deals Electric DMG, <u>[10 Stagger]</u>, and Electric Infliction to all enemies in the path. The lingering arc explodes after a short while, dealing another Electric DMG and <u>[20 Stagger]</u>.<br/>If the enemy has active Electric Infliction, consume the infliction and instantly deal Electrification.',
    cost: 120,
    multipliers: [
      {
        name: 'SEQ 1 DMG Multiplier',
        value: 385,
        isPercent: true
      },
      {
        name: 'SEQ 2 DMG Multiplier',
        value: 830,
        isPercent: true
      }
    ]
  },
  Panda_Basic: {
    name: 'Rolling Cut!',
    description_basic:
      '<b>4</b>-sequence attack that deals Physical DMG. As the controlled operator, Final Strike also deals <u>[25 Stagger]</u>.',
    description_dive:
      'Basic attack performed in mid- air becomes a dive attack that deals Physical DMG to nearby enemies.',
    description_dodge:
      'Basic attack performed right after a dodge becomes a dodge attack that deals Physical DMG.',
    description_finisher:
      'Basic attack performed near a Staggered enemy becomes a finisher attack that deals Physical DMG to the said enemy and restores some SP.',
    multi_basic: [63, 75, 113, 136],
    multi_dodge: 106,
    multi_finisher: 563,
    multi_dive: 124
  },
  Panda_Skill: {
    name: 'Flip Da Wok!',
    description:
      'Takes out the wok and performs a short charging process before unleashing an upward swing that deals Physical DMG, <u>[15 Stagger]</u>, and Lifted to the enemy.',
    cost: 100,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 644,
        isPercent: true
      }
    ]
  },
  Panda_Combo: {
    name: 'More Spice!',
    description:
      'Da Pan leaps into mid-air and slams his metal wok to deal Physical DMG to enemies within the AOE as well as Force Knock Down to Lifted enemies.',
    trigger: 'When a Lifted or Knocked Down enemy is Lifted.',
    cooldown: 10,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 923,
        isPercent: true
      }
    ]
  },
  Panda_Ultimate: {
    name: "Chop 'n Dunk!",
    description:
      'Slams the chopping board to forcibly deal Lifted to all enemies in the area of effect, then performs a <b>6</b>-sequence flurry of slashes that deal Nature DMG. Finally, bashes every enemy into the ground to forcibly deal Knocked Down and massive Nature DMG to them.',
    cost: 120,
    multipliers: [
      {
        name: 'Final Slash Multiplier',
        value: 861,
        isPercent: true
      },
      {
        name: 'Mid-Air Flurry DMG Multiplier',
        value: 81,
        isPercent: true
      }
    ]
  },
  Snowshine_Basic: {
    name: 'Hypothermic Assault',
    description_basic:
      '<b>4</b>-sequence attack that deals Physical DMG. As the controlled operator, Final Strike also deals <u>[35 Stagger]</u>.',
    description_dive:
      'Basic attack performed in mid- air becomes a dive attack that deals Physical DMG.',
    description_dodge:
      'Basic attack performed right after a dodge becomes a dodge attack that deals Physical DMG.',
    description_finisher:
      'Basic attack performed near a Staggered enemy becomes a finisher attack that deals massive Physical DMG to the said enemy and restores some',
    multi_basic: [103, 123, 137, 245],
    multi_dodge: 122,
    multi_finisher: 563,
    multi_dive: 124
  },
  Snowshine_Skill: {
    name: 'Defensive Posture',
    description:
      'Raises the shield to temporarily grant massive DMG Reduction to self and nearby operators. If attacked during this time, perform a retaliation bash that deals Physical DMG, <u>[25 Stagger]</u>, and knock back to the attacker.',
    cost: 100,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 421,
        isPercent: true
      },
      {
        name: 'DMG Reduction',
        value: 90,
        isPercent: true
      }
    ]
  },
  Snowshine_Combo: {
    name: 'Emergency SAR',
    description:
      "Snowshine throws a little mangler cub at the controlled operator. Upon contact, the cub instantly gives massive HP Treatment to nearby operators. The cub also stays for a while and continuously offers HP Treatment to nearby operators.<br/>Amount of treatment is based on Snowshine's ATK.",
    trigger:
      'When controlled operator gets attacked and is below <b>60%</b> HP.',
    cooldown: 30,
    multipliers: [
      {
        name: 'Initial Base Treatment',
        value: 386,
        isPercent: false
      },
      {
        name: 'Initial Treatment Multiplier',
        value: 10.5,
        isPercent: true
      },
      {
        name: 'Base Treatment Over Time',
        value: 97,
        isPercent: false
      },
      {
        name: 'Treatment Over Time Multiplier',
        value: 2.62,
        isPercent: true
      },
      {
        name: 'Treatment Over Time Interval (s)',
        value: 0.5,
        isPercent: false
      },
      {
        name: 'Duration (s)',
        value: 3,
        isPercent: false
      }
    ]
  },
  Snowshine_Ultimate: {
    name: 'Frigid Quake',
    description:
      'Leaps into mid-air with a jetpack before slamming down with the shield to deal massive Cryo DMG and <u>[20 Stagger]</u> to enemies in the area of effect. Then generates a Freeze Zone that lasts for several seconds that deals Cryo Damage over time to enemies.<br/>The Freeze Zone also deals Cryo Infliction to enemies within it if they have no other Arts Inflictions. ',
    cost: 100,
    multipliers: [
      {
        name: 'Explosion DMG Multiplier',
        value: 709,
        isPercent: true
      },
      {
        name: 'DMG Over Time Multiplier',
        value: 71,
        isPercent: true
      },
      {
        name: 'DMG Over Time Interval (s)',
        value: 0.5,
        isPercent: false
      },
      {
        name: 'Duration (s)',
        value: 5,
        isPercent: false
      }
    ]
  },
  Perlica_Basic: {
    name: 'Protocol α: Breach',
    description_basic:
      '<b>4</b>-sequence attack that deals Electric DMG. As the controlled operator, Final Strike also deals <u>[15 Stagger]</u>.',
    description_dive:
      'Basic attack performed in mid-air becomes a dive attack that deals Electric DMG to nearby enemies.',
    description_dodge:
      'Basic attack performed right after a dodge becomes a dodge attack that deals Electric DMG.',
    description_finisher:
      'Basic attack performed near a Staggered enemy becomes a finisher attack that deals massive Electric DMG to the said enemy and restores some SP.',
    multi_basic: [57, 76, 95, 127],
    multi_dodge: 96,
    multi_finisher: 563,
    multi_dive: 124
  },
  Perlica_Skill: {
    name: 'Protocol ω: Strike',
    description:
      'Calls down a bolt of electro-magnetic energy that deals Electric DMG, <u>[10 Stagger]</u>, and Electric Infliction to enemies in a small AOE.',
    cost: 100,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 495,
        isPercent: true
      }
    ]
  },
  Perlica_Combo: {
    name: 'Instant Protocol: Chain',
    description:
      'Performs <b>1</b> attack at the target to deal Electric DMG and <u>[10 Stagger]</u> and inflicts temporary Electrification.',
    trigger: 'When controlled operator performs Final Strike on any enemy.',
    cooldown: 30,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 180,
        isPercent: true
      },
      {
        name: 'Electrify Duration (s)',
        value: 5,
        isPercent: false
      }
    ]
  },
  Perlica_Ultimate: {
    name: 'Protocol ε: 70.41K',
    description:
      'Tele-protocols a massive piece of orbital debris into the battlefield with devastating impact. All enemies hit suffer massive Electric DMG and <u>[20 Stagger]</u>.',
    cost: 100,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 1000,
        isPercent: true
      }
    ]
  },
  Laevatain_Basic: {
    name: 'Sviga Lævi',
    description_basic:
      '<b>5</b>-sequence slash that deals Heat DMG. As the controlled operator, Final Strike also deals <u>[27 Stagger]</u>.',
    description_dive:
      'Basic attack performed in mid-air becomes a dive attack that deals Heat DMG to nearby enemies.',
    description_dodge:
      'Basic attack performed right after a dodge becomes a dodge attack that deals Heat DMG.',
    description_finisher:
      'Basic attack performed near a Staggered enemy becomes a finisher attack that deals massive Heat DMG to the said enemy restores some SP.',
    multi_basic: [64, 75, 107, 118, 160],
    multi_dodge: 132,
    multi_finisher: 563,
    multi_dive: 124
  },
  Laevatain_Skill: {
    name: 'Blade of Conflagration',
    description:
      'Makes fire erupt from the blade and unleashes multiple pillars of fire after a short casting process. Deal Heat DMG, <u>[10 Stagger]</u>, and Heat Infliction to all enemies within a medium area of effect. If an enemy hit by this skill is quickly defeated, restore <b>50</b> SP (this effect can only trigger <b>1</b> time per casting of this skill).',
    cost: 100,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 900,
        isPercent: true
      }
    ]
  },
  Laevatain_Combo: {
    name: 'Smouldering Thorns',
    description:
      'Unleashes the Burning Thorn that incinerates the enemy to deal Heat DMG, <u>[10 Stagger]</u>, and <b>15s</b> of Heat RES reduction.',
    trigger: 'When Combustion is dealt to the enemy.',
    cooldown: 27,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 909,
        isPercent: true
      },
      {
        name: 'Heat RES Reduction',
        value: 20,
        isPercent: true
      }
    ]
  },
  Laevatain_Ultimate: {
    name: 'Ring of Twilight',
    description:
      'Summons a giant blade of fire and makes Laevatain the controlled operator. Enhances basic attack (BATK) for <b>15s</b> with the giant blade striking together with the operator. Each BATK sequence deals more Heat DMG while the <b>2nd</b> and <b>4th</b> BATK sequences also deal Heat Infliction.',
    cost: 100,
    multipliers: [
      {
        name: 'Enhanced BATK SEQ 1 Multiplier',
        value: 152,
        isPercent: true
      },
      {
        name: 'Enhanced BATK SEQ 2 Multiplier',
        value: 182,
        isPercent: true
      },
      {
        name: 'Enhanced BATK SEQ 3 Multiplier',
        value: 273,
        isPercent: true
      },
      {
        name: 'Enhanced BATK SEQ 4 Multiplier',
        value: 405,
        isPercent: true
      }
    ]
  },
  Avywenna_Basic: {
    name: 'Thunderlance: Blitz',
    description_basic:
      '<b>5</b>-sequence attack that deals Physical DMG. As the controlled operator, Final Strike also deals <u>[20 Stagger]</u>.',
    description_dive:
      'Basic attack performed in mid- air becomes a dive attack that deals Physical DMG.',
    description_dodge:
      'Basic attack performed right after a dodge becomes a dodge attack that deals <b>2</b> hits of Physical DMG.',
    description_finisher:
      'Basic attack performed near a Staggered enemy becomes a finisher attack that deals massive Physical DMG to the said enemy and restores some SP.',
    multi_basic: [58, 62, 52, 82, 110],
    multi_dodge: 111,
    multi_finisher: 563,
    multi_dive: 124
  },
  Avywenna_Skill: {
    name: 'Thunderlance: Interdiction',
    description:
      'Leaps into mid-air and stirs up a maelstrom with the lance, dealing some Electric DMG and <u>[5 Stagger]</u> to nearby enemies. Skill also retrieves all Thunderlances. During retrieval, Thunderlances deal Electric DMG and <u>[5 Stagger]</u> to all enemies hit by them, while Thunderlances EX deal even more Electric DMG, <u>[20 Stagger]</u>, and Electric Infliction.',
    cost: 100,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 170,
        isPercent: true
      },
      {
        name: 'Thunderlance DMG Multiplier',
        value: 280,
        isPercent: true
      },
      {
        name: 'Thunderlance EX DMG Multiplier',
        value: 600,
        isPercent: true
      }
    ]
  },
  Avywenna_Combo: {
    name: 'Thunderlance: Strike',
    description:
      'Leaps into mid-air and throws <b>3</b> Thunderlances at the target, then deal <b>1</b> hit of Electric DMG and <u>[10 Stagger]</u> to enemies in an area of effect in front of the operator.',
    trigger:
      'When controlled operator performs Final Strike on a target  with active Electric Infliction or Electrification.',
    cooldown: 10,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 356,
        isPercent: true
      }
    ]
  },
  Avywenna_Ultimate: {
    name: 'Thunderlance: Final Shock',
    description:
      'Throws a Thunderlance EX at the target area that deals massive Electric DMG, <u>[20 Stagger]</u>, and temporary Electric RES debuff to all enemies nearby.',
    cost: 100,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 780,
        isPercent: true
      },
      {
        name: 'Electric RES Reduction',
        value: 40,
        isPercent: true
      },
      {
        name: 'RES Reduction Duration (s)',
        value: 8,
        isPercent: false
      }
    ]
  },
  Yvonne_Basic: {
    name: 'Exuberant Trigger',
    description_basic:
      '<b>5</b>-sequence pistol firing that deals Cryo DMG. As the controlled operator, Final Strike also deals <u>[18 Stagger]</u>',
    description_dive:
      'Basic attack performed in mid- air becomes a dive attack that deals Cryo DMG to nearby enemies.',
    description_dodge:
      'Basic attack performed right after a dodge becomes a dodge attack that deals Cryo DMG.',
    description_finisher:
      'Basic attack performed near a Staggered enemy becomes a finisher attack that deals massive Cryo DMG to the said enemy and restores some SP.',
    multi_basic: [61, 70, 75, 97, 133],
    multi_dodge: 105,
    multi_finisher: 563,
    multi_dive: 124
  },
  Yvonne_Skill: {
    name: 'Chilly Bullet β',
    description:
      'Fires an ice bullet that explodes upon hitting an enemy to deal Cryo DMG, <u>[10 Stagger]</u>, and Cryo Infliction to nearby enemies.',
    cost: 100,
    multipliers: [
      {
        name: 'DMG Multiplier',
        value: 460,
        isPercent: true
      }
    ]
  },
  Yvonne_Combo: {
    name: 'Flashfreezer u37',
    description:
      'Immediately deploys a Flashfreezer unit with a <b>3s</b> lifespan next to the target that constantly releases shockwaves that deal Cryo DMG to nearby enemies. Flashfreezer explodes upon expiration and deals Cryo DMG, <u>[10 Stagger]</u>, and instant Solidification to enemies with active Cryo Infliction.',
    trigger:
      'When the controlled operator performs Final Strike on enemies with active Cryo Infliction or Solidification. ',
    cooldown: 30,
    multipliers: [
      {
        name: 'Shock Wave DMG Multiplier',
        value: 180,
        isPercent: true
      },
      {
        name: 'Explosion DMG Multiplier',
        value: 450,
        isPercent: true
      }
    ]
  },
  Yvonne_Ultimate: {
    name: 'Glacial Killjoy',
    description:
      "Immediately deploys a Support Bot and makes Yvonne the controlled operator. Enhances Yvonne's basic attack (BATK) for a short duration. Each BATK sequence increases the DMG dealt by her BATK (max stacks: <b>10</b>; duration: <b>3s</b>).<br/>At max stacks, Yvonne also gains a Critical Rate buff. When the skill is about to expire, Yvonne's last BATK becomes a powered attack that deals massive Cryo DMG and Cryo Infliction.",
    cost: 150,
    multipliers: [
      {
        name: 'Duration (s)',
        value: 12,
        isPercent: false
      },
      {
        name: 'BATK DMG Multiplier',
        value: 14.6,
        isPercent: true
      },
      {
        name: 'Powered ATK DMG Multiplier',
        value: 304,
        isPercent: true
      },
      {
        name: 'BATK DMG Increase per Stack',
        value: 20,
        isPercent: true
      },
      {
        name: 'Critical Rate increase at max Stack',
        value: 30,
        isPercent: true
      }
    ]
  }
};
