import React, { useEffect, useReducer, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import './characters.scss';
import '../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, Link } from 'gatsby';
import { IAKECharacter } from '../../modules/common/model/graphql-types';
import lodash from 'lodash';
import {
  faAsterisk,
  faSortAlphaUp,
  faSortAmountUp
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Col, Row, Table } from 'react-bootstrap';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { AkeCharacter } from '../../modules/endfield/components/endfield-character';

interface IAKECharacterNodes {
  nodes: IAKECharacter[];
}

interface IAKECharacterEntry {
  allCharacters: IAKECharacterNodes;
}

interface IProps {
  data: IAKECharacterEntry;
}

const EndfieldCharsPageStats: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '4✦', value: '4' },
          { label: '5✦', value: '5' },
          { label: '6✦', value: '6' }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Caster',
            tooltip: 'Caster',
            image: (
              <StaticImage
                src="../../images/endfield/icons/class_caster.webp"
                width={24}
                alt="Caster"
              />
            )
          },
          {
            value: 'Defender',
            tooltip: 'Defender',
            image: (
              <StaticImage
                src="../../images/endfield/icons/class_defender.webp"
                width={24}
                alt="Defender"
              />
            )
          },
          {
            value: 'Guard',
            tooltip: 'Guard',
            image: (
              <StaticImage
                src="../../images/endfield/icons/class_guard.webp"
                width={24}
                alt="Guard"
              />
            )
          },
          {
            value: 'Specialist',
            tooltip: 'Specialist',
            image: (
              <StaticImage
                src="../../images/endfield/icons/class_specialist.webp"
                width={24}
                alt="Specialist"
              />
            )
          },
          {
            value: 'Support',
            tooltip: 'Support',
            image: (
              <StaticImage
                src="../../images/endfield/icons/class_support.webp"
                width={24}
                alt="Support"
              />
            )
          }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Physical',
            tooltip: 'Physical',
            image: (
              <StaticImage
                src="../../images/endfield/icons/element_physical.webp"
                width={24}
                alt="Physical"
              />
            )
          },
          {
            value: 'Cryo',
            tooltip: 'Cryo',
            image: (
              <StaticImage
                src="../../images/endfield/icons/element_cryo.webp"
                width={24}
                alt="Cryo"
              />
            )
          },
          {
            value: 'Electric',
            tooltip: 'Electric',
            image: (
              <StaticImage
                src="../../images/endfield/icons/element_electric.webp"
                width={24}
                alt="Electric"
              />
            )
          },
          {
            value: 'Heat',
            tooltip: 'Heat',
            image: (
              <StaticImage
                src="../../images/endfield/icons/element_heat.webp"
                width={24}
                alt="Heat"
              />
            )
          },
          {
            value: 'Nature',
            tooltip: 'Nature',
            image: (
              <StaticImage
                src="../../images/endfield/icons/element_nature.webp"
                width={24}
                alt="Nature"
              />
            )
          }
        ]
      },
      {
        key: 'weapon',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Great Sword',
            tooltip: 'Great Sword',
            image: (
              <StaticImage
                src="../../images/endfield/icons/weapon_greatsword.webp"
                width={24}
                alt="Great Sword"
              />
            )
          },
          {
            value: 'Guns',
            tooltip: 'Guns',
            image: (
              <StaticImage
                src="../../images/endfield/icons/weapon_guns.webp"
                width={24}
                alt="Great Sword"
              />
            )
          },
          {
            value: 'Orbiters',
            tooltip: 'Orbiters',
            image: (
              <StaticImage
                src="../../images/endfield/icons/weapon_orbiter.webp"
                width={24}
                alt="Orbiters"
              />
            )
          },
          {
            value: 'Polearm',
            tooltip: 'Polearm',
            image: (
              <StaticImage
                src="../../images/endfield/icons/weapon_polearm.webp"
                width={24}
                alt="Polearm"
              />
            )
          },
          {
            value: 'Sword',
            tooltip: 'Sword',
            image: (
              <StaticImage
                src="../../images/endfield/icons/weapon_sword.webp"
                width={24}
                alt="Sword"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.element.toLowerCase() === activeFilters.element.toLowerCase()
        );
      }

      if (activeFilters.weapon && activeFilters.weapon !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.weapon.toLowerCase() === activeFilters.weapon.toLowerCase()
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.slug > b.slug ? 1 : -1));
    } else if (sortOption === 'stats_str') {
      filtered = filtered.sort((a, b) =>
        a.attributes.str < b.attributes.str ? 1 : -1
      );
    } else if (sortOption === 'stats_agi') {
      filtered = filtered.sort((a, b) =>
        a.attributes.agi < b.attributes.agi ? 1 : -1
      );
    } else if (sortOption === 'stats_int') {
      filtered = filtered.sort((a, b) =>
        a.attributes.int < b.attributes.int ? 1 : -1
      );
    } else if (sortOption === 'stats_will') {
      filtered = filtered.sort((a, b) =>
        a.attributes.will < b.attributes.will ? 1 : -1
      );
    } else if (sortOption === 'stats_hp') {
      filtered = filtered.sort((a, b) =>
        a.attributes.hp < b.attributes.hp ? 1 : -1
      );
    } else if (sortOption === 'stats_atk') {
      filtered = filtered.sort((a, b) =>
        a.attributes.atk < b.attributes.atk ? 1 : -1
      );
    }

    setVisibleCharacters(filtered);
    forceUpdate();
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: 'stats_hp', label: 'Sort by HP' },
    { value: 'stats_atk', label: 'Sort by ATK' },
    { value: 'stats_str', label: 'Sort by STR' },
    { value: 'stats_agi', label: 'Sort by AGI' },
    { value: 'stats_int', label: 'Sort by INT' },
    { value: 'stats_will', label: 'Sort by WILL' }
  ];

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }
  return (
    <DashboardLayout
      className={'generic-page endfield characters-ake character-stats-ag ake'}
      game="endfield"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/arknights-endfield/">Arknights: Endfield</Link>
        </li>
        <li className="divider">/</li>
        <li>Characters Attributes</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/endfield/categories/category_stats.jpg"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Arknights: Endfield Characters Attributes</h1>
          <h2>
            Compare the stats and attributes of all characters available in
            Arknights: Endfield.
          </h2>
          <p>
            Last updated: <strong>20/01/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Characters"
        subtitle={
          <span className="additional-text">
            Showing {visibleCharacters.length} Character
            {visibleCharacters.length !== 1 && 's'}
          </span>
        }
      />
      <Alert variant="primary">
        <p>
          <strong>Important!</strong> The base HP is the same for everyone as it
          doesn't include the bonus the character gets from STR - this will be
          included there soon! Same with the impact of Primary and Secondary
          Attributes on the character's base ATK.
        </p>
      </Alert>
      <div className="employees-filter-bar ake">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <Row>
        <Col>
          <Table className="stats-table" striped bordered responsive>
            <thead>
              <tr>
                <th
                  scope="col"
                  className="name"
                  onClick={() => setSortOption('+fullName')}
                >
                  Name
                  {sortOption && sortOption === '+fullName' && (
                    <span className="icon">
                      <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                    </span>
                  )}
                </th>
                <th
                  scope="col"
                  className="stat"
                  onClick={() => setSortOption('stats_hp')}
                >
                  <span>HP</span>
                  {sortOption && sortOption === 'stats_hp' && (
                    <span className="icon">
                      <FontAwesomeIcon icon={faSortAmountUp} width="18" />
                    </span>
                  )}
                </th>
                <th
                  scope="col"
                  className="stat"
                  onClick={() => setSortOption('stats_atk')}
                >
                  <span>ATK</span>
                  {sortOption && sortOption === 'stats_atk' && (
                    <span className="icon">
                      <FontAwesomeIcon icon={faSortAmountUp} width="18" />
                    </span>
                  )}
                </th>
                <th
                  scope="col"
                  className="stat"
                  onClick={() => setSortOption('stats_str')}
                >
                  <span>STR</span>
                  {sortOption && sortOption === 'stats_str' && (
                    <span className="icon">
                      <FontAwesomeIcon icon={faSortAmountUp} width="18" />
                    </span>
                  )}
                </th>
                <th
                  scope="col"
                  className="stat"
                  onClick={() => setSortOption('stats_agi')}
                >
                  <span>AGI</span>
                  {sortOption && sortOption === 'stats_agi' && (
                    <span className="icon">
                      <FontAwesomeIcon icon={faSortAmountUp} width="18" />
                    </span>
                  )}
                </th>
                <th
                  scope="col"
                  className="stat"
                  onClick={() => setSortOption('stats_int')}
                >
                  <span>INT</span>
                  {sortOption && sortOption === 'stats_int' && (
                    <span className="icon">
                      <FontAwesomeIcon icon={faSortAmountUp} width="18" />
                    </span>
                  )}
                </th>
                <th
                  scope="col"
                  className="stat"
                  onClick={() => setSortOption('stats_will')}
                >
                  <span>WILL</span>
                  {sortOption && sortOption === 'stats_will' && (
                    <span className="icon">
                      <FontAwesomeIcon icon={faSortAmountUp} width="18" />
                    </span>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {visibleCharacters.map((emp, index) => (
                <tr key={index}>
                  <th scope="row" className="char">
                    <AkeCharacter slug={emp.slug} mode="inline" enablePopover />
                  </th>
                  <td className="stat">
                    {emp.attributes.hp && <>{Math.floor(emp.attributes.hp)}</>}
                  </td>
                  <td className="stat">
                    {emp.attributes.atk && (
                      <>{Math.floor(emp.attributes.atk)}</>
                    )}
                  </td>
                  <td className="stat">
                    {emp.attributes.str && (
                      <>{Math.floor(emp.attributes.str)}</>
                    )}
                  </td>
                  <td className="stat">
                    {emp.attributes.agi && (
                      <>{Math.floor(emp.attributes.agi)}</>
                    )}
                  </td>
                  <td className="stat">
                    {emp.attributes.int && (
                      <>{Math.floor(emp.attributes.int)}</>
                    )}
                  </td>
                  <td className="stat">
                    {emp.attributes.will && (
                      <>{Math.floor(emp.attributes.will)}</>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../images/endfield/no_results.webp"
            alt="No results"
          />
          <p>No characters found. Try changing your filter.</p>
        </div>
      )}
      <br /> <br />
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default EndfieldCharsPageStats;

export const Head: React.FC = () => (
  <Seo
    title="Characters Attributes | Arknights: Endfield | Prydwen Institute"
    description="Compare the stats and attributes of all characters available in Arknights: Endfield."
    game="endfield"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulAkeCharacter(
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        rarity
        class
        element
        weapon
        attributes {
          hp
          agi
          atk
          int
          str
          will
        }
      }
    }
  }
`;
