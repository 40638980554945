import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const SoloGuides: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page solo'} game="solo">
      <ul className="breadcrumb">
        <li>
          <Link to="/solo-leveling/">Solo Leveling: Arise</Link>
        </li>
        <li className="divider">/</li>
        <li>Guides</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/solo/categories/category_guides.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Solo Leveling: Arise Guides</h1>
          <h2>
            Best guides for Solo Leveling: Arise that will help you understand
            the game better
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Newbie Zone" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/solo-leveling/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../../images/solo/categories/category_intro.jpg"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Reroll guide"
          link="/solo-leveling/guides/reroll"
          image={
            <StaticImage
              src="../../../images/solo/categories/category_reroll.jpg"
              alt="Reroll guide"
            />
          }
          updated
        />
        <CategoryCard
          title="Beginner guide"
          link="/solo-leveling/guides/beginner-guide"
          image={
            <StaticImage
              src="../../../images/solo/categories/category_beginner.jpg"
              alt="Reroll guide"
            />
          }
        />
        <CategoryCard
          title="Best skills for Jinwoo"
          link="/solo-leveling/guides/jinwoo-skills"
          image={
            <StaticImage
              src="../../../images/solo/categories/category_skill.jpg"
              alt="Best skills for Jinwoo"
            />
          }
          updated
        />
        <CategoryCard
          title="Best stats and gear for Jinwoo"
          link="/solo-leveling/guides/jinwoo-stats"
          image={
            <StaticImage
              src="../../../images/solo/categories/category_stats.jpg"
              alt="Best stats for Jinwoo"
            />
          }
          updated
        />
        <CategoryCard
          title="Jinwoo's Best Blessing Stones"
          link="/solo-leveling/guides/jinwoos-best-blessing-stones"
          image={
            <StaticImage
              src="../../../images/solo/categories/category_blessing.webp"
              alt="Guides"
            />
          }
        />
        <CategoryCard
          title="Battlefield of Chaos"
          link="/solo-leveling/guides/battlefield-of-chaos"
          image={
            <StaticImage
              src="../../../images/solo/categories/category_boc.jpg"
              alt="Battlefield of Chaos"
            />
          }
        />
        <CategoryCard
          title="Workshop of Brilliant Light"
          link="/solo-leveling/guides/workshop-of-brilliant-light"
          image={
            <StaticImage
              src="../../../images/solo/categories/category_wobl.webp"
              alt="Guides"
            />
          }
        />
        <CategoryCard
          title="WOBL: Upper Floors"
          link="/solo-leveling/guides/wobl-upper-floors"
          image={
            <StaticImage
              src="../../../images/solo/categories/category_wobl_upper.webp"
              alt="Guides"
            />
          }
        />
        <CategoryCard
          title="WOBL: Deimos"
          link="/solo-leveling/guides/wobl-deimos"
          image={
            <StaticImage
              src="../../../images/solo/categories/category_deimos.webp"
              alt="Guides"
            />
          }
          isNew
        />
        <CategoryCard
          title="Simulation Gate"
          link="/solo-leveling/guides/simulation-gate"
          image={
            <StaticImage
              src="../../../images/solo/categories/category_sim.webp"
              alt="Guides"
            />
          }
          isNew
        />
        <CategoryCard
          title="Core System"
          link="/solo-leveling/guides/core-review"
          image={
            <StaticImage
              src="../../../images/solo/categories/category_core.webp"
              alt="Core"
            />
          }
          updated
        />
        <CategoryCard
          title="Artifact Dungeons"
          link="/solo-leveling/guides/artifact-dungeons"
          image={
            <StaticImage
              src="../../../images/solo/categories/category_ad.webp"
              alt="Guides"
            />
          }
        />
        <CategoryCard
          title="Guilds and Guild Boss"
          link="/solo-leveling/guides/guilds-and-guild-boss"
          image={
            <StaticImage
              src="../../../images/solo/categories/category_guild.webp"
              alt="Guides"
            />
          }
        />
      </Row>
      <SectionHeader title="Database" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Artifact Sets"
          link="/solo-leveling/guides/artifact-sets"
          image={
            <StaticImage
              src="../../../images/solo/categories/category_arti.png"
              alt="Artifact Sets"
            />
          }
        />
        <CategoryCard
          title="Artifact Stats"
          link="/solo-leveling/guides/artifact-stats"
          image={
            <StaticImage
              src="../../../images/solo/categories/category_arti2.png"
              alt="Artifact Stats"
            />
          }
        />
        <CategoryCard
          title="Characters list"
          link="/solo-leveling/characters"
          image={
            <StaticImage
              src="../../../images/solo/categories/category_characters.jpg"
              alt="Characters"
            />
          }
        />
      </Row>
    </DashboardLayout>
  );
};

export default SoloGuides;

export const Head: React.FC = () => (
  <Seo
    title="Guides | Solo Leveling: Arise | Prydwen Institute"
    description="Best guides for Solo Leveling: Arise that will help you understand the game better."
    game="solo"
  />
);
