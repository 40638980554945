import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { Col, Row } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const AFKGuidesDreamSkyclops: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Dream Realm - Skyclops</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_skyclops.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Skyclops</h1>
          <h2>
            Dream Realm Skyclops guide that explains the boss mechanics and how
            to beat it!
          </h2>
          <p>
            Last updated: <strong>11/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Skyclops" />
        <StaticImage
          src="../../../images/afk/generic/boss_skyclops.webp"
          alt="Guides"
        />
        <p>
          <strong>Skyclops</strong> is one of the 8 available Dream Realm bosses
          you will encounter in AFK Journey. In this guide we will go over the
          boss mechanics and the best characters and teams to use against him.
        </p>
        <SectionHeader title="What’s the meta team?" />
        <p>
          Realistically speaking,{' '}
          <strong>
            your meta team will depend on your specific box, and your account
            focus
          </strong>
          , so use your highest rarity DPS carry characters, along with whoever
          can best boost their damage.{' '}
          <strong className="gold">
            Try running Dual/Triple carry, as currently there is no way to boost
            a Hypercarry’s damage high enough to make it worth running only one
          </strong>
          . Keep faction bonus in mind but don’t run suboptimal characters just
          to force it.
        </p>
        <p>
          Still, below you can find a few example teams. Big thanks to{' '}
          <StaticImage
            src="../../../images/afk/icons/afkanalytica.png"
            alt="AFK Analytica"
          />{' '}
          <strong className="yellow">AFK Analytica</strong> for the team
          building tool. You can find their website{' '}
          <a
            href="https://www.afkanalytica.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and their discord{' '}
          <a
            href="https://www.discord.gg/analytica"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <SectionHeader title="Season 2 Teams (Endless)" />
        <p>
          Skyclops Dream Realm boss was available in Season 2 and he was removed
          in Season 3 from the rotation.
        </p>
        <SectionHeader title="Boss Mechanics" />
        <p>
          At the start of the battle, Skyclops will designate the player’s{' '}
          <strong>closest character as the “Tank”</strong> and will keep
          attacking that character, pausing to summon 4 minions from time to
          time, or to use a sweeping laser attack that covers the entire area.
          In the early game, this tank can be any traditional frontline tank
          character. But this does not contribute much to your damage so you
          might want to switch to a more aggressive strategy!
        </p>
        <p>
          When Skyclops summons his minions, he will gain{' '}
          <strong>90% physical damage resist or 90% magic damage resist</strong>
          , alternating between them each time, making characters who deal true
          damage all the more valuable here.
        </p>
        <p>
          In the mid to late game, it is prudent to let{' '}
          <AFKCharacter mode="inline" slug="smokey-and-meerky" enablePopover />{' '}
          or <AFKCharacter mode="inline" slug="kruger" enablePopover /> tank
          this boss as long as they can survive for the duration of the
          encounter, because this enables you to run as many damage dealers and
          buffers/debuffers as possible in order to maximize damage. Be sure to
          kill mobs as soon as they spawn in order to keep your tank alive.
          Characters who can protect your tank or who have inherent AOE or kill
          chaining abilities are great here.
        </p>
        <p>
          You will want constant healing in order to deal with the dangerous
          sweeping laser attacks, which will be the main reason for party wipes
          in the early stages of the game before you can get to the end of the
          endless mode timer.
        </p>
        <p>
          This stage does not feature skill channeling interruption, so{' '}
          <AFKCharacter mode="inline" slug="smokey-and-meerky" enablePopover />{' '}
          is king here as they are able to keep buffing the team throughout the
          entire fight with little trouble.
        </p>
        <SectionHeader title="Action Summary" />
        <p>
          Skyclops takes about <strong>38-39</strong> actions in the span of 90
          seconds (the full length of the battle - if you survive through all of
          it that is). Here's a list of all possible actions:
        </p>
        <ul>
          <li>melee attack (up to 9 times per cycle)</li>
          <li>ranged attack (up to 9 times per cycle)</li>
          <li>summon 4 minions (3 or 4 times total)</li>
          <li>sweeping laser attack (4x)</li>
        </ul>
        <SectionHeader title="Videos" />
        <p>For more team ideas, check this video:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="WCB1GTZLetI" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesDreamSkyclops;

export const Head: React.FC = () => (
  <Seo
    title="Skyclops | AFK Journey | Prydwen Institute"
    description="Dream Realm Skyclops guide that explains the boss mechanics and how to beat it!"
    game="afk"
  />
);
