import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';

const AFKGuidesArti: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Artifacts - Season 3</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_arti_s.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Artifacts guide</h1>
          <h2>List of Artifacts available in the Seasons 3 of AFK Journey.</h2>
          <p>
            Last updated: <strong>16/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Artifacts - Season 3" />
        <p>
          Season 3 introduces 12 new Artifacts that are more specialized than
          the base game ones. Below you will find a list of them all:
        </p>
        <div className="artifacts">
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/s3_lightforge.webp"
                alt="Guides"
              />
            </div>
            <h5>Lightforge Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                Blesses the rearmost ally when a battle starts, lasting for 15s.
                The blessed ally becomes unaffected and gains 24% increased ATK
                and 15 Life Drain. Upon assisting or defeating 1 enemy, they
                will gain extra stat boost of equal amount and extend the
                blessing duration by 10s.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> Increases the ally’s ATK by 28%, and
                  Life Drain by 18.
                </li>
                <li>
                  <strong>At +10:</strong> Increases the ally’s ATK by 32%, and
                  Life Drain by 21.
                </li>
                <li>
                  <strong>At +15:</strong> Increases the ally’s ATK by 36%, and
                  Life Drain by 24.
                </li>
                <li>
                  <strong>At +20:</strong> If the blessed ally assists or
                  defeats 2 enemies while the blessing lasts, their Phys DEF and
                  Magic DEF are further increased by 30%, and the blessing
                  duration is extended by 10s.
                </li>
                <li>
                  <strong>At +25:</strong> Increases the ally’s ATK by 40%, and
                  Life Drain by 27.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats</p>
              <ul>
                <li>ATK</li>
                <li>Phys & Magic DEF</li>
                <li>Execution</li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Season Milestones</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/s3_overcharge.webp"
                alt="Guides"
              />
            </div>
            <h5>Overcharge Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                Blesses the rearmost ally when a battle starts, recovering 2% of
                max HP and 30 Energy for the ally every second for 10s. When the
                blessed ally casts their Ultimate for the first time, the
                blessing duration will extend by 10s, during which they become
                unaffected.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> Increases the blessed ally’s HP
                  recovered per second to 2.5%, Energy recovered per second to
                  35.
                </li>
                <li>
                  <strong>At +10:</strong> Increases the blessed ally’s HP
                  recovered per second to 3%, Energy recovered per second to 40.
                </li>
                <li>
                  <strong>At +15:</strong> Increases the blessed ally’s HP
                  recovered per second to 3.5%, Energy recovered per second to
                  45.
                </li>
                <li>
                  <strong>At +20:</strong> Increases ATK by 30% when the blessed
                  ally casts their Ultimate for the first time. This effect
                  lasts until the blessing effect ends.
                </li>
                <li>
                  <strong>At +25:</strong> Increases the blessed ally’s HP
                  recovered per second to 4%, Energy recovered per second to 50.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats</p>
              <ul>
                <li>ATK</li>
                <li>HP</li>
                <li>Haste</li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Season Milestones</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/s3_soulbound.webp"
                alt="Guides"
              />
            </div>
            <h5>Soulbound Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                Links the frontmost and rearmost heroes when a battle starts.
                When the frontmost hero gains Energy, the rearmost hero recovers
                20% of that Energy. When the rearmost hero deals damage, the
                frontmost hero recovers HP equal to 20% of the damage dealt.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> Increases Energy ratio recovery to
                  24%, and HP ratio recovery to 24%.
                </li>
                <li>
                  <strong>At +10:</strong> Increases Energy ratio recovery to
                  28%, and HP ratio recovery to 28%.
                </li>
                <li>
                  <strong>At +15:</strong> Increases Energy ratio recovery to
                  32%, and HP ratio recovery to 32%.
                </li>
                <li>
                  <strong>At +20:</strong> When the linked rearmost hero assists
                  or defeats an enemy, the linked frontmost hero recovers 30% of
                  their max HP.
                </li>
                <li>
                  <strong>At +25:</strong> Increases Energy ratio recovery to
                  36%, and HP ratio recovery to 36%.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats</p>
              <ul>
                <li>ATK SPD</li>
                <li>Phys & Magic DEF</li>
                <li>Vitality</li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Season Milestones</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/s3_banishing.webp"
                alt="Guides"
              />
            </div>
            <h5>Banishing Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                Teleports the frontmost enemy to another dimension every 10s.
                After 0.5s, teleports them to the farthest available tile on the
                enemy’s side of the battlefield and immobilizes them for 4s.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> Increases the immobilize duration to
                  4.5s.
                </li>
                <li>
                  <strong>At +10:</strong> Increases the immobilize duration to
                  5s.
                </li>
                <li>
                  <strong>At +15:</strong> Increases the immobilize duration to
                  5.5s.
                </li>
                <li>
                  <strong>At +20:</strong> Immobilizes the rearmost enemy every
                  time this skill is cast, reducing their ATK by 30% while
                  immobilized.
                </li>
                <li>
                  <strong>At +25:</strong> Increases the immobilize duration to
                  6s.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats</p>
              <ul>
                <li>ATK</li>
                <li>Crit</li>
                <li>HP</li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Season Milestones</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/s3_snowman.webp"
                alt="Guides"
              />
            </div>
            <h5>Snowman Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                {' '}
                3s into a battle, summons a snowman every 16s within the most
                crowded area, lasting for 5s and taunting enemies within 1 tile
                for 5s. The snowman cannot heal and has a HP equal to 200% of
                Team ATK, along with Phys DEF and Magic DEF equal to 6% of the
                Team ATK.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> Reduces skill cooldown to 15s.
                </li>
                <li>
                  <strong>At +10:</strong> Reduces skill cooldown to 14s.
                </li>
                <li>
                  <strong>At +15:</strong> Reduces skill cooldown to 13s.
                </li>
                <li>
                  <strong>At +20:</strong> The snowman explodes before it
                  vanishes, dealing magic damage equal to 30% of Team ATK to
                  adjacent enemies and reducing their Haste by 30 for 5s.
                </li>
                <li>
                  <strong>At +25:</strong> Reduces skill cooldown to 12s.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats</p>
              <ul>
                <li>ATK</li>
                <li>HP</li>
                <li>Phys & Magic DEF</li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Story Quests</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/s3_bladesummon.webp"
                alt="Guides"
              />
            </div>
            <h5>Bladesummon Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                Summons a portal nearby whenever the allied team deals a
                cumulative ranged damage equal to 500%/1200%/2000% of the
                initial Team ATK. Every 2s, the portal launches a flying sword
                at the frontmost enemy, dealing true damage equal to 6% of Team
                ATK and reducing their Phys DEF and Magic DEF by 10% for 2s. The
                flying swords can reduce the target’s Phys DEF and Magic DEF by
                up to 30%.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> Increases damage dealt by flying
                  swords to 6.5% of Team ATK.
                </li>
                <li>
                  <strong>At +10:</strong> Increases damage dealt by flying
                  swords to 7% of Team ATK.
                </li>
                <li>
                  <strong>At +15:</strong> Increases damage dealt by flying
                  swords to 7.5% of Team ATK.
                </li>
                <li>
                  <strong>At +20:</strong> Summons an extra portal when a battle
                  starts.
                </li>
                <li>
                  <strong>At +25:</strong> Increases damage dealt by flying
                  swords to 8% of Team ATK.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats</p>
              <ul>
                <li>ATK</li>
                <li>ATK SPD</li>
                <li>Phys & Magic DEF</li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Season Milestones</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/s3_sanctum.webp"
                alt="Guides"
              />
            </div>
            <h5>Sanctum Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                5s into a battle, selects 1 tile to cast a healing spell every
                20s, recovering HP equal to 8% of Team ATK per second to allies
                within 1 tile for 10s. This skill prioritizes casting in the
                most crowded area containing the weakest ally.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> Increases the HP recovery amount to 9%
                  of Team ATK.
                </li>
                <li>
                  <strong>At +10:</strong> Increases the HP recovery amount to
                  10% of Team ATK.
                </li>
                <li>
                  <strong>At +15:</strong> Increases the HP recovery amount to
                  11% of Team ATK.
                </li>
                <li>
                  <strong>At +20:</strong> Expands the radius of healing spell
                  to 2 tiles.
                </li>
                <li>
                  <strong>At +25:</strong> Increases the HP recovery amount to
                  12% of Team ATK.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats</p>
              <ul>
                <li>ATK</li>
                <li>HP</li>
                <li>Vitality</li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Season Milestones</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/s3_surging.webp"
                alt="Guides"
              />
            </div>
            <h5>Surging Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                3s into a battle, selects a 3-tile wide line that covers the
                most allies and enemies every 12s, firing a light beam towards
                the enemy side of the battlefield. The beam deals magic damage
                equal to 60% of Team ATK to all enemies within and grants a
                shield equal to 60% of Team ATK to all allies within, lasting
                for 6s.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> Increases the beam damage to 65% of
                  Team ATK, and shield value to 65% of Team ATK.
                </li>
                <li>
                  <strong>At +10:</strong> Increases the beam damage to 70% of
                  Team ATK, and shield value to 70% of Team ATK.
                </li>
                <li>
                  <strong>At +15:</strong> Increases the beam damage to 75% of
                  Team ATK, and shield value to 75% of Team ATK.
                </li>
                <li>
                  <strong>At +20:</strong> Immobilizes the rearmost enemy every
                  time this skill is cast, reducing their ATK by 30% while
                  immobilized.
                </li>
                <li>
                  <strong>At +25:</strong> Increases the beam damage to 80% of
                  Team ATK, and shield value to 80% of Team ATK.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats</p>
              <ul>
                <li>Crit</li>
                <li>Phys & Magic DEF</li>
                <li>HP</li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Season Milestones</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/s3_harmonic.webp"
                alt="Guides"
              />
            </div>
            <h5>Harmonic Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                Blesses all allies when a battle starts and every 10s
                thereafter, increasing their ATK by 18% and Phys DEF and Magic
                DEF by 25% for 5s. During this time, allies with HP ratio above
                50% gain 20 Haste, and allies with HP ratio below 50% gain 20
                Life Drain.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> Increases the allies’ ATK boost to
                  21%, and Phys DEF and Magic DEF boost to 30%.
                </li>
                <li>
                  <strong>At +10:</strong> Increases the allies’ ATK boost to
                  24%, and Phys DEF and Magic DEF boost to 35%.
                </li>
                <li>
                  <strong>At +15:</strong> Increases the allies’ ATK boost to
                  27%, and Phys DEF and Magic DEF boost to 40%.
                </li>
                <li>
                  <strong>At +20:</strong> Immobilizes the rearmost enemy every
                  time this skill is cast, reducing their ATK by 30% while
                  immobilized.
                </li>
                <li>
                  <strong>At +25:</strong> Increases the allies’ ATK boost to
                  30%, and Phys DEF and Magic DEF boost to 45%.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats</p>
              <ul>
                <li>ATK</li>
                <li>Haste</li>
                <li>Life Drain</li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Season Milestones</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/s3_frostfall.webp"
                alt="Guides"
              />
            </div>
            <h5>Frostfall Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                Summons a blizzard in the most crowded area every time 4
                cumulative Ultimates are cast by allies. The blizzard lasts for
                6s, dealing true damage equal to 8% of Team ATK to enemies
                within 1 tile each second, while reducing their Haste by 40.
                After casting this skill 3 times, the range expands to 2 tiles.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> Increases the blizzard’s damage dealt
                  per second to 9% of Team ATK, and reduces Haste by 40.
                </li>
                <li>
                  <strong>At +10:</strong> Increases the blizzard’s damage dealt
                  per second to 10% of Team ATK, and reduces Haste by 40.
                </li>
                <li>
                  <strong>At +15:</strong> Increases the blizzard’s damage dealt
                  per second to 11% of Team ATK, and reduces Haste by 40.
                </li>
                <li>
                  <strong>At +20:</strong> Casts this skill when allies have
                  cumulatively cast 3 Ultimates.
                </li>
                <li>
                  <strong>At +25:</strong> Increases the blizzard’s damage dealt
                  per second to 12% of Team ATK, and reduces Haste by 40.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats</p>
              <ul>
                <li>ATK</li>
                <li>Haste</li>
                <li>Phys & Magic DEF</li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Story Quests</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/s3_stormstrike.webp"
                alt="Guides"
              />
            </div>
            <h5>Stormstrike Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                Summons a lightning strike whenever an enemy hero cumulatively
                loses 40% of their HP, dealing magic damage equal to 60% of Team
                ATK, while reducing their Vitality by 40 and Energy on Hit by 40
                for 6s.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> Increases damage dealt by lightning
                  strike to 65% of Team ATK.
                </li>
                <li>
                  <strong>At +10:</strong> Increases damage dealt by lightning
                  strike to 70% of Team ATK.
                </li>
                <strong>At +15:</strong> Increases damage dealt by lightning
                strike to 75% of Team ATK.
                <li>
                  <strong>At +20:</strong> Deals extra damage equal to 40% of
                  target’s lost HP, capped at 80% of Team ATK.
                </li>
                <li>
                  <strong>At +25:</strong> Increases damage dealt by lightning
                  strike to 80% of Team ATK.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats</p>
              <ul>
                <li>ATK</li>
                <li>DEF Penetration</li>
                <li>HP</li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Season Milestones</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/s3_iceguard.webp"
                alt="Guides"
              />
            </div>
            <h5>Iceguard Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                When a battle starts, grants a shield that equals to 160% of
                Team ATK to the frontmost allied hero, lasting for 12s. While
                the shield is active, it creates a cold zone that affects all
                enemies within 1 tile, reducing their ATK by 20% and ATK SPD by
                20.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> Increases the shield value to 170% of
                  Team ATK.
                </li>
                <li>
                  <strong>At +10:</strong> Increases the shield value to 180% of
                  Team ATK.
                </li>
                <li>
                  <strong>At +15:</strong> Increases the shield value to 190% of
                  Team ATK.
                </li>
                <li>
                  <strong>At +20:</strong> Grants the frontmost allied hero a
                  shield every 20s thereafter, with the shield value being only
                  40% of the initial shield’s value.
                </li>
                <li>
                  <strong>At +25:</strong> Increases the shield value to 200% of
                  Team ATK.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats</p>
              <ul>
                <li>Haste</li>
                <li>Vitality</li>
                <li>Phys & Magic DEF</li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Season Milestones</strong>
            </p>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesArti;

export const Head: React.FC = () => (
  <Seo
    title="Artifacts - Season 2 & 3 | AFK Journey | Prydwen Institute"
    description="List of Artifacts available in the Seasons 2 and 3 of AFK Journey."
    game="afk"
  />
);
