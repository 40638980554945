import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import ScrollspyNav from 'react-scrollspy-nav';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Row, Col, Card } from 'react-bootstrap';
import { AshCharacter } from '../../../modules/ash/components/ash-character';
import { AshTrace } from '../../../modules/ash/components/ash-trace';

const AshGuidesSYPSkylar: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should You Pull Skylar?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_skylar.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should You Pull Skylar?</h1>
          <h2>
            A dedicated guide for Skylar that will help you decide whether to
            pull her or not.
          </h2>
          <p>
            Last updated: <strong>02/01/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Introduction" />
              <p>
                <strong>
                  Skylar is a flex character focused on enabling elemental
                  reactions and Crowd Control.
                </strong>{' '}
                One of her main utilities is reducing Lightning RES of enemies,
                which makes her best friends with Lightning carry Echomancers.
                Her versatility, however, is extended to other teams: thanks to
                her [Radiant Star] summons and low cooldowns, she can
                consistently barrage enemies and trigger Explosion,
                Electroconduction and Electrolyze depending on the team.
              </p>
              <p>
                These characteristics allow Skylar to aid the team both with her
                innate crowd control with her skill “Chirpy Storm”, or stunning
                enemies with her Seed skill “Birdies, Assemble!”. Furthermore,
                while on the debuff side she has Elemental RES shred, due to the
                nature of her kit focused on elemental reactions, Skylar can
                easily trigger many Memory Traces who grant extra buffs to the
                team.
              </p>
              <p>
                Also, thanks to being a Diamond Archetype (Tactician class),
                she's also capable of swiftly trigger several stacks of
                [Inspire] through a myriad of Memory Traces. Some examples
                include: “Born from Ashes”, “Lucky Find” and “Lawful Cage”.
              </p>
              <StaticImage
                src="../../../images/ash/pull/skylar_1.webp"
                alt="Guides"
              />
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Review" />
              <p>
                Skylar may feel a bit awkward and out of place at first. Unlike
                most characters released so far, she isn’t made to have a team
                “built” around her. Instead, as a specialist in debuffs and
                crowd-control, she’s made to fit in a myriad of teams. Almost
                every team has a character who can benefit from enemies being
                grouped, and almost any team can benefit from having waves
                constantly stunned or stuck in place. This is what Skylar is
                for, but that’s merely a small portion of her potential.
              </p>
              <p>
                Skylar is a sub-DPS who will spend most of the fight sitting in
                the back, sending her summons to act for her. She’s better
                suited to Lightning-related teams and Wet environments, where
                she can make the most of her natural 30% Lightning RES shred
                (45% if at dupe 1). She also has a bit of Water RES shred. These
                two debuffs will shine the most with the Uni + Jean combo, a
                future team comp that is perfect for Skylar.
              </p>
              <p>
                When it comes to skills, although her active skill “Chirpy
                Storm” may be tempting to spam, try to save it for key moments,
                as it only has four uses. In the meanwhile, “Starry Blast” may
                not seem like much, but it adds up over time, with Skylar being
                able to keep up to three [Radiant Star] summons at a time. This
                aspect of her kit is very interesting as it opens the
                possibility for two things: The first one is her synergy with
                the summoner team, especially with her brother Boreas joining
                the field. The second one is tied to her ultimate, “Birdies,
                Assemble!”.
              </p>
              <p>
                When using her Seed skill, Skylar will activate all [Radiant
                Stars] to deal damage alongside her ultimate, thus creating the
                possibility of a burst window. Also, the Seed Skill will reduce
                the CD of [Radiant Stars] by 50%, allowing the auto-skill
                “Starry Blast” to trigger every 7.5s. Considering that Skylar’s
                Seed skill already has a very short CD (45s) and with the right
                traces she’s able to start the battle with it ready to go, it’s
                easy to imagine how this combo can snowball with the right team
                comp and build.
              </p>
              <h5>Pros & Cons</h5>
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Versatile, can fit in many teams: Explosion, Summon,
                          Electroconduct, and anything who can benefit from
                          someone who can group enemies and deliver consistent
                          CC;
                        </li>
                        <li>Low cooldown on skills, including Seed Skill;</li>
                        <li>A rare source of Elemental RES shred;</li>
                        <li>Can stack up [Inspire] fast.</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Low personal output;</li>
                        <li>Quite squishy, be careful with her positioning;</li>
                        <li>
                          Her Elemental RES shred only makes a significant
                          different for Lightning Echomancers.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Gameplay Tips" />
              <h5>Dupes</h5>
              <p>
                Skylar offers a complete experience at Dupe 0. If you're
                focusing on teams who have Lightning Echomancers as the main
                carry (such as Jean + Uni's Electroconduct Team), Skylar's first
                dupe is worth it.
              </p>
              <h5>General Tips</h5>
              <ul>
                <li>
                  Thanks to her low cooldown on Ult, Skylar can be placed as a
                  backup in any team and brought in to deal with airborne
                  enemies, barriers and big waves comfortably.
                </li>
                <li>
                  When using “Chirpy Storm”, remember that it will draw enemies
                  to its center. Use that to your advantage and place the AoE a
                  little behind to increase the distance between the enemies and
                  your terminal, or to simply better group targets into an
                  Elemental zone.
                </li>
                <li>
                  Barriers, Block Gauges and Shields are different things.
                  Skylar’s ultimate can only delete Barriers, the “dome-like”
                  structures around certain enemies.
                  <strong>
                    <i>It will not deplete block gauges.</i>
                  </strong>{' '}
                  Shields, on the other hand, are much rarer and can be
                  recognized as a white bar integrated to an Echomancer’s HP.
                </li>
              </ul>
              <h5>Skill Upgrade Priority</h5>
              <ul>
                <li>
                  Her Skill 2, “Starry Blast” should be the priority when
                  leveling her skills. “Chirpy Storm” has CC as its main
                  utility, and a low number of uses. Meanwhile, “Starry Blast”
                  has the same cooldown, infinite uses due to being an
                  auto-skill, more damage and empowers Skylar's summons,
                  allowing her to contribute more as a sub-DPS.
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Team Recommendation" />
              <p>
                NOTE: Remember you can mix and match Echomancers among all
                suggestions and alternate options, adjusting based on who you
                have available.
              </p>
              <h5>Team 1</h5>
              <p>
                Leader: <strong>Freda</strong>
              </p>
              <div className={`character-teams box Fire`}>
                <div className="team-header">
                  <p>THE IAN SIBLINGS & FRIENDS</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="hassel"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Main-DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="skylar"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Sub-DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="boreas"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="freda"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                Fast and relentless, this team focuses on quick attacks and ult
                spam, aided by Skylar’s 30% Lightning RES shred (45% if at dupe
                1) and the massive amount of buffs from Boreas and Freda
                combined. If preferred, You can use Lydia instead of Hassel.
                Survivability should not be a problem considering Boreas’ shield
                and the consistent Electrolyzed reaction if this team is used in
                Wet Environment, but if you struggle with that, consider
                swapping Boreas for a tank.
              </p>
              <p>
                <strong>Back-up Options:</strong>
                <AshCharacter
                  slug="mo-hongxiu"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="yuqi"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="lydia"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="begonia"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="felicio"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                (And anyone of those elements and roles)
              </p>
              <p>
                <strong>Core Nexus Skills</strong>: Shield Infusion, Cheers to
                Friendship, Seed Overload, Seed Charge
              </p>
              <p>
                NOTE: If you prioritize using Lydia as the main DPS, make sure
                to inherit the “Life Anthem” Nexus skill or use the “Starlit
                Memories” Memory Trace. The gist of it is that you will want to
                bring as many Square (Bulwark, Vanguard, Striker) Echomancers as
                possible to trigger that skill.
              </p>
              <p>
                <strong>Memory Traces</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Lucky Find" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace
                    name="Beneath The Deception"
                    mode="data"
                    onProfile
                  />{' '}
                </li>
                <li>
                  <AshTrace name="Born From Ashes" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Silent Yesterday" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Into The Fantasy" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Growth" mode="data" onProfile />
                </li>
              </ul>
              <p>
                <strong>Alternative Memory Traces:</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Happy Hours" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Behind The Curtains" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Karma" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Lawful Cage" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Secret Melody" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Muscle Building" mode="data" onProfile />
                </li>
              </ul>
              <h5>Team 2</h5>
              <p>
                Leader: <strong>Skylar</strong>
              </p>
              <div className={`character-teams box Fire`}>
                <div className="team-header">
                  <p>“I CAN FIGHT TOO!”</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="skylar"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Main-DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="yuqi"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="furay-and-furney"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Elemental Reaction</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="freda"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                Skylar is far from the definition of a ‘main DPS’, but if you
                like her, she can definitely land a punch and clear content
                easily, especially when she’s in her favored environment.
                Speaking of environment, this team is perfectly suited to Wet
                Environments, and in some cases, such as the “Deep Abyss” Nexus
                path or the “Shriek Party” joint training, you can even opt to
                swap away Furay & Furney for another Echomancer of your choice.
                Skylar’s main damage source comes from her ultimate, so make
                sure to bring Freda and load Skylar with as many sources of CD
                reduction as possible.
              </p>
              <p>
                <strong>Back-up Options:</strong>
                <AshCharacter
                  slug="lydia"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="boreas"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="longqing"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                (And anyone of those elements and roles)
              </p>
              <p>
                <strong>Core Nexus Skills</strong>: Class Link (Diamond), Life
                Anthem, Seed Overload, Seed Charge
              </p>
              <p>
                <strong>Memory Traces</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace
                    name="Beneath The Deception"
                    mode="data"
                    onProfile
                  />
                </li>
                <li>
                  <AshTrace name="Lucky Find" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Starlit Memories" mode="data" onProfile />{' '}
                </li>
                <li>
                  <AshTrace name="Born from Ashes" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="My Vision Is Clear!" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Into The Fantasy" mode="data" onProfile />
                </li>
              </ul>
              <p>
                <strong>Alternative Memory Traces:</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Happy Hours" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Behind The Curtains" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Nightmare" mode="data" onProfile />
                </li>
              </ul>
              <h5>Team 3</h5>
              <p>
                Leader: <strong>Cyros</strong>
              </p>
              <div className={`character-teams box Fire`}>
                <div className="team-header">
                  <p>EXPLOSIVE BIRDIE</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="cyros"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Main-DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="skylar"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Sub-DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="mo-hongxiu"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="freda"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                Although Skylar can make the most of her debuffs on Wet
                Environments and Lightning teams, she can also be a great asset
                to Fire teams and Dry environments, aiding with much needed
                anti-air support, crowd control and creating Explosion
                reactions. A solid frontline like Mo Hongxiu can help Skylar
                relax safely at the back, creating a well-rounded team when
                paired with Cyros’ offensive capabilities and Freda’s buffs.
              </p>
              <p>
                <strong>Back-up Options include:</strong>
                <AshCharacter
                  slug="baili-tusu"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="reid"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="roar"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="lydia"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="longqing"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="boreas"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                (And anyone of those elements and roles)
              </p>
              <p>
                <strong>Core Nexus Skills</strong>: Strength Works Miracles,
                Seed Chard, Seed Overload, Life Harvest
              </p>
              <p>
                <strong>Memory Traces</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Emptiness" mode="data" onProfile />{' '}
                </li>
                <li>
                  <AshTrace name="Lucky Find" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Company" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Into The Fantasy" mode="data" onProfile />{' '}
                </li>
                <li>
                  <AshTrace name="Born from Ashes" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Lightchaser" mode="data" onProfile />
                </li>
              </ul>
              <p>
                <strong>Alternative Memory Traces:</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Happy Hours" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Dusk Radiance" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Into A Paper Crane" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Karma" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace
                    name="Beneath The Deception"
                    mode="data"
                    onProfile
                  />
                </li>
                <li>
                  <AshTrace name="Lawful Cage" mode="data" onProfile />
                </li>
              </ul>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Future Synergies" />
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="uni"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                The future master of Water zones, Uni brings not only a lot of
                damage output by herself, but she also adds further DR reduction
                to enemies.
              </p>
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="jean"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                One of the strongest main carries in the game, Jean is the
                perfect candidate to make use of Skylar’s Elemental RES shred.
                To make things even better, since she’s a Square Lightning
                Echomancer, she can consistently trigger “My Vision is Clear!”,
                more than doubling the damage capability of the teams she is in.
              </p>
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="tish"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                Tish is another fast shooter and the future main carry of the
                pure Lightning team. When paired with Skylar, the two of them
                can go beyond the basic functions of their kits: Tish can keep a
                “lightning field” endlessly on the ground, while Skylar can keep
                waves of enemies stuck inside to maximize her partner’s damage
                potential, as Tish can also unleash a barrage of skills and
                ultimates in quick succession that affect a small area.
              </p>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h5>Jump to</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Review</a>
                </li>
                <li>
                  <a href="#section-2">Gameplay Tips</a>
                </li>
                <li>
                  <a href="#section-3">Team Recommendation</a>
                </li>
                <li>
                  <a href="#section-4">Future Synergies</a>
                </li>
              </ul>
            </ScrollspyNav>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesSYPSkylar;

export const Head: React.FC = () => (
  <Seo
    title="Should You Pull Skylar | Ash Echoes | Prydwen Institute"
    description="A dedicated guide for Skylar that will help you decide whether to pull her or not."
    game="ash"
  />
);
