import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import './hsr-guides.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const HSRGuidesPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page hsr-guide'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>Guides</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_guides.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Guides</h1>
          <h2>Our guides for Honkai: Star Rail. </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Newbie zone" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Introduction to the game"
            link="/star-rail/guides/introduction-to-the-game"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_introgame.png"
                alt="Introduction to the game"
              />
            }
          />
          <CategoryCard
            title="Reroll guide"
            link="/star-rail/guides/reroll"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_rerollguide.jpg"
                alt="Reroll"
              />
            }
          />
          <CategoryCard
            title="Free characters"
            link="/star-rail/guides/free-characters"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_freechars.png"
                alt="Free characters"
              />
            }
            updated
          />
          <CategoryCard
            title="Early progression tips"
            link="/star-rail/guides/early-progression"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_earlyprog.png"
                alt="Early progression tips"
              />
            }
          />
          <CategoryCard
            title="Early gearing"
            link="/star-rail/guides/early-gearing"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_earlygear.png"
                alt="Early gearing"
              />
            }
          />
          <CategoryCard
            title="Team Archetypes"
            link="/star-rail/guides/team-archetypes"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_team_archetypes.webp"
                alt="Team Archetypes"
              />
            }
            updated
          />
          <CategoryCard
            title="Eidolon for Trailblazer"
            link="/star-rail/guides/trailblazer-eidolon"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_shadows.png"
                alt="Trailblazer Eidolon"
              />
            }
            updated
          />
        </Row>
        <SectionHeader title="Game guides" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Achievement Tracker"
            link="/star-rail/guides/achievement-tracker"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_achie.png"
                alt="Achievement Tracker"
              />
            }
          />
          <CategoryCard
            title="Debuff Counter"
            link="/star-rail/guides/debuff-counter"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_debuffs.jpg"
                alt="Debuff Counter"
              />
            }
          />
          <CategoryCard
            title="Daily farming route"
            link="/star-rail/guides/daily-farming-route"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_rota.jpg"
                alt="Daily farming route"
              />
            }
          />
          <CategoryCard
            title="What Relic Sets to farm?"
            link="/star-rail/guides/relic-sets-farming"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_relicfarm.png"
                alt="What Relic Sets to farm?"
              />
            }
            updated
          />
          <CategoryCard
            title="Best Battle Pass Light Cone"
            link="/star-rail/guides/best-battle-pass-light-cone"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_bpcone.png"
                alt="Best Battle Pass Light Cone"
              />
            }
          />
          <CategoryCard
            title="Combat and exploration"
            link="/star-rail/guides/combat-and-exploration"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_combat.png"
                alt="Combat and exploration"
              />
            }
          />
          <CategoryCard
            title="Game modes"
            link="/star-rail/guides/game-modes"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_gamemodes.png"
                alt="Game modes"
              />
            }
          />
          <CategoryCard
            title="Game systems"
            link="/star-rail/guides/game-systems"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_gamesystems.png"
                alt="Game systems"
              />
            }
          />
          <CategoryCard
            title="Gacha system"
            link="/star-rail/guides/gacha-system"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_gacha.png"
                alt="Gacha system"
              />
            }
          />
          <CategoryCard
            title="Sparkle vs Bronya vs Sunday"
            link="/star-rail/guides/sparkle-vs-bronya-vs-sunday"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_spabro.webp"
                alt="Sparkle vs Bronya vs Sunday"
              />
            }
          />
          <CategoryCard
            title="Fugue vs Harmony Trailblazer"
            link="/star-rail/guides/harmony-mc-vs-fugue"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_fumc.webp"
                alt="Fugue vs Harmony Trailblazer"
              />
            }
          />
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Characters" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Character progression"
            link="/star-rail/guides/character-progression"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_charprogression.png"
                alt="Character progression"
              />
            }
          />
          <CategoryCard
            title="Elements"
            link="/star-rail/guides/elements"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_elements.png"
                alt="Elements"
              />
            }
          />
          <CategoryCard
            title="Paths"
            link="/star-rail/guides/paths"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_paths.png"
                alt="Paths"
              />
            }
          />
          <CategoryCard
            title="Stats comparison"
            link="/star-rail/guides/characters-stats"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_charactersstats.png"
                alt="Characters stats"
              />
            }
          />
        </Row>
        <SectionHeader title="Game modes guides" />
        <h6>Forgotten Hall</h6>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Forgotten Hall"
            link="/star-rail/guides/forgotten-hall"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_fhm.png"
                alt="Forgotten Hall"
              />
            }
          />
          <CategoryCard
            title="Memory of Chaos Analytics"
            link="/star-rail/memory-of-chaos"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_fh.png"
                alt="Memory of Chaos"
              />
            }
          />
        </Row>
        <h6>Pure Fiction</h6>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Pure Fiction"
            link="/star-rail/guides/pure-fiction"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_pf.jpg"
                alt="Pure Fiction"
              />
            }
          />
          <CategoryCard
            title="Pure Fiction Analytics"
            link="/star-rail/pure-fiction"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_pf.jpg"
                alt="Pure Fiction"
              />
            }
          />
        </Row>
        <h6>Apocalyptic Shadow</h6>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Apocalyptic Shadow"
            link="/star-rail/guides/apocalyptic-shadow"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_apocalyptic_shadow.webp"
                alt="Apocalyptic Shadow"
              />
            }
          />
          <CategoryCard
            title="Apocalyptic Shadow Analytics"
            link="/star-rail/apocalyptic-shadow"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_apocalyptic_shadow.webp"
                alt="Apocalyptic Shadow"
              />
            }
          />
        </Row>
        <h6>Simulated Universe</h6>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Simulated Universe"
            link="/star-rail/guides/simulated-universe"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_sim.png"
                alt="Simulated Universe"
              />
            }
          />
          <CategoryCard
            title="Simulated Universe Builds"
            link="/star-rail/guides/simulated-universe-builds"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_sim2.png"
                alt="Simulated Universe"
              />
            }
          />
          <CategoryCard
            title="Swarm Disaster"
            link="/star-rail/guides/swarm-disaster"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_swarm.jpg"
                alt="Swarm Disaster"
              />
            }
          />
        </Row>
        <SectionHeader title="Mechanics" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Damage formula"
            link="/star-rail/guides/damage-formula"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_damage.png"
                alt="Damage formula"
              />
            }
          />
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Equipment" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Relics - introduction"
            link="/star-rail/guides/relics"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_relicinfo.png"
                alt="Relics"
              />
            }
          />
          <CategoryCard
            title="Relic Sets"
            link="/star-rail/guides/relic-sets"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_relics.png"
                alt="Relic Sets"
              />
            }
          />
          <CategoryCard
            title="Relic Stats"
            link="/star-rail/guides/relic-stats"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_relicstats.png"
                alt="Relic Stats"
              />
            }
          />
          <CategoryCard
            title="Light Cones"
            link="/star-rail/guides/light-cones"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_cones_guide.png"
                alt="Light Cones"
              />
            }
          />
        </Row>
        <SectionHeader title="Database" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Nameless Honor (BP)"
            link="/star-rail/guides/nameless-honor"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_nameless.png"
                alt="Nameless Honor"
              />
            }
          />
        </Row>
        <SectionHeader title="Lore" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Alien Space (manga)"
            link="/star-rail/guides/alien-space"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_manga.png"
                alt="Alien Space"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
    </DashboardLayout>
  );
};

export default HSRGuidesPage;

export const Head: React.FC = () => (
  <Seo
    title="Guides | Honkai: Star Rail | Prydwen Institute"
    description="List of guides for Honkai: Star Rail."
  />
);
