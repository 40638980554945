import React, { useEffect, useReducer, useState } from 'react';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import lodash from 'lodash';

import './index.scss';
import '../generic-page.scss';
import './traces.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Form } from 'react-bootstrap';
import { AshTracesData } from '../../modules/ash/data/ash-traces-data';
import { AshTrace } from '../../modules/ash/components/ash-trace';

const AshTracesPage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [currentRarity, setCurrentRarity] = useState('All');
  const [currentType, setCurrentType] = useState('All');
  const [visibleTraces, setVisibleTraces] = useState(AshTracesData);
  const [textSearch, setTextSearch] = useState('');

  const rarityChangeHandler = (e) => {
    setCurrentRarity(e);
  };

  const typeChangeHandler = (e) => {
    setCurrentType(e);
  };

  useEffect(() => {
    let filtered = AshTracesData;

    filtered = filtered.sort((a, b) => (a.Name > b.Name ? 1 : -1));

    setVisibleTraces(filtered);
    forceUpdate();
  }, []);

  useEffect(() => {
    let filtered = AshTracesData;

    if (currentRarity != 'All') {
      filtered = filtered
        .sort((a, b) => (a.Name > b.Name ? 1 : -1))
        .filter((trace) => {
          if (trace.Rarity === currentRarity) return true;
        });
    }

    if (currentType != 'All') {
      filtered = filtered
        .sort((a, b) => (a.Name > b.Name ? 1 : -1))
        .filter((trace) => {
          if (trace.Type === currentType) return true;
        });
    }

    if (textSearch != '') {
      filtered = filtered.filter(
        (echo) =>
          echo.Name &&
          echo.Name.toLowerCase().indexOf(textSearch.toLowerCase()) > -1
      );
      filtered = lodash.uniqBy(filtered, 'ID');
    }

    setVisibleTraces(filtered);
    forceUpdate();
  }, [currentRarity, currentType, textSearch]);

  const items = visibleTraces.map((trace, idx) => {
    return (
      <div key={idx} className="ash-trace-box">
        <AshTrace name={trace.Name} mode="data" />
      </div>
    );
  });

  return (
    <DashboardLayout className={'generic-page ash-traces-page'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>Memory Traces</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/ash/categories/category_traces.webp"
            alt="Tier List"
          />
        </div>
        <div className="page-details">
          <h1>Ash Echoes Memory Traces</h1>
          <h2>
            All Memory Traces available in Ash Echoes - both Global and CN
            versions.
          </h2>
          <p>
            Last updated: <strong>02/01/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Memory Traces"
        subtitle={
          <span className="additional-text">
            Showing {visibleTraces.length} trace
            {visibleTraces.length !== 1 && 's'}
          </span>
        }
      />
      <Form.Control
        size="lg"
        type="text"
        placeholder="Search for Traces"
        value={textSearch}
        onChange={(e) => setTextSearch(e.target.value)}
      />
      <div className="trace-filter rarity">
        <div
          className={`option all ${currentRarity === 'All' && 'selected'}`}
          onClick={() => rarityChangeHandler('All')}
        >
          <h6>All Rarities</h6>
        </div>
        <div
          className={`option R ${currentRarity === 'R' && 'selected'}`}
          onClick={() => rarityChangeHandler('R')}
        >
          <StaticImage src="../../images/ash/icons/rarity_r.webp" alt="Icon" />
        </div>
        <div
          className={`option SR ${currentRarity === 'SR' && 'selected'}`}
          onClick={() => rarityChangeHandler('SR')}
        >
          <StaticImage src="../../images/ash/icons/rarity_sr.webp" alt="Icon" />
        </div>
        <div
          className={`option SSR ${currentRarity === 'SSR' && 'selected'}`}
          onClick={() => rarityChangeHandler('SSR')}
        >
          <StaticImage
            src="../../images/ash/icons/rarity_ssr.webp"
            alt="Icon"
          />
        </div>
      </div>
      <div className="trace-filter type">
        <div
          className={`option all ${currentType === 'All' && 'selected'}`}
          onClick={() => typeChangeHandler('All')}
        >
          <h6>All Types</h6>
        </div>
        <div
          className={`option ATK ${currentType === 'ATK' && 'selected'}`}
          onClick={() => typeChangeHandler('ATK')}
        >
          <StaticImage src="../../images/ash/icons/stat_3.webp" alt="Icon" />
          <h6>ATK</h6>
        </div>
        <div
          className={`option DEF ${currentType === 'DEF' && 'selected'}`}
          onClick={() => typeChangeHandler('DEF')}
        >
          <StaticImage src="../../images/ash/icons/stat_2.webp" alt="Icon" />
          <h6>DEF</h6>
        </div>
        <div
          className={`option MST ${currentType === 'MST' && 'selected'}`}
          onClick={() => typeChangeHandler('MST')}
        >
          <StaticImage src="../../images/ash/icons/stat_4.webp" alt="Icon" />
          <h6>MST</h6>
        </div>
        <div
          className={`option TRM ${currentType === 'TRM' && 'selected'}`}
          onClick={() => typeChangeHandler('TRM')}
        >
          <StaticImage src="../../images/ash/icons/stat_5.webp" alt="Icon" />
          <h6>TRM</h6>
        </div>
        <div
          className={`option VIT ${currentType === 'VIT' && 'selected'}`}
          onClick={() => typeChangeHandler('VIT')}
        >
          <StaticImage src="../../images/ash/icons/stat_1.webp" alt="Icon" />
          <h6>VIT</h6>
        </div>
      </div>
      <div className="ash-traces-container">{items}</div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default AshTracesPage;

export const Head: React.FC = () => (
  <Seo
    title="Memory Traces | Ash Echoes | Prydwen Institute"
    description="All Memory Traces available in Ash Echoes - both Global and CN versions."
    game="ash"
  />
);
