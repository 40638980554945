/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { Tabs, Tab } from 'react-bootstrap';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';

const AFKGuidesOrson: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Dream Realm - Orson</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_orson.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Orson</h1>
          <h2>
            Dream Realm Orson guide that explains the boss mechanics and how to
            beat it!
          </h2>
          <p>
            Last updated: <strong>15/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Orson " />
        <StaticImage
          src="../../../images/afk/generic/boss_orson.webp"
          alt="Guides"
        />
        <p>
          <strong>Orson</strong> is one of the 8 available Dream Realm bosses
          you will encounter in AFK Journey who was introduced to the game in
          Season 2. In this guide we will go over the boss mechanics and the
          best characters and teams to use against him.
        </p>
        <SectionHeader title="What’s the meta team?" />
        <p>
          Realistically speaking,{' '}
          <strong>
            your meta team will depend on your specific box, and your account
            focus
          </strong>
          , so use your highest rarity DPS carry characters, along with whoever
          can best boost their damage.{' '}
          <strong className="gold">
            Try running Dual carry, as currently there is no way to boost a
            Hypercarry’s damage high enough to make it worth running only one
          </strong>
          . Keep faction bonus in mind but don’t run suboptimal characters just
          to force it.
        </p>
        <p>
          Still, below you can find a few example teams. Big thanks to{' '}
          <StaticImage
            src="../../../images/afk/icons/afkanalytica.png"
            alt="AFK Analytica"
          />{' '}
          <strong className="yellow">AFK Analytica</strong> for the team
          building tool. You can find their website{' '}
          <a
            href="https://www.afkanalytica.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and their discord{' '}
          <a
            href="https://www.discord.gg/analytica"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <SectionHeader title="Season 3 Teams (Pre-Endless)" />
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-season"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={<>Team #1 - Best Team</>}
            className="with-bg"
          >
            <h5>Team #1 - Best Team (Talene + Reinier)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/orson3_1.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>叫我波妮大人</strong>.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="talene" enablePopover /> is
                the main damage carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> acts
                as secondary damage carry and buffer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="alsa" enablePopover /> acts as
                consistent CC source.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer<strong>(Mythic+ Required)</strong>.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="hugin" enablePopover /> acts
                as buffer and provides sustain.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Harmonic Spell" /> - Best overall performance
                  thanks to the stats provided.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team2"
            title={<>Team #2 - Alt Team</>}
            className="with-bg"
          >
            <h5>Team #2 - Alt Team (Reinier)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/orson3_2.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Atraxy</strong> from AFK Analytica Discord.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover /> is the
                main carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> acts
                as secondary damage carry and buffer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="alsa" enablePopover /> acts as
                consistent CC source.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer (<strong>Mythic+</strong> required).
              </li>{' '}
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> acts
                as buffer and healer.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Harmonic Spell" /> - Best overall performance
                  thanks to the stats provided.
                </li>
              </ul>
            </ul>
          </Tab>
        </Tabs>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesOrson;

export const Head: React.FC = () => (
  <Seo
    title="Orson | AFK Journey | Prydwen Institute"
    description="Dream Realm Orson guide that explains the boss mechanics and how to beat it!"
    game="afk"
  />
);
