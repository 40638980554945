import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldDuncan: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you pull Mr. Duncan?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_duncan.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you pull Mr. Duncan?</h1>
          <h2>
            Not sure if pulling Mr. Duncan is the right move? No worries! Check
            our review and guide for him!
          </h2>
          <p>
            Last updated: <strong>06/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Should You Pull? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="mr-duncan"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>You should pull Duncan</p>
        <ul>
          <li>
            If you want a damage dealer who can provide support for the team via
            his bullets.
          </li>
          <li>Counters, counters, counters.</li>
          <li>
            If you appreciate an old man design which is quite rare in the game.
            (Gordon)
          </li>
          <li>
            If you need a Beast Afflatus who is a decent DMG Dealer in
            comparison to people lower rarity than him (but will be outclassed
            by his peers and 6 stars.)
          </li>
        </ul>
        <p>
          If you’re here only for the best and strongest characters, Mr. Duncan
          is not going to be that person. However, he’s not unusable, no one in
          this game truly is. That said, to put it plainly, he is quite
          outclassed by other damage dealers, especially those of higher rarity.
          He is not a bad character and can get you through most of the game’s
          content, but he doesn’t stand out in any meaningful way and lacks a
          distinct niche aside from being a decent 5-star beatstick. Ultimately,
          he’s not someone I can objectively recommend pulling for unless you
          like his design or character. (Valid I still use Matilda).
        </p>
        <SectionHeader title="Review" />
        <p>
          Mr. Duncan, for better or worse, is probably one of the simplest
          character gameplay designs we’ve seen in a while, reminiscent of the
          OG characters who have been in the roster the longest. His gameplay
          essentially revolves around the mechanic of [Bullets]. He gains them
          either from his counter skill, which lets him acquire 2 bullets from
          the [Veteran’s Cartridge], or through his Insight III passive. With
          Insight III, he generates bullets for the entire team whenever he
          lands a critical hit or defeats an enemy, granting all allies one type
          of bullet. Additionally, every 3 critical hits he lands, he gains
          Moxie.
        </p>
        <p>
          Now, you’re probably wondering: what do the bullets even do? Looking
          at the [Veteran’s Cartridge], it contains 2 types of bullets: [Acid
          Bullets] and [Shrapnel Bullets]. The effects are pretty simple: the
          Acid Bullet lowers the target’s defense by 5% (lasting 2 rounds) and
          can be stacked up to 5 times, while the Shrapnel Bullet lets you deal
          50% additional damage. And that’s kind of the bulk of his kit.
        </p>
        <p>
          He provides bullets for himself and others, gains Moxie quickly, and
          can use his Ultimate, which also increases Crit Rate by 10% for each
          type of bullet you have. This is further buffed by his Insight I,
          which provides [Ceremonious] (you gain a stack every 2 times an ally
          triggers [Bullets]), increasing his Ultimate Might by 3%. This can
          stack up to 100 times, making his potential Ultimate damage quite
          good. Though realistically the buff is only 3%, I can’t think of many
          ways to spam [Bullets] to the point where you can get his damage that
          high, at least not right now.{' '}
        </p>
        <p>
          Additionally, things I didn’t mention: he has a Mass Attack, with a
          bit of random additional Genesis DMG included. He has counters with
          [Quick Draw] which when you have bullets, will launch Rapid
          Counter-Fire, which does single target damage and the unique part
          about it is, he can land the counter before they even hit him.
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    He gains Moxie pretty quickly through his own skillset, even
                    faster in a Crit team, allowing him to use Ultimate fairly
                    consistently which is a good source of damage.
                  </li>
                  <li>
                    [Veteran's Cartridge] allows for good debuffs/buffs, with
                    Acid Bullets reducing target defense and Shrapnel Bullets
                    adding a significant damage boost.
                  </li>
                  <li>
                    Solid Crit synergy, thanks to his passive [Insight III],
                    providing bullets for the entire team whenever he lands
                    critical hits or defeats enemies.
                  </li>
                  <li>
                    He provides a unique Counter, where he can hit the opponent
                    before they get to attack him. He also provides Genesis DMG.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Damage Dealers have an extremely stiff competition with
                    mostly 6 Stars leading the race. Just due to the sheer
                    amount of damage that those 6 Stars do.
                  </li>
                  <li>
                    [Ceremonious] buff is only 3 percent and without many
                    characters with Bullets, I just can’t see how you can get
                    the effects to become extremely impactful.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>
          As a 5 star his Portrays are significantly easier to get I would say.
          His P5 is really good for him, but even as a 5 Star that can be a bit
          hard to get. He also has a lot of boosts related to his Crit Rate,
          Genesis DMG, and Counter DMG being improved. All very useful buffs and
          if you can get them it would definitely help him, but not necessarily
          required.
        </p>
        <SectionHeader title="Great Synergies" />
        <p>Notable synergies include:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="lopera"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="j"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>Future notable synergies:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="ms-newbabel"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          Lopera: Someone who’s also releasing in the same version,
          unfortunately in different banners. She’s also a character who
          utilizes bullets and she can convert them into buffs depending on if
          they’re in front of her or behind her in the team lineup. I recommend
          giving the Ultimate Buff bullet which means putting Duncan behind
          Lopera as that is where his main source of DMG will be from.{' '}
        </p>
        <p>
          J/Ms. Newbabel: If you want to focus on Duncan's counter portion of
          the kit, I think he makes a great partner with both of these two. J
          should benefit from the counters, and Ms. Newbabel provides defense,
          taunt, etc. With the new Euphoria buffs, she’s extremely powerful and
          can provide crit rate and extra actions.
        </p>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on Mr. Duncan:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="luxurious-leisure"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="brave-new-world"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          “Luxurious Leisure” is likely Duncan’s best-in-slot option, as his
          main source of damage is probably his Ultimate. He gains Moxie fast
          enough to utilize it well and is great for longer battles.
        </p>
        <p>
          "Brave New World" is also a great option to increase his damage, as
          he'll likely be using his Ultimate quite a bit.
        </p>
        <p>
          For the most part, he also works well with the "Generic Damage Dealer"
          Psychubes like Hopscotch, His Bounden Duty, and Her Second Life, so
          you can be flexible if you want.
        </p>
        <SectionHeader title="Resonance" />
        <h6>Resonance Pattern</h6>
        <StaticImage
          src="../../../images/reverse/generic/duncan_r_1.webp"
          alt="Guides"
        />
        <SectionHeader title="Teams" />
        <p>
          A few suggestions are listed below. 3-People teams are highlighted,
          with the 4th slot added at the side to provide a 4-P variation of the
          same team or even insights on possible variations of the 3-P team.
        </p>
        <h5>Standard Teams</h5>
        <div className="team-box">
          <h5 className="star">
            Hunks and Fire (Bullets)
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="mr-duncan"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="lopera"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="isolde"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Sub-DPS</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Tooth Fairy</strong>
              </li>
              <li>
                This is a review of Mr. Duncan, so he’ll be our main DMG dealer.
                As mentioned earlier, Lopera is a great option for Mr. Duncan,
                and I recommend placing him behind her to get the Ultimate buff.
                She also has [Bullet], which will help Mr. Duncan’s kit activate
                faster. Isolde is a strong support/Sub-DPS who can significantly
                increase the team’s damage output, and she can benefit from
                Lopera’s Incantation Bullet. Both of them apply burn. Tooth
                Fairy is the usual healer for the team, but her crit support is
                invaluable, as Mr. Duncan can take great advantage of that.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Same Version Gang (And J, I guess)<span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="mr-duncan"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="lopera"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="anjo-nala"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>J</strong>
              </li>
              <li>
                I wanted to list Anjo Nala as one of his particularly good
                synergies, but in reality, Anjo Nala is just kind of good with
                anybody, including Duncan. The best part is that if you're
                pulling for Duncan, you might get Anjo Nala. She can fit into
                almost any team. Anjo Nala has a unique ability to form a
                contract with a character, taking their Moxie every time they
                do, and she can use both her own and the bonded ally's Ultimate
                at the same time. Depending on the Afflatus of the bonded ally,
                she will apply different effects. In this case, since Duncan is
                a Beast-type, both of them will get 8% Crit DMG and Reality Def.
                She also gains buffs based on what the bound ally does. Since
                Duncan is a Beast, she gains Moxie whenever he consumes AP,
                which is great. Lopera is here for the reasons already mentioned
                above. J can take really good advantage of Duncan’s counters.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldDuncan;

export const Head: React.FC = () => (
  <Seo
    title="Should you pull Mr. Duncan? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if pulling Mr. Duncan is the right move? No worries! Check our review and guide for him!"
    game="reverse"
  />
);
