/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { AKETalents } from '../data/endfield-talents';

interface IProps {
  id: string;
  character_rarity: string;
  type?: string;
}

export const EndfieldTalent: React.FC<IProps> = ({
  id,
  character_rarity,
  type
}) => {
  const [originalskillDesc, setOriginalskillDesc] = useState(
    AKETalents[id].description
  );
  const [skillDesc, setSkillDesc] = useState(originalskillDesc);
  const [currentLevel, setCurrentLevel] = useState([1]);
  const [color, setColor] = useState('#979797');

  useEffect(() => {
    if (character_rarity === '4') {
      setColor('#8A5FCC');
    } else if (character_rarity === '5') {
      setColor('#B9AF28');
    } else if (character_rarity === '6') {
      setColor('#d57224;');
    }
  }, []);

  useEffect(() => {
    const newString = originalskillDesc
      .replaceAll(`Physical DMG`, `<u class="Physical">Physical DMG</u>`)
      .replaceAll(`Physical RES`, `<u class="Physical">Physical RES</u>`)
      .replaceAll(`Electric DMG`, `<u class="Electric">Electric DMG</u>`)
      .replaceAll(`Electric RES`, `<u class="Electric">Electric RES</u>`)
      .replaceAll(
        `Electric Infliction`,
        `<u class="Electric">Electric Infliction</u>`
      )
      .replaceAll(`Cryo DMG`, `<u class="Cryo">Cryo DMG</u>`)
      .replaceAll(`Cryo RES`, `<u class="Cryo">Cryo RES</u>`)
      .replaceAll(`Cryo Infliction`, `<u class="Cryo">Cryo Infliction</u>`)
      .replaceAll(`Nature DMG`, `<u class="Nature">Nature DMG</u>`)
      .replaceAll(`Nature RES`, `<u class="Nature">Nature RES</u>`)
      .replaceAll(
        `Nature Infliction`,
        `<u class="Nature">Nature Infliction</u>`
      )
      .replaceAll(`HP Treatment`, `<u class="Nature">HP Treatment</u>`)
      .replaceAll(`Heat DMG`, `<u class="Heat">Heat DMG</u>`)
      .replaceAll(`Heat RES`, `<u class="Heat">Heat RES</u>`)
      .replaceAll(`Heat Infliction`, `<u class="Heat">Heat Infliction</u>`)
      .replaceAll(`<br/>`, `<br />`);
    setSkillDesc(newString);
  }, []);
  return (
    <>
      <div className="skill-header">
        <div className={`skill-icon rarity-${character_rarity}`}>{type}</div>
        <div className="skill-info">
          <p className="skill-name">{AKETalents[id].name}</p>
        </div>
      </div>
      <div
        className={`skill-description`}
        dangerouslySetInnerHTML={{
          __html: skillDesc
        }}
      />
    </>
  );
};
