import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import ScrollspyNav from 'react-scrollspy-nav';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Row, Col, Card } from 'react-bootstrap';
import { AshCharacter } from '../../../modules/ash/components/ash-character';
import { AshTrace } from '../../../modules/ash/components/ash-trace';

const AshGuidesSYPFeng: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should You Pull Feng Qingxue?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_feng.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should You Pull Feng Qingxue?</h1>
          <h2>
            A dedicated guide for Feng Qingxue that will help you decide whether
            to pull her or not.
          </h2>
          <p>
            Last updated: <strong>17/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Introduction" />
              <p>
                <strong>
                  Feng Qingxue is a heavy-hitter hyper-carry with infinite skill
                  uses.
                </strong>{' '}
                This sentence should be enough to summarize her position as the
                best of the best when it comes to reaching big numbers per hit.
                She’s very versatile when it comes to Memory Traces, but not so
                much with her core team composition, requiring Boreas to be
                present at all times. Aside from the infinite skill uses, Feng
                possesses other unique qualities, such as a first skill that
                allows her to switch between shooting modes, a cartridge
                mechanic, and the fact that her weapons are considered summons.
                This last fact allows Feng to take advantage of Boreas 100% CRIT
                Rate to summons, as well as access to some very useful
                summoner-only buffs and skills. She’s recommended for players
                who enjoy following the meta and/or who like characters that
                require more control but deliver satisfying results for the
                extra effort.
              </p>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Review" />
              <p>
                To nobody’s surprise, as a sniper, Feng Qingxue has a very long
                attack range. Her first skill, “Icicon” is a Selection skill
                with two modes: Recon and Coordination. Recon attacks in a round
                area, and focuses on ASPD. Coordination, on the other hand, hits
                on a long straight line, knocking back targets and ignoring 20%
                of their basic DR. While the former is ideal for dealing with
                large waves and airborne enemies, the latter is a specialized
                tool for tougher enemies, especially bosses. If used in a Wet
                environment, this skill creates an Ice Zone in the area it hits.
                Make sure to use it first before creating Melt stacks with a
                Fire Echomancer’s attacks or skills. If the stage takes place in
                a Dry or Neutral environment, however, then the ideal gameplay
                order switches to the other way around: place the Fire zone
                first, and then use Feng’s attacks and skills.
              </p>
              <StaticImage
                src="../../../images/ash/pull/feng_1.webp"
                alt="Guides"
              />
              <p>
                Her second skill, “Cloudburst”, is an interesting case. This
                skill is intertwined both with Feng Qingxue’s basic attacks, and
                her ultimate. Each basic attack generates a stack of [Charged
                Magazine]. At 9 stacks, [Charged Magazine] generates one
                [Cloudburst Bullet]. This is when you can use this second skill.
                By default, Feng is able to hold up to 2 [Cloudburst Bullet]s,
                but if you get her third dupe, that number increases to 3. For
                bossing and speedruns, it’s advised to hold onto the maximum
                amount of bullets and unleash them all at once after using Feng
                Qingxue’s Seed Skill: “Cerulean Skies”.
              </p>
              <p>
                “Cerulean Skies” is at first a simple and straightforward damage
                ultimate. Yet, at closer inspection, it’s possible to notice
                that it has the [Barrier PEN] trait, as well as a Lv 3
                displacement strength (the strongest among playable characters).
                This combo of extra utility is useful to keep enemies at bay and
                to trigger the Nexus Skill “Keep Out - Triangle” (from the
                Memory Trace “Home”). If you ever used the cheese strategy of
                Sambheka with the Memory Trace “Dawn”, you may already be
                familiar with the benefits of displacement. Feng’s first skill,
                “Icicon”’ in its Coordination variant also has displacement
                built in (albeit at Lv 2 strength only). Being a quick and
                spammable skill, it opens the possibility for a fun and
                effective strategy.
              </p>
              <p>
                Despite the godlike numbers in damage output, this adorable
                sniper does have one big flaw. Feng Qingxue has the lowest base
                ASPD in the game, being almost half of the average when compared
                to other Echomancers. This can be a problem considering that she
                needs to attack 9 times to get a single [Cloudburst Bullet]
                charge. Thankfully, everything has a workaround. Feng has great
                synergy with Begonia, one of the most commonly used Echomancers
                when it comes to offering ASPD buffs and filling the Sub-DPS
                role. Nexus skills like “Winning Chase” also help with this
                issue, and using her first skill, “Icicon” in its Recon form as
                an opener, is yet another way to speed up Cartridge stacks.
              </p>
              <p>
                When it comes to team building and gameplay, the elemental
                reaction “Melt” is Feng Qingxue’s best friend. Capable of
                permanently lowering the target’s Basic DR to up to 60%, Feng
                will almost always be paired with an Echomancer adept atb
                creating Fire zones, such as Cyros, Mo Hongxiu, Thea, or Reid if
                you need a budget option.
              </p>
              <StaticImage
                src="../../../images/ash/pull/feng_2.webp"
                alt="Guides"
              />
              <h5>Pros & Cons</h5>
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Infinite skill uses with low to no cooldown;</li>
                        <li>Highest base damage per hit in the game;</li>
                        <li>Has displacement and Barrier PEN;</li>
                        <li>
                          Can consistently create Ice Zones in Wet Environments;
                        </li>
                        <li>Very long range and area of effect;</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Not very versatile in terms of teams;</li>
                        <li>Requires high and specific investment;</li>
                        <li>Low base ASPD.</li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Gameplay Tips" />
              <h5>Dupes</h5>
              <p>
                Feng Qingxue’s first dupe has the best cost-benefit. Her third
                dupe is also fantastic to increase the overall output of her
                burst windows. If you’re a spender or really like Feng Qingxue,
                she’s one of the best characters to invest on max dupes. Not
                only her Seed skill will gain a significant increase in its
                multipliers, but the buff to her second skill, “Cloudburst” will
                also increase.
              </p>
              <h5>General Tips</h5>
              <ul>
                <li>
                  Try to save your [Cloudburst Bullets] to use *after*
                  unleashing Feng Qingxue’s ultimate, as the ultimate will buff
                  Cloudburst.
                </li>
                <li>
                  While “Icicon” can be spammed due to its unlimited uses,
                  timing Feng’s burst windows is essential. Boreas’ buff only
                  has 3 uses, and depending on your build, it may take a bit for
                  [Charged Magazine] to fill.
                </li>
                <li>
                  Take advantage of Feng Qingxue’s skills to reposition her
                  whenever you want. Having infinite skill uses provides the
                  unexpected quality of high on-demand mobility.
                </li>
              </ul>
              <h5>Skill Upgrade Priority</h5>
              <ul>
                <li>
                  The skill 2, “Cloudburst” is the easy choice for priority, as
                  it’s an essential part of Feng Qingxue’s burst window and tied
                  to her Seed skill.
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Team Recommendation" />
              <p>
                NOTE: Remember you can mix and match Echomancers among all
                suggestions and alternate options, adjusting based on who you
                have available.
              </p>
              <h5>Team 1</h5>
              <p>
                Leader: <strong>Feng Qingxue</strong>
              </p>
              <div className={`character-teams box Fire`}>
                <div className="team-header">
                  <p>MELTING SNOW</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="feng-qingxue"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Main DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="begonia"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Sub DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="freda"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="boreas"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                As a Hyper-Carry, everything about Feng Qingxue’s team is
                centered around boosting her damage. The duo of Freda and Boreas
                are staple choices for their respective buffs, while Begonia is
                here to benefit from Boreas’ passives but, mainly, to increase
                Feng’s ASPD, while also being useful for breaking block gauges.
                Feng Qingxue has one of the lowest base ASPD in the game
                (0.25/s), but at the same time, she relies on her auto-attacks
                to charge her magazine and trigger Cloudburst. In some Dry
                stages, you can start with this formation right away, while for
                others it’s recommended to place someone to create Fire Zones
                first before swapping to Begonia. This will trigger the “Melt”
                elemental reaction (Ice + Fire), which reduces Enemies’ Basic DR
                up to a maximum of 60%. When in need of a good Frontline, Mo
                Hongxiu and Kylin are the prime choices for Feng. If you have
                (or can borrow) a Dupe 3 Tong, he will provide +15% DMG to Feng
                Qingxue even if he’s never deployed.
              </p>
              <p>
                <strong>Back-up Options:</strong>
                <AshCharacter
                  slug="cyros"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="mo-hongxiu"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="reid"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="kylin"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="tong"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                ,
                <AshCharacter
                  slug="hassel"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                ,
                <AshCharacter
                  slug="pris"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                ,
                <AshCharacter
                  slug="felicio"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                ,
                <AshCharacter
                  slug="boss"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                (Tong if Dupe 3, Pris if using Secret Melody)
              </p>
              <p>
                <strong>Core Nexus Skills</strong>: Getting into the Swing, Seed
                Overload, Bonus Time, Shield Blessing, Winning Chase
              </p>
              <p>
                <strong>Memory Traces</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Emptiness" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace
                    name="Beneath The Deception"
                    mode="data"
                    onProfile
                  />{' '}
                </li>
                <li>
                  <AshTrace name="Born From Ashes" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Sirius" mode="data" onProfile /> (Core)
                </li>
                <li>
                  <AshTrace name="Karma" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Your Yummiest Friend" mode="data" onProfile />
                </li>
              </ul>
              <p>
                <strong>Alternative Memory Traces:</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Secret Melody" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Lucky Find" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Company" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Muscle Building" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Home" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Into The Fantasy" mode="data" onProfile />
                </li>
              </ul>
              <h5>Team 2</h5>
              <p>
                Leader: <strong>Feng Qingxue</strong>
              </p>
              <div className={`character-teams box Fire`}>
                <div className="team-header">
                  <p>REVIVAL: LITE</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="feng-qingxue"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Main-DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="su-xiao"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="freda"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="boreas"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                Although the true “Revival Feng” won’t come live until Thea
                arrives next patch, you can try an alternate, “lite” version of
                it. The idea of this team is similar to Cen Ying’s Necromancer
                setup, with the first step being sacrificing Feng Qingxue as
                fast as possible in order to obtain massive amounts of boosts
                from Su Xiao and “Old Alley”. Furthermore, all the other Traces
                and skills recommended here are focused on maximizing bursts.
                Once Feng has died once, if the boss is somehow not dead, you
                can swap Su Xiao away and play the remaining of the fight like
                the regular Feng team.
              </p>
              <p>
                <strong>Back-up Options:</strong>
                <AshCharacter
                  slug="cyros"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="mo-hongxiu"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="begonia"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="boss"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="tong"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                (Dupe 3)
              </p>
              <p>
                <strong>Core Nexus Skills</strong>: Seed Overload, Strike the
                Heart, Cheers to Friendship, Time to Reap: Elite
              </p>
              <p>
                <strong>Memory Traces</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Lucky Find" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace
                    name="Beneath The Deception"
                    mode="data"
                    onProfile
                  />{' '}
                </li>
                <li>
                  <AshTrace name="Born from Ashes" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Sirius" mode="data" onProfile /> (Core)
                </li>
                <li>
                  <AshTrace name="Karma" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Old Alley" mode="data" onProfile /> (Core)
                </li>
              </ul>
              <p>
                <strong>Alternative Memory Traces:</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Your Yummiest Friend" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Into The Fantasy" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Company" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Home" mode="data" onProfile />
                </li>
              </ul>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Future Synergies" />
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="thea"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                Feng Qingxue’s next best friend alongside Boreas. Thea offers
                great utility, damage, Fire Zones, and most importantly, near
                immortality. Thea’s unique buffs are essential for Feng
                Qingxue’s best Speedrunning strat, “Revival Feng”, that will
                come online next patch. You can check out its performance here:{' '}
                <a
                  href="https://www.bilibili.com/video/BV12JxYebE3Q/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video
                </a>{' '}
                (WARNING, contains spoilers of an upcoming boss and loud happy
                music).
              </p>
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="titia"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                Titia is the final piece (as of now) to boost Feng Qingxue’s
                damage to the next level. This versatile Echomancer can
                guarantee 30% Ice Res shred, along with a kit that synergizes
                well with Feng’s in terms of utility and elemental reactions,
                adding further displacement and control of the battlefield.
              </p>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h5>Jump to</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Review</a>
                </li>
                <li>
                  <a href="#section-2">Gameplay Tips</a>
                </li>
                <li>
                  <a href="#section-3">Team Recommendation</a>
                </li>
                <li>
                  <a href="#section-4">Future Synergies</a>
                </li>
              </ul>
            </ScrollspyNav>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesSYPFeng;

export const Head: React.FC = () => (
  <Seo
    title="Should You Pull Feng Qingxue | Ash Echoes | Prydwen Institute"
    description="A dedicated guide for Feng Qingxue that will help you decide whether to pull her or not."
    game="ash"
  />
);
