import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { GFLCharacter } from '../../../modules/exilium/components/exilium-character';
import { GFLWeaponDB } from '../../../modules/exilium/components/exilium-weapon-db';

const ExiliumGuidesGacha: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page gfl-guide'} game="exilium">
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/gfl-exilium/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Gacha System</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/exilium/categories/category_gacha.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Gacha System Guide</h1>
          <h2>The Gacha System in Girls' Frontline 2: Exilium explained.</h2>
          <p>
            Last updated: <strong>07/01/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Gacha System" />
        <p>
          The gacha in GFL 2 is basically a copy of the system from Genshin
          Impact, Honkai: Star Rail and Wuthering Waves. So if you played any of
          the games, you already know what to expect from the banners in GFL 2.
        </p>
        <SectionHeader title="Beginner Procurement" />
        <StaticImage
          src="../../../images/exilium/generic/gacha_1.webp"
          alt="Guides"
        />
        <blockquote>
          [Beginner Procurement] Exclusive Access Event for new Commanders is
          now available! During this event, new Commanders can enjoy a 20%
          discount when accessing 10 times. An Elite Doll is guaranteed within
          50 accesses.
        </blockquote>
        <h5>Information about this banner</h5>
        <ul>
          <li>
            You can only pull 50 times on the Beginner Banner, but you are
            guaranteed to obtain one <strong className="rarity-SSR">SSR</strong>{' '}
            Character within those pulls,
          </li>
          <li>
            If you're lucky though, you can obtain multiple{' '}
            <strong className="rarity-SSR">SSR</strong> characters from the
            banner. The guaranteed one mentioned above is basically the pity
            that will trigger while you do the last 10 pulls,
          </li>
          <ul>
            <li>
              Keep in mind that the pity will only trigger once. So if you
              already pulled a SSR before the 50th pull, it won't trigger again.
            </li>
          </ul>
          <li>
            <strong>You cannot do single pulls on the banner</strong> - you can
            only pull in multiples of 10 (commonly referred to as a ten pull or
            a multi pull). However, you will receive a 20% discount every time
            you do that, so it will only cost 8 Standard Permissions instead of
            10,
          </li>
          <li>
            The rate for pulling a <strong className="rarity-SSR">SSR</strong>{' '}
            Character from this banner is currently set at <strong>0.6%</strong>
            ,
          </li>
          <li>
            The rate for pulling a <strong className="rarity-SR">SR</strong>{' '}
            Character from this banner is currently set at <strong>6%</strong>,
          </li>
          <li>
            There's no rate-up Characters on this banner - you have an equal
            chance to receive someone from the pool.
          </li>
        </ul>
        <p>
          Here's the list of <strong className="rarity-SSR">SSR</strong>{' '}
          Characters available on the banner:
        </p>
        <div className="employees-container-card-mode-gfl">
          <Card className="avatar-card">
            <GFLCharacter
              slug="mosin-nagant"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="tololo" mode="card" enablePopover showLabel />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="sabrina" mode="card" enablePopover showLabel />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="qiongjiu" mode="card" enablePopover showLabel />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="peritya" mode="card" enablePopover showLabel />
          </Card>
          {/* <Card className="avatar-card">
            <GFLCharacter slug="vepley" mode="card" enablePopover showLabel />
          </Card> */}
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Standard Procurement" />
        <StaticImage
          src="../../../images/exilium/generic/gacha_2.webp"
          alt="Guides"
        />
        <h5>Information about this banner</h5>
        <ul>
          <li>
            You can use <strong>Standard Permissions</strong> to pull on this
            banner and you should only pull using those. Keep your premium
            currency (Collapse Piece & Credit Token) for the Limited Banner,
          </li>
          <li>
            The rate for pulling a <strong className="rarity-SSR">SSR</strong>{' '}
            Character from this banner is currently set at <strong>0.3%</strong>{' '}
            (and <strong>0.3%</strong> for a{' '}
            <strong className="rarity-SSR">SSR</strong> Weapon),
          </li>
          <li>
            The rate for pulling a <strong className="rarity-SR">SR</strong>{' '}
            Character from this banner is currently set at <strong>6%</strong>,
          </li>
          <li>
            You are guaranteed to obtain a{' '}
            <strong className="rarity-SR">SR</strong> Character or a{' '}
            <strong className="rarity-SR">SR</strong> Weapon within{' '}
            <strong>10</strong> pulls,
          </li>
          <li>
            You are guaranteed to obtain a{' '}
            <strong className="rarity-SSR">SSR</strong> Character or a{' '}
            <strong className="rarity-SSR">SSR</strong> Weapon within{' '}
            <strong>80</strong> pulls,
          </li>
          <ul>
            <li>
              When you pull a <strong className="rarity-SSR">SSR</strong>{' '}
              Character/Weapon, there's 50% chance it will be either,
            </li>
          </ul>
          <li>
            Once you do <strong>260</strong> pulls you will be able to pick one{' '}
            <strong className="rarity-SSR">SSR</strong> available in the banner.
            This feature is available only once though,
          </li>
          <li>Soft pity starts at the 58th pull,</li>
          <li>The pity isn't shared with other banners,</li>
          <li>
            In the CN server, you can pick any{' '}
            <strong className="rarity-SSR">SSR</strong> Character or Weapon as
            'rate-up' and when obtaining one, there's 50% chance it will be the
            one you have picked. Sadly we don't know when this system will
            arrive to Global.
          </li>
        </ul>
        <p>
          Here's the list of <strong className="rarity-SSR">SSR</strong>{' '}
          Characters available on the banner:
        </p>
        <div className="employees-container-card-mode-gfl">
          <Card className="avatar-card">
            <GFLCharacter
              slug="mosin-nagant"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="tololo" mode="card" enablePopover showLabel />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="sabrina" mode="card" enablePopover showLabel />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="qiongjiu" mode="card" enablePopover showLabel />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="peritya" mode="card" enablePopover showLabel />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="vepley" mode="card" enablePopover showLabel />
          </Card>
        </div>
        <p>
          Here's the list of <strong className="rarity-SSR">SSR</strong> Weapons
          available on the banner:
        </p>
        <Row xs={1} xxl={2} className="relic-set-container">
          <Col>
            <GFLWeaponDB name="Golden Melody" mode="box" />
          </Col>
          <Col>
            <GFLWeaponDB name="Samosek" mode="box" />
          </Col>
          <Col>
            <GFLWeaponDB name="Optical Illusion" mode="box" />
          </Col>
          <Col>
            <GFLWeaponDB name="Heart Seeker" mode="box" />
          </Col>
          <Col>
            <GFLWeaponDB name="Mezzaluna" mode="box" />
          </Col>
          <Col>
            <GFLWeaponDB name="Planeta" mode="box" />
          </Col>
        </Row>
        <p>
          During the first anniversary in CN, the standard roster of heroes has
          been expanded and new character (and her weapon) was added there:
        </p>
        <div className="employees-container-card-mode-gfl">
          <Card className="avatar-card">
            <GFLCharacter slug="faye" mode="card" enablePopover showLabel />
          </Card>
        </div>
        <Row xs={1} xxl={2} className="relic-set-container">
          <Col>
            <GFLWeaponDB name="Hestia" mode="box" />
          </Col>
        </Row>
        <SectionHeader title="Targeted Procurement (Character)" />
        <StaticImage
          src="../../../images/exilium/generic/gacha_3.webp"
          alt="Guides"
        />
        <h5>Information about this banner</h5>
        <ul>
          <li>
            Targeted Procurement is a limited banner that lasts for 3-4 weeks,
          </li>
          <li>
            Once the banner is over, the{' '}
            <strong className="rarity-SSR">SSR</strong> Character featured on it
            won't be added to the standard pool. So you will have to wait for a
            rerun to get another chance of getting it,
          </li>
          <li>
            You can't use Standard Permissions and instead you need to use the{' '}
            <strong>Premium Permissions</strong> or Collapse Piece & Credit
            Token (it costs 150 to do a single pull),
          </li>
          <li>
            The rate for pulling a <strong className="rarity-SSR">SSR</strong>{' '}
            Character from this banner is currently set at <strong>0.6%</strong>
            ,
          </li>
          <li>
            The rate for pulling a <strong className="rarity-SR">SR</strong>{' '}
            Character or Weapon from this banner is currently set at{' '}
            <strong>6%</strong>,
          </li>
          <li>
            You are guaranteed to obtain a{' '}
            <strong className="rarity-SR">SR</strong> Character or a{' '}
            <strong className="rarity-SR">SR</strong> Weapon within 10 pulls,
          </li>
          <li>
            You are guaranteed to obtain a{' '}
            <strong className="rarity-SSR">SSR</strong> Character within{' '}
            <strong>80 pulls</strong>,
          </li>
          <li>
            Once you pull a <strong className="rarity-SSR">SSR</strong>{' '}
            Character, you have <strong>50%</strong> chance of it being the
            Rate-up one,
          </li>
          <li>
            If you lose the 50/50 then the second{' '}
            <strong className="rarity-SSR">SSR</strong> Character you pull will
            100% be the Rate-up one (so 160 pulls is hard pity),
          </li>
          <li>Soft pity starts at the 58th pull,</li>
          <li>The pity isn't shared with other banner types.</li>
        </ul>
        <SectionHeader title="Targeted Procurement (Weapon)" />
        <h5>Information about this banner</h5>
        <ul>
          <li>
            Targeted Procurement is a limited banner that lasts for 3-4 weeks,
          </li>
          <li>
            Once the banner is over, the new{' '}
            <strong className="rarity-SR">SR</strong> and{' '}
            <strong className="rarity-SSR">SSR</strong> Weapons featured on it
            won't be added to the standard pool. So you will have to wait for a
            rerun to get another chance of getting them,
          </li>
          <li>
            You can't use Standard Permissions and instead you need to use the{' '}
            <strong>Premium Permissions</strong> or Collapse Piece & Credit
            Token (it costs 150 to do a single pull),
          </li>
          <li>
            The rate for pulling a <strong className="rarity-SSR">SSR</strong>{' '}
            Weapon from this banner is currently set at <strong>0.7%</strong>,
          </li>
          <li>
            The rate for pulling a <strong className="rarity-SR">SR</strong>{' '}
            Character or Weapon from this banner is currently set at{' '}
            <strong>7%</strong>,
          </li>
          <li>
            You are guaranteed to obtain a{' '}
            <strong className="rarity-SR">SR</strong> Character or a{' '}
            <strong className="rarity-SR">SR</strong> Weapon within 10 pulls,
          </li>
          <li>
            You are guaranteed to obtain a{' '}
            <strong className="rarity-SSR">SSR</strong> Weapon within{' '}
            <strong>70 pulls</strong>,
          </li>
          <li>
            Once you pull a <strong className="rarity-SSR">SSR</strong> Weapon,
            you have <strong>75%</strong> chance of it being the Rate-up one,
          </li>
          <li>
            If you lose the 75/25 then the second{' '}
            <strong className="rarity-SSR">SSR</strong> Weapon you pull will
            100% be the Rate-up one (so 140 pulls is hard pity),
          </li>
          <li>Soft pity starts at the 50th pull,</li>
          <li>The pity isn't shared with other banner types.</li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default ExiliumGuidesGacha;

export const Head: React.FC = () => (
  <Seo
    title="Gacha System | Girls' Frontline 2: Exilium | Prydwen Institute"
    description="The Gacha System in Girls' Frontline 2: Exilium explained."
    game="exilium"
  />
);
