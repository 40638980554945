import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const AFKGuidesTeamsPVP: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide '}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Supreme Arena Teams</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_sarena.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Supreme Arena Teams</h1>
          <h2>
            Best teams to use in AFK Journey Supreme Arena/PVP mode - both on
            defense and offense.
          </h2>
          <p>
            Last updated: <strong>22/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Introduction" />
        <p>
          In the AFK Journey Supreme Arena, many of the archetypes used in the
          non-season arena can be used here as well, but many opportunities are
          made available by season talents and artifacts. For attack teams, it
          is often necessary to tailor your teams based on what the other player
          is running, so below we will list some solid defense teams.
        </p>
        <p>Check out our Arena Guide here!</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Best Arena Teams"
            link="/afk-journey/guides/arena-teams"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_meta.jpg"
                alt="Best Arena Teams"
              />
            }
          />
        </Row>
        <SectionHeader title="Map 1" />
        <StaticImage
          src="../../../images/afk/dream_s/sarena_1.webp"
          alt="Guides"
        />
        <h5>Wilder Burst Team</h5>
        <div className="heroes with-margin">
          <div className="employees-container afk-cards">
            <AFKCharacter
              mode="card"
              slug="eironn"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="ulmus"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="arden"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="carolina"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="lorsan"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </div>
          <div className="info">
            <p>
              This team is relatively accessible thanks to not necessarily
              requiring Celestial/Hypogean heroes, while also being very
              powerful against many matchups.{' '}
            </p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="eironn" enablePopover /> is
                used to group up and debuff enemies and is the core of this
                archetype.
              </li>
              <ul className="bigger-margin">
                <li>
                  Of course, we can always run other sources of CC for our
                  frontline, such as{' '}
                  <AFKCharacter
                    mode="inline"
                    slug="granny-dahnie"
                    enablePopover
                  />
                  , <AFKCharacter mode="inline" slug="lumont" enablePopover />,
                  or <AFKCharacter mode="inline" slug="lucca" enablePopover />,
                  but they will lack the explosive potential of the main{' '}
                  <AFKCharacter mode="inline" slug="eironn" enablePopover />{' '}
                  variant.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="ulmus" enablePopover /> is
                used to root enemies after{' '}
                <AFKCharacter mode="inline" slug="eironn" enablePopover />{' '}
                groups them up, and act as a frontline along with Eironn
              </li>
              <ul className="bigger-margin">
                <li>
                  Ulmus is not strictly necessary but he does make the team a
                  lot more consistent. Another option is running more damage
                  carries such as the ones listed below, or even{' '}
                  <AFKCharacter mode="inline" slug="bryon" enablePopover /> in
                  order to counter an enemy{' '}
                  <AFKCharacter mode="inline" slug="lily-may" enablePopover />{' '}
                  who may interfere with Eironn’s ultimate.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="arden" enablePopover /> is the
                preferred damage carry for this team, and his dupes are readily
                available
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="carolina" enablePopover />{' '}
                  can be used instead of or along with{' '}
                  <AFKCharacter mode="inline" slug="arden" enablePopover /> as
                  long as we drop one of our supports for more damage.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="parisa" enablePopover /> is
                  just as powerful as a burst damage damage carry, lacking
                  either of the others
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="carolina" enablePopover />{' '}
                complements{' '}
                <AFKCharacter mode="inline" slug="arden" enablePopover />,
                adding more burst damage to the team
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="damian" enablePopover /> can
                  be used for more sustain, as he provides some initial healing
                  that helps keep the backline alive at the start of battle,
                  while also providing Haste and blinding enemies.
                </li>
                <li>
                  <AFKCharacter
                    mode="inline"
                    slug="elijah-and-lailah"
                    enablePopover
                  />{' '}
                  can be used in place of{' '}
                  <AFKCharacter mode="inline" slug="damian" enablePopover /> as
                  a higher investment alternative, by making use of the
                  connected tiles at the back to greatly speed up our ultimates
                  and provide some healing and stat stacking, and work perfectly
                  in this mono faction team.
                </li>
                <li>
                  Alternatively, we can opt for an alternate damage carry, such
                  as <AFKCharacter mode="inline" slug="bonnie" enablePopover />,
                  providing long lasting AOE damage, or{' '}
                  <AFKCharacter mode="inline" slug="lily-may" enablePopover />{' '}
                  to counter enemy characters with dangerous ultimates.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="lorsan" enablePopover /> takes
                advantage of the burst damage archetype to double up on{' '}
                <AFKCharacter mode="inline" slug="arden" enablePopover /> damage
                while also providing a powerful burst of healing by the time{' '}
                <AFKCharacter mode="inline" slug="damian" enablePopover /> cart
                is destroyed. The Wilder talent tile makes it so that Lorsan can
                usually survive long enough to trigger his healing burst, though
                depending on the matchup he can still die as he is quite
                squishy.
              </li>
              <ul className="bigger-margin">
                <li>
                  As this is more of a flex slot, If we don’t have{' '}
                  <AFKCharacter mode="inline" slug="lorsan" enablePopover />
                  another option is running{' '}
                  <AFKCharacter mode="inline" slug="hewynn" enablePopover />, as
                  she charges up her ultimate by the time her invulnerability
                  runs out.
                </li>
                <li>
                  We can also run a more offensive alternative relying on more
                  damage carries.
                </li>
                <li>
                  Celestials/Hypogeans such as{' '}
                  <AFKCharacter mode="inline" slug="scarlita" enablePopover />,{' '}
                  <AFKCharacter mode="inline" slug="berial" enablePopover /> or{' '}
                  <AFKCharacter mode="inline" slug="harak" enablePopover /> can
                  be useful as an additional threat with more staying power.
                  <AFKCharacter
                    mode="inline"
                    slug="reinier"
                    enablePopover
                  />{' '}
                  can be used to disrupt the enemy formation.
                </li>
              </ul>
            </ul>
          </div>
        </div>
        <SectionHeader title="Map 2" />
        <StaticImage
          src="../../../images/afk/dream_s/sarena_2.webp"
          alt="Guides"
        />
        <h5>Tasi Talene Team</h5>
        <div className="heroes with-margin">
          <div className="employees-container afk-cards">
            <AFKCharacter
              mode="card"
              slug="tasi"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="talene"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="phraesto"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="scarlita"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="damian"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </div>
          <div className="info">
            <p>
              This team is one of the higher end options for players who
              collected some of the best C/H characters over the seasons, with
              strong staying power, capable of surviving against high damage
              teams and coming back for more.
            </p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="tasi" enablePopover /> is one
                of the main victory conditions for this team, often capable of
                coming back after death to slay the enemy team all by herself.
              </li>
              <ul className="bigger-margin">
                <li>
                  While <AFKCharacter mode="inline" slug="tasi" enablePopover />{' '}
                  is a key component of this team, lacking Tasi, it is possible
                  to run a team tailored around{' '}
                  <AFKCharacter mode="inline" slug="talene" enablePopover />{' '}
                  instead, in which case{' '}
                  <AFKCharacter mode="inline" slug="ludovic" enablePopover /> or{' '}
                  <AFKCharacter mode="inline" slug="hewynn" enablePopover /> can
                  be a great addition here, though the team will be lacking in
                  offensive potential without Tasi.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="talene" enablePopover /> is
                our second main victory condition, capable of regenerating after
                being defeated as long as another ally is still alive on the
                field, providing incredible synergy with{' '}
                <AFKCharacter mode="inline" slug="tasi" enablePopover />
                and <AFKCharacter mode="inline" slug="damian" enablePopover />.
                The talent tile also greatly enhances Talene’s ultimate and
                grants her extra stats.
              </li>
              <ul className="bigger-margin">
                <li>
                  It is possible to run a version of this team without{' '}
                  <AFKCharacter mode="inline" slug="talene" enablePopover />,
                  focusing instead on disruption.{' '}
                  <AFKCharacter mode="inline" slug="temesia" enablePopover />{' '}
                  can be a great choice for this variant.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="igor" enablePopover /> can
                  be used as an alternative distraction to keep{' '}
                  <AFKCharacter mode="inline" slug="tasi" enablePopover />
                  and <AFKCharacter
                    mode="inline"
                    slug="damian"
                    enablePopover
                  />{' '}
                  in the game.
                </li>
                <li>
                  We can also just run extra healing, or another damage carry,
                  though the team loses a lot of what makes it special without
                  <AFKCharacter mode="inline" slug="talene" enablePopover />.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover /> is
                currently the best tank in the game, dividing damage between two
                meat shields, while also providing valuable Energy Regen to
                allies.
              </li>
              <ul className="bigger-margin">
                <li>
                  Lacking{' '}
                  <AFKCharacter mode="inline" slug="phraesto" enablePopover />,{' '}
                  <AFKCharacter mode="inline" slug="thoran" enablePopover /> is
                  another great option with some offensive potential with his
                  counter.
                </li>
                <li>
                  Any other tank can be used in this slot, just make sure they
                  can hold out long enough for{' '}
                  <AFKCharacter mode="inline" slug="talene" enablePopover /> and{' '}
                  <AFKCharacter mode="inline" slug="tasi" enablePopover /> to do
                  their thing.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="scarlita" enablePopover /> is
                used to complete our requirement for the talent tile, and also
                helps keep{' '}
                <AFKCharacter mode="inline" slug="tasi" enablePopover /> alive a
                little longer, while also providing an extra victory condition
                later in the fight as she crashes down.
              </li>
              <ul className="bigger-margin">
                <li>
                  Another C/H carry can be used instead of{' '}
                  <AFKCharacter mode="inline" slug="scarlita" enablePopover />,
                  preferably one that provides an additional threat for the
                  enemy to deal with, such as{' '}
                  <AFKCharacter mode="inline" slug="harak" enablePopover /> or{' '}
                  <AFKCharacter mode="inline" slug="berial" enablePopover />,
                  allowing us to keep the talent tile.
                </li>
                <li>
                  Another great secondary carry option is{' '}
                  <AFKCharacter mode="inline" slug="lily-may" enablePopover />,
                  as she alone can stop an enemy{' '}
                  <AFKCharacter mode="inline" slug="eironn" enablePopover /> in
                  their tracks, forcing them to play around her, while being an
                  all around great damage dealer, though we end up switching to
                  Wilder talent tile.
                </li>
                <li>
                  Likewise,{' '}
                  <AFKCharacter mode="inline" slug="bonnie" enablePopover />{' '}
                  also works, especially if running a Wilder talent tile
                  variant.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="damian" enablePopover /> is
                the only healer that can keep up with{' '}
                <AFKCharacter mode="inline" slug="talene" enablePopover /> and{' '}
                <AFKCharacter mode="inline" slug="tasi" enablePopover />
                against burst teams, as he can just summon a new cart to quickly
                heal Talene and Tasi back up after the rest of the team is wiped
                by a powerful attack. Works at Mythic+.
              </li>
              <ul className="bigger-margin">
                <li>
                  Lacking{' '}
                  <AFKCharacter mode="inline" slug="damian" enablePopover />, we
                  can run any other healer here, though the team will be a lot
                  weaker against burst damage, as Damian is the only one who can
                  survive to provide backup healing for Talene and Tasi.
                </li>
              </ul>
            </ul>
            <p>
              Overall, <AFKCharacter mode="inline" slug="tasi" enablePopover />,{' '}
              <AFKCharacter mode="inline" slug="talene" enablePopover /> and{' '}
              <AFKCharacter mode="inline" slug="damian" enablePopover /> are the
              most important pieces, so when looking for substitutions,
              prioritize replacing{' '}
              <AFKCharacter mode="inline" slug="scarlita" enablePopover />
              and/or{' '}
              <AFKCharacter mode="inline" slug="phraesto" enablePopover />.
            </p>
          </div>
        </div>
        <SectionHeader title="Map 3" />
        <StaticImage
          src="../../../images/afk/dream_s/sarena_3.webp"
          alt="Guides"
        />
        <h5>Physical Damage Melee Team</h5>
        <div className="heroes with-margin">
          <div className="employees-container afk-cards">
            <AFKCharacter
              mode="card"
              slug="igor"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="nara"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="ludovic"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="lenya"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="alsa"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </div>
          <div className="info">
            <p>
              This team is quite good at locking down and quickly taking out
              priority targets, as{' '}
              <AFKCharacter mode="inline" slug="nara" enablePopover /> pulls a
              backline character and the rest of the team quickly finishes them
              off before moving on to the next. It is also possible to run a
              mauler version of this team based around{' '}
              <AFKCharacter mode="inline" slug="shakir" enablePopover />.
            </p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="igor" enablePopover /> is used
                to distract the enemy while our brawlers take them out one by
                one.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="thoran" enablePopover /> is
                  a good option if you want to bring a tank while still
                  maintaining the talent tile.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="lumont" enablePopover /> can
                  be used in a mauler variant of this team.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="nara" enablePopover /> is used
                to make use of the graveborn tile and help lock down and finish
                off enemies.
              </li>
              <ul className="bigger-margin">
                <li>
                  The graveborn version of this team has counterplay as the
                  enemy can choose who{' '}
                  <AFKCharacter mode="inline" slug="nara" enablePopover /> can
                  pull, or even negate it entirely with cc-resistant characters,
                  so an alternative unit to build around while still maintaining
                  a similar game plan is{' '}
                  <AFKCharacter mode="inline" slug="shakir" enablePopover />, as
                  he can provide the team with a mauler talent tile and more
                  safely disrupt the enemy formation relying on superior sustain
                  and staying power.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="ludovic" enablePopover />{' '}
                provides fast healing around the area while also offering some
                crowd control and helping achieve the graveborn talent tile
                requirements.
              </li>
              <ul className="bigger-margin">
                <li>
                  For the mauler version of the team, a good alternative is
                  <AFKCharacter
                    mode="inline"
                    slug="smokey-and-meerky"
                    enablePopover
                  />
                  , as they can provide healing in the area while melee units
                  clash.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="lenya" enablePopover />{' '}
                provides unmatched melee damage potential, cornering enemies and
                quickly finishing them off, and is an important part of any
                melee team.
              </li>
              <ul className="bigger-margin">
                <li>
                  A less effective but still viable alternative can be{' '}
                  <AFKCharacter mode="inline" slug="seth" enablePopover />, as
                  he can quickly snowball out of control as long as he can get
                  initial kills.
                </li>
                <li>
                  If you have him at Supreme+,{' '}
                  <AFKCharacter mode="inline" slug="harak" enablePopover /> can
                  be a great addition to this team, a superior version of{' '}
                  <AFKCharacter mode="inline" slug="seth" enablePopover /> in
                  many ways.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="alsa" enablePopover /> helps
                keep enemies locked down with cc skills, and complements many
                melee teams.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="soren" enablePopover /> can
                  also work well with the walls of the map, especially in the
                  mauler variant of the team.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="lily-may" enablePopover />{' '}
                  can counter ultimate-reliant enemies while also providing
                  great damage.
                </li>
                <li>
                  Another option is running{' '}
                  <AFKCharacter mode="inline" slug="shakir" enablePopover /> as
                  an addition to the graveborn variant.
                </li>
                <li>
                  Besides that, we can also just bring an additional healer such
                  as <AFKCharacter mode="inline" slug="koko" enablePopover />.
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesTeamsPVP;

export const Head: React.FC = () => (
  <Seo
    title="Supreme Arena Teams | AFK Journey | Prydwen Institute"
    description="Best teams to use in AFK Journey Supreme Arena/PVP mode - both on defense and offense."
    game="afk"
  />
);
