import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const NikkeGuidesCinderella: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide '} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Cinderella guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_cinderella.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Cinderella Guide & Review</h1>
          <h2>A guide & review for Cinderella in NIKKE: Goddess of Victory.</h2>
          <p>
            Last updated: <strong>08/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Cinderella" />
              <div className="employee-container for-nikke">
                <NikkeCharacter mode="icon" slug="cinderella" enablePopover />
              </div>
              <p>
                Today on the news: The beauty becomes the beast herself!
                Cinderella is an absolute monster DPS unit, one for the history
                books. Yet, her happy and much awaited release has been
                surrounded by an annoyance of bugs, causing even the monthly
                prestigious Solo Raids to be delayed! With the recent bug fixes,
                is she still shining as bright as she was before? Let us dive
                into what our combined heads managed to know about her so far.
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Cinderella is a remarkable DPS unit with a versatile profile.
                Her PvE performance is beyond impressive, and her PvP strength
                is no slouch either.
              </p>
              <p>
                For bossing, her coming is a chef's kiss. With the right team,
                she can out damage the current queens of DPS, Scarlet: Black
                Shadow & Alice, but the best thing about it is that she does not
                use the same supports as them (except Crown). Rouge and SAnis
                are her best partners, and they are likely inseparable for now.
              </p>
              <p>
                For campaign and other mob stages, Cinderella performs superb in
                low deficits. Her auto-friendly gameplay paired with how strong
                her basic attacks are makes her one of the top choices to use in
                low deficit gameplay. She is able to one shot kill every small
                minion during full burst, and shoots at an insane rate of 3
                shots per second. Understandably, this is a powerful ability to
                have for campaign. However, she does struggle on higher deficits
                if the element is not in her favour. This means that while she
                is still able to perform at a great level in electric weak
                stages, she will not be one of the top picks on neutral grounds.
                This is because some of her problems become apparent, which are
                largely related to her gimmicky weapon, which provides little to
                no AoE even though she is an RL unit. She is basically a single
                target DPS without pierce, and we hope to detail more about it
                on our Usage Analysis section. She is prone to some serious
                issues in high deficit gameplay that elemental damage buffs are
                able to revert due to the sheer strength at which Cinderella
                hits her bullets. In grave conditions (deficit ≥40%), she might
                as well not be dealing enough damage, especially since her AoE
                nuke can get split up in damage and will often randomly target
                minions instead of the tanks. When it comes to campaign bosses,
                however, this paradigm shifts. She easily destroys Electric-weak
                bosses for you, and given proper investments, she is currently
                setting new lowest CP clear records for electric weak stages and
                bosses.
              </p>
              <p>
                For PvP, her being a B3 Defender with a powerful AoE opens up
                various creative ways of using her, from posting her as a P1/P5
                tanker to sending her as a nuker. Cinderella is the only
                existing B3 (for now) that can one shot the entire enemy team
                without help from any buffer! Despite her versatility, she does
                not really improve on the existing meta/roster since the current
                requirement is of Supports, not DPS, so she is pretty much the
                same as other nuker units like Ein or Rei, although she will
                perform at a higher level than them in most situations. Her
                extremely poor burst gen is a serious problem, but her tankiness
                is well appreciated.
              </p>
              <p>
                Thankfully, she is not as costly as most other meta units to
                build, due to the fact that her Skill 2 provides little
                offensive bonuses. However, her team is, and you will need to
                invest a lot in her OL and team, especially Rouge. Testing has
                shown that Rouge’s limit breaks, skill investments, and gear
                level contribute decently in improving Cindy’s overall damage.
                SAnis affects her damage too if you pair them together.
                Fortunately, the requirements for Cinderella’s OL lines and her
                playstyle are comparatively much less demanding and are similar
                to that of RH and 2B (somewhere in between). For more info,
                visit the Investments section.
              </p>
              <p>
                Cinderella’s bugs have hence been fixed, and the intended design
                displays similar performance to her ‘buffed yet bugged’ version.
              </p>
              <h5>How did these bug fixes impact her?</h5>
              <p>
                It was a “politically correct nerf” because bug abuse is
                illegal, so they tried to compensate for it as much as possible.
              </p>
              <p>
                In base form, without any Charge Damage boost from Doll, OL, and
                external units like Alice, the Burst Skill fix did not reduce
                Cindy's damage. However, if any of these three conditions was
                satisfied, then she would lose damage. Remember that Doll's
                Multiplier is a multiplicative factor, which means it amplifies
                other Charge Damage buffs.
              </p>
              <p>
                Now, the improved accuracy for basic attacks was something we
                looked forward to. It did make her mobbing more reliable, as she
                can now hit targets more consistently.
              </p>
              <p>
                For bossing, other than QoL improvement from like accidental
                hitting of QTEs against Crystal Chamber, the answer will highly
                depend on: 1. whether core exists, and 2. whether the fix
                permits her to miss fewer shots and land more core shots. If the
                update did cause her to land more shots/core shots, then Cindy
                would see a damage improvement.
              </p>
              <p>
                One more thing: in the past, we stated that Cindy’s quirky
                targeting and projectile pathing brought only negatives. We were
                not entirely correct. In fact, it actually helped a lot when
                dealing with projectiles. Against bosses like Mirror Container,
                we were able to ignore the boss’s projectiles because Cindy
                would automatically destroy them for us. But now? You are forced
                to aim at them because Cindy no longer destroys them for you.
                Even worse, the change has also made some teams no longer
                feasible. However, Cinderella’s performance largely remains the
                same as it was before these bugs were patched, and the teams she
                best performed in before still remain meta.
              </p>
              <h5>Should I pull this unit?</h5>
              <p>
                <strong>Absolutely!</strong> There is no stopping you here. She
                is an excellent unit for Bossing, one of the best if not the
                best choices to use. Not only that, but her Campaign and PvP
                performance deserves a shout out since she can perform
                exceptionally well in low deficits, and can also perform at a
                superb level when it is her own element at higher deficits.
                Don’t forget, she does not even need a buffer to kill the enemy
                team in PvP! While her most proper PvE team can be expensive, it
                is very worth it and vital - not just Solo Raid tryhards, but
                also to players struggling with Interception. Even casual
                players trying to do better in UR and SR, simply because
                Cinderella can work effectively in neutral elements as well! She
                also excels against Mirror Container in AI and Campaign both,
                and those resilient Crystal Chambers in Campaign. If you are
                walled there, this is your chance. A very crucial unit for
                endgame because of her Raid, PvP and Campaign use (above Chapter
                23).
              </p>
              <p>
                Splurge if needed, but if available, we recommend using just
                your Mileage Tickets as the probability is abysmal. Remember to
                keep gems or mileage (ideally mileage due to the abysmal 1% rate
                of pilgrim banners) resources for Grave as well, since she seems
                to have a strong kit as well!
              </p>
              <p>
                As for dupes, we always recommend getting dupes for
                game-changing Pilgrims like her. Try to get at least MLB if you
                have a lot of surplus resources, especially if you are a
                spender. Remember to also put Rouge in Wishlist if you care
                about squeezing out as much juice as possible out of Cindy.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <p>
                Let's start with Cinderella's exclusive weapon, an auto-RL that
                continuously shoots projectiles 3 times per second once it is
                fully charged until it reloads. It deals 64.22% of final ATK as
                damage per fully charged shot. Reloading, even at ≥110% reload
                speed, will cause the charge to reset, forcing her to "wind up"
                again to maximum charge before resuming. This means uptime is
                important in maintaining her basic attack output, as it
                functions similarly to MGs.
              </p>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Concealed by
                  Skill
                </h6>
                <p>
                  Cinderella’s basic attack damage is low, but her basic attacks
                  deal additional skill damage from S1. This makes her basic
                  attacks appear strong, but actually they are not. Since
                  Cinderella heavily relies on Skill Damage and Burst Skill
                  Damage, core shots become less important, and crit becomes
                  more valuable. The low basic attack damage also makes her not
                  able to deal any significant damage to boss parts, so when
                  fighting Mirror Container or Crystal Chamber, she would have a
                  hard time destroying the Glass Slippers and Back Crystals.
                </p>
              </blockquote>
              <p>
                Cinderella's basic attacks are quirky. They can hit enemies or
                parts outside the designated target, and even miss cores against
                nearby enemies, which do not happen as often as before but still
                happen often enough to be relevant. This is an intended game
                design. This quirkiness makes her annoying to use in Campaign
                and brings some serious downsides. This forces you to play
                differently to accommodate these limitations.
              </p>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> It Helped
                  But Not Anymore
                </h6>
                <p>
                  In the past, these seemingly disjointed homing missiles would
                  actually help you clear projectiles without you having to
                  worry about them. Now, they do not, and you must actively
                  shoot them. You can experience this QoL downgrade when
                  fighting Mirror Container (like in Anomaly Interception).
                </p>
              </blockquote>
              <h5>2B or Not 2B</h5>
              <blockquote>
                <p>
                  ■ Activates when entering Burst Skill Stage 3. Affects self.
                </p>
                <p>ATK ▲ 2.71% of caster's final Max HP for 10 sec.</p>
                <p>
                  ■ Activates when attacking with Full Charge. Affects self.
                </p>
                <p>
                  Charge Speed ▲ 100%. Removed upon reloading to max ammunition.
                </p>
                <p>
                  ■ Activates when hitting a target with Full Charge. Affects
                  the target.
                </p>
                <p>Deals 136.6% of final ATK as additional damage.</p>
              </blockquote>
              <p>
                Cinderella's S1 is all about converting her final Max HP into
                ATK. Since Cinderella is a Defender, her base ATK stat is
                terrible, so ShiftUp compensates this by urging players to buff
                her Max HP instead. For example, a 100,000 HP with 2.71%
                conversion will give her 2,710 ATK. Without any external buffs,
                about slightly over half of her real ATK comes from her HP.
                Therefore, Rouge can be an essential contributor to her overall
                ATK and damage.
              </p>
              <p>
                Do note that this buff activates upon entering the Burst 3 stage
                (before Full Burst, when you can select whose Burst to use).
                This guarantees her receiving Full Burst buffs from the likes of
                Maxwell but exercise caution when dealing with buffs before B3
                like Mast's. We can also assume that her ATK buff is only
                present during Full Burst(s) and deactivate between them.
                Remember this when pushing High-Deficit Campaign. If you like
                stalling Full Burst(s), which ironically is the best strat
                sometimes, do not be surprised when she starts dealing 1 damage!
              </p>
              <p>
                This skill also has another part worth mentioning: the extra
                Skill Damage on each fully charged basic attack. This is similar
                to Modernia's. It can crit but not core-hit and actually
                constitute a dominant portion of her basic attack damage. This
                makes maxing this skill your main priority.
              </p>
              <h5>Decoys Hide True Feelings</h5>
              <blockquote>
                <p>■ Activates at the start of battle. Affects self.</p>
                <p>
                  Decoy: Creates an avatar with 96% of caster's final Max HP
                  continuously.
                </p>
                <p>
                  ■ Activates when entering Burst Skill Stage 3. Affects self.
                </p>
                <p>
                  Decoy: Creates an avatar with 96% of caster's final Max HP
                  continuously.
                </p>
                <p>■ Activates when decoy exists. Affects self every 3 sec.</p>
                <p>
                  Beautiful: Max HP ▲ 1.6% continuously, stacks up to 12
                  time(s).
                </p>
              </blockquote>
              <p>
                Upon entering battle and Burst Stage 3, Cinderella creates a
                decoy (which can be viewed as a shield). This does not taunt,
                but it does absorb frontal damage for Cinderella (including
                projectiles but excluding AoE attacks). Levelling up this skill
                greatly improves the decoy's survivability, but it is not
                something most of us are really interested in, so let's skip to
                the second component of this skill.
              </p>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Decoy's
                  Interaction in PvP
                </h6>
                <p>
                  Decoys are considered standalone characters and therefore take
                  sequential damage from enemy Nikke(s) in Arena and may even
                  provide opposing RL more burst gen.
                </p>
              </blockquote>
              <p>
                The second part of this skill is what makes this skill useful.
                In short, Cindy gains a Max HP buff that stacks every 3 seconds
                as long as her decoy is standing. This does NOT expire even when
                the decoy is destroyed and is permanent. It takes 36 seconds to
                reach max stacks from the beginning of the battle. While the
                value is low, it synergizes well with her S1, providing her the
                necessary ATK stat to make an impact.
              </p>
              <h5>
                Reminder of the Past: The Anniversary Pilgrim’s Burst Skill
                Buffed
              </h5>
              <blockquote>
                <p>■ Affects random enemies.</p>
                <p>
                  Deals 682.96% of final ATK as damage. Attacks sequentially for
                  10 time(s).
                </p>
                <p>■ Affects the same target(s) when in Beautiful status.</p>
                <p>
                  Deals 28.9% of final ATK as additional damage. Mirrors the
                  stack count of Beautiful.
                </p>
              </blockquote>
              <p>
                Cinderella's Burst Skill is self-explanatory. It is like Summer
                Sakura's but, instead of applying DoT, it deals extra damage for
                each Beauty stack she has from S2. In other words, one strike
                with maximum Beauty stacks will deal 1365.92% + 28.9% × 12
                damage, and there are 10 strikes in total. We are still yet to
                account for Full Burst and other misc buffs, and yet this
                percentage alone is already mind blowing.
              </p>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> What is
                  Sequential Attack?
                </h6>
                <p>
                  Sequential Attack AoE is a pseudo-screen-wipe skill that
                  attacks one enemy after another. It follows a random order
                  established at the start and rotates in the same cycle until
                  it strikes X times. If there are 3 enemies, then Target A will
                  be hit 4 times, and B and C will be hit 3 times. If there is
                  only one target, then all 10 attacks will be redirected at
                  that target.
                </p>
              </blockquote>
              <Alert variant="primary">
                <p>
                  The first part of Cinderella’s Burst Damage got doubled in
                  value in a bug fix which was causing her damage to be ×2 than
                  normal. Hence, if you had Charge Damage buffs from OL lines,
                  Dolls, and Skills (such as from Alice’s S1), they would now
                  become much less useful and not be considered something to aim
                  for anymore. In fact, this “politically correct nerf” might
                  have caused a noticeable dip in damage. If you were relying on
                  Charge Damage lines for Cinderella, you will see a potential
                  nerf in her performance and should try to reroll them away for
                  better lines.
                </p>
              </Alert>
              <div className="section-scroll" id="section-2">
                <SectionHeader title="Major Bugs (All Bugs have been Fixed)" />
                <h5>Bug 1 (Fixed)</h5>
                <p>
                  This analysis was made after the bug fix on Cinderella where
                  her basic attacks used to completely miss opponents on the
                  screen. This has since then been fixed and had no impact on
                  our ratings, although she still misses a bunch of her shots in
                  campaign due to her bullet curve and ends up hitting
                  buildings. They also used to sometimes hit projectiles, which
                  could be a good thing or a bad thing.
                </p>
                <Row className="video">
                  <Col xs={12} lg={6}>
                    <YoutubeEmbed embedId="DkLqi27_ELU" />
                  </Col>
                </Row>
                <h5>
                  Bug 2 (Fixed, Damage Loss was compensated by doubling her
                  Burst Values, essentially giving same performance)
                </h5>
                <p>
                  There is another existing bug where the first instance of 10
                  hits of Cinderella's Burst Damage is increased by 200% (or
                  even higher if you have charge damage buffs on her), which
                  happens if you burst with Cinderella when her basic attacks
                  are fully charged. This is an on-going bug as of this review's
                  release, but it had no impact on our testing. With careful
                  testing, we were able to figure out that even without the bug,
                  Cinderella gives similar performance in both Campaign and
                  Bossing. We are able to calculate that the damage increase to
                  Cinderella's current single target damage is ~21%. We can
                  assure you that even if this bug is patched, the ratings will
                  remain the same, even in PvP due to multiple advantages she
                  enjoys over others.
                </p>
                <Row className="video">
                  <Col xs={12} lg={6}>
                    <YoutubeEmbed embedId="lviYQ5D7Uhw" />
                  </Col>
                </Row>
                <Alert variant="primary">
                  <p>The projectile size was reverted to its original size.</p>
                </Alert>
                <h5>
                  Bug 3 (Fixed, may still happen occasionally but it is an
                  intended Game Design due to her Bullet Trajectory Gimmick)
                </h5>
                <p>
                  &gt; Cinderella also has a current aiming bug where she will
                  not core hit on near targets. We have confirmed that it is
                  because Shift Up, in order to try and fix Bug 1, increased the
                  projectile size, and not the projectile splash radius. After
                  it is fixed, her DPS output should be improved for even more
                  situations than it is already good in, especially for Bossing.
                </p>
                <Row className="video">
                  <Col xs={12} lg={6}>
                    <YoutubeEmbed embedId="N_rvkp1uZCc" />
                  </Col>
                </Row>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Usage Analysis and Tier List" />
              <h5>
                Campaign (LD) - <strong className="ss">SS</strong> | Campaign
                (HD) - <strong className="s">S</strong>
              </h5>
              <p>
                <strong>
                  Cinderella is a very good pick for campaign when under ~25%
                  deficit.
                </strong>{' '}
                She is able to work both as a main B3 (which is a B3 who you
                burst with) and as an off-burst B3 due to how strong her basic
                attacks hit. Anything that her nuke leaves alone, she will clean
                up using her basic attack within a couple of seconds since
                usually she just needs 1 bullet to kill. She even works well
                when her B3 partner is bursting. Nikkes like Emilia who have AoE
                clearing but lack good Single Target focus damage, Cinderella is
                able to help her easily kill those tanky raptures with ease. She
                will help you progress fast at the start, as is tradition with
                these meta Pilgrim B3 units, and is a good target for rerolling
                since she has major use in the endgame (chapter 23+ having 4 out
                of 11 chapters as electric weak and 4 electric weak boss walls,
                especially Mirror Container, Crystal Chamber and Behemoth). Also
                worth noting is that Fire weak (11/32) and Electric weak (7/32)
                weak are the most common element weaknesses seen in campaign,
                and hence Cinderella may see decent use late game.
                <strong>
                  However, for players who are looking to find a Red Hood 2.0,
                  Alice, Modernia or SBS, Cinderella is a weaker campaign unit
                  when compared to them in this bracket.
                </strong>
              </p>
              <p>
                Now, let’s move on and discuss{' '}
                <strong>
                  some problems in detail since criticism is necessary when such
                  a hard hitting nikke is placed lower in high deficits
                </strong>
                . Cinderella has two huge problems for high deficit: Her basic
                attacks, and her Burst skill's nuke. Firstly, her Basic attacks
                have no splash damage at all, and hence she has to kill units
                literally one by one. She is essentially a single target DPS
                unit with no pierce. In Low Deficit, she is able to one shot
                these raptures and quickly move on to another one. In higher
                deficits however, only if you have a good amount of elemental
                lines on her overload gear are you able to achieve this again,
                that too only for Electric Weak stages. Otherwise, she is unable
                to one shot these raptures, and has to often spend 2-3+ bullets
                to kill just one of them. Keep in mind that Cinderella hits 3
                shots per second, which means when a wave of 10-15 raptures
                drops, she spends way too long to kill them. This is a detriment
                since the opponent raptures are not there to sit around, and
                will kill you since they need only one shot to do so mostly. Her
                projectiles take a while to travel and aren't instant hits like
                RedHood's or Alice's, and neither are they constant AoEs like
                SBS and Mod or even Ein are able to provide.
              </p>
              <p>
                <strong>The second problem is her Burst skill's nuke.</strong>{' '}
                While it is able to hit super hard when the amount of raptures
                on the screen is lower than ~6-7, it fails to do the job when
                there is around 12-15 or even more raptures on the screen, while
                units like Scarlet or Rei Ayanami are able to fully clear their
                screens, no matter the amount of raptures standing in front of
                them. Hence, even SS tier units in high deficit tier list are
                able to push through on more situations and are less situational
                compared to Cinderella, something that is not the case on low
                deficit. On higher deficits, since she targets only a maximum of
                10 raptures randomly, Cinderella can actually get her damage
                divided up so much that she will fail to one shot in higher
                deficits. This is one thing, but another thing which is an
                absolute detriment to her use is that since she is only hitting
                10 raptures, she will leave 3-5 or even more raptures alive! In
                high deficit, that is a death sentence for at least one of your
                Nikkes as they are able to one shot you.
              </p>
              <p>
                While these 2 factors we mentioned above are key to why she was
                rated lower in comparison to other B3 Pilgrim releases in
                campaign (Modernia, SBS, RedHood), she is still a decent unit to
                use in campaign.{' '}
                <strong>
                  Cinderella still hits very hard in low deficit and is even
                  able to perform well in higher deficits, given that the
                  situation (ideally the element) is in her favour.
                </strong>
                Situationally, Cinderella, Scarlet, Ein and S.Anis will replace
                each other according to the stage on electric weak campaign.
                Some stages might see none of them being used due to the sheer
                brilliance Red Hood, Alice, Asuka, SBS and Modernia are able to
                provide.
              </p>
              <h5>
                Bossing - <strong className="sss">SSS</strong>
              </h5>
              <p>
                This should not come across as a surprise. We will try to keep
                this short since the answer is pretty simple to this rating.
                Cinderella just straight up hits super duper mega and insanely
                hard when there is only one target on the screen. She does
                enough damage to outperform even Alice's peak, and even SBS's
                peak! <strong>She is just that good!</strong> Only way to beat
                her out in DPS (considering all units are equally built) is if
                the top tier DPS units have elemental advantage or have pierce
                advantage over Cinderella! (but this means it is no longer a non
                conditional comparison). She is, safe to say, one of the best
                DPS units to ever be released for Bossing. She has lowered the
                bar required to beat both Crystal Chamber (who you will get
                tired of facing in campaign) and Mirror Container (both campaign
                and A.I), and is also one of the must-have units for Behemoth,
                the new boss. An absolute must pull for this reason. Crucial for
                endgame meta.
              </p>
              <p>
                Do note that Cinderella’s basic attack damage is low! It deals
                plenty of damage thanks to her S1. However, this comes at the
                downside of not having noticeable damage to boss parts as they
                cannot be broken with skill damage. In other words, Cindy is a
                RAW damage dealer.
              </p>
              <h5>
                PVP - <strong className="ss">SS</strong>
              </h5>
              <p>
                <strong>
                  It is pretty clear where Cinderella's main power in PvP lies -
                  her AoE nuke!
                </strong>{' '}
                She hits SUPER hard, She is the only unit in the game that can
                one-shot the enemy team without any buffers! There are some pros
                and cons that we would like to touch upon here. Cinderella is a
                defender unit, which means there is synergy with Biscuit here.
                She can proc Biscuit's S2 and give herself invincibility. She
                can also snoop under the radar because of her class versus the
                notorious and only B1 DPS, Rosanna. Rosanna's nuke targets
                attackers only, and since Cindy is a defender, she can hide from
                Rosanna just like that. She also gets her HP -&gt; ATK buff
                during full burst, and not at the start of the round like 2B's
                buff happens. Hence, her buff cannot be removed from Rosanna's
                cheeky dispel ability.
              </p>
              <p>
                There are only 2 cons here. One is that her Burst Nuke has the
                "Attacks Sequentially" mechanic, which we already saw in
                S.Sakura's burst. This means that Cindy needs a short interval
                to deliver the full hit on the enemy team. This is beneficial
                when compared to Ein's nuke in that it can't be taunted, but it
                is worse than Scarlet's AoE since the delay means that Noah or
                Blanc can successfully counter Cinderella teams even if they are
                slower by 1RL - well, almost every time. Sometimes, Cinderella
                does get lucky and lands a hit on a key unit on the opponent
                team before Noah and Blanc can nullify Cinderella due to Noah's
                AoE block or Blanc's indomitability. She can also rarely fully
                nullify Noah Burst even if both teams are 3RL, but this is a
                random event. It works similar to the process where S.Sakura
                manages to sneak 1-2 hits in the same speed versus Noah. If
                Cinderella manages to hit Noah first, there is a chance she will
                win.
              </p>
              <p>
                The second con is that even though Cinderella is an RL unit, her
                Burst Generation is almost non-existent. Her base burst gen is
                too low and she is, in fact, one of the worst Burst Generation
                units in PvP, even worse than MG units! She can also only hit
                one opponent at a time, unlike other RL units, which lowers her
                possible DPS output and shows that her AoE radius is indeed very
                small.
              </p>
              <p>
                <strong>Cinderella PvP Showcase</strong>
              </p>
              <Row className="video">
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="_jkSCLoeWoU" />
                </Col>
              </Row>
              <p>
                <i>Cinderella simply kills the opponent team</i>
              </p>
              <Row className="video">
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="Cd5h8bqTDBc" />
                </Col>
              </Row>
              <p>
                <i>
                  Cinderella’s Defender HP and Decoy HP showcased as she tanks
                  an insane amount of damage against 5 SGs
                </i>
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                You probably understand by now that Cinderella is a DPS unit
                even though she is a Defender. This means we need to focus on
                increasing her DPS output as much as we can. Since she has the
                HP -&gt; ATK conversion, you want to fully level up all 4 of her
                gears. Yes, even her Boots since, unlike the usual DPS where you
                can leave the boots at lvl0 since they provide no offensive
                buffs, Cinderella does benefit from the slight HP increase from
                them.
              </p>
              <p>
                However, this is very costly. Most of us do not upgrade all her
                gear to Level 5. Instead, we leave her Boots and/or Gloves at
                Level 3 (they provide the least bonuses to her).
              </p>
              <p>
                Now that we know she wants a DPS build, let's focus on the
                essentials first. Cinderella shoots 3 times per second, thus she
                needs at least 30 ammo to shoot for the entirety of HP → ATK
                conversion buff duration. Considering she has a base of 24 ammo,
                she only needs 1 Max Ammo line. However, for convenience, it
                might be better to go slightly over.
              </p>
              <p>
                The rest is just your everyday DPS necessities: Elemental Damage
                lines and ATK lines. Note that ATK &gt;&gt; Crit Rate/Crit
                Damage &gt; Charge Damage.
              </p>
              <p>
                Elemental Damage lines are better than ATK lines since Nikke
                Meta has shifted towards Elemental DPS for both Campaign and
                Raids. Meanwhile, between Critical Rate and Damage, Critical
                Rate offers a more stable improvement, whereas Critical Damage
                is more volatile, and depending on your luck, allows for a
                potentially higher peak damage, which is what some people like
                to do in Solo Raid by retrying over and over again — RNG crit
                fishing.
              </p>
              <ul>
                <li>
                  <strong>Essential:</strong> 1× Max Ammo
                </li>
                <li>
                  <strong>Ideal:</strong> 4× Elemental DMG, 4× ATK, 1× Max Ammo
                </li>
                <li>
                  <strong>Passable:</strong> 1~3× Crit Rate/Crit Damage, 1~2×
                  Max Ammo
                </li>
                <li>
                  <strong>Priority:</strong> Meta
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <ul>
                <li>
                  <strong>Skill 1: 10</strong>
                </li>
                <ul>
                  <li>
                    This is where her source of her power is located, where her
                    HP is converted to ATK buffs. Reminiscent of 2B. Focus on
                    maxing this first to maximise your DPS.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 4-10</strong>
                </li>
                <ul>
                  <li>
                    This skill is of relatively lower priority since it is a
                    much more defensive skill. It improves her decoy
                    survivability and gives a small Max HP buff which stacks to
                    give a decent value, indirectly providing ATK buff since her
                    S1 gives ATK buff based on final Max HP.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill: 10</strong>
                </li>
                <ul>
                  <li>
                    Simple nuke with super high value which can target 10 units
                    at max. Very important to fully max out this skill, since it
                    will still be where the majority of her DPS stems from.{' '}
                    <strong>
                      Note: Shift Up has recently buffed this skill such that it
                      does 2× the damage!
                    </strong>
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="cinderella" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_3_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_8_sm.png"
                alt="Guide"
              />
              <ul>
                <li>
                  Cinderella’s interaction with cubes is slightly awkward. That
                  is because she still needs to take 1s to fully charge her
                  weapon every time she reloads before she can resume shooting.
                  This causes her reload to be perceived 1 sec. longer, and
                  since this is unaffected by Reload Speed buffs, the
                  effectiveness of Resilience Cube is heavily impacted. Cube.
                </li>
                <ul>
                  <li>
                    Since Cindy's weapon functions like an MG and has a "wind-up
                    (charge-up)" period of 1s, she always scales better with
                    Bastion, especially in teams with two or more Reload Speed
                    buffers
                  </li>
                  <li>
                    Resilience is worse for Cindy. Unlike other DPS who gain a
                    significant uptime boost upon reaching &gt;109%, Cindy still
                    needs to wait 1s after each Reload. Depending on how many
                    Reload Speed buffers there are, she would lose around ~1%
                    DPS. Nevertheless, the level of the cube is more important
                    than this percentage. If your Resilience is significantly
                    stronger, its increased stats and Elemental Damage will
                    allow your Cindy to slap harder than when she uses Bastion.
                  </li>
                  <li>
                    Lastly, Vigor is a decent alternative that can amplify her
                    damage through bruteforce HP scaling. However, it is also
                    diluted by other ATK/Max HP buffs. For most players, their
                    Vigor Cube level is still too low to warrant using it. The
                    extra stat and Elemental Damage from their higher level
                    cubes are more potent.
                  </li>
                </ul>
                <li>
                  In the end, if you are still confused on what cube to use,
                  just pick whichever has the highest level between Resilience
                  and Bastion. time.
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: Zap Those Raptures with the Electric Girls</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="cinderella"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rouge" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="ein" enablePopover />
                    <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="sparkling-summer-anis"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sparkling-summer-anis"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="naga" enablePopover />
                    <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                This team revolves around 2 heavy DPS but in the final damage
                charts, Cinderella will always be on top. All of these DPS also
                share the same trait: having an insane amount of innate ATK
                buff. This makes Liter inferior to Rouge, who is more ideal for
                buffing Cinderella. This team works great for both Bossing and
                Electric-Weak Campaign, provided you can spam properly with Ein
                and Rouge. SAnis’s presence is usually more important because
                she acts as a buffer for Cindy. ExiaTr/Scarlet can be used as an
                off-burst damage dealer if supports like SAnis and Naga don’t
                fascinate you or are used elsewhere.
              </p>
              <p>
                A variant or two would work best for you depending on your
                investment; Mirror Container AI is a good place to test them
                out. Comps with Cindy, whether using Ein or Naga as the fifth
                unit, have proven to reach Stage 9. Both variants feature SAnis.
              </p>
              <p>
                For Electric-Weak Raids, usually Crown will join the strongest
                DPS (in this case, Cindy’s team). However, outside Electric-Weak
                Raids, you can choose to use Blanc so that Crown can support
                other teams with potentially more effective DPS.
              </p>
              <h5>Team #2: Red Hood Buffer Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="cinderella"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sparkling-summer-anis"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                This is a comp that has recently set a new CP record for Crystal
                Chamber. The gameplay involves using Red Hood’s Burst B1 to line
                up with SAnis’s to avoid sabotaging her DPS (first with Cindy;
                second with SAnis; and then SAnis till the end of the battle).
                Red Hood’s Burst is also effective for off-burst DPS, and since
                Cindy also deals damage during her off-burst rotation, Cindy
                also benefits from it.
              </p>
              <h5>Team #3: Off With Those Rapture Heads as an Off Burst DPS</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="rouge" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="alice" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="cinderella"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                As we mentioned above, Cinderella is a great non burst B3 DPS
                too. It's a very basic team with 2 piercers and one non burst
                DPS. Works very impressively against Electric Weak stages. This
                team is for campaign.
              </p>
              <h5>Team #4: HP Scaling Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="cinderella"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rouge" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="2b" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                    <NikkeCharacter mode="icon" slug="mast" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                Rouge is the best Buffer for Cinderella and 2B - 2 Nikkes who
                scale on HP. We also have 1 spare slot to put another Nikke
                here, which can be Modernia or Scarlet as off-burst DPS, or Noir
                to make Cinderella reload less.
              </p>
              <h5>Team #5: The Exia Coper for the Rougeless</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="exia"
                      enablePopover
                      showTreasure
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="mast" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="cinderella"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sparkling-summer-anis"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                A tricky team to set up because Mast’s Burst Skill records the
                two units with the highest ATK in the Burst 2 Stage, while Cindy
                gains her conversion buff in Burst 3 Stage. For this team to
                work, then Cindy’s base ATK together with OL must be higher than
                SAnis’s. If you can achieve this, this team is quite good
                theoretically.
              </p>
              <h5>Team #6: Rocket PUNCH with the forgotten Rocket Supporter</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="cinderella"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rouge" enablePopover />
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="laplace" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="scarlet-black-shadow"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="a2" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rem" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="emilia" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Rocket launchers are getting abundant, slowly but surely. Rem
                can have a piece of the fun as well here
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="PvP Team Compositions" />
              <h5>Fast Nuke @ 2 RL</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noise" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="cinderella"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="anis" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                This team needs at least 3 Quantum cube lv 3+ on Centi, Anis and
                Jackal to reach burst at 2 RL. Cinderella's self damage is huge,
                enough that with Noise, she will kill everyone unless they have
                protection like Noah. The fun part is, even Rosanna can't save
                you.
              </p>
              <h5>Could It Be Thicker? @ 4 RL</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noah" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noise" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="cinderella"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sparkling-summer-anis"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                We have 4 Electric Nikkes here which Sanis will buff all of
                them, and don't underestimate Rocket Launcher in PVP - They hit
                like a truck. Cinderella herself is a great DPS too, unlike S.
                Sakura where her damage falls extremely hard after bursting.
                This is a great team to deal with 3 RL Emilia.
              </p>
            </div>
            <div className="section-scroll" id="section-7">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Strongest non-conditional DPS.</li>
                        <li>
                          A Defender unit means she is very tanky. Good for
                          raids where she can be the Highest ATK unit.
                        </li>
                        <li>
                          Very strong basic attacks since she shoots fast and
                          with full charge.
                        </li>
                        <li>
                          Strong nuke, can kill in PvP even without buffers.
                        </li>
                        <li>Extremely auto-friendly.</li>
                        <li>
                          {' '}
                          On top of being Defender, she technically has 2 life
                          bars - Her decoy must break first before she takes
                          damage (unless it's AoE damage like Bronchus' fart).
                        </li>
                        <li>
                          Her skill scaling is mediocre, which means her skill
                          values are already bloated. Hence, she performs very
                          well with moderate investments as well.
                        </li>
                        <li>
                          One of the coolest designs. One of the hottest Nikkes.
                          One of the best VAs for every language. Cinderella is
                          a gem.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Huge innate ATK buff, scales poorly with ATK buffs.
                        </li>
                        <li>
                          Her weird attack type still makes her miss some shots.
                          In PvP, she often only hits the cover and not the
                          Nikke.
                        </li>
                        <li>
                          Small explosion radius of basic attacks, she is
                          basically a single target damage dealer without
                          pierce.
                        </li>
                        <li>
                          Burst AoE nuke is limited to 10 enemies, which means
                          it is not a great screen clear tool for campaign.
                        </li>
                        <li>
                          {' '}
                          Burst AoE nuke targeting is random, often hits minions
                          instead of tanky raptures.
                        </li>
                        <li>
                          Her burst has a delay which is a detriment in PvP.
                        </li>
                        <li>
                          Mirror, Mirror on the wall, what was the buggiest
                          Nikke release of them all?
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6',
                'section-7'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Major Bugs</a>
                </li>
                <li>
                  <a href="#section-3">Tier List</a>
                </li>
                <li>
                  <a href="#section-4">Investments</a>
                </li>
                <li>
                  <a href="#section-5">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-7">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesCinderella;

export const Head: React.FC = () => (
  <Seo
    title="Cinderella Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Cinderella in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
