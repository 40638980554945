import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';

const RevRaidCritter: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Critter Rehab Center</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_critter.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Ms. NewBabel's Critter Rehab Center Startup Guide</h1>
          <h2>
            Everything you need to know about the Ms. NewBabel's Critter Rehab
            Center in Reverse 1999!
          </h2>
          <p>
            Last updated: <strong>19/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <SectionHeader title="Is this game mode mandatory?" />
      <p>No.</p>
      <p>
        This gamemode is entirely optional. The only rewards you get are
        Wilderness buildings, water tiles, an increased tile limit, and currency
        used for the Critter Rehabilitation Center. If you don’t want the UI
        affecting your Wilderness, you can remove the buildings and it’ll be
        like this game mode never existed in the first place.
      </p>
      <SectionHeader title="Early Game Best Practices and Tips" />
      <ul>
        <li>Prioritize finishing the Handbook!</li>
        <li>
          Use the Whirring Winder to pull critters from the Cranking Gumball
          Machine.
        </li>
        <li>
          <strong>DO NOT</strong> “Reset Flavors” in the Cranking Gumball
          Machine until you’ve gotten all the critters.
        </li>
        <li>
          Once you reach Handbook Operation Goals - 14, you’ve unlocked
          everything (this takes at least a week).
        </li>
        <li>
          Try to finish 4 Purchase Orders in the Trade menu{' '}
          <strong>every day</strong>.
        </li>
        <li>
          Finish all your Purchase Orders for the day before placing new
          buildings or earning building upgrades. (If you put down new
          buildings, the refreshed orders will ask for new materials that you
          may not have time to produce.)
        </li>
        <li>
          Try to train 1 of every type of critter. (This is important for
          Gumball Mixing.)
        </li>
        <li>
          The starter critters have lower stats than their gacha counterparts,
          so you should train an extra one of each type.
        </li>
        <li>
          Spend your Jigjog Coins on Entwined Winders and Timewarp Watches.
        </li>
        <li>
          There’s a chance of getting orders with a 200% Payout. Try using your
          rerolls to get as many of these as you can per day.
        </li>
        <li>
          Don’t bother with assigning critters to transportation duty in the
          early game.
        </li>
        <li>
          When your critter runs out of Mood, you have to put them in the
          Critter Dwelling for them to recover. Try putting a Refined
          Groupbuncle or Orangebuncle in the Critter Dwelling to help critters
          recover Mood faster.
        </li>
      </ul>
      <SectionHeader title="Complete the Tutorial" />
      <p>
        NewBabel arrives with an exciting new business venture for Vertin: using
        the Wilderness’ critters for unpaid labor! Medicine Pocket, Sotheby,
        Desert Flannel, 37, Eternity, Barbara make brief appearances in the
        tutorial story.
      </p>
      <p>By the time you finish the tutorial, you should have:</p>
      <ul>
        <li>
          3 Carbuncles, 1 Axe-handle Hound, 1 Moon-eyed Fiddler, and 1 Pinnacle
          Grouse
        </li>
        <li>1 critter from the Cranking Gumball Machine gacha tutorial</li>
        <li>1 finished training for Axe-handle Hound</li>
        <li>5 Luminous Butterfly</li>
        <li>
          New buildings: Ingredient Depot (6*), Greencage Studio (6*), and
          Production Factory (5*)
        </li>
        <li>1000 Jigjog coins</li>
        <li>10 Tickers of Speed</li>
        <li>Finished Operation Goals - 1</li>
      </ul>
      <SectionHeader title="All Buildings" />
      <p>Ingredient Stations</p>
      <ul>
        <li>Ingredient Depot (Ingredient Hub)</li>
        <li>Ingredient Depot (Ingredient Station I)</li>
        <li>Ingredient Depot (Ingredient Station II)</li>
      </ul>
      <p>Processing Stations</p>
      <ul>
        <li>Greencage Studio (Processing Hub)</li>
        <li>Utterly-Automated Workshop (Processing Station II)</li>
        <li>Balmy Greenhouse (Processing Station III)</li>
      </ul>
      <p>Manufacturing Stations</p>
      <ul>
        <li>“Nascence” Factory (Manufacturing Hub)</li>
        <li>Flamespark Workshop (Manufacturing Station II)</li>
        <li>Alchemic Abode (Manufacturing Station III)</li>
      </ul>
      <p>Trade</p>
      <ul>
        <li>NewBabel Exchange (Trading Center)</li>
      </ul>
      <p>Critter Dwelling</p>
      <ul>
        <li>Café Hangout (Critter Dwelling)</li>
        <li>Café Hangout (Critter Dwelling)</li>
        <li>Café Hangout (Critter Dwelling)</li>
      </ul>
      <p>
        <i>
          Despite getting the “Production Factory” from the Handbook, it is{' '}
          <strong>NOT</strong>a production building and does not actually help
          you produce anything.
        </i>
      </p>
      <SectionHeader title="Critter Training" />
      <p>
        Because it’s important for Gumball Mixing, you should train 1 of every
        type of critter.
      </p>
      <p>
        Each trainer has a specific type of critter they are best at training.
        <strong>
          If you give them the wrong type of critter, that critter won’t get the
          trainer’s bonuses.
        </strong>
      </p>
      <p>
        Each training session has 9 events where you can interact, train, or
        play with the critter. Each event uses Jigjog Candy. Special Events do
        not use Jigjog Candy.
      </p>
      <SectionHeader title="Gumball Mixing" />
      <p>
        Gumball Mixing doesn’t change a critter’s base stats, only its growth
        rate.
      </p>
      <p>
        Gumball Mixing <strong>does not</strong> destroy the critters you use.
      </p>
      <p>
        Any mix has a chance of producing the <strong>Cartoncle</strong>. This
        is the only way to get them.
      </p>
      <p>
        To push for 1 substat, you should mix critters twice, train the new
        critters with the stat you want, then mix those two together. Keep doing
        this until you reach SSS in the substat you want.
      </p>
      <p>Recommended Critter Training & Mixing Strategy:</p>
      <ul>
        <li>Pull the Dodough, Splintercat, and Teakettler.</li>
        <li>
          If you have 2 Dodoughs, train them in “Efficiency” and mix them. Keep
          mixing the offspring and training them in “Efficiency.”
        </li>
        <li>
          If you have 1 Dodough and 1 Teakettler, train them in “Efficiency” and
          mix them. Mix the offspring with the highest stats together,
          prioritizing Dodoughs.
        </li>
        <li>
          If you have 1 Dodough and 1 Splintercat, train the Dodough in
          “Efficiency” and the Splintercat in “Specialty.” Mix them and their
          offspring, prioritizing Dodoughs.
        </li>
      </ul>
      <SectionHeader title="Best Critters & Layout" />
      <p>Here are the best critters and where you should assign them:</p>
      <ul>
        <li>
          <strong>Splintercat</strong>: Fantastic early game critter, great for
          when you only have 1 Manufacturing slot open.
        </li>
        <li>
          <strong>Dodough</strong>: You should have 12 Dodoughs with SSS
          Efficiency (and as high Specialty as possible) working at every
          building.
        </li>
        <li>
          <strong>Teakettler</strong>: If you only have 1 critter working in
          each building, the Teakettler has the best production for a single
          critter. (You should try to switch to Dodoughs for max efficiency,
          though.)
        </li>
        <li>
          <strong>Refined Groupbuncle</strong>: Their passive helps critters
          regenerate their mood. Assign 1 to every Critter Dwelling.
        </li>
        <li>
          <strong>Cartoncle</strong>: Their passive makes them great at
          transportation because their mood never drops.
        </li>
      </ul>
      <SectionHeader title="Fluff’s Selections" />
      <p>You can spend your Jigjog Coins at Fluff’s Selections in the Shop.</p>
      <ul>
        <li>
          In the early game when you lack critters, you can buy Whirring Winders
          to clear out the Cranking Gumball Machine.
        </li>
        <li>
          In the mid-game, you want to buy Entwined Winders and Timewarp
          Watches. This is a long portion of the gamemode and is kind of a slog,
          as you’re trying to create critters with the best substats possible
          while having to train them all. Timewarp Watches will help speed up
          the training process.
        </li>
        <li>
          The Mood-replenishing foods (Bouncing Meat Jelly, Fermented Herring,
          Chewing Bone, Aphid Honey, Pebble Candy, and Crispy Twigs) are{' '}
          <strong>not worth it</strong>. We do not recommend spending Jigjog
          Coins on them.
        </li>
      </ul>
      <SectionHeader title="References" />
      <a
        href="https://bbs.nga.cn/read.php?tid=41872707"
        target="_blank"
        rel="noreferrer"
      >
        https://bbs.nga.cn/read.php?tid=41872707
      </a>
      <a
        href="https://bbs.nga.cn/read.php?tid=42729059"
        target="_blank"
        rel="noreferrer"
      >
        https://bbs.nga.cn/read.php?tid=42729059
      </a>
      <a
        href="https://bbs.nga.cn/read.php?tid=41888983&_fp=2"
        target="_blank"
        rel="noreferrer"
      >
        https://bbs.nga.cn/read.php?tid=41888983&_fp=2
      </a>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevRaidCritter;

export const Head: React.FC = () => (
  <Seo
    title="Ms. NewBabel's Critter Rehab Center Startup Guide | Reverse: 1999 | Prydwen Institute"
    description="Everything you need to know about the Ms. NewBabel's Critter Rehab Center in Reverse 1999!"
    game="reverse"
  />
);
