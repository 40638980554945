import React from 'react';
import { HSRCharacter } from '../common/components/hsr-character';

export const ProfileMCvsFugue: React.FC = () => {
  return (
    <>
      <h5>Foreword</h5>
      <div className="employee-container for-nikke">
        <HSRCharacter mode="icon" slug="tingyun-fugue" enablePopover />
        <HSRCharacter mode="icon" slug="trailblazer-harmony" enablePopover />
      </div>
      <p>
        In this guide we’ll briefly offer a comparison between Fugue and Harmony
        Trailblazer to highlight each of their benefits when played alongside
        certain characters. Our assumptions:
      </p>
      <ul>
        <li>Both characters have 280% Break Effect (In Combat)</li>
        <li>Harmony Trailblazer is E6 Fugue is E0</li>
      </ul>
      <h5>Super Break</h5>
      <p>
        When it comes to granting the team Super Break, Harmony Trailblazer
        wins, granting up to a 20-60% stronger Super Break buff with the higher
        end of that being when it’s most relevant (on single target).
      </p>
      <ul className="bigger-margin">
        <li>
          <HSRCharacter mode="inline" slug="tingyun-fugue" enablePopover /> -{' '}
          <strong>100%</strong>
        </li>
        <li>
          <HSRCharacter
            mode="inline"
            slug="trailblazer-harmony"
            enablePopover
          />{' '}
          - <strong>120% - 160%</strong>
        </li>
      </ul>
      <h5>Toughness Damage</h5>
      <p>
        When comparing Toughness Damage output, Harmony Trailblazer wins again
        in most cases. Trailblazer usually uses their Skill as frequently as
        possible and it deals a whopping 50 Toughness each time at E6. Compared
        to this Fugue’s enhanced Basic Attack - which she’ll have to weave in
        between Skill uses - only deals 10/20 depending on targeting. It’s not
        all bad news for Fugue though her Ultimate deals 20 Toughness to all
        enemies and completely ignores elements meaning she can deal up to 100
        Toughness unconditionally. In game modes with multiple enemies like Pure
        Fiction this equalizes the comparison somewhat, but Fugue’s Ultimate
        isn’t one that is always easily accessible.
      </p>
      <p>Primary Attack:</p>
      <ul className="bigger-margin">
        <li>
          <HSRCharacter mode="inline" slug="tingyun-fugue" enablePopover /> -{' '}
          Enhanced Basic <strong>(10 Toughness - 20 in Blast)</strong>
        </li>
        <li>
          <HSRCharacter
            mode="inline"
            slug="trailblazer-harmony"
            enablePopover
          />{' '}
          - Skill <strong>(50 Toughness)</strong>
        </li>
      </ul>
      <p>Ultimate:</p>
      <ul className="bigger-margin">
        <li>
          <HSRCharacter mode="inline" slug="tingyun-fugue" enablePopover /> -{' '}
          <strong>20 Toughness per enemy</strong>
        </li>
        <li>
          <HSRCharacter
            mode="inline"
            slug="trailblazer-harmony"
            enablePopover
          />{' '}
          - <strong>0</strong>
        </li>
      </ul>
      <h5>Break Effect % Buffs</h5>
      <p>
        When it comes to Break Effect buffs, it’s a question of teams and
        investment. It’s important to note that Fugue’s Break buffs are tied to
        first breaking enemies and her skill Foxian Prayer - while Trailblazer’s
        is tied half to their Ultimate and half to a passive bonus. Comparing
        the two, Harmony Trailblazer has a slight edge as their buffs can apply
        to the whole team effective immediately at the start of combat while
        Fugue must select only a single target for Foxian Prayer and has to wait
        for enemies to be broken first. The downside of waiting for enemies to
        be broken isn’t a big deal in most fights and most Break teams generally
        only have 1 carry so while it’s a difference it isn’t a major one.
      </p>
      <p>
        At modest investment Fugue and Harmony Trailblazer’s Break buffs offer
        similar increases but it’s important to note that Harmony Trailblazer’s
        buff has the potential to scale much higher as it grants Break Effect%
        based on their own while Fugue’s is a static amount.
      </p>
      <ul className="bigger-margin">
        <li>
          <HSRCharacter mode="inline" slug="tingyun-fugue" enablePopover /> -{' '}
          <strong>36%</strong> (66% for target of Foxian Prayer)
        </li>
        <li>
          <HSRCharacter
            mode="inline"
            slug="trailblazer-harmony"
            enablePopover
          />{' '}
          - <strong>30%</strong> (72% With E4)
        </li>
      </ul>
      <h5>Additional Action Delay</h5>
      <p>
        For Action delay bonuses both characters are nearly the same. Harmony
        Trailblazer grants an extra 30% action delay on Break whilst Fugue
        grants 15% but allows enemies to be broken twice thanks to her Exo
        Toughness. Technically Fugue has the edge here as 2 breaks will not only
        give her 2x 15% delay but also the base delay associated with breaking
        enemies in the first place.
      </p>
      <ul className="bigger-margin">
        <li>
          <HSRCharacter mode="inline" slug="tingyun-fugue" enablePopover /> -{' '}
          <strong>15% </strong> (30% Including Double Break)
        </li>
        <li>
          <HSRCharacter
            mode="inline"
            slug="trailblazer-harmony"
            enablePopover
          />{' '}
          - <strong>30%</strong>
        </li>
      </ul>
      <h5>Extra Bonuses</h5>
      <p>
        When it comes to extra bonuses this is where Fugue shines as she has
        many more over Harmony Trailblazer - with each of them being potential
        game changers for your team.
      </p>
      <p>
        Fugue’s Exo Toughness “Cloudflame Luster” is an absurdly strong
        mechanic, allowing enemies to be Broken twice triggering break damage,
        any character based on Break Effect and break delays at double the
        value. For some characters this is quite literally a full transformation
        in how they feel to play allowing Fugue a huge edge over Trailblazer in
        these cases.
      </p>
      <p>
        Fugue also possesses a DEF Shred Debuff in her kit - something all Break
        damage dealers appreciate and something which gets stronger the more you
        have. Many Break damage dealers have DEF Shred on their Eidolons, Light
        Cones and Relics Sets making this bonus more powerful than it might
        seem.
      </p>
      <p>
        Fugue can grant a teammate of her choice the ability to deal 50% of
        their Toughness Damage as though they ignore enemy weakness types,
        allowing them to effectively break any element at any time opening up
        serious flexibility. This is an ability many of the premiere Break
        damage dealers have baked into their kit but is invaluable for those who
        don’t.
      </p>
      <p>
        Finally Fugue is more Skill Point friendly than Harmony Trailblazer,
        allowing for a little easier time in some team compositions which
        previously were incredibly tight on resources.
      </p>
      <p>
        When it comes to equipment there is a little more of a discussion as
        things don’t all go Fugue’s way. Harmony Trailblazer is able to equip
        and maintain the 4P Watchmaker set, granting a further 30% Break Effect
        and allowing them to pull further ahead of Fugue in the buffing
        department. Trailblazer can also equip Dance! Dance! Dance! - an
        absurdly powerful supportive Cone which absolutely can make the
        difference especially for min-maxers. As a Nihility Fugue has some
        specialized equipment choice of her own - she can catch up in the
        buffing/debuffing department with a further 16% DEF Shred from S5 Pearls
        of Sweat or 36% Break damage taken debuff from her signature.
      </p>
      <ul className="bigger-margin">
        <li>
          <HSRCharacter mode="inline" slug="tingyun-fugue" enablePopover />
        </li>
        <ul>
          <li>18% DEF Shred,</li>
          <li>
            Allows 1 team member to strike non element weak enemies for 50% of
            Toughness damage,
          </li>
          <li>Applies Exo Toughness allowing for double breaks,</li>
          <li>More Skill Point Friendly,</li>
          <li>Strong Nihility Light Cone Options:</li>
          <ul>
            <li>Further 16% DEF Shred From S5 Pearls of Sweat,</li>
            <li>36% Break DMG Taken from S1 Long Road Leads Home.</li>
          </ul>
        </ul>
        <li>
          <HSRCharacter
            mode="inline"
            slug="trailblazer-harmony"
            enablePopover
          />
        </li>
        <ul>
          <li>
            Can use and reliably keep active the 4P Watchmaker set for 30% Break
            Effect,
          </li>
          <li>
            Able to equip Dance Dance Dance! for frequent team wide advances.
          </li>
        </ul>
      </ul>
      <h5>Conclusion</h5>
      <p>
        To conclude, Harmony Trailblazer has the edge when it comes to raw
        numbers on Super Break, increases to Break Effect and Toughness Damage
        output with some great equipment options which Fugue doesn’t have access
        to. But Fugue has an incredible array of potent specialized effects
        which in certain teams make it an auto-in for her due to pure mechanical
        advantage. For characters that can take advantage of one or multiple of
        Fugues special advantages, she’s the clear winner no question. But for
        characters that don’t particularly lean into any of them, Harmony
        Trailblazer regains ground and can even serve as equal or a bit stronger
        option.
      </p>
      <p>Here's how it looks for some characters:</p>
      <ul className="bigger-margin">
        <li>
          <HSRCharacter mode="inline" slug="rappa" enablePopover /> wants to
          score as many Breaks as possible. Fugue’s Exo Toughness allows her to
          do just that at twice the effectiveness. This alone gives Fugue the
          edge in her team,
        </li>
        <li>
          <HSRCharacter mode="inline" slug="boothill" enablePopover /> can take
          advantage of Fugue’s Exo Toughness to deal far more initial Break
          damage to each enemy, but more importantly can also ramp up his Pocket
          Trickshot stacks faster as it’s possible for him to Break an enemy
          twice in one shot. The ability to grant Boothill the 50% Toughness
          ignore is also handy for the ranger, as it reduces his reliance on his
          Ultimate. All this combined gives Fugue the edge in Boothill teams.
        </li>
        <li>
          <HSRCharacter mode="inline" slug="firefly" enablePopover /> doesn’t
          benefit from Fugue’s Exo Toughness as much as other characters - only
          gaining the additional Break damage that comes with breaking twice.
          She also already has her own 50% Toughness ignore and a Fire Implant
          which means most enemies should be Fire Weak already and if they’re
          not she has what Fugue offers already. What Fugue can help with
          though, is DEF Shred and Skill point economy - two things Firefly
          appreciates greatly especially at higher investment. With that said
          Harmony Trailblazer’s additional Buffs and Super Break Multipliers for
          Firefly are a big deal. Fugue’s special benefits aren’t as much use to
          Firefly as other characters and she benefits more from the higher
          Multipliers Trailblazer offers. Because of this which is better will
          come down to the circumstances of your account, the enemy encounter
          and player preference.
        </li>
        <li>
          <HSRCharacter mode="inline" slug="himeko" enablePopover /> - Similar
          to Rappa, Fugue's "Cloudflame Luster" turbo charges Himeko's talent
          and Charge gain, allowing her to do what she does best - deploying the
          pizza cutter more often. Fugue also grants Himeko the ability to
          finally deal with non-fire weak enemies thanks to the 50% Toughness
          ignore. The edge goes to Fugue in solo Himeko teams,
        </li>
        <li>
          <HSRCharacter mode="inline" slug="xueyi" enablePopover />{' '}
          <HSRCharacter mode="inline" slug="sushang" enablePopover /> &{' '}
          <HSRCharacter mode="inline" slug="luka" enablePopover /> will all
          benefit from the Exo-Toughness, drastically improving their potential
          and performance. Harmony MC doesn't really do much for them.
        </li>
      </ul>
    </>
  );
};
