export const ZZZAssault_2 = [
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 6.84,
    rank: 1,
    avg_round: 19284,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 6.37,
    rank: 2,
    avg_round: 22188,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 4.61,
    rank: 3,
    avg_round: 22171,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 4.12,
    rank: 4,
    avg_round: 20506,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 3.92,
    rank: 5,
    avg_round: 28158,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 2.73,
    rank: 6,
    avg_round: 19608,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 2.65,
    rank: 7,
    avg_round: 15270,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 2.55,
    rank: 8,
    avg_round: 14777,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 2.53,
    rank: 9,
    avg_round: 24762,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 2.4,
    rank: 10,
    avg_round: 17463,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 2.08,
    rank: 11,
    avg_round: 21009,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 2.01,
    rank: 12,
    avg_round: 17248,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 1.96,
    rank: 13,
    avg_round: 25218,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 1.88,
    rank: 14,
    avg_round: 19713,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 1.81,
    rank: 15,
    avg_round: 20821,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 1.59,
    rank: 16,
    avg_round: 18191,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 1.54,
    rank: 17,
    avg_round: 19492,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 1.54,
    rank: 17,
    avg_round: 22067,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 1.49,
    rank: 19,
    avg_round: 26258,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'rina',
    app_rate: 1.34,
    rank: 20,
    avg_round: 24313,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 1.34,
    rank: 20,
    avg_round: 24505,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 1.26,
    rank: 22,
    avg_round: 14427,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'caesar',
    app_rate: 1.19,
    rank: 23,
    avg_round: 23555,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 1.14,
    rank: 24,
    avg_round: 19382,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'caesar',
    app_rate: 1.07,
    rank: 25,
    avg_round: 17552,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 1.02,
    rank: 26,
    avg_round: 22773,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.97,
    rank: 27,
    avg_round: 23184,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.84,
    rank: 28,
    avg_round: 19778,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.72,
    rank: 29,
    avg_round: 21763,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 0.69,
    rank: 30,
    avg_round: 19741,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.69,
    rank: 30,
    avg_round: 19118,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.67,
    rank: 32,
    avg_round: 16698,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.64,
    rank: 33,
    avg_round: 20159,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.62,
    rank: 34,
    avg_round: 20287,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.57,
    rank: 35,
    avg_round: 16906,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.52,
    rank: 36,
    avg_round: 19369,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.52,
    rank: 36,
    avg_round: 11906,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 0.5,
    rank: 38,
    avg_round: 21137,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 0.47,
    rank: 39,
    avg_round: 18550,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.45,
    rank: 40,
    avg_round: 16198,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'lucy',
    app_rate: 0.45,
    rank: 40,
    avg_round: 15773,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.45,
    rank: 40,
    avg_round: 17328,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.4,
    rank: 43,
    avg_round: 9960,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.4,
    rank: 43,
    avg_round: 17181,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.4,
    rank: 43,
    avg_round: 13265,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.37,
    rank: 46,
    avg_round: 22500,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.37,
    rank: 46,
    avg_round: 20492,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.37,
    rank: 46,
    avg_round: 22883,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'seth',
    app_rate: 0.37,
    rank: 46,
    avg_round: 9895,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'rina',
    app_rate: 0.35,
    rank: 50,
    avg_round: 22558,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.35,
    rank: 50,
    avg_round: 16179,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.35,
    rank: 50,
    avg_round: 12814,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.32,
    rank: 53,
    avg_round: 19497,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.32,
    rank: 53,
    avg_round: 22661,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.32,
    rank: 53,
    avg_round: 15497,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.32,
    rank: 53,
    avg_round: 9274,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'seth',
    app_rate: 0.32,
    rank: 53,
    avg_round: 12487,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.32,
    rank: 53,
    avg_round: 21432,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.27,
    rank: 59,
    avg_round: 14860,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'seth',
    app_rate: 0.27,
    rank: 59,
    avg_round: 11595,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'caesar',
    app_rate: 0.27,
    rank: 59,
    avg_round: 17853,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.27,
    rank: 59,
    avg_round: 14777,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.27,
    rank: 59,
    avg_round: 13736,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.27,
    rank: 59,
    avg_round: 10787,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.25,
    rank: 65,
    avg_round: 19142,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.25,
    rank: 65,
    avg_round: 19224,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'qingyi',
    app_rate: 0.25,
    rank: 65,
    avg_round: 16544,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.22,
    rank: 68,
    avg_round: 31551,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'koleda',
    char_three: 'rina',
    app_rate: 0.22,
    rank: 68,
    avg_round: 14042,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.22,
    rank: 68,
    avg_round: 32657,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.22,
    rank: 68,
    avg_round: 17870,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.2,
    rank: 72,
    avg_round: 17710,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.2,
    rank: 72,
    avg_round: 18267,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.2,
    rank: 72,
    avg_round: 17006,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.2,
    rank: 72,
    avg_round: 20066,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'anby-demara',
    app_rate: 0.2,
    rank: 72,
    avg_round: 16554,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.17,
    rank: 77,
    avg_round: 15868,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.17,
    rank: 77,
    avg_round: 24528,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.17,
    rank: 77,
    avg_round: 22640,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.17,
    rank: 77,
    avg_round: 25937,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.17,
    rank: 77,
    avg_round: 26678,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.17,
    rank: 77,
    avg_round: 15487,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.17,
    rank: 77,
    avg_round: 19754,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'qingyi',
    app_rate: 0.17,
    rank: 77,
    avg_round: 15917,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.15,
    rank: 85,
    avg_round: 22030,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'seth',
    app_rate: 0.15,
    rank: 85,
    avg_round: 13910,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.15,
    rank: 85,
    avg_round: 14327,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.15,
    rank: 85,
    avg_round: 17927,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.15,
    rank: 85,
    avg_round: 21627,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 0.15,
    rank: 85,
    avg_round: 16922,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'soukaku',
    char_three: 'seth',
    app_rate: 0.15,
    rank: 85,
    avg_round: 19101,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'burnice',
    app_rate: 0.15,
    rank: 85,
    avg_round: 19984,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 93,
    avg_round: 21102,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'piper',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 93,
    avg_round: 17041,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.12,
    rank: 93,
    avg_round: 13293,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.12,
    rank: 93,
    avg_round: 12301,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 93,
    avg_round: 17427,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'lighter',
    app_rate: 0.12,
    rank: 93,
    avg_round: 23142,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.12,
    rank: 93,
    avg_round: 19417,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'seth',
    app_rate: 0.12,
    rank: 93,
    avg_round: 13815,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 93,
    avg_round: 14941,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.12,
    rank: 93,
    avg_round: 12920,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 93,
    avg_round: 19307,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 93,
    avg_round: 21546,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'koleda',
    app_rate: 0.12,
    rank: 93,
    avg_round: 12102,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 93,
    avg_round: 22666,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.12,
    rank: 93,
    avg_round: 7407,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'qingyi',
    app_rate: 0.12,
    rank: 93,
    avg_round: 13764,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.12,
    rank: 93,
    avg_round: 9310,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.12,
    rank: 93,
    avg_round: 10381,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 93,
    avg_round: 13724,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 112,
    avg_round: 21061,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 112,
    avg_round: 17199,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 112,
    avg_round: 28939,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'grace-howard',
    char_three: 'lucy',
    app_rate: 0.1,
    rank: 112,
    avg_round: 15078,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.1,
    rank: 112,
    avg_round: 22514,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'jane-doe',
    app_rate: 0.1,
    rank: 112,
    avg_round: 19970,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.1,
    rank: 112,
    avg_round: 23420,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'qingyi',
    app_rate: 0.1,
    rank: 112,
    avg_round: 16310,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.1,
    rank: 112,
    avg_round: 16422,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.1,
    rank: 112,
    avg_round: 19449,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.1,
    rank: 112,
    avg_round: 15458,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 112,
    avg_round: 12421,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 0.1,
    rank: 112,
    avg_round: 12394,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'soukaku',
    char_three: 'seth',
    app_rate: 0.1,
    rank: 112,
    avg_round: 13380,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'grace-howard',
    char_three: 'lucy',
    app_rate: 0.1,
    rank: 112,
    avg_round: 14507,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lighter',
    char_three: 'rina',
    app_rate: 0.1,
    rank: 112,
    avg_round: 16744,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.1,
    rank: 112,
    avg_round: 21546,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.1,
    rank: 112,
    avg_round: 13971,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'qingyi',
    app_rate: 0.1,
    rank: 112,
    avg_round: 10572,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'koleda',
    char_three: 'soukaku',
    app_rate: 0.1,
    rank: 112,
    avg_round: 13513,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 0.1,
    rank: 112,
    avg_round: 15828,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 133,
    avg_round: 18860,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 133,
    avg_round: 18686,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'soukaku',
    app_rate: 0.07,
    rank: 133,
    avg_round: 23871,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 133,
    avg_round: 12629,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 133,
    avg_round: 19200,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 133,
    avg_round: 14246,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 133,
    avg_round: 26078,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 133,
    avg_round: 21117,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lighter',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 133,
    avg_round: 17385,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'jane-doe',
    char_three: 'anby-demara',
    app_rate: 0.07,
    rank: 133,
    avg_round: 10567,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'piper',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 133,
    avg_round: 16256,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.07,
    rank: 133,
    avg_round: 9986,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'anby-demara',
    app_rate: 0.07,
    rank: 133,
    avg_round: 13786,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 133,
    avg_round: 15380,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 133,
    avg_round: 16690,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.07,
    rank: 133,
    avg_round: 20079,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 133,
    avg_round: 15878,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.07,
    rank: 133,
    avg_round: 11174,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 133,
    avg_round: 30431,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 133,
    avg_round: 18608,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.07,
    rank: 133,
    avg_round: 28598,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 133,
    avg_round: 9866,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'qingyi',
    app_rate: 0.07,
    rank: 133,
    avg_round: 14664,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.07,
    rank: 133,
    avg_round: 24195,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.07,
    rank: 133,
    avg_round: 16798,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 133,
    avg_round: 16263,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 133,
    avg_round: 23244,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'piper',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 133,
    avg_round: 10712,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 133,
    avg_round: 16760,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.05,
    rank: 162,
    avg_round: 10196,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 162,
    avg_round: 21636,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'koleda',
    app_rate: 0.05,
    rank: 162,
    avg_round: 11460,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 162,
    avg_round: 22202,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 162,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 162,
    avg_round: 7662,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 162,
    avg_round: 13212,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.05,
    rank: 162,
    avg_round: 12544,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 162,
    avg_round: 21947,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 162,
    avg_round: 16331,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'lycaon',
    char_three: 'anby-demara',
    app_rate: 0.05,
    rank: 162,
    avg_round: 13740,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.05,
    rank: 162,
    avg_round: 19456,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 162,
    avg_round: 24417,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 162,
    avg_round: 23236,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 162,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 162,
    avg_round: 15495,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 162,
    avg_round: 20288,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 162,
    avg_round: 24788,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 162,
    avg_round: 19552,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'anby-demara',
    app_rate: 0.05,
    rank: 162,
    avg_round: 21345,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'soukaku',
    app_rate: 0.05,
    rank: 162,
    avg_round: 17824,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 162,
    avg_round: 19402,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 162,
    avg_round: 18894,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.05,
    rank: 162,
    avg_round: 8271,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 162,
    avg_round: 7878,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'anby-demara',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 162,
    avg_round: 9858,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 162,
    avg_round: 6268,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'anby-demara',
    app_rate: 0.05,
    rank: 162,
    avg_round: 12714,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 162,
    avg_round: 14602,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'soukaku',
    app_rate: 0.05,
    rank: 162,
    avg_round: 13409,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 162,
    avg_round: 10111,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 162,
    avg_round: 17056,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 162,
    avg_round: 9434,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 162,
    avg_round: 24406,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 162,
    avg_round: 8676,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'qingyi',
    app_rate: 0.05,
    rank: 162,
    avg_round: 14811,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.05,
    rank: 162,
    avg_round: 23946,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'piper',
    char_three: 'qingyi',
    app_rate: 0.05,
    rank: 162,
    avg_round: 10650,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'yanagi',
    app_rate: 0.05,
    rank: 162,
    avg_round: 13574,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 162,
    avg_round: 8356,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 162,
    avg_round: 12952,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 162,
    avg_round: 16309,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.05,
    rank: 162,
    avg_round: 15361,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 162,
    avg_round: 20818,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 162,
    avg_round: 13643,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 162,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 162,
    avg_round: 7879,
    star_num: '4'
  }
];
