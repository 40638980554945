export const Shiyu6_2 = [
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 11.23,
    rank: 1,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 11.11,
    rank: 2,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 7.26,
    rank: 3,
    avg_round: 116,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 5.66,
    rank: 4,
    avg_round: 115,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Seth',
    app_rate: 5.11,
    rank: 5,
    avg_round: 124,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 4.78,
    rank: 6,
    avg_round: 126,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 4.68,
    rank: 7,
    avg_round: 131,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 3.42,
    rank: 8,
    avg_round: 112,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 3.03,
    rank: 9,
    avg_round: 142,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 2.56,
    rank: 10,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 1.59,
    rank: 11,
    avg_round: 103,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Rina',
    app_rate: 1.34,
    rank: 12,
    avg_round: 93,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 1.26,
    rank: 13,
    avg_round: 158,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 1.22,
    rank: 14,
    avg_round: 158,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 1.2,
    rank: 15,
    avg_round: 100,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Caesar',
    app_rate: 1.2,
    rank: 15,
    avg_round: 87,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 1.08,
    rank: 17,
    avg_round: 86,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.96,
    rank: 18,
    avg_round: 151,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.92,
    rank: 19,
    avg_round: 147,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.81,
    rank: 20,
    avg_round: 132,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.69,
    rank: 21,
    avg_round: 115,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.65,
    rank: 22,
    avg_round: 132,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Rina',
    app_rate: 0.63,
    rank: 23,
    avg_round: 104,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.57,
    rank: 24,
    avg_round: 135,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.57,
    rank: 24,
    avg_round: 109,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.55,
    rank: 26,
    avg_round: 151,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.53,
    rank: 27,
    avg_round: 135,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.53,
    rank: 27,
    avg_round: 122,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.51,
    rank: 29,
    avg_round: 135,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Caesar',
    app_rate: 0.51,
    rank: 29,
    avg_round: 113,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.49,
    rank: 31,
    avg_round: 143,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.47,
    rank: 32,
    avg_round: 155,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.45,
    rank: 33,
    avg_round: 115,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.45,
    rank: 33,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.43,
    rank: 35,
    avg_round: 124,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Caesar',
    app_rate: 0.41,
    rank: 36,
    avg_round: 137,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.39,
    rank: 37,
    avg_round: 151,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.35,
    rank: 38,
    avg_round: 140,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.35,
    rank: 38,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lighter',
    char_three: 'Caesar',
    app_rate: 0.33,
    rank: 40,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.33,
    rank: 40,
    avg_round: 143,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.33,
    rank: 40,
    avg_round: 105,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.33,
    rank: 40,
    avg_round: 154,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.31,
    rank: 44,
    avg_round: 109,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.31,
    rank: 44,
    avg_round: 86,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.31,
    rank: 44,
    avg_round: 131,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.31,
    rank: 44,
    avg_round: 110,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Lighter',
    app_rate: 0.31,
    rank: 44,
    avg_round: 112,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Lucy',
    app_rate: 0.31,
    rank: 44,
    avg_round: 91,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.28,
    rank: 50,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.28,
    rank: 50,
    avg_round: 145,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.26,
    rank: 52,
    avg_round: 135,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Qingyi',
    app_rate: 0.26,
    rank: 52,
    avg_round: 127,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.26,
    rank: 52,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Burnice',
    app_rate: 0.24,
    rank: 55,
    avg_round: 101,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.24,
    rank: 55,
    avg_round: 149,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.24,
    rank: 55,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Seth',
    app_rate: 0.22,
    rank: 58,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Koleda',
    app_rate: 0.22,
    rank: 58,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.22,
    rank: 58,
    avg_round: 138,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.22,
    rank: 58,
    avg_round: 146,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Rina',
    app_rate: 0.2,
    rank: 62,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.2,
    rank: 62,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.18,
    rank: 64,
    avg_round: 111,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.18,
    rank: 64,
    avg_round: 112,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Burnice',
    app_rate: 0.18,
    rank: 64,
    avg_round: 125,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lighter',
    char_three: 'Caesar',
    app_rate: 0.18,
    rank: 64,
    avg_round: 128,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Rina',
    app_rate: 0.16,
    rank: 68,
    avg_round: 102,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Soukaku',
    app_rate: 0.16,
    rank: 68,
    avg_round: 128,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.16,
    rank: 68,
    avg_round: 167,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.16,
    rank: 68,
    avg_round: 164,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.16,
    rank: 68,
    avg_round: 134,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.16,
    rank: 68,
    avg_round: 156,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.14,
    rank: 74,
    avg_round: 114,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Lighter',
    app_rate: 0.14,
    rank: 74,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.14,
    rank: 74,
    avg_round: 95,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Burnice',
    app_rate: 0.14,
    rank: 74,
    avg_round: 133,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.12,
    rank: 78,
    avg_round: 142,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 78,
    avg_round: 103,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Burnice',
    char_three: 'Lighter',
    app_rate: 0.12,
    rank: 78,
    avg_round: 143,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 0.12,
    rank: 78,
    avg_round: 115,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Lighter',
    char_three: 'Caesar',
    app_rate: 0.12,
    rank: 78,
    avg_round: 161,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 83,
    avg_round: 133,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 83,
    avg_round: 85,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.1,
    rank: 83,
    avg_round: 131,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 83,
    avg_round: 124,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.1,
    rank: 83,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.1,
    rank: 83,
    avg_round: 152,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.1,
    rank: 83,
    avg_round: 195,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.1,
    rank: 83,
    avg_round: 139,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.1,
    rank: 83,
    avg_round: 150,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Seth',
    app_rate: 0.1,
    rank: 83,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 93,
    avg_round: 177,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 93,
    avg_round: 97,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 93,
    avg_round: 186,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 93,
    avg_round: 143,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 93,
    avg_round: 128,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Lighter',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 93,
    avg_round: 124,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 93,
    avg_round: 122,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'anby-demara',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 93,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 93,
    avg_round: 112,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 93,
    avg_round: 150,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 93,
    avg_round: 190,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 93,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 93,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Yanagi',
    char_three: 'Qingyi',
    app_rate: 0.08,
    rank: 93,
    avg_round: 152,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 93,
    avg_round: 139,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Qingyi',
    app_rate: 0.06,
    rank: 108,
    avg_round: 166,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 108,
    avg_round: 121,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.06,
    rank: 108,
    avg_round: 240,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 108,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.06,
    rank: 108,
    avg_round: 145,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'Yanagi',
    app_rate: 0.06,
    rank: 108,
    avg_round: 111,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 108,
    avg_round: 188,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 108,
    avg_round: 126,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Koleda',
    app_rate: 0.06,
    rank: 108,
    avg_round: 157,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 108,
    avg_round: 137,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.06,
    rank: 108,
    avg_round: 154,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 108,
    avg_round: 136,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 108,
    avg_round: 159,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Seth',
    app_rate: 0.06,
    rank: 108,
    avg_round: 110,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Lucy',
    char_three: 'Ben',
    app_rate: 0.06,
    rank: 108,
    avg_round: 157,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 108,
    avg_round: 122,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 108,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 108,
    avg_round: 128,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Yanagi',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 108,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 108,
    avg_round: 180,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 108,
    avg_round: 134,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 108,
    avg_round: 122,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'jane-doe',
    app_rate: 0.06,
    rank: 108,
    avg_round: 155,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Lighter',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 108,
    avg_round: 186,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Ben',
    app_rate: 0.06,
    rank: 108,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 133,
    avg_round: 143,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.04,
    rank: 133,
    avg_round: 204,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 133,
    avg_round: 134,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 133,
    avg_round: 169,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 133,
    avg_round: 190,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lucy',
    char_three: 'Ben',
    app_rate: 0.04,
    rank: 133,
    avg_round: 137,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 133,
    avg_round: 122,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 133,
    avg_round: 150,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 133,
    avg_round: 110,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Burnice',
    app_rate: 0.04,
    rank: 133,
    avg_round: 200,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 133,
    avg_round: 138,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.04,
    rank: 133,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 133,
    avg_round: 116,
    star_num: '4'
  },
  {
    char_one: 'Lucy',
    char_two: 'Ben',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 133,
    avg_round: 166,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 133,
    avg_round: 125,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 133,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 133,
    avg_round: 176,
    star_num: '4'
  },
  {
    char_one: 'Lighter',
    char_two: 'Lucy',
    char_three: 'Ben',
    app_rate: 0.04,
    rank: 133,
    avg_round: 140,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 133,
    avg_round: 78,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 133,
    avg_round: 116,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Ben',
    app_rate: 0.04,
    rank: 133,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'Burnice',
    app_rate: 0.04,
    rank: 133,
    avg_round: 154,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 133,
    avg_round: 138,
    star_num: '4'
  },
  {
    char_one: 'Lighter',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 133,
    avg_round: 160,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 133,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 133,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 133,
    avg_round: 132,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.04,
    rank: 133,
    avg_round: 150,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.04,
    rank: 133,
    avg_round: 211,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Qingyi',
    app_rate: 0.04,
    rank: 133,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 133,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.04,
    rank: 133,
    avg_round: 205,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 133,
    avg_round: 96,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'grace-howard',
    app_rate: 0.04,
    rank: 133,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 133,
    avg_round: 192,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Yanagi',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 133,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Koleda',
    app_rate: 0.04,
    rank: 133,
    avg_round: 139,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 133,
    avg_round: 239,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 133,
    avg_round: 147,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 133,
    avg_round: 242,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 133,
    avg_round: 142,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 133,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Burnice',
    app_rate: 0.04,
    rank: 133,
    avg_round: 125,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Piper',
    char_three: 'Soukaku',
    app_rate: 0.04,
    rank: 133,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 133,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 133,
    avg_round: 140,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 133,
    avg_round: 143,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 133,
    avg_round: 97,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.04,
    rank: 133,
    avg_round: 164,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 133,
    avg_round: 132,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 133,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 133,
    avg_round: 146,
    star_num: '4'
  }
];
