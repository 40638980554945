import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';
import { GFLCharacter } from '../../../modules/exilium/components/exilium-character';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Button, Row, Col, Card, Alert } from 'react-bootstrap';
import { Box } from '../../../modules/common/components/box';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const ExiliumGuidesReroll: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page exilium-page gfl-guide'}
      game="exilium"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/gfl-exilium/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Reroll Guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/exilium/categories/category_reroll.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>GFL 2: Reroll Guide</h1>
          <h2>
            The best characters to reroll for in Girls' Frontline 2: Exilium
            (GFL 2).
          </h2>
          <p>
            Last updated: <strong>25/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Is it even worth to reroll?" />
        <p>
          The short answer is <strong>maybe</strong>. Whether rerolling in this
          game is worth it, depends on what type of player you are - below are
          some points you should consider before rerolling.
        </p>
        <h6>Why you should consider rerolling</h6>
        <ul>
          <li>
            Some of the characters available on the{' '}
            <strong>Standard character pool are obtainable for free</strong> if
            you play the game long enough - so eventually you will get them and
            their dupes (
            <GFLCharacter
              slug="sabrina"
              mode="inline"
              enablePopover
              showLabel
            />{' '}
            and{' '}
            <GFLCharacter
              slug="peritya"
              mode="inline"
              enablePopover
              showLabel
            />
            ). By rerolling you can avoid starting with a character that you
            will get for free either way.
          </li>
          <li>
            <strong>
              There's a huge disparity in strength between Standard characters
              obtained from the starter banner.
            </strong>{' '}
            On one side we have{' '}
            <GFLCharacter
              slug="qiongjiu"
              mode="inline"
              enablePopover
              showLabel
            />{' '}
            who is one of the best characters in CN nowadays (the game is 1 year
            old there), and on the other hand we have{' '}
            <GFLCharacter slug="vepley" mode="inline" enablePopover showLabel />{' '}
            who gets completely powercrept by a welfare unit{' '}
            <GFLCharacter slug="lotta" mode="inline" enablePopover showLabel />{' '}
            (who can be obtained on the first event for the Global release to
            make things even funnier).
          </li>
        </ul>
        <h6>Why rerolling may not be worth it</h6>
        <ul>
          <li>
            You <strong>can't just delete your account</strong> and start fresh
            instantly as the feature has a lengthy timer.
          </li>
          <li>
            Rerolling takes quite some time - you will need to do the tutorial
            and complete 1-4 to be unlock summoning feature.{' '}
            <strong>It takes around 10-12 minutes per account.</strong>
          </li>
          <li>
            The rate is not the best for rerollers. Even if we consider maxed
            out pre-registration rewards <strong>20 standard pulls</strong>,
            it's still only a <strong>0.6%</strong> chance of getting a SSR
            character per pull (on Beginner Banner) and <strong>0.3%</strong> on
            Standard Banner (because 0.3% is taken by Weapons). And if you want
            to aim for a specific Standard character, things get even worse.
          </li>
          <ul>
            <li>
              Seems there's additional 10 pulls waiting in the mail, so this
              means you reroll with 30 pulls,
            </li>
            <li>
              Though there's a chance we will get additional 10 pulls if the
              game hits top spots in Apple/Google Stores,
            </li>
          </ul>
          <li>
            A Beginner banner exists that guarantees a{' '}
            <strong>random SSR standard character within 50 pulls</strong> and
            the banner features 20% discount (so you need 40 Standard Summon
            Tickets to do 50 pulls).
          </li>
          <ul>
            <li>
              You can obtain more than 1 SSR character from the Beginner banner
              if you're lucky, but the pity will only trigger once. So if you
              already pulled a SSR before the 50th pull, it won't trigger again.
            </li>
          </ul>
          <li>
            Rerolling for the rate-up character is even harder as you get very
            little premium currency and it's enough to do a few pulls at most (4
            to be exact unless the codes give more).
          </li>
        </ul>
        <SectionHeader title="How to reroll" />
        <Alert variant="primary alert-red ">
          <p>
            It seems that{' '}
            <strong>
              Haoplay has removed both Guest Account and blocked the Salt Method
            </strong>
            , so you can't really reroll anymore on their servers. Both methods
            still work on Darkwinter servers.
          </p>
        </Alert>
        <h5>Reroll methods</h5>
        <p>You have two options when it comes to the method of rerolling:</p>
        <ul>
          <li>
            Using an <strong>Emulator to create multiple instances</strong>{' '}
            which will drastically increase the speed of rolling - and we
            suggest using <strong>BlueStacks</strong> for the process.
          </li>
          <ul>
            <li>
              Just keep in mind to create a master instance first and clone it
              without opening the game there - just to prevent account cookie to
              be created which will prevent you from multi-instancing.
            </li>
            <li>Also always login as Guest.</li>
          </ul>
          <li>
            Use the <strong>salt account email</strong> method, but keep in mind
            it's slower. Here's how it works:
          </li>
          <ul>
            <li>
              If your email is abc@gmail.com, add +numbers (anything is fine,
              but numbers make tracking the accounts easier) which makes it
              abc+1@gmail.com. Any email send to abc+1@gmail.com will to
              abc@gmail.com.
            </li>
          </ul>
        </ul>
        <h5>Reroll Process</h5>
        <ul>
          <li>
            Login through email option with salted email or use a Guest Account,
          </li>
          <li>Complete stages up to 1-4 where you will unlock gacha,</li>
          <li>Claim your mail rewards,</li>
          <li>Use all available codes (listed below),</li>
          <li>
            Use your pulls - and the options that you have there will be
            explained in the next section,
          </li>
          <li>Repeat step 1 to 5 until you are satisfied with your results,</li>
          <li>
            Keep track of that salted email and bind other credentials to the
            email.
          </li>
        </ul>
        <p>
          If you're planning to reroll using Emulator, we suggest using{' '}
          <strong>BlueStacks</strong>! You can download it from the link below
          and by doing so, support the website!
        </p>
        <div className="gfl-promo">
          <h4>Play & Reroll GFL 2 on PC</h4>
          <OutboundLink href="https://bstk.me/o485XScik" target="_blank">
            <Button variant="primary">Reroll now on BlueStacks</Button>
          </OutboundLink>
        </div>
        <SectionHeader title="Codes" />
        <p>
          Here's the list of active codes. Currently, we don't know what they
          give and we will update it post launch.{' '}
          <strong>How to redeem codes?</strong> Click the Settings button on the
          bottom left corner of the game's main interface, and click [Redeem
          Code] on the bottom right corner of the Personal Information page.
        </p>
        <p>Only codes that give summon currency are listed below.</p>
        <div className="codes">
          <Box isCentered>
            <p className="code">Darkwinter Servers</p>
            <ul>
              <li>
                <strong className="blue">DC100K</strong> <span>NEW!</span>
              </li>
              <li>
                <strong className="blue">ELMOLIVE</strong>
              </li>
              <li>
                <strong className="blue">OMNGKTWN</strong>
              </li>

              <li>
                <strong className="blue">GFL2GIFT</strong>
              </li>

              <li>
                <strong className="blue">1203GFL2</strong>
              </li>
            </ul>
          </Box>
          <Box isCentered>
            <p className="code">Haoplay Servers</p>
            <ul>
              <li>
                <strong className="blue">DC100K</strong> <span>NEW!</span>
              </li>
              <li>
                <strong className="blue">ELMOLIVE</strong>
              </li>
              <li>
                <strong className="blue">OMNGKTWN</strong>
              </li>
            </ul>
          </Box>
        </div>
        <p>
          There are more active codes, but only those give Collapse Pieces (used
          for summoning).
        </p>
        <SectionHeader title="Reroll paths" />
        <p>
          Now, you will have to choose two paths for the reroll, depending on
          how much free time you have and how much sanity are you willing to
          lose.
        </p>
        <Row xs={1} xl={2} xxl={2} className="reroll-paths">
          <Col>
            <div className="box">
              <div className="option hard">
                <p>Hell Path</p>
              </div>
              <div className="inside">
                <p className="time">
                  Difficulty: <strong>HELL</strong>
                </p>
                <p>
                  In this reroll path, you will aim to pull the character from
                  the rate-up banner using the premium currency you manage to
                  get during the reroll process (It won't be much - at most a
                  few pulls) and also get a random SSR character from Standard
                  Banner.
                </p>
                <hr />
                <h6>Process</h6>
                <ul>
                  <li>
                    Play through the tutorial according to the guide above to
                    unlock summoning feature,
                  </li>
                  <li>
                    Do the 4 pulls on the <strong>rate-up banner</strong> and
                    pray.
                  </li>
                  <ul>
                    <li>
                      Could be potentially more with codes and additional
                      rewards.
                    </li>
                  </ul>
                  <li>
                    If you got the rate-up, congrats, but it's not over yet!
                  </li>
                  <li>
                    To complete the Hell path you also want to get another SSR
                    character from the Standard Banner (NOT Beginner Banner), so
                    you can guarantee starting with 3 SSR characters (1x
                    rate-up, 1x from the 30 pulls on Standard and only then you
                    start pulling on Beginner to get the last random SSR
                    character).
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col>
            <div className="box">
              <div className="option easy">
                <p>Heaven Path</p>
              </div>
              <div className="inside">
                <p className="time">
                  Difficulty: <strong>Medium</strong>
                </p>
                <p>
                  This path ignores the rate-up banner and instead focuses
                  getting 1-4x SSR characters from the Beginner/Standard banner.
                </p>
                <hr />
                <h6>Process</h6>
                <ul>
                  <li>
                    Play through the tutorial according to the guide above to
                    unlock summoning feature,
                  </li>
                  <li>
                    Pull on the Standard Banner and try to get a SSR character
                    of your choice within the 30 pulls you get from pre-register
                    rewards.
                  </li>
                  <li>
                    If you obtain the SSR character you want, now you can switch
                    to the Beginner Banner - it has 20% discount for the first
                    50 pulls and you're guaranteed to obtain a SSR within that.
                    So you will start with 2 SSR characters - one you target and
                    one random.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <h5>What about Weapons?</h5>
        <p>
          You can get the Standard characters signature weapons from the
          Exchange Shop, so rolling specifically for them isn't advised. While
          it will take you some time to gather enough currency to do the
          exchange, it's better to be patient rather than having a weapon
          without the character that can use it.
        </p>
        <SectionHeader title="Video" />
        <p>
          Check this video if you're interested in optimizing the reroll process
          even more - 20 minutes to get 10x Rate-up tickets and 40 Standard
          Tickets.
        </p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="UpMAb-jEnoA" />
          </Col>
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Standard SSR Characters" />
        <p>
          Here's the list of possible SSR Characters you can pull from the
          Beginner/Standard Banners (please note that{' '}
          <GFLCharacter slug="vepley" mode="inline" enablePopover showLabel />{' '}
          isn't available on the Beginner Banner as she's a guaranteed free
          reward from Beginner Missions):
        </p>
        <div className="employees-container-card-mode-gfl">
          <Card className="avatar-card">
            <GFLCharacter
              slug="mosin-nagant"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="tololo" mode="card" enablePopover showLabel />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="sabrina" mode="card" enablePopover showLabel />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="qiongjiu" mode="card" enablePopover showLabel />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="peritya" mode="card" enablePopover showLabel />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="vepley" mode="card" enablePopover showLabel />
          </Card>
        </div>
        <p>
          Now which of the 6 available characters is the best one? Let's go over
          each of them to show their good and bad sides!
        </p>
        <div className="reroll-char-info reroll first">
          <div className="top SSR">
            <p className="name">Tololo [DPS]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <GFLCharacter
                  slug="tololo"
                  mode="card"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Tololo (TLL) is a single-target damage dealer with a kit design
                centered around her Extra Action mechanic, which involves
                rapidly generating Confectance Index through her skills to feed
                her passive and perform an additional action. These additional
                actions not only boost her own damage output but also let her
                perform the role of Action Support enabler.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>
                  Has insane burst damage thanks to being able to take two
                  actions per turn,
                </li>
                <li>
                  Her skills have good damage and get better when she has buffs
                  - her passive gives her an innate Crit Rate and Crit DMG
                  permanent boost,
                </li>
                <li>
                  Unlocks her strongest Fixed Keys early on, leading to
                  exceptional performance in the early/mid-game,
                </li>
                <li>
                  Has very versatile Fixed Key options, giving her access to
                  Anti-heal, Dispel and Stun,
                </li>
                <li>
                  She isn’t a dupe-reliant character and works perfectly fine at
                  V0.
                </li>
              </ul>
              <ul className="cons">
                <li>
                  Needs an allied Hydro unit to continue to stack her passive -
                  Hydro characters are heavily limited with only Sabrina and
                  Colphne available in Global,
                </li>
                <li>Reliant on buffs for most of her kit to work,</li>
                <li>
                  Her Mod Key and best partner - Springfield - won't be
                  available to us on Global for quite some time.
                </li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon icon={faTrophy} width="30" className="gold" />
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top SSR">
            <p className="name">Qiongjiu [DPS]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <GFLCharacter
                  slug="qiongjiu"
                  mode="card"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Qiongjiu is an AR wielder who excels at doing single-target
                damage through Action Support. You will use most in any content
                where there's a boss, as not only can she attack it multiple
                times per turn thanks to her Passive, but she can also apply DEF
                Down while doing it (with Fixed Key 04-01), drastically
                increasing your team's damage output. Core member of the Burn
                team.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>
                  Top tier single target damage output - she's still meta even
                  now in CN,
                </li>
                <li>
                  Easy Action Support trigger condition gives her the
                  flexibility to slot into any comp with minimal teambuilding
                  considerations (add any one enabler) to accommodate her.
                </li>
                <li>
                  Fixed Key 04-01 provides unconditional DEF Down debuff on
                  Action Support.
                </li>
              </ul>
              <ul className="cons">
                <li>None.</li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon icon={faTrophy} width="30" className="gold" />
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top SSR">
            <p className="name">Sabrina [Tank]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <GFLCharacter
                  slug="sabrina"
                  mode="card"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Sabrina is a Tank/Amplifier hybrid who provides defensive buffs
                to allies and inflicts various debuffs on enemies. Her skills
                are more AOE-focused, so she thrives in normal stages but
                struggles against bosses, but she still has something to offer
                there. She is a great flex pick as she has synergy with most of
                the characters.
              </p>
              <p>
                <strong>Important!</strong> Sabrina and her dupes can be
                obtained for free from Weekly Boss Mode (1 copy every 2 months
                roughly)
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>
                  Has two AoE skills, making her a great choice for AoE-focused
                  teams - with Peritya and Littara,
                </li>
                <li>
                  Has great synergy with Tololo, thanks to the ability to apply
                  Hydro weakness on demand,
                </li>
                <li>Can destroy any destructible cover easily,</li>
                <li>Can self-heal her Stability Index, </li>
                <li>Counterattack can deal quite a lot of Stability Damage,</li>
                <li>
                  Very strong Fixed Keys that can make her better in PVE or PVP,
                </li>
                <li>
                  You can V6 her with enough time - her dupe tokens can be
                  acquired from the weekly Boss Battle mode.
                </li>
              </ul>
              <ul className="cons">
                <li>
                  Her damage output is a bit underwhelming but considering how
                  much other stuff she offers, it's a good tradeoff,
                </li>
                <li>
                  Not that good in Bossing content due to her AoE-focused
                  nature,
                </li>
                <li>
                  Most of her best Fixed Keys unlock later, so she doesn't feel
                  that great early on.
                </li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon
                  icon={faTrophy}
                  width="30"
                  className="silver"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top SSR">
            <p className="name">Peritya [DPS]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <GFLCharacter
                  slug="peritya"
                  mode="card"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Peritya excels at doing a lot of AoE Corrosion Damage and while
                on Global initially, she will struggle quite a lot, she's part
                of the future Corrosion team with Klukay and Mechty - the two
                can finally make use of her kit and elevate her to new levels,
                making her work even in single-target content.
              </p>
              <p>
                <strong>Important!</strong> Peritya and her dupes can be
                obtained for free from Mystery Box gacha in Dispatch.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>
                  Action Support has incredible damage potential for up to 300%
                  damage multiplier per turn.
                </li>
              </ul>
              <ul className="cons">
                <li>
                  Trying to play around Peritya’s Action Support without Klukai
                  can feel like a fool’s errand when everyone else is having
                  several magnitudes more fun playing the game with teams
                  operating on a fraction of the jank,
                </li>
                <li>
                  Peritya’s actual niche in the game as a single-target damage
                  dealer runs counter to everything her kit is designed to be
                  (AOE), and as such has zero self-synergy (skills, keys) with
                  her Action Support. In many ways Peritya has found success in
                  spite of her kit.
                </li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon
                  icon={faTrophy}
                  width="30"
                  className="bronze"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top SSR">
            <p className="name">Mosin Nagant [DPS]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <GFLCharacter
                  slug="mosin-nagant"
                  mode="card"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Mosin-Nagant is a long-range single-target damage dealer with
                limited function beyond those general traits shared across all
                rifle wielders (i.e. long range), and those synergies available
                to her as a Electric type elemental damage dealer
                (element-specific buffers/debuffers - Lenna). Mosin-Nagant’s
                fairly basic, unimpressive kit leaves her without much in the
                way of a distinct identity, and her lack of a clear niche leaves
                her in dire want of purpose.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>Long range.</li>
                <li>
                  Applies Paralysis that stops enemies from attacking resulting
                  in a great source of Crowd Control.
                </li>
              </ul>
              <ul className="cons">
                <li>
                  Highly conditional Action Support trigger severely limits its
                  effectiveness,
                </li>
                <li>
                  Dependence on Action Support for Confectance Index gain all
                  but guarantees shortages,
                </li>
                <li>
                  Requires dupes to be competitive with alternative damage
                  dealers,
                </li>
                <li>
                  Paralysis does not apply to bosses, making it completely
                  useless outside of mobbing.
                </li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon
                  icon={faTrophy}
                  width="30"
                  className="silver"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top SSR">
            <p className="name">Vepley [DPS / Amplifier]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <GFLCharacter
                  slug="vepley"
                  mode="card"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Vepley is a highly mobile character who excels in knocking back
                enemies and instantly killing them when they are pushed off the
                map. She has a mix of single-target and AoE skills, making her
                pretty versatile, but she shines the most in Boss battles thanks
                to her increased damage-taken debuff.
              </p>
              <p>
                <strong>Important!</strong> Vepley can be obtained for free from
                the Beginner Mission event.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>Can dispel buffs from enemies,</li>
                <li>
                  Can debuff enemies so they take more damage from her (30%) and
                  her allies (20%),
                </li>
                <li>
                  Can knock back enemy targets, even pushing them off the map
                  and killing them instantly,
                </li>
                <li>Has both single target and AoE damage skills,</li>
                <li>Very mobile.</li>
              </ul>
              <ul className="cons">
                <li>
                  Only deals Physical damage and that's the weakest 'element' in
                  the game currently,
                </li>
                <li>Jack of all trades, master of none,</li>
                <li>
                  Relatively weak Neural Helix keys, making her a niche T-Doll
                  that is primarily used in Boss Fight mode or in battles where
                  her mobility helps her reach the objective fast.
                </li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon
                  icon={faTrophy}
                  width="30"
                  className="bronze"
                />
              </p>
            </div>
          </div>
        </div>
        <p>Reroll value explanation:</p>
        <ul>
          <li>
            <FontAwesomeIcon icon={faTrophy} width="18" className="gold" /> -
            The best you can go for,
          </li>
          <li>
            <FontAwesomeIcon icon={faTrophy} width="18" className="silver" /> -
            The runner-up who have some good sides, but also bad sides,
          </li>
          <li>
            <FontAwesomeIcon icon={faTrophy} width="18" className="bronze" /> -
            While they aren't strong enough to be worth rerolling for, they
            still have some good things about them that make them worth using -
            so they deserve a bronze award!
          </li>
        </ul>
        <SectionHeader title="Rate-up SSR Characters" />
        <p>
          To find out if the current rate-up character is worth rerolling, check
          out tier list and the character review:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Tier List"
            link="/gfl-exilium/tier-list"
            image={
              <StaticImage
                src="../../../images/exilium/categories/category_tier.jpg"
                alt="Weapons"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default ExiliumGuidesReroll;

export const Head: React.FC = () => (
  <Seo
    title="Reroll Guide (GFL 2) | Girls' Frontline 2: Exilium | Prydwen Institute"
    description="The best characters to reroll for in Girls' Frontline 2: Exilium (GFL 2)."
    game="exilium"
  />
);
