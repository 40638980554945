export const ZZZUpdateData = [
  {
    slug: 'anby-demara',
    build: 1.0,
    review: 1.0,
    synergy: 1.5
  },
  {
    slug: 'anton',
    build: 1.0,
    review: 1.0,
    synergy: 1.5
  },
  {
    slug: 'astra-yao',
    build: 1.5,
    review: 1.5,
    synergy: 1.5
  },
  {
    slug: 'ben',
    build: 1.0,
    review: 1.0,
    synergy: 1.5
  },
  {
    slug: 'billy-kid',
    build: 1.0,
    review: 1.0,
    synergy: 1.5
  },
  {
    slug: 'burnice',
    build: 1.2,
    review: 1.2,
    synergy: 1.5
  },
  {
    slug: 'caesar',
    build: 1.2,
    review: 1.2,
    synergy: 1.5
  },
  {
    slug: 'corin',
    build: 1.0,
    review: 1.0,
    synergy: 1.5
  },
  {
    slug: 'ellen',
    build: 1.0,
    review: 1.0,
    synergy: 1.5
  },
  {
    slug: 'grace-howard',
    build: 1.0,
    review: 1.0,
    synergy: 1.5
  },
  {
    slug: 'harumasa',
    build: 1.4,
    review: 1.4,
    synergy: 1.5
  },
  {
    slug: 'jane-doe',
    build: 1.1,
    review: 1.1,
    synergy: 1.5
  },
  {
    slug: 'koleda',
    build: 1.0,
    review: 1.0,
    synergy: 1.5
  },
  {
    slug: 'lighter',
    build: 1.3,
    review: 1.3,
    synergy: 1.3
  },
  {
    slug: 'lucy',
    build: 1.5,
    review: 1.0,
    synergy: 1.5
  },
  {
    slug: 'lycaon',
    build: 1.0,
    review: 1.0,
    synergy: 1.5
  },
  {
    slug: 'miyabi',
    build: 1.4,
    review: 1.4,
    synergy: 1.5
  },
  {
    slug: 'nekomata',
    build: 1.0,
    review: 1.0,
    synergy: 1.5
  },
  {
    slug: 'nicole-demara',
    build: 1.5,
    review: 1.0,
    synergy: 1.5
  },
  {
    slug: 'piper',
    build: 1.0,
    review: 1.0,
    synergy: 1.5
  },
  {
    slug: 'qingyi',
    build: 1.1,
    review: 1.1,
    synergy: 1.5
  },
  {
    slug: 'rina',
    build: 1.5,
    review: 1.0,
    synergy: 1.5
  },
  {
    slug: 'seth',
    build: 1.4,
    review: 1.1,
    synergy: 1.5
  },
  {
    slug: 'soldier-11',
    build: 1.0,
    review: 1.0,
    synergy: 1.5
  },
  {
    slug: 'soukaku',
    build: 1.5,
    review: 1.0,
    synergy: 1.5
  },
  {
    slug: 'yanagi',
    build: 1.3,
    review: 1.3,
    synergy: 1.3
  },
  {
    slug: 'zhu-yuan',
    build: 1.0,
    review: 1.0,
    synergy: 1.5
  }
];
