export const ZZZDeadlyCharData = [
  {
    char: 'anby-demara',
    name: 'Anby',
    rarity: '4*',
    dupes_score: 17831,
    current_usage: 17.15,
    prev_usage: 11.52,
    current_score: 15947,
    prev_score: 15124,
    boss_1_usage: 1.22,
    boss_1_score: 16294,
    boss_2_usage: 11.68,
    boss_2_score: 15491,
    boss_3_usage: 6.62,
    boss_3_score: 16639
  },
  {
    char: 'anton',
    name: 'Anton',
    rarity: '4*',
    dupes_score: 0,
    current_usage: 0.97,
    prev_usage: 0.59,
    current_score: 18106,
    prev_score: 14481,
    boss_1_usage: 0.02,
    boss_1_score: 0,
    boss_2_usage: 1.04,
    boss_2_score: 18586,
    boss_3_usage: 0.07,
    boss_3_score: 0
  },
  {
    char: 'ben',
    name: 'Ben',
    rarity: '4*',
    dupes_score: 0,
    current_usage: 1.14,
    prev_usage: 2.1,
    current_score: 15454,
    prev_score: 14536,
    boss_1_usage: 0.36,
    boss_1_score: 0,
    boss_2_usage: 0.15,
    boss_2_score: 0,
    boss_3_usage: 0.74,
    boss_3_score: 15237
  },
  {
    char: 'billy-kid',
    name: 'Billy',
    rarity: '4*',
    dupes_score: 0,
    current_usage: 1.03,
    prev_usage: 1.04,
    current_score: 19837,
    prev_score: 14470,
    boss_1_usage: 0.09,
    boss_1_score: 0,
    boss_2_usage: 0.4,
    boss_2_score: 0,
    boss_3_usage: 0.67,
    boss_3_score: 18857
  },
  {
    char: 'burnice',
    name: 'Burnice',
    rarity: 'Limited 5*',
    dupes_score: 31872,
    current_usage: 46.17,
    prev_usage: 53.96,
    current_score: 24681,
    prev_score: 26220,
    boss_1_usage: 22.73,
    boss_1_score: 28837,
    boss_2_usage: 14.66,
    boss_2_score: 21375,
    boss_3_usage: 13.5,
    boss_3_score: 21829
  },
  {
    char: 'caesar',
    name: 'Caesar',
    rarity: 'Limited 5*',
    dupes_score: 29906,
    current_usage: 46.69,
    prev_usage: 47.88,
    current_score: 21550,
    prev_score: 21876,
    boss_1_usage: 8.34,
    boss_1_score: 22824,
    boss_2_usage: 25.64,
    boss_2_score: 21789,
    boss_3_usage: 18.61,
    boss_3_score: 20713
  },
  {
    char: 'corin',
    name: 'Corin',
    rarity: '4*',
    dupes_score: 21278,
    current_usage: 1.67,
    prev_usage: 1.61,
    current_score: 14961,
    prev_score: 14670,
    boss_1_usage: 0.14,
    boss_1_score: 0,
    boss_2_usage: 0.89,
    boss_2_score: 17520,
    boss_3_usage: 0.85,
    boss_3_score: 13089
  },
  {
    char: 'ellen',
    name: 'Ellen',
    rarity: 'Limited 5*',
    dupes_score: 18563,
    current_usage: 40.38,
    prev_usage: 40.23,
    current_score: 17465,
    prev_score: 16757,
    boss_1_usage: 3.94,
    boss_1_score: 14803,
    boss_2_usage: 2.91,
    boss_2_score: 15369,
    boss_3_usage: 37.26,
    boss_3_score: 17875
  },
  {
    char: 'grace-howard',
    name: 'Grace',
    rarity: 'Standard 5*',
    dupes_score: 22120,
    current_usage: 9.02,
    prev_usage: 6.91,
    current_score: 16741,
    prev_score: 17780,
    boss_1_usage: 3.31,
    boss_1_score: 22167,
    boss_2_usage: 6.29,
    boss_2_score: 14861,
    boss_3_usage: 0.62,
    boss_3_score: 14733
  },
  {
    char: 'harumasa',
    name: 'Harumasa',
    rarity: 'Limited 5*',
    dupes_score: 23782,
    current_usage: 29.31,
    prev_usage: 7.1,
    current_score: 17441,
    prev_score: 13402,
    boss_1_usage: 2.73,
    boss_1_score: 18017,
    boss_2_usage: 30.76,
    boss_2_score: 17484,
    boss_3_usage: 0.67,
    boss_3_score: 14304
  },
  {
    char: 'jane-doe',
    name: 'Jane Doe',
    rarity: 'Limited 5*',
    dupes_score: 22845,
    current_usage: 34.95,
    prev_usage: 46.04,
    current_score: 18847,
    prev_score: 22406,
    boss_1_usage: 1.46,
    boss_1_score: 16968,
    boss_2_usage: 23.75,
    boss_2_score: 18347,
    boss_3_usage: 14.58,
    boss_3_score: 19808
  },
  {
    char: 'koleda',
    name: 'Koleda',
    rarity: 'Standard 5*',
    dupes_score: 20148,
    current_usage: 5.58,
    prev_usage: 7.73,
    current_score: 15860,
    prev_score: 14713,
    boss_1_usage: 1.44,
    boss_1_score: 17880,
    boss_2_usage: 1.32,
    boss_2_score: 14582,
    boss_3_usage: 3.38,
    boss_3_score: 15718
  },
  {
    char: 'lighter',
    name: 'Lighter',
    rarity: 'Limited 5*',
    dupes_score: 28289,
    current_usage: 15.23,
    prev_usage: 15.21,
    current_score: 22296,
    prev_score: 19761,
    boss_1_usage: 8.27,
    boss_1_score: 24868,
    boss_2_usage: 1.19,
    boss_2_score: 17098,
    boss_3_usage: 7.04,
    boss_3_score: 20123
  },
  {
    char: 'lucy',
    name: 'Lucy',
    rarity: '4*',
    dupes_score: 29143,
    current_usage: 59.58,
    prev_usage: 58.65,
    current_score: 23574,
    prev_score: 22153,
    boss_1_usage: 35.48,
    boss_1_score: 27409,
    boss_2_usage: 13.04,
    boss_2_score: 19784,
    boss_3_usage: 16.58,
    boss_3_score: 20075
  },
  {
    char: 'lycaon',
    name: 'Lycaon',
    rarity: 'Standard 5*',
    dupes_score: 22430,
    current_usage: 43.91,
    prev_usage: 36.13,
    current_score: 19897,
    prev_score: 18526,
    boss_1_usage: 18.5,
    boss_1_score: 22533,
    boss_2_usage: 2.73,
    boss_2_score: 17501,
    boss_3_usage: 26.4,
    boss_3_score: 18534
  },
  {
    char: 'miyabi',
    name: 'Miyabi',
    rarity: 'Limited 5*',
    dupes_score: 31436,
    current_usage: 91.83,
    prev_usage: 82.24,
    current_score: 25803,
    prev_score: 23637,
    boss_1_usage: 93.47,
    boss_1_score: 25754,
    boss_2_usage: 1.29,
    boss_2_score: 25273,
    boss_3_usage: 3.38,
    boss_3_score: 27128
  },
  {
    char: 'nekomata',
    name: 'Nekomata',
    rarity: 'Standard 5*',
    dupes_score: 0,
    current_usage: 2.18,
    prev_usage: 2.55,
    current_score: 16203,
    prev_score: 13685,
    boss_1_usage: 0.05,
    boss_1_score: 0,
    boss_2_usage: 1.49,
    boss_2_score: 15835,
    boss_3_usage: 0.94,
    boss_3_score: 16819
  },
  {
    char: 'nicole-demara',
    name: 'Nicole',
    rarity: '4*',
    dupes_score: 24728,
    current_usage: 55.84,
    prev_usage: 47.57,
    current_score: 21004,
    prev_score: 19507,
    boss_1_usage: 4.42,
    boss_1_score: 22578,
    boss_2_usage: 18.76,
    boss_2_score: 20138,
    boss_3_usage: 38.94,
    boss_3_score: 21289
  },
  {
    char: 'piper',
    name: 'Piper',
    rarity: '4*',
    dupes_score: 24260,
    current_usage: 14.55,
    prev_usage: 20.53,
    current_score: 18838,
    prev_score: 21312,
    boss_1_usage: 5.16,
    boss_1_score: 20191,
    boss_2_usage: 5.99,
    boss_2_score: 18220,
    boss_3_usage: 5.04,
    boss_3_score: 18477
  },
  {
    char: 'qingyi',
    name: 'Qingyi',
    rarity: 'Limited 5*',
    dupes_score: 21416,
    current_usage: 48.36,
    prev_usage: 40.07,
    current_score: 21125,
    prev_score: 19394,
    boss_1_usage: 0.54,
    boss_1_score: 19729,
    boss_2_usage: 28.57,
    boss_2_score: 19658,
    boss_3_usage: 25.67,
    boss_3_score: 22720
  },
  {
    char: 'rina',
    name: 'Rina',
    rarity: 'Standard 5*',
    dupes_score: 26961,
    current_usage: 43.32,
    prev_usage: 31.44,
    current_score: 21726,
    prev_score: 22134,
    boss_1_usage: 12.86,
    boss_1_score: 26278,
    boss_2_usage: 32.89,
    boss_2_score: 20725,
    boss_3_usage: 3.59,
    boss_3_score: 17485
  },
  {
    char: 'seth',
    name: 'Seth',
    rarity: '4*',
    dupes_score: 23496,
    current_usage: 28.51,
    prev_usage: 27.35,
    current_score: 17812,
    prev_score: 17950,
    boss_1_usage: 2.16,
    boss_1_score: 18054,
    boss_2_usage: 24.35,
    boss_2_score: 18067,
    boss_3_usage: 6.3,
    boss_3_score: 16686
  },
  {
    char: 'soldier-11',
    name: 'Soldier 11',
    rarity: 'Standard 5*',
    dupes_score: 22027,
    current_usage: 4.48,
    prev_usage: 14.8,
    current_score: 16731,
    prev_score: 16659,
    boss_1_usage: 0.79,
    boss_1_score: 15128,
    boss_2_usage: 0.47,
    boss_2_score: 0,
    boss_3_usage: 3.63,
    boss_3_score: 17390
  },
  {
    char: 'soukaku',
    name: 'Soukaku',
    rarity: '4*',
    dupes_score: 22065,
    current_usage: 62.41,
    prev_usage: 58.1,
    current_score: 20638,
    prev_score: 19007,
    boss_1_usage: 37.22,
    boss_1_score: 22847,
    boss_2_usage: 3.13,
    boss_2_score: 16213,
    boss_3_usage: 27.07,
    boss_3_score: 18674
  },
  {
    char: 'yanagi',
    name: 'Yanagi',
    rarity: 'Limited 5*',
    dupes_score: 32203,
    current_usage: 53.85,
    prev_usage: 51.63,
    current_score: 24057,
    prev_score: 24833,
    boss_1_usage: 22.98,
    boss_1_score: 26671,
    boss_2_usage: 36.75,
    boss_2_score: 22555,
    boss_3_usage: 1.17,
    boss_3_score: 24997
  },
  {
    char: 'zhu-yuan',
    name: 'Zhu Yuan',
    rarity: 'Limited 5*',
    dupes_score: 21581,
    current_usage: 39.45,
    prev_usage: 38.54,
    current_score: 21715,
    prev_score: 19697,
    boss_1_usage: 0.25,
    boss_1_score: 0,
    boss_2_usage: 8.1,
    boss_2_score: 21419,
    boss_3_usage: 35.21,
    boss_3_score: 21788
  }
];
