export const MoCCurrent10_1 = [
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 15.72,
    rank: 1,
    avg_round: 1.07,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 12.23,
    rank: 2,
    avg_round: 1.4,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 8.34,
    rank: 3,
    avg_round: 1.26,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 8.0,
    rank: 4,
    avg_round: 1.21,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 6.14,
    rank: 5,
    avg_round: 1.36,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 3.49,
    rank: 6,
    avg_round: 1.49,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 1.49,
    rank: 7,
    avg_round: 1.39,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 1.3,
    rank: 8,
    avg_round: 1.6,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 1.24,
    rank: 9,
    avg_round: 1.7,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 1.2,
    rank: 10,
    avg_round: 1.76,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 1.09,
    rank: 11,
    avg_round: 1.06,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.94,
    rank: 12,
    avg_round: 1.2,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.89,
    rank: 13,
    avg_round: 1.17,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.88,
    rank: 14,
    avg_round: 1.91,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.77,
    rank: 15,
    avg_round: 1.44,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.68,
    rank: 16,
    avg_round: 3.12,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.67,
    rank: 17,
    avg_round: 1.9,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.62,
    rank: 18,
    avg_round: 2.65,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.61,
    rank: 19,
    avg_round: 1.66,
    star_num: '4'
  },
  {
    char_one: 'tingyun-fugue',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.54,
    rank: 20,
    avg_round: 1.55,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.49,
    rank: 21,
    avg_round: 3.19,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.45,
    rank: 22,
    avg_round: 1.18,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.43,
    rank: 23,
    avg_round: 1.73,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.41,
    rank: 24,
    avg_round: 1.18,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.35,
    rank: 25,
    avg_round: 2.65,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.32,
    rank: 26,
    avg_round: 2.93,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.3,
    rank: 27,
    avg_round: 1.43,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.29,
    rank: 28,
    avg_round: 1.52,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.26,
    rank: 29,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.26,
    rank: 29,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.26,
    rank: 29,
    avg_round: 0.57,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.25,
    rank: 32,
    avg_round: 1.44,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.25,
    rank: 32,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.23,
    rank: 34,
    avg_round: 1.0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.23,
    rank: 34,
    avg_round: 1.88,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.22,
    rank: 36,
    avg_round: 0.43,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.22,
    rank: 36,
    avg_round: 0.75,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.22,
    rank: 36,
    avg_round: 1.33,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.22,
    rank: 36,
    avg_round: 2.3,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'robin',
    app_rate: 0.22,
    rank: 36,
    avg_round: 0.62,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.22,
    rank: 36,
    avg_round: 2.14,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.21,
    rank: 42,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.2,
    rank: 43,
    avg_round: 3.12,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.2,
    rank: 43,
    avg_round: 3.36,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.2,
    rank: 43,
    avg_round: 1.6,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.19,
    rank: 46,
    avg_round: 1.67,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.19,
    rank: 46,
    avg_round: 1.17,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.18,
    rank: 48,
    avg_round: 1.64,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.17,
    rank: 49,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.17,
    rank: 49,
    avg_round: 2.2,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.16,
    rank: 51,
    avg_round: 1.57,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.16,
    rank: 51,
    avg_round: 2.45,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.16,
    rank: 51,
    avg_round: 1.33,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.15,
    rank: 54,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.15,
    rank: 54,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.15,
    rank: 54,
    avg_round: 1.43,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.15,
    rank: 54,
    avg_round: 2.0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.15,
    rank: 54,
    avg_round: 1.75,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.15,
    rank: 54,
    avg_round: 2.92,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.15,
    rank: 54,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.14,
    rank: 61,
    avg_round: 3.62,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.14,
    rank: 61,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.14,
    rank: 61,
    avg_round: 1.25,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.14,
    rank: 61,
    avg_round: 1.67,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.13,
    rank: 65,
    avg_round: 1.71,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 65,
    avg_round: 1.38,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.13,
    rank: 65,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.13,
    rank: 65,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.13,
    rank: 65,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.13,
    rank: 65,
    avg_round: 1.75,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.13,
    rank: 65,
    avg_round: 1.0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.12,
    rank: 72,
    avg_round: 2.14,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.12,
    rank: 72,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 72,
    avg_round: 1.83,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.12,
    rank: 72,
    avg_round: 1.7,
    star_num: '4'
  },
  {
    char_one: 'topaz',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 72,
    avg_round: 4.5,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.12,
    rank: 72,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.12,
    rank: 72,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 72,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.12,
    rank: 72,
    avg_round: 2.4,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.11,
    rank: 81,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.11,
    rank: 81,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.11,
    rank: 81,
    avg_round: 1.8,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.11,
    rank: 81,
    avg_round: 1.0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 81,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'tingyun-fugue',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.11,
    rank: 81,
    avg_round: 0.67,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 81,
    avg_round: 3.33,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.1,
    rank: 88,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.1,
    rank: 88,
    avg_round: 1.75,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 88,
    avg_round: 3.17,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 88,
    avg_round: 1.0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 88,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.1,
    rank: 88,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.1,
    rank: 88,
    avg_round: 1.6,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 88,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 88,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.1,
    rank: 88,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 88,
    avg_round: 1.8,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.1,
    rank: 88,
    avg_round: 1.43,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 88,
    avg_round: 3.0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.1,
    rank: 88,
    avg_round: 1.88,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.1,
    rank: 88,
    avg_round: 1.67,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.09,
    rank: 103,
    avg_round: 2.17,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 103,
    avg_round: 2.25,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 103,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'yanqing',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 103,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 103,
    avg_round: 0.5,
    star_num: '4'
  },
  {
    char_one: 'tingyun-fugue',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.09,
    rank: 103,
    avg_round: 1.6,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 103,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 103,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 103,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.09,
    rank: 103,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 103,
    avg_round: 0.0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 114,
    avg_round: 1.67,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 114,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 114,
    avg_round: 1.17,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 114,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 114,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 114,
    avg_round: 1.33,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 114,
    avg_round: 1.75,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 114,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 114,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 114,
    avg_round: 0.67,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 114,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 114,
    avg_round: 2.17,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 126,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.07,
    rank: 126,
    avg_round: 4.0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 126,
    avg_round: 2.25,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 126,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.07,
    rank: 126,
    avg_round: 1.0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 126,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 126,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.07,
    rank: 126,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.07,
    rank: 126,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 126,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 126,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 126,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 126,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.07,
    rank: 126,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.07,
    rank: 126,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 141,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 141,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.06,
    rank: 141,
    avg_round: 1.75,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 141,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 141,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 141,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 141,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 141,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 141,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 141,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'asta',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 141,
    avg_round: 1.8,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 141,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 141,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 141,
    avg_round: 2.0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 141,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 141,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 141,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 141,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 141,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 141,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 141,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 141,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 141,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 164,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 164,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 164,
    avg_round: 2.0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 164,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'tingyun',
    app_rate: 0.05,
    rank: 164,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 164,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 164,
    avg_round: 2.25,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 164,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 164,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 164,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.05,
    rank: 164,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 164,
    avg_round: 1.0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 164,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 164,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 164,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 164,
    avg_round: 1.67,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 164,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 164,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 164,
    avg_round: 1.67,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 164,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 164,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 164,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 164,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 164,
    avg_round: 3.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 164,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 164,
    avg_round: 0.5,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 190,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'robin',
    app_rate: 0.04,
    rank: 190,
    avg_round: 2.25,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'robin',
    app_rate: 0.04,
    rank: 190,
    avg_round: 0.33,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 190,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'trailblazer-harmony',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 190,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 190,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'blade',
    char_three: 'bronya',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 190,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 190,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'sparkle',
    app_rate: 0.04,
    rank: 190,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 190,
    avg_round: 3.0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 190,
    avg_round: 1.33,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.04,
    rank: 190,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.04,
    rank: 190,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 190,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 190,
    avg_round: 0.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 190,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 190,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 190,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 190,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 190,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 190,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 190,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 190,
    avg_round: 4.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 190,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 190,
    avg_round: 1.33,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 190,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 190,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 190,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 190,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 190,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 190,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 190,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 190,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 190,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 190,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'topaz',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 190,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 190,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 190,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'blade',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 190,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 190,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 190,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 190,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 190,
    avg_round: 3.67,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 190,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 190,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 190,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 190,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 190,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 190,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 239,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 239,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 239,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 239,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 239,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 239,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 239,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 239,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 239,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 239,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 239,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 239,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 239,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 239,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'guinaifen',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 239,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 239,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 239,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 239,
    avg_round: 3.33,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 239,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 239,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 239,
    avg_round: 0.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 239,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 239,
    avg_round: 1.33,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 239,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 239,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 239,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'moze',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 239,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 239,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 239,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 239,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yanqing',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 239,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 239,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'bronya',
    char_four: 'robin',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 239,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'tingyun-fugue',
    char_two: 'trailblazer-harmony',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 239,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 239,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 239,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 239,
    avg_round: 1.33,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'trailblazer-harmony',
    app_rate: 0.03,
    rank: 239,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'tingyun',
    app_rate: 0.03,
    rank: 239,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 239,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 239,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 239,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 239,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 239,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 239,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 239,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 239,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 239,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 239,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 239,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 313,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'bronya',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'gallagher',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'trailblazer-harmony',
    char_two: 'ruan-mei',
    char_three: 'gallagher',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'tingyun-fugue',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'jingliu',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 313,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'topaz',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'xueyi',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 313,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'bronya',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'xueyi',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'bronya',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 4.5,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 313,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'jiaoqiu',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'huohuo',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'asta',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'trailblazer-harmony',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'tingyun',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'topaz',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 313,
    avg_round: 0.5,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'topaz',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'guinaifen',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'blade',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'xueyi',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'blade',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'tingyun-fugue',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'topaz',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'asta',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'topaz',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'jingliu',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'yanqing',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'misha',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 313,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 313,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'topaz',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 313,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 313,
    avg_round: 0.5,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 313,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'yanqing',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 313,
    avg_round: 4,
    star_num: '4'
  }
];
