import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NikkeGuidesEin: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Ein guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_ein.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Ein Guide & Review</h1>
          <h2>A guide & review for Ein in NIKKE: Goddess of Victory.</h2>
          <p>
            Last updated: <strong>26/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Ein" />
              <div className="employee-container for-nikke">
                <NikkeCharacter mode="icon" slug="ein" enablePopover />
              </div>
              <p>
                A juvenile scientist with genuine love for fashion, a rather
                unlikely yet deadly combination that bridges logics and
                expressions, Ein holds a promising future commanders must
                protect. As a Missilis Attacker, she fights with a unique
                armament that periodically deals true damage to a random enemy,
                Near Feathers. She is also capable of maintaining a steady
                rhythm with her regular Sniper Rifle, boosting her ATK and
                Charged Damage at opportune moments.
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Ein has middling AoE capabilities but remarkable single-target
                lethality. Despite satisfactory performance across multiple game
                modes, Solo Raid remains her principal battlefield. All things
                considered, we expect her performance to conveniently surpass
                Scarlet's in similar setups with even higher growth potential as
                her ATK buff is less diluted. Her kit also accepts True Damage
                buffs, which Scarlet cannot make use of. Overall, she is a very
                promising unit that might have a chance to still be relevant in
                the future in element-neutral Raids, replacing Scarlet and going
                together with SAnis, or maybe in the True Damage team alongisde
                treasured up Laplace and Frima. It all really depends on the
                boss' design.
              </p>
              <h5>Should I pull this unit?</h5>
              <p>
                If you care about Raids, then yes, you should. Her True Damage
                counters the boss Mirror Container very well, which is obvious
                since it is designed exclusively for her. This boss barricades
                itself with layers of DEF buffs, and units without enough ATK
                will struggle at penetrating its rock-hard defenses. Outside of
                this boss, she still has a high chance of being deployed
                alongside SAnis in everyone's favorite easy-to-run team.
              </p>
              <p>
                As a bonus, she can also be used in PvP as a tauntable delayed
                AoE damage dealer, despite still not being quite meta. Similar
                to SBS and 2B, her hard counter is any taunter + immunity (from
                Biscuit, for example) who can redirect her Near Feathers'
                attacks away from key units. However, unless taunted, her drones
                will able to punish squishies throughout Full Burst, and let's
                not forget the guaranteed small AoE damage upon activation.
              </p>
              <p>
                Now, if you don't care much about Raids or PvP, and can still
                reach top ranks without her, then there is no rush getting her.
                In fact, she will be obtainable from Standard. By doing this,
                you can instead get SHelm/SAnis or save for future units. A
                wonderful collab shall indulge us any week now.
              </p>
              <p>
                <strong>
                  Last but not least, do NOT use Gold Tickets on her.
                </strong>
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <p>
                Ein is a damage dealer, and her performance is determined mainly
                by two things: her normal attacks & Near Feathers' cumulative
                damage. Against bosses with exposed cores and with buffs that
                empower regular attacks (i.e. Reload Speed/Core Damage), her
                normal attacks tend to hold a greater share. Otherwise, her
                damage will mainly come from Near Feathers, which also
                synergizes with True Damage buffs provided by herself and other
                fringe units. This assumes that her skills are adequately
                leveled, as she is heavily skill-dependent.
              </p>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> What is True
                  Damage?
                </h6>
                <p>
                  True Damage is a type of damage that bypasses DEF completely.
                  For example, a unit with 300 ATK hitting a unit with 100 DEF
                  would normally have a modified ATK of 200. However, if it is
                  True Damage, the ATK will pass through unprocessed, staying at
                  300.
                </p>
              </blockquote>
              <p>
                Having True Damage is a tactical advantage as it allows Ein to
                benefit from niché supports, like FrimaT. If better supports or
                partners as her B3 will ever be released, then she will be
                dominating alongside them. She can also handle enemies of high
                DEF, like Mirror Container, without compromising her damage.
                Being an Electric, she also pairs well with SAnis.{' '}
              </p>
              <h5>Buffs and Feathers Make You Fly</h5>
              <p>
                A simple skill, easy to explain. Ein's S1 summons 4 Near
                Feathers upon entering battle, which helps provide a tiny bit of
                Burst Energy to help cycle through Full Burst(s) faster. Apart
                from this, she also provides herself with a substantial ATK
                buff, which activates every time the team enters Burst Stage 3,
                even when she is not activating her Burst Skill! This allows her
                to strengthen her basic damage during her own and during other
                units' Full Burst(s).
              </p>
              <Alert variant="primary">
                <p>
                  The ATK buff takes effect before Burst Skill is activated
                  since it triggers upon entering Burst Stage 3 (when B3 choices
                  appear). Therefore, it also affects her Burst Skill damage!
                </p>
              </Alert>
              <h5>True Damage Carnival</h5>
              <p>
                Here is where the real fun begins. Let us start dismantling Near
                Feathers:
              </p>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Near Feather
                  Facts
                </h6>
                <ul>
                  <li>
                    Whenever a Near Feather is summoned (i.e. whenever Ein uses
                    Burst Skill), the feathers shoot lasers on random enemy
                    units on the screen and deal True Damage. If there is one
                    enemy, all the damage is dealt to that one single opponent.
                  </li>
                  <li>
                    In normal team comps, at the start of the stage/battle,
                    every ~3 seconds, there is one shot from Near Feathers which
                    helps cycle through rotations faster in both PvE and PvP.
                  </li>
                  <li>
                    Every time Ein uses Burst Skill, there are 34 instances of
                    Near Feather dealing damage, with 1-4 of them occurring
                    after Full Burst ends.
                  </li>
                  <li>
                    During the downtime of when Ein is not in her own Burst,
                    there are usually 6 instances of Near Feathers dealing
                    damage (with CDR), in relation to S1.
                  </li>
                  <li>
                    Near Feathers can CRIT but not Core Hit. They are affected
                    by Full Burst Multiplier (0.5×) but not Damage Distance
                    (0.3×).
                  </li>
                </ul>
              </blockquote>
              <p>
                All of these help Ein deal immense damage during her Burst,
                which can be used either as a screen-clearing tool or as a
                focus-target DPS, kind of like how Distributed Damage works.
                But, this is not her only damage source. Ein has one trick up
                her sleeve left to show—increasing her Charge Damage
                considerably after every Fully Charged shot. This makes her a
                super auto-friendly unit to use, minimizing the need to spam
                like one would with Alice or Trony. You would still want to
                perform quick shots between Full Bursts to accelerate rotations,
                however.
              </p>
              <h5>Even. More. Buffs. And An Additional Small AoE.</h5>
              <p>
                Simply put, her Burst Skill summons Near Feathers, which
                activates her Skill 2 and allows her to unleash a rain of
                destruction, repeatedly dealing True Damage to enemies while
                also enhancing her True Damage and Charge Damage even further!
              </p>
              <p>
                Finally, she has a 'small' True Damage nuke. These tiny
                multipliers are not to be scoffed at as they actually benefit
                from the ATK buff from S1, True Damage buff from Burst Skill,
                and Full Burst Bonus Multiplier (0.5×) all at once!{' '}
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Usage Analysis and Tier List" />
              <h5>
                Campaign (LD) - <strong className="ss">SS</strong> | Campaign
                (HD) - <strong className="s">S</strong>
              </h5>
              <p>
                Admirable performance in Campaign due to her semi-sequential
                attacks, which can be considered a delayed wave clear. This is
                similar to how Modernia takes time to clear waves, but that also
                means she can clear mobs that spawn a bit later. Meanwhile,
                instant wipes will only clear mobs that are currently on the
                screen. Ein's S2 allows her to spread Near Feathers' damage
                across multiple enemies, which is better for Low Deficit as
                enemies are more delicate, and together with her small AoE +
                Basic Attack, she can multitask and hunt down tanks at the same
                time. However, she is not as effective in High Deficit because
                you want to kill mobs as quickly as possible, suffering from the
                same fate SBS endures. If the damage pool is overly spread out,
                then no one dies, basically.
              </p>
              <p>
                Ein provides herself with a massive ATK lift every time the team
                enters Burst Stage 3. In High Deficit, where units' ATK and
                mobs' DEF converge, her basic attack damage will still be
                somewhat stable. Moreover, her skills deal True Damage, ignoring
                enemy's DEF!
              </p>
              <h5>
                Boss (Solo) - <strong className="ss">SS</strong> | Boss (Adds) -{' '}
                <strong className="s">S</strong>
              </h5>
              <p>
                Damage wise, Ein surpasses Scarlet against solo bosses, but her
                wipe is not as strong as Scarlet's when it comes to confronting
                too many mobs at the same time. She is similar to Sakura in a
                way that their effectiveness rises significantly as the number
                of enemies decreases. This allows them to focus their skill
                attacks on a single target. But, does this mean they are bad
                against adds? Not really. Their attacks are still, well, a
                wave-clearing tool. And for Ein specifically, she has a small
                screenwide AoE in her Burst Skill, which should be enough for
                eradicating low-HP critters.
              </p>
              <p>
                Let's not forget to mention that Ein is a True Damage dealer,
                which again, allows her to be paired with niché supports like
                FrimaT (Frima Treasure). This frees up the more generic supports
                for those with straightforward attack methods, enhancing
                compositional flexibility in Solo Raids.
              </p>
              <StaticImage
                src="../../../images/nikke/reviews/ein_dmg.webp"
                alt="Kit"
              />
              <h5>
                PVP - <strong className="s">S</strong>
              </h5>
              <p>
                Ow, we didn't expect another PvP superstar to be released.
                Summer Sakura introduced a delayed barrage alongside a
                well-executed targeted chip damage. Now, we are showcased a
                guaranteed small AoE damage (untauntable) paired with
                substantial AoE pressure! While there is randomness in play with
                Near Feathers, it is almost impossible for them not to strike an
                enemy or two. Unless taunted, they can deal considerable damage
                across 10s, inflicting continuous chip damage. Her approach is
                similar to SBS (more destructive in total but without the
                guarantee) and 2B (no guarantee + countered by Rosanna). Since
                she is already outfitted with an ATK + True Damage buff, she is
                not as reliant on DMG buffs/debuffs as the other wipers, but you
                still need them because her multipliers are not enough alone.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                Ein has a lot of ATK buff in her kit, which activates every time
                the team enters Burst Stage 3. We can safely assume that the
                uptime is very high, so constant dilution is to be expected.
                Thus, she scales better with Elemental DMG, but after that,
                there is nothing better than ATK. You will need a bunch of Max
                Ammo too, so rotations become more efficient and her basic
                attacks become more prominent.
              </p>
              <ul>
                <li>
                  <strong>Essential:</strong> None
                </li>
                <li>
                  <strong>Ideal:</strong> 4× Elemental Damage, 4× ATK, 1~2× Max
                  Ammo
                </li>
                <li>
                  <strong>Passable:</strong> Max Ammo, Charge Speed, CRIT Rate,
                  CRIT DMG
                </li>
                <li>
                  <strong>Priority:</strong> Medium
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <ul>
                <li>
                  <strong>Skill 1: 7-10</strong>
                </li>
                <ul>
                  <li> Huge ATK buff warrants an upgrade to the fullest.</li>
                </ul>
                <li>
                  <strong>Skill 2: 7~10</strong>
                </li>
                <ul>
                  <ul>
                    <li>
                      {' '}
                      Her main source of True Damage (aka Near Feathers). Also
                      increases Charge Damage.
                    </li>
                  </ul>
                </ul>
                <li>
                  <strong>Burst Skill: 7~10</strong>
                </li>
                <ul>
                  <li>
                    A major portion of her powerful short-lasting buffs resides
                    here. True Damage, Charge Damage all significantly
                    contribute to her DPS. Also increases Nuke Damage.
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="ein" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_3_sm.png"
                alt="Guide"
              />
              <p>
                With Reload Speed team, Resilience is the best. Otherwise,
                Bastion is the best if you have at least 1× Max Ammo, else use
                Resilience for more comfortable magazine handling.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: Electric Bunny Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter mode="icon" slug="volume" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="ein" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noir" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">Flex B3</div>
                </div>
              </div>
              <p>
                In Solo Raid, when the boss demonstrates an Elemental Shield
                skill, most of the time you will be required to place at least
                one unit of the advantageous element in each team. Against Water
                Bosses (weak to Electric), there are not many flexible B3 DPS
                options for the Bunny Team. Guillotine hates healing, and PrivM
                is better off with her specialized supports. This in turn limits
                our options to Scarlet and Ein. While both are valid, Scarlet is
                more optimal with a Reload Team as her damage is purely from
                basic attacks. This hypothetically makes Ein the best candidate.
              </p>
              <h5>Team #2: The New S.Anis Wombo Combo</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="exia" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sparkling-summer-anis"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="ein" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <Alert variant="primary">
                <p>Exia being used here is her Treasure Version!</p>
              </Alert>
              <p>
                The revolutionized SAnis Team. This takes full advantage of
                Anis's ability to support her Electric squadmates! Exia
                (Treasure) also provides offensive buffs, and all of them
                combined have the ability to deal immense damage! Here, Biscuit
                buffs SAnis and ExiaT, while S.Helm/Dolla provides the CDR for
                the team to work.
              </p>
              <h5>Team #3: The Boring SAnis Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="marciana" enablePopover />
                    <NikkeCharacter mode="icon" slug="rem" enablePopover />
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sparkling-summer-anis"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="ein" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="privaty" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                A pretty simple team that all of us have seen on the
                leaderboards. Dorothy provides CDR, S.Anis & Ein step on the DPS
                pedal, and Privaty provides the necessary Reload Speed buffs, as
                well as Ammo Reduction for SAnis. The B2s are the survivability
                supports to ensure the team stays alive.
              </p>
              <h5>Team #4: Privaty-Less? No Problem.</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="pepper" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="bay-goddess-mary"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="frima" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sparkling-summer-anis"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="ein" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                  </div>
                </div>
              </div>
              <Alert variant="primary">
                <p>Frima being used here is her Treasure Version!</p>
              </Alert>
              <p>
                If Privaty is to be used in another team, usually with Crown and
                the piercers (Alice & SBS), then this team is an excellent
                alternative. SAnis provides ATK and Reload Speed buff to Ein and
                Scarlet, and we can avoid wasting our Full Burst chance
                triggering Scarlet's pointless Burst Skill against bosses.
                Although neither Ein and Scarlet will achieve perfect Reload
                Skip (~110%), the reload duration will still be trimmed
                significantly. Additionally, Frima (Treasure) will provide True
                Damage buff for Ein, boosting her damage, while also healing the
                team. Pepper and SMary can be used as alternatives if FrimaT is
                unavailable.
              </p>
              <h5>Team #5: True Damage Team... Completed?</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="frima" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rem" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      slug="laplace"
                      enablePopover
                      mode="icon-tier"
                      tierID={54}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="ein" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <Alert variant="primary">
                <p>Frima being used here is her Treasure Version!</p>
              </Alert>
              <p>
                While we need more potent replacements over Rem and Dolla/S.Helm
                for a better True Damage team, this can absolutely perform at a
                decent level! The downside is that Frima (Treasure) and Laplace
                need to have their Favorite Items, which are expensive time
                investments. Frima also needs Treasure Phase 3 to be a viable
                support, which can take up to 1.5 months to get.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvP Team Compositions" />
              <p>
                Ein has tons of similarities with Summer Sakura in her PvP
                capabilities, so her teams will be similar
              </p>
              <h5>Team #1: High Power Nuke (3RL)</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="ein" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="miracle-fairy-anne"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Opponent running a slow team? This team can effectively counter
                it. The Blanc version of this team will fare well against most
                Noah-less teams. Defenders who are Taunters + Biscuit may also
                prove effective against this team.
              </p>
              <h5>Team #2: Indom, the Savior of DPS with Delayed AoE (3RL) </h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="ein" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                    <NikkeCharacter mode="icon" slug="anis" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Clip-SG</div>
                </div>
              </div>
              <p>
                Noir, B.Soda, and Drake are the best choices for Clip SG here.
                Hits less harder than the previous team but you save some
                critical resources. Again, will fare well against Noah-less
                teams, but otherwise may struggle. Defenders who are Taunters +
                Biscuit may also prove effective against this team.
              </p>
              <h5>Team #3: Fast Nuke (2~2.5RL)</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="ein" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="miracle-fairy-anne"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="emilia" enablePopover />
                    <NikkeCharacter mode="icon" slug="anis" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Water Main B3s like Emilia and Privaty can run into trouble when
                against this comp since Ein can hunt them down with nuke.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Capable of unleashing great amounts of True Damage,
                          allowing her to handle high-DEF enemies like a boss.
                        </li>
                        <li>
                          Her personal ATK and True Damage buffs are massive,
                          and the former has high uptime.
                        </li>
                        <li>
                          Noteworthy Charge Damage buffs that empower her basic
                          attacks.
                        </li>
                        <li>Auto-friendly SR unit.</li>
                        <li>
                          Small but useful AoE damage in Burst Skill that can
                          benefit from all active Full Burst buffs.
                        </li>
                        <li>
                          Having True Damage means she can be paired with True
                          Damage buffers, whom no one else wants, freeing up the
                          generic supports for generic units.{' '}
                        </li>
                        <li>
                          Damage is quite stable and does not fluctuate too much
                          regardless of whether there is a core or not.{' '}
                        </li>
                        <li>So... frickin'... cute!</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Heavily skill-dependent.</li>
                        <li>
                          Does not pair well with Charge Damage buffs because of
                          severe dilution.
                        </li>
                        <li>ATK is slightly diluted for Ein.</li>
                        <li>
                          Against multiple enemies, her focus target damage,
                          especially against bosses, is lowered substantially
                          due to spread.
                        </li>
                        <li>Her Near Feathers are tauntable in PvP.</li>
                        <li>
                          An alluring bait banner alongside Summer units and
                          just before a potential collab, but she is not limited
                          and can be put in Wishlist.
                        </li>
                        <li>
                          Skill description is too vague. You need to test her
                          in practice to understand the secret details.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesEin;

export const Head: React.FC = () => (
  <Seo
    title="Ein Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Ein in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
