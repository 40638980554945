import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import ScrollspyNav from 'react-scrollspy-nav';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Row, Col, Card } from 'react-bootstrap';
import { AshCharacter } from '../../../modules/ash/components/ash-character';
import { AshTrace } from '../../../modules/ash/components/ash-trace';

const AshGuidesSYPBoreas: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should You Pull Boreas?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_boreas.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should You Pull Boreas?</h1>
          <h2>
            A dedicated guide for Boreas that will help you decide whether to
            pull him or not.
          </h2>
          <p>
            Last updated: <strong>02/01/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Introduction" />
              <p>
                <strong>
                  Boreas is the first advanced Support added to the game, and
                  also a staple to most future endgame team comps and strats.
                </strong>{' '}
                The reason for this is his ability to reduce Elemental
                Resistance to Ice, Water and Lightning (mainly Ice). He grants
                high amounts of CRIT Rate to his allies and shields to save them
                from mechanics that otherwise would kill most Echomancers with
                one hit. He is often used alongside Freda, either in tandem or
                alternating between one another for a maximum of buff potential.
              </p>
              <StaticImage
                src="../../../images/ash/pull/boreas_1.webp"
                alt="Guides"
              />
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Review" />
              <p>
                Boreas is a unique healer who focuses on giving shields to his
                allies instead of directly healing them. This can guarantee that
                your team will survive certain attacks and mechanics that
                otherwise would instantly kill them, but it may be tricky to
                manage in the long run without proper investment on Boreas'
                skills.
              </p>
              <p>
                Every 10 seconds, he'll passively apply his [Cryo Contract]
                shield on all allies. This shield is very distinctive, as it
                scales both with Boreas' HP and the damage he takes, making him
                a suitable Echomancer to have at the frontline, unlike most
                Supports. His first skill, “Cryonic Calculations”, works as a
                'panic button', by instantly applying a larger shield on all
                allies and healing them if their HP is below 100%. However, it
                only has 4 uses.
              </p>
              <p>
                “Full Encryption”, the second skill”, grants 50% Crit Rate to an
                allied Echomancer and 100% Crit Rate to all summons, turning
                Boreas into the best friend of every summoner. It also applies
                [Arctic Test], which allows allied Echomancers to unleash AoE
                Ice DMG attacks. Do take note that “Full Encryption” only
                affects one target, and you have to manually select the target
                (figure below). Otherwise, the buff will only go to Boreas. For
                this reason, he’s mainly suited for teams who focus on a single
                hyper-carry, such as Cen Ying and Feng Qingxue.
              </p>
              <StaticImage
                src="../../../images/ash/pull/boreas_2.webp"
                alt="Guides"
              />
              <p>
                His Seed skill, “Sub-Zero Protocol” can act both as another
                panic button or burst window opener, as it applies everything
                Boreas does all at once. Much like “Full Encryption”, this one
                also has a special condition to be fulfilled: it will only grant
                the [Arctic Test] buff to Water, Lightning, or Ice Echomancers.
                This is important to keep in mind since “Full Encryption” has a
                very limited use amount of 3, which means that when it comes to
                the Summoner Team, Boreas will be at his best in shorter
                battles, such as Simultaneous Raid. For longer content like the
                challenge version of Synced Return, try to favor teams with one
                of the three listed elements when bringing him.
              </p>
              <p>
                As a final addendum, it's worth mentioning that thanks to [Cryo
                Contract] and [Arctic Test], Boreas enables many possibilities
                of combos with Memory Traces that require a shield, as well as
                enabling more Elemental Reaction possibilities to many
                characters.
              </p>
              <h5>Pros & Cons</h5>
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Can grant 50% Crit Rate to an allied Echomancer, and
                          100% Crit Rate to summons;
                        </li>
                        <li>
                          The preemptive nature of his shields can be a game
                          changer to save the team from certain boss mechanics;
                        </li>
                        <li>
                          In Wet environment stages, can consistently create Ice
                          zones without needing a Water zone first;
                        </li>
                        <li>
                          Can buff allies and enable them to deal AoE Ice
                          attacks. Although Final ATK scales with Boreas' own
                          stat, the DMG bonus granted by this buff is calculated
                          mainly by the ally's own stats, ensuring the
                          effectiveness of this buff even if the ally already
                          deals Ice DMG normally.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Personal damage output is almost non-existent;</li>
                        <li>
                          Focuses mostly on preemptive support, having a
                          relatively weaker healing capability compared to
                          fellow healers;
                        </li>
                        <li>
                          Dupes are only useful for teams with Ice main DPS.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Gameplay Tips" />
              <h5>Dupes</h5>
              <p>
                Boreas can offer a complete experience without dupes. In fact,
                for most teams, his dupes aren't needed at all. However, both
                his first and third dupe can significantly benefit teams who
                rely on Ice DMG or Ice Elemental Reactions. If you plan to
                invest on those teams in particular, his dupes are recommended.
                Otherwise, it's an easy pass.
              </p>
              <h5>General Tips</h5>
              <ul>
                <li>
                  Boreas works better with teams who rely heavily on
                  Auto-attacks (or summons' attacks), as most of his buffs will
                  directly affect the allied Echomancer's next attack or trigger
                  more often with higher ASPD. For this reason, he's often
                  paired with Begonia.
                </li>
                <li>
                  Boreas is particularly powerful in Wet environments, where his
                  [Arctic Test] buff and “Sub-Zero Protocol” ultimate can
                  instantly and steadily create Ice zones without the need of a
                  Water zone.
                </li>
              </ul>
              <h5>Skill Upgrade Priority</h5>
              <ul>
                <li>
                  The skill 1 “Cryonic Calculations” is recommended as priority.
                  Being an unorthodox healer, the bonus granted to Shield and
                  Healing by leveling this skill is necessary to allow Boreas to
                  act as the only healer in the party.
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Team Recommendation" />
              <p>
                NOTE: Remember you can mix and match Echomancers among all
                suggestions and alternate options, adjusting based on who you
                have available.
              </p>
              <h5>Team 1</h5>
              <p>
                Leader: <strong>Cen Ying</strong>
              </p>
              <div className={`character-teams box Fire`}>
                <div className="team-header">
                  <p>THE NEW SUMMONER TEAM</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="cen-ying"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="luke"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="yuqi"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="boreas"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                The new summoner team benefits greatly from Boreas’ +100% CRIT
                Rate buff to summons. Furthermore, content like Deep Abyss Nexus
                and Shriek Party Joint Training already come with plenty of
                Water Zones for Yuqi to trigger “My Vision is Clear!”. If that’s
                not the case, try bringing Furay & Furney or another Water Zone
                character to lay down the field before switching in to Luke.
              </p>
              <p>
                <strong>Back-up Options:</strong>
                <AshCharacter
                  slug="skylar"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="freda"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="mars"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="furay-and-furney"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="sambheka"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                (And anyone of those elements and roles)
              </p>
              <p>
                <strong>Core Nexus Skills</strong>: Getting the Groove, Seed
                Overload, Life Harvest, Targeted Strike
              </p>
              <p>
                <strong>Memory Traces</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Lucky Find" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace
                    name="Beneath The Deception"
                    mode="data"
                    onProfile
                  />{' '}
                </li>
                <li>
                  <AshTrace name="Born From Ashes" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Racing Night" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="My Vision Is Clear!" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Into The Fantasy" mode="data" onProfile />
                </li>
              </ul>
              <p>
                <strong>Alternative Memory Traces:</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Happy Hours" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Behind The Curtains" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Dusk Radiance" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Codename Diversity" mode="data" onProfile />
                </li>
              </ul>
              <h5>Team 2</h5>
              <p>
                Leader: <strong>Ivana</strong>
              </p>
              <div className={`character-teams box Fire`}>
                <div className="team-header">
                  <p>ETERNAL WINTER</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="ivana"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Main-DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="begonia"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Sub-DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="sambheka"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Elemental Reaction</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="boreas"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                This team focuses on relentless crowd-control alongside stacking
                many buffs and debuffs both from its character kits and Memory
                Traces. You can also swap either Begonia or Sambheka for Cyros
                and turn it into a Melt team. It’s important to fill as many of
                your backup slots with Square Echomancers (Bulwarks, Vanguards,
                Strikers), and swap then in and out whenever possible in order
                to take maximum advantage of the Nexus Skill “Life Anthem”. This
                skill can greatly increase Ivana’s MST and consequently her
                overall damage output.
              </p>
              <p>
                <strong>Back-up Options:</strong>
                <AshCharacter
                  slug="cen-ying"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="cyros"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="lorelle"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="mo-hongxiu"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="freda"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="longqing"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                (And anyone of those elements and roles)
              </p>
              <p>
                <strong>Core Nexus Skills</strong>: Strength Works Miracles,
                Life Anthem, Seed Overload
              </p>
              <p>
                <strong>Memory Traces</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Lucky Find" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Starlit Memories" mode="data" onProfile />{' '}
                </li>
                <li>
                  <AshTrace name="Born from Ashes" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Dawn" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Company" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Into The Fantasy" mode="data" onProfile />
                </li>
              </ul>
              <p>
                <strong>Alternative Memory Traces:</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Happy Hours" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Emptiness" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Behind The Curtains" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Dusk Radiance" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Karma" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Hello, Visier" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Lightchaser" mode="data" onProfile />
                </li>
                <li>
                  {' '}
                  <AshTrace name="Nightmare" mode="data" onProfile />
                </li>
              </ul>
              <h5>Team 3</h5>
              <p>
                Leader: <strong>Cyros</strong>
              </p>
              <div className={`character-teams box Fire`}>
                <div className="team-header">
                  <p>ELEMENTAL FEAST</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="cyros"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Main-DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="baili-tusu"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Sub-DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="skylar"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Sub-DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="boreas"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                This team has the insane capability of quickly triggering
                several elemental reactions in quick succession. With Boreas in
                the team, the previous weakness of the Fire Team not working so
                well in Wet environment is turned into a strength. In Wet
                environments, Boreas and his buffs can consistently create Ice
                zones without the need of a Water zone previously placed.
                However, Cyros can consistently place Fire zones as usual, thus
                enabling combos of Melt, Explosion, and Electrolysis with the
                help of Skylar.
              </p>
              <p>
                <strong>
                  Back-up Options to switch either Baili Tusu or Skylar include:
                </strong>
                <AshCharacter
                  slug="begonia"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="ivana"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="lydia"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="hassel"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="freda"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                (And anyone of those elements and roles)
              </p>
              <p>
                <strong>Core Nexus Skills</strong>: Strength Works Miracles,
                Seed Chard, Seed Overload, Life Harvest
              </p>
              <p>
                <strong>Memory Traces</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Emptiness" mode="data" onProfile />{' '}
                </li>
                <li>
                  <AshTrace name="Lucky Find" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Company" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Into The Fantasy" mode="data" onProfile />{' '}
                </li>
                <li>
                  <AshTrace name="Born from Ashes" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Lightchaser" mode="data" onProfile />
                </li>
              </ul>
              <p>
                <strong>Alternative Memory Traces:</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Happy Hours" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Dusk Radiance" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Into A Paper Crane" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Karma" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace
                    name="Beneath The Deception"
                    mode="data"
                    onProfile
                  />
                </li>
                <li>
                  <AshTrace name="Lawful Cage" mode="data" onProfile />
                </li>
              </ul>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Future Synergies" />
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="feng-qingxue"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                Feng Qingxue is Boreas best duo to Boreas when it comes to burst
                damage. With the highest damage ceiling in the game per single
                hit. Feng Qingxue also can take maximum advantage of Boreas’ Ice
                Res shred, buffs and, furthermore, both her weapons are
                considered summons, guaranteeing her the sweet 100% CRIT Rate
                bonus from Boreas.
              </p>
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="thea"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                Both Thea and (much) later Lynn, are the next step in Melt
                teams, offering great synergy.
              </p>
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="elpis"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                Elpis, especially with Dupe 1, brings any team with herself and
                Boreas to the next level of burst damage through sheer amounts
                of ATK buffs, CD reduction, Elemental Res shred and DR shred.
              </p>
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="midas"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                Similarly to Thea and Lynn, Midas also marks a “next step” in
                team comps. In his case, he becomes the centerpiece of the
                Summoner Team down the road.
              </p>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h5>Jump to</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Review</a>
                </li>
                <li>
                  <a href="#section-2">Gameplay Tips</a>
                </li>
                <li>
                  <a href="#section-3">Team Recommendation</a>
                </li>
                <li>
                  <a href="#section-4">Future Synergies</a>
                </li>
              </ul>
            </ScrollspyNav>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesSYPBoreas;

export const Head: React.FC = () => (
  <Seo
    title="Should You Pull Boreas | Ash Echoes | Prydwen Institute"
    description="A dedicated guide for Boreas that will help you decide whether to pull him or not."
    game="ash"
  />
);
