import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import {
  faBriefcaseMedical,
  faHandFist,
  faShield,
  faShuffle,
  faVirus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const AFKGuidesTeamsADV: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Team Building (Advanced)</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_meta.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Team Building (Advanced)</h1>
          <h2>
            How to build teams in AFK Journey and what are the best teams you
            can run in the late game and PVP.
          </h2>
          <p>
            Last updated: <strong>29/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          In this guide, we’ll go over some factors of building a late-game
          team. We’ll focus on PVP, and by extension PVE, as we already have
          guides specific to Dream Realm.
        </p>
        <p>If you're looking for beginner teams, check our other guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Best AFK Stages Teams"
            link="/afk-journey/guides/afk-progression-teams"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_team.jpg"
                alt="Best AFK Stages Teams"
              />
            }
          />
        </Row>
        <SectionHeader title="PVE team or PVP team?" />
        <p>
          Generally speaking, there is no clear distinction between the two. A
          team that is good in PVP, will also be good in PVE, unless you are
          tackling extreme deficit stages. At which point the standard high
          damage teams fail to kill enemies fast enough and we require sturdier
          stall units such as{' '}
          <AFKCharacter mode="inline" slug="brutus" enablePopover />,{' '}
          <AFKCharacter mode="inline" slug="antandra" enablePopover /> and{' '}
          <AFKCharacter mode="inline" slug="koko" enablePopover /> to buy enough
          time to kill the enemy units.
        </p>
        <SectionHeader title="Standard Team Setup" />
        <p>
          Even though there are many possible teams in this game, more often
          than not, they will follow the same style of building when deciding
          between which characters to use.
        </p>
        <ul>
          <li>
            Pick a{' '}
            <strong className="tank">
              <FontAwesomeIcon icon={faShield} width="18" /> Tank
            </strong>
            . Tanks are the key figures in a team, even if their presence
            doesn’t often decide the other characters that will make up the
            team. Tanks have an exceedingly small roster, so be careful with who
            you pick to build around the faction bonus.
          </li>
          <li>
            Pick 2x{' '}
            <strong className="dps">
              <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
            </strong>
            . At the time of writing this, there is no real reason to run just 1
            DPS. There aren't enough characters that can support that kind of
            playstyle, so most teams opt to run at least 2 DPS.
          </li>
          <li>
            Pick a{' '}
            <strong className="support">
              <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
            </strong>{' '}
            Supports are often the backbone of a team. Though most Supports
            (depending on the character) can provide enough sustain on their
            own, sometimes their kits trade raw healing output for things like
            buffs or debuffs.
          </li>
          <li>
            Flex spot. Pick a{' '}
            <strong className="specialist">
              <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
            </strong>
            , a second{' '}
            <strong className="support">
              <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
            </strong>{' '}
          </li>
          <ul>
            <li>
              Specialists are either characters with quirky kits, or characters
              that focus on multiple roles at the same time. A key observation
              you may have is that the “good” Specialists often provide
              something in terms of buffs, debuffs or enhancements for your
              other characters. Due to this, there’s also the option of running
              a second Support.
            </li>
          </ul>
        </ul>
        <p>
          But we are in the late game now, which opens up new and exciting
          options! Below we’ll briefly describe some prominent archetypes. Keep
          in mind that all those teams require characters ascended to at least{' '}
          <strong className="mythic">Mythic+</strong> as their EX weapons often
          are core for the team to work.
        </p>
        <SectionHeader title="Advanced team - Eironn Nuke Team" />
        <p>
          In this team, your strategy is to kill the enemy before they kill you,
          through overwhelming burst damage potential.
        </p>
        <ul>
          <li>
            <strong>Bring</strong>{' '}
            <AFKCharacter mode="inline" slug="eironn" enablePopover />. He is
            the character who comes to mind when we think about who enables the
            very idea of a “Nuke Team”. But only strictly necessary in the
            Eironn variant.
          </li>
          <li>
            <strong>Pick 2+ DPS</strong>. Pick characters with high burst damage
            potential, as many as you can get away with.
          </li>
          <li>
            And that’s it! Other than these, all other roles are optional:
          </li>
          <li>
            <strong>Pick a Tank</strong>. Or don’t. This role is optional, if
            running Eironn as your Specialist he can be used as a Tank so you
            can bring more DPS carries.
          </li>
          <li>
            <strong>Pick a Support</strong>. Or don’t. This role is optional, if
            running a more aggressive variant, we can ditch our support role
            entirely, but this is risky, because if the enemy Hewynn manages to
            get their ult out before dying, you are very likely to lose the
            match, so make sure your burst damage is up to the task.
          </li>
          <li>
            <strong>Pick a Celestial/Hypogean</strong>. Or don’t. This role is
            optional. A Celestial/Hypogean character (or Celehypo, as we’ll
            start calling them for brevity.) can help deal more damage, or offer
            sustain. In the late game we should have at least one of these to
            work with so might be worth slotting them into the team.
          </li>
        </ul>
        <p>
          <strong>Comments</strong>: This is THE meta team you will see
          everywhere in the endgame. Everyone builds around Eironn, and
          countering Eironn. The overwhelming burst damage wins fights right
          from the start.
        </p>
        <div className="team-table">
          <div className="header simple  Wilder">EIRONN NUKE (PVE/PVP)</div>
          <div className="heroes">
            <div className="hero-column">
              <p className="role specialist">
                <FontAwesomeIcon icon={faVirus} width="18" />
                Specialist
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="eironn"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="arden"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="lily-may"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="carolina"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="parisa"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="heroes two">
            <div className="hero-column">
              <p className="role flex">
                <FontAwesomeIcon icon={faShuffle} width="18" /> Flex spot
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="thoran"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="damian"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="silvina"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role flex">
                <FontAwesomeIcon icon={faShuffle} width="18" /> Flex spot
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="scarlita"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="reinier"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="info">
            <h6>Information about the team</h6>
            <ul className="bigger-margin">
              <li>
                This team composition functions and is viable in PVE and PVP as
                soon as{' '}
                <AFKCharacter mode="inline" slug="eironn" enablePopover />{' '}
                reaches Mythic+, becoming stronger as Eironn and the damage
                dealers paired up with him gain EX weapon levels.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="eironn" enablePopover /> is
                the main enabler of the “Nuke Team” archetype, and the rest of
                the team is built around him. His ability to cast his ultimate
                at the start of the battle at mythic+ synergizes well with
                Arden, Carolina, and even Thoran, as he often gets to use his
                counter against a tightly packed group of enemies. His EX +10 is
                a big upgrade as it increases the duration of his CC.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="arden" enablePopover /> is
                usually the first damage dealer you’ll think of when building
                the Eironn Nuke Team. He charges his ult rapidly when the enemy
                team gets hit by Eironn’s ultimate and then deals massive AOE
                damage and CC.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover />{' '}
                counters enemy Eironn teams, and other ultimate-reliant teams in
                general while dealing high damage, taking advantage of eironn’s
                magic DEF debuff and being hard to kill, she is our first choice
                of second dps carry.{' '}
                <AFKCharacter mode="inline" slug="carolina" enablePopover />{' '}
                capitalizes even further on Eironn and Arden’s CC attacks with
                her snowballs, and is the another choice for the second DPS
                carry.{' '}
                <AFKCharacter mode="inline" slug="parisa" enablePopover /> also
                functions in this slot by taking full advantage of Eironn’s
                ultimate grouping up enemies to deal massive damage, though she
                does not particularly synergize with the rest of the team’s CC
                the way Carolina does. All these damage dealers are at their
                best potential at Mythic+ and EX weapon upgraded as high as you
                can go.
              </li>
              <li>
                For the flex slots there are many choices, but ideally we want
                to bring a Wilder, Graveborn or Celehypo hero for faction bonus
                purposes.{' '}
              </li>
              <li>
                If running a healer, our first pick would be{' '}
                <AFKCharacter mode="inline" slug="damian" enablePopover /> at
                mythic+ EX +5, as it allows him to start healing shortly after
                the start of the battle while also providing haste.
              </li>
              <li>
                If running a dedicated tank,{' '}
                <AFKCharacter mode="inline" slug="thoran" enablePopover /> will
                be our first pick due to his resilience and high damage
                potential with his Counter. Mythic+ helps protect one of your
                damage dealers while also allowing Thoran to survive longer.
              </li>
              <li>
                If running an additional damage dealer,{' '}
                <AFKCharacter mode="inline" slug="silvina" enablePopover /> will
                be our first pick, as a situational assassin option in case the
                enemy has a high priority backline character she can quickly
                eliminate. At Mythic+ EX +10 Silvina gains the ability to
                inflict the Fear debuff on kill, same as Berial, which is very
                strong against a packed enemy team. Use the Enlightening Spell
                artifact if running her.
              </li>
              <li>
                If running a Celehypo,{' '}
                <AFKCharacter mode="inline" slug="scarlita" enablePopover />{' '}
                will be our first choice since she helps shield our frontliner
                long enough for the backline to kill the enemy. At Mythic+ +10
                Scarlita starts to really “pop off” by unlocking her instakill
                ability.{' '}
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> is a
                good option that works at low dupes, and can help disrupt the
                enemy.
              </li>
            </ul>
            <h6>Other picks</h6>
            <ul className="bigger-margin">
              <li>
                Tank:{' '}
                <AFKCharacter
                  mode="inline"
                  slug="granny-dahnie"
                  enablePopover
                />{' '}
                - We can replace Thoran with Granny in a pinch for her tankiness
                and CC potential, but watch out for Odie! A highly invested Odie
                will absolutely chew through Granny’s health bar.
              </li>
              <li>
                Flex Position:{' '}
                <AFKCharacter mode="inline" slug="hewynn" enablePopover /> - We
                can also run Hewynn as a second healer option in one of the flex
                slots to help increase the team’s sustain potential.
              </li>
              <li>
                Flex Position:{' '}
                <AFKCharacter mode="inline" slug="mikola" enablePopover />{' '}
                -Mikola is another great option for a secondary healer with
                great buffs, especially if the enemy does not have thoran.
              </li>
              <li>
                Damage Dealer:{' '}
                <AFKCharacter mode="inline" slug="florabelle" enablePopover /> -
                Flora can be worth running in this team, especially for the more
                sustain-heavy variant running Scarlita, a tank and a support.
                Her ultimate offers great synergy with Scarlita’s slam, as long
                as you can survive enough to see it happen.
              </li>
            </ul>
          </div>
        </div>
        <SectionHeader title="Advanced team - Mauler Rush" />
        <p>
          An Anti-Eironn team, taking advantage of the faction bonus to survive
          long enough to kill the Wilder units in the enemy team.
        </p>
        <ul>
          <li>
            <strong>Bring</strong>{' '}
            <AFKCharacter mode="inline" slug="shakir" enablePopover />. Shakir
            is the biggest enabler of mauler melee teams, being tanky enough to
            fight in the front lines as long as he can stay alive long enough to
            pop his ult, and providing powerful buffs to the team.
          </li>
          <li>
            <strong>Pick 2 DPS</strong>. Preferably Mauler faction heroes, but
            you can experiment with melee characters from other factions too.
          </li>
          <li>
            <strong>Pick a Tank</strong>. Mauler has resilient tanks who can
            keep the rest of the team alive long enough for Shakir to get his
            ult out, but you can also experiment with other faction tanks.
          </li>
          <li>
            <strong>Pick a Support or Specialist</strong>. Someone to keep this
            team going just long enough to rush the enemy. You don’t necessarily
            need a dedicated healer, as some Specialists can also provide
            shields or healing.
          </li>
        </ul>
        <p>
          <strong>Comments</strong>: Anti-Eironn team. Might be harder to build
          due to Maulers having many priority units and the average player not
          raising all of them. Will perform well as long as Shakir survives long
          enough to get his ult out.
        </p>
        <div className="team-table">
          <div className="header simple  Mauler">MAULER RUSH (PVE/PVP)</div>
          <div className="heroes">
            <div className="hero-column">
              <p className="role specialist">
                <FontAwesomeIcon icon={faVirus} width="18" />
                Specialist
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="shakir"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="odie"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="seth"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="alsa"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="satrana"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="heroes two">
            <div className="hero-column">
              <p className="role tank">
                <FontAwesomeIcon icon={faShield} width="18" /> Tank
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="thoran"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="brutus"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="antandra"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="mikola"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="scarlita"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="koko"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="info">
            <h6>Information about the team</h6>
            <ul className="bigger-margin">
              <li>
                This team is build to survive against Eironn’s initial burst of
                damage and push ahead with self heal and damage resistance,
                taking advantage of their faction advantage. Assume everyone is
                at least mythic+ rarity.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="shakir" enablePopover /> can
                be seen as a core unit in any melee-based team, as he provides
                powerful defensive and offensive buffs, as well as being quite
                tanky himself if he survives long enough to get his ult out.
                You’ll want to get his EX weapon to +10 so he can share Lupine
                Aura with the rest of the team.
              </li>
              <li>
                As for the tanks, our first pick would be{' '}
                <AFKCharacter mode="inline" slug="brutus" enablePopover />, for
                his invulnerability which guarantees that he will at least
                survive against the initial burst from Eironn and maybe heal
                himself back up.{' '}
                <AFKCharacter mode="inline" slug="thoran" enablePopover /> also
                provides a powerful counter attack, along with his
                survivability, but his faction is not ideal for mauler teams.{' '}
                <AFKCharacter mode="inline" slug="antandra" enablePopover /> can
                also be used for her sheer defensive potential, to hold the line
                long enough for Shakir to get his ult out.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover />,
                especially with his EX weapon unlocked, is an absolute menace
                and will absolutely melt any wilders unit he comes across, as
                long as he survives long enough to build up his damage. A
                popular resource management strategy is to pour most of your
                weapon upgrade resources into Odie to help with both Dream Realm
                and pvp.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="satrana" enablePopover /> and{' '}
                <AFKCharacter mode="inline" slug="seth" enablePopover /> are
                both good choices of melee damage carry, with great sustain
                mechanics. Seth can very easily snowball out of the enemy’s
                control, but that requires getting kills.{' '}
                <AFKCharacter mode="inline" slug="alsa" enablePopover /> is a
                more control-oriented option that helps isolate enemies and
                score kills.
              </li>
              <li>
                For our support flex slot,{' '}
                <AFKCharacter mode="inline" slug="koko" enablePopover /> is a
                great option for sustain seeing as her kit is tailored
                specifically for supporting melee teams. The same can be said of{' '}
                <AFKCharacter mode="inline" slug="mikola" enablePopover />, who
                offers powerful buffs and healing, and her arena remains after
                death.{' '}
                <AFKCharacter mode="inline" slug="scarlita" enablePopover />{' '}
                remains an universal support option who also offers high damage.
              </li>
            </ul>
            <h6>Other picks</h6>
            <ul className="bigger-margin">
              <li>
                Tank:{' '}
                <AFKCharacter mode="inline" slug="phraesto" enablePopover />{' '}
                Great option to run with Mikola to win her Arena bonus, while
                also providing extra energy.
              </li>
              <li>
                Tank:{' '}
                <AFKCharacter
                  mode="inline"
                  slug="granny-dahnie"
                  enablePopover
                />{' '}
                - If running Kafra, it might be worth considering running Granny
                here to help close out the faction bonus, and her CC can also be
                useful.
              </li>
              <li>
                Flex Support:{' '}
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                - You might have problems with your characters running out of
                Smokey’s healing cloud, but if they manage to stay with him he
                will provide great healing while also contributing to the Mauler
                faction bonus.
              </li>
              <li>
                Flex Support:{' '}
                <AFKCharacter mode="inline" slug="kafra" enablePopover /> -
                Kafra is a damage dealer/healer/debuffer hybrid who heals allies
                around him on kill, and can rush down enemy supports.
              </li>
              <li>
                Flex Support:{' '}
                <AFKCharacter mode="inline" slug="hewynn" enablePopover /> or{' '}
                <AFKCharacter mode="inline" slug="damian" enablePopover />. Both
                offer great healing and haste buffs, good options if you seek a
                team with more sustain.
              </li>
            </ul>
          </div>
        </div>
        <SectionHeader title="Advanced team - Dionel Hypercarry" />
        <p>
          This is technically another type of nuke team, if your Dionel is
          invested enough, that is.
        </p>
        <ul>
          <li>
            <strong>Bring</strong>{' '}
            <AFKCharacter mode="inline" slug="dionel" enablePopover />. Dionel
            is best used as a build-around unit, being the most straightforward
            of the Celehypos, he just deals damage, so we’ll focus on amplifying
            that even further.
          </li>
          <li>
            <strong>Pick 3 Supports</strong>. More specifically, “buffers”. We
            want three support characters offering different Stat-boosting buffs
            to feed Dionel’s Intoxication stacks. Typically that means ATK,
            Haste and ATK SPD, but all stat-boosting buffs work.
          </li>
          <li>
            <strong>Pick a Tank/DPS/Celehypo</strong>. This is our flex slot, we
            can bring pretty much anyone, either to increase the team’s damage
            output, or survivability.
          </li>
        </ul>
        <p>
          <strong>Comments</strong>: Very high burst damage potential, can
          survive Eironn’s nuke as long as you use the ATK SPD artifact to keep
          Dionel safe. This team is sometimes used by whales who had the spare
          resources to invest in Dionel rather than the more utility-focused
          Celehypos. Requires very high investment on Dionel to be competitive
          with other meta teams.
        </p>
        <div className="team-table">
          <div className="header simple  Celestial">
            DIONEL HYPERCARRY (PVP/PVE)
          </div>
          <div className="heroes">
            <div className="hero-column">
              <p className="role dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="dionel"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="lyca"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="smokey-and-meerky"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="heroes two">
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="damian"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role tank">
                <FontAwesomeIcon icon={faShield} width="18" /> Tank
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="thoran"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="brutus"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="granny-dahnie"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="info">
            <h6>Information about the team</h6>
            <ul className="bigger-margin">
              <li>
                This team is built around{' '}
                <AFKCharacter mode="inline" slug="dionel" enablePopover />,
                providing enough buffs to quickly get his intoxication stacks up
                so he can destroy the enemy team. Reasonably tanky team thanks
                to multiple supports, but requires high investment on Dionel, at
                least Mythic+ and EX +10.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="lyca" enablePopover /> is used
                for her ATK SPD buff at the start of the battle.
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                is used for his ATK buff and healing. His haste only comes
                online after channeling for a while.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="damian" enablePopover /> is
                used for his HASTE buff and healing, completing the 3 required
                buffs for Dionel to reach his maximum potential.
              </li>
              <li>
                For our tank, we can run{' '}
                <AFKCharacter
                  mode="inline"
                  slug="granny-dahnie"
                  enablePopover
                />{' '}
                or <AFKCharacter mode="inline" slug="brutus" enablePopover />{' '}
                for the faction bonus, or{' '}
                <AFKCharacter mode="inline" slug="thoran" enablePopover />{' '}
                because he’s just that good.
              </li>
              <ul>
                <li>Granny is good due to her tankiness and CC potential.</li>
                <li>
                  Brutus is actually viable here since Dionel Hypercarry fights
                  tend to be on the shorter end.
                </li>
                <li>
                  Thoran, despite not contributing to the faction bonus, remains
                  a solid choice due to his revival mechanic.
                </li>
              </ul>
            </ul>
            <h6>Other picks</h6>
            <ul className="bigger-margin">
              <li>
                Support: You can mix and match supports as long as you can
                guarantee Dionel gets 3 buffs. Anyone who can provide a
                stat-boosting buff, be it an offensive buff or defensive buff.
              </li>
            </ul>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
        <SectionHeader title="Advanced team - Heal Stall Team" />
        <p>
          A powerful stall team that can also work on attack. Defense focused.
        </p>
        <ul>
          <li>
            <strong>Bring Scarlita or DPS</strong>.{' '}
            <AFKCharacter mode="inline" slug="scarlita" enablePopover /> ends up
            being the main source of damage on this team, as well as making the
            frontline tankier, and she is what allows the team to get kills at
            all by using her instakill mechanic. But you can also just run your
            best dps carry in this slot.
          </li>
          <li>
            <strong>Pick a Tank.</strong> This is a stall team so bringing a
            tank goes without saying.
          </li>
          <li>
            <strong>Pick 3 Supports</strong>. We want our supports to provide a
            constant flow of healing to keep the team going as long as possible.
          </li>
        </ul>
        <p>
          <strong>Comments</strong>: This is the best defense team in arena,
          often beating people above its power level by killing their units with
          Scarlita or just waiting out the timer. Can also work in attack if the
          enemy happens to have low sustain so you dont run out of time
        </p>
        <div className="team-table">
          <div className="header simple  Wilder">
            HEAL STALL (PVP - DEFENSE / PVE)
          </div>
          <div className="heroes">
            <div className="hero-column">
              <p className="role tank">
                <FontAwesomeIcon icon={faShield} width="18" /> Tank
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="thoran"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="granny-dahnie"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="hewynn"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="damian"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="heroes two">
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="rowan"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="koko"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="smokey-and-meerky"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role specialist">
                <FontAwesomeIcon icon={faVirus} width="18" />
                Specialist
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="scarlita"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="info">
            <h6>Information about the team</h6>
            <ul className="bigger-margin">
              <li>
                The traditional stall team, an impenetrable wall. This team is
                unique in its gameplan, as it is primarily tailored to waste the
                enemy team’s timer, rather than outright kill them, but it is
                still perfectly capable of doing that, should it be used in an
                attack formation, as long as the enemy team doesn’t also have
                high sustain. Not ideal for story pushing, as you might run out
                of time yourself in high deficit stages.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover /> and{' '}
                <AFKCharacter
                  mode="inline"
                  slug="granny-dahnie"
                  enablePopover
                />{' '}
                are the tanks of choice, with pros and cons to each.{' '}
              </li>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover />{' '}
                offers higher damage due to his counter, but using him can also
                be risky if the enemy happens to also have Thoran, because your
                counter might be reflected right back and deal enough damage to
                kill your tank before you can heal them.{' '}
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="granny-dahnie"
                  enablePopover
                />
                , on the other hand, is actually tankier than Thoran in a stall
                team, as she offers a slightly higher team bonus due to her
                faction, and has powerful CC and defensive tools built into her
                kit, and she would be the perfect choice, that is, if Odie
                didn’t exist. If the enemy happens to have a highly invested
                Odie (Let’s say, EX +15), his massive single target damage
                combined with faction advantage will absolutely shred through
                Granny’s health faster than our supports can heal.
              </li>
              <li>
                For our supports,{' '}
                <AFKCharacter mode="inline" slug="damian" enablePopover /> and{' '}
                <AFKCharacter mode="inline" slug="hewynn" enablePopover /> offer
                consistent, CC-resistant healing, as well as haste buffs. Hewynn
                also provides damage resistance to the team while healing to
                boost our survivability even further.
              </li>
              <li>
                In the third support slot,{' '}
                <AFKCharacter mode="inline" slug="rowan" enablePopover /> is the
                first pick, since he allows our other supports to get their
                ultimates out faster, but he is also squishy and vulnerable to
                graveborn damage dealers such as Carolina, Viperian and Silvina.
                Since we don’t particularly care about faction for this last
                slot, we can also run{' '}
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />
                , for his continuous healing and offensive buffs, or
                <AFKCharacter mode="inline" slug="koko" enablePopover /> for her
                jack-of-all-trades kit of defensive and offensive buffs, and as
                a bonus, being of the mauler faction, they are resistant to
                Wilder damage dealers.
              </li>
              <li>
                For our flex slot, the best unit you can run is a highly
                invested{' '}
                <AFKCharacter mode="inline" slug="scarlita" enablePopover />, as
                she offers extra sustain while also single-handedly solo-ing the
                entire enemy team with her powerful attacks and instakill
                abilities. But realistically you can run pretty much anyone
                here, preferably someone with significant damage potential, who
                also completes our faction bonus. We’ll go through some of the
                options below.
              </li>
            </ul>
            <h6>Other picks</h6>
            <ul className="bigger-margin">
              <li>
                <strong>Damage Dealer:</strong>{' '}
                <AFKCharacter mode="inline" slug="lily-may" enablePopover /> - A
                powerful variant of this defense team with more damage
                potential, counters Eironn attacker teams.
              </li>
              <li>
                <strong>Flex Position:</strong>{' '}
                <AFKCharacter mode="inline" slug="eironn" enablePopover /> - His
                ultimate can group up the enemy team to possibly set up a team
                wipe when combined with Thoran’s counter.
              </li>
              <li>
                <strong>Flex Position:</strong>{' '}
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> -
                His ultimate can disrupt the enemy team enough to stop them from
                ever having enough damage to finish your tank off, by
                teleporting one of their damage dealers away, or remove their
                support.
              </li>
              <li>
                <strong>Flex Position:</strong>{' '}
                <AFKCharacter mode="inline" slug="carolina" enablePopover />{' '}
                <AFKCharacter mode="inline" slug="arden" enablePopover /> - Both
                benefit greatly from crowd control, and fit perfectly in the
                Granny variant of the team.
              </li>
              <li>
                <strong>Flex Position:</strong>{' '}
                <AFKCharacter mode="inline" slug="viperian" enablePopover /> -
                Known for his great burst damage potential, he can help thoran
                finish off low health enemies after a counter.
              </li>
              <li>
                <strong>Flex Position:</strong>{' '}
                <AFKCharacter mode="inline" slug="cecia" enablePopover />{' '}
                <AFKCharacter mode="inline" slug="florabelle" enablePopover /> -
                Both have great solo carry potential, as long as they have
                enough time to let their summons do their job, which our stall
                team should offer plenty of.
              </li>
              <li>
                <strong>Flex Position:</strong>{' '}
                <AFKCharacter mode="inline" slug="berial" enablePopover /> -
                Berial can pick off isolated enemies, and leave the enemy team
                in low enough health to perish at the hands of Thoran, if they
                didn’t bring enough sustain to heal back up. His summons also
                divert some of the enemy team’s damage buying us extra time to
                outlast the timer.
              </li>
            </ul>
          </div>
        </div>
        <SectionHeader title="Advanced team - Graveborn Stall Team" />
        <p>
          Another option for stall teams, focusing on abusing graveborn
          immortality. Defense focused.
        </p>
        <ul>
          <li>
            Bring <AFKCharacter mode="inline" slug="thoran" enablePopover />,{' '}
            <AFKCharacter mode="inline" slug="igor" enablePopover /> and{' '}
            <AFKCharacter mode="inline" slug="niru" enablePopover />. These
            three form the backbone of this team, with all three being able to
            cheat death somehow, they can keep themselves alive while also
            dealing damage.
          </li>
          <li>
            <strong>Pick a Support</strong>. The immortality gimmick alone is
            not enough, we still need to bring some form of healing to make any
            stall strategy work.
          </li>
          <li>
            <strong>Pick a unit for the Flex slot</strong>. If we want to deal
            more damage with this team, we can bring a dps carry. If we want to
            lean entirely on the stall aspect for defense purposes, we can bring
            another Support. Or we can bring a Celehypo.
          </li>
        </ul>
        <div className="team-table">
          <div className="header simple  Graveborn">
            GRAVEBORN STALL (PVP - DEFENSE / PVE)
          </div>
          <div className="heroes">
            <div className="hero-column">
              <p className="role tank">
                <FontAwesomeIcon icon={faShield} width="18" /> Tank
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="thoran"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="niru"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role specialist">
                <FontAwesomeIcon icon={faVirus} width="18" />
                Specialist
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="igor"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="heroes two">
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="ludovic"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="hewynn"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="damian"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role flex">
                <FontAwesomeIcon icon={faShuffle} width="18" /> Flex spot
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="scarlita"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="info">
            <h6>Information about the team</h6>
            <ul className="bigger-margin">
              <li>
                This variant focuses on abusing immortality mechanics, rather
                than raw healing numbers, while offering slightly higher damage
                potential than the Heal variant.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover />,{' '}
                <AFKCharacter mode="inline" slug="igor" enablePopover /> and{' '}
                <AFKCharacter mode="inline" slug="niru" enablePopover /> are the
                core of the Graveborn variant of the stall team.{' '}
              </li>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover /> can
                revive twice, while also protecting one other character.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="niru" enablePopover /> can
                cast his ultimate right away, giving either Thoran or Igor
                another life, and the ghosts stay as long as your allies don’t
                die. Requires Mythic+.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="igor" enablePopover />{' '}
                completes the trio, being nigh-unkillable through his
                tombstones, often the last to die, allowing Niru’s ghosts to
                stay active for the duration of the fight.
              </li>
              <li>
                Even though we rely on the immortality gimmicks, this is still a
                stall team, so we’ll want a support or two to give us some extra
                breathing room.{' '}
                <AFKCharacter mode="inline" slug="hewynn" enablePopover /> or{' '}
                <AFKCharacter mode="inline" slug="damian" enablePopover /> are
                the first picks if we want consistent healing and haste.{' '}
                <AFKCharacter mode="inline" slug="ludovic" enablePopover /> can
                be used to increase our faction bonus at the cost of offering
                little other than healing.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="scarlita" enablePopover />{' '}
                gives the team extra sustain and has great damage potential, but
                generally speaking, we want someone to guarantee a faction
                bonus. We can use the same options recommended for the Heal
                Stall Team.
              </li>
              <li>
                For arena defense, you can bring an extra support in the Flex
                Position.
              </li>
            </ul>
          </div>
        </div>
        <SectionHeader title="Advanced team - Flora Zoo Team" />
        <p>
          A team based around taking advantage of Flora’s skill that grants
          allied summons a sizable shield.
        </p>
        <ul>
          <li>
            Bring <AFKCharacter mode="inline" slug="florabelle" enablePopover />
            , <AFKCharacter
              mode="inline"
              slug="berial"
              enablePopover
            /> and <AFKCharacter mode="inline" slug="cecia" enablePopover />.
            These three form our zoo archetype, based around overwhelming the
            enemy with summons, and offering plenty of tanky targets for the
            enemy to waste damage on.
          </li>
          <li>
            <strong>Pick a Tank</strong>. We need to hold the line just long
            enough for our backline to get their summons out, so bring a tank.
          </li>
          <li>
            <strong>Pick a Support</strong>. Someone to keep our frontline alive
            and heal our summons. Healing is more valuable than shields here, as
            we’ll have time to heal since the summons get a shield.
          </li>
        </ul>
        <div className="team-table">
          <div className="header simple  Wilder">FLORA ZOO (PVP/PVE)</div>
          <div className="heroes">
            <div className="hero-column">
              <p className="role dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="florabelle"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="berial"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="cecia"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="heroes two">
            <div className="hero-column">
              <p className="role tank">
                <FontAwesomeIcon icon={faShield} width="18" /> Tank
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="phraesto"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="thoran"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="granny-dahnie"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="mikola"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="hewynn"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="damian"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="info">
            <h6>Information about the team</h6>
            <ul className="bigger-margin">
              <li>
                The Zoo Team. Here we take advantage of{' '}
                <AFKCharacter mode="inline" slug="florabelle" enablePopover />{' '}
                ability to grant allied summons sizable shields, allowing us to
                overwhelm the enemy with summons, and offering plenty of tanky
                targets for the enemy to waste damage on.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="florabelle" enablePopover />{' '}
                is the one who enables the archetype.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="berial" enablePopover /> is
                constantly summoning shadows, which all get Florabelle’s
                shields, allowing them to survive longer to deal more damage,
                and absorb more hits.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="cecia" enablePopover />{' '}
                ultimate, Mr. Carlye, also benefits from the shields, doing his
                job as a secondary tank.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover /> and{' '}
                <AFKCharacter
                  mode="inline"
                  slug="granny-dahnie"
                  enablePopover
                />{' '}
                remain our first picks for both being top tier tanks, and also
                helping us gain the Graveborn/Wilder faction bonus.{' '}
                <AFKCharacter mode="inline" slug="phraesto" enablePopover /> is
                a powerful, but more expensive, addition to the zoo archetype
                due to deploying as two copies of the same character.
              </li>
              <li>
                For our support slot, we’ll want{' '}
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> for
                her EX ability, which grants a damaging aura to allies. We can
                also opt for someone who offers a lot of healing while also
                contributing to our faction bonus.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="hewynn" enablePopover /> is
                the first pick here for her map-wide healing ultimate, which
                allows us to heal all our summons at the same time.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="damian" enablePopover /> is
                another viable option, being able to provide his AOE healing
                sooner than Hewynn, with the tradeoff of having a relatively
                small AOE for his heals.
              </li>
            </ul>
            <h6>Other picks</h6>
            <ul className="bigger-margin">
              <li>
                <strong>Support:</strong>{' '}
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                - Provides great sustained healing and offensive buffs, might
                clash with our faction bonus.
              </li>
              <li>
                <strong>Support: Extra Healer </strong> - A more
                sustain-oriented variant can trade Cecia for an extra healer,
                helping with this team’s survival.
              </li>
            </ul>
          </div>
        </div>
        <SectionHeader title="Advanced team - Talene Hypercarry" />
        <p>
          A team based on keeping Talene’s ultimate going for as long as
          possible, relying on her to do most of the damage.
        </p>
        <ul>
          <li>
            Bring <AFKCharacter mode="inline" slug="talene" enablePopover />
            .Talene is best used as a build-around unit, we’ll take advantage of
            her revival gimmick and continuous ultimate, and try to keep her
            going until the enemy team is dead
          </li>
          <li>
            <strong>Pick 3 Supports</strong>. We want as much healing and
            defensive buffs as possible to feed Talene’s ultimate
          </li>
          <li>
            <strong>Pick a Specialist/Tank/DPS</strong>. This is our flex slot,
            we can bring pretty much anyone, either to increase the team’s
            damage output, or survivability
          </li>
        </ul>
        <div className="team-table">
          <div className="header simple  Wilder">
            TALENE HYPERCARRY (PVP/PVE)
          </div>
          <div className="heroes">
            <div className="hero-column">
              <p className="role dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="talene"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Support
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="koko"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Support
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="ludovic"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="heroes two">
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faShield} width="18" /> Support
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="damian"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role flex">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Flex
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="shakir"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="scarlita"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="thoran"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="info">
            <h6>Information about the team</h6>
            <ul className="bigger-margin">
              <li>
                This team is built around
                <AFKCharacter mode="inline" slug="talene" enablePopover />,
                providing as much healing as possible to keep her ultimate
                going. Reasonably tanky team thanks to multiple supports, but
                requires high investment on Talene, at least Supreme and EX +15.
                Her EX +15 ability is very important for keeping our frontline
                alive.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="koko" enablePopover />
                is used for her powerful array of defensive and offensive buffs
                tied to her ultimate, along with some extra healing ability.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="ludovic" enablePopover /> is
                used for his consistent healing.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="damian" enablePopover /> is
                used for his HASTE buff and healing.
              </li>
              <li>For our flex slot, we have several options: </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="shakir" enablePopover /> for
                  his tankiness and powerful buffs.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="scarlita" enablePopover />{' '}
                  for her shields, damage and execution ability.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="thoran" enablePopover /> for
                  his revival ability and powerful counter, as well as general
                  tankiness.
                </li>
              </ul>
            </ul>
            <h6>Other picks</h6>
            <ul className="bigger-margin">
              <li>
                <strong>Support</strong> - You can mix and match supports as
                long as you can guarantee Talene gets enough healing.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesTeamsADV;

export const Head: React.FC = () => (
  <Seo
    title="Team Building (Advanced) | AFK Journey | Prydwen Institute"
    description="How to build teams in AFK Journey and what are the best teams you can run in the late game and PVP."
    game="afk"
  />
);
