/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ZZZCharacter } from '../components/zzz-character';
import {
  faHandFist,
  faGavel,
  faFlask
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../common/components/youtube-embed';

interface IProps {
  name: string;
  slug: string;
  character_element: string;
}

export const ZZZProfileTeams: React.FC<IProps> = ({
  slug,
  character_element,
  name
}) => {
  return (
    <>
      {slug === 'astra-yao' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Miyabi Anomaly</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="miyabi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="yanagi"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="burnice"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Jane Anomaly</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="jane-doe"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="burnice"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Stun Teams</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="zhu-yuan"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="harumasa"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="qingyi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options (Bangboo will be determined by your core team):</p>
          <ul>
            <li>
              <strong>Resonaboo </strong> = If playing Zhu Yuan,
            </li>
            <li>
              <strong>Agent Gulliver</strong> = Yanagi/Miyabi teams,
            </li>
            <li>
              <strong>Butler or Knightboo</strong> = Generic energy gain,
            </li>
            <li>
              <strong>Plugboo</strong> or <strong>Electroboo</strong> - Harumasa
              teams.
            </li>
          </ul>
          <p>For more team ideas, check this video:</p>
          <Row className="video">
            <Col xs={12} lg={6}>
              <YoutubeEmbed embedId="nMcU-q0K4iw" />
            </Col>
          </Row>
        </>
      )}
      {slug === 'anby-demara' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Cunning Hare Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="nekomata"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="billy-kid"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Grace/Anton Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anton"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="grace-howard"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>F2P Harumasa Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="harumasa"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>Amillion</strong> or <strong>Bagboo</strong> = If 2x
              Cunning Hare,
            </li>
            <li>
              <strong>Bagboo</strong> or <strong>Bangvolver</strong> = if 2x
              Physical.
            </li>
            <li>
              <strong>Plugboo</strong> or <strong>Electroboo</strong> - If 2x
              Electric,
            </li>
          </ul>
        </>
      )}
      {slug === 'anton' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Anton Hyper Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anton"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="qingyi"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="koleda"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Anton & Grace Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anton"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="grace-howard"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="qingyi"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun / Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>Plugboo</strong> or <strong>Electroboo</strong> - since
              you're always playing with 2x Electric characters.
            </li>
          </ul>
        </>
      )}
      {slug === 'ben' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Premium Soldier 11 Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="soldier-11"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-equal">
                    <ZZZCharacter
                      mode="icon"
                      slug="lighter"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="koleda"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="burnice"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun / Sub-DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="ben"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>F2P Soldier 11 Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="soldier-11"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="ben"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>F2P Piper Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="piper"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="ben"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>Rocketboo</strong> - since you're always playing with 2x
              Fire characters.
            </li>
          </ul>
        </>
      )}
      {slug === 'billy-kid' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Billy Hyper Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="billy-kid"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="qingyi"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>Amillion</strong> - if 2x Cunning Hares,
            </li>
            <li>
              <strong>Red Moccus</strong> - if playing Caesar and Lucy,
            </li>
            <li>
              <strong>Bagboo</strong> or <strong>Boolseye</strong> - F2P option,
            </li>
            <li>
              <strong>Knightboo</strong> - generic option if nothing else
              applies.
            </li>
          </ul>
        </>
      )}
      {slug === 'burnice' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Premium Burnice Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main multi-equal">
                    <ZZZCharacter
                      mode="icon"
                      slug="jane-doe"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="yanagi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="burnice"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> SUB DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Fire & Ice</p>
            </div>
            <div className="single-team with-comment">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="miyabi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="burnice"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Budget Assault Disorder Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="piper"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="burnice"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> SUB DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>RocketBoo/Cryboo</strong> = If 2x Fire or nothing else
              below applies,
            </li>
            <li>
              <strong>Bangvolver</strong> = If 2x Physical,
            </li>
            <li>
              <strong>Red Moccus</strong> = Alternative if 2x Sons of Calydon,
            </li>
            <li>
              <strong>NEPS</strong> = Alternative if 2x NEPS members.
            </li>
          </ul>
        </>
      )}
      {slug === 'caesar' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Jane Doe Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="jane-doe"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support / Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="seth"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Soldier 11 Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="soldier-11"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support / Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {slug === 'corin' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="corin"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lycaon"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>F2P Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="corin"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="piper"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="billy-kid"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>Butler</strong> = Victoria Housekeeping,
            </li>
            <li>
              <strong>Bangvolver</strong> or <strong>Bagboo</strong> = Physical
              Comps.
            </li>
          </ul>
        </>
      )}
      {slug === 'ellen' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Best Ellen Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="ellen"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lighter"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lycaon"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Double Stun Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="ellen"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lycaon"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lighter"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>F2P Ellen Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="ellen"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>Butler</strong> = 2x Victoria Housekeeping,
            </li>
            <li>
              <strong>Sharkboo</strong> or <strong>Penguinboo</strong> = 2x Ice.
            </li>
          </ul>
        </>
      )}
      {slug === 'grace-howard' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Harumasa Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="harumasa"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="grace-howard"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> SUB DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="qingyi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Jane Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="jane-doe"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="grace-howard"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> SUB DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="seth"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Yanagi Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="yanagi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="grace-howard"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> SUB DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="seth"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>F2P Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anton"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="grace-howard"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> SUB DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>Plugboo</strong> or <strong>Electroboo</strong> - since
              you're always playing with 2x Electric characters.
            </li>
          </ul>
        </>
      )}
      {slug === 'harumasa' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>BIS Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="harumasa"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="qingyi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-equal">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Alternative Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="harumasa"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="burnice"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="grace-howard"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>F2P Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="harumasa"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>Plugboo</strong> or <strong>Electroboo</strong> - if 2x
              Electric.
            </li>
          </ul>
        </>
      )}
      {slug === 'jane-doe' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Premium Jane Doe Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="jane-doe"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="burnice"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> SUB DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Stun Jane Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="jane-doe"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="qingyi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="seth"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>Plugboo</strong> or <strong>Electroboo</strong> = If 2x
              Electric,
            </li>
            <li>
              <strong>Red Moccus</strong> = Alternative if 2x Sons of Calydon,
            </li>
          </ul>
        </>
      )}
      {slug === 'koleda' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Mono Fire</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main ">
                    <ZZZCharacter
                      mode="icon"
                      slug="soldier-11"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="koleda"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>RocketBoo</strong>.
            </li>
          </ul>
        </>
      )}
      {slug === 'lighter' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Miyabi Team</p>
            </div>
            <div className="single-team with-comment">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="miyabi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lighter"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support / Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Ellen Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="ellen"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lighter"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support / Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="burnice"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Soldier 11 Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="soldier-11"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lighter"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support / Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="burnice"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>RocketBoo/Cryboo</strong> = If 2x Fire or nothing else
              below applies,
            </li>
            <li>
              <strong>Sharkboo/Penguinboo</strong> = If 2x Ice,
            </li>
            <li>
              <strong>Red Moccus</strong> = Alternative if 2x Sons of Calydon,
            </li>
            <li>
              <strong>Butler</strong> = Alternative if 2x Victoria Housekeeping
              members.
            </li>
          </ul>
        </>
      )}
      {slug === 'lucy' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Miyabi Hyper</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="miyabi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="lighter"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="burnice"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun / Sub-DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Burnice Disorder</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main multi-equal">
                    <ZZZCharacter
                      mode="icon"
                      slug="yanagi"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="jane-doe"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="burnice"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> Sub-DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Mono Fire</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main ">
                    <ZZZCharacter
                      mode="icon"
                      slug="soldier-11"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="burnice"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> Sub-DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>RocketBoo/Cryboo</strong> = If 2x Fire,
            </li>
            <li>
              <strong>Red Moccus</strong> = Alternative if 2x Sons of Calydon
            </li>
          </ul>
        </>
      )}
      {slug === 'lycaon' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Corin Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="corin"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lycaon"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Ellen Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="ellen"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lycaon"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>F2P Miyabi Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="miyabi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lycaon"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>Butler</strong> = 2x Victoria Housekeeping,
            </li>
            <li>
              <strong>Sharkboo</strong> or <strong>Penguinboo</strong> = 2x Ice,
            </li>
            <li>
              <strong>Agent Gulliver</strong> or <strong>Bagboo</strong> = When
              playing Miyabi.
            </li>
          </ul>
        </>
      )}
      {slug === 'miyabi' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Miyabi + Yanagi Quick Swap</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="miyabi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="yanagi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support / Stun
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Miyabi Hyper</p>
            </div>
            <div className="single-team with-comment">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="miyabi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lighter"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Fire & Ice</p>
            </div>
            <div className="single-team with-comment">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="miyabi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="burnice"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>F2P Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="miyabi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lycaon"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>Butler</strong> = For the Energy gain,
            </li>
            <li>
              <strong>Red Moccus</strong> = Good option if playing two of
              Lighter, Lucy or Burnice in the team,
            </li>
            <li>
              <strong>Agent Gulliver</strong> = When playing with Yanagi.
            </li>
          </ul>
        </>
      )}
      {slug === 'nekomata' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="nekomata"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>Amillion</strong> or <strong>Bagboo</strong> = if 2x
              Cunning Hare,
            </li>
            <li>
              <strong>Bagboo</strong> or <strong>Bangvolver</strong> = if 2x
              Physical.
            </li>
          </ul>
        </>
      )}
      {slug === 'nicole-demara' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Zhu Yuan Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="zhu-yuan"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="qingyi"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Miyabi Quick Swap Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="miyabi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="yanagi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Cunning Hare Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="nekomata"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="billy-kid"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>Amillion</strong> or <strong>Bagboo</strong> = If 2x
              Cunning Hare,
            </li>
            <li>
              <strong>Reasonaboo</strong> or <strong>Devilboo</strong> = for Zhu
              Yuan teams.
            </li>
          </ul>
        </>
      )}
      {slug === 'qingyi' && (
        <>
          <p>
            Due to the way Qingyi's additional ability team requirements work
            she can be quite literally played in almost all standard team
            compositions consisting of a Stunner, Attacker and Support and is in
            many cases actually the best in slot as well. For this reason treat
            the teams below simply as samples of the teams she can be played in
            - not the only teams.
          </p>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Zhu Yuan Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="zhu-yuan"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="qingyi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Harumasa Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="harumasa"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="qingyi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main main-equal">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="burnice"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="grace-howard"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support / DPS
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              The choice of your Banbgoo depeds on who you're pairing Qingyi
              with.
            </li>
          </ul>
        </>
      )}
      {slug === 'piper' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="piper"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="burnice"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> SUB DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Disorder Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="piper"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="yanagi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Assault Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="piper"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="jane-doe"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>Red Moccus</strong> = If Sons of Calydon,
            </li>
            <li>
              <strong>Bangvolver</strong> or <strong>Luckyboo</strong> = If 2x
              Physical.
            </li>
          </ul>
        </>
      )}
      {slug === 'rina' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Miyabi Quick Swap</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="yanagi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="miyabi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>'Mono' Electric</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="yanagi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="qingyi"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="seth"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support / Stun
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Victoria Housekeeping</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="ellen"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="corin"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="lycaon"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support / Stun
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>Butler</strong> = If 2x Victoria Housekeeping,
            </li>
            <li>
              <strong>Plugboo/Electrobo</strong> = If 2x Electric,
            </li>
            <li>
              <strong>Knightboo</strong> = Generic option.
            </li>
          </ul>
        </>
      )}
      {slug === 'seth' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Jane Anomaly Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="jane-doe"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="qingyi"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support / Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="seth"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Yanagi Anomaly Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="yanagi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="qingyi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support / Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="seth"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>Plugboo</strong> or <strong>Electroboo</strong> - since
              you're always playing with 2x Electric characters.
            </li>
          </ul>
        </>
      )}
      {slug === 'soldier-11' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main ">
                    <ZZZCharacter
                      mode="icon"
                      slug="soldier-11"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="lighter"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun / Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="burnice"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support /
                    Sub-DPS
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>F2P Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main ">
                    <ZZZCharacter
                      mode="icon"
                      slug="soldier-11"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main ">
                    <ZZZCharacter
                      mode="icon"
                      slug="koleda"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="ben"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>RocketBoo/Cryboo</strong> = If 2x Fire,
            </li>
            <li>
              <strong>Red Moccus</strong> = Alternative if 2x Sons of Calydon
            </li>
          </ul>
        </>
      )}
      {slug === 'soukaku' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="ellen"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="lycaon"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lighter"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>Sharkboo</strong> or <strong>Penguinboo</strong> = Most
              offensive options,
            </li>
            <li>
              <strong>Butler</strong> = Energy comfort.
            </li>
          </ul>
        </>
      )}
      {slug === 'yanagi' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Miyabi Quick Swap</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="yanagi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="miyabi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Premium Disorder</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="yanagi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="burnice"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> SUB DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="seth"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Duo/Tripple Electric</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="yanagi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="seth"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>Agent Gulliver</strong> = Strong choice if there are at
              least two Section 6 characters in the team,
            </li>
            <li>
              <strong>Plugboo/Electrobo</strong> = If 2x Electric
              (Seth/Rina/Qingyi/Anby),
            </li>
            <li>
              <strong>RocketBoo/Cryboo</strong> = If 2x Fire
              (Koleda/Lucy/Burnice),
            </li>
            <li>
              <strong>Bangvolver</strong> = If 2x Physical (Piper/Jane/Caesar).
            </li>
          </ul>
        </>
      )}
      {slug === 'zhu-yuan' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Best Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="zhu-yuan"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="qingyi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="astra-yao"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>F2P Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="zhu-yuan"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Bangboo options:</p>
          <ul>
            <li>
              <strong>Reasonaboo</strong> = Great for grouping enemies,
            </li>
            <li>
              <strong>Officer Cui</strong> = Gives more damage,
            </li>
            <li>
              <strong>Devilboo</strong> or <strong>Bagboo</strong> = F2P
              alternatives.
            </li>
          </ul>
        </>
      )}
    </>
  );
};
