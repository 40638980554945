export const Shiyu7_2 = [
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 8.17,
    rank: 1,
    avg_round: 136,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Rina',
    app_rate: 8.0,
    rank: 2,
    avg_round: 78,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 6.13,
    rank: 3,
    avg_round: 154,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Caesar',
    app_rate: 4.3,
    rank: 4,
    avg_round: 79,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 4.02,
    rank: 5,
    avg_round: 100,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 3.5,
    rank: 6,
    avg_round: 75,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 3.05,
    rank: 7,
    avg_round: 93,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 3.05,
    rank: 7,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 2.97,
    rank: 9,
    avg_round: 124,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 2.86,
    rank: 10,
    avg_round: 94,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Rina',
    app_rate: 2.38,
    rank: 11,
    avg_round: 97,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 2.02,
    rank: 12,
    avg_round: 100,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Soukaku',
    app_rate: 1.96,
    rank: 13,
    avg_round: 91,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 1.87,
    rank: 14,
    avg_round: 150,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 1.66,
    rank: 15,
    avg_round: 193,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 1.6,
    rank: 16,
    avg_round: 196,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Seth',
    app_rate: 1.6,
    rank: 16,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 1.43,
    rank: 18,
    avg_round: 84,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Lucy',
    app_rate: 1.26,
    rank: 19,
    avg_round: 75,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 1.1,
    rank: 20,
    avg_round: 139,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.95,
    rank: 21,
    avg_round: 97,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.78,
    rank: 22,
    avg_round: 170,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.67,
    rank: 23,
    avg_round: 166,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.65,
    rank: 24,
    avg_round: 93,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Burnice',
    app_rate: 0.61,
    rank: 25,
    avg_round: 77,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.61,
    rank: 25,
    avg_round: 157,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.61,
    rank: 25,
    avg_round: 158,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.55,
    rank: 28,
    avg_round: 142,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 0.55,
    rank: 28,
    avg_round: 139,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.55,
    rank: 28,
    avg_round: 144,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.51,
    rank: 31,
    avg_round: 138,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 0.51,
    rank: 31,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.48,
    rank: 33,
    avg_round: 160,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.48,
    rank: 33,
    avg_round: 169,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.46,
    rank: 35,
    avg_round: 86,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Yanagi',
    char_three: 'Qingyi',
    app_rate: 0.44,
    rank: 36,
    avg_round: 176,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.44,
    rank: 36,
    avg_round: 134,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.44,
    rank: 36,
    avg_round: 102,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Seth',
    app_rate: 0.42,
    rank: 39,
    avg_round: 202,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Yanagi',
    char_three: 'Rina',
    app_rate: 0.42,
    rank: 39,
    avg_round: 142,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.4,
    rank: 41,
    avg_round: 155,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 0.38,
    rank: 42,
    avg_round: 178,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.38,
    rank: 42,
    avg_round: 138,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Seth',
    app_rate: 0.38,
    rank: 42,
    avg_round: 103,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.34,
    rank: 45,
    avg_round: 152,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'anby-demara',
    char_three: 'Caesar',
    app_rate: 0.34,
    rank: 45,
    avg_round: 196,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Caesar',
    app_rate: 0.34,
    rank: 45,
    avg_round: 126,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'Yanagi',
    app_rate: 0.32,
    rank: 48,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Qingyi',
    app_rate: 0.32,
    rank: 48,
    avg_round: 134,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.32,
    rank: 48,
    avg_round: 149,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.29,
    rank: 51,
    avg_round: 196,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.29,
    rank: 51,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.29,
    rank: 51,
    avg_round: 142,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.27,
    rank: 54,
    avg_round: 187,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.27,
    rank: 54,
    avg_round: 154,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lighter',
    char_three: 'Caesar',
    app_rate: 0.27,
    rank: 54,
    avg_round: 171,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.27,
    rank: 54,
    avg_round: 176,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.27,
    rank: 54,
    avg_round: 146,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Burnice',
    char_three: 'Soukaku',
    app_rate: 0.27,
    rank: 54,
    avg_round: 80,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.27,
    rank: 54,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.25,
    rank: 61,
    avg_round: 117,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.25,
    rank: 61,
    avg_round: 133,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Caesar',
    app_rate: 0.25,
    rank: 61,
    avg_round: 152,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.23,
    rank: 64,
    avg_round: 202,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.23,
    rank: 64,
    avg_round: 171,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'grace-howard',
    char_three: 'Soukaku',
    app_rate: 0.23,
    rank: 64,
    avg_round: 108,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.23,
    rank: 64,
    avg_round: 208,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.23,
    rank: 64,
    avg_round: 144,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Yanagi',
    char_three: 'Caesar',
    app_rate: 0.23,
    rank: 64,
    avg_round: 136,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.23,
    rank: 64,
    avg_round: 217,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 0.21,
    rank: 71,
    avg_round: 147,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.21,
    rank: 71,
    avg_round: 210,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.21,
    rank: 71,
    avg_round: 86,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.19,
    rank: 74,
    avg_round: 187,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.19,
    rank: 74,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Yanagi',
    char_three: 'Seth',
    app_rate: 0.19,
    rank: 74,
    avg_round: 166,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.19,
    rank: 74,
    avg_round: 107,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'Soukaku',
    app_rate: 0.19,
    rank: 74,
    avg_round: 135,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.17,
    rank: 79,
    avg_round: 202,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.17,
    rank: 79,
    avg_round: 107,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.17,
    rank: 79,
    avg_round: 224,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Yanagi',
    char_three: 'anby-demara',
    app_rate: 0.17,
    rank: 79,
    avg_round: 193,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.17,
    rank: 79,
    avg_round: 179,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.17,
    rank: 79,
    avg_round: 194,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.15,
    rank: 85,
    avg_round: 105,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.15,
    rank: 85,
    avg_round: 161,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.15,
    rank: 85,
    avg_round: 185,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.15,
    rank: 85,
    avg_round: 139,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Lycaon',
    app_rate: 0.15,
    rank: 85,
    avg_round: 92,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.15,
    rank: 85,
    avg_round: 151,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.15,
    rank: 85,
    avg_round: 159,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.15,
    rank: 85,
    avg_round: 186,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'grace-howard',
    app_rate: 0.15,
    rank: 85,
    avg_round: 117,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.15,
    rank: 85,
    avg_round: 84,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.15,
    rank: 85,
    avg_round: 158,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.13,
    rank: 96,
    avg_round: 84,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.13,
    rank: 96,
    avg_round: 179,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 96,
    avg_round: 144,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.13,
    rank: 96,
    avg_round: 185,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.13,
    rank: 96,
    avg_round: 182,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Lighter',
    char_three: 'Rina',
    app_rate: 0.13,
    rank: 96,
    avg_round: 185,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.13,
    rank: 96,
    avg_round: 106,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lighter',
    char_three: 'Soukaku',
    app_rate: 0.13,
    rank: 96,
    avg_round: 149,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.13,
    rank: 96,
    avg_round: 153,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'Rina',
    app_rate: 0.13,
    rank: 96,
    avg_round: 87,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.13,
    rank: 96,
    avg_round: 103,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 107,
    avg_round: 143,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Burnice',
    char_three: 'Qingyi',
    app_rate: 0.11,
    rank: 107,
    avg_round: 199,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.11,
    rank: 107,
    avg_round: 173,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 107,
    avg_round: 180,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'anby-demara',
    app_rate: 0.11,
    rank: 107,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 107,
    avg_round: 199,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Burnice',
    char_three: 'Lighter',
    app_rate: 0.11,
    rank: 107,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 107,
    avg_round: 189,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.11,
    rank: 107,
    avg_round: 192,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.11,
    rank: 107,
    avg_round: 135,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.11,
    rank: 107,
    avg_round: 118,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Lighter',
    app_rate: 0.11,
    rank: 107,
    avg_round: 143,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 107,
    avg_round: 205,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Qingyi',
    app_rate: 0.11,
    rank: 107,
    avg_round: 102,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 0.08,
    rank: 121,
    avg_round: 196,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 121,
    avg_round: 171,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 121,
    avg_round: 95,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.08,
    rank: 121,
    avg_round: 243,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 0.08,
    rank: 121,
    avg_round: 121,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 121,
    avg_round: 177,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 121,
    avg_round: 74,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 121,
    avg_round: 185,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Lighter',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 121,
    avg_round: 220,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 121,
    avg_round: 81,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 121,
    avg_round: 99,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'Lighter',
    app_rate: 0.08,
    rank: 121,
    avg_round: 113,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.08,
    rank: 121,
    avg_round: 173,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 121,
    avg_round: 159,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 121,
    avg_round: 95,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 121,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 121,
    avg_round: 196,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 121,
    avg_round: 121,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Piper',
    char_three: 'Soukaku',
    app_rate: 0.08,
    rank: 121,
    avg_round: 108,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 121,
    avg_round: 159,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'Qingyi',
    app_rate: 0.08,
    rank: 121,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Yanagi',
    char_three: 'Qingyi',
    app_rate: 0.08,
    rank: 121,
    avg_round: 165,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 121,
    avg_round: 146,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Lighter',
    app_rate: 0.08,
    rank: 121,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.08,
    rank: 121,
    avg_round: 146,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 146,
    avg_round: 164,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 146,
    avg_round: 87,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Soukaku',
    char_three: 'Seth',
    app_rate: 0.06,
    rank: 146,
    avg_round: 143,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lighter',
    char_three: 'Soukaku',
    app_rate: 0.06,
    rank: 146,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lighter',
    char_three: 'Ben',
    app_rate: 0.06,
    rank: 146,
    avg_round: 168,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 146,
    avg_round: 199,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 146,
    avg_round: 160,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 146,
    avg_round: 255,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Burnice',
    char_three: 'Seth',
    app_rate: 0.06,
    rank: 146,
    avg_round: 218,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 146,
    avg_round: 187,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'anby-demara',
    app_rate: 0.06,
    rank: 146,
    avg_round: 102,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.06,
    rank: 146,
    avg_round: 186,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Miyabi',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 146,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 146,
    avg_round: 163,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Burnice',
    app_rate: 0.06,
    rank: 146,
    avg_round: 137,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lighter',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 146,
    avg_round: 160,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Qingyi',
    app_rate: 0.06,
    rank: 146,
    avg_round: 193,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Lighter',
    app_rate: 0.06,
    rank: 146,
    avg_round: 118,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 146,
    avg_round: 248,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 146,
    avg_round: 161,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.04,
    rank: 166,
    avg_round: 164,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 166,
    avg_round: 159,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 166,
    avg_round: 122,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 166,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 166,
    avg_round: 131,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Burnice',
    char_three: 'Lighter',
    app_rate: 0.04,
    rank: 166,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.04,
    rank: 166,
    avg_round: 158,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lucy',
    char_three: 'Ben',
    app_rate: 0.04,
    rank: 166,
    avg_round: 166,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 166,
    avg_round: 182,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 166,
    avg_round: 202,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 166,
    avg_round: 194,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Yanagi',
    char_three: 'grace-howard',
    app_rate: 0.04,
    rank: 166,
    avg_round: 170,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 166,
    avg_round: 200,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 166,
    avg_round: 89,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.04,
    rank: 166,
    avg_round: 95,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'grace-howard',
    app_rate: 0.04,
    rank: 166,
    avg_round: 94,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Yanagi',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 166,
    avg_round: 146,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 166,
    avg_round: 106,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 166,
    avg_round: 192,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 166,
    avg_round: 153,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Burnice',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 166,
    avg_round: 100,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 166,
    avg_round: 97,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'anby-demara',
    app_rate: 0.04,
    rank: 166,
    avg_round: 40,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Yanagi',
    char_three: 'Qingyi',
    app_rate: 0.04,
    rank: 166,
    avg_round: 179,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Yanagi',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 166,
    avg_round: 188,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 166,
    avg_round: 186,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lighter',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 166,
    avg_round: 203,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.04,
    rank: 166,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Ellen',
    char_three: 'Yanagi',
    app_rate: 0.04,
    rank: 166,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Lighter',
    app_rate: 0.04,
    rank: 166,
    avg_round: 227,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.04,
    rank: 166,
    avg_round: 146,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 166,
    avg_round: 134,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 166,
    avg_round: 126,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'grace-howard',
    app_rate: 0.04,
    rank: 166,
    avg_round: 152,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Koleda',
    app_rate: 0.04,
    rank: 166,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Burnice',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 166,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Burnice',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 166,
    avg_round: 159,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 166,
    avg_round: 194,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 166,
    avg_round: 206,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.04,
    rank: 166,
    avg_round: 302,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Yanagi',
    char_three: 'Lighter',
    app_rate: 0.04,
    rank: 166,
    avg_round: 170,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'anby-demara',
    app_rate: 0.04,
    rank: 166,
    avg_round: 193,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 166,
    avg_round: 200,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Yanagi',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 166,
    avg_round: 156,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 166,
    avg_round: 190,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 166,
    avg_round: 134,
    star_num: '4'
  }
];
