import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { IGFLCharacter } from '../modules/common/model/graphql-types';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import './employee.scss';
import './gfl-character-dynamic.scss';
import { Accordion, Col, Row } from 'react-bootstrap';
import { Seo } from '../modules/common/components/seo';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faCircleInfo,
  faDiagramProject,
  faSquare
} from '@fortawesome/free-solid-svg-icons';
import { GFLWeaponDB } from '../modules/exilium/components/exilium-weapon-db';
import { GFLProfileTeams } from '../modules/exilium/profile/gfl-teams';
import { GFLProfileWeapons } from '../modules/exilium/profile/gfl-weapons';
import { RatingBox } from '../modules/common/components/rating-box';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

interface IGFLCharacterNodes {
  nodes: IGFLCharacter[];
}

interface IGFLCharacterEntry {
  currentUnit: IGFLCharacterNodes;
}

interface IProps {
  data: IGFLCharacterEntry;
}

const GFLCharacterDetails: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];
  const [currentTab, setCurrentTab] = useState('Info');

  const profileImage = character.cardImage
    ? getImage(character.cardImage.localFile.childImageSharp)
    : null;

  return (
    <DashboardLayout
      className={'generic-page character-page character-gfl '}
      game="exilium"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/gfl-exilium/characters">Characters</Link>
        </li>
        <li className="divider">/</li>
        <li>{character.name}</li>
      </ul>
      <>
        <div className="character-top">
          <div className="left-info">
            <h1>
              <span className="small"></span>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>
              <span className="sub">Build and Guide</span>
            </h1>
          </div>
          <div className="right-image">
            <div
              className={`character-background rarity-${character.rarity} ${character.slug}`}
            ></div>
            <GatsbyImage image={profileImage} alt="Character" />
          </div>
        </div>
        <Row className="intro-section">
          <Col xs={12} xl={12}>
            <div className={`content-header rarity-${character.rarity}`}>
              <FontAwesomeIcon icon={faSquare} width="18" /> Introduction
            </div>
            <div className="character-intro">
              <div className="combined">
                <h2>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  is a{' '}
                  <strong className={`rarity-${character.rarity}`}>
                    {character.rarity}
                  </strong>{' '}
                  character from the <strong>{character.class}</strong> class
                  who wields a weapon from the{' '}
                  <strong>{character.weapon}</strong> category{' '}
                  <strong>
                    ({character.weaponName ? character.weaponName : '-'})
                  </strong>
                  .
                  {character.element != 'Physical' && (
                    <>
                      {' '}
                      Her skills on top of Physical element, can also deal
                      {character.element === 'Burn' && (
                        <StaticImage
                          src="../images/exilium/icons/ele_burn.png"
                          alt="Burn"
                          className="faction-icon"
                        />
                      )}
                      {character.element === 'Corrosion' && (
                        <StaticImage
                          src="../images/exilium/icons/ele_corrosion.png"
                          alt="Corrosion"
                          className="faction-icon"
                        />
                      )}
                      {character.element === 'Freeze' && (
                        <StaticImage
                          src="../images/exilium/icons/ele_freeze.png"
                          alt="Freeze"
                          className="faction-icon"
                        />
                      )}
                      {character.element === 'Hydro' && (
                        <StaticImage
                          src="../images/exilium/icons/ele_hydro.png"
                          alt="Hydro"
                          className="faction-icon"
                        />
                      )}
                      {character.element === 'Electric' && (
                        <StaticImage
                          src="../images/exilium/icons/ele_electric.png"
                          alt="Electric"
                          className="faction-icon"
                        />
                      )}
                      <strong className={`${character.element}`}>
                        {character.element}
                      </strong>{' '}
                      damage.
                    </>
                  )}{' '}
                  She was originally released on{' '}
                  <strong>{character.releaseDate}</strong> in China{' '}
                  {character.releaseDateGlobal ? (
                    <>
                      and on <strong>{character.releaseDateGlobal}</strong> in
                      Global.
                    </>
                  ) : (
                    <>but her Global release date isn't known yet.</>
                  )}
                </h2>
                <div className="char-intro">
                  {character.description ? (
                    <>{renderRichText(character.description, options)}</>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <p className="hide-on-mobile">
                To learn more about{' '}
                <strong className={`rarity-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                check the sections below. <strong>Use the tabs</strong> to
                quickly switch to the kind of information you're looking for.
              </p>
            </div>
          </Col>
        </Row>
        <div
          className="fuse-ad-placeholder bigger"
          data-fuse="22844297232"
        ></div>
        <p className="show-on-mobile">
          To learn more about{' '}
          <strong className={`rarity-${character.rarity}`}>
            {character.name}
          </strong>{' '}
          check the sections below. <strong>Use the tabs</strong> to quickly
          switch to the kind of information you're looking for.
        </p>
        <div className="tabs">
          <div
            className={`single-tab rarity-${character.rarity} ${
              currentTab === 'Info' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Info')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
            </div>
            <p>Profile</p>
          </div>
          <div
            className={`single-tab rarity-${character.rarity} ${
              currentTab === 'Review' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Review')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faBook} width="18" />
            </div>
            <p>Review</p>
          </div>
          <div
            className={`single-tab rarity-${character.rarity} ${
              currentTab === 'Build' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Build')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faDiagramProject} width="18" />
            </div>
            <p>Build and teams</p>
          </div>
        </div>
        <div className={`tab-inside ${currentTab === 'Info' ? 'active' : ''}`}>
          <div className={`mobile-header rarity-${character.rarity}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Profile
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Stats (Max Level)
          </div>
          {character.stats ? (
            <>
              <div className="stats-gfl">
                <Row xs={1} xl={1} xxl={2} className="info-list stats">
                  <Col>
                    <div className="stat-box">
                      <div className="info-list-row">
                        <div className="category">
                          <StaticImage
                            src="../images/exilium/icons/stat_atk.png"
                            alt="Stat"
                          />
                          Attack
                        </div>
                        <div className={`details`}>{character.stats.atk}</div>
                      </div>
                      <div className="info-list-row">
                        <div className="category">
                          <StaticImage
                            src="../images/exilium/icons/stat_def.png"
                            alt="Stat"
                          />
                          Defense
                        </div>
                        <div className={`details`}>{character.stats.def}</div>
                      </div>
                      <div className="info-list-row">
                        <div className="category">
                          <StaticImage
                            src="../images/exilium/icons/stat_hp.png"
                            alt="Stat"
                          />
                          Health
                        </div>
                        <div className={`details`}>{character.stats.hp}</div>
                      </div>
                      <div className="info-list-row">
                        <div className="category">
                          <StaticImage
                            src="../images/exilium/icons/stat_crit.png"
                            alt="Stat"
                          />
                          Critical Hit
                        </div>
                        <div className={`details`}>
                          {character.stats.crit_rate}%
                        </div>
                      </div>
                      <div className="info-list-row">
                        <div className="category">
                          <StaticImage
                            src="../images/exilium/icons/stat_stability.png"
                            alt="Stat"
                          />
                          Stability Index
                        </div>
                        <div className={`details`}>{character.stats.home}</div>
                      </div>
                      <div className="info-list-row">
                        <div className="category">Weakness I</div>
                        <div className={`details`}>
                          {character.stats.weakness_1}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="stat-box">
                      <div className="info-list-row">
                        <div className="category">
                          <StaticImage
                            src="../images/exilium/icons/stat_atk.png"
                            alt="Stat"
                          />
                          Attack Boost
                        </div>
                        <div className={`details`}>
                          {character.stats.atk_bonus}%
                        </div>
                      </div>
                      <div className="info-list-row">
                        <div className="category">
                          <StaticImage
                            src="../images/exilium/icons/stat_def.png"
                            alt="Stat"
                          />
                          Defense Boost
                        </div>
                        <div className={`details`}>
                          {character.stats.def_bonus}%
                        </div>
                      </div>
                      <div className="info-list-row">
                        <div className="category">
                          <StaticImage
                            src="../images/exilium/icons/stat_hp.png"
                            alt="Stat"
                          />
                          Health Boost
                        </div>
                        <div className={`details`}>
                          {character.stats.hp_bonus}%
                        </div>
                      </div>
                      <div className="info-list-row">
                        <div className="category">
                          <StaticImage
                            src="../images/exilium/icons/stat_critdmg.png"
                            alt="Stat"
                          />
                          Critical Damage
                        </div>
                        <div className={`details`}>
                          {character.stats.crit_dmg}%
                        </div>
                      </div>
                      <div className="info-list-row">
                        <div className="category">
                          <StaticImage
                            src="../images/exilium/icons/stat_mobility.png"
                            alt="Stat"
                          />
                          Action Points
                        </div>
                        <div className={`details`}>
                          {character.stats.mobility}
                        </div>
                      </div>
                      <div className="info-list-row">
                        <div className="category">Weakness II</div>
                        <div className={`details`}>
                          {character.stats.weakness_2}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>stats aren't available yet.</strong> They will be
                  added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Skills
          </div>
          {character.cnOnly && (
            <div className="info-box with-margin">
              <p>
                <strong className={`rarity-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                <strong>skills were translated by us</strong>, because they
                aren't available in the Global server yet. If you spot any
                mistakes, please let us know!
              </p>
            </div>
          )}
          {character.skills ? (
            <>
              <div className={`skills rarity-${character.rarity}`}>
                <Row xs={1} xxl={2}>
                  {character.skills.map((skill, index) => {
                    return (
                      <Col key={index}>
                        <div className="box">
                          <div className="skill-header">
                            <div
                              className={`skill-icon rarity-${character.rarity}`}
                            >
                              {skill.type} Skill
                            </div>
                            <div className="skill-info">
                              <p className="skill-name">{skill.name}</p>
                              <p className="skill-type">
                                {skill.tags}{' '}
                                {skill.specialTags && (
                                  <>| {skill.specialTags}</>
                                )}
                              </p>
                            </div>
                          </div>
                          <div className={`additional-information`}>
                            <p>
                              Range:{' '}
                              <span>
                                {skill.range ? <>{skill.range}</> : <>-</>}
                              </span>
                            </p>
                            <p>
                              AoE:{' '}
                              <span>
                                {skill.aoE ? <>{skill.aoE}</> : <>-</>}
                              </span>
                            </p>
                            <p>
                              {skill.isConsumption ? (
                                <>Confectance Cons.</>
                              ) : (
                                <>Cooldown</>
                              )}
                              :{' '}
                              <span>
                                {skill.cooldown ? (
                                  <>{skill.cooldown}</>
                                ) : (
                                  <>-</>
                                )}
                              </span>
                            </p>
                            <p>
                              Stability damage:{' '}
                              <span>
                                {skill.stabilityDamage ? (
                                  <>{skill.stabilityDamage}</>
                                ) : (
                                  <>-</>
                                )}
                              </span>
                            </p>
                          </div>
                          <div className={`raw list skill-description`}>
                            {renderRichText(skill.description, options)}
                          </div>
                          <Accordion className="skill-effects">
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>Skill Effects</Accordion.Header>
                              <Accordion.Body>
                                {skill.effects ? (
                                  <>{renderRichText(skill.effects, options)}</>
                                ) : (
                                  <>
                                    <p className="no-margin">
                                      This skill has no special effects or they
                                      haven't been added yet.
                                    </p>
                                  </>
                                )}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                          {skill.upgrades && skill.upgrades.length > 0 ? (
                            <>
                              <div className="skill-upgrades">
                                {skill.upgrades.map((upgrade, index2) => {
                                  return (
                                    <p key={index2}>
                                      <span className="level">
                                        Fortification Segment {upgrade.level} (V
                                        {upgrade.level}):
                                      </span>{' '}
                                      <span
                                        className={`skill-with-coloring`}
                                        dangerouslySetInnerHTML={{
                                          __html: upgrade.effect
                                        }}
                                      />
                                    </p>
                                  );
                                })}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="skill-upgrades">
                                <p>This skill has no upgrades.</p>
                              </div>
                            </>
                          )}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>skills aren't available yet.</strong> They will be
                  added soon!
                </p>
              </div>
            </>
          )}
          {character.slug === 'papasha' && (
            <>
              <div className={`content-header rarity-${character.rarity}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Summon Skills
              </div>
              <p>Coming soon!</p>
            </>
          )}
          <div className={`content-header rarity-${character.rarity} `}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Neural Helix
          </div>
          {character.helix ? (
            <>
              <div className={`skills rarity-${character.rarity}`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div
                          className={`skill-icon rarity-${character.rarity}`}
                        >
                          Neural Helix
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">Neural Helix</p>
                        </div>
                      </div>
                      <div className={`potential`}>
                        <p className="fixed">Fixed Keys:</p>
                        <p>
                          <span>02-01 - {character.helix.skill_2_1_name}</span>:{' '}
                          {character.helix.skill_2_1_effect}
                        </p>
                        <p>
                          <span>02-03 - {character.helix.skill_2_3_name}</span>:{' '}
                          {character.helix.skill_2_3_effect}
                        </p>
                        <p>
                          <span>04-01 - {character.helix.skill_4_1_name}</span>:{' '}
                          {character.helix.skill_4_1_effect}
                        </p>
                        <p>
                          <span>04-03 - {character.helix.skill_4_3_name}</span>:{' '}
                          {character.helix.skill_4_3_effect}
                        </p>
                        <p>
                          <span>06-01 - {character.helix.skill_6_1_name}</span>:{' '}
                          {character.helix.skill_6_1_effect}
                        </p>
                        <p>
                          <span>06-03 - {character.helix.skill_6_3_name}</span>:{' '}
                          {character.helix.skill_6_3_effect}
                        </p>
                        <hr />
                        <p>
                          <span className="common">Common Key</span>{' '}
                          <span>{character.helix.skill_special_name}</span>:{' '}
                          {character.helix.skill_special_effect}
                        </p>
                        {character.modKey && (
                          <>
                            <hr />
                            <div className="mod-key">
                              <span className="mod">Mod Key (CN only)</span>:{' '}
                              {renderRichText(character.modKey, options)}
                            </div>
                            {character.slug === 'centaureissi' && (
                              <div>
                                <p>
                                  <strong>Invigorating Tea</strong>: All allied
                                  units gain a 10% Critical Damage boost and a
                                  15% Burn Damage increase when dealing Burn
                                  Damage. Cannot be dispelled.
                                </p>
                                <p>
                                  <strong>Perfect Protection</strong>: Prevents
                                  damage from Basic Attacks and Skills. The
                                  effect is removed after taking damage from one
                                  Basic Attack or Skill. Considered as a Buff.
                                </p>
                              </div>
                            )}
                            {character.slug === 'dushevnaya' && (
                              <div>
                                <p>
                                  <strong>Blessing of Frost</strong>: Upon
                                  reaching 4 stacks, the active skill Hero's
                                  Code gains an additional effect: it ignores
                                  15% of the target's cover damage reduction,
                                  deals Freeze damage, and increases damage
                                  dealt to targets on allied Freeze tiles to
                                  130%. This effect can stack up to 4 times and
                                  activates once. It cannot be dispelled.
                                </p>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>Neural Helix aren't available yet.</strong> They will
                  be added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header rarity-${character.rarity} `}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Signature Weapon
          </div>
          <>
            {character.rarity === 'SSR' ? (
              <>
                {character.slug === 'qiuhua' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Radiant Light" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'peri' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Ermenita" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'daiyan' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Heavy Strings" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'klukay' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Scylla" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'lenna' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Young Lion" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'andoris' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Aglaea" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'dushevnaya' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Ballad of Glory" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'zhaohui' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Bu Liu Xing" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'mechty' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Daydream" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'springfield' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Faithful Thornlight" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'belka' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Forest Sprite" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'faye' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Hestia" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'jiangyu' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Leaping Tiger" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'centaureissi' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Maid's Code" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'vector' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Mourning Whisper" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'makiatto' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Bittersweet Caramel" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'papasha' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Svarog" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'mosin-nagant' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Samosek" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'peritya' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Optical Illusion" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'qiongjiu' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Golden Melody" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'sabrina' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Mezzaluna" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'suomi' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Unspoken Calling" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'ullrid' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Rectrix" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'vepley' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Heart Seeker" mode="box" />
                    </Col>
                  </Row>
                )}
                {character.slug === 'tololo' && (
                  <Row xs={1} xxl={1} className="relic-set-container">
                    <Col>
                      <GFLWeaponDB name="Planeta" mode="box" />
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              <>
                <div className="info-box">
                  <p>
                    <strong className={`rarity-${character.rarity}`}>
                      {character.name}
                    </strong>{' '}
                    <strong>doesn't have a signature Weapon</strong>, as only
                    SSR rarity characters posses one.
                  </p>
                </div>
              </>
            )}
          </>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Voice Actors
          </div>
          <Row xs={1} xxl={4} className="va-section">
            <Col>
              <div className="info-list-row">
                <div className="category">ENG</div>
                <div className="details">
                  {character.va.en ? character.va.en : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">JPN</div>
                <div className="details">
                  {character.va.jpn ? character.va.jpn : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">CN</div>
                <div className="details">
                  {character.va.cn ? character.va.cn : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">KR</div>
                <div className="details">
                  {character.va.kr ? character.va.kr : '-'}
                </div>
              </div>
            </Col>
          </Row>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Gallery
          </div>
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>images aren't available yet.</strong> They will be added
              soon!
            </p>
          </div>
        </div>
        <div
          className={`tab-inside ${currentTab === 'Review' ? 'active' : ''}`}
        >
          <div className={`mobile-header rarity-${character.rarity}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Review
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Intro
          </div>
          {character.review ? (
            <div className="section-analysis">
              <div className={`review raw`}>
                {character.review && (
                  <>{renderRichText(character.review, options)}</>
                )}
              </div>
            </div>
          ) : (
            <div className="info-box with-margin">
              <p>
                <strong className={`rarity-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                <strong>introduction isn't available yet.</strong> It will be
                added soon!
              </p>
            </div>
          )}
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Pros & Cons
          </div>
          {character.pros ? (
            <div className="section-analysis">
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      {renderRichText(character.pros, options)}
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      {renderRichText(character.cons, options)}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`rarity-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                <strong>pros & cons aren't available yet.</strong> They will be
                added when the character is released.
              </p>
            </div>
          )}
          <div className={`content-header  rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Ratings
          </div>
          <p>
            For Global tier list, both <strong className="rarity-SR">SR</strong>{' '}
            and <strong className="rarity-SSR">SSR</strong> characters are rated
            at V0 (no dupes). For CN tier list,{' '}
            <strong className="rarity-SSR">SSR</strong> characters are rated at
            V0 (no dupes) and <strong className="rarity-SR">SR</strong>{' '}
            characters are rated at V6 (max dupes).
          </p>
          {character.ratings.cn != 1 ? (
            <>
              <div className="ratings-container">
                <RatingBox
                  game="gfl"
                  rating={character.ratings.global}
                  ratingName="Global (Early Game)"
                />
                <RatingBox
                  game="gfl"
                  rating={character.ratings.cn}
                  ratingName="CN (End game)"
                />
              </div>
            </>
          ) : (
            <>
              {' '}
              <div className="info-box">
                <p>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>ratings aren't available yet.</strong> It will be
                  added soon!
                </p>
              </div>
            </>
          )}
        </div>
        <div className={`tab-inside ${currentTab === 'Build' ? 'active' : ''}`}>
          <div className={`mobile-header rarity-${character.rarity}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Build and teams
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Best Synergies
          </div>
          {!character.cnOnly || character.slug === 'daiyan' ? (
            <>
              <div className="info-box with-margin">
                <p>
                  This section is still work in progress, so some synergies
                  might be missing! We will update the information as soon as we
                  can.
                </p>
              </div>
              <div className="gfl-teams">
                <GFLProfileTeams name={character.slug} />
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>synergies aren't available yet.</strong> They will be
                  added soon.
                </p>
              </div>
            </>
          )}
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Best Build (Keys)
          </div>{' '}
          {character.build ? (
            <div className="section-analysis">
              <div className={`review raw`}>
                <>{renderRichText(character.build, options)}</>
              </div>
            </div>
          ) : (
            <div className="info-box with-margin">
              <p>
                <strong className={`rarity-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                <strong>
                  best Keys builds information isn't available yet.
                </strong>{' '}
                It will be added soon!
              </p>
            </div>
          )}
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Best Attachments and
            Stats
          </div>{' '}
          {character.bestStats ? (
            <div className="section-analysis">
              <div className={`review raw`}>
                <>{renderRichText(character.bestStats, options)}</>
              </div>
            </div>
          ) : (
            <div className="info-box with-margin">
              <p>
                <strong className={`rarity-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                <strong>
                  best Attachment sets information isn't available yet.
                </strong>{' '}
                It will be added soon!
              </p>
            </div>
          )}
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Best Weapons
          </div>
          {!character.cnOnly || character.slug === 'daiyan' ? (
            <div className="gfl-teams">
              <GFLProfileWeapons name={character.slug} />
            </div>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>best weapons tips aren't available yet.</strong> They
                  will be added soon.
                </p>
              </div>
            </>
          )}
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      </>
    </DashboardLayout>
  );
};

export default GFLCharacterDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <Seo
      title={character.name + ' Guide and Build | GFL: Exilium | Prydwen'}
      description={
        'Discover the best builds, teams and detailed guides for ' +
        character.name +
        ' in GFL: Exilium.'
      }
      image={character.cardImage}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulGflCharacter(
      filter: { id: { eq: $contentfulId } }
    ) {
      nodes {
        ...GflCharacterFieldsFragment
      }
    }
  }
`;
