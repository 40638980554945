import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldAnjo: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you pull Anjo Nala?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_anjo.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you pull Anjo Nala?</h1>
          <h2>
            Not sure if pulling Anjo Nala is the right move? No worries! Check
            our review and guide for her!
          </h2>
          <p>
            Last updated: <strong>06/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Should You Pull? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="anjo-nala"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>
          Due to her status as a Limited character, the answer to this question
          will most likely be yes regardless of her usefulness. Luckily, you
          don’t need to worry about that! Anjo Nala is a VERY good Sub Carry
          that provides stats to another ally via her [Contract], launches a
          bunch of FUAs, has access to semi-consistent CC and deals a truck load
          of personal damage.
        </p>
        <p>
          Due to this generalist kit, Anjo Nala is applicable to a lot of
          different teams. For new players she can function as a versatile
          support and even damage dealer, while she can fill the remaining holes
          in the rosters of experienced players.
        </p>
        <p>
          If you already have specific upcoming teams in mind that you’re trying
          to build such as Poison (Willow/Tuesday), Inspiration
          (Barcarola/Voyager) or Riposte (J/NewBabel) you don’t technically NEED
          Anjo Nala, but she is a great pick nonetheless.
        </p>
        <SectionHeader title="Review" />
        <p>
          Anjo Nala is a 6-star Arcanist of the Beast Afflatus. She excels at...
          pretty much everything, providing ample Support, casting a huge amount
          of FUAs, applying very consistent CC and also dealing large amounts of
          personal damage.
        </p>
        <p>
          There's two gimmicks in her kit. The first is her [Contract], which
          she applies to a target ally at the beginning of the stage. Depending
          on the ally's Afflatus, they and Anjo Nala receive varying stat
          bonuses and when the bound ally casts an Incantation, Anjo Nala
          activates one of several effects (ST FUA, Moxie Gen, Mass FUA). The
          bound ally cannot gain any Moxie, giving any they gain to Anjo
          instead, and her own max Moxie is increased to 12 (with 8 needed to
          generate her Ultimate).
        </p>
        <p>
          The second gimmick is [Hymn], which is a 7 turn duration Channel that
          she activates with "Twin Phantoms" Incantation. All her Incantations
          reduce the duration of this Channel, and when it ends, she casts
          "Angel's Temptation", which is a powerful Mass attack that usually
          inflicts [Titillated] (stacking debuff, steals ATK from carrier).
          However, if its cast through this Channel, it inflicts [Captivated]
          (hard CC, Titillated transforms into Captivated when stacked) instead.
          It is also the Mass FUA that Anjo casts when bound to a Mineral/Star
          character, though this FUA inflicts [Titillated].
        </p>
        <p>
          Her Ultimate is interesting. First, she enters herself and her bound
          ally into a Channel. Then, at the end of the turn, the bound ally is
          forced to use their Ultimate, after which she uses her own.
          "Interwoven Dreams" is a powerful Mass attack that inflicts several
          random [Neg Status]es, with its damage scaling with the amount of
          different [Neg Statuses] on her targets.
        </p>
        <h5>Gameplay </h5>
        <p>
          Anjo Nala has two very different (not) playstyles: Support/Nuke and
          Nuke/Nuke.
        </p>
        <p>
          She can be played as a more generic FUA/CC based Support for units
          like 37, Fatutu and Lucy. Here, she provides consistent FUAs, filling
          the gaps in certain kits (37 requiring a lot of FUAs/Actions and Lucy
          having lacking ST DMG) while providing stat boosts for the Main Carry.
          The most interesting pick here is 37, especially if you have Portraits
          on her, as the combo of "Angel's Temptation" and "Supplementary
          Formula" casts can cycle permanent [Captivated] CC against
          non-resistant enemies.
        </p>
        <p>
          The other playstyle is a bit more niche but still has value,
          especially for new players. Due to her True Limited privilege, Anjo
          Nala's numbers are extraordinarily high, which allows her to act as
          Main Carry for a majority of the game. There's also interesting combos
          with Shamane (Euphoria), Flutterpage and Eternity
          (Euphoria)/Semmelweis. With Shamane she can set up 1-turn Ultimate
          cycles, with Flutterpage she can imitate her 37 combo and HP Loss
          teams can use Anjo as a Carry.
        </p>
        <p>
          Generally for high-end players, Main Carry Anjo Nala isn't very
          appealing, as they'll have access to Windsong, Lucy (and later Willow,
          Barcarola, Lilya Euphoria). However, Anjo Nala can fill a lot of holes
          in a new player's roster. She's generally usable in most teams, as of
          writing this her weakest [Contract] is definitely the Beast/Plant one
          (though even that has its combos).
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Takes “jack of all trades” and puts “master of all” after it
                    due to her ability to cater to whatever the team needs
                    depending on her [Contract] partner.
                  </li>
                  <li>
                    Access to a ridiculous amount of Follow-Up Attacks while in
                    Spirit/Intellect or Mineral/Star [Contract]s.
                  </li>
                  <li>
                    Able to inflict highly consistent CC with [Captivated].
                  </li>
                  <li>
                    Also fits into debuff-based teams due to the DMG increases
                    she receives on enemies carrying [Neg Status] effects
                    (+being able to inflict those herself).
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Doesn't mesh well with characters that need to generate
                    Moxie or have a generally slow Ultimate cycle (example:
                    Willow).
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>Completely fine at P0.</p>
        <p>
          If you’re looking to invest into Anjo Nala, there are several powerful
          Portrait breakpoints to look for.
        </p>
        <ul>
          <li>
            Portrait 1 allows her to cast an additional “Angel’s Temptation” FUA
            under the Mineral/Star [Contract] while also increasing its damage.
            This is solid for FUA teams involving Windsong, 37, Flutterpage, Jiu
            Niangzi, Fatutu and the likes.
          </li>
          <li>
            If you’re going the damage route, further breakpoints would be
            Portrait 2 and 5, both increasing the damage of the “Twin Phantoms”
            channel and “Interwoven Dreams” respectively while offering a bit of
            extra debuffing.
          </li>
          <li>
            If you’re keeping her exclusively as a Support, it’s best to finish
            her at Portrait 1; however, if you’re eager to dump pulls on her,
            Portrait 4 offers a modicum of extra [Contract] stats that the bound
            ally may like. Though if you’re at Portrait 4 you might as well just
            gun for Portrait 5 and complete both paths.
          </li>
        </ul>
        <SectionHeader title="Great Synergies" />
        <p>
          Anjo Nala generally synergizes with most characters to some degree.
          She does, however, excel at supporting FUA teams.
        </p>
        <p>Notable synergies include:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="jiu-niangzi"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="37"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="shamane"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>Future notable synergies:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="lilya"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="flutterpage"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="ms-newbabel"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="fatutu"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on Anjo Nala:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="transcendent-love"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="blasphemer-of-night"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="on-the-hand-of-time"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <SectionHeader title="Resonance" />
        <h6>R10 ATK Build (Recommended)</h6>
        <StaticImage
          src="../../../images/reverse/generic/anjo_r_1.webp"
          alt="Guides"
        />
        <SectionHeader title="Teams" />
        <p>A few suggestions are listed below.</p>
        <h5>Standard Teams</h5>
        <div className="team-box">
          <h5 className="star">
            BOMB.ZIP
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="lucy"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="anjo-nala"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="vila"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Mercuria</strong>
              </li>
              <li>Contracted: Lucy</li>
              <li>
                This is a raid-centered team surrounding Lucy. Using the
                Contract, Lucy can spam her cards while Anjo Nala in-turn
                provides ST Follow-Ups to help with damage directly to the boss.
                Mercuria and Vila primarily contribute Crit Rate/DMG support
                here, while Vila can additionally increase the damage of Lucy’s
                Follow-Ups with her [Song of Passion] under Ultimate.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Captivating Dorito<span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="37"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="anjo-nala"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="vila"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Kakania</strong>
              </li>
              <li>Contracted: 37</li>
              <li>
                An interesting team built around allowing 37 to spam as many
                cards as she wants, which lets Anjo Nala throw out a multitude
                of Mass FUAs that all apply her [Titillated] debuff. From there,
                you can cycle 37 and Anjo Nala to create a crowd control cycle
                on stages that aren’t resistant to this. This team is made more
                viable if you have Portraits on 37 or down the line when Fatutu
                releases and can replace Vila for additional fuel for
                “Supplementary Formula”.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldAnjo;

export const Head: React.FC = () => (
  <Seo
    title="Should you pull Anjo Nala? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if pulling Anjo Nala is the right move? No worries! Check our review and guide for her!"
    game="reverse"
  />
);
