/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useReducer, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../generic-page.scss';
import './memory-of-chaos.scss';
import {
  Row,
  Col,
  Alert,
  FloatingLabel,
  Form,
  Button,
  Card,
  Collapse,
  OverlayTrigger,
  Popover
} from 'react-bootstrap';
import { MoCCurrent10_1 } from '../../modules/hsr/moc/current-10-1';
import { MoCCurrent10_2 } from '../../modules/hsr/moc/current-10-2';
import { MoCCurrent11_1 } from '../../modules/hsr/moc/current-11-1';
import { MoCCurrent11_2 } from '../../modules/hsr/moc/current-11-2';
import { MoCCurrent12_1 } from '../../modules/hsr/moc/current-12-1';
import { MoCCurrent12_2 } from '../../modules/hsr/moc/current-12-2';
import { MoCSupportedCharactersList as listCharacters } from '../../modules/hsr/moc/supported-chars';
import { HSRCharacter } from '../../modules/hsr/common/components/hsr-character';
import Switch from 'react-switch';
import { Box } from '../../modules/common/components/box';
import {
  faArrowDown,
  faArrowUp,
  faBriefcaseMedical,
  faCircleQuestion,
  faFilter,
  faFlask,
  faHandFist,
  faVirus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MoCCharacterData } from '../../modules/hsr/moc/char-stats';
import { HSRMocHisto } from '../../modules/hsr/moc/histo';

// enum to replace strings. Not necessary, but good practice.
enum CharacterState {
  include,
  exclude
}

enum FilterMode {
  includeAny,
  includeAll
}

// interface for grouping together character use state information
interface ICharacterUseStateInfo {
  name: string;
  state: CharacterState;
  setFunction: (CharacterState: CharacterState) => void;
}

const HSRMoCAnalyticsPage: React.FC = () => {
  const [characterList, setCharacterList] = useState(MoCCharacterData);
  const [characterListCombined, setCharacterListCombined] = useState(false);
  const [characterListEidolon, setCharacterListEidolon] = useState(false);
  const [enableCycle, setEnableCycle] = useState(false);
  const [currentTeamStageConfig, setCurrentTeamStageConfig] =
    useState('stage_12');
  const [currentTeamStage, setCurrentTeamStage] = useState(MoCCurrent12_1);
  const [currentTeamStage2, setCurrentTeamStage2] = useState(MoCCurrent12_2);
  const [currentTeamStageFiltered, setCurrentTeamStageFiltered] =
    useState(MoCCurrent12_1);
  const [currentTeamStage2Filtered, setCurrentTeamStage2Filtered] =
    useState(MoCCurrent12_2);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const [currentPhase, setCurrentPhase] = useState('2.7');
  const [currentTotalUsers, setCurrentTotalUsers] = useState('16473');
  const [currentSelfUsers, setCurrentSelfUsers] = useState('8492');
  const [currentRandomUsers, setCurrentRandomUsers] = useState('7981');
  const [currentRandomUsers12, setCurrentRandomUsers12] = useState('4108');
  const [updateDate, setUpdateDate] = useState('11/01/2025');

  // filter mode
  const [currentFilterMode, setFilterMode] = useState(FilterMode.includeAny);

  // create all supported character icon states
  const listCharacterUseStates: Array<ICharacterUseStateInfo> = [];
  // just the states in a list
  const listCharacterStates: Array<CharacterState> = [];
  listCharacters.map((entry) => {
    const [filterCharacterMode, setFilterCharacterMode] = useState(
      CharacterState.include
    );
    listCharacterUseStates.push({
      name: entry['value'],
      state: filterCharacterMode,
      setFunction: setFilterCharacterMode
    });
    listCharacterStates.push(filterCharacterMode);
  });

  const [open, setOpen] = useState(true);
  const [currentShow, setCurrentShow] = useState(10);

  const handleShowMore = () => {
    setCurrentShow(50);
  };

  const handleShowLess = () => {
    setCurrentShow(10);
  };

  useEffect(() => {
    if (currentTeamStageConfig === 'stage_10') {
      setCurrentTeamStage(MoCCurrent10_1);
      setCurrentTeamStage2(MoCCurrent10_2);
      setCurrentTeamStageFiltered(MoCCurrent10_1);
      setCurrentTeamStage2Filtered(MoCCurrent10_2);
    } else if (currentTeamStageConfig === 'stage_11') {
      setCurrentTeamStage(MoCCurrent11_1);
      setCurrentTeamStage2(MoCCurrent11_2);
      setCurrentTeamStageFiltered(MoCCurrent11_1);
      setCurrentTeamStage2Filtered(MoCCurrent11_2);
    } else if (currentTeamStageConfig === 'stage_12') {
      setCurrentTeamStage(MoCCurrent12_1);
      setCurrentTeamStage2(MoCCurrent12_2);
      setCurrentTeamStageFiltered(MoCCurrent12_1);
      setCurrentTeamStage2Filtered(MoCCurrent12_2);
    }

    setEnableCycle(false);
    // set all filters on all characters to 'include'
    const currentDefaultCharState: CharacterState = CharacterState.include;
    listCharacterUseStates.map((entry) => {
      entry.setFunction(currentDefaultCharState);
    });
  }, [currentTeamStageConfig]);

  useEffect(() => {
    if (enableCycle === true) {
      currentTeamStageFiltered.sort((a, b) =>
        b.avg_round < a.avg_round ? 1 : -1
      );
      currentTeamStage2Filtered.sort((a, b) =>
        b.avg_round < a.avg_round ? 1 : -1
      );
    } else {
      currentTeamStageFiltered.sort((a, b) =>
        b.app_rate > a.app_rate ? 1 : -1
      );
      currentTeamStage2Filtered.sort((a, b) =>
        b.app_rate > a.app_rate ? 1 : -1
      );
    }
    forceUpdate();
  }, [enableCycle]);

  useEffect(() => {
    const filtered = currentTeamStage;
    const filtered2 = currentTeamStage2;
    const listFiltered = [filtered, filtered2];

    // filter function. Filters both stages for duplicate code reduction
    // the lists within listFiltered will be updated. Function has void return type.
    // note: functionFilter should take and return an object of the same type as the elements of listFiltered
    const functionFilterAll = (listFiltered, functionFilter) => {
      for (let i = 0; i < listFiltered.length; i++) {
        listFiltered[i] = functionFilter(listFiltered[i]);
      }
    };

    // filter mode based on toggle
    // block to restrict dictFilter scope. Unnecessary.
    {
      const dictFilter: { [name: string]: ICharacterUseStateInfo } = {};
      let dictLength = 0;
      switch (currentFilterMode) {
        case FilterMode.includeAny:
          // teams can be made of of any combination of selected units. Filter based on the existence of unselected units.
          // create filter dict of unselected units for faster access
          listCharacterUseStates.map((entry) => {
            if (entry.state === CharacterState.exclude) {
              dictFilter[entry.name] = entry;
            }
          });

          functionFilterAll(listFiltered, (filtered) => {
            return filtered.filter(
              (emp) =>
                // logical not in front of what is expected to be undefined (falsey value)
                !dictFilter[emp.char_one.toLowerCase()] &&
                !dictFilter[emp.char_two.toLowerCase()] &&
                !dictFilter[emp.char_three.toLowerCase()] &&
                !dictFilter[emp.char_four.toLowerCase()]
            );
          });
          break;
        case FilterMode.includeAll:
          // teams must have all selected units. Filter based on the existence of selected units.
          // create filter dict of selected units for faster access. Track filter length.
          listCharacterUseStates.map((entry) => {
            if (entry.state === CharacterState.include) {
              dictFilter[entry.name] = entry;
              dictLength++;
            }
          });

          // When the selection is empty, set lists to empty
          if (dictLength == 0) {
            listFiltered[0] = [];
            listFiltered[1] = [];
          } else {
            functionFilterAll(listFiltered, (filtered) => {
              return filtered.filter((emp) => {
                // filter based on how many remaining wild cards there are
                let selectedCount = 0;
                if (dictFilter[emp.char_one.toLowerCase()]) {
                  selectedCount++;
                }
                if (dictFilter[emp.char_two.toLowerCase()]) {
                  selectedCount++;
                }
                if (dictFilter[emp.char_three.toLowerCase()]) {
                  selectedCount++;
                }
                if (dictFilter[emp.char_four.toLowerCase()]) {
                  selectedCount++;
                }

                // if the two numbers match, then all selected chars are in the team.
                // selectedCount should never be greater than dictLength, but it probably should behave this way if it did.
                if (selectedCount >= dictLength) {
                  return true;
                }
                return false;
              });
            });
          }

          break;

        default:
          // should never be reached
          break;
      }
    }

    setEnableCycle(false);
    setCurrentTeamStageFiltered(listFiltered[0]);
    setCurrentTeamStage2Filtered(listFiltered[1]);
  }, [...listCharacterStates, currentFilterMode]);

  const resetStuff: () => void = () => {
    listCharacterUseStates.map((entry) =>
      entry.setFunction(CharacterState.include)
    );
  };

  // deselect everything
  const unsetStuff: () => void = () => {
    listCharacterUseStates.map((entry) =>
      entry.setFunction(CharacterState.exclude)
    );
  };

  // toggle filter mode.
  // any -> all deselects everything
  // all -> any does not change selection
  const toggleFilterMode: () => void = () => {
    switch (currentFilterMode) {
      case FilterMode.includeAny:
        listCharacterUseStates.map((entry) =>
          entry.setFunction(CharacterState.exclude)
        );
        setFilterMode(FilterMode.includeAll);
        break;
      case FilterMode.includeAll:
        setFilterMode(FilterMode.includeAny);
        break;
      default:
        // unreachable in theory
        break;
    }
  };

  const items = currentTeamStageFiltered
    .slice(0, currentShow)
    .map((item, index) => (
      <div className="team-row" key={index}>
        <div className="column info">
          <p className="rank">Rank {item.rank}</p>
          <p className="usage">App. rate: {item.app_rate}%</p>
          <p className="rounds">
            Avg. cycles: {item.avg_round != 99.99 ? item.avg_round : '-'}
          </p>
        </div>
        <div className="column characters">
          <HSRCharacter
            slug={item.char_one.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
          <HSRCharacter
            slug={item.char_two.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
          <HSRCharacter
            slug={item.char_three.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
          <HSRCharacter
            slug={item.char_four.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
        </div>
      </div>
    ));

  const items2 = currentTeamStage2Filtered
    .slice(0, currentShow)
    .map((item, index) => (
      <div className="team-row" key={index}>
        <div className="column info">
          <p className="rank">Rank {item.rank}</p>
          <p className="usage">App. rate: {item.app_rate}%</p>
          <p className="rounds">
            Avg. cycles: {item.avg_round != 99.99 ? item.avg_round : '-'}
          </p>
        </div>
        <div className="column characters">
          <HSRCharacter
            slug={item.char_one.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
          <HSRCharacter
            slug={item.char_two.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
          <HSRCharacter
            slug={item.char_three.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
          <HSRCharacter
            slug={item.char_four.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
        </div>
      </div>
    ));

  useEffect(() => {
    setCurrentTeamStage(MoCCurrent12_1);
    setCurrentTeamStage2(MoCCurrent12_2);
    setCurrentTeamStageFiltered(MoCCurrent12_1);
    setCurrentTeamStage2Filtered(MoCCurrent12_2);
  }, []);

  // creates include/exclude character icons for filtering
  const createFilterCharacterIcons: React.FC = () => {
    // only calculate selected count if in includeAll filter mode
    let currentSelectedCount = 0;
    if (currentFilterMode === FilterMode.includeAll) {
      listCharacterUseStates.map((entry) => {
        if (entry.state === CharacterState.include) {
          currentSelectedCount++;
        }
      });
    }

    return (
      <div className={`employees-container`}>
        {listCharacterUseStates.map((entry, index) => {
          const isSelected = entry.state === CharacterState.include;
          return (
            <span key={index}>
              {isSelected ||
              !(
                currentFilterMode === FilterMode.includeAll &&
                currentSelectedCount >= 4
              ) ? (
                <Card
                  onClick={() => {
                    switch (entry.state) {
                      case CharacterState.include:
                        entry.setFunction(CharacterState.exclude);
                        break;
                      case CharacterState.exclude:
                        entry.setFunction(CharacterState.include);
                        break;
                      default:
                        break;
                    }
                  }}
                  className={`avatar-card ${isSelected ? 'selected' : ''}`}
                >
                  <HSRCharacter
                    slug={entry.name}
                    mode="icon-no-link"
                    showIcon
                  />
                </Card>
              ) : (
                <Card className="avatar-card">
                  <HSRCharacter
                    slug={entry.name}
                    mode="icon-no-link"
                    showIcon
                  />
                </Card>
              )}
            </span>
          );
        })}
      </div>
    );
  };

  const popoverStrict = (
    <Popover id="popover-strict">
      <Popover.Header as="h3">Strict Mode</Popover.Header>
      <Popover.Body>
        <strong>Strict Mode</strong> will show teams that contain only the
        characters you have selected (limit up to 4 characters).
      </Popover.Body>
    </Popover>
  );

  return (
    <DashboardLayout className={'generic-page memory-of-chaos'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>Memory of Chaos Analytics ({currentPhase})</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/starrail/categories/category_fh.png"
            alt="MoC"
          />
        </div>
        <div className="page-details">
          <h1>Memory of Chaos Analytics ({currentPhase})</h1>
          <h2>
            Information and analytics about the current and past Memory of Chaos
            phases!
          </h2>
          <p>
            Last updated: <strong>{updateDate}</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Foreword" />
      <p>
        The Memory of Chaos Analytics is the fruit of collaboration between
        Prydwen and{' '}
        <strong>
          Spiral Stats who create beautiful infographics with character and team
          usage for Genshin Impact
        </strong>
        . They recently branched out to Honkai: Star Rail, so expect similar
        great images to be prepared by them for each Memory of Chaos phase!
      </p>
      <p>You can check their Honkai: Star Rail works by going here:</p>
      <Button
        variant="primary"
        href="https://www.reddit.com/user/LvlUrArti/comments/155o3wi/compilation_of_my_honkai_star_rail_infographics/"
        target="_blank"
        rel="noreferrer"
        className="custom-button"
      >
        Spiral Stats
      </Button>
      <p>
        I would like to thank{' '}
        <strong>
          Grimro,{' '}
          <a
            href="https://discord.gg/pokkesvillage"
            target="_blank"
            rel="noreferrer"
          >
            MrPokke
          </a>
          ,{' '}
          <a
            href="https://discord.gg/KQMStarRail"
            target="_blank"
            rel="noreferrer"
          >
            KQM
          </a>
          ,{' '}
          <a
            href="https://discord.gg/kafkamains"
            target="_blank"
            rel="noreferrer"
          >
            Kafka Mains
          </a>{' '}
          and{' '}
          <a
            href="http://discord.gg/guobafans"
            target="_blank"
            rel="noreferrer"
          >
            Guoba
          </a>{' '}
          communities for sharing their clear information with us!
        </strong>{' '}
        The stats you can find on the page will be updated on a bi-weekly basis
        to make them as accurate as possible and help you with clearing the
        current MoC phase!
      </p>
      <p>
        If you have any suggestions about the contents of this page, contact the
        Spiral Stats team on Discord (username: lvlurarti) or Reddit (username:
        LvlUrArti). Also, if you want to see the raw data we have gathered, you
        can find them on our GitHub account,{' '}
        <a
          href="https://github.com/piedorr/MocStats"
          target="_blank"
          rel="noreferrer"
        >
          here.
        </a>{' '}
      </p>
      <SectionHeader title="Help us!" />
      <div className="top-partner-intro hsr">
        <Box>
          <Row>
            <Col>
              <div className="inner">
                <h2>Help us make the Analytics better!</h2>
                <p>
                  You just need to share your UID to help us. It's so simple!
                </p>
              </div>
            </Col>
          </Row>
        </Box>
      </div>
      <p>
        If you want to share your Memory of Chaos clear information with us, you
        simply need to fill the form below and{' '}
        <strong>
          provide us with your UID and make your Hoyo profile public - we will
          automatically parse the MoC clear information from your profile after
          that
        </strong>
        ! The more people join the project, the better and more accurate data we
        can provide!
      </p>
      <Button
        variant="primary"
        href="https://bit.ly/mocstats"
        target="_blank"
        rel="noreferrer"
        className="custom-button"
      >
        MoC Stats Form
      </Button>
      <SectionHeader title="Memory of Chaos" />
      <Row xs={1} xl={2} className="moc-information">
        <Col>
          <div className="box with-gradient red moc-info">
            <p className="info">Current</p>
            <h3>2.7 Memory of Chaos</h3>
            <p className="duration">06.01.2025 - 27.02.2025</p>
            <hr />
            <p className="memory">
              <span>Memory Turbulence:</span> Summons 2 Trotters at the
              beginning of each wave or each Cycle. When defeated, the Trotters
              deal a set amount of DMG to all Elite enemies and above.
            </p>
          </div>
        </Col>
      </Row>
      <SectionHeader title={`Fastest cycle ${currentPhase}`} />
      <Alert variant="primary">
        <p>
          <strong>Disclaimer:</strong> Don't use the data to compare the pull
          value between characters. Currently the average cycle clear takes into
          account both sides - sadly the API is pretty limited and we have no
          way to obtain the average cycle per 1 side currently.
        </p>
        <p>
          For more accurate assessment, check our tier list and the character
          reviews.
        </p>
      </Alert>
      <h6>Additional information</h6>
      <ul>
        <li>
          The data has been last updated on <strong>{updateDate},</strong>
        </li>
        <li>
          <strong>{currentTotalUsers} players</strong> shared their{' '}
          {currentPhase} Memory of Chaos clear data with us ({currentSelfUsers}{' '}
          of the sample are self-reported players that filled our form,{' '}
          {currentRandomUsers} are from random UIDs that we scanned),
        </li>
        <ul>
          <li>
            We have scanned <strong>1.2 million</strong> accounts in EU, NA and
            Asia regions and out of those, <strong>{currentRandomUsers}</strong>{' '}
            cleared MoC 1 and <strong>{currentRandomUsers12}</strong> cleared
            MoC 12 and were included in our statistics.
          </li>
        </ul>
        <li>
          Currently the average cycle clear takes into account both sides -
          sadly the API is pretty limited in that way and we have no way to
          obtain the average cycle per 1 side currently.
        </li>
        <li>
          If a team used a limited 5* E1+ character, it was excluded from the
          average cycles calculation,
        </li>
        <li>
          The data includes all Light Cones - if you want to compare Signature
          vs Free options, go to the profile of a character as there we post
          detailed breakpoints,
        </li>
        <li>
          The data only includes characters information based on the usage in{' '}
          <strong>MoC stage 12</strong>.
        </li>
      </ul>
      <div className="configuration">
        <div className="custom-switch">
          <Switch
            checked={characterListEidolon}
            onChange={(value) => setCharacterListEidolon(value)}
            onColor="#009EEC"
            offColor="#484950"
            className="switch"
          />
          Include Eidolons for 5* (up to E6){' '}
          <span className="tag new">New!</span>
        </div>
      </div>
      <h5>
        All characters{' '}
        {characterListEidolon ? (
          <>
            <span>[Up to E6 for 5*]</span>
          </>
        ) : (
          <>
            <span>[Only E0 for 5*]</span>
          </>
        )}
      </h5>
      {characterListEidolon ? (
        <>
          <div className={`char-view simple`}>
            <>
              {characterList
                .sort((a, b) => (a.round_eidolons > b.round_eidolons ? 1 : -1))
                .map((character, index) => {
                  return (
                    <div key={index} className="char-box">
                      <Card className="avatar-card">
                        <HSRCharacter
                          slug={character.char}
                          mode="icon"
                          enablePopover
                          showIcon
                        />
                      </Card>
                      <div className="column percentage">
                        <p>{character.round_eidolons}</p>
                      </div>
                    </div>
                  );
                })}
            </>
          </div>
        </>
      ) : (
        <>
          <div className={`char-view simple`}>
            <>
              {characterList
                .sort((a, b) =>
                  a.current_avg_round > b.current_avg_round ? 1 : -1
                )
                .map((character, index) => {
                  return (
                    <div key={index} className="char-box">
                      <Card className="avatar-card">
                        <HSRCharacter
                          slug={character.char}
                          mode="icon"
                          enablePopover
                          showIcon
                        />
                      </Card>
                      <div className="column percentage">
                        <p>{character.current_avg_round}</p>
                        <p className="difference">
                          {character.current_avg_round -
                            character.prev_avg_round ===
                          0 ? (
                            <>
                              {character.char === 'tingyun-fugue' ||
                              character.char === 'sunday' ? (
                                <>New!</>
                              ) : (
                                <>-</>
                              )}
                            </>
                          ) : (
                            <>
                              {character.current_avg_round -
                                character.prev_avg_round <
                              0 ? (
                                <span className="green">
                                  <FontAwesomeIcon
                                    icon={faArrowUp}
                                    width="18"
                                    className="arrow-green"
                                  />
                                  {(
                                    character.current_avg_round -
                                    character.prev_avg_round
                                  ).toFixed(2)}
                                </span>
                              ) : (
                                <span className="red">
                                  <FontAwesomeIcon
                                    icon={faArrowDown}
                                    width="18"
                                    className="arrow-red"
                                  />
                                  {(
                                    character.current_avg_round -
                                    character.prev_avg_round
                                  ).toFixed(2)}
                                </span>
                              )}{' '}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </>
          </div>
        </>
      )}
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title={`Character usage ${currentPhase}`} />
      <Alert variant="primary">
        <p>
          <strong>Disclaimer:</strong> Don't use the data to compare the pull
          value between characters. There are a lot that factors in appearance
          rate, such as the memory turbulence and enemy lineup. The appearance
          rate shouldn't be used to compare characters of different rarities due
          to the difference in their ownership rate.
        </p>
        <p>
          For more accurate assessment, check our tier list and the character
          reviews.
        </p>
      </Alert>
      <h6>Additional information</h6>
      <ul>
        <li>
          The data has been last updated on <strong>{updateDate},</strong>
        </li>
        <li>
          <strong>{currentTotalUsers} players</strong> shared their{' '}
          {currentPhase} Memory of Chaos clear data with us ({currentSelfUsers}{' '}
          of the sample are self-reported players that filled our form,{' '}
          {currentRandomUsers} are from random UIDs that we scanned),
        </li>
        <ul>
          <li>
            We have scanned <strong>1 million</strong> accounts in EU, NA and
            Asia regions and out of those, <strong>{currentRandomUsers}</strong>{' '}
            cleared MoC 1 and <strong>{currentRandomUsers12}</strong> cleared
            MoC 12 and were included in our statistics.
          </li>
        </ul>
        <li>
          Characters are ranked with appearance rate, which is how often a
          character was used by all players, regardless of whether they own the
          character or not.{' '}
          <strong> Ownership rate currently cannot be obtained.</strong>
        </li>
        <li>
          The data includes all Light Cones - if you want to compare Signature
          vs Free options, go to the profile of a character as there we post
          detailed breakpoints,
        </li>
        <li>
          The data only includes characters information based on the usage in{' '}
          <strong>MoC stage 12</strong>.
        </li>
      </ul>
      <div className="configuration">
        <div className="custom-switch">
          <Switch
            checked={characterListCombined}
            onChange={(value) => setCharacterListCombined(value)}
            onColor="#009EEC"
            offColor="#484950"
            className="switch"
          />
          Show combined
        </div>
      </div>
      {characterListCombined ? (
        <>
          <h5>All characters</h5>
          <div className={`char-view simple`}>
            {characterList
              .sort((a, b) =>
                b.current_app_rate > a.current_app_rate ? 1 : -1
              )
              .map((character, index) => {
                return (
                  <div key={index} className="char-box">
                    <Card className="avatar-card">
                      <HSRCharacter
                        slug={character.char}
                        mode="icon"
                        enablePopover
                        showIcon
                      />
                    </Card>
                    <div className="column percentage">
                      <p>{character.current_app_rate}%</p>
                      <p className="difference">
                        {character.current_app_rate -
                          character.prev_app_rate ===
                        0 ? (
                          <>
                            {' '}
                            {character.char === 'jiaoqiu' ||
                            character.char === 'yunli' ||
                            character.char === 'feixiao' ||
                            character.char === 'moze' ||
                            character.char === 'march-7th-swordmaster' ? (
                              <>New!</>
                            ) : (
                              <>-</>
                            )}
                          </>
                        ) : (
                          <>
                            {character.current_app_rate -
                              character.prev_app_rate >
                            0 ? (
                              <span className="green">
                                <FontAwesomeIcon
                                  icon={faArrowUp}
                                  width="18"
                                  className="arrow-green"
                                />
                                {(
                                  character.current_app_rate -
                                  character.prev_app_rate
                                ).toFixed(2)}
                                %
                              </span>
                            ) : (
                              <span className="red">
                                <FontAwesomeIcon
                                  icon={faArrowDown}
                                  width="18"
                                  className="arrow-red"
                                />
                                {(
                                  character.current_app_rate -
                                  character.prev_app_rate
                                ).toFixed(2)}
                                %
                              </span>
                            )}{' '}
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      ) : (
        <div className="split-version">
          <h5>Limited 5★ characters</h5>
          <div className={`char-view simple`}>
            {characterList
              .filter((char) => char.rarity === 'Limited 5*')
              .sort((a, b) =>
                b.current_app_rate > a.current_app_rate ? 1 : -1
              )
              .map((character, index) => {
                return (
                  <div key={index} className="char-box">
                    <Card className="avatar-card">
                      <HSRCharacter
                        slug={character.char}
                        mode="icon"
                        enablePopover
                        showIcon
                      />
                    </Card>
                    <div className="column percentage">
                      <p>{character.current_app_rate}%</p>
                      <p className="difference">
                        {character.current_app_rate -
                          character.prev_app_rate ===
                        0 ? (
                          <>
                            {' '}
                            {character.char === 'jiaoqiu' ||
                            character.char === 'yunli' ||
                            character.char === 'feixiao' ||
                            character.char === 'moze' ||
                            character.char === 'march-7th-swordmaster' ? (
                              <>New!</>
                            ) : (
                              <>-</>
                            )}
                          </>
                        ) : (
                          <>
                            {character.current_app_rate -
                              character.prev_app_rate >
                            0 ? (
                              <span className="green">
                                <FontAwesomeIcon
                                  icon={faArrowUp}
                                  width="18"
                                  className="arrow-green"
                                />
                                {(
                                  character.current_app_rate -
                                  character.prev_app_rate
                                ).toFixed(2)}
                                %
                              </span>
                            ) : (
                              <span className="red">
                                <FontAwesomeIcon
                                  icon={faArrowDown}
                                  width="18"
                                  className="arrow-red"
                                />
                                {(
                                  character.current_app_rate -
                                  character.prev_app_rate
                                ).toFixed(2)}
                                %
                              </span>
                            )}{' '}
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
          <h5>Standard 5★ characters</h5>
          <div className={`char-view simple`}>
            {characterList
              .filter((char) => char.rarity === 'Standard 5*')
              .sort((a, b) =>
                b.current_app_rate > a.current_app_rate ? 1 : -1
              )
              .map((character, index) => {
                return (
                  <div key={index} className="char-box">
                    <Card className="avatar-card">
                      <HSRCharacter
                        slug={character.char}
                        mode="icon"
                        enablePopover
                        showIcon
                      />
                    </Card>
                    <div className="column percentage">
                      <p>{character.current_app_rate}%</p>
                      <p className="difference">
                        {character.current_app_rate -
                          character.prev_app_rate ===
                        0 ? (
                          <>
                            {' '}
                            {character.char === 'jiaoqiu' ||
                            character.char === 'yunli' ||
                            character.char === 'feixiao' ||
                            character.char === 'moze' ||
                            character.char === 'march-7th-swordmaster' ? (
                              <>New!</>
                            ) : (
                              <>-</>
                            )}
                          </>
                        ) : (
                          <>
                            {character.current_app_rate -
                              character.prev_app_rate >
                            0 ? (
                              <span className="green">
                                <FontAwesomeIcon
                                  icon={faArrowUp}
                                  width="18"
                                  className="arrow-green"
                                />
                                {(
                                  character.current_app_rate -
                                  character.prev_app_rate
                                ).toFixed(2)}
                                %
                              </span>
                            ) : (
                              <span className="red">
                                <FontAwesomeIcon
                                  icon={faArrowDown}
                                  width="18"
                                  className="arrow-red"
                                />
                                {(
                                  character.current_app_rate -
                                  character.prev_app_rate
                                ).toFixed(2)}
                                %
                              </span>
                            )}{' '}
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
          <h5>Non Warp 5★/4★ characters</h5>
          <div className={`char-view simple`}>
            {characterList
              .filter((char) => char.rarity === '4*')
              .sort((a, b) =>
                b.current_app_rate > a.current_app_rate ? 1 : -1
              )
              .map((character, index) => {
                return (
                  <div key={index} className="char-box">
                    <Card className="avatar-card">
                      <HSRCharacter
                        slug={character.char}
                        mode="icon"
                        enablePopover
                        showIcon
                      />
                    </Card>
                    <div className="column percentage">
                      <p>{character.current_app_rate}%</p>
                      <p className="difference">
                        {character.current_app_rate -
                          character.prev_app_rate ===
                        0 ? (
                          <>
                            {' '}
                            {character.char === 'jiaoqiu' ||
                            character.char === 'yunli' ||
                            character.char === 'feixiao' ||
                            character.char === 'moze' ||
                            character.char === 'march-7th-swordmaster' ? (
                              <>New!</>
                            ) : (
                              <>-</>
                            )}
                          </>
                        ) : (
                          <>
                            {character.current_app_rate -
                              character.prev_app_rate >
                            0 ? (
                              <span className="green">
                                <FontAwesomeIcon
                                  icon={faArrowUp}
                                  width="18"
                                  className="arrow-green"
                                />
                                {(
                                  character.current_app_rate -
                                  character.prev_app_rate
                                ).toFixed(2)}
                                %
                              </span>
                            ) : (
                              <span className="red">
                                <FontAwesomeIcon
                                  icon={faArrowDown}
                                  width="18"
                                  className="arrow-red"
                                />
                                {(
                                  character.current_app_rate -
                                  character.prev_app_rate
                                ).toFixed(2)}
                                %
                              </span>
                            )}{' '}
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      {/* <SectionHeader title={`Character usage over time`} />
      <p>
        The below graph shows the trends of how the Memory of Chaos meta evolved
        since the moment we started gathering our data.
      </p>
      <div className="trends-graph">
        <div className="desktop">
          <iframe
            className="responsive-iframe"
            src="https://public.tableau.com/views/SpiralStatsHSR/SpiralUsage?:language=en-US&:display_count=n&:origin=viz_share_link&:embed=y&:showVizHome=n&:apiID=host0#navType=0&navSrc=Parse"
            width="100%"
            height="850"
            allowFullScreen
          ></iframe>
        </div>
        <div className="mobile">
          <p>
            We are sorry, but the graph is currently only available on desktop
            sized screens.
          </p>
        </div>
      </div> */}
      <SectionHeader title={`Character usage over time`} />
      <p>
        The table below shows the average performance and usage rate in the{' '}
        <strong>last 3 phases of Memory of Chaos</strong> - so what's being
        taken into account for the purpose of our tier list.{' '}
        <strong>
          We don't just look at the last phase data because the buffs and enemy
          layout heavily impact performance of some characters.
        </strong>{' '}
        Keep in mind that sometimes the data can be skewed - for example high
        usage rate usually negatively impacts the character cycles and the
        opposite also happens when a character isn't that popular, but performs
        above expectations thanks to dedicated fans. We don't take the data at
        face value, but further correlate it with our testing to get a better
        picture of which characters make clearing MoC easier.
      </p>
      <p>
        Characters with <strong>*</strong> behind the data only have 1 phase
        worth of data, so they are pretty skewed.
      </p>
      <Row xs={1} xl={2} xxl={4} className="histo-info">
        <Col>
          <h5 className="dps">
            <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
          </h5>
          <div className="histo-table">
            <div className="histo-row header">
              <div className="header first">&nbsp;Char.</div>
              <div className="header">Av. Cycle</div>
              <div className="header">Av. Usage</div>
            </div>
            {HSRMocHisto.filter((char) => char.role === 'dps')
              .sort((a, b) => (a.score > b.score ? 1 : -1))
              .map((character, index) => {
                return (
                  <div className="histo-row" key={index}>
                    <div className="character">
                      <Card className="avatar-card">
                        <HSRCharacter
                          slug={character.slug}
                          mode="icon"
                          enablePopover
                        />
                      </Card>
                    </div>
                    <div
                      className={`cycle  ${
                        character.score < 7.5 && 'score-6'
                      } ${
                        character.score >= 7.5 &&
                        character.score < 8.5 &&
                        'score-7'
                      } ${
                        character.score >= 8.5 &&
                        character.score < 9 &&
                        'score-8'
                      } ${
                        character.score >= 9 &&
                        character.score < 10 &&
                        'score-9'
                      } ${character.score >= 10 && 'score-11'}`}
                    >
                      <p>
                        {character.score >= 10 ? (
                          '10+ cyc.'
                        ) : (
                          <>
                            {character.score} cyc.{character.new && '*'}
                          </>
                        )}
                      </p>
                    </div>
                    <div
                      className={`usage ${character.usage > 75 && 'score-7'} ${
                        character.usage > 50 &&
                        character.usage <= 75 &&
                        'score-8'
                      } ${
                        character.usage >= 25 &&
                        character.usage < 50 &&
                        'score-9'
                      } ${
                        character.usage >= 5 &&
                        character.usage < 25 &&
                        'score-10'
                      } ${character.usage <= 5 && 'score-11'}`}
                    >
                      <p>
                        {character.usage}%{character.new && '*'}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </Col>
        <Col>
          <h5 className="specialist">
            <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
          </h5>
          <div className="histo-table">
            <div className="histo-row header">
              <div className="header first">&nbsp;Char.</div>
              <div className="header">Av. Cycle</div>
              <div className="header">Av. Usage</div>
            </div>
            {HSRMocHisto.filter((char) => char.role === 'specialist')
              .sort((a, b) => (a.score > b.score ? 1 : -1))
              .map((character, index) => {
                return (
                  <div className="histo-row" key={index}>
                    <div className="character">
                      <Card className="avatar-card">
                        <HSRCharacter
                          slug={character.slug}
                          mode="icon"
                          enablePopover
                        />
                      </Card>
                    </div>
                    <div
                      className={`cycle  ${
                        character.score < 7.5 && 'score-6'
                      } ${
                        character.score >= 7.5 &&
                        character.score < 8.5 &&
                        'score-7'
                      } ${
                        character.score >= 8.5 &&
                        character.score < 9 &&
                        'score-8'
                      } ${
                        character.score >= 9 &&
                        character.score < 10 &&
                        'score-9'
                      } ${character.score >= 10 && 'score-11'}`}
                    >
                      <p>
                        {character.score >= 10 ? (
                          '10+ cyc.'
                        ) : (
                          <>
                            {character.score} cyc.{character.new && '*'}
                          </>
                        )}
                      </p>
                    </div>
                    <div
                      className={`usage ${character.usage > 75 && 'score-7'} ${
                        character.usage > 50 &&
                        character.usage <= 75 &&
                        'score-8'
                      } ${
                        character.usage >= 25 &&
                        character.usage < 50 &&
                        'score-9'
                      } ${
                        character.usage >= 5 &&
                        character.usage < 25 &&
                        'score-10'
                      } ${character.usage <= 5 && 'score-11'}`}
                    >
                      <p>
                        {character.usage}%{character.new && '*'}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </Col>
        <Col>
          <h5 className="amplifier">
            <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
          </h5>
          <div className="histo-table">
            <div className="histo-row header">
              <div className="header first">&nbsp;Char.</div>
              <div className="header">Av. Cycle</div>
              <div className="header">Av. Usage</div>
            </div>
            {HSRMocHisto.filter((char) => char.role === 'amplifier')
              .sort((a, b) => (a.score > b.score ? 1 : -1))
              .map((character, index) => {
                return (
                  <div className="histo-row" key={index}>
                    <div className="character">
                      <Card className="avatar-card">
                        <HSRCharacter
                          slug={character.slug}
                          mode="icon"
                          enablePopover
                        />
                      </Card>
                    </div>
                    <div
                      className={`cycle  ${
                        character.score < 7.5 && 'score-6'
                      } ${
                        character.score >= 7.5 &&
                        character.score < 8.5 &&
                        'score-7'
                      } ${
                        character.score >= 8.5 &&
                        character.score < 9 &&
                        'score-8'
                      } ${
                        character.score >= 9 &&
                        character.score < 10 &&
                        'score-9'
                      } ${character.score >= 10 && 'score-11'}`}
                    >
                      <p>
                        {character.score >= 10 ? (
                          '10+ cyc.'
                        ) : (
                          <>
                            {character.score} cyc.{character.new && '*'}
                          </>
                        )}
                      </p>
                    </div>
                    <div
                      className={`usage ${character.usage > 75 && 'score-7'} ${
                        character.usage > 50 &&
                        character.usage <= 75 &&
                        'score-8'
                      } ${
                        character.usage >= 25 &&
                        character.usage < 50 &&
                        'score-9'
                      } ${
                        character.usage >= 5 &&
                        character.usage < 25 &&
                        'score-10'
                      } ${character.usage <= 5 && 'score-11'}`}
                    >
                      <p>
                        {character.usage}%{character.new && '*'}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </Col>
        <Col>
          <h5 className="sustain">
            <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
          </h5>
          <div className="histo-table">
            <div className="histo-row header">
              <div className="header first">&nbsp;Char.</div>
              <div className="header">Av. Cycle</div>
              <div className="header">Av. Usage</div>
            </div>
            {HSRMocHisto.filter((char) => char.role === 'sustain')
              .sort((a, b) => (a.score > b.score ? 1 : -1))
              .map((character, index) => {
                return (
                  <div className="histo-row" key={index}>
                    <div className="character">
                      <Card className="avatar-card">
                        <HSRCharacter
                          slug={character.slug}
                          mode="icon"
                          enablePopover
                        />
                      </Card>
                    </div>
                    <div
                      className={`cycle  ${
                        character.score < 7.5 && 'score-6'
                      } ${
                        character.score >= 7.5 &&
                        character.score < 8.5 &&
                        'score-7'
                      } ${
                        character.score >= 8.5 &&
                        character.score < 9 &&
                        'score-8'
                      } ${
                        character.score >= 9 &&
                        character.score < 10 &&
                        'score-9'
                      } ${character.score >= 10 && 'score-11'}`}
                    >
                      <p>
                        {character.score >= 10 ? (
                          '10+ cyc.'
                        ) : (
                          <>
                            {character.score} cyc.{character.new && '*'}
                          </>
                        )}
                      </p>
                    </div>
                    <div
                      className={`usage ${character.usage > 75 && 'score-7'} ${
                        character.usage > 50 &&
                        character.usage <= 75 &&
                        'score-8'
                      } ${
                        character.usage >= 25 &&
                        character.usage < 50 &&
                        'score-9'
                      } ${
                        character.usage >= 5 &&
                        character.usage < 25 &&
                        'score-10'
                      } ${character.usage <= 5 && 'score-11'}`}
                    >
                      <p>
                        {character.usage}%{character.new && '*'}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </Col>
      </Row>
      <SectionHeader title={`Team usage ${currentPhase}`} />
      <Alert variant="primary">
        <p>
          <strong>Disclaimer:</strong> Don't use the data to compare the pull
          value between characters. There are a lot that factors in appearance
          rate, such as the memory turbulence and enemy lineup.
        </p>
        <p>
          For more accurate assessment, check our tier list and the character
          reviews.
        </p>
      </Alert>
      <h6>Additional information</h6>
      <ul>
        <li>
          The data has been last updated on <strong>{updateDate},</strong>
        </li>
        <li>
          <strong>{currentTotalUsers} players</strong> shared their{' '}
          {currentPhase} Memory of Chaos clear data with us ({currentSelfUsers}{' '}
          of the sample are self-reported players that filled our form,{' '}
          {currentRandomUsers} are from random UIDs that we scanned),
        </li>
        <ul>
          <li>
            We have scanned <strong>1 million</strong> accounts in EU, NA and
            Asia regions and out of those, <strong>{currentRandomUsers}</strong>{' '}
            cleared MoC 1 and <strong>{currentRandomUsers12}</strong> cleared
            MoC 12 and were included in our statistics.
          </li>
        </ul>
        <li>
          The data only includes characters information based on the usage in{' '}
          <strong>MoC stages 10-12.</strong>
        </li>
        <li>
          If a team used a limited 5* E1+ character, it was excluded from the
          average cycles calculation,
        </li>
        <li>
          The data includes all Light Cones - if you want to compare Signature
          vs Free options, go to the profile of a character as there we post
          detailed breakpoints,
        </li>
        <li>
          <strong>The % number below the Rank are calculated per stage</strong>.
        </li>
      </ul>
      <div className="filters">
        <FloatingLabel controlId="customTarget" label="Stage">
          <Form.Select
            aria-label="selected-role"
            value={currentTeamStageConfig}
            onChange={(event) => setCurrentTeamStageConfig(event.target.value)}
          >
            <option value="stage_12">{currentPhase} - Stage 12</option>
            <option value="stage_11">{currentPhase} - Stage 11</option>
            <option value="stage_10">{currentPhase} - Stage 10</option>
          </Form.Select>
        </FloatingLabel>
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="character-filter"
          aria-expanded={open}
        >
          <FontAwesomeIcon icon={faFilter} width="16" />
          {open ? 'Hide' : 'Show'} character filters
        </Button>
      </div>
      <Collapse in={open}>
        <div id="character-filter">
          <div className="character-filters">
            <h6>Character filter</h6>
            <p>
              Select the characters below that you own to show teams containing
              them (by default, all are selected). You can also enable the{' '}
              <strong>Strict Mode</strong> to search for a specific team
              composition.
            </p>
            <div className="filters-container">
              {createFilterCharacterIcons(null)}
            </div>
            <div className="options">
              <Button
                onClick={resetStuff}
                variant="primary"
                className="reset-button"
                disabled={
                  currentFilterMode === FilterMode.includeAll ? true : false
                }
              >
                Select all
              </Button>
              <Button
                onClick={unsetStuff}
                variant="primary"
                className="reset-button"
              >
                Deselect all
              </Button>
              <div className="custom-switch">
                <Switch
                  checked={currentFilterMode === FilterMode.includeAll}
                  onChange={toggleFilterMode}
                  onColor="#009EEC"
                  offColor="#484950"
                  className="switch"
                />
                <span className="text">Strict mode</span>
              </div>
              <OverlayTrigger
                trigger="click"
                rootClose
                placement="auto-start"
                overlay={popoverStrict}
              >
                <span>
                  <FontAwesomeIcon
                    icon={faCircleQuestion}
                    className="arrow"
                    width="18"
                  />
                </span>
              </OverlayTrigger>
              <div className="custom-switch">
                <Switch
                  checked={enableCycle}
                  onChange={() => setEnableCycle(!enableCycle)}
                  onColor="#009EEC"
                  offColor="#484950"
                  className="switch"
                />
                <span className="text">Sort by cycles</span>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
      <Row xs={1} xxl={2} className="teams-details">
        <Col>
          <div className="box">
            <h5>
              {currentTeamStageConfig === 'all' ? 'All stages' : 'Sub-stage 1'}
            </h5>
            <p className="team-number">
              There's <strong>{currentTeamStageFiltered.length}</strong> team(s)
              in the database matching the criteria.
            </p>
            {items}
            {items.length === 0 && (
              <div className="no-results">
                <StaticImage
                  src="../../images/starrail/no_results.png"
                  alt="No results"
                />
                <p>No teams found. Try selecting more characters.</p>
              </div>
            )}
          </div>
        </Col>
        <Col>
          <div className="box">
            <h5>Sub-stage 2</h5>
            {currentTeamStageConfig === 'all' ||
            currentTeamStageConfig === 'all_previous' ? (
              <>
                <p>
                  The all stages data aren't split into stages, but instead show
                  overall team usage across all of them. Pick a specific stage
                  to see the teams used in each sub-stage.
                </p>
              </>
            ) : (
              <>
                <p className="team-number">
                  There's <strong>{currentTeamStage2Filtered.length}</strong>{' '}
                  team(s) in the database matching the criteria.
                </p>
                {items2}
                {items2.length === 0 && (
                  <div className="no-results">
                    <StaticImage
                      src="../../images/starrail/no_results.png"
                      alt="No results"
                    />
                    <p>No teams found. Try selecting more characters.</p>
                  </div>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
      <div className="show-more-section">
        {currentShow === 10 ? (
          <>
            <button className="btn btn-primary" onClick={handleShowMore}>
              Show more teams
            </button>
          </>
        ) : (
          <>
            <button className="btn btn-primary" onClick={handleShowLess}>
              Show less teams
            </button>
          </>
        )}
      </div>
      <br /> <br />
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default HSRMoCAnalyticsPage;

export const Head: React.FC = () => (
  <Seo
    title="Memory of Chaos Analytics | Honkai: Star Rail | Prydwen Institute"
    description="Information and analytics about the current and past Memory of Chaos phases! Learn about the teams used by others to clear Memory of Chaos!"
  />
);
