import React, { useState } from 'react';
import { WWCharacter } from '../common/components/ww-character';
import { WWCharactersDMG } from '../data/ww-char-damage';
import { Row, Col } from 'react-bootstrap';

interface IProps {
  slug: string;
  name: string;
  element: string;
}

export const WWCharacterRotation: React.FC<IProps> = ({
  slug,
  name,
  element
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dpsRanking, setDpsRanking] = useState(
    WWCharactersDMG.find((rankingCharacter) => {
      if (rankingCharacter.slug === slug) return true;
    })
  );

  return (
    <>
      <>
        <p>
          Below you can find optimal rotation (or rotations) for{' '}
          <strong className={element}>{name}</strong>.{' '}
          {dpsRanking && (
            <>
              The total time needed to perform it is{' '}
              <strong className={element}>{dpsRanking.time} seconds</strong>
              {slug === 'camellya' && (
                <>
                  for S0-S5 and{' '}
                  <strong className={element}>22.52 seconds</strong> for S6
                </>
              )}
              .{' '}
            </>
          )}
          When selecting the rotations we prioritized achieving 100 Concerto as
          fast as possible and once that condition was fulfilled, we focused on
          maximizing the damage output. As or if new and more optimal rotations
          are found, we'll update the characters calculations as needed.
        </p>
        <hr />
        <div className="optimal-rotation">
          {/* Supports */}
          {slug === 'youhu' && (
            <>
              <p>
                Youhu has no specific set rotation in combat, but here are some
                tips how to play her:
              </p>
              <ul>
                <li>
                  Keep Resonance Skill: Divination on cooldown at all times and
                  follow it up with “Antique Appraisal“ for burst concerto
                  generation.
                </li>
                <li>
                  Utilize Intro Skill into “Antique appraisal“ to generate the
                  Concerto Energy needed to maintain Outro Buff more easily
                </li>
                <li>
                  Aim to perform Dodge counters followed by “Antique Appraisal“
                  whenever she has field time and it’s convenient to do so.
                </li>
                <li>
                  When building Concerto Energy for the first use of Outro skill
                  or when missing Concerto Energy to maintain it, use either a
                  full combo of Basic Attacks into a Heavy Attack: Frostfall and
                  “Antique Appraisal" or skip the Basic Attacks by holding Heavy
                  Attack in place of them.
                </li>
                <li>
                  Use Resonance Liberation when available, preferably as the 3rd
                  or 4th “Auspice” to gain a better shot at a beneficial buff.
                </li>
                <li>Use “Poetic Essence“ as soon as you have 4 “Auspice“.</li>
              </ul>
            </>
          )}
          {slug === 'baizhi' && (
            <>
              <p>
                Concerto weapon cuts down on the final amount of basics you have
                to do, hence it’s best to equip on Baizhi for overall Team DPS
                (you want to spend the least amount of time on her). If you
                don’t have your intro skill, add an extra basic attack.
              </p>
              <p>
                This rotation isn’t always going to repeat itself as you will
                have stacks built up from previous rotations. Use skill whenever
                you’ve built 4 stacks, and heavy attack if you don’t have skill.
                It should make this rotation faster than what’s on paper here.
              </p>
              <blockquote>
                <h6>Standard rotation</h6>
                <ul>
                  <li>Intro</li>
                  <li>Ultimate</li>
                  <li>B1</li>
                  <li>B2</li>
                  <li>B3</li>
                  <li>B4</li>
                  <li>Skill</li>
                  <li>B1</li>
                  <li>B2</li>
                  <li>B3</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
            </>
          )}
          {slug === 'verina' && (
            <>
              <p>
                Having Variation equipped on Verina saves you a bit of time by
                not forcing you to do the third Aerial at the end - which is why
                it’s her optimal weapon choice. Intros are bad on Verina as they
                render this rotation very awkward to execute and is inherently
                suboptimal. Outro to your other characters always when she’s on
                the team (feel free to instantly swap into Verina afterwards).
              </p>
              <blockquote>
                <h6>Basic rotation</h6>
                <ul>
                  <li>Swap-in (without an intro)</li>
                  <li>B3</li>
                  <li>B4</li>
                  <li>B5</li>
                  <li>Skill</li>
                  <li>Ultimate (cancel skill instantly if you can)</li>
                  <li>Jump</li>
                  <li>Aerial 1</li>
                  <li>Aerial 2</li>
                  <li>Aerial 3</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
            </>
          )}
          {slug === 'yangyang' && (
            <>
              <p>
                When playing Yangyang as a support focus on achieving the
                following in combat:
              </p>
              <ul>
                <li>
                  Keep your Resonance Skill on cooldown at all times, unless its
                  usage interrupts use of other higher priority cooldowns or DPS
                  burst windows.
                </li>
                <li>
                  Always utilize Yangyang’s Ultimate as frequently as possible
                  aside from saving it to Group-Up enemies at specific points of
                  combat.
                </li>
                <li>
                  Use her Echo as frequently as possible asides from saving it
                  for usage just before a burst combo when using Bell-Borne
                  Echo, or alongside her Outro to buff one of your core DPS when
                  using Impermanence Heron,
                </li>
                <li>
                  Utilize her Forte Gauge charges whenever filled to jump Aerial
                  Plunge (not Heavy Aerial Plunge) to generate massive amounts
                  of Concerto Energy. This can be Swap-Canceled to protect her
                  during its long animation while saving field time.
                </li>
                <li>
                  Incase of spare field time or need to use her Concerto Gauge
                  in a pinch, Yangyang can build additional Forte Gauge charges
                  via Heavy Attacks or a full Basic Attack sequence to
                  accelerate the process.
                </li>
              </ul>
            </>
          )}
          {slug === 'taoqi' && (
            <>
              <p>
                For warm-up when you don't have an intro available, simply do 2
                basics pre-rotation (swap cancel the second one and come back
                afterwards). A high-refinement Discord is strongly recommended
                to shorten this rotation due to its extensive length.
              </p>
              <blockquote>
                <h6>Basic rotation</h6>
                <ul>
                  <li>Intro</li>
                  <li>B1</li>
                  <li>B2</li>
                  <li>B3</li>
                  <li>B4</li>
                  <li>Ultimate (cancel B4 on hit)</li>
                  <li>B1</li>
                  <li>B2</li>
                  <li>B3</li>
                  <li>B4</li>
                  <li>Skill (cancel B4 on hit)</li>
                  <li>Echo</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
            </>
          )}
          {slug === 'the-shorekeeper' && (
            <>
              <p>
                When playing Shorekeeper there are 2 rotations to make note of:
                her opener which must be performed without access to her intro
                skill and her standard rotation which can make use of it.
              </p>
              <p>
                When starting a fight in endgame modes such as the Tower of
                Adversity none of your team members will have access to any
                concerto. In order for Shorekeeper's ultimate to gain its full
                effect it's imperative your team can quickly cycle through at
                least 2 Intro skills after its use. Because of these two factors
                it makes the most sense to build Shorekeeper's Concerto as fast
                as possible, then use her ultimate and quickly execute her Outro
                followed by another team members Intro in these game modes. Here
                is an efficient rotation in order to achieve this (depending on
                your team altering this intro to incorporate quick swapping may
                improve performance).
              </p>
              <blockquote>
                <h6>Opener Rotation:</h6>
                <ul>
                  <li>Basic P1</li>
                  <li>Basic P2</li>
                  <li>Basic P3</li>
                  <li>Basic P4</li>
                  <li>Forte: Heavy Atk: Illation</li>
                  <li>Basic P1</li>
                  <li>Basic P2</li>
                  <li>Basic P3</li>
                  <li>Basic P4</li>
                  <li>Forte: Heavy Atk: Illation</li>
                  <li>Skill: Chaos Theory</li>
                  <li>Echo</li>
                  <li>Liberation</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
              <p>
                After the opening rotation is complete and you have access to
                concerto across your team to ensure Shorekeeper can gain an
                intro execute the following rotation instead for greater
                efficiency;
              </p>
              <blockquote>
                <h6>Standard Rotation:</h6>
                <ul>
                  <li>Intro Skill: Discernment</li>
                  <li>Basic P1</li>
                  <li>Basic P2</li>
                  <li>Basic P3</li>
                  <li>Basic P4</li>
                  <li>Forte: Heavy Atk: Illation</li>
                  <li>Skill: Chaos Theory</li>
                  <li>Echo</li>
                  <li>Liberation</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
              <p>
                Both of these rotations require between 6-8 additional concerto
                energy in order to function. This is easily acquired by
                equipping either a Variation S1 or Shorekeeper's signature. If
                you have neither of these you'll need to supplement the missing
                Concerto energy by including additional Basic Attacks or a Dodge
                Counter.
              </p>
            </>
          )}
          {/* DPS */}
          {slug === 'roccia' && (
            <>
              <p>
                <strong>Standard Rotation</strong>
              </p>
              <p>
                Roccia's rotation is incredibly simple and boils down to
                building up 300 Fantasy quickly via her Intro, Basic ATK 4 and
                Skill, spending it shortly after and finishing things off with
                her Ultimate in order to generate 100 Concerto. When optimizing
                you can watch out for the following:
              </p>
              <ul>
                <li>
                  To save time, use your skill to cancel the extremely long
                  animation of Basic ATK P4 right after you generate the 100
                  Fantasy from it,
                </li>
                <li>
                  To save even more time you can activate your Ultimate right
                  after the damage of the third bounce of Real Fantasy lands to
                  cut out the end of the animation.
                </li>
              </ul>
              <blockquote>
                <ul>
                  <li>Intro</li>
                  <li>
                    Basic P4 (Cancel animation after 100 Fantasy is gained using
                    skill)
                  </li>
                  <li>Skill: Acrobatic Trick</li>
                  <li>Forte: Basic: Real Fantasy P1</li>
                  <li>Forte: Basic: Real Fantasy P2</li>
                  <li>
                    Forte: Basic: Real Fantasy P3 (Cancel animation after the
                    bounce lands with Ultimate)
                  </li>
                  <li>Ultimate: Commedia Improvviso!</li>
                  <li>Echo (Swap Cancel)</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
              <p>
                <strong>S1 SHORTENED ROTATION</strong>
              </p>
              <p>
                If you pulled S1 you can shorten Roccia's rotation by removing
                Basic ATK P4 completely. This results in lower DMG and DPS for
                Roccia personally but will shorten her rotation, leaving for
                time for the rest of your team to more than make up for that
                damage loss.
              </p>
              <blockquote>
                <ul>
                  <li>Intro</li>
                  <li>Skill: Acrobatic Trick</li>
                  <li>Forte: Basic: Real Fantasy P1</li>
                  <li>Forte: Basic: Real Fantasy P2</li>
                  <li>
                    Forte: Basic: Real Fantasy P3 (Cancel animation after the
                    bounce lands with Ultimate)
                  </li>
                  <li>Ultimate: Commedia Improvviso!</li>
                  <li>Echo (Swap Cancel)</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
            </>
          )}
          {slug === 'carlotta' && (
            <>
              <p>
                <strong>WARM UP</strong>
              </p>
              <p>
                If you have 0 Substance, perform the following combo in order to
                generate the 30 you'll need to execute Carlotta's main Burst
                Combo:
              </p>
              <blockquote>
                <ul>
                  <li>
                    Skill: Art of Violence (Generate 3 “Moldable Crystals”)
                  </li>
                  <li>
                    Skill: Chromatic Splendor - (Consume 3 “Moldable Crystals”
                    for 30 “Substance”)
                  </li>
                </ul>
              </blockquote>
              <p>
                <strong>BURST COMBO</strong>
              </p>
              <ul>
                <li>
                  You will require 30 Substance before starting Carlotta's burst
                  combo - if you don't have it perform the Warm Up first,
                </li>
                <li>
                  Ensure Carlotta has access to her Intro, Resonance Skill,
                  Resonance Liberation and Tinted Crystal before starting,
                </li>
                <li>
                  Buff Carlotta as much as possible with the rest of your team
                  before starting the burst rotation,
                </li>
                <li>
                  Echo: Use Carlotta's Echo at the end of her rotation and
                  perform a swap cancel. When using Sentry Construct monitor the
                  Echoes cooldown and consider using it outside the burst
                  rotation as it becomes available - it will recharge faster
                  than usual thanks to its additional ability.
                </li>
              </ul>
              <blockquote>
                <h6>Burst Rotation</h6>
                <ul>
                  <li>Start with 30 Substance</li>
                  <li>
                    Intro: Wintertime Aria (Generate 3 “Moldable Crystals”,
                    Generate 30 “Substance”)
                  </li>
                  <li>Skill: Art of Violence (Generate “Moldable Crystals”)</li>
                  <li>
                    Skill: Chromatic Splendor - (Consume 6 “Moldable Crystals”
                    for 60 “Substance”)(Enter “Final Bow”)
                  </li>
                  <li>
                    Mid-Air Atk (To return to the ground to continue combo)
                  </li>
                  <li>
                    Forte: Heavy Attack: Imminent Oblivion (Consume 120
                    “Substance” to reduce Cooldown of Skill)
                  </li>
                  <li>Ultimate: Era of New Wave</li>
                  <li>Ultimate: Death Knell</li>
                  <li>Ultimate: Death Knell</li>
                  <li>Ultimate: Death Knell</li>
                  <li>Ultimate: Death Knell</li>
                  <li>Ultimate: Fatal Finale</li>
                  <li>
                    Skill: Art of Violence (Generate 3 “Moldable Crystals”)
                  </li>
                  <li>
                    Skill: Chromatic Splendor - (Consume 3 “Moldable
                    Crystals”for 30 “Substance”)
                  </li>
                  <li>Echo (Swap Cancel)</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
            </>
          )}
          {slug === 'lumi' && (
            <>
              <p>
                Lumi's rotation is simple, clean cut but fairly long for a
                hybrid character - to remedy this you can attempt to integrate
                swap cancel and swap backs in to shorten it. Be warned you'll
                need to be quick with your swap backs to not compromise your
                team or Lumi's rotation.
              </p>
              <blockquote>
                <p>
                  <strong>Rotation</strong>
                </p>
                <ul>
                  <li>Intro</li>
                  <li>Ultimate</li>
                  <li>
                    Skill: Energized Pounce (Optionally Swap Cancel and
                    immediately back)
                  </li>
                  <li>Red Spotlight: Basic Attack 1</li>
                  <li>Red Spotlight: Basic Attack 2</li>
                  <li>
                    Red Spotlight: Basic Attack 3 (Optionally Swap Cancel and
                    immediately back)
                  </li>
                  <li>Skill: Energized Rebound</li>
                  <li>Yellow Light: Basic Attack</li>
                  <li>Channelled Dash: Glare x 6</li>
                  <li>Skill: Energized Pounce</li>
                  <li>Echo (Swap Cancel)</li>
                  <li>Outro</li>
                </ul>
                <br />
                <p>
                  Echo: Use Lumi's Echo at the end of her rotation and perform a
                  swap cancel to ensure you're gaining the best uptime for your
                  incoming carry.
                </p>
              </blockquote>
            </>
          )}
          {slug === 'camellya' && (
            <>
              <p>
                Crimson Pistils and Concerto is essential to Camellya's rotation
                and without them she cannot function. Camellya's intro is one of
                her only accessible sources of Crimson Pistils and grants a huge
                amount of Concerto - ensure it is always available before
                swapping to her.
              </p>
              <p>
                When opening combat in the Tower of Adversary no character will
                have access to their Intro/Outro. As mention above Camellya very
                much relies on her intro for a seamless rotation. To get around
                this and to ensure a maximum damage first combo generate your
                supportive character's Outros first. This grants Camellya full
                buffs and also ensure's she'll have access to her Intro for a
                smooth first rotation.
              </p>
              <p>
                <strong>Basic Combo</strong>
              </p>
              <p>
                Below is Camellya's basic combo focused on using all available
                resources in order to generate a perfect amount of Concerto to
                fuel all her abilities in the shortest amount of time:
              </p>
              <ul>
                <li>
                  Execute and land Camellya's Intro for a large influx of
                  Concerto and Crimson Pistil Generation.
                </li>
                <li>
                  Enter Blossom Mode via Skill: Crimson Blossom. As soon as you
                  see both hits register cut the animation short by executing
                  Camellya's Ultimate to save time.
                </li>
                <li>
                  Execute a full string of empowered Basic Attacks, ensuring a
                  full channel on Skill:Blazing Waltz when available.
                </li>
                <li>
                  After the first full set of empowered Basic Attacks use
                  Camellya's Forte Enhanced skill Forte: Skill: Ephemeral to
                  enter "Budding Mode".
                </li>
                <li>
                  Perform another full sequence of empowered Basic Attacks.
                </li>
                <li>
                  Finish the burst combo with Skill: Floral Ravage for a final
                  burst of damage followed by her outro and if you're using it
                  the dreamless echo (Swap Cancelled).
                </li>
              </ul>
              <p>
                If you are lacking either Camellya's Ultimate or Intro skill,
                this combo will need to be altered. This is also the case if you
                miss any abiliites or a portion of the Skill: Blazing Waltz
                channel as you'll be lacking Concerto to execute the next step.
              </p>
              <p>
                If in the interest of Concerto you make use of Camellya's
                Mid-Air Attack (which is very efficient at consuming Crimson
                Pistils) you will find yourself ground-bound. To get back in the
                air you'll need to execute a Resonance Skill once again (you may
                find yourself needing to wait for its cooldown). Dodge counters
                are a good way to make up for lost concerto.
              </p>
              <blockquote>
                <h6>Burst Rotation</h6>
                <ul>
                  <li>Intro</li>
                  <li>Skill: Crimson Blossom</li>
                  <li>Ultimate</li>
                  <li>Skill: Basic Attack: Vining Waltz 1</li>
                  <li>Skill: Basic Attack: Vining Waltz 2</li>
                  <li>Skill: Basic Attack: Vining Waltz 3</li>
                  <li>Skill: Blazing Waltz (Full Channel)</li>
                  <li>Skill: Basic Attack: Vining Waltz 4</li>
                  <li>Forte: Skill: Ephemeral</li>
                  <li>Skill: Basic Attack: Vining Waltz 1</li>
                  <li>Skill: Basic Attack: Vining Waltz 2</li>
                  <li>Skill: Basic Attack: Vining Waltz 3</li>
                  <li>Skill: Blazing Waltz (Full Channel)</li>
                  <li>Skill: Basic Attack: Vining Waltz 4</li>
                  <li>Skill: Floral Ravage</li>
                  <li>Outro</li>
                </ul>
                <p>
                  Use Camellya's Echo either at the end of her rotation and
                  perform a swap cancel (Dreamless) or if using a buffing echo
                  use it so that its buff duration applies to her "Budding
                  State" damage window firstly but also her Ultimate if
                  possible.
                </p>
              </blockquote>
              <p>
                <strong>OPTIMIZED DOUBLE INTRO COMBO</strong>
              </p>
              <p>
                For those looking to optimize further, you can attempt and
                integrate the "Double Intro" combo which focuses on banking up
                resources then spending them in a larger burst window to
                concentrate Camellya's damage in a shorter window to better take
                advantage of team buffs. This combo requires a bit more planning
                and set up but will lead to higher damage per rotation and DPS -
                assuming you line up your team buffs and damage windows right.
                The main advantage of this combo is executing two Forte-enhanced
                attack strings back to back instead of the only one listed
                above.
              </p>
              <p>Execute the following two stage combo:</p>
              <blockquote>
                <h6>Double Intro Burst Rotation</h6>
                <ul>
                  <li>Intro</li>
                  <li>Skill: Crimson Blossom</li>
                  <li>Skill: Basic Attack: Vining Waltz 1</li>
                  <li>Skill: Basic Attack: Vining Waltz 2</li>
                  <li>Skill: Basic Attack: Vining Waltz 3</li>
                  <li>Skill: Blazing Waltz (Full Channel)</li>
                  <li>Skill: Basic Attack: Vining Waltz 4</li>
                </ul>
                <br />
                <p>Build team Outro, Concerto & apply buffs here.</p>
                <ul>
                  <li>Intro</li>
                  <li>Ultimate</li>
                  <li>Forte: Skill: Ephemeral</li>
                  <li>Skill: Basic Attack: Vining Waltz 1</li>
                  <li>Skill: Basic Attack: Vining Waltz 2</li>
                  <li>Skill: Basic Attack: Vining Waltz 3</li>
                  <li>Skill: Blazing Waltz (Full Channel)</li>
                  <li>Skill: Basic Attack: Vining Waltz 4</li>
                  <li>Forte: Skill: Ephemeral</li>
                  <li>Skill: Basic Attack: Vining Waltz 1</li>
                  <li>Skill: Basic Attack: Vining Waltz 2</li>
                  <li>Skill: Basic Attack: Vining Waltz 3</li>
                  <li>Skill: Blazing Waltz (Full Channel)</li>
                  <li>Skill: Basic Attack: Vining Waltz 4</li>
                  <li>Skill: Floral Ravage</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
              <h5>Additional information</h5>
              <p>
                Here is the damage difference between the two different
                rotations.
              </p>
              <Row xs={1} xl={1} className="dps-comparison">
                <Col>
                  <div className="box">
                    <h5>Basic vs Double Intro</h5>
                    <div className={`simulation-container ${slug}`}>
                      <div className="simulation-row">
                        <div className="character">
                          <WWCharacter
                            slug={dpsRanking.slug}
                            mode="icon"
                            enablePopover
                          />
                        </div>
                        <div className="chart">
                          <div className="info-2">
                            <p>{dpsRanking.name} (Double Intro)</p>
                          </div>
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar top-1`}
                              style={{ width: '100%' }}
                            ></div>
                            <div className="data">
                              <span className="dps">599,024 DMG (22.27s)</span>
                              <span className="percent">125.64%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="simulation-row">
                        <div className="character">
                          <WWCharacter
                            slug={dpsRanking.slug}
                            mode="icon"
                            enablePopover
                          />
                        </div>
                        <div className="chart">
                          <div className="info-2">
                            <p>{dpsRanking.name} (Basic)</p>
                          </div>
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar top-3`}
                              style={{ width: '79.59%' }}
                            ></div>
                            <div className="data">
                              <span className="dps">476,781 DMG (15.50s)</span>
                              <span className="percent">100%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
          {slug === 'xiangli-yao' && (
            <>
              <p>
                <strong>Warm Up</strong>
              </p>
              <p>
                Xiangli Yao requires usage of at least 1 Standard Resonance
                Skill before his burst combo in order to have enough Concerto
                Energy to cast his Outro. Fortunately, his Resonance Skill is
                incredibly fast to use, can be Swap-Canceled and generates good
                Resonance Energy. The Skill also features an incredibly short
                cooldown of 4 seconds, allowing you to weave it in between other
                characters actions to accumulate Ultimate's even faster. If
                Xiangli Yao's Ultimate is still on cooldown and you have field
                time or a Swap available doing so is recommended.
              </p>
              <blockquote>
                <ul>
                  <li>
                    Skill: Deduction (Swap Cancel)(Use atleast 1 per ultimate
                    combo)
                  </li>
                </ul>
              </blockquote>
              <p>
                <strong>BURST COMBO</strong>
              </p>
              <p>
                Majority of Xiangli Yao’s field time and team's attention should
                be centered around his Ultimate burst window, as it inflicts
                majority of his as well as team's damage. Ensure to line up all
                team’s kit, Echo Buffs/Amplifications, Xiangli Yao’s Intro and
                cast at least 1 Deduction before initiating his burst combo
              </p>
              <p>
                Below given is his most optimal combo, without the need of Swap
                mid-Ultimate. While it may look, confusing or complex at first,
                here are the basics:
              </p>
              <ul>
                <li>
                  Using Xiangli’s Yao Ultimate puts him in Intuition state for
                  24 seconds, during this all of his abilities are enhanced and
                  ends upon execution of Forte Skill: Law of Reigns 3 times.
                </li>
                <li>
                  After Ultimate activation, his most potent ability is Forte
                  Skill: Law of Reigns, which requires 5 Performance Capacity to
                  use. Thus making your new to build Performance Capacity as
                  soon as possible, execute 3 Law of Reigns and work on the next
                  Ultimate.
                </li>
                <li>
                  Fastest way to build 5 Performance Capacity is to use Ultimate
                  Skill: Divergence, followed by Forte Mid Air Attack: Revamp,
                  but due to it having a cooldown of 7 seconds, it can’t be
                  repeated 3 times for all Law of Reigns Triggers.
                </li>
                <li>
                  The next best way of generating Performance Capacity is
                  casting 3 Ultimate Basic: Pivot Impales.
                </li>
                <li>
                  Combining all this is Xiangli Yao’s combo in a nutshell. You
                  initiate with Divergence + Revamp, follow it up with Law of
                  Reigns, then use 3 Pivot Imaples, followed up with another Law
                  of Reigns before repeating Divergence + Revamp and executing
                  the final Law of Reigns.
                </li>
                <li>
                  This combo might need alternations in certain combat scenarios
                  should you feel the need to incorporate Dodge Counters.
                </li>
              </ul>
              <blockquote>
                <h6>Combo</h6>
                <ul>
                  <li>
                    Echo (Use before combo but refer to Echo recommendations for
                    exact specifics for your chosen Echo)
                  </li>
                  <li>Intro</li>
                  <li>Ultimate: Cogiation Model</li>
                  <li>Ultimate: Skill: Divergence</li>
                  <li>Forte: Mid-Air Attack: Revamp</li>
                  <li>Forte: Skill: Law of Reigns (Optionally Swap-Cancel)</li>
                  <li>Ultimate: Basic: Pivot - Impale P1</li>
                  <li>Ultimate: Basic: Pivot - Impale P2</li>
                  <li>Ultimate: Basic: Pivot - Impale P3</li>
                  <li>Forte: Skill: Law of Reigns (Optionally Swap-Cancel)</li>
                  <li>Ultimate: Skill: Divergence</li>
                  <li>Forte: Mid-Air Attack: Revamp </li>
                  <li>Forte: Skill: Law of Reigns (Swap-Cancel)</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
              <p>
                While many of Xiangli Yao's abilities within his burst combo are
                Swap-Cancellable and benefit heavily from doing the same, it
                comes at a cost, as a lot of the most powerful team buffs (Outro
                Amplifications) only apply to the entering character while they
                remain on field. This means you must make a choice between
                Swap-Canceling and Amplification Outro buffs, the best choice
                being dependent on your choice of team mate. Yinlin for example
                offers Xiangli Yao 20% Electro Amplification and 25% Liberation
                Amplification for not Swapping out, something very worth for not
                considering Swap-Cancel for. Changli on the other hand offers
                far less Outro Amplification options and benefits far more from
                Swap-Cancels to power up her damage dealing rotation. Xiangli
                Yao is capable and does well using both playstyles, thus we
                recommend trying out both playstyles with the characters on your
                account to find what hits the spot for you.
              </p>
            </>
          )}
          {slug === 'zhezhi' && (
            <>
              <p>
                <strong>Zhezhi Standard Combo</strong>
              </p>
              <p>
                Zhezhi's combo is incredibly simple and quite easy to execute,
                but does rely on ensuring you have you ultimate cooldown
                available, sufficient energy generated from other teammates and
                an Intro skill ready for Zhezhi to use. If you've checked all
                these boxes execute the follow:
              </p>
              <ul>
                <li>
                  Execute Intro skill followed by Basic 1, 2 and 3 to generate
                  90 Afflatus (a full Forte Gauge with 3 segments) - as long as
                  you don't miss any hits these attacks will grant you all 90.
                </li>
                <li>
                  Tap or Hold Zhezhi's Resonance Skill to convert 60 Afflatus
                  into 2 Imprints. If you wish to execute a slightly faster
                  ground-based combo only tap the Resonance Skill - if you want
                  to execute an airborne combo instead, tap and hold the
                  Resonance until after Zhezhi launches herself airborne.
                </li>
                <li>
                  Quickly follow the Resonance Skill with a single Basic Attack
                  tap to convert the last 30 Afflatus into a 3rd Imprint.
                </li>
                <li>
                  Execute 3 back to back Resonance Skills, consuming all 3
                  Imprints, leaving a short pause for the final attack to
                  complete the majority of its animation.
                </li>
                <li>
                  Activate Zhezhi's Ultimate, followed immediately by swap
                  cancelling her Echo (some adjustment may be required if not
                  using Impermenance Heron).
                </li>
              </ul>
              <p>
                For those looking to further optimize (and a bit of a
                challenge), Zhezhi has a number of cancels she can perform to
                speed up her rotation at no cost to her damage or resource
                generation. Performing a well timed Jump during Stroke of Genius
                will allow Zhezhi to move earlier while the attack continues.
                Also using her Ultimate during Creations Zenith can save time
                when done correctly. These cancel do not come without risk
                though - attempting and messing them up can lose you time
                instead!
              </p>
              <blockquote>
                <h6>Combo</h6>
                <ul>
                  <li>Intro Skill</li>
                  <li>Basic P1</li>
                  <li>Basic P2</li>
                  <li>Basic P3</li>
                  <li>
                    Resonance Skill: Manifestation (Tap to Execute Ground combo,
                    Hold To Execute Air Combo)
                  </li>
                  <li>Forte: Heavy Atk: Conjuration</li>
                  <li>
                    Forte: Resonance Skill: Stroke of Genius (Optionally Jump
                    Cancel)
                  </li>
                  <li>
                    Forte: Resonance Skill: Stroke of Genius (Optionally Jump
                    Cancel)
                  </li>
                  <li>Forte: Resonance Skill: Creations Zenith</li>
                  <li>
                    Ultimate: Living Canvas (Optionally Cancel Forte Skill)
                  </li>
                  <li>
                    Echo: Impermenance Heron (Swap Cancel immediately after
                    activation)
                  </li>
                </ul>
              </blockquote>
              <h5>Additional information</h5>
              <p>
                Here is the damage difference between the two different
                rotations that can be used on Zhezhi - the basic one and the
                Jump Cancel one (the damage is the same, only the rotation time
                shortens which results in higher DPS):
              </p>
              <Row xs={1} xl={1} className="dps-comparison">
                <Col>
                  <div className="box">
                    <h5>Basic vs Jump Cancel</h5>
                    <div className={`simulation-container ${slug}`}>
                      <div className="simulation-row">
                        <div className="character">
                          <WWCharacter
                            slug={dpsRanking.slug}
                            mode="icon"
                            enablePopover
                          />
                        </div>
                        <div className="chart">
                          <div className="info-2">
                            <p>{dpsRanking.name} (Jump Cancel)</p>
                          </div>
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar top-1`}
                              style={{ width: '100%' }}
                            ></div>
                            <div className="data">
                              <span className="dps">
                                169,606 DMG (28,894 DPS - 5.87s)
                              </span>
                              <span className="percent">119.59%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="simulation-row">
                        <div className="character">
                          <WWCharacter
                            slug={dpsRanking.slug}
                            mode="icon"
                            enablePopover
                          />
                        </div>
                        <div className="chart">
                          <div className="info-2">
                            <p>{dpsRanking.name} (Basic)</p>
                          </div>
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar top-3`}
                              style={{ width: '81.41%' }}
                            ></div>
                            <div className="data">
                              <span className="dps">
                                169,606 DMG (24,160 DPS - 7.02s)
                              </span>
                              <span className="percent">100%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
          {slug === 'changli' && (
            <>
              <p>
                <strong>CHANGLI ABILITY PRIORITY</strong>
              </p>
              <p>
                Changli is most effective when played as a Quick-Swap character,
                executing cooldown-based combos as they become available.
                Depending on the teammates you pair her with, how much field
                time they utilize and the fight itself, cooldowns may become
                available at different timings. When playing Changli aim to
                follow the Ability Priority listed down below. When optional
                Swap-Cancels are mentioned we highly recommend you attempt them,
                as executing them successfully will make Changli gameplay much
                safe as well as raise her damage tremendously provided you make
                use of the character you're Swapping to via some cooldown or
                rotational progress of their own.
              </p>
              <ul>
                <li>
                  <strong>
                    If Ultimate is available and you have 0 Enflamement charges
                    (Forte Gauge) execute the following:
                  </strong>
                </li>
                <ul>
                  <li>Ultimate</li>
                  <li>
                    Forte: Heavy Attack: Flaming Sacrifice (Optionally
                    Swap-Cancel)
                  </li>
                </ul>
                <li>
                  <strong>
                    If Enflamement is full (Forte Gauge) immediately execute the
                    following:
                  </strong>
                </li>
                <ul>
                  <li>
                    Forte: Heavy Attack: Flaming Sacrifice (Optionally
                    Swap-Cancel)
                  </li>
                </ul>
              </ul>
              <p>
                Past this point Changli has 2 different ways she can execute her
                main combos: a "Basic" approach or the "Double intro" approach.
                The Basic approach allows Changli to generate Enflamement stacks
                more freely, but deals lower DPS and leaves her with
                vulnerablities at specific points in combat (we'll mention these
                vulnerabilities and when they occur). The "Double Intro"
                approach is higher DPS but restricts the amount of Enflamement
                stacks Changli can generate, switching focus onto her teammates
                who have to generate the Intros to use and will need to fill any
                field time Changli isn't using, this combo has very few
                vulnerabilities though. Both approaches make use of the above.
              </p>
              <p>
                <strong>Basic Approach</strong>
              </p>
              <ul>
                <li>
                  <strong>
                    Upon activating Changli's Intro execute the following
                  </strong>{' '}
                  (this combo can be challenging to land on moving targets and
                  leaves Changli exposed to Interruption and hits during the
                  Mid-Air and Basic Attack portion of the combo):
                </li>
                <ul>
                  <li>Intro</li>
                  <li>Basic Attack: True Sight: Charge</li>
                  <li>Mid-Air Heavy Attack</li>
                  <li>Basic P3</li>
                  <li>Basic P4</li>
                  <li>
                    Basic Attack: True Sight: Conquest (Optionally Swap-Cancel)
                  </li>
                </ul>
                <li>
                  <strong>
                    When not executing any of the above, you should execute the
                    following combo instead. Keeping 1 charge (keep both on CD
                    to be safe) of Changli's skill on cooldown at all times
                    should be a priority for your team:
                  </strong>
                </li>
                <ul>
                  <li>Skill: True Sight: Capture </li>
                  <li>
                    Basic Attack: True Sight: Conquest (Optionally Swap Cancel)
                  </li>
                </ul>
              </ul>
              <p>
                <strong>Double Intro Approach</strong>
              </p>
              <ul>
                <li>
                  <strong>
                    Upon activating Changli's Intro execute the following:
                  </strong>
                </li>
                <ul>
                  <li>Intro</li>
                  <li>Basic Attack: True Sight: Charge</li>
                  <li>Skill: True Sight: Capture </li>
                  <li>
                    Basic Attack: True Sight: Conquest (Optionally Swap Cancel)
                  </li>
                </ul>
                <li>
                  <strong>
                    If no Intro Skill is available soon and Changli has 2
                    charges of her skill, you should not waste the cooldown
                    timer, instead execute the following combo. However, outside
                    of this rare situation holding Changli's skill charges for
                    Intro's is essential for the Double Intro approach.
                  </strong>
                </li>
                <ul>
                  <li>Skill: True Sight: Capture </li>
                  <li>
                    Basic Attack: True Sight: Conquest (Optionally Swap Cancel)
                  </li>
                </ul>
              </ul>
              <p>
                The Basic Approach relies on Changli's Intro and Basic Attack
                sequence to generate Enflamement on top of her skill charges but
                leaves her open to Interruption and increases her field time
                consumption with lower damage combos. The Double Intro combo, on
                the other hand requires lower field time, is safer but consumes
                a skill charge every intro lowering total possible Enflamements
                during combat. Each approach has its Pros and Cons and if you'd
                prefer, a mix of both can be used in combat depending on the
                team you're using and how many Intro's you can generate for
                Changli in any given fight.
              </p>
              <ul>
                <li>
                  <strong>
                    In the event you land a Dodge Counter Changli can skip
                    straight to her Basic P3 allowing for an alternate True
                    Sight combo. This combo not only deals low damage but also
                    exposes Changli to danger, as such this should only be
                    utilized if you have no cooldowns available on Changli or
                    any other character on the team (an extremely rare
                    occurrence):
                  </strong>
                </li>
                <ul>
                  <li>Dodge Counter</li>
                  <li>Basic P3</li>
                  <li>Basic P4</li>
                  <li>
                    Basic Attack: True Sight: Conquest (Optionally Swap=Cancel)
                  </li>
                </ul>
              </ul>
              <blockquote>
                <h6>BASIC APPROACH COMBO</h6>
                <p>
                  Below is a sample of how Changli's kit can play out in battle,
                  keep in mind as a Quick-Swap cooldown based character you
                  should follow the Ability Priority above first and foremost,
                  but can refer to following to get an idea of what a full 100
                  Concerto Rotation would look like for her.
                </p>
                <ul>
                  <li>Inferno Rider Echo (Swap After 3rd Slash Begins)</li>
                  <li>Intro</li>
                  <li>Basic Attack: True Sight: Charge</li>
                  <li>Mid-Air Heavy Attack</li>
                  <li>Basic P3</li>
                  <li>Basic P4</li>
                  <li>
                    Basic Attack: True Sight: Conquest (Optionally Swap-Cancel)
                  </li>
                  <li>Skill: True Sight: Capture </li>
                  <li>
                    Basic Attack: True Sight: Conquest (Optionally Swap-Cancel)
                  </li>
                  <li>Skill: True Sight: Capture </li>
                  <li>
                    Basic Attack: True Sight: Conquest (Optionally Swap-Cancel)
                  </li>
                  <li>Forte: Heavy Attack: Flaming Sacrifice</li>
                  <li>Ultimate</li>
                  <li>Forte: Heavy Attack: Flaming Sacrifice (Swap-Cancel)</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
              <blockquote>
                <h6>DOUBLE INTRO COMBO</h6>
                <p>
                  Alternatively here is what a Double Intro combo would look
                  like in battle.
                </p>
                <ul>
                  <li>Inferno Rider Echo (Swap After 3rd Slash Begins)</li>
                  <li>Intro</li>
                  <li>Basic Attack: True Sight: Charge</li>
                  <li>Skill: True Sight: Capture </li>
                  <li>
                    Basic Attack: True Sight: Conquest (Optionally Swap-Cancel)
                  </li>
                  <li>Intro</li>
                  <li>Basic Attack: True Sight: Charge</li>
                  <li>Skill: True Sight: Capture </li>
                  <li>
                    Basic Attack: True Sight: Conquest (Optionally Swap-Cancel)
                  </li>
                  <li>Forte: Heavy Attack: Flaming Sacrifice</li>
                  <li>Ultimate</li>
                  <li>Forte: Heavy Attack: Flaming Sacrifice (Swap-Cancel)</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
            </>
          )}
          {slug === 'jinhsi' && (
            <>
              <p>
                <strong>COMBO REQUIREMENTS</strong>
              </p>
              <p>
                Unlike most other characters Jinhsi is not reliant on Ultimate
                or Forte Gauge burst windows - she is instead cooldown-based and
                as such only needs to work around those, which can be easily fit
                into a followable rotation. One thing Jinhsi does require in
                order to execute her rotation, is to perform her Intro each and
                every time before she starts dealing her damage. Because of this
                requirement it's imperative you build up Concerto on another
                character before beginning her rotation (or else it wont work).
              </p>
              <h6>STANDARD ROTATION</h6>
              <p>
                Jinhsi's kit and rotation look hard but are actually incredibly
                easy to play and understand. Here are the key things you need to
                know when playing Jinhsi as well as some extra info, tips and
                tricks.
              </p>
              <ul>
                <li>
                  A large portion of Jinhsi's kit is not required when playing
                  her - specifically her standard Resonance Skill and standard
                  Basic Attacks. You'll almost never be using these so you can
                  ignore them.
                </li>
                <li>
                  The reason you can ignore the above is because when executing
                  her full rotation you can skip half of it by executing her
                  Intro. As using her Intro allows you to move straight to
                  Jinhsi's harder hitting abilities raising her damage
                  considerably. The result of this though is that you always
                  want to start off any Jinhsi rotation with her Intro and never
                  Swap to her until you have it.
                </li>
                <li>
                  When playing Jinhsi, generate enough Concerto on another
                  character first then Swap to Jinhsi performing her Intro.
                  After that use your Echo (if it's Jue) and Ultimate if it's
                  available immediately, then use Jinhsi's Resonance Skill twice
                  (it will be glowing to indicate it's ready and empowered).
                  After that use 4 Basic Attacks - this will enable the final
                  part of Jinhsi's rotation: another empowered Resonance Skill
                  which can you'll see glowing as soon as it's available. After
                  this switch back out to your next desired character and you're
                  done.
                </li>
                <li>
                  When executing Jinhsi's 4 Basic Attacks as a part of her
                  rotation her final empowered Resonance Skill will become
                  available before the 4th Basic Attack deals any damage. If you
                  activate it as soon as it's available you'll lose the damage
                  from the 4th Basic Attack - it can be worth it to hold off on
                  immediate activation and ensure the 4th Basic Attack fully
                  completes (the animation of the 4th Basic is of a big Dragon
                  diving at enemies).
                </li>
                <li>
                  Due to Jinhsi's short rotations, higher energy cost and
                  emphasis on low teammate field time she can't access her
                  Ultimate every single rotation even with high energy
                  regeneration. Due to this Jinhsi's full rotation listed below
                  consists of a big damage rotation including her Ultimate and
                  echo and a smaller damage rotation not containing them. The
                  rotations howerver are functionally the same outside of the
                  Ultimate and Echo activation!
                </li>
                <li>
                  When playing Jinhsi in teams don't spend to much time on your
                  other characters - Jinhsi's output is only limited by her
                  cooldowns which aren't that long. A good rule of thumb is that
                  whenever either of your characters has their Outro ready, it's
                  time to Swap back to Jinhsi and perform one of her rotations.
                </li>
                <li>
                  When playing Jinhsi's teams, ensure you try to keep the team
                  buffs up but more importantly ensure you utilize their
                  cooldowns and if applicable keep their coordinated attacks
                  active as much as possible.
                </li>
              </ul>
              <blockquote>
                <h6>Rotation</h6>
                <ul>
                  <li>Intro</li>
                  <li>
                    Echo (Use immediately if Jue or before combo and Swap-Cancel
                    if Aix)
                  </li>
                  <li>Ultimate</li>
                  <li>Skill: Overflowing Radiance</li>
                  <li>Incarnation: Skill: Crescent Divinity</li>
                  <li>Incarnation: Basic P1</li>
                  <li>Incarnation: Basic P2</li>
                  <li>Incarnation: Basic P3</li>
                  <li>Incarnation: Basic P4</li>
                  <li>Skill: Illuminous Ephiphany</li>
                  <li>
                    Outro (Swap to another character to build Concerto and wait
                    for cooldowns)
                  </li>
                  <li>Intro</li>
                  <li>Skill: Overflowing Radiance</li>
                  <li>Incarnation: Skill: Crescent Divinity</li>
                  <li>Incarnation: Basic P1</li>
                  <li>Incarnation: Basic P2</li>
                  <li>Incarnation: Basic P3</li>
                  <li>Incarnation: Basic P4</li>
                  <li>Skill: Illuminous Ephiphany Star DMG</li>
                  <li>Skill: Illuminous Ephiphany Sun DMG</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
              <h6>OPTIMIZED ROTATION</h6>
              <p>
                For those looking to optimize their Jinhsi gameplay, a number of
                ability cancels can be implemented into her rotation to shorten
                the time it takes to perform significantly at the cost of
                lowering the damage per rotation a small amount.
              </p>
              <ul>
                <li>
                  The first optimization that can be made is the skipping of
                  Skill: Overflowing Radiance. This skill takes a significant
                  amount of time to execute and by skipping it either with
                  Jinhsi's Ultimate or a Dash Cancel you can raise your DPS,
                </li>
                <li>
                  Both Incarnation: Basic P3 and Incarnation: Basic P4 can also
                  be skipped saving time in a similar fashion this can be done
                  easily by adjusting when you use Incarnation: Skill: Crescent
                  Divinity and Skill: Illuminous Ephiphany and when you time
                  them.
                </li>
              </ul>
              <p>Here is a rotation factoring in all of these skips:</p>
              <blockquote>
                <h6>Rotation</h6>
                <ul>
                  <li>Intro</li>
                  <li>
                    Echo (Use immediately if Jue or before combo and swap cancel
                    if Aix)
                  </li>
                  <li>Skill: Overflowing Radiance </li>
                  <li>Ultimate (Cancel Skill With This Attack)</li>
                  <li>Incarnation: Basic P1</li>
                  <li>Incarnation: Basic P2</li>
                  <li>Incarnation: Basic P3 </li>
                  <li>
                    Incarnation: Skill: Crescent Divinity (Cancel Basic P3 With
                    This Attack)
                  </li>
                  <li>Incarnation: Basic P4</li>
                  <li>
                    Skill: Illuminous Ephiphany (Cancel Basic P4 With This
                    Attack)
                  </li>
                  <li>
                    Outro (Swap to another character to build concerto and wait
                    for cooldowns)
                  </li>
                  <li>Intro</li>
                  <li>Skill: Overflowing Radiance (Dash Cancel asap)</li>
                  <li>Incarnation: Basic P1</li>
                  <li>Incarnation: Basic P2</li>
                  <li>Incarnation: Basic P3</li>
                  <li>
                    Incarnation: Skill: Crescent Divinity (Cancel Basic P3 With
                    This Attack)
                  </li>
                  <li>Incarnation: Basic P4</li>
                  <li>
                    Skill: Illuminous Ephiphany (Cancel Basic P4 With This
                    Attack)
                  </li>
                  <li>Outro</li>
                </ul>
              </blockquote>
              <h5>Additional information</h5>
              <p>
                Here is the damage difference between the two different
                rotations.
              </p>
              <Row xs={1} xl={1} className="dps-comparison">
                <Col>
                  <div className="box">
                    <h5>Baseline vs Basic Cancels</h5>
                    <div className={`simulation-container ${slug}`}>
                      <div className="simulation-row">
                        <div className="character">
                          <WWCharacter
                            slug={dpsRanking.slug}
                            mode="icon"
                            enablePopover
                          />
                        </div>
                        <div className="chart">
                          <div className="info-2">
                            <p>{dpsRanking.name} (Basic Cancels)</p>
                          </div>
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar top-1`}
                              style={{ width: '100%' }}
                            ></div>
                            <div className="data">
                              <span className="dps">
                                507,633 DMG (51,620 DPS - 9.83s)
                              </span>
                              <span className="percent">131.74%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="simulation-row">
                        <div className="character">
                          <WWCharacter
                            slug={dpsRanking.slug}
                            mode="icon"
                            enablePopover
                          />
                        </div>
                        <div className="chart">
                          <div className="info-2">
                            <p>{dpsRanking.name} (Baseline)</p>
                          </div>
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar top-2`}
                              style={{ width: '75.91%' }}
                            ></div>
                            <div className="data">
                              <span className="dps">
                                534,940 DMG (39,184 DPS - 13.65s)
                              </span>
                              <span className="percent">100%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
          {slug === 'rover-havoc' && (
            <>
              <h6>WARM UP</h6>
              <p>
                When awaiting cooldowns, building energy for Havoc Rover and
                cycling through your team for concerto, aim to execute the
                following:
              </p>
              <ul>
                <li>
                  Your top priority when playing Havoc Rover is to ensure you
                  keep using your Resonance Skill on cooldown in your downtime
                  between Umbra burst windows.
                </li>
                <li>
                  In addition to using your Resonance Skill on cooldown, hit the
                  enemy with a Basic Attack when possible to maintain the 5P
                  Havoc Eclipse echo set.
                </li>
              </ul>
              <p>
                Havoc Rover's rotation is flexible and can be either incredibly
                short or quite long - with all of them being viable with their
                own pros, cons and playstyles.
              </p>
              <h6>SHORT BURST COMBO</h6>
              <p>
                The Short Burst Combo aims to deal maximum damage in as little
                time as possible and uses all of Rover's cooldowns at once -
                primarily suitable for quick swap team's containing another
                damage dealer within them that also desires field time. This
                combo completely ignores Basic Attacks and instead focuses only
                on the highest damage abilities that can be executed in the
                shortest time with the least vulnerabilities.
              </p>
              <blockquote>
                <ul>
                  <li>Intro</li>
                  <li>Skill</li>
                  <li>Heavy ATK: Devastation</li>
                  <li>Skill: Umbra: Lifetaker (Optionally Swap Cancel)</li>
                  <li>Ultimate</li>
                  <li>Echo (Dreamless Swap Cancel)</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
              <h6>MEDIUM BURST COMBO</h6>
              <p>
                Havoc Rover's medium burst combo achieves higher total damage
                but over a longer duration and is suitable for teams where they
                are the sole damage dealer accompanied by two supportive
                characters. As long as you execute the warm-up you'll be able to
                move straight into Umbra following your intro and standard skill
                ability. Ater which aim to execute 1-2 full Basic Attack
                sequences, followed by an Umbra skill to extend the Umbra state.
                After this use the remaining time within Umbra to use your
                Ultimate and immediately after the Dreamless echo straight into
                a swap cancel.
              </p>
              <blockquote>
                <ul>
                  <li>Intro</li>
                  <li>Skill</li>
                  <li>Heavy ATK: Devastation</li>
                  <li>Umbra: Basic P1</li>
                  <li>Umbra: Basic P2</li>
                  <li>Umbra: Basic P3</li>
                  <li>Umbra: Basic P4</li>
                  <li>Umbra: Basic P5</li>
                  <li>Umbra: Basic P1</li>
                  <li>Umbra: Basic P2</li>
                  <li>Umbra: Basic P3</li>
                  <li>Umbra: Basic P4</li>
                  <li>Umbra: Basic P5</li>
                  <li>Skill: Umbra: Lifetaker (Optionally Swap Cancel)</li>
                  <li>Ultimate</li>
                  <li>Echo (Dreamless Swap Cancel)</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
              <h6>LONG BURST COMBO</h6>
              <p>
                For those with excellent enemy awareness and the ability to spam
                Basic Attacks without the need to dodge, Rover is capable of
                fitting a full 3 Basic Attack sequences in their Umbra state -
                albeit with a bit of difficulty. This rotation has even longer
                field time, slightly higher total damage and is primarily useful
                in teams which require Rover take up substantial field time
                while waiting for cooldowns. Note, one or multiple abilities can
                end up falling outside the Umbra state, depending on the combat
                scenario introducing some risk to this rotation.
              </p>
              <blockquote>
                <ul>
                  <li>Intro</li>
                  <li>Skill</li>
                  <li>Heavy ATK: Devastation</li>
                  <li>Umbra: Basic P1</li>
                  <li>Umbra: Basic P2</li>
                  <li>Umbra: Basic P3</li>
                  <li>Umbra: Basic P4</li>
                  <li>Umbra: Basic P5</li>
                  <li>Skill: Umbra: Lifetaker (Optionally Swap Cancel)</li>
                  <li>Umbra: Basic P1</li>
                  <li>Umbra: Basic P2</li>
                  <li>Umbra: Basic P3</li>
                  <li>Umbra: Basic P4</li>
                  <li>Umbra: Basic P5</li>
                  <li>Ultimate</li>
                  <li>Umbra: Basic P1</li>
                  <li>Umbra: Basic P2</li>
                  <li>Umbra: Basic P3</li>
                  <li>Umbra: Basic P4</li>
                  <li>Umbra: Basic P5</li>
                  <li>Echo (Dreamless Swap Cancel)</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
            </>
          )}
          {slug === 'yuanwu' && (
            <>
              <p>
                Unlike most primary DPS and Hybrid dealers, Yuanwu features no
                set rotation when played and instead focuses on his Ultimate and
                Resonance Skill, which make up the majority of his endgame
                capabilities. While we have assigned him a “rotation” for the
                purpose of calculations, it is better to refer to the following
                list as things to watch out for during his gameplay instead:
              </p>
              <ul>
                <li>
                  Building Yuanwu’s Forte Gauge as fast as possible should be
                  your focus as it maximizes his damage potential and generates
                  Concerto and Resonance Energy.
                </li>
                <li>
                  While his Thunder Wedge (skill) is active, he gains Forte
                  Gauge passively, and gains additional Forte each time he lands
                  a coordinated attack (whether after an attack by himself or
                  via ally) on an enemy in his Wedge radius. As such it is
                  essential to keep his Wedge active at all times and within
                  range of the currently targeted enemy to maximize Forte
                  generation.
                </li>
                <li>
                  Another benefit of keeping his Thunder Wedge active and in
                  range of target enemy is that it emits a pulse of energy
                  dealing small AoE damage upon completely filling his Forte.
                </li>
                <li>
                  Using a charged skill on an enemy to expend filled Forte Gauge
                  is by far, the easiest way to generate Concerto and Resonance
                  Energy for him.
                </li>
                <li>
                  Upon executing his Ultimate or Forte Gauge empowered skill, he
                  explodes any active Thunder Wedges, thus making it important
                  to ensure it’s presence on the target you are about to hit
                  with either skill beforehand. (Thunder Wedge can be summoned
                  back shortly to minimize downtime).
                </li>
                <li>
                  Yuanwu’s Ultimate skill grants him Lightning-infused state
                  grants him many effects, including Anti-Interruption which
                  benefits other team members even after Swapping.
                </li>
                <li>
                  Yuanwu’s Ultimate should be utilized as soon as it's available
                  (preferably with detonation of a Wedge), unless it's being
                  saved to assist an teammate with Anti-Interruption buff.
                </li>
                <li>
                  Despite Lighting Infused state being an enhanced state, like
                  many other characters possess, due to its low multipliers,
                  it's not worth keeping Yuanwu on the field to utilize his
                  Basic Attack combos.
                </li>
              </ul>
              <blockquote>
                <h6>Ability Priority</h6>
                <ul>
                  <li>Echo </li>
                  <li>Intro (If Available)</li>
                  <li>Skill: Place Thunderwedge</li>
                  <li>Ultimate (Detonates Thunderwedge)</li>
                  <li>Skill: Forte: Rumbling Spark</li>
                  <li>Skill: Place Thunderwedge</li>
                  <li>Outro (If Available)</li>
                  <li></li>
                  <li>
                    Skill: Place Thunderwedge (As needed to keep active and on
                    top of enemies)
                  </li>
                </ul>
              </blockquote>
            </>
          )}
          {slug === 'jiyan' && (
            <>
              <blockquote>
                <h6>WARM UP</h6>
                <p>
                  While waiting for cooldowns to reset, building energy for
                  Jiyan and cycling through your team for Concerto generation,
                  aim to execute the following warm-up moves:
                </p>
                <ul>
                  <li>
                    Keep Jiyan’s Resonance Skill on cooldown always outside your
                    burst window, as it generates massive Resonance Energy.
                  </li>
                  <li>
                    Jiyan’s Echo should be used in the warm-up phase but right
                    before his Ultimate usage, so you get Echo buff without
                    reducing time in his Ultimate burst window.
                  </li>
                </ul>
              </blockquote>
              <blockquote>
                <h6>BURST COMBO</h6>
                <p>
                  Jiyan’s enhanced Basic Attack, Lance of Qinglong only deals
                  damage, when Jiyan is executing the animation associated,
                  afterwards despite animation continuing on its own, it deals
                  no damage. As such, Dash-Canceling Lance or interrupting it
                  with another skill, allows you to exit the animation state,
                  securing any damage done so far while forgoing any further
                  damage. His Ultimate duration allows for either 6 Lance’s +
                  Skill or 7 Lance’s before being left with little duration, to
                  maximize gain from this duration, we start and cancel another
                  Lance of Qinglong, gaining a bit of damage at cost of minimum
                  rotation time. It can be seen as executing 6 Lance’s, a skill
                  and small portion of 7th Lance of Qinglong with this trick,
                  however it can be ignored if desired, for it is minimal damage
                  gain.
                </p>
                <ul>
                  <li>
                    Echo (Use Jiyan's Echo right before entering the burst combo
                    so the Echo buff is active. Refer to the Echo guide on
                    whether you'll need to Swap-Cancel it.)
                  </li>
                  <li>Intro</li>
                  <li>Forte: Emerald Storm</li>
                  <li>
                    Heavy ATK: Lance of Qinqlong P1 (Interrupt as fast as
                    possible using Resonance Skill)
                  </li>
                  <li>Resonance Skill</li>
                  <li>Heavy Attack: Lance of Qinqlong P1</li>
                  <li>Heavy Attack: Lance of Qinqlong P2</li>
                  <li>Heavy Attack: Lance of Qinqlong P3</li>
                  <li>Heavy Attack: Lance of Qinqlong P1</li>
                  <li>Heavy Attack: Lance of Qinqlong P2</li>
                  <li>Heavy Attack: Lance of Qinqlong P3</li>
                  <li>Resonance Skill</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
              <blockquote>
                <h6>DOUBLE DRAGON COMBO</h6>
                <p>
                  If you're a fan of pushing your characters to the limit and
                  want to get more out of your Jiyan, you can go for the Double
                  Dragon Combo instead. This combo is harder to pull off, takes
                  good timing and coordination but when executed correctly deals
                  more damage in a shorter time. As the name suggests executing
                  this combo will allow Jiyan to have two Lance of Qinglong
                  Dragons active and hitting the enemy at the same time.
                </p>
                <p>
                  This can be achieved by alternating between using the first
                  part of Lance of Qinglong and then at a specific moment
                  interrupting the animation with a dodge cancel. Doing this
                  results in Jiyan executing his Lance of Qinglong faster -
                  enough so that he can start the next one while the hit box of
                  the previous is still on the field. The downside of this combo
                  is that if you don't time your dodge cancels just right, you
                  can end up cutting Lance of Qinglong short, losing a portion
                  of the 8 hits it usually delivers. You'll need to practice and
                  time your cancels to ensure each attack fully executes without
                  any loss. Here is how the rotation is played:
                </p>
                <ul>
                  <li>Intro</li>
                  <li>Forte: Emerald Storm</li>
                  <li>
                    Heavy ATK: Lance of Qinqlong P1 (Interrupt as fast as
                    possible using Resonance Skill)
                  </li>
                  <li>Resonance Skill</li>
                  <li>Heavy ATK: Lance of Qinqlong P1 (Dodge Cancel)</li>
                  <li>Heavy ATK: Lance of Qinqlong P1 (Dodge Cancel)</li>
                  <li>Heavy ATK: Lance of Qinqlong P1 (Dodge Cancel)</li>
                  <li>Heavy ATK: Lance of Qinqlong P1 (Dodge Cancel)</li>
                  <li>Heavy ATK: Lance of Qinqlong P1 (Dodge Cancel)</li>
                  <li>Heavy ATK: Lance of Qinqlong P1 (Dodge Cancel)</li>
                  <li>Heavy ATK: Lance of Qinqlong P1 (Dodge Cancel)</li>
                  <li>Resonance Skill</li>
                  <li>Outro</li>
                </ul>
                <p className="with-margin-top">
                  <i>
                    Due to the faster execution time of the Double Dragon Combo
                    it's possible to execute an 8th lance of Qinglong with
                    perfect play (this is quite difficult though).
                  </i>
                </p>
              </blockquote>
              <h5>Additional information</h5>
              <p>
                Here is the damage difference between the two different
                rotations that can be used on Jiyan - the basic rotation that's
                easy to use and the harder, Double Dragon rotation. While the
                raw DMG output of both is similar, Double Dragon rotation takes
                less field time (12.17s vs 10.94s) which results in higher DPS.
              </p>
              <Row xs={1} xl={1} className="dps-comparison">
                <Col>
                  <div className="box">
                    <h5>Basic vs Double Dragon</h5>
                    <div className={`simulation-container ${slug}`}>
                      <div className="simulation-row">
                        <div className="character">
                          <WWCharacter
                            slug={dpsRanking.slug}
                            mode="icon"
                            enablePopover
                          />
                        </div>
                        <div className="chart">
                          <div className="info-2">
                            <p>{dpsRanking.name} (Double Dragon)</p>
                          </div>
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar top-1`}
                              style={{ width: '100%' }}
                            ></div>
                            <div className="data">
                              <span className="dps">
                                362,739 DMG (33,775 DPS)
                              </span>
                              <span className="percent">112.33%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="simulation-row">
                        <div className="character">
                          <WWCharacter
                            slug={dpsRanking.slug}
                            mode="icon"
                            enablePopover
                          />
                        </div>
                        <div className="chart">
                          <div className="info-2">
                            <p>{dpsRanking.name} (Basic)</p>
                          </div>
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar top-3`}
                              style={{ width: '89.02%' }}
                            ></div>
                            <div className="data">
                              <span className="dps">
                                360,023 DMG (30,067 DPS)
                              </span>
                              <span className="percent">100%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
          {slug === 'danjin' && (
            <>
              <h6>MAINTENANCE</h6>
              <p>
                Keep Danjin's Incinerating Will active as consistantly as
                possible:
              </p>
              <blockquote>
                <ul>
                  <li>Basic P1 OR Skill: Carmine Gleam</li>
                  <li>Basic P2</li>
                  <li>Skill: Crimson Erosion I</li>
                  <li>Skill: Crimson Erosion II</li>
                </ul>
              </blockquote>
              <p>
                Danjin can be played as a Hybrid character with a focus on
                generating her concerto quickly and efficiently or as a damage
                dealer with a focus on acheiving the highest damage rotation
                possible.
              </p>
              <h6>DAMAGE DEALER COMBO</h6>
              <p>
                This combo's defining feature is its increased length and focus
                on maxing out Danjin's Forte gauge to perform a full power
                double Forte Heavy attack.
              </p>
              <blockquote>
                <ul>
                  <li>Intro</li>
                  <li>Skill: Crimson Erosion I</li>
                  <li>Skill: Crimson Erosion II</li>
                  <li>Ultimate</li>
                  <li>Skill: Carmine Gleam</li>
                  <li>Basic P2</li>
                  <li>Basic P3</li>
                  <li>Skill: Sanguine Pulse I</li>
                  <li>Skill: Sanguine Pulse II</li>
                  <li>Skill: Sanguine Pulse III</li>
                  <li>Skill: Carmine Gleam</li>
                  <li>Basic P2</li>
                  <li>Basic P3</li>
                  <li>Skill: Sanguine Pulse I</li>
                  <li>Skill: Sanguine Pulse II</li>
                  <li>Skill: Sanguine Pulse III</li>
                  <li>Heavy Attack: Chaoscleave (Full Power)</li>
                  <li>Heavy Attack: Scatterbloom (Full Power)</li>
                  <li>Echo (Swap Cancel)</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
              <h6>HYBRID COMBO</h6>
              <p>
                This combo is an easy to execute, has no strings attached, has
                fast concerto rotation, focused on generating Danjin's Outro
                quickly without sacrificing all of her kit.
              </p>
              <blockquote>
                <ul>
                  <li>Intro</li>
                  <li>Skill: Crimson Erosion I</li>
                  <li>Skill: Crimson Erosion II</li>
                  <li>Ultimate</li>
                  <li>Skill: Carmine Gleam</li>
                  <li>Basic P2</li>
                  <li>Basic P3</li>
                  <li>Skill: Sanguine Pulse I</li>
                  <li>Skill: Sanguine Pulse II</li>
                  <li>Skill: Sanguine Pulse III</li>
                  <li>Heavy Attack: Chaoscleave (Partial Power)</li>
                  <li>Heavy Attack: Scatterbloom (Partial Power)</li>
                  <li>Echo (Swap Cancel)</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
              <h6>FAST HYBRID COMBO</h6>
              <p>
                This combo further speeds up Danjin's Concerto generation at the
                cost of increased complexity and sacrificing even more of her
                damage and kit in the process.
              </p>
              <blockquote>
                <ul>
                  <li>Skill: Carmine Gleam</li>
                  <li>Echo (Swap Cancel)</li>
                  <li>Intro</li>
                  <li>Skill: Crimson Erosion I</li>
                  <li>Skill: Crimson Erosion II</li>
                  <li>Ultimate</li>
                  <li>Skill: Carmine Gleam</li>
                  <li>Basic P2</li>
                  <li>Skill: Crimson Erosion I</li>
                  <li>Skill: Crimson Erosion II</li>
                  <li>
                    Heavy Attack: Chaoscleave Full (Partial Power)(Swap Cancel)
                  </li>
                </ul>
              </blockquote>
              <h5>Additional information</h5>
              <p>
                Here is the damage difference between the three different
                rotations.
              </p>
              <Row xs={1} xl={1} className="dps-comparison">
                <Col>
                  <div className="box">
                    <h5>Rotation Damage Comparison</h5>
                    <div className={`simulation-container ${slug}`}>
                      <div className="simulation-row">
                        <div className="character">
                          <WWCharacter
                            slug={dpsRanking.slug}
                            mode="icon"
                            enablePopover
                          />
                        </div>
                        <div className="chart">
                          <div className="info-2">
                            <p>{dpsRanking.name} (Extended DPS Rotation)</p>
                          </div>
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar top-1`}
                              style={{ width: '100%' }}
                            ></div>
                            <div className="data">
                              <span className="dps">298,781 DMG (11.16s)</span>
                              <span className="percent">156.84%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="simulation-row">
                        <div className="character">
                          <WWCharacter
                            slug={dpsRanking.slug}
                            mode="icon"
                            enablePopover
                          />
                        </div>
                        <div className="chart">
                          <div className="info-2">
                            <p>
                              {dpsRanking.name} (Baseline Concerto Rotation)
                            </p>
                          </div>
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar top-2`}
                              style={{ width: '63.76%' }}
                            ></div>
                            <div className="data">
                              <span className="dps">190,505 DMG (7.61s)</span>
                              <span className="percent">100%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="simulation-row">
                        <div className="character">
                          <WWCharacter
                            slug={dpsRanking.slug}
                            mode="icon"
                            enablePopover
                          />
                        </div>
                        <div className="chart">
                          <div className="info-2">
                            <p>
                              {dpsRanking.name} (Fast Concerto Outro Rotation)
                            </p>
                          </div>
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar top-3`}
                              style={{ width: '46.92%' }}
                            ></div>
                            <div className="data">
                              <span className="dps">140,178 DMG (5.24s)</span>
                              <span className="percent">73.58%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
          {slug === 'encore' && (
            <>
              <h6>WARM UP</h6>
              <p>
                When awaiting cooldowns, building energy for Encore and cycling
                through your team for Concerto or playing Encore in Quick Swap,
                aim to execute the following warm-up moves on cooldown. Ensure
                all of the following abilities are always utilized to maximize
                her damage, this is even more so important when playing Encore
                in Quick Swap teams, where she has less field time to utilize
                her Ultimate.
              </p>
              <ul>
                <li>
                  Use Resonance Skill P1 Flaming Woolies on cooldown and
                  immediately Swap-Cancel it after casting, allowing you to play
                  another character while Encore finishes the cast in an immune
                  state.
                </li>
                <li>
                  Use Encore’s Echo on cooldown, especially if Inferno Rider,
                  performing any Swap-Cancels where required. When playing
                  Encore in teams with ample time for her Ultimate usage, ensure
                  Echo buff is active throughout her Ultimate duration.
                </li>
                <li>
                  Use Charged Heavy Attack whenever Encore’s Forte Gauge is full
                  and Swap-Cancel to the next character immediately. Whenever
                  possible, align usage of Forte Gauge with her Ultimate for a
                  large increase in its damage.
                </li>
              </ul>
              <blockquote>
                <h6>EASY & BASIC BURST COMBO</h6>
                <p>
                  When playing teams that focus on Encore's Ultimate or when you
                  have field time to spare after all other cooldowns have been
                  expended in Quick Swap team's execute the following burst
                  combo on Encore.
                </p>
                <p>
                  Ending Encore’s Ultimate early to cast Rupture before duration
                  concludes can be a viable strategy in Quick Swap teams, so as
                  to not waste other DPS cooldowns. Whereas, if Encore is the
                  sole DPS that is buffed by teammates, this is generally
                  advised against.
                </p>
                <ul>
                  <li>
                    Echo (Use before combo but refer to Echo recommendations for
                    exact specifics for your chosen Echo)
                  </li>
                  <li>Intro</li>
                  <li>Ultimate</li>
                  <li>Skill: Cosmos: Rampage</li>
                  <li>Basic ATK: Cosmos: Frolicking P1</li>
                  <li>Basic ATK: Cosmos: Frolicking P2</li>
                  <li>Basic ATK: Cosmos: Frolicking P3</li>
                  <li>Basic ATK: Cosmos: Frolicking P4</li>
                  <li>Skill: Cosmos: Rampage</li>
                  <li>Basic ATK: Cosmos: Frolicking P1</li>
                  <li>Basic ATK: Cosmos: Frolicking P2</li>
                  <li>Basic ATK: Cosmos: Frolicking P3</li>
                  <li>Basic ATK: Cosmos: Frolicking P4</li>
                  <li>Skill: Cosmos: Rampage</li>
                  <li>
                    Heavy ATK: Cosmos: Rupture (Swap-Cancel The Moment Encore
                    Begin's Channelling)
                  </li>
                  <li>Outro</li>
                </ul>
              </blockquote>
              <blockquote>
                <h6>ADVANCED BURST COMBO</h6>
                <p>
                  For those aiming even more damage when playing Encore and are
                  confident in their mechanical abilities, there are a number of
                  tricks you can employ to increase her damage at cost of
                  difficulty increase in battle. This is achieved via executing
                  more Basic Attacks after each Ultimate, here is what you will
                  need to adjust:
                </p>
                <ul>
                  <li>
                    Core of new rotates lies in cancellation of Encore’s 4th
                    enhanced Basic Attack animation, allowing you to start your
                    next action much faster. This can be achieved by
                    interrupting it via Skill usage or a Dash, you will need to
                    perform one of these cancels 3 times during her Ultimate in
                    this new rotation.
                  </li>
                  <li>
                    The other change is, this rotation gives one enhanced Skill
                    use in favor of 4 more Basic Attacks, this leads to
                    increased damage but leaves you with very less time to
                    execute the entire combo without losing out on final Basic
                    Attack, Skill and Forte Heavy Attack if you mess up. up.
                  </li>
                </ul>
                <ul>
                  <li>
                    Echo (Use before combo but refer to Echo recommendations for
                    exact specifics for your chosen Echo)
                  </li>
                  <li>Intro</li>
                  <li>Ultimate</li>
                  <li>Basic ATK: Cosmos: Frolicking P1</li>
                  <li>Basic ATK: Cosmos: Frolicking P2</li>
                  <li>Basic ATK: Cosmos: Frolicking P3</li>
                  <li>Basic ATK: Cosmos: Frolicking P4</li>
                  <li>
                    Skill: Cosmos: Rampage (Use Skill to animation cancel
                    Frolicking P4)
                  </li>
                  <li>Basic ATK: Cosmos: Frolicking P1</li>
                  <li>Basic ATK: Cosmos: Frolicking P2</li>
                  <li>Basic ATK: Cosmos: Frolicking P3</li>
                  <li>
                    Basic ATK: Cosmos: Frolicking P4 (Dash Cancel this attack)
                  </li>
                  <li>Basic ATK: Cosmos: Frolicking P1</li>
                  <li>Basic ATK: Cosmos: Frolicking P2</li>
                  <li>Basic ATK: Cosmos: Frolicking P3</li>
                  <li>Basic ATK: Cosmos: Frolicking P4</li>
                  <li>
                    Skill: Cosmos: Rampage (Use Skill to animation cancel
                    Frolicking P4)
                  </li>
                  <li>
                    Heavy ATK: Cosmos: Rupture (Swap-Cancel the moment Encore
                    begins channelling)
                  </li>
                  <li>Outro</li>
                </ul>
              </blockquote>
              <blockquote>
                <h6>No Forte Rotation</h6>
                <p>
                  This rotation is specific to the Encore-Sanhua-Verina trio -
                  or with any 2 supports with the same extremely short field
                  time.
                </p>
                <p>
                  Since you don’t have time, at an optimized level, to use both
                  Encore’s Forte and off-ultimate skill in the team’s rotation,
                  it ends up being optimal to forfeit the Forte entirely. This
                  is also optimal if you want to skip investing into your
                  Forte’s skill level — which may help you cut on resources
                  without forfeiting much damage.
                </p>
                <p>
                  Remember however, that the timings on the cancels here are
                  pretty tight and make for one of the most difficult rotations
                  in the game (while not on the level of the most difficult).
                  This rotation may also present minor inconsistencies if your
                  enemy isn’t a training dummy (which Encore thankfully doesn’t
                  suffer much from).
                </p>
                <p>Here’s what it looks like:</p>
                <ul>
                  <li>Echo (pre-rotation)</li>
                  <li>Intro</li>
                  <li>Ultimate</li>
                  <li>Skill</li>
                  <li>B1</li>
                  <li>B2</li>
                  <li>B3</li>
                  <li>B4 (dash cancel)</li>
                  <li>B1</li>
                  <li>B2</li>
                  <li>B3</li>
                  <li>B4 </li>
                  <li>Skill (cancel B4’s endlag)</li>
                  <li>B1</li>
                  <li>B2</li>
                  <li>B3</li>
                  <li>B4</li>
                  <li>Skill (cancel B4’s endlag)</li>
                  <li>
                    Skill (after your previous skill’s damage comes out, then
                    swap cancel this attack)
                  </li>
                  <li>Outro </li>
                  <li>
                    Skill: Energetic Welcome (come back to Encore to cast this
                    attack before swapping out)
                  </li>
                </ul>
                <p>
                  Note: This is only optimal if your other 2 characters have
                  field times as short as Sanhua and Verina. This rotation
                  becomes suboptimal when Shorekeeper is at play, for example —
                  in which case use the other optimized rotation (that includes
                  using Forte).
                </p>
              </blockquote>
            </>
          )}
          {slug === 'calcharo' && (
            <>
              <h6>WARM UP</h6>
              <p>
                When awaiting cooldowns, building energy for Calcharo and
                cycling through your team for Concerto, aim to execute the
                following warm-up moves. Use one of these combos before
                executing Ultimate combo to build up Resonance Energy as well as
                enabling many Weapon bonus buffs to be active during burst
                combo:
              </p>
              <blockquote>
                <p>
                  <strong>Option 1</strong> (This generates more Concerto and
                  Resonance Energy and enables more Weapon bonuses to be active
                  during burst, at the cost of higher risk)
                </p>
                <ul>
                  <li>Basic P1</li>
                  <li>Skill: Extermination Order P1</li>
                  <li>Basic P2</li>
                  <li>Skill: Extermination Order P2</li>
                  <li>Basic P3</li>
                  <li>Basic P4</li>
                  <li>
                    Skill: Extermination Order P3 (Can be used to animation
                    cancel the less effective portion of Basic P4)
                  </li>
                  <li>
                    Heavy ATK: Mercy (Swap-Cancel if you can for higher damage
                    and to protect Calcharo)
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Option 2</strong> (faster to execute, safer and can be
                  done in between Dodging)
                </p>
                <ul>
                  <li>Skill: Extermination Order P1</li>
                  <li>Skill: Extermination Order P2</li>
                  <li>Skill: Extermination Order P3</li>
                  <li>
                    Heavy ATK: Mercy (Swap-Cancel if you can for higher damage
                    and to protect Calcharo)
                  </li>
                </ul>
              </blockquote>
              <h6>OPTIMIZED BURST COMBO</h6>
              <p>
                Below is an optimized combo that implements Dash cancels to
                allow Calcharo to accumulate Killing Intent faster. The slowest
                part of the 5 attack Hounds Roar combo needed to generate 5
                Killing Intent to cast Death Messenger is part 4 and 5 - by
                interrupting the combo after the second attack with a dash you
                can reset the combo back to the start and repeat parts 1 and 2
                only, speeding up your access to death messengers. This combo is
                more challenging to execute but offers more reliable acces to 3
                Death Messengers and minimises some of your time stuck in long
                attack animations.
              </p>
              <p>
                When playing Calcharo, quick swaps can help protect him during
                the long cast times of Death Messenger - if you're having
                trouble with taking too much damage or getting interrupted while
                attacking consider this as a form of mitigation.
              </p>
              <blockquote>
                <ul>
                  <li>Echo (Use before combo and swap cancel)</li>
                  <li>Intro</li>
                  <li>Ultimate</li>
                  <li>Heavy ATK: Death Messenger (Optionally Swap Cancel)</li>
                  <li>Basic ATK: Hounds Roar P1</li>
                  <li>Basic ATK: Hounds Roar P2 (Dash Cancel)</li>
                  <li>Basic ATK: Hounds Roar P1</li>
                  <li>Basic ATK: Hounds Roar P2 (Dash Cancel)</li>
                  <li>Basic ATK: Hounds Roar P1</li>
                  <li>Heavy ATK: Death Messenger (Optionally Swap Cancel)</li>
                  <li>Basic ATK: Hounds Roar P1</li>
                  <li>Basic ATK: Hounds Roar P2 (Dash Cancel)</li>
                  <li>Basic ATK: Hounds Roar P1</li>
                  <li>Basic ATK: Hounds Roar P2 (Dash Cancel)</li>
                  <li>Basic ATK: Hounds Roar P1</li>
                  <li>Heavy ATK: Death Messenger (Optionally Swap Cancel)</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
              <h6>BASIC BURST COMBO</h6>
              <p>
                If you struggle with performing Dash Cancels, you can simply
                spam left mouse button to execute the combo. This will lead to a
                slower rotation, more vulnerabilities in the combo and less
                overall time to execute all Death Messengers but is easier to
                execute even if less effective.
              </p>
              <blockquote>
                <ul>
                  <li>
                    Echo (Use before combo but refer to echo recommendations for
                    exact specifics for your chosen echo)
                  </li>
                  <li>Intro</li>
                  <li>Ultimate</li>
                  <li>
                    Heavy ATK: Death Messenger (Optionally Swap Cancel If You
                    Can For Higher Damage And To Protect Calcharo)
                  </li>
                  <li>Basic ATK: Hounds Roar P1</li>
                  <li>Basic ATK: Hounds Roar P2</li>
                  <li>Basic ATK: Hounds Roar P3</li>
                  <li>Basic ATK: Hounds Roar P4</li>
                  <li>Basic ATK: Hounds Roar P5</li>
                  <li>
                    Heavy ATK: Death Messenger (Swap Cancel If You Can For
                    Higher Damage And To Protect Calcharo)
                  </li>
                  <li>Basic ATK: Hounds Roar P1</li>
                  <li>Basic ATK: Hounds Roar P2</li>
                  <li>Basic ATK: Hounds Roar P3</li>
                  <li>Basic ATK: Hounds Roar P4</li>
                  <li>Basic ATK: Hounds Roar P5</li>
                  <li>
                    Heavy ATK: Death Messenger (Swap Cancel If You Can For
                    Higher Damage And To Protect Calcharo)
                  </li>
                  <li>Outro</li>
                </ul>
              </blockquote>
              <h6>4 DEATH MESSENGER COMBO (DIFFICULTY = IMPOSSIBLE)</h6>
              <p>
                For those looking to optimize it's technically possible to
                achieve a 4 Death Messenger combo when peforming Dash cancels
                perfectly, leading to an overall DPS increase. This rotation and
                combo is widely considered to be one of the hardest in the
                entire game to pull off, so even though it exists, achieving it
                at all, let alone consistantly is seen as the holy grail.
              </p>
              <blockquote>
                <ul>
                  <li>Echo (Use before combo and swap cancel)</li>
                  <li>Intro</li>
                  <li>Ultimate</li>
                  <li>Heavy ATK: Death Messenger (Optionally Swap Cancel)</li>
                  <li>Basic ATK: Hounds Roar P1</li>
                  <li>Basic ATK: Hounds Roar P2 (Dash Cancel)</li>
                  <li>Basic ATK: Hounds Roar P1</li>
                  <li>Basic ATK: Hounds Roar P2 (Dash Cancel)</li>
                  <li>Basic ATK: Hounds Roar P1</li>
                  <li>Heavy ATK: Death Messenger (Optionally Swap Cancel)</li>
                  <li>Basic ATK: Hounds Roar P1</li>
                  <li>Basic ATK: Hounds Roar P2 (Dash Cancel)</li>
                  <li>Basic ATK: Hounds Roar P1</li>
                  <li>Basic ATK: Hounds Roar P2 (Dash Cancel)</li>
                  <li>Basic ATK: Hounds Roar P1</li>
                  <li>Heavy ATK: Death Messenger (Optionally Swap Cancel)</li>
                  <li>Basic ATK: Hounds Roar P1</li>
                  <li>Basic ATK: Hounds Roar P2 (Dash Cancel)</li>
                  <li>Basic ATK: Hounds Roar P1</li>
                  <li>Basic ATK: Hounds Roar P2 (Dash Cancel)</li>
                  <li>Basic ATK: Hounds Roar P1</li>
                  <li>Heavy ATK: Death Messenger (Optionally Swap Cancel)</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
              <h5>Additional information</h5>
              <p>
                Here's a comparison between the damage output of 3 Death
                Messenger rotation (our baseline) and 2 and 4 (the dream that's
                hard to achieve).
              </p>
              <Row xs={1} xl={1} className="dps-comparison">
                <Col>
                  <div className="box">
                    <h5>4DM vs 3DM vs 2DM</h5>
                    <div className={`simulation-container ${slug}`}>
                      <div className="simulation-row">
                        <div className="character">
                          <WWCharacter
                            slug={dpsRanking.slug}
                            mode="icon"
                            enablePopover
                          />
                        </div>
                        <div className="chart">
                          <div className="info-2">
                            <p>{dpsRanking.name} (4DM)</p>
                          </div>
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar top-1`}
                              style={{ width: '100%' }}
                            ></div>
                            <div className="data">
                              <span className="dps">449,369 DMG</span>
                              <span className="percent">114.77%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="simulation-row">
                        <div className="character">
                          <WWCharacter
                            slug={dpsRanking.slug}
                            mode="icon"
                            enablePopover
                          />
                        </div>
                        <div className="chart">
                          <div className="info-2">
                            <p>{dpsRanking.name} (3DM)</p>
                          </div>
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar top-2`}
                              style={{ width: '87.13%' }}
                            ></div>
                            <div className="data">
                              <span className="dps">391,539 DMG</span>
                              <span className="percent">100%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="simulation-row">
                        <div className="character">
                          <WWCharacter
                            slug={dpsRanking.slug}
                            mode="icon"
                            enablePopover
                          />
                        </div>
                        <div className="chart">
                          <div className="info-2">
                            <p>{dpsRanking.name} (2DM)</p>
                          </div>
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar top-3`}
                              style={{ width: '68%' }}
                            ></div>
                            <div className="data">
                              <span className="dps">305,568 DMG</span>
                              <span className="percent">78.04%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
          {slug === 'chixia' && (
            <>
              <blockquote>
                <h6>WARM UP</h6>
                <p>
                  While waiting for cooldowns to reset, building energy for
                  Chixia and cycling through your team for Concerto generation,
                  aim to execute the following warm-up moves:
                </p>
                <ul>
                  <li>
                    Use a charge of Resonance Skill on cooldown, outside of
                    burst of window to generate much required Resonance Energy
                    and alongside her Forte Gauge. As a guideline, try to fit in
                    two Resonance Skills in Warm-Up phase while setting aside 1
                    Charge before entering burst combo, unless you possess
                    Chixia’s Sequence - 4 which allows for greater flexibility
                    in skill usage. By executing 2 Resonance Skills in this
                    phase, you will generate the majority of her Forte Gauge,
                    making her ready for burst combo.
                  </li>
                  <li>
                    When executing Resonance Skills in the Warm-Up phase,
                    remember to immediately Swap-Cancel them saving you long
                    animation times.
                  </li>
                </ul>
              </blockquote>
              <blockquote>
                <h6>BURST COMBO</h6>
                <p>
                  <strong>
                    Chixia features one of the hardest burst combo sequences to
                    successfully complete in endgame content due to multiple
                    reasons such as:
                  </strong>{' '}
                </p>
                <p>
                  Using her Echo, especially if buff related Echo such as
                  Inferno Rider or Mech Abomination before initiating her burst
                  combo. With her Echo buff in place, move away from target to
                  build some distance to make landing combo easier and trigger
                  her Intro.
                </p>
                <p>
                  Immediately after Intro skill, use her Resonance Skill to
                  consume Forte Gauge built in Warm-Up phase. Repeat this until
                  you reach the indicator on her Forte Gauge then tap Basic
                  Attack to fire BOOM BOOM. A full execution of this ability
                  takes 4 seconds and while it allows movement, failing to
                  complete the full duration will cause massive damage loss.
                </p>
                <p>
                  While channeling Chixia’s Forte Gauge, aim for exactly 30
                  Bullets consumption, as any longer will leave you dry for the
                  next channel. While channeling, she gains a massive attack
                  boost, which is why we use it just before her Ultimate,
                  following which use another full Forte channel into BOOM BOOM
                  and lastly Outro, before repeating the whole sequence.
                </p>
                <p>
                  <strong>It's extremely important</strong> to note that while
                  pairing her Forte Skills and Ultimate result in large damage
                  increases, it is more important to land them in first place.
                  As such, you are free to completely ignore the sequence below
                  and instead use each ability as opportunity presents itself,
                  even if it ends up in damage loss.
                </p>
                <ul>
                  <li>
                    Echo (Use before combo but refer to Echo recommendations for
                    exact specifics for your chosen Echo)
                  </li>
                  <li>Intro</li>
                  <li>Skill: Forte: Thermobaric Bullets</li>
                  <li>Skill: Forte: BOOM BOOM</li>
                  <li>Ultimate</li>
                  <li>Skill: Forte: Thermobaric Bullets</li>
                  <li>Skill: Forte: BOOM BOOM</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
            </>
          )}
          {slug === 'jianxin' && (
            <>
              <p>
                When playing Jianxin as a support focus on achieving the
                following in combat:
              </p>
              <blockquote>
                <ul>
                  <li>
                    Keep your Resonance Skill on cooldown at all times, unless
                    its usage interrupts use of other higher priority cooldowns
                    or DPS burst windows.
                  </li>
                  <li>
                    Ensure to perform Basic Attacks throughout combat to keep 5P
                    Rejuvenating Glow Set and Originite: Type IV bonus active at
                    all times. These attacks also help in generating additional
                    Concerto energy if aiming to trigger her Outro before
                    character Swap.
                  </li>
                  <li>
                    Use her Echo as frequently as possible asides from saving it
                    for usage just before a burst combo.
                  </li>
                  <li>
                    Always utilize Jianxin’s Ultimate to generate Concerto
                    Energy and control enemies, aside from saving it to Group-Up
                    enemies at specific point of combat.
                  </li>
                  <li>
                    If possible, aim to align Jianxin’s Outro with the main DPS
                    burst combo (provided they benefit from Resonance Liberation
                    damage) or the use of their Ultimate (provided it deals
                    direct damage) when available.
                  </li>
                  <li>
                    Use her Forte Gauge as you deem fit, it will almost always
                    result in overall damage loss, but sometimes the Shield and
                    Concerto Energy generation is worth the tradeoff.
                  </li>
                </ul>
              </blockquote>
            </>
          )}
          {slug === 'rover-spectro' && (
            <>
              <blockquote>
                <h6>COMBO REQUIREMENTS</h6>
                <p>
                  Thanks to an incredibly accessible Forte Gauge, Ultimate and
                  very short Resonance Skill cooldown, Spectro Rover can
                  directly jump into action without any Warm-Up phase.
                </p>
              </blockquote>
              <blockquote>
                <h6>BURST COMBO</h6>
                <p>
                  Spectro Rover requires a Quick Swap mid-rotation to unlock
                  their maximum potential. This Swap is performed to abuse their
                  extremely short Resonance Skill cooldown of only 6 seconds.
                  Use one Skill initially alongside Rover’s Ultimate, perform a
                  Quick Swap and use a Skill or Echo on another character and
                  Swap back in to use another Resonance Skill combo for maximum
                  damage with little downtime. This way Rover is always using
                  their most effective and highest damage action throughout the
                  majority of the fight.
                </p>
                <ul>
                  <li>
                    Echo (Use before combo but refer to Echo recommendations for
                    exact specifics for your chosen Echo)
                  </li>
                  <li>Intro</li>
                  <li>Heavy Attack</li>
                  <li>Heavy Attack: Resonance</li>
                  <li>Heavy Attack: Aftertune</li>
                  <li>Skill: Forte: Resonanting Spin</li>
                  <li>Basic Attack: Forte: Resonating Echoes</li>
                  <li>Ultimate (Quick Swap While Waiting For Skill)</li>
                  <li>Skill: Forte: Resonanting Spin</li>
                  <li>Basic Attack: Forte: Resonating Echoes</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
            </>
          )}
          {slug === 'aalto' && (
            <>
              <p>
                Aalto’s primary aim is to spend minimal field time while fully
                generating his Concerto, in order to grant another character a
                powerful 23% generic Aero Amplification buff with least chance
                of failure. Due to his trickster build, he has many quirks and
                play styles available but to best suit his endgame role - we
                have settled with the fastest and most consistent Concerto
                rotation. Presented below is some information on how to play
                Aalto and how his rotation works:
              </p>
              <ul>
                <li>
                  His Resonance Skill: Mist Avatar, generates absurd amounts of
                  Concerto Energy along with decent Resonance Energy and taunts
                  the enemies with a Mist Clone.
                </li>
                <li>
                  The quickest and most efficient method to generate his Outro
                  is to use his Skill twice alongside his Ultimate and sequence
                  of Basic Attacks. Due to his Skill having a long cooldown of
                  10 seconds at S0, longer than any rotation he would want to
                  employ itself, it is best to weave in his skill into rotation
                  of your other support characters to refresh its cooldown, thus
                  allowing you to use it again for his main Concerto rotation.
                </li>
                <li>
                  His Forte Skill: Mistcloak Dash, is an excellent but difficult
                  to use mobility tool in actual combat situations, more so when
                  attempting to generate Concerto quickly under pressure. Given
                  this issue and challenge of generating Forte Gauge charges to
                  begin with, we have opted for no Forte rotation and found it
                  working the best, however feel free to weave in this Forte
                  where you find it to be effective if given a chance!
                </li>
                <li>
                  His Resonance Skill : Mist Avatar, requires a target to
                  generate most of its Concerto and Resonance Energy and
                  features a slight delay in their gain due to bullets needing
                  to hit the target first. This can lead to delay in Outro and
                  as such should be used as early in rotation as possible.
                </li>
                <li>
                  In the given rotation, you don’t need to worry about firing
                  through or positioning for his Mist Gates as the same is not
                  required to achieve the Concerto threshold for his Outro.
                </li>
              </ul>
              <blockquote>
                <ul>
                  <li>
                    Skill: Mist Avatar (Use during another character's rotation
                    to allow for cooldown)
                  </li>
                  <li>Intro</li>
                  <li>Ultimate</li>
                  <li>
                    Skill: Mist Avatar (Can be used after Basic Attacks if still
                    on Cooldown)
                  </li>
                  <li>Basic P1</li>
                  <li>Basic P2</li>
                  <li>Basic P3</li>
                  <li>Basic P4</li>
                  <li>Basic P5</li>
                  <li>Echo</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
            </>
          )}
          {slug === 'yangyang' && (
            <blockquote>
              <ul>
                <li>Intro</li>
                <li>Resonance Liberation</li>
                <li>Resonance Skill</li>
                <li>Basic Attack P1</li>
                <li>Basic Attack P2</li>
                <li>Basic Attack P3</li>
                <li>Basic Attack P4</li>
                <li>Mid-Air Attack: Feather Release</li>
                <li>
                  Heron OR Turtle Echo (Swap-Cancel after first part hits)
                </li>
                <li>Outro</li>
              </ul>
            </blockquote>
          )}
          {slug === 'sanhua' && (
            <>
              <p>
                When playing Sanhua, you have few choices to quickly and
                effectively build her Concerto gauge, while dealing great bust
                damage. Below listed are two commonly used rotations with the
                Heavy Attack variant being the preferred choice of player base
                currently. When playing Sanhua, keep in mind the following
                things:
              </p>
              <ul>
                <li>
                  Deploy her 3 Forte Ice Constructs - 1 each from her Intro,
                  Skill and Ultimate.
                </li>
                <li>
                  Shatter all 3 Constructs via a Heavy Attack Detonate before
                  their expiry, as exploding each Construct generates 15
                  Concerto Energy for a combined total of 45, something not
                  worth missing).
                </li>
                <li>
                  Trigger her Concerto at the right moment to buff DPS of choice
                  with her 38% Basic Attack Amplification.
                </li>
              </ul>
              <blockquote>
                <h6>HEAVY ATTACK CONCERTO ROTATION</h6>
                <ul>
                  <li>Intro</li>
                  <li>Ultimate</li>
                  <li>Skill</li>
                  <li>Heavy Attack: Detonate</li>
                  <li>Heavy Attack: Detonate</li>
                  <li>Echo (Impermanance Heron Swap-Cancel)</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
              <blockquote>
                <h6>BASIC ATTACK CONCERTO ROTATION</h6>
                <ul>
                  <li>Intro</li>
                  <li>Basic Attack P3</li>
                  <li>Basic Attack P4</li>
                  <li>Basic Attack P5</li>
                  <li>Ultimate</li>
                  <li>Skill</li>
                  <li>Heavy Attack: Detonate</li>
                  <li>Echo (Impermanance Heron Swap-Cancel)</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
            </>
          )}
          {slug === 'mortefi' && (
            <>
              <p>
                Mortefi is easy and straightforward to play when following the
                rotation below, however keep an eye out for the following things
                while playing him:
              </p>
              <ul>
                <li>
                  Mortefi’s Ultimate and Outro should be always used together
                  right before you Swap to another character to perform their
                  burst combo (preferably a Heavy attacker). Triggering his
                  Ultimate too early or during his own rotation wastes a chunk
                  of his damage potential as well as buffing potential if S6.
                </li>
                <li>
                  Using Resonance Skill: Passionate Variation gains you
                  additional Annoyance from all Basic Attacks for 5
                  seconds.Taking advantage of this window is important to allow
                  you regain access to Fury Fugue quickly to complete his
                  Concerto and keep rotation short.
                </li>
                <li>
                  His Ultimate will be always used at the end of his Concerto
                  Rotation, and generates 20 Concerto Energy (a little less than
                  a quarter of the gauge). As such, plan around this and use his
                  Ultimate when you are missing approx 20 Concerto so as to
                  minimize time spent generating it.
                </li>
              </ul>
              <blockquote>
                <ul>
                  <li>Intro</li>
                  <li>Skill: Passionate Variation</li>
                  <li>Skill: Fury Fugue</li>
                  <li>Basic P1</li>
                  <li>Basic P2</li>
                  <li>Basic P3</li>
                  <li>Basic P4</li>
                  <li>Skill: Fury Fugue</li>
                  <li>Ultimate</li>
                  <li>Echo (Impermanance Heron Swap-Cancel)</li>
                </ul>
              </blockquote>
            </>
          )}
          {slug === 'lingyang' && (
            <>
              <blockquote>
                <h6>COMBO REQUIREMENTS</h6>
                <p>
                  Unlike other characters, Lingyang can directly jump into
                  action without any Warm-Up phase. Only thing to focus outside
                  of his Ultimate burst combo is ensuring usage of Echo at
                  appropriate time, that is, if Buff Echo, use it right before
                  burst combo execution, if direct damage Echo (such as Glacial
                  Predator), use it whenever off cooldown.
                </p>
              </blockquote>
              <blockquote>
                <h6>BURST COMBO</h6>
                <p>
                  Lingyang’s combo is incredibly simple, just make sure you have
                  full energy available on him before Swapping to him and enter
                  with his Intro to generate a portion of his Forte Gauge
                  required. Follow it up with his Ultimate and perform a Heavy
                  Attack to become airborne.
                </p>
                <p>
                  While airborne, alternate between using a single Basic Attack
                  and a single Resonance Skill to take advantage of his Inherent
                  Skill: Diligent Practice. Ensure that you do not chain 2 of
                  same type together while alternating between them, as it is
                  very easy to accidentally do the same and lose damage overall.
                </p>
                <p>
                  Under ideal circumstances, you can execute 9 independant
                  attacks during his Ultimate duration, but if you get Stunned
                  or use Dodge, this number will reduce. While the damage loss
                  from this isn’t a disaster, you may need to spend extra time
                  building energy via other characters to make up for it. While
                  his Ultimate is on cooldown or he lacks the energy, utilize
                  that time on the rest of the team recharging their Concerto
                  Energy and preparing buffs for his next burst combo.
                </p>
                <ul>
                  <li>
                    Echo (Use before combo but refer to Echo recommendations for
                    exact specifics for your chosen Echo)
                  </li>
                  <li>Intro</li>
                  <li>Ultimate</li>
                  <li>
                    Heavy Attack: Glorious Plunge (Used to enter Forte and
                    become airborne)
                  </li>
                  <li>Basic Attack: Feral Gyrate P1</li>
                  <li>Skill: Mountain Roamer</li>
                  <li>Basic Attack: Feral Gyrate P2</li>
                  <li>Skill: Mountain Roamer</li>
                  <li>Basic Attack: Feral Gyrate P1</li>
                  <li>Skill: Mountain Roamer</li>
                  <li>Basic Attack: Feral Gyrate P2</li>
                  <li>Skill: Mountain Roamer</li>
                  <li>Basic Attack: Feral Gyrate P1</li>
                  <li>Basic Attack: Stormy Kicks</li>
                  <li>Mid-Air Attack: Tail Strike</li>
                  <li>Outro</li>
                </ul>
              </blockquote>
            </>
          )}
          {slug === 'yinlin' && (
            <>
              <blockquote>
                <h6>OPENER ROTATION</h6>
                <p>
                  Yinlin’s utmost priority in battle is to apply the Punishment
                  Mark as quickly and on as many enemies as possible. To do the
                  same, she needs to build her Forte Gauge, her Intro being the
                  best tool for it, but unfortunately not always accessible
                  (especially at the start of ToA run). As such, you might
                  consider using the following rotation to quickly build up her
                  initial Forte Gauge before your team gets access to Intro’s.
                  <strong>
                    NOTE: This rotation should only be utilized when her Intro
                    is unavailable in a timely manner, which is usually limited
                    to the start of ToA runs.
                  </strong>{' '}
                </p>
                <ul>
                  <li>Ultimate (Should be full in ToA Runs from the start)</li>
                  <li>Skill: Magnetic Roar</li>
                  <li>Skill: Lightning Execution (Swap Cancel)</li>
                  <li>Heavy Attack</li>
                  <li>Heavy Attack</li>
                  <li>Chameleon Cipher (Swap Cancel)</li>
                </ul>
              </blockquote>
              <blockquote>
                <h6>STANDARD ROTATION</h6>
                <p>
                  This rotation ensures you have 100% uptime of Punishment Mark
                  on primary target by generating Forte to access Chameleon
                  Cipher within 18 seconds (it’s duration) each time:
                </p>
                <ul>
                  <li>
                    This and most Yinlin rotations make ample use of
                    Swap-Cancels, which improves both her damage and
                    survivability. A large part of her abilities have extremely
                    long animations, which causes damage loss and chance to hit
                    and stunned by enemies if kept on field.
                  </li>
                  <li>
                    When Swapping in Yinlin without her Intro active, she will
                    begin her Basic Attack Sequence from Part 2 instead of Part
                    1. Her Intro, both parts of her Resonance Skill and 3 Basic
                    Attacks are ample to fill her Forte Gauge. While there are
                    many ways to build it, this is what we found to be easiest
                    and safest without utilizing her Intro.
                  </li>
                  <li>
                    When Swapped out, all Sinner’s Mark applied by Yinline are
                    removed from enemies. When activating her Forte: Chameleon
                    Cipher, she can only inflict Punishment Mark to enemies
                    inflicted with Sinner’s Mark beforehand, making it
                    imperative to re-apply them to high priority enemies before
                    using her Forte Skill.
                  </li>
                  <li>
                    Using Yinlin’s Ultimate just before her Forte skill is a
                    quickfire way to widely apply Sinner’s Mark to enemies.
                  </li>
                  <li>
                    Her Forte: Chameleon Cipher can be charged during other
                    skill animations, including her Ultimate. By holding Left
                    Mouse Button (or your Heavy Attack equivalent), you can skip
                    the charge time by performing Basic Attack 1 before ability
                    activation to save a lot of time.
                  </li>
                  <li>
                    All abilities we listed as Swap-Cancel, can be immediately
                    done from the very start of their animations without any
                    wait.
                  </li>
                </ul>
                <br />
                <p>And here's the rotation:</p>
                <ul>
                  <li>Echo (Swap-Cancel)</li>
                  <li>Intro</li>
                  <li>Skill: Magnetic Roar</li>
                  <li>Skill: Lightning Execution (Swap-Cancel)</li>
                  <li>Basic P2</li>
                  <li>Basic P3 </li>
                  <li>Basic P4 (Swap-Cancel)</li>
                  <li>Ultimate</li>
                  <li>Chameleon Cipher (Swap-Cancel)</li>
                </ul>
              </blockquote>
              <blockquote>
                <h6>EASY/EMERGENCY MODE ROTATION</h6>
                <p>
                  In case you fail to grant Yinlin an Intro in midst of combat,
                  fail to perform Basic Attacks or have to utilize more field
                  time on other team mates, you may find her Skill be off
                  cooldown before fully generating your Forte Gauge. If this
                  happens and your Forte is still not close to completion, you
                  can use her Skill again, executing a rotation like one listed
                  below. However, using 2 sets of Skills for 1 Forte Gauge will
                  result in Punishment Mark downtime as skill has a cooldown of
                  12 seconds, whereas Punishment Mark lasts for 18 seconds,
                  causing a 6 seconds downtime on two sets of Skill.
                </p>
                <ul>
                  <li>Echo (Swap-Cancel)</li>
                  <li>Intro</li>
                  <li>Skill: Magnetic Roar</li>
                  <li>Skill: Lightning Execution (Swap-Cancel)</li>
                  <li>Skill: Magnetic Roar</li>
                  <li>Skill: Lightning Execution (Swap-Cancel)</li>
                  <li>Ultimate</li>
                  <li>Chameleon Cipher (Swap-Cancel)</li>
                </ul>
              </blockquote>
            </>
          )}
        </div>
      </>
    </>
  );
};
