export const PFCharacterData = [
  {
    char: 'acheron',
    name: 'Acheron',
    current_app_rate: 45.08,
    prev_app_rate: 54.33,
    current_avg_score: 33640,
    prev_avg_score: 34487,
    round_eidolons: 38447,
    rarity: 'Limited 5*'
  },
  {
    char: 'argenti',
    name: 'Argenti',
    current_app_rate: 5.8,
    prev_app_rate: 1.64,
    current_avg_score: 38218,
    prev_avg_score: 25539,
    round_eidolons: 39920,
    rarity: 'Limited 5*'
  },
  {
    char: 'arlan',
    name: 'Arlan',
    current_app_rate: 0.03,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'asta',
    name: 'Asta',
    current_app_rate: 0.48,
    prev_app_rate: 1.23,
    current_avg_score: 31637,
    prev_avg_score: 28447,
    round_eidolons: 34205,
    rarity: '4*'
  },
  {
    char: 'aventurine',
    name: 'Aventurine',
    current_app_rate: 53.97,
    prev_app_rate: 39.05,
    current_avg_score: 33788,
    prev_avg_score: 34033,
    round_eidolons: 38550,
    rarity: 'Limited 5*'
  },
  {
    char: 'bailu',
    name: 'Bailu',
    current_app_rate: 0.87,
    prev_app_rate: 0.87,
    current_avg_score: 30633,
    prev_avg_score: 29547,
    round_eidolons: 34589,
    rarity: 'Standard 5*'
  },
  {
    char: 'black-swan',
    name: 'Black Swan',
    current_app_rate: 16.77,
    prev_app_rate: 59.78,
    current_avg_score: 32381,
    prev_avg_score: 35164,
    round_eidolons: 35426,
    rarity: 'Limited 5*'
  },
  {
    char: 'blade',
    name: 'Blade',
    current_app_rate: 0.42,
    prev_app_rate: 2.25,
    current_avg_score: 27406,
    prev_avg_score: 27272,
    round_eidolons: 33397,
    rarity: 'Limited 5*'
  },
  {
    char: 'boothill',
    name: 'Boothill',
    current_app_rate: 0.17,
    prev_app_rate: 0.22,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 34148,
    rarity: 'Limited 5*'
  },
  {
    char: 'bronya',
    name: 'Bronya',
    current_app_rate: 5.15,
    prev_app_rate: 3.4,
    current_avg_score: 32834,
    prev_avg_score: 27151,
    round_eidolons: 39391,
    rarity: 'Standard 5*'
  },
  {
    char: 'clara',
    name: 'Clara',
    current_app_rate: 3.41,
    prev_app_rate: 4.72,
    current_avg_score: 30826,
    prev_avg_score: 29853,
    round_eidolons: 33467,
    rarity: 'Standard 5*'
  },
  {
    char: 'dan-heng',
    name: 'Dan Heng',
    current_app_rate: 0,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'dr-ratio',
    name: 'Dr. Ratio',
    current_app_rate: 0.14,
    prev_app_rate: 0.08,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 27593,
    rarity: 'Limited 5*'
  },
  {
    char: 'feixiao',
    name: 'Feixiao',
    current_app_rate: 4.88,
    prev_app_rate: 10.95,
    current_avg_score: 31121,
    prev_avg_score: 30907,
    round_eidolons: 35684,
    rarity: 'Limited 5*'
  },
  {
    char: 'firefly',
    name: 'Firefly',
    current_app_rate: 29.76,
    prev_app_rate: 45.37,
    current_avg_score: 29791,
    prev_avg_score: 27888,
    round_eidolons: 38978,
    rarity: 'Limited 5*'
  },
  {
    char: 'fu-xuan',
    name: 'Fu Xuan',
    current_app_rate: 23.74,
    prev_app_rate: 20.63,
    current_avg_score: 32155,
    prev_avg_score: 32425,
    round_eidolons: 35574,
    rarity: 'Limited 5*'
  },
  {
    char: 'tingyun-fugue',
    name: 'Fugue',
    current_app_rate: 19.81,
    prev_app_rate: 19.81,
    current_avg_score: 37602,
    prev_avg_score: 37602,
    round_eidolons: 40000,
    rarity: 'Limited 5*',
    new: true
  },
  {
    char: 'gallagher',
    name: 'Gallagher',
    current_app_rate: 38.01,
    prev_app_rate: 46.2,
    current_avg_score: 32532,
    prev_avg_score: 29530,
    round_eidolons: 35989,
    rarity: '4*'
  },
  {
    char: 'gepard',
    name: 'Gepard',
    current_app_rate: 1.52,
    prev_app_rate: 2.52,
    current_avg_score: 31514,
    prev_avg_score: 33216,
    round_eidolons: 37231,
    rarity: 'Standard 5*'
  },
  {
    char: 'guinaifen',
    name: 'Guinaifen',
    current_app_rate: 0.71,
    prev_app_rate: 2.99,
    current_avg_score: 28675,
    prev_avg_score: 28191,
    round_eidolons: 31081,
    rarity: '4*'
  },
  {
    char: 'hanya',
    name: 'Hanya',
    current_app_rate: 0.11,
    prev_app_rate: 0.08,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'herta',
    name: 'Herta',
    current_app_rate: 62.33,
    prev_app_rate: 12.42,
    current_avg_score: 33458,
    prev_avg_score: 31597,
    round_eidolons: 37685,
    rarity: '4*'
  },
  {
    char: 'himeko',
    name: 'Himeko',
    current_app_rate: 54.96,
    prev_app_rate: 31.24,
    current_avg_score: 32731,
    prev_avg_score: 28900,
    round_eidolons: 35889,
    rarity: 'Standard 5*'
  },
  {
    char: 'hook',
    name: 'Hook',
    current_app_rate: 0,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'huohuo',
    name: 'Huohuo',
    current_app_rate: 22.67,
    prev_app_rate: 30.62,
    current_avg_score: 33540,
    prev_avg_score: 33369,
    round_eidolons: 37557,
    rarity: 'Limited 5*'
  },
  {
    char: 'imbibitor-lunae',
    name: 'Imbibitor Lunae',
    current_app_rate: 0.71,
    prev_app_rate: 1.06,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 30933,
    rarity: 'Limited 5*'
  },
  {
    char: 'jade',
    name: 'Jade',
    current_app_rate: 16.07,
    prev_app_rate: 8.58,
    current_avg_score: 37800,
    prev_avg_score: 31437,
    round_eidolons: 39881,
    rarity: 'Limited 5*'
  },
  {
    char: 'jiaoqiu',
    name: 'Jiaoqiu',
    current_app_rate: 23.62,
    prev_app_rate: 30.65,
    current_avg_score: 35816,
    prev_avg_score: 35433,
    round_eidolons: 39994,
    rarity: 'Limited 5*'
  },
  {
    char: 'jing-yuan',
    name: 'Jing Yuan',
    current_app_rate: 21.79,
    prev_app_rate: 1.37,
    current_avg_score: 34902,
    prev_avg_score: 24413,
    round_eidolons: 39234,
    rarity: 'Limited 5*'
  },
  {
    char: 'jingliu',
    name: 'Jingliu',
    current_app_rate: 0.95,
    prev_app_rate: 0.11,
    current_avg_score: 26846,
    prev_avg_score: 0,
    round_eidolons: 30631,
    rarity: 'Limited 5*'
  },
  {
    char: 'kafka',
    name: 'Kafka',
    current_app_rate: 17.11,
    prev_app_rate: 58.04,
    current_avg_score: 32301,
    prev_avg_score: 34773,
    round_eidolons: 35212,
    rarity: 'Limited 5*'
  },
  {
    char: 'lingsha',
    name: 'Lingsha',
    current_app_rate: 32.3,
    prev_app_rate: 37.81,
    current_avg_score: 35299,
    prev_avg_score: 32791,
    round_eidolons: 39845,
    rarity: 'Limited 5*'
  },
  {
    char: 'luka',
    name: 'Luka',
    current_app_rate: 0,
    prev_app_rate: 0.02,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'luocha',
    name: 'Luocha',
    current_app_rate: 9.06,
    prev_app_rate: 10.96,
    current_avg_score: 32672,
    prev_avg_score: 31592,
    round_eidolons: 37116,
    rarity: 'Limited 5*'
  },
  {
    char: 'lynx',
    name: 'Lynx',
    current_app_rate: 0.31,
    prev_app_rate: 0.89,
    current_avg_score: 31027,
    prev_avg_score: 30803,
    round_eidolons: 33769,
    rarity: '4*'
  },
  {
    char: 'march-7th',
    name: 'March 7th',
    current_app_rate: 0.14,
    prev_app_rate: 0.56,
    current_avg_score: 0,
    prev_avg_score: 32972,
    round_eidolons: 34508,
    rarity: '4*'
  },
  {
    char: 'march-7th-swordmaster',
    name: 'March 7th - The Hunt',
    current_app_rate: 1.51,
    prev_app_rate: 2.87,
    current_avg_score: 28658,
    prev_avg_score: 27240,
    round_eidolons: 32512,
    rarity: '4*'
  },
  {
    char: 'misha',
    name: 'Misha',
    current_app_rate: 0.03,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'moze',
    name: 'Moze',
    current_app_rate: 0.2,
    prev_app_rate: 0.03,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 31262,
    rarity: '4*'
  },
  {
    char: 'natasha',
    name: 'Natasha',
    current_app_rate: 0.1,
    prev_app_rate: 0.08,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'pela',
    name: 'Pela',
    current_app_rate: 15.32,
    prev_app_rate: 9.72,
    current_avg_score: 33065,
    prev_avg_score: 32581,
    round_eidolons: 35275,
    rarity: '4*'
  },
  {
    char: 'qingque',
    name: 'Qingque',
    current_app_rate: 0.05,
    prev_app_rate: 0.02,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'rappa',
    name: 'Rappa',
    current_app_rate: 16.07,
    prev_app_rate: 23.22,
    current_avg_score: 35215,
    prev_avg_score: 33565,
    round_eidolons: 39275,
    rarity: 'Limited 5*'
  },
  {
    char: 'robin',
    name: 'Robin',
    current_app_rate: 58,
    prev_app_rate: 44.38,
    current_avg_score: 34133,
    prev_avg_score: 31895,
    round_eidolons: 38896,
    rarity: 'Limited 5*'
  },
  {
    char: 'ruan-mei',
    name: 'Ruan Mei',
    current_app_rate: 75.82,
    prev_app_rate: 89.21,
    current_avg_score: 33561,
    prev_avg_score: 31010,
    round_eidolons: 38750,
    rarity: 'Limited 5*'
  },
  {
    char: 'sampo',
    name: 'Sampo',
    current_app_rate: 0.12,
    prev_app_rate: 3.13,
    current_avg_score: 0,
    prev_avg_score: 28793,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'seele',
    name: 'Seele',
    current_app_rate: 0.32,
    prev_app_rate: 0.29,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 33232,
    rarity: 'Limited 5*'
  },
  {
    char: 'serval',
    name: 'Serval',
    current_app_rate: 0.69,
    prev_app_rate: 0.48,
    current_avg_score: 29935,
    prev_avg_score: 28413,
    round_eidolons: 33137,
    rarity: '4*'
  },
  {
    char: 'silver-wolf',
    name: 'Silver Wolf',
    current_app_rate: 2.03,
    prev_app_rate: 1.12,
    current_avg_score: 27984,
    prev_avg_score: 28070,
    round_eidolons: 32898,
    rarity: 'Limited 5*'
  },
  {
    char: 'sparkle',
    name: 'Sparkle',
    current_app_rate: 19.94,
    prev_app_rate: 15.88,
    current_avg_score: 32389,
    prev_avg_score: 28726,
    round_eidolons: 38620,
    rarity: 'Limited 5*'
  },
  {
    char: 'sunday',
    name: 'Sunday',
    current_app_rate: 38.05,
    prev_app_rate: 38.05,
    current_avg_score: 34902,
    prev_avg_score: 34902,
    round_eidolons: 39253,
    rarity: 'Limited 5*'
  },
  {
    char: 'sushang',
    name: 'Sushang',
    current_app_rate: 0,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'tingyun',
    name: 'Tingyun',
    current_app_rate: 7.9,
    prev_app_rate: 5.52,
    current_avg_score: 33902,
    prev_avg_score: 27812,
    round_eidolons: 37606,
    rarity: '4*'
  },
  {
    char: 'topaz',
    name: 'Topaz & Numby',
    current_app_rate: 2.65,
    prev_app_rate: 3.1,
    current_avg_score: 27852,
    prev_avg_score: 25679,
    round_eidolons: 33566,
    rarity: 'Limited 5*'
  },
  {
    char: 'trailblazer-destruction',
    name: 'Trailblazer - Destruction',
    current_app_rate: 0,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'trailblazer-preservation',
    name: 'Trailblazer - Preservation',
    current_app_rate: 0.1,
    prev_app_rate: 0.12,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 38854,
    rarity: '4*'
  },
  {
    char: 'trailblazer-harmony',
    name: 'Trailblazer - Harmony',
    current_app_rate: 41.09,
    prev_app_rate: 72.45,
    current_avg_score: 32676,
    prev_avg_score: 30119,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'welt',
    name: 'Welt',
    current_app_rate: 1.05,
    prev_app_rate: 0.26,
    current_avg_score: 27670,
    prev_avg_score: 26276,
    round_eidolons: 32279,
    rarity: 'Standard 5*'
  },
  {
    char: 'xueyi',
    name: 'Xueyi',
    current_app_rate: 0.08,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'yanqing',
    name: 'Yanqing',
    current_app_rate: 0.01,
    prev_app_rate: 0.01,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: 'Standard 5*'
  },
  {
    char: 'yukong',
    name: 'Yukong',
    current_app_rate: 0.02,
    prev_app_rate: 0.01,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'yunli',
    name: 'Yunli',
    current_app_rate: 5.9,
    prev_app_rate: 7.36,
    current_avg_score: 33653,
    prev_avg_score: 32020,
    round_eidolons: 38565,
    rarity: 'Limited 5*'
  }
];
