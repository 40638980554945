/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { Row, Col, Tab, Tabs } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';

const AFKGuidesDreamAlpha: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Dream Realm - Alpha Bear</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_bear.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Alpha Bear</h1>
          <h2>
            Dream Realm Alpha Bear guide that explains the boss mechanics and
            how to beat it!
          </h2>
          <p>
            Last updated: <strong>11/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Alpha Bear " />
        <StaticImage
          src="../../../images/afk/generic/dream_bear.webp"
          alt="Guides"
        />
        <p>
          <strong>Alpha Bear</strong> is one of the 8 available Dream Realm
          bosses you will encounter in AFK Journey. In this guide we will go
          over the boss mechanics and the best characters and teams to use
          against him.
        </p>
        <SectionHeader title="What’s the meta team?" />
        <p>
          Realistically speaking,{' '}
          <strong>
            your meta team will depend on your specific box, and your account
            focus
          </strong>
          , so use your highest rarity DPS carry characters, along with whoever
          can best boost their damage.{' '}
          <strong className="gold">
            Try running Dual carry, as currently there is no way to boost a
            Hypercarry’s damage high enough to make it worth running only one
          </strong>
          . Keep faction bonus in mind but don’t run suboptimal characters just
          to force it.
        </p>
        <p>
          Still, below you can find a few example teams. Big thanks to{' '}
          <StaticImage
            src="../../../images/afk/icons/afkanalytica.png"
            alt="AFK Analytica"
          />{' '}
          <strong className="yellow">AFK Analytica</strong> for the team
          building tool. You can find their website{' '}
          <a
            href="https://www.afkanalytica.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and their discord{' '}
          <a
            href="https://www.discord.gg/analytica"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <SectionHeader title="Season 2 Teams (Endless)" />
        <p>
          Alpha Bear Dream Realm boss was available in Season 2 and he was
          removed in Season 3 from the rotation.
        </p>
        <SectionHeader title="Boss Mechanics" />
        <p>
          The Dream Realm version of the Alpha Bear is basically a copy of the
          fight we know from the Primal Lord event. Here are the boss skills:
        </p>
        <h5>Skills</h5>
        <ul>
          <li>
            <strong>Ultimate</strong> - Summons a swarm that deals 80% damage
            per second to each enemy covered in honey for 3s. When all enemy
            heroes are marked with honey, a mega swarm is summoned, dealing 250%
            damage per second till the end of the battle.
          </li>
          <li>
            <strong>Skill 1</strong> - Throws honey at an enemy hero, marking
            them until the end of the battle and dealing 100% damage. The
            healing received by the marked enemy is reduced by 80% and cannot be
            dispelled.
          </li>
          <li>
            <strong>Skill 2</strong> - Throws honey at enemies in adjacent
            tiles, dealing 50% damage and marking them. Smashes the ground when
            there are no enemies in adjacent tiles, dealing 400% damage to all
            enemies.
          </li>
          <li>
            <strong>Skill 3</strong> - Makes and pulls a Terraball towards the
            enemy, dealing 100% damage to all enemies in its path.
          </li>
        </ul>
        <SectionHeader title="Tips and tricks" />
        <h5>Tips and tricks</h5>
        <ul>
          <li>
            <strong>
              Use a single character with 1 Range, who will 'tank' the boss.
            </strong>{' '}
            That's because of the Skill 2 as you want prevent the Bear from
            using his massive AoE - so you always need someone 'hugging' the
            boss,
          </li>
          <li>
            Avoid using damage dealers with the Range of 1 as they will have
            issues surviving close to the boss,
          </li>
          <li>
            Because the boss can reduce the healing received by an ally,
            characters that provide Shields help a lot,
          </li>
          <li>
            Summoners allow you to 'bypass' the boss Honey mechanic as you will
            have more 'bodies' before he will wipe the team,
          </li>
          <li>
            Consider using{' '}
            <strong>damage dealers who can keep themselves alive</strong> and
            provide additional utility to the team.
          </li>
        </ul>
        <SectionHeader title="Videos" />
        <p>For more team idea, check this video:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="lxii5CAhxLk" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesDreamAlpha;

export const Head: React.FC = () => (
  <Seo
    title="Alpha Bear | AFK Journey | Prydwen Institute"
    description="Dream Realm Alpha Bear guide that explains the boss mechanics and how to beat it!"
    game="afk"
  />
);
