import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { Row, Col } from 'react-bootstrap';
import { HSRLightCone } from '../../../modules/hsr/common/components/hsr-light-cone';

const HSRGuidesFHPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page hsr-dps-rankings'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Best Battle Pass Light Cone</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_bpcone.png"
            alt="Best Battle Pass Light Cone"
          />
        </div>
        <div className="page-details">
          <h1>Best Battle Pass Light Cone</h1>
          <h2>
            A guide for picking the best Light Cone in the Battle Pass available
            in Honkai: Star Rail.
          </h2>
          <p>
            Last updated: <strong>21/01/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          Every patch, you can only purchase the Battle Pass and obtain 1 Light
          Cone once so the choice is pretty important since it will also take
          around 5 months for reach max Superimposition on the Light Cones. Out
          of all the Light Cones, there are only 2 that are worth getting, with
          2 of them being for DPS units following the Path of Erudition and
          Remembrance. It is recommended to first obtain 1 copy of these if you
          plan on using Erudition/Remembrance characters without their signature
          Light Cone.
        </p>
        <SectionHeader title="Which Light Cone to pick?" />
        <Row xs={1} xxl={2} className="relic-set-container">
          <Col>
            <HSRLightCone mode="card" slug="today-is-another-peaceful-day" />
          </Col>
          <Col>
            <HSRLightCone mode="card" slug="sweat-now-cry-less" />
          </Col>
        </Row>
        <p>
          From the eight available Light Cones, 2 of them are worth picking up
          if you plan on using characters following these Paths:
        </p>
        <ul>
          <li>
            <strong>Today Is Another Peaceful Day (Erudition)</strong> - this
            Light Cone is one of the strongest in the Erudition category, so if
            you use the characters from this path, you will for sure not regret
            picking it.
          </li>
          <li>
            <strong>Sweat Now, Cry Less (Remembrance)</strong> - Also the
            strongest Light Cone for DPS Remembrance characters after their
            Signature Light Cones.
          </li>
          <ul>
            <li>
              Sweat Now, Cry Less is an excellent Light Cone for DPS units
              thanks to its high base stats and strong permanent buffs, only
              requiring the wearer’s memosprites to be on the field. Currently,
              it stands to be the second best Light Cone at max Superimposition
              for Remembrance DPS after their Signature Light Cone since there
              aren’t that many Light Cones available, but things can change
              after more Remembrance Light Cones are released. Keep in mind that
              the Remembrance path contains all types of units so long as they
              have memosprites, we may get DoT or Break oriented characters that
              this Light Cone’s effect may not work with.
            </li>
          </ul>
        </ul>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesFHPage;

export const Head: React.FC = () => (
  <Seo
    title="Best Battle Pass Light Cone | Honkai: Star Rail | Prydwen Institute"
    description="A guide for picking the best Light Cone in the Battle Pass available in Honkai: Star Rail."
  />
);
