import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const ExiliumGuidesIntroPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page exilium-page'} game="exilium">
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/gfl-exilium/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Introduction to the game</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/exilium/categories/category_intro.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Introduction to the game</h1>
          <h2>
            Basic information about the Girls' Frontline 2: Exilium gacha game.
          </h2>
          <p>
            Last updated: <strong>11/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="About the game" />
        <p>
          Girls' Frontline 2: Exilium is a turn-based tactics gacha game
          developed by Sunborn. The gameplay in GFL 2: Exilium is very unique
          when it comes to gacha games as here you control the team and traverse
          a grid-based map where you have to take into account the tactical
          implications of elevation, hard cover and line of sight. If you ever
          played the game XCOM, then GFL2 is basically that gameplay-wise.
        </p>
        <SectionHeader title="Official links" />
        <ul>
          <li>
            Official Website (CN):{' '}
            <a
              href="https://gf2.sunborngame.com/index"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official Community (CN):{' '}
            <a
              href="https://space.bilibili.com/697654195/dynamic"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official Website (Global):{' '}
            <a
              href="https://gf2exilium.sunborngame.com/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official YouTube (Global):{' '}
            <a
              href="https://www.youtube.com/@GFL2EXILIUM"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Google Play Store (Global):{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.Sunborn.SnqxExilium.Glo"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Apple Store (Global):{' '}
            <a
              href="https://apps.apple.com/us/app/girls-frontline-2-exilium/id6502505286"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
        </ul>
        <SectionHeader title="Official Images" />
        <Row xs={1} xl={2}>
          <Col>
            <StaticImage src="../../../images/exilium/promo.jpg" alt="Guides" />
          </Col>
          <Col>
            <StaticImage
              src="../../../images/exilium/promo2.jpg"
              alt="Guides"
            />
          </Col>
          <Col>
            <StaticImage
              src="../../../images/exilium/promo3.jpg"
              alt="Guides"
            />
          </Col>
          <Col>
            <StaticImage
              src="../../../images/exilium/promo4.jpg"
              alt="Guides"
            />
          </Col>
        </Row>
        <SectionHeader title="Video" />
        <p>If you want to check the gameplay for the game, go here:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="gm4xL2YVlQ8" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default ExiliumGuidesIntroPage;

export const Head: React.FC = () => (
  <Seo
    title="Introduction to the game | Girls' Frontline 2: Exilium | Prydwen Institute"
    description="Basic information about the Girls' Frontline 2: Exilium gacha game."
    game="exilium"
  />
);
