export const Shiyu6_1 = [
  {
    char_one: 'Miyabi',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 15.15,
    rank: 1,
    avg_round: 75,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Rina',
    app_rate: 14.28,
    rank: 2,
    avg_round: 75,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Soukaku',
    app_rate: 9.21,
    rank: 3,
    avg_round: 85,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Caesar',
    app_rate: 5.82,
    rank: 4,
    avg_round: 71,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 5.43,
    rank: 5,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 4.6,
    rank: 6,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 4.21,
    rank: 7,
    avg_round: 73,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 3.15,
    rank: 8,
    avg_round: 66,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Lucy',
    app_rate: 2.24,
    rank: 9,
    avg_round: 75,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'nicole-demara',
    app_rate: 1.67,
    rank: 10,
    avg_round: 78,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 1.48,
    rank: 11,
    avg_round: 83,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 1.38,
    rank: 12,
    avg_round: 86,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 1.22,
    rank: 13,
    avg_round: 132,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Lycaon',
    app_rate: 1.1,
    rank: 14,
    avg_round: 82,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lighter',
    char_three: 'Soukaku',
    app_rate: 1.06,
    rank: 15,
    avg_round: 127,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Burnice',
    char_three: 'Soukaku',
    app_rate: 0.92,
    rank: 16,
    avg_round: 79,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Lighter',
    app_rate: 0.87,
    rank: 17,
    avg_round: 112,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.85,
    rank: 18,
    avg_round: 149,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.83,
    rank: 19,
    avg_round: 85,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.81,
    rank: 20,
    avg_round: 85,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 0.77,
    rank: 21,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.69,
    rank: 22,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Ellen',
    char_three: 'Soukaku',
    app_rate: 0.67,
    rank: 23,
    avg_round: 100,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.67,
    rank: 23,
    avg_round: 94,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.59,
    rank: 25,
    avg_round: 87,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.57,
    rank: 26,
    avg_round: 72,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Caesar',
    app_rate: 0.53,
    rank: 27,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'Soukaku',
    app_rate: 0.53,
    rank: 27,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Seth',
    app_rate: 0.51,
    rank: 29,
    avg_round: 101,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lighter',
    char_three: 'Soukaku',
    app_rate: 0.49,
    rank: 30,
    avg_round: 110,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Burnice',
    app_rate: 0.49,
    rank: 30,
    avg_round: 75,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.49,
    rank: 30,
    avg_round: 86,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'Yanagi',
    app_rate: 0.47,
    rank: 33,
    avg_round: 90,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Miyabi',
    char_three: 'nicole-demara',
    app_rate: 0.45,
    rank: 34,
    avg_round: 94,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'grace-howard',
    char_three: 'Soukaku',
    app_rate: 0.45,
    rank: 34,
    avg_round: 99,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Piper',
    char_three: 'Soukaku',
    app_rate: 0.43,
    rank: 36,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.39,
    rank: 37,
    avg_round: 78,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.33,
    rank: 38,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Qingyi',
    app_rate: 0.31,
    rank: 39,
    avg_round: 91,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.28,
    rank: 40,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.28,
    rank: 40,
    avg_round: 102,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 0.26,
    rank: 42,
    avg_round: 99,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.26,
    rank: 42,
    avg_round: 64,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.26,
    rank: 42,
    avg_round: 126,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.26,
    rank: 42,
    avg_round: 102,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.24,
    rank: 46,
    avg_round: 91,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.24,
    rank: 46,
    avg_round: 184,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.2,
    rank: 48,
    avg_round: 154,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Lighter',
    app_rate: 0.2,
    rank: 48,
    avg_round: 104,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'Qingyi',
    app_rate: 0.18,
    rank: 50,
    avg_round: 104,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Ellen',
    char_three: 'Lycaon',
    app_rate: 0.18,
    rank: 50,
    avg_round: 124,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.16,
    rank: 52,
    avg_round: 96,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.16,
    rank: 52,
    avg_round: 74,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.16,
    rank: 52,
    avg_round: 173,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.16,
    rank: 52,
    avg_round: 93,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 0.16,
    rank: 52,
    avg_round: 128,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Soukaku',
    char_three: 'Seth',
    app_rate: 0.16,
    rank: 52,
    avg_round: 100,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'Rina',
    app_rate: 0.14,
    rank: 58,
    avg_round: 91,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.14,
    rank: 58,
    avg_round: 68,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Miyabi',
    char_three: 'Soukaku',
    app_rate: 0.14,
    rank: 58,
    avg_round: 105,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 58,
    avg_round: 137,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 58,
    avg_round: 145,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lighter',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 63,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 63,
    avg_round: 117,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 63,
    avg_round: 126,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.12,
    rank: 63,
    avg_round: 96,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.12,
    rank: 63,
    avg_round: 86,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.1,
    rank: 68,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 68,
    avg_round: 195,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.1,
    rank: 68,
    avg_round: 96,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.1,
    rank: 68,
    avg_round: 74,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Burnice',
    char_three: 'Rina',
    app_rate: 0.1,
    rank: 68,
    avg_round: 79,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.1,
    rank: 68,
    avg_round: 189,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'grace-howard',
    app_rate: 0.08,
    rank: 74,
    avg_round: 144,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 74,
    avg_round: 160,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'grace-howard',
    app_rate: 0.08,
    rank: 74,
    avg_round: 106,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 74,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'Lighter',
    app_rate: 0.08,
    rank: 74,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 74,
    avg_round: 118,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Ellen',
    char_three: 'Yanagi',
    app_rate: 0.08,
    rank: 74,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 74,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lycaon',
    char_three: 'Lighter',
    app_rate: 0.06,
    rank: 82,
    avg_round: 112,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 82,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 82,
    avg_round: 182,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 82,
    avg_round: 83,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'anby-demara',
    app_rate: 0.06,
    rank: 82,
    avg_round: 106,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Ellen',
    char_three: 'Harumasa',
    app_rate: 0.06,
    rank: 82,
    avg_round: 117,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 82,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Ellen',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 82,
    avg_round: 116,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lighter',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 82,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 82,
    avg_round: 315,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 82,
    avg_round: 139,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 82,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Koleda',
    app_rate: 0.04,
    rank: 94,
    avg_round: 43,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 94,
    avg_round: 122,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 94,
    avg_round: 145,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.04,
    rank: 94,
    avg_round: 104,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lycaon',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 94,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 94,
    avg_round: 86,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 94,
    avg_round: 140,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Ellen',
    char_three: 'Lighter',
    app_rate: 0.04,
    rank: 94,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lighter',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 94,
    avg_round: 89,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Ellen',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 94,
    avg_round: 90,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 94,
    avg_round: 257,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 94,
    avg_round: 158,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Burnice',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 94,
    avg_round: 70,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 94,
    avg_round: 195,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'Lycaon',
    app_rate: 0.04,
    rank: 94,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'jane-doe',
    char_three: 'Soukaku',
    app_rate: 0.04,
    rank: 94,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 94,
    avg_round: 176,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 94,
    avg_round: 51,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 94,
    avg_round: 142,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 94,
    avg_round: 157,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Ellen',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 94,
    avg_round: 99,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Ellen',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 94,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'Harumasa',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 94,
    avg_round: 146,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Burnice',
    char_three: 'Koleda',
    app_rate: 0.04,
    rank: 94,
    avg_round: 89,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lycaon',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 94,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 94,
    avg_round: 99,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 94,
    avg_round: 142,
    star_num: '4'
  }
];
