import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import './hsr-guides.scss';
import { HSRCharacter } from '../../../modules/hsr/common/components/hsr-character';
import { Col, Row } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const HSRRerollPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page hsr-guide'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Team Archetypes</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_team_archetypes.webp"
            alt="Team Archetypes"
          />
        </div>
        <div className="page-details">
          <h1>Team Archetypes</h1>
          <h2>Common Team Archetypes in Honkai Star Rail explained!</h2>
          <p>
            Last updated: <strong>18/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Overview" />
        <p>
          Teams in Honkai: Star Rail typically fall into an archetype that
          defines their playstyle in combat and serves as excellent templates
          when building your teams. From the classic beatdown playstyle to a
          more controlling one, these archetypes are generally categorized by
          the combat mechanic that they specialize in and rely on to dish out
          their damage.
        </p>
        <p>Below are the most common team archetypes in the game.</p>
        <SectionHeader title="Crit Archetype" />
        <p>
          The traditional RPG archetype that focuses on landing big critical
          hits to take down the enemy. A Crit archetype team will typically use
          one main damage dealer backed up by offensive damage supports as the
          core of the team. Some teams will opt to replace one of the supports
          with a secondary damage dealer if that Character has better synergy
          with the main damage dealer.
        </p>
        <p>
          The game plan for this archetype is to stack as many offensive stats
          that enhance the damage dealer’s critical hits as much as possible.
          Once buffed up, the damage dealer can then deal out large critical
          hits to quickly take down the enemy. This process is done both through
          the damage dealer’s gear and during battle with the buffs or debuffs
          from the team’s support Characters.
        </p>
        <p>Notable Crit archetype characters:</p>
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="acheron" enablePopover />
          <HSRCharacter mode="icon" slug="imbibitor-lunae" enablePopover />
          <HSRCharacter mode="icon" slug="the-herta" enablePopover />
          <HSRCharacter mode="icon" slug="bronya" enablePopover />
          <HSRCharacter mode="icon" slug="sparkle" enablePopover />
          <HSRCharacter mode="icon" slug="sunday" enablePopover />
        </div>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Flexible due having the broadest selection of Characters
                    that can be used among the archetypes listed here.
                  </li>
                  <li>
                    Only need to heavily invest in one Character, the damage
                    dealer, as the supports can get by with just building Speed
                    and defensive stats. This is a double-edged sword as it
                    means that the gear on your damage dealer can and most often
                    will make or break the team.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Most or all of the damage is concentrated on one Character.
                    If that Character is knocked down or gets crowd controlled,
                    then the team essentially loses all of its damage.
                  </li>
                  <li>
                    Requires relatively heavy relic investment into the damage
                    dealer due to the stat requirements.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <p>Here's a video showcasing this archetype in action:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="cD_tMM-5QOE" />
          </Col>
        </Row>
        <SectionHeader title="Break Archetype" />
        <p>
          This archetype focuses on breaking the enemy’s Toughness bar as
          quickly and as often as possible in order to inflict massive break
          damage to the enemy. To accomplish this goal, teams in this archetype
          will utilize Characters with skills that deal high Toughness damage or
          increase the Toughness damage their teammates can deal. Examples
          include random target skills like Asta’s or the Harmony Trailblazer’s
          Skill and skills that buff the team’s Weakness Efficiency like Ruan
          Mei’s Skill. Random target skills can hit the same target multiple
          times with each hit dealing its own instance of Toughness damage.
          Weakness Efficiency is a buff that increases the Toughness damage that
          a Character’s attacks will do. These types of abilities quicken the
          process of breaking enemies, making them ideal for Break teams.
        </p>
        <p>
          Break teams will also try to stack as much Break Effect and Speed as
          possible since these are the two most relevant stats for this
          archetype. Break Effect is the primary stat that is used to calculate
          how much break damage the enemy takes. Other offensive stats such as
          ATK and Crit DMG do not increase break damage, so they are not needed
          for Break teams. Speed, on the other hand, provides an indirect boost
          to break damage as high speed Characters can act more often, allowing
          them to attack the enemies more often and thus breaking them faster.
        </p>
        <p>Notable Break archetype characters:</p>
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="firefly" enablePopover />
          <HSRCharacter mode="icon" slug="boothill" enablePopover />
          <HSRCharacter mode="icon" slug="rappa" enablePopover />
          <HSRCharacter mode="icon" slug="tingyun-fugue" enablePopover />
          <HSRCharacter mode="icon" slug="lingsha" enablePopover />
          <HSRCharacter mode="icon" slug="gallagher" enablePopover />
          <HSRCharacter mode="icon" slug="ruan-mei" enablePopover />
          <HSRCharacter mode="icon" slug="trailblazer-harmony" enablePopover />
          <HSRCharacter mode="icon" slug="luka" enablePopover />
        </div>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Relatively easy to gear as Characters only need to focus on
                    the Speed and Break Effect stats and do not have to worry
                    about Crit stats.
                  </li>
                  <li>
                    Has inherent crowd control ability as Weakness Breaking
                    enemies will delay their action significantly
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Loses a large amount of damage if the team cannot Weakness
                    Break the enemy, either due not having the correct Element
                    or due to enemy mechanics that prevent it.
                  </li>
                  <li>
                    Need to pay attention to which Character breaks the enemy’s
                    Toughness bar as it affects the Break damage taken by that
                    enemy.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <p>Here's a video showcasing this archetype in action:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="LXTUgfLXkUg" />
          </Col>
        </Row>
        <SectionHeader title="Follow-Up-Attack (FuA) Archetype" />
        <p>
          A variant of the Crit archetype that uses follow up attacks (FUA) to
          do the bulk of its damage. Follow up attacks are out of turn attacks
          that come in one of two forms: summon and triggered types.
        </p>
        <p>
          Summon type FUA’s will summon an entity with its own turn on the
          action bar and will act in turn order like any other units. Examples
          include Jing Yuan’s Lightning Lord and Topaz’s Numby. Note that these
          action bar FUA summons are different and separate from the Memosprite
          summons that Characters from the Path of Remembrance use. Teams that
          revolve around this type of FUA Characters will try to maximize the
          number of times that the summon will attack as that is where most of
          these Characters’ damage is concentrated.
        </p>
        <p>
          Triggered type FUA’s are follow up attacks that are automatically
          triggered once certain conditions are met. For example, March 7th will
          automatically perform a follow up attack on any enemy that attacks a
          shielded ally. They can also be triggered multiple times during a
          round, so teams in this archetype will try to take advantage of that
          and attempt to trigger as many follow up attacks as possible.
        </p>
        <p>
          When it comes to Character builds, FUA damage dealers will want the
          same stats that the Crit archetype damage dealers want (i.e. ATK or
          the stat that their skills scale with, Crit Rate, Crit DMG) as their
          damage is still coming from landing critical hits with their regular
          and follow up attacks.
        </p>
        <p>Notable Follow-Up-Attack (FuA) archetype characters:</p>
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="feixiao" enablePopover />
          <HSRCharacter mode="icon" slug="dr-ratio" enablePopover />
          <HSRCharacter mode="icon" slug="yunli" enablePopover />
          <HSRCharacter mode="icon" slug="jade" enablePopover />
          <HSRCharacter mode="icon" slug="jing-yuan" enablePopover />
          <HSRCharacter mode="icon" slug="topaz" enablePopover />
          <HSRCharacter mode="icon" slug="robin" enablePopover />
          <HSRCharacter mode="icon" slug="aventurine" enablePopover />
          <HSRCharacter mode="icon" slug="herta" enablePopover />
        </div>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Great Weakness Break capability due to being able to launch
                    multiple out of turn attacks.
                  </li>
                  <li>
                    High variety of playstyles due each FUA Character having a
                    different follow up attack condition, so teams will vary
                    depending on which FUA Character is used.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Crowd control effects hurt this archetype more than the
                    other archetype as you not only lose your turn, but also any
                    follow up attacks that could have triggered while crowd
                    controlled.
                  </li>
                  <li>
                    Teams tend to be inflexible due to the need to fulfill and
                    maximize the follow up attack condition.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <p>Here's a video showcasing this archetype in action:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="kVdz8_oQ0u4" />
          </Col>
        </Row>
        <SectionHeader title="Damage over Time (DoT) Archetype" />
        <p>
          The DoT, or damage over time, archetype aims to inflict as many DoT
          effects on the enemy as possible, whittling away at their HP until
          they are defeated. Teams in this archetype will typically utilize
          Characters that can inflict DoT effects with their skills rather than
          relying on the DoT that occurs when an enemy is Weakness Broken. DoT
          effects that come from Character skills scale off of that Character’s
          ATK and can be stacked multiple times, greatly increasing the damage
          that the DoT effects will do. On the other hand, DoT effects that
          occur from Weakness Breaking enemies scale off of Break Effect and
          only occur once when the enemy’s Toughness bar is reduced to zero, so
          it is not very useful to DoT archetype teams.
        </p>
        <p>
          When building Characters in this archetype, stack as much ATK and
          Speed as possible along with a healthy amount of Effect Hit Rate. ATK
          and Speed will allow for stronger and more application of DoT effects
          while Effect Hit Rate will help to ensure that the enemy will not
          resist the DoT debuff. Crit Rate and Crit DMG do not affect DoT
          damage, so they can be ignored.
        </p>
        <p>Notable Damage over Time (DoT) archetype characters:</p>
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="kafka" enablePopover />
          <HSRCharacter mode="icon" slug="black-swan" enablePopover />
          <HSRCharacter mode="icon" slug="sampo" enablePopover />
          <HSRCharacter mode="icon" slug="guinaifen" enablePopover />
        </div>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Great at dealing with mob enemies due to the AoE nature of
                    DoT teams.
                  </li>
                  <li>
                    Can ignore the enemy’s Toughness bar for the most part.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Low burst damage potential (if you don't have Kafka to
                    trigger the DoTs).
                  </li>
                  <li>
                    Need setup time to apply sufficient DoT effects on the
                    enemies.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <p>Here's a video showcasing this archetype in action:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="w705folPKRc" />
          </Col>
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRRerollPage;

export const Head: React.FC = () => (
  <Seo
    title="Team Archetypes | Honkai: Star Rail | Prydwen Institute"
    description="Common Team Archetypes in Honkai Star Rail explained!"
  />
);
