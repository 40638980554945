import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';

const AshGuidesEchoDu: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Echo Hunt: Dissonance - Du Wang</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_du.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Echo Hunt: Dissonance - Du Wang</h1>
          <h2>
            A dedicated guide for the Echo Hunt: Dissonance - Du Wang in Ash
            Echoes.
          </h2>
          <p>
            Last updated: <strong>06/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <SectionHeader title="Echo Hunt: Dissonance - Du Wang" />
            <StaticImage
              src="../../../images/ash/event/du_1.webp"
              alt="Guides"
            />
            <p>
              Echo Hunt, also unofficially referred to as Guild Raid/Boss, is a
              new scoring content centered around doing the highest possible
              damage, with up to 3 teams, against an enemy with infinite HP and
              only 2 minutes per team.
            </p>
            <p>
              The game mode comes with multiple Leaderboards, one personal
              within each Club and one pitting all Clubs against each other. A
              high place on the Leaderboard isn’t the only reward, however, as
              club currency and upgrade materials can be obtained, either as
              personal or club-wide rewards.
            </p>
            <SectionHeader title="The Rewards" />
            <p>
              The personal rewards come in the form of Monologue Token, a.k.a
              the currency spent at the Club shop for a variety of useful items.
            </p>
            <p>
              On the other hand, the Club-wide rewards are mostly Skill level up
              materials and, at higher clear levels, even a Badge of Projections
              for Potential Awakening. White Drives can also be obtained, and
              they give access to more limited and highly needed materials in
              its related shop. You may also find some Action Point recharge
              items as well as a few coins, overall a bunch of nice rewards to
              fast-forward your progress and make building multiple teams
              easier.
            </p>
            <SectionHeader title="Pure Echo stages" />
            <StaticImage
              src="../../../images/ash/event/du_2.webp"
              alt="Guides"
            />
            <p>
              Getting progressively harder as you complete them, the Pure Echo
              stages offer powerful, personal buffs to massively empower you
              during the Dissonance fight (the Boss). It is crucial to complete
              as many of them as you can since they will allow you to build
              teams more easily, as well as making possible a full focus on
              Offense-related skills.
            </p>
            <p>
              On top of those buffs, the first 4 fights reward you with White
              Drive currency, while the last and arguably hardest fight rewards
              you with Coins, making the more precious currency more accessible
              to all players.
            </p>
            <SectionHeader title="Dissonance" />
            <StaticImage
              src="../../../images/ash/event/du_3.webp"
              alt="Guides"
            />
            <p>
              This first Dissonance Boss acts as a sandbag with unlimited HP,
              and the goal is to deal as much damage as you can within 2
              minutes, with up to 3 teams.
            </p>
            <p>
              Just like Simultaneous Raid, the same Echomancers cannot be used
              in multiple teams, even if one is an Assist. Speaking of Assists,
              <strong>
                the Assist Echomancers can only be recruited from your club
                mates,
              </strong>
              which gives a strategic depth to organizing your Club’s Assists.
            </p>
            <p>
              The Boss itself is fairly unremarkable, its main gimmick remains
              the recurring AoE damage to the tiles around your Terminal so
              place your Tank and Supports there to reduce the damage your
              Terminal takes if you need to.
            </p>
            <p>
              Lastly, should you be a brand new player, the game offers a
              complete Engraving + Leader set to be used only on the Dissonance
              fight. You won’t reach crazy levels of damage with it, but it
              remains a viable and entirely free Engraving to get you started.
            </p>
            <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesEchoDu;

export const Head: React.FC = () => (
  <Seo
    title="Echo Hunt: Dissonance - Du Wang | Ash Echoes | Prydwen Institute"
    description="A dedicated guide for the Echo Hunt: Dissonance - Du Wang in Ash Echoes."
    game="ash"
  />
);
