import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import './guides.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Card, Col, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { ZZZCharacter } from '../../../modules/zzz/common/components/zzz-character';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const ZZZBegGuide: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page zzz guide-zzz'} game="zzz">
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/zenless/guides/">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Beginner guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/zzz/categories/category_beginner.jpg"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Zenless Zone Zero (ZZZ) Beginner guide</h1>
          <h2>
            A beginner guide for Zenless Zone Zero (ZZZ) who will help you
            understand the game better and progress through it faster.
          </h2>
          <p>
            Last updated: <strong>28/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="page-content">
        <SectionHeader title="Beginner guide" />
        <StaticImage
          src="../../../images/zzz/guides/intro_n_1.webp"
          alt="Guide"
        />
        <p>
          Zenless Zone Zero (ZZZ) is an Action Combat RPG similar to Genshin
          Impact. The game is split into two modes:{' '}
          <strong>Story and Hollows</strong>.
        </p>
        <ul>
          <li>
            In the <strong>Story</strong>, you move through areas following
            along the main plot while defeating enemies and bosses.
          </li>
          <li>
            <strong>Hollows</strong> are the primary game mode of ZZZ and where
            you’ll be spending most of your time after you’re up to date on the
            current story. Hollows are a <strong>roguelike experience</strong>{' '}
            that sees you play through randomized dungeons with a team of your
            choice for rewards.
          </li>
        </ul>
        <p>
          Overall, the game focuses on exploring Hollows using the HDD system
          which is very similar to a top down roguelike dungeon. The gameplay
          consists of navigating the HDD game board to interact with events and
          complete combat encounters handled by your team of Agents.
        </p>
        <SectionHeader title="Agents" />
        <StaticImage
          src="../../../images/zzz/guides/characters.webp"
          alt="Guide"
        />
        <p>
          Agents are playable units in ZZZ. You are given{' '}
          <strong>3 agents for free (Anby, Nicole and Billy)</strong> at the
          start of the game:
        </p>
        <div className="zzz-container">
          <Card className="avatar-card">
            <ZZZCharacter
              slug="anby-demara"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="nicole-demara"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="billy-kid"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          And those Agents you can obtain for free from progressing through the
          game and from various events:
        </p>
        <div className="zzz-container">
          <Card className="avatar-card">
            <ZZZCharacter
              slug="ben"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="corin"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="soukaku"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>Sources:</p>
        <ul>
          <li>
            <strong>Ben</strong> - complete Chapter 2 Story 1,
          </li>
          <li>
            <strong>Corin</strong> - pre-register reward,
          </li>
          <li>
            <strong>Soukaku</strong> - complete Shiyu Defense 3-4
          </li>
        </ul>
        <h5>Rarities</h5>
        <p>
          Agents are split into two rarities:{' '}
          <strong className="s-rank">S-rank</strong> and{' '}
          <strong className="a-rank">A-rank</strong>, with S-rank Agents being
          more powerful and harder to get on average.
        </p>
        <h5>Agent Stats</h5>
        <StaticImage src="../../../images/zzz/guides/stats.webp" alt="Guide" />
        <p>Here are all the combat stats agents can gain and what they do:</p>
        <ul>
          <li>
            <strong>HP</strong> - How much damage an Agent can take before
            falling in combat.
          </li>
          <li>
            <strong>ATK</strong> - How much damage an Agent deals.
          </li>
          <li>
            <strong>DEF</strong> - Reduces the damage an Agent takes.
          </li>
          <li>
            <strong>Impact</strong> - Attacking enemies increases their Daze. A
            higher Impact stat allows more Daze to accumulate with each attack.
          </li>
          <li>
            <strong>Crit Rate</strong> - How likely it is for the Agent to land
            a critical hit.
          </li>
          <li>
            <strong>Crit DMG</strong> - Determines the damage multiplier when
            landing a critical hit.
          </li>
          <li>
            <strong>PEN</strong> - Ignores a set amount of the target’s DEF
            based on the PEN stat when attacking an enemy.
          </li>
          <li>
            <strong>Pen Ratio</strong> - Ignores a percentage of the target’s
            DEF based on the PEN Ratio stat when attacking an enemy.
          </li>
          <li>
            <strong>Anomaly Mastery</strong> - Affects the efficiency of Anomaly
            Buildup. Each point in Anomaly Mastery is equivalent to 1% of
            Anomaly Buildup Rate.
          </li>
          <li>
            <strong>Anomaly Proficiency</strong> - Enhances the damage inflicted
            by Attribute Anomalies. Each point in Anomaly Proficiency is
            equivalent to 1% of Attribute Anomaly DMG.
          </li>
          <li>
            <strong>Energy Regen</strong> - After entering combat, Agents
            passively generate Energy every second. The higher this value is,
            the more Energy gained per second.
          </li>
          <li>
            <strong>Energy Generation Rate</strong> - Energy Generation Rate
            will increase the Energy generated through Energy Regen, and
            attacking enemies.
          </li>
        </ul>
        <h5>Character Attributes</h5>
        <p>
          All agents are categorized into one of five{' '}
          <strong>Attributes</strong>:
        </p>
        <ul>
          <li>
            <strong className="Fire">Fire</strong>
          </li>
          <li>
            <strong className="Electric">Electric</strong>
          </li>
          <li>
            <strong className="Ice">Ice</strong>
          </li>
          <li>
            <strong className="Ice">Frost</strong>
          </li>
          <li>
            <strong className="Physical">Physical</strong>
          </li>
          <li>
            <strong className="Ether">Ether</strong>
          </li>
        </ul>
        <p>
          Combining Agents with specific Elemental attributes and Factions in
          the same squad allows you to activate their additional passive skill.
        </p>
        <p>
          Enemies come in different types and are vulnerable to different
          elemental damage:
        </p>
        <ul>
          <li>
            <strong>Organic and Corrupted</strong> are vulnerable to{' '}
            <strong className="zzz-color Fire">Fire</strong>-Type Attacks.
          </li>
          <li>
            <strong>Machines</strong> are vulnerable to{' '}
            <strong className="zzz-color Electric">Electric</strong>-Type
            Attacks.
          </li>
          <li>
            <strong>Mutants</strong> are vulnerable to{' '}
            <strong className="zzz-color Ice">Ice</strong>-Type Attacks.
          </li>
        </ul>
        <h5>Attribute Anomaly</h5>
        <p>
          When an agent deals attribute damage, they{' '}
          <strong>inflict a certain amount of Anomaly Buildup</strong>. Once a
          certain level of buildup is reached, the enemy will be afflicted with
          an attribute anomaly debuff of the corresponding attribute.
          Additionally, exploiting enemies weakness to certain Attributes to
          trigger Attribute Anomaly will intensify the Anomaly effect. As we
          mentioned in the stats section above,{' '}
          <strong>
            Anomaly Mastery stat will increase the buildup of the Anomaly, while
            Anomaly Proficiency will increase the damage dealt by it.
          </strong>
        </p>
        <p>
          Combining 2 different attributes will override the original anomaly
          and trigger an additional effect <strong>Disorder</strong> which deals
          extra damage calculated based on original anomalies damage and
          accumulates Daze.
        </p>
        <p>Here are all the Anomaly Debuffs that exist in the game:</p>
        <div className="anomaly-debuff-table">
          <div className="ano-header">
            <div>Element</div>
            <div>Activation</div>
            <div>Debuff</div>
          </div>
          <div className="single-row">
            <div>
              <strong className="zzz-color Electric">Electric</strong>
            </div>
            <div>
              <span className="on-mobile">Activation</span>
              Dealing Electric damage to enemies accumulates the Electric
              Attribute Anomaly, which triggers the Shock effect when enough is
              accumulated.
            </div>
            <div>
              <span className="on-mobile">Debuff</span>
              <strong className="zzz-color Electric">Shock:</strong> Being
              attacked intermittently triggers additional Electric damage and
              interrupts enemy actions. Machine enemies are unable to move while
              Shocked.
            </div>
          </div>
          <div className="single-row">
            <div>
              <strong className="zzz-color Physical">Physical</strong>
            </div>
            <div>
              <span className="on-mobile">Activation</span>
              Dealing Physical damage to enemies accumulates the Physical
              Attribute Anomaly, which triggers the Assault effect when enough
              is accumulated.
            </div>
            <div>
              <span className="on-mobile">Debuff</span>
              <ul>
                <li>
                  <strong className="zzz-color Physical">Assault:</strong>{' '}
                  Interrupts the enemy, deals massive Physical damage and
                  increases the Daze inflicted on the target.
                </li>
              </ul>
            </div>
          </div>
          <div className="single-row">
            <div>
              <strong className="zzz-color Ice">Ice</strong>
            </div>
            <div>
              <span className="on-mobile">Activation</span>
              Dealing Ice damage to enemies accumulates the Ice Attribute
              Anomaly, which triggers the Freeze and Frostbite effects when
              enough is accumulated.
            </div>
            <div>
              <span className="on-mobile">Debuff</span>
              <ul>
                <li>
                  <strong className="zzz-color Ice">Freeze:</strong> Prevents
                  taking action for a certain period, and triggers Shatter at
                  the end of the effect, dealing Ice damage.
                </li>
                <li>
                  <strong className="zzz-color Ice">Frostbite:</strong>{' '}
                  Increases the CRIT DMG taken by the target for a period of
                  time.
                </li>
              </ul>
            </div>
          </div>
          <div className="single-row">
            <div>
              <strong className="zzz-color Ice">Frost</strong>
            </div>
            <div>
              <span className="on-mobile">Activation</span>
              Dealing Frost damage to enemies accumulates the Frost Attribute
              Anomaly, which triggers the Freeze and Frostbite effects when
              enough is accumulated.
            </div>
            <div>
              <span className="on-mobile">Debuff</span>
              <ul>
                <li>
                  <strong className="zzz-color Ice">Freeze:</strong> Prevents
                  taking action for a certain period, and triggers Shatter at
                  the end of the effect, dealing Ice damage.
                </li>
                <li>
                  <strong className="zzz-color Ice">Frostbite:</strong>{' '}
                  Increases the CRIT DMG taken by the target for a period of
                  time.
                </li>
              </ul>
            </div>
          </div>
          <div className="single-row">
            <div>
              <strong className="zzz-color Fire">Fire</strong>
            </div>
            <div>
              <span className="on-mobile">Activation</span>
              Dealing Fire damage to enemies accumulates the Fire Attribute
              Anomaly, which triggers the Burn effect when enough is
              accumulated.
            </div>
            <div>
              <span className="on-mobile">Debuff</span>
              <strong className="zzz-color Fire">Burn:</strong> Deals continuous
              Fire damage. Organic enemies are unable to move while Burned.
            </div>
          </div>
          <div className="single-row">
            <div>
              <strong className="zzz-color Ether">Ether</strong>
            </div>
            <div>
              <span className="on-mobile">Activation</span>
              Dealing Ether damage to enemies accumulates the Ether Attribute
              Anomaly, causing the Corruption effect when enough is accumulated.
            </div>
            <div>
              <span className="on-mobile">Debuff</span>
              <strong className="zzz-color Ether">Corruption:</strong> Causes
              additional Ether damage when attacked. Corrupted Ethereal enemies
              will also attack both friend and foe.
            </div>
          </div>
        </div>
        <p>To learn more about Anomaly Debuffs, check this great video:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="bi6IJXh_-9A" />
          </Col>
        </Row>
        <h5>Specialty</h5>
        <p>
          In addition Agents can specialize in five different{' '}
          <strong>Specialties</strong> that define their role in the team:
        </p>
        <ul>
          <li>
            <strong>Attack</strong> - Agents with the Attack specialty possess
            formidable damage output capabilities, excelling at rapidly racking
            up damage directly through attacks to defeat enemies and bring the
            combat to a swift end.
          </li>
          <li>
            <strong>Stun</strong> - Agents with Stun specialty possess powerful
            control abilities, excelling at building up Daze to cause enemies to
            be Stunned, thus creating opportunities for the squad to deal
            damage.
          </li>
          <li>
            <strong>Support</strong> - Agents with Support specialty are able to
            aid and enhance other Agents in battle, excelling at buffing
            friendly units to improve the combat effectiveness of the whole
            squad.
          </li>
          <li>
            <strong>Defense</strong> - Agents with Defense specialty have strong
            survivability, excelling at tanking attacks to counterattack,
            seizing the upper hand for their side in the heat of battle.
          </li>
          <li>
            <strong>Anomaly</strong> - Agents with Anomaly specialty are
            exceptional with applying debuffs, excelling at accumulating Anomaly
            Buildup to weaken enemies and deal damage by triggering Attribute
            Anomalies.
          </li>
        </ul>
        <h5>Agent Training</h5>
        <p>
          If you want to learn more about how to make your Agents stronger,
          check this guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Agents Progression"
            link="/zenless/guides/agents-progression"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_agents.jpg"
                alt="Agents Progression"
              />
            }
          />
        </Row>
        <SectionHeader title="W-Engines" />
        <p>
          W-Engines are the Zenless Zone Zero equivalent of Weapons from Genshin
          Impact or Light Cones from Honkai: Star Rail. Here's some more
          information about the system:
        </p>
        <ul>
          <li>
            W-Engines come in 3 rarities:{' '}
            <strong className="s-rank">S-rank</strong>,{' '}
            <strong className="a-rank">A-rank</strong> and{' '}
            <strong className="b-rank">B-rank</strong>.
          </li>
          <li>
            W-Engines increase the Agent's Attack and another stat that changes
            based on the W-Engine,
          </li>
          <li>
            W-Engines also come with a passive effect that activates in the
            combat,
          </li>
          <ul>
            <li>
              W-Engines are restricted to the character's Specialty - so for
              example,{' '}
              <strong>
                only Attack characters can activate the special passive of an
                Attack W-Engine
              </strong>
              .
            </li>
          </ul>
          <li>You can level W-Engines in the same way as you level Agents,</li>
          <li>
            You can obtain W-Engines from the Signal Search, buy them in the
            Gadget Store or obtain them as random drops from some game modes.
          </li>
        </ul>
        <p>
          You can find the list of currently available W-Engines in this guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="W-Engines"
            link="/zenless/w-engines"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_engines.png"
                alt="W-Engines"
              />
            }
          />
        </Row>
        <SectionHeader title="Bangboo" />
        <StaticImage
          src="../../../images/zzz/guides/info_bangboo.webp"
          alt="Guide"
        />
        <p>
          Bangboo were originally created to assist New Eridu residents with
          evacuation in Hollow disasters. When a Hollow occurs in New Eridu's
          residential areas, Bangboo will immediately receive disaster
          information and evacuation guidance from the public network, and help
          people around them avoid the hazard-prone areas.
        </p>
        <p>
          If you ever played Honkai Impact 3, then{' '}
          <strong>Bangboo are really similar to ELFs</strong> - they are the 4th
          team member who supports the currently active character with both
          active skills and buffs. Similar to Agents, Bangboo come in two ranks
          - <strong className="s-rank">S-rank</strong> and{' '}
          <strong className="a-rank">A-rank</strong>.
        </p>
        <h5>How to obtain Bangboo?</h5>
        <p>
          You can pull them from the Bangboo Oriented Signal Search. You can
          only use <strong>Boopons</strong> on this banner -{' '}
          <strong>Boopons</strong> can be obtained from missions, events and
          various modes in Hollow Deep Dive Zone and can't be bought using
          real-life currency.
        </p>
        <p>
          If you want to check the list of currently available Bangboo and their
          skills, take a look at this guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Bangboo list"
            link="/zenless/bangboo"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_bangboo.png"
                alt="Bangboo list"
              />
            }
          />
        </Row>
        <SectionHeader title="Gacha" />
        <StaticImage
          src="../../../images/zzz/guides/banners.webp"
          alt="Guide"
        />
        <p>
          Zenless Zone Zero uses a gacha system as the primary way for a player
          to obtain new Characters, W-Engines and Bangboos and it is generally
          the only way to obtain the highest rarity Characters, W-Engines and
          Bangboos. There are 3 types of banners in Zenless Zone Zero:
        </p>
        <ul>
          <li>Character (Agents) Banner,</li>
          <li>W-Engines Banner.</li>
          <li>Bangboo Banner.</li>
        </ul>
        <p>
          The rates are nearly the same as in other Hoyoverse games, so the rate
          to pulling a <strong className="s-rank">S-rank</strong> Character is
          set at <strong>0.6%</strong> and the chance of pulling an{' '}
          <strong className="a-rank">A-rank</strong> Character from this banner
          is set at <strong>9.4%</strong>.
        </p>
        <p>You can learn more about the gacha system in another guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Gacha system"
            link="/zenless/guides/gacha-system"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_gacha.png"
                alt="Gacha system"
              />
            }
          />
        </Row>
        <SectionHeader title="Combat" />
        <p>
          Before battle you must assemble a team of up to 3 Agents. You can only
          control one Agent at a time but you can freely swap between them to
          access each of their abilities and combine them.
        </p>
        <h5>Combat UI Elements</h5>
        <StaticImage
          src="../../../images/zzz/guides/intro_4.webp"
          alt="Guide"
        />
        <h6>HP</h6>
        <ul>
          <li>
            When all your Agents HP is depleted you will be evacuated from the
            current combat zone or hollow.
          </li>
        </ul>
        <StaticImage
          src="../../../images/zzz/guides/intro_5.webp"
          alt="Guide"
        />
        <h6>Energy</h6>
        <ul>
          <li>
            Agents gain energy over time when they are on the field based on
            their Energy Regen stat.
          </li>
          <li>
            Basic attacks, dodge counters, and dash attacks also generate energy
            for agents.
          </li>
          <li>
            Breaking supply chests and picking up energy matter dropped by
            defeated enemies increases energy for the entire squad.
          </li>
          <li>
            Each Agent has their own energy bar, cycling between each of them to
            ensure you make use of each of their energy is advised.
          </li>
        </ul>
        <StaticImage
          src="../../../images/zzz/guides/intro_6.webp"
          alt="Guide"
        />
        <h6>Daze</h6>
        <ul>
          <li>
            When enemies are hit, they accumulate Daze. When Daze reaches 100%,
            enemies become stunned, rendering them unable to move and more
            susceptible to damage.
          </li>
          <li>
            While enemies are dazed they can be inflicted with your team's
            powerful chain attacks you’ll learn about below.
          </li>
        </ul>
        <SectionHeader title="Combat Actions" />
        <h5 className="with-icon">
          Basic Attack
          <StaticImage
            src="../../../images/zzz/icons/icon_basic.png"
            alt="Guide"
          />
        </h5>
        <ul>
          <li>
            Press, tap and hold the appropriate key or button to launch a basic
            attack consisting of a number of strikes (usually 3-5). Some agents
            unleash alternative moves by holding the basic attack during a basic
            attack combo.
          </li>
          <li>
            The Basic attack is your primary means of consistent damage on most
            characters and executing full basic attack chains will yield the
            most energy recharge and damage in most cases.
          </li>
        </ul>
        <h5 className="with-icon">
          Special Attack
          <StaticImage
            src="../../../images/zzz/icons/icon_special.png"
            alt="Guide"
          />
        </h5>
        <ul>
          <li>
            Special attacks are normally the defining feature of an Agent and
            can be used at any time without any cooldown but can be used in a
            special enhanced state (EX Skill Variant) after accumulating enough
            energy. Special attacks have a heavy attack effect which when used
            on a fully dazed enemy will allow for a chain attack.
          </li>
          <li>
            Normal special attacks are very rarely worth using, they do low
            damage and do not regenerate energy and can mostly be ignored. The
            empowered EX Special attack is always worth using and should be
            prioritized as soon as it’s available but can be saved in
            preparation for stronger enemies or for specific character
            synergies.
          </li>
        </ul>
        <StaticImage
          src="../../../images/zzz/guides/intro_7.webp"
          alt="Guide"
        />
        <h5 className="with-icon">
          Chain Attack
          <StaticImage
            src="../../../images/zzz/icons/icon_qte.png"
            alt="Guide"
          />
        </h5>
        <ul>
          <li>
            If there are two or more Agents in your squad, hitting a stunned
            enemy after their daze reaches 100% with a heavy attack will trigger
            a chain attack.
          </li>
          <li>
            Pressing an Agent’s avatar on the left or right before the countdown
            at the bottom of the screen ends will trigger the Agent’s Chain
            Attack.
          </li>
          <li>
            These attacks do tremendous damage and can be used in quick
            succession while an enemy is stunned combining them is advised.
          </li>
        </ul>
        <StaticImage
          src="../../../images/zzz/guides/intro_8.webp"
          alt="Guide"
        />
        <h5 className="with-icon">
          Ultimate
          <StaticImage
            src="../../../images/zzz/icons/icon_ulti_full.png"
            alt="Guide"
          />
        </h5>
        <ul>
          <li>
            Hitting enemies, using chain attacks, dodge Counters, and triggering
            Assist Follow-Ups builds up Decibel Rating gauge.
          </li>
          <li>
            When your Decibel Rating reaches <strong>3,000</strong>, the
            Ultimate button will light up, signaling you may press the Ultimate
            key to clear the decibel rating and unleash an ultimate.
          </li>
          <li>
            You can check how close to the Ultimate using the tracker that has 3
            stages, once it says “Maximum” it’s ready:
          </li>
        </ul>
        <StaticImage
          src="../../../images/zzz/guides/intro_9.webp"
          alt="Guide"
        />
        <p>
          Your entire team seems to share the{' '}
          <strong>Decibel Rating Gauge</strong> so be sure to use the ultimate
          on the character that will have the most impact in combat!
        </p>
        <h5 className="with-icon">
          Dodge
          <StaticImage
            src="../../../images/zzz/icons/icon_dodge.png"
            alt="Guide"
          />
        </h5>
        <ul>
          <li>Press the appropriate key or button to Dodge.</li>
          <li>Using a Basic Attack while dodging will launch a Dash Attack.</li>
          <li>
            Dodging when your Agent is about to be hit can trigger a “Perfect
            Dodge”.
          </li>
          <li>
            Quickly using a Basic Attack right after a Perfect Dodge will launch
            a Dodge Counter with a Heavy Attack effect.
          </li>
        </ul>
        <h5 className="with-icon">
          Switching Agents
          <StaticImage
            src="../../../images/zzz/icons/icon_assist.png"
            alt="Guide"
          />
        </h5>
        <ul>
          <li>
            When there are 2 or more Agents in the squad, pressing the agent
            switch key will replace the agent on the field with the next Agent
            to its right on the Agent bar in the top-left corner.
          </li>
        </ul>
        <h5>Assist Attack</h5>
        <ul>
          <li>
            When the Agent on the field is knocked back, another Agent’s Assist
            Attack window will trigger. Switch Agents during this window trigger
            a reactive assist.
          </li>
          <li>
            Reactive Assists have a Heavy Attack effect and can quickly turn the
            tides of battle back in your favour.
          </li>
        </ul>
        <StaticImage
          src="../../../images/zzz/guides/intro_10.webp"
          alt="Guide"
        />
        <h5>Special: Perfect Assist</h5>
        <ul>
          <li>
            If you switch Agents right before some enemy attacks (those that
            flash gold) hit, the Agent switching in will consume an Assist Point
            to active a Defensive Assist or Evasive Assist.
          </li>
          <li>
            You can activate up to 3 Perfect Assists in a row within a certain
            period.
          </li>
          <li>You can trigger Chain Attacks to regenerate Assist Points.</li>
          <li>
            When you do not have Assist Points, the Agent Switching in will
            automatically Perfect Dodge instead.
          </li>
          <li>
            Pressing the Basic Attack or Special Attack button when a Perfect
            Assist is triggered will launch an Assist Follow-Up.
          </li>
        </ul>
        <StaticImage
          src="../../../images/zzz/guides/intro_11.webp"
          alt="Guide"
        />
        <SectionHeader title="Game Modes" />
        <StaticImage src="../../../images/zzz/guides/hollow.webp" alt="Guide" />
        <p>
          Zenless Zone Zero features several game modes those who have played
          Honkai: Star Rail or Genshin Impact will be familiar with.
        </p>
        <h5>Resource/Boss Stages</h5>
        <p>
          In Zenless Zone Zero, you will have to clear instance based stages
          with either the bosses we met during story or just regular enemies, to
          obtain resources and materials needed to progress your account.
          However, <strong>Battery Charges</strong> (Stamina) must be used in
          order to enter the stages and claim your rewards.
        </p>
        <h5>Hollow Deep Dive Zone</h5>
        <p>
          This is the <strong>Main Menu</strong> of the gameplay section. From
          here you can access story, combat commissions, exploration commissions
          as well as Shiyu Defense, Hollow Zero and Rally Commission.
        </p>
        <ul>
          <li>
            <strong>Shiyu Defense</strong> - This mode is basically the Zenless
            Zone Zero equivalent of Memory of Chaos from Honkai: Star Rail.
            Shiyu Defense is a game mode where the objective is to defeat a
            group of enemies in limited amount of time. After completing a Shiyu
            Defense stage you will be awarded between 0 to 3 stars for that
            stage depending on how long it took you to defeat the enemies in
            that stage.
          </li>
          <li>
            <strong>Hollow Zero</strong> - This mode is basically the Zenless
            Zone Zero equivalent of Simulated Universe from Honkai: Star Rail.
            Hollow Zero is a roguelike game mode where the player will select a
            team of 3 Characters to venture inside a Hollow Zone to collect a
            random set of buffs that they can use to enhance their team and
            fight the enemies inside.
          </li>
          <li>
            <strong>Rally Commission</strong> - This mode doesn't have an
            equivalent in other Hoyoverse games. This is another weekly mode
            where you enter a stage thats filled with combat challenges. After
            clearing the initial 2 stages, you unlock a weekly stage.
          </li>
        </ul>
        <p>
          For more details about the available game modes, visit our dedicated
          guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Game Modes"
            link="/zenless/guides/game-modes"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_modes.png"
                alt="Game Modes"
              />
            }
          />
        </Row>
        <SectionHeader title="Drive Disks" />
        <StaticImage src="../../../images/zzz/guides/drive.webp" alt="Guide" />
        <p>
          Drive Disks are a type of equipment in this game and serve the
          function of armor and accessories in a traditional RPG game -{' '}
          <strong>
            they're the equivalent of Relics from Honkai: Star Rail
          </strong>
          .
        </p>
        <p>
          Here's a summary of information about the Drive Disks that we know so
          far:
        </p>
        <ul>
          <li>
            Equipping Drive Disks to your Agents will provide them with a
            variety of stat increases from HP to ATK to CRIT,
          </li>
          <li>
            Drive Disks will provide your Agents with the main bulk of their
            stat increases,
          </li>
          <li>
            Each Agent can equip up to <strong>six Drive Disks</strong>,
          </li>
          <ul>
            <li>
              Numbered in the game with the Roman numerals: I, II, III, IV, V,
              and VI (you can see it also on the image above),
            </li>
          </ul>
          <li>
            Each Drive Disk has one <strong>base stat</strong> and up to{' '}
            <strong>4 random sub-stats</strong>,
          </li>
          <li>
            Equipping a required number (either 2 or 4) of Drive Disks belonging
            to the same set, will unlock <strong>Set Effects</strong>.
          </li>
        </ul>
        <h5>Main Stats</h5>
        <p>
          Each Drive Disk has one main stat and up to 4 sub-stats. Depending on
          the Drive Disk slot it fits in, the possible main stats are:
        </p>
        <ul>
          <li>
            <strong>I</strong> - Flat HP
          </li>
          <li>
            <strong>II</strong> - Flat ATK
          </li>
          <li>
            <strong>III</strong> - Flat DEF
          </li>
          <li>
            <strong>IV</strong> - HP%, ATK%, DEF%, CRIT Rate%, CRIT DMG%,
            Anomaly Proficiency
          </li>
          <li>
            <strong>V</strong> - HP%, ATK%, DEF%, PEN Ratio%, Element DMG%*
          </li>
          <ul>
            <li>
              *Element DMG is random and can roll any of the 5 elements
              available in the game,
            </li>
          </ul>
          <li>
            <strong>VI</strong> - HP%, ATK%, DEF%, Anomaly Mastery%, Energy
            Regen%, Impact%
          </li>
        </ul>
        <h5>Sub Stats</h5>
        <p>
          Drive Disks sub-stats are randomly generated. The list of possible
          substats are:
        </p>
        <ul>
          <li>Flat HP</li>
          <li>Flat ATK</li>
          <li>Flat DEF</li>
          <li>HP%</li>
          <li>ATK%</li>
          <li>DEF%</li>
          <li>Crit Rate%</li>
          <li>Crit DMG%</li>
          <li>PEN (flat)</li>
          <li>Anomaly Proficiency</li>
        </ul>
        <p>To learn more about the Disk Sets, check our other guides:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Drive Disks Stats"
            link="/zenless/guides/disk-drives-stats"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_disk2.png"
                alt="Drive Disks Stats"
              />
            }
          />
          <CategoryCard
            title="Drive Disks Sets"
            link="/zenless/disk-drives"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_disksets.png"
                alt="Drive Disks Sets"
              />
            }
          />
        </Row>
        <SectionHeader title="Videos" />
        <p>If you want to learn more about the game, check the videos below:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="bAUwWD1j29o" />
          </Col>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="f1MBxzgrue0" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ZZZBegGuide;

export const Head: React.FC = () => (
  <Seo
    title="Beginner guide (ZZZ) | Zenless Zone Zero | Prydwen Institute"
    description="A beginner guide for Zenless Zone Zero (ZZZ) who will help you understand the game better and progress through it faster."
    game="zzz"
  />
);
