import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldLopera: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you pull Lopera?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_lopera.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you pull Lopera?</h1>
          <h2>
            Not sure if pulling Lopera is the right move? No worries! Check our
            review and guide for her!
          </h2>
          <p>
            Last updated: <strong>23/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Should You Pull? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="lopera"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>
          Lopera is a very strong offensive Support with the possibility of
          becoming a Tuesday-like requirement for Bullet teams specifically,
          consider grabbing Lopera if:
        </p>
        <ul>
          <li>
            You’re missing a strong Generalist Support such as Mercuria or Argus
          </li>
          <li>You’re looking to make a Bullet team once it becomes viable</li>
          <li>You’re looking to optimize Windsong’s damage output</li>
          <li>You enjoy the [Burn] niche</li>
        </ul>
        <SectionHeader title="Review" />
        <p>
          Lopera is a 6-star Arcanist of the Beast Afflatus. She doesn't follow
          a particular niche at the moment, but has the potential to become
          Tuesday's [Bullet] counterpart as a key unit for that archetype.
        </p>
        <p>
          Her kit is one large gimmick, so let's start with that. First is her
          "Winning Chips" Incantation, which grants Lopera stacks of [Flaming
          Bullet] which allows her to apply [Burn] on hit, and enters her into
          the [Ready to Shoot] channel. While channeling, at the end of the
          round, she attacks with "Bull's Eye" which is a single-target attack
          that grants all allies [Right on Target]; a buff that increases Crit
          DMG by a percentage of Lopera's own. This buff is increased if the
          target has at least 20 stacks of [Burn].
        </p>
        <p>
          But there's more to do with [Flaming Bullet]. With "Double Backups"
          she can turn [Flaming Bullet] into [Incantation Bullet] (increasing
          Incantation Might) for allies in front of her, and [Ritual Bullet]
          (increasing Ultimate Might) for allies behind her. This allows her to
          work in both in teams oriented around casting Incantations as well as
          Ultimates. Further helping this cycle of [Bullet] to DMG are her
          Insights, which grant her +1 Moxie upon [Bullet] trigger and
          additional Moxie upon entering combat, allowing her to quickly cycle
          her Array.
        </p>
        <p>
          The interesting part is her Ultimate "More Than Alchemy". It creates
          the [Crucible Arsenal] Array that provides a few very powerful
          effects:
        </p>
        <ul>
          <li>
            Any [Bullet]s carried by allies do not go below 1 stack, making them
            effectively infinite so long as the Array exists.
          </li>
          <li>It grants +40% Crit Rate.</li>
          <li>Up to 40% of excess Crit Rate gets converted into Crit DMG.</li>
          <li>
            For every type of [Bullet] carried by an ally, the excess conversion
            is increased by 10% (up to 5 times).
          </li>
        </ul>
        <p>
          This is great for Crit-based characters to begin with, but it has the
          potential to completely carry the [Bullet] team once it gets a proper
          Damage Dealer.
        </p>
        <h5>Gameplay </h5>
        <p>
          As perturbed as I am by Lopera stealing Sonetto's face, she's at least
          got a really good kit to back it up.
        </p>
        <p>
          The Bullet distribution is definitely the most important part of her
          kit, but the entire kit works in tandem really well. The [Flaming
          Bullet] can go infinite in the Array, allowing for easy access to
          [Incantation Bullet] and [Ritual Bullet], while also letting Lopera
          stay in her channel infinitely. In addition, the Crit buff from the
          Array quickly scales up Lopera's Crit DMG through the conversion,
          which then gets granted to allies via [Ready to Shoot].
        </p>
        <p>
          I'm not sure why exactly she gets to ST heal 50% of an ally's Lost HP
          when triggering a [Bullet], given she'll be triggering it on every
          attack, but it's a nice addition to her kit. The [Burn] requirement
          for [Ready to Shoot] to give 20% instead of 10% of her Crit DMG is
          kind of whack, and she probably won't be meeting it on her own which
          makes Isolde or J necessary to maximize her supporting capabilities,
          but she is strong enough without them.
        </p>
        <p>
          Outside of that, it means that Lopera will have 2 main uses: a
          [Bullet] battery and as a Crit relay. If you need either of those,
          Lopera will be a very solid option and also a possible replacement for
          Mercuria or Argus.
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Can make [Bullet]s permanent, potentially becoming the
                    Tuesday of the [Bullet] archetype.
                  </li>
                  <li>
                    Extremely easy to cycle Ultimate that also grants large
                    amounts of Crit.
                  </li>
                  <li> Has access to a wide variety of offensive buffs.</li>
                  <li>Can heal a surprising amount in the right teams.</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Can't fully realize her potential without a bit of [Burn]
                    help
                  </li>
                  <li>
                    Doesn't have a Damage Dealer for her primary archetype
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>Completely fine at P0.</p>
        <p>
          If you’re looking to invest into Lopera, the only real viable Portrait
          in terms of Supporting will be Portrait one. It increases the Crit
          gained from her Array and increases the Crit conversion too. Portrait
          2 is another option to enhance her self-sufficiency and help with more
          Crit, but the rest is pretty redundant (Portrait 5 is obviously good
          but not necessary at all).
        </p>
        <SectionHeader title="Great Synergies" />
        <p>
          Lopera generally synergizes with Bullet, Follow-Up, Crit and Bullet
          teams. Especially the last one, but there aren’t any Damage Dealers
          that can make full use of that yet.
        </p>
        <p>Notable synergies include:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="windsong"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="lucy"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="j"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="isolde"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="mr-duncan"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>Future notable synergies:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="flutterpage"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="ms-newbabel"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on Lopera:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="the-third-commitment"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="blasphemer-of-night"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="stray-off-the-path"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <SectionHeader title="Resonance" />
        <h6>R10 ATK Build (Recommended)</h6>
        <StaticImage
          src="../../../images/reverse/generic/lopera_r_1.webp"
          alt="Guides"
        />
        <SectionHeader title="Teams" />
        <p>A few suggestions are listed below.</p>
        <h5>Standard Teams</h5>
        <div className="team-box">
          <h5 className="star">
            Bullet Hell
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="lucy"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="anjo-nala"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="vila"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Lopera</strong>
              </li>
              <li>Contracted: Lucy</li>
              <li>
                A lot of people don’t know, but Lucy’s [Ammunition] counts as a
                [Bullet]. Due to this, using Lucy and Anjo in conjunction with
                Lopera’s [Incantation Bullet] on top of making [Ammunition]
                infinite under her Array makes her spam incredibly strong. Add
                Vila for the [Song of Passion] and you’ve got a fairly solid
                defensive core with a lot of damage.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Team Rocket Blasting Off Again [2.3]
            <span className="tag">[CN]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="windsong"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="flutterpage"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="vila"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Lopera</strong>
              </li>
              <li>
                Probably one of the strongest teams available come 2.3,
                Flutterpage unlocks Windsong’s short-cycle potential by
                massively boosting FUA damage on top of performing various ones
                herself. The support here comes in the form of Vila, whose [Song
                of Passion] is a known contender for best Windsong buff, and
                Lopera, who while her Array is up can spam [Incantation Bullet]
                to enhance Windsong while also providing additional FUAs for
                Lopera.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldLopera;

export const Head: React.FC = () => (
  <Seo
    title="Should you pull Lopera? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if pulling Lopera is the right move? No worries! Check our review and guide for her!"
    game="reverse"
  />
);
