import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import './characters.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { ZZZDriveSetsJSON } from '../../modules/zzz/common/data/zzz-drives-data';
import { ZZZDiskSet } from '../../modules/zzz/common/components/zzz-disk-set';
import { Row, Col } from 'react-bootstrap';
import { CategoryCard } from '../../modules/cs/main/components/category-card';

const ZZZDiskDriveSets: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page characters-zzz zzz'} game="zzz">
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>Drive Disks Sets</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/zzz/categories/category_disksets.png"
            alt="Drive Disks Sets"
          />
        </div>
        <div className="page-details">
          <h1>Zenless Zone Zero Drive Disk Sets</h1>
          <h2>List of all Drive Disk Sets available in Zenless Zone Zero.</h2>
          <p>
            Last updated: <strong>15/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="page-content">
        <SectionHeader title="Drive Disk Sets" />
        <p>
          <strong>Drive Disks</strong> are items in Zenless Zone Zero that can
          be equipped on Agents to increase their stats and if you{' '}
          <strong>
            combine the available Sets, you will unlock additional traits
          </strong>
          . These are the equivalent of Relics from Honkai: Star Rail. Each
          character has <strong>6 slots for Drive Disks</strong> and below you
          can find the list of all available sets that you can fit into them.
        </p>
        <Row xs={1} xxl={2} className="relic-set-container">
          {ZZZDriveSetsJSON.sort((a, b) => (a.name > b.name ? 1 : -1)).map(
            (disk, index) => {
              return (
                <Col key={index}>
                  <ZZZDiskSet name={disk.name} />
                </Col>
              );
            }
          )}
        </Row>
        <SectionHeader title="More information" />
        <p>
          If you want to{' '}
          <strong>
            learn more about Drive Disks themselves, do check our other guides
          </strong>
          .
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Drive Disks"
            link="/zenless/guides/disk-drives-about"
            image={
              <StaticImage
                src="../../images/zzz/categories/category_disk.png"
                alt="Drive Disks"
              />
            }
          />
          <CategoryCard
            title="Drive Disks Stats"
            link="/zenless/guides/disk-drives-stats"
            image={
              <StaticImage
                src="../../images/zzz/categories/category_disk2.png"
                alt="Drive Disks Stats"
              />
            }
          />
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default ZZZDiskDriveSets;

export const Head: React.FC = () => (
  <Seo
    title="Drive Disk Sets | Zenless Zone Zero | Prydwen Institute"
    description="List of all Drive Disk Sets available in Zenless Zone Zero."
    game="zzz"
  />
);
