import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldRum: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you build White Rum?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_rum.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you build White Rum?</h1>
          <h2>
            Not sure if building White Rum is the right move? No worries! Check
            our review and guide for her!
          </h2>
          <p>
            Last updated: <strong>06/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Should You Pull? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="white-rum"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>
          Probably not, unless you’re doing a low-rarity unit challenge. On that
          front, she’s actually one of the best low-rarity characters that have
          been released so far. If you want:
        </p>
        <ul>
          <li>A cheap and consistent follow-up attacker,</li>
          <li>
            A control unit with [Daze] who can ignore all [Daze] resistance,
          </li>
          <li>A low-rarity, non-humanoid character,</li>
          <li>Or just someone who synergizes well with Pioneer,</li>
        </ul>
        <p>then you can consider building White Rum.</p>
        <h5>Lore</h5>
        <p>
          A pirate ship who served under one of the few female pirates Anne
          Bonny, White Rum slumbered in a bottle for hundreds of years before
          Awakening. Now, the arcanist travels the world with the skeletal hand
          of her captain.
        </p>
        <SectionHeader title="Review" />
        <p>
          White Rum’s kit is pretty easy to understand. Her gameplay follows
          this general flow:
        </p>
        <ul>
          <li>During battle preparation, put White Rum in Slot 1.</li>
          <li>
            Cast her Skill 2, [Prepare to Board!]. This channel lets her cast
            her Skill 1 as a follow-up attack at the start of each round.
          </li>
          <li>
            Cast her Skill 1, [Port Side Barrage], if you want to get to her
            Ultimate faster.
          </li>
          <li>
            When White Rum has traveled all the way to the right, cast her
            Ultimate to inflict mass [Daze].
          </li>
        </ul>
        <p>
          At P5 (which you get for free), White Rum’s Insight I lets her gain 2
          [Rousing Morale] for each space she moves backward and gains 2
          [Sturdiness] for each space she moves forward (instead of 1 each at
          base). [Prepare to Board!] puts her in the [Boarding Assault] Channel
          status, which makes her cast her Skill 1 as a follow-up attack at the
          start of each round and gain 1 Moxie. Her Skill 1 moves her 1 space
          backward. Her Ultimate moves her forward as much as possible and
          ignores 100% [Daze] resistance if she moves at least 3 spaces.
        </p>
        <p>
          [Port Side Barrage] isn’t really necessary for her core gameplay loop
          since her Skill 2 casts it for free every turn. Since her channel
          doesn’t end until she casts her Ultimate, White Rum is very
          AP-generous if you don’t need her Ultimate often.
        </p>
        <p>
          IMPORTANT NOTE: The rank of her Skill 1 follow-up attack is based on
          the rank of the Skill 2 that started her Channel. This means that if
          you cast her rank 3 Skill 2, White Rum will continue casting a free
          rank 3 [Port Side Barrage] at the start of each turn until she gets
          [Control]led or casts her Ultimate.
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    A great low-rarity sub-DPS with consistent follow-up
                    attacks, performs best in AoE scenarios
                  </li>
                  <li>
                    Very strong mass [Daze] effect that can ignore up to 100%
                    resistance
                  </li>
                  <li>Extremely AP-generous</li>
                  <li>
                    Can cast a high rank FUA every turn with minimal setup
                  </li>
                  <li>Free in 2.2—including all 5 Portrays!</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Low rarity means no Insight III passive and a general lack
                    of stats.
                  </li>
                  <li>Very squishy. Also doesn’t do much damage.</li>
                  <li>
                    Not good in single-target scenarios or against enemies who
                    are immune to [Control].
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>
          You can get all 5 of White Rum’s Portrays for free! Just complete the
          “White Rum’s Travelogue” mini-game to claim her Portrays.
        </p>
        <p>
          P1 makes her get more stacks of [Sturdiness] and [Rousing Morale] per
          change in position. P4 is notable because it allows her to ignore up
          to 100% [Daze] resistance.
        </p>
        <SectionHeader title="Great Synergies" />
        <p>
          Despite not having any inherent synergy, Anjo Nala and White Rum can
          make a great Control team for AoE scenarios like the new Fog of War
          raid (though Anjo Nala is kind of just great at the raid in general).
        </p>
        <p>
          White Rum is a pretty decent AFK Eureka battery for 37 since her FUAs
          are very consistent and require no AP after the first channel.
        </p>
        <p>
          A very strange use case for White Rum exploits her Skill 2’s ability
          to let her cast a rank 3 incantation at the start of each round. While
          this requires a lot more setup than normal White Rum gameplay, getting
          a Rank 3 Skill 2 Channel out means that you can get 4 [Cosmic Energy]
          on Mercuria every turn with no AP (as long as you don’t cast White
          Rum’s Ultimate).
        </p>
        <p>
          As the only Shift characters available in the game so far, Pioneer and
          White Rum have some synergy, though not as much as you might think.
          White Rum ONLY gains [Rousing Morale] and [Sturdiness] when she is
          being shifted, which means there are blue or red arrows that appear on
          her sprite. Pioneer shifting himself backward or forward across White
          Rum (and thus changing her position) DOES NOT trigger her Insight I
          passive. Thankfully, his Ultimate does count as a Shift effect on
          White Rum, allowing you to move her to the back right before her
          Ultimate without having to wait.
        </p>
        <p>
          Fatutu is an upcoming healer who synergizes well with follow-up
          attackers in general. Her ability to split incoming damage among
          allies and her very frequent healing can help White Rum’s
          survivability in high-pressure scenarios.
        </p>
        <p>Notable synergies include:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="anjo-nala"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="37"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="mercuria"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="pioneer"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>Future notable synergies:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="fatutu"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>Notable Anti-Synergies:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="argus"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          Despite being able to output consistent high-rank cards, White Rum
          actually doesn’t synergize with Argus at all. Since Argus’ Graverobber
          FUA only takes the cards in the AP Area into account, it doesn’t count
          White Rum’s FUA at the start of the round. The high rank will still
          increase the number of [Pinpointed] stacks, but Argus is probably
          better off teaming up with someone else.
        </p>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on White Rum:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="balance-please"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="another-story"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="same-old-story"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="the-severed-and-the-rest"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          “Balance, Please” is the best option for White Rum to do damage, as
          its passive works perfectly with her kit. As an Intellect character,
          White Rum gets the full 12–24% DMG boost; in addition, a lot of her
          damage comes from her Ultimate so the Ultimate Might is pretty good
          for her, too.
        </p>
        <p>
          “Another Story” is a decent option, especially if you already have it
          built. It’s pretty easy to get White Rum to use a rank 2 Channel and
          stack up the DMG bonus. The Ultimate Might substat works well for her.
          White Rum doesn’t have Eureka, though, so she can’t get the
          Incantation Might buff.
        </p>
        <p>
          “Same Old Story” is a decent option, as well. While she loses out on
          the 5% Penetration Rate, the 12%–28% DMG bonus for mass attacks is
          great since it also applies to her non-FUA Skill 1 as long as she’s
          channeling. It doesn’t really help with her Ultimate’s damage, though.
        </p>
        <p>
          “The Severed and the Rest” is one of this version’s anecdote
          psychubes, and it actually works alright on White Rum. The Mental DEF
          substat is hot garbage but White Rum can get the PEN Rate from being
          an Intellect character and can stack up the DMG Dealt pretty easily,
          making this psychube a niche budget option.
        </p>
        <SectionHeader title="Resonance" />
        <h6>Resonance Patterns</h6>
        <StaticImage
          src="../../../images/reverse/generic/rum_r_1.webp"
          alt="Guides"
        />
        <SectionHeader title="Teams" />
        <p>
          3-People teams are highlighted, with the 4th slot added at the side to
          provide a 4-P variation of the same team or even insights on possible
          variations of the 3-P team.
        </p>
        <h5>Standard Teams</h5>
        <div className="team-box">
          <h5 className="star">
            Complete Control
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="anjo-nala"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="white-rum"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="medicine-pocket"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options:{' '}
                <strong>Darley Clatter, An-an Lee, Sweetheart, Leilani</strong>
              </li>
              <li>
                Credit goes to 风风风风驿驿 on Bilibili for their showcase on
                this team.
              </li>
              <li>
                This team is best in an AoE scenario with enemies susceptible to
                [Control], like the Fog of War raid.
              </li>
              <li>
                Anjo Nala provides lots of damage and consistent [Control]
                through [Captivated].
              </li>
              <li>
                White Rum’s Ultimate can supply some extra mass [Daze] during
                Anjo Nala’s downtime.
              </li>
              <li>
                Medicine Pocket keeps the team healthy while providing
                single-target [Daze].
              </li>
              <li>
                In 风风风风驿驿’s video, they put Darley Clatter in a contract
                with Anjo Nala for fast Ultimate cycling.
              </li>
              <li>
                Feel free to substitute in other [Daze] characters like An-an
                Lee and Sweetheart if you have them built; Leilani also works as
                a Moxie battery if you don’t want to use Darley Clatter.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            A Ship, a Mannequin, and a Boy Walk into a Disco…
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="white-rum"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="pioneer"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="avgust"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options:{' '}
                <strong>
                  Yenisei, Barbara (2.1), Sotheby, Any Other Healer, Lorelei
                </strong>
              </li>
              <li>
                This is an ultra budget team that maximizes sliding shenanigans.
              </li>
              <li>
                Pioneer can help position White Rum at the very back to set up
                for her Ultimate if she slides out of place.
              </li>
              <li>
                As both White Rum and Pioneer like dealing more damage up front
                and taking less damage in the back, Avgust’s kit perfectly
                synergizes with them.
              </li>
              <li>
                Both Pioneer and Avgust have some survival capabilities, though
                it wouldn’t be a bad idea to add in a proper healer.
              </li>
              <li>
                In 4-P content, Yenisei makes for a synergistic healer as her
                [Sturdiness] stacks with White Rum’s and Avgust’s while her
                single-target heals can be used on Pioneer since he’ll be taking
                the bulk of the damage.
              </li>
              <li>
                Barbara is another great 5* half-support, half-sustain like
                Avgust. She can rank up Avgust’s attack for dispelling purposes,
                increase Mental DMG (which Pioneer and Avgust do), and give
                everyone big shields to make up for the lack of sustain.
              </li>
              <li>
                With Avgust’s access to [Cure], Sotheby becomes a much more
                viable healer if you have her at I3. Any other healer works well
                too.
              </li>
              <li>
                Lorelei is a great budget buffer who doesn’t overlap with anyone
                except Avgust. Her [Weakness] debuff helps these squishy
                characters survive, and her Crit DMG and DMG Taken Reduction
                buffs stack with Pioneer’s [Roving Salesman]. However, since
                this team will mostly have Attack cards, her Insight I will
                grant +15% DMG Dealt most of the time. Avgust grants +DMG Dealt
                upon attacking, so you may want to save up the attack card for
                an enemy dispel instead.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Mmm Cosmic Energy<span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="white-rum"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="mercuria"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="vila"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options:{' '}
                <strong>Any DPS, Marcus, Windsong, Anjo Nala</strong>
              </li>
              <li>
                For people who didn’t pull for Argus but want Mercuria to get
                more [Cosmic Energy] stacks—on a budget.
              </li>
              <li>
                As mentioned above, with good RNG and some setup, you can get
                White Rum to act as an AFK battery who will generate 4 [Cosmic
                Energy] at the start of each turn. All you need to do is cast
                White Rum’s Skill 2 at Rank 3 and never use her cards again for
                the rest of the battle.
              </li>
              <li>
                Mercuria and Vila obviously work well together and need no
                introduction.
              </li>
              <li>
                Slot in just about any DPS who likes Crit and this team will
                work well.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldRum;

export const Head: React.FC = () => (
  <Seo
    title="Should you build White Rum? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if building White Rum is the right move? No worries! Check our review and guide for her!"
    game="reverse"
  />
);
