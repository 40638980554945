import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NikkeGuidesRapi: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Rapi: Red Hood guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_rapi.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Rapi: Red Hood & Review</h1>
          <h2>
            A guide & review for Rapi: Red Hood in NIKKE: Goddess of Victory.
          </h2>
          <p>
            Last updated: <strong>03/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Rapi: Red Hood" />
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon"
                  slug="rapi-red-hood"
                  enablePopover
                />
              </div>
              <p>
                Shiftup writers: "How much trauma do you want for Rapi's past?"
              </p>
              <p>Shiftup CEO: "Y E S".</p>
              <p>
                An utterly heart wrenching start to her life, but an upwards
                spiral to her life ever since she met Red Hood. As a century
                passes, she herself is now Red Hood, an ideal she has always
                wanted to achieve. It's time to dive deep into her skills to see
                if she can match up in skills to the all time legend and current
                best unit in the game!
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                New year, new beginning. Rapi: Red Hood is the culmination of
                one's long lost dreams and journey, the pinnacle of a maiden's
                growth, and a testament to hope and strength. Rapi: Red Hood is
                a DPS/support character that imitates Red Hood and changes how
                teams work in unimaginable ways. Her Burst Skill can be used in
                Stage I and III depending on what the team needs, and not only
                that, she also deals bonus elemental damage to Wind and Electric
                enemies at the same time thanks to her inheriting Red Hood's
                power.
              </p>
              <p>
                As a B1 CDR, she is a direct replacement to Liter in some
                compositions, allowing for faster rotations, more overall team
                damage, and increased damage output. She does not have the bells
                and whistles Liter has (~80% ATK buff), nor does she provide
                survivability bonuses such as cover repair. However, her
                personal damage is not something to underestimate. Even as B1,
                her damage rivals some of the highest B3 DPS. While she might
                lower the team's damage a tiny bit, she compensates for it with
                her own damage that is probably tens of times the difference.
                Furthermore, her instant CDR comes clutch in many situations
                where one cannot keep full-charging (DWife/Rouge), giving her a
                slight advantage in high-deficit campaigns. In bossing, this CDR
                also helps the team rotate faster and squeeze in one more Full
                Burst at the very end.
              </p>
              <p>
                As a B3 DPS, she is a harbinger of death. Explosive AoE damage,
                intense single-target damage, and burst damage all in one. No,
                unlike Alice with her savage 10s of "fully charged basic attacks
                that pierce and slaughter everything they touch", Rapi: Red Hood
                maintains a more ladylike demeanor with her more consistent
                output. Her damage does spike during her Burst, but she can
                still be used in off-burst position and still be incredible too.
                She excels in both bossing and mobbing thanks to MG's perfect
                accuracy, which significantly boosts her damage. However, be
                careful when using her in Campaign with Red Hood B1. If you
                don't bring another B1 like Liter/DWife, she will be forced to
                become B1 because Red Hood is not registered as a B1 unit.
              </p>
              <p>
                Overall, in terms of usage, she excels best in bossing. Her
                duality as an Iron and Fire DPS makes her more economical to
                build as she covers two bases at the same time. Currently, when
                it comes to Anomaly Interception, she has allowed more players
                to reach S9 in Indivilia and has proven capable of replacing
                Liter in Harvester/Kraken and Maxwell in Ultra. That's some
                presence there. In Union and Solo Raid, she can handle two
                elements, allowing players to come up with more variants of
                three-team combinations.
              </p>
              <p>
                In Campaign, she can be used as either a B1 or a B3. She can
                replace DWife in 2 B1 comps and become a B3 in
                B1-Crown-Naga-B3-B3 comps or RHB1-B1-Crown-B3-B3. Meanwhile, her
                performance in PvP is not astonishing. She has promising
                continuous AoE damage in Burst, able to hit all 5 enemies at the
                same time with her S2 explosion. She also deals bonus damage to
                Electric units, which pervade PvP like crickets. However, she is
                counterable by Rosanna, Jackal, and Scarlet; has no burst gen;
                and may be a liability more often than a tool on defense. Good
                attacking unit, nevertheless.
              </p>
              <p>
                Oh, and one more thing, she is very FPS dependent, like other MG
                users. If you experience FPS drops or low FPS too often, she
                will feel awful to use.
              </p>
              <h5>Should I pull this unit?</h5>
              <p>
                Absolutely. A legendary unit that shifts the meta in her favor.
                Rapi is an extremely reliable jack of all trades. In fact, her
                value is much higher than any other DPS Pilgrims out there with
                her two elemental advantages. One unit for two elements means
                you get twice the value of your investments. That's some
                bargain. Both beginners and veterans would benefit from having
                her, and not having her will likely place your account at a high
                risk of being permanently disadvantaged.
              </p>
              <p>
                How about dupes? As aforementioned, investments in her yield
                twice the value than usual. And since people will invest a lot
                in her equipment, she is likely to have tons of CP as well. What
                does this mean? While she might not be the perfect choice for
                all stages in high-deficit Campaign, the CP padding might make
                stages easier as that is basically equivalent to increasing the
                ATK of all units. There is also service in increased stat for
                other content.
              </p>
              <p>
                She is worth the mileage. MLB is advised if you have surplus
                resources but be mindful of the upcoming EVA 2 collab in
                February, which will likely be of the Wind element as the
                Christmas stream shows that Unknown Object is now yellow in
                color and therefore Iron (hypothetical).
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <p>
                Rapi has a unique talent that allows her to transform into a B1
                or B3 unit depending on the team.
              </p>
              <ul>
                <li>
                  As a B1 unit, she is more of a support unit with
                  low-maintenance CDR that deals a substantial amount of
                  personal damage on the side.
                </li>
                <li>
                  As a B3 unit, her full DPS potential is unleashed, and she
                  becomes an untamable beast, dealing over 2x more damage.
                </li>
              </ul>
              <h5>To Damage or To Damage</h5>
              <blockquote>
                <p>
                  ■ Activates at the start of battle and at the end of Full
                  Burst.
                </p>
                <p>
                  Effects differ according to squad formation. Activates only
                  the corresponding
                </p>
                <p>Affects self if there are no Burst Stage I allies.</p>
                <p>Combat Assist: Changes to Burst phase 1 continuously.</p>
                <p>Affects self if there are Burst Stage 1 allies.</p>
                <p>Removes Combat Assist.</p>
                <p>
                  ■ Affects all allies if in Combat Assist status when entering
                  Full Burst.
                </p>
                <p>Cooldown of Burst Skill ▼ 7.48 sec.</p>
                <p>Attack Damage ▲ 8.02% for 10 sec.</p>
                <p>
                  ■ Affects self if not in Combat Assist status when entering
                  Full Burst.
                </p>
                <p>ATK ▲ 95.04% for 10 sec.</p>
                <p>Damage to interruption Parts ▲ 48% for 10 sec.</p>
              </blockquote>
              <p>
                Depending on whether the team needs B1, Rapi: Red Hood changes
                her form to accommodate the team.
              </p>
              <ul>
                <li>
                  If there is no Burst Stage I unit in the team, she gains the
                  status effect Combat Assist, with which she can buff the
                  team's ATK DMG and gives CDR upon entering Full Burst.
                </li>
                <li>
                  If there is a Burst Stage I unit, she will stay as a B3 unit
                  and not obtain Combat Assist. She does not buff the team but
                  provides herself with a significant amount of ATK for 10s upon
                  entering Full Burst.
                </li>
              </ul>
              <p>
                This condition is checked at the start of battle and at the end
                of every Full Burst, allowing her to replace your B1 shall they
                perish in battle.
              </p>
              <Alert variant="primary">
                <p>
                  Note that units with skills that can be used in multiple Burst
                  Stage(s) like Red Hood and Rapi: Red Hood are not regarded as
                  B1 units.
                </p>
              </Alert>
              <p>Now, let's talk about the buffs themselves:</p>
              <ul>
                <li>
                  In B1 form, the <strong>▼7.48 sec</strong>. CDR Rapi gives as
                  B1 is huge and instant, comparable to Rouge and DWife but
                  without the prerequisite of having to Full Charge 8 times.
                  This is an important thing to note in Campaign—where in high
                  deficit units must sometimes remain in cover to dodge
                  attacks—and against bosses where faster rotations are feasible
                  (such as against Indivilia/Ultra in Anomaly). Those 0.5s time
                  saved each Full Burst will rack up, and by the end of a
                  battle, allow you to trigger one more Full Burst(s) or at
                  least benefit from longer final Full Burst.
                </li>
                <li>
                  Her{' '}
                  <strong>
                    ATK DMG ▲8.02% and ATK ▲18.01% of caster's ATK
                  </strong>{' '}
                  may not seem a lot when compared to other CDR units in their
                  peak form (DWife's pierce damage buff for snipers is stronger,
                  etc), but they are universal and easy to use. What makes her
                  ridiculous, though, is the fact that she still deals damage
                  even as a support B1. While her weaker buffs may result in
                  weaker damage from the team, she easily compensates for that
                  with her personal DPS.
                </li>
                <li>
                  In B3 form, Rapi's personal DPS is greatly enhanced thanks to
                  the <strong>▲95.04% ATK</strong>. She also gains access to her
                  B3 Skill, which deals a medium amount of burst damage and
                  temporarily reduces the number of shots needed to trigger
                  explosives by half. Alongside the increased projectile
                  attachment damage bonus, this takes her personal damage up a
                  notch.
                </li>
              </ul>
              <h5>Low Risk, High Reward</h5>
              <blockquote>
                <p>■ Activates at the start of battle. Affects self.</p>
                <p>
                  Applies damage as strong element to Electric Code enemies
                  continuously.
                </p>
                <p>Projectile attachment damage ▲ 150.72% continuously.</p>
                <p>Projectile explosion damage ▲ 100.6% continuously.</p>
                <p>■ Activates after 120 normal attack(s).Affects self.</p>
                <p>
                  Attachable Projectiles Effect: Launches attachable projectiles
                  that attach to hit locations.
                </p>
                <p>When entering Full Burst, the projectiles explode.</p>
                <p>
                  Projectile attachment damage: Deals 88.11% of final ATK as
                  damage.
                </p>
                <p>
                  Projectile explosion damage: Deals 88.11% of final ATK as
                  damage.
                </p>
                <p>Max Ammunition Capacity: 1 round(s).</p>
              </blockquote>
              <p>
                Rapi, inheriting the power of Red Hood, gains her core element
                and deals bonus damage to both Wind and Electric enemies at the
                same time. This makes her a trustworthy DPS in twice the number
                of situations and saves you the cost of upgrading a secondary
                DPS. Being able to harass bosses like Ultra and Indivilia in
                Anomaly Interception makes her an efficient unit to build for
                beginners and resource-conscious late-gamers.
              </p>
              <p>
                This skill also stores all the multipliers of her explosives,
                which the game calls projectiles.{' '}
                <strong>
                  Projectile attachment damage ▲ and Projectile explosion damage
                  ▲
                </strong>{' '}
                are considered part of the Attack Damage group in formula.
              </p>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> What are
                  projectiles?
                </h6>
                <p>
                  Projectiles are basically Rapi: Red Hood's secondary mode of
                  attack that is launched every 60~120 landed basic attacks (1~2
                  sec. @ 60 FPS; longer if lower FPS). Launched projectiles deal
                  damage upon impact (can core hit, can crit) and stick to
                  enemies (or explode immediately on certain surfaces). Upon
                  entering Full Burst and throughout Full Burst, these
                  explosives spontaneously combust, dealing AoE damage (cannot
                  corehit, can crit). This AoE damage can hit multiple enemies
                  or parts.
                </p>
              </blockquote>
              <p>
                According to our math, projectiles constitute half of her damage
                as B3 and slightly less as B1. You want to make sure that this
                skill is highly leveled, so her damage can be maximized.
              </p>
              <h5>How Large is Too Large?</h5>
              <blockquote>
                <p>When used in Stage I: Squad Support Action Style</p>
                <p>■ Affects self.</p>
                <p>Cooldown of Burst Skill ▼ 20 sec.</p>
                <p>Explosion Radius ▲ 100.62% for 10 sec.</p>
                <p>■ Affects all allies.</p>
                <p>ATK ▲ 18.01% of caster's ATK for 10 sec.</p>
                <p>
                  When used in Stage 3: High-Mobility Close-Range Combat Weapon
                </p>
                <p>■ Affects the enemy nearest to the crosshair.</p>
                <p>Deals 2808% of final ATK as additional damage.</p>
                <p>■ Affects self.</p>
                <p>Explosion Radius ▲ 100.62% for 10 sec.</p>
                <p>Projectile attachment damage ▲ 421.2% for 10 sec.</p>
                <p>
                  Skill 2: attachable projectiles trigger count condition▼ 60
                  times for 10 sec.
                </p>
              </blockquote>
              <p>
                Based on the form determined by S1, Rapi: Red Hood can use
                either her B1 Skill or B3 Skill but not at the same time (unlike
                Red Hood). Her B1 skill is more supportive, whereas her B3 skill
                is more for herself.
              </p>
              <p>
                <strong>B1 Form: More ATK for the Team</strong>
              </p>
              <p>
                Rapi temporarily enhances the team's ATK based on her own ATK,
                which means the buff is as effective on Defenders as it is on
                Attackers. <strong>ATK ▲18.01%</strong> is not that big but it's
                easily felt nonetheless. Additionally, if she uses B1 Skill, she
                also reduces her own CD by -20s. This allows her to continue
                using B1 every rotation (unlike RH that is locked to 40s).
              </p>
              <Alert variant="primary">
                <p>
                  If Rapi uses B3 Skill, then a B1 dies in the team, forcing her
                  to switch forms, then she cannot immediately use B1 Skill in
                  the next rotation as the -20s CDR only triggers upon her using
                  B1.
                </p>
              </Alert>
              <p>
                <strong>B3 Form: More DMG for Herself</strong>
              </p>
              <p>
                Rapi deals a considerable amount of instant damage to the enemy
                closest to her crosshair, then massively boosts her projectile
                attachment damage. She also launches projectiles twice as often
                as the number of shots required is cut by half (from 120 → 60,
                2s → 1s @ 60FPS). This greatly increases the damage she deals
                from S2, but it does not affect her raw basic attack damage.
                <strong>
                  Therefore, the effectiveness of this skill is dependent on the
                  investments of S2
                </strong>
                .
              </p>
              <p>
                In both forms, Rapi's Burst Skill temporarily expands the range
                of her projectile explosions, allowing them to reach more
                enemies/parts and even accidentally destroying terrain, which
                can be a good thing or a bad thing. For example, it allows her
                to reach Mirror Container's Glass Slipper, which is probably not
                a good thing because she is unlikely to destroy them in one
                shot, making her clunky to use against it (you can cover,
                however). Terrain is also useful for burst generation in
                Campaign. If all environmental objects are destroyed, then
                recharging Burst Energy can become more difficult. On the
                contrary, this skill allows her explosions to hit all enemies in
                PvP at the same time. It also helps the team clear flying rocks
                against Harvester AI.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Usage Analysis and Tier List" />
              <h5>
                Campaign (LD) B1/B3 - <strong className="sss">SSS</strong>/
                <strong className="sss">SSS</strong> | Campaign (HD) B1/B3 -{' '}
                <strong className="sss">SSS</strong>/
                <strong className="sss">SSS</strong>
              </h5>
              <p>
                Rapi in their B1 and B3 forms have different uses in campaign
                and can be considered meta replacements to old units. Best unit
                in Elysion Tower.
              </p>
              <p>
                As a B1, she can go either solo or with another B1 like Red
                Hood, providing ATK & ATK DMG buffs for the team and
                contributing some personal damage. She pairs well with the likes
                of Crown as she does not enjoy her <strong>▲95.04% ATK</strong>{' '}
                whilst having Combat Assist. But, what makes her special is her
                instant CDR, which is more convenient than DWife's. It does not
                require 8 Full Charge(s) to trigger, so even if your team is
                fully in cover for the whole Full Burst, your next rotation
                still won't be stalled. The downside? Well, DWife can trigger
                her CDR multiple times over one rotation, and this in some cases
                can allow for faster clears with mob stalling strategy. DWife's
                ATK DMG + Pierce DMG (+ core damage) combined is also still
                stronger than Rapi's buffs for piercer snipers overall. However,
                Rapi's prowess comes from her self DPS. Alongside Crown, she is
                buffed every burst rotation and hence is still able to deal MEGA
                damage. She is just too good! You can 2-1-2 comp with Red Hood
                as the main B1 and her as the secondary B1, or you can run a
                1-1-3 comp.
              </p>
              <p>
                As a B3, Rapi is just your regular ol' wave clearing unit—well,
                mostly single target—that can quickly decimate enemies in her
                Burst. She can pair RH, Asuka, Rei, Alice, or Mod, literally
                anyone that would conform to the stage design better. The only
                problem is that she cannot be in a RHB1-B2-B3-B3-B3 comp as that
                will force her into B1, so the only choice to run her in a 1-1-3
                comp is with DWife or Liter. However, nowadays, people have
                shifted into a 2-1-2 comp or 1-2-2 comp depending on whether
                Naga or CDR is more needed for a stage. Making the shift from
                1-1-3 should not be a huge problem for anyone.
              </p>
              <p>
                Realistically speaking, Rapi will fit into ANY team. She does
                have special synergy though due to her being Fire. In both
                forms, Rapi has excellent synergy with Asuka and Rei, which are
                good units in Campaign, as well as a wide range of applicability
                thanks to her double element shticks. She can integrate into
                many teams and provide them what they need. For example, in Iron
                stages, you can do Liter Crown RH Rapi Naga, and in Fire, you
                can do RH-B1 Crown Rapi Alice Naga. Glasses' hitcount shield is
                a problem for your team? Slot her in. She can directly replace
                Modernia! A straight powercreep to Modernia in all senses. Just
                in general she will perform insanely well as a B3 unit in MOST
                campaign stages, since more than half of them are either Iron or
                Fire-weak (Fire being most common while Iron being just a little
                less common than Electric-weak).
              </p>
              <p>
                Additionally, since people will be investing a lot in her, she
                will naturally have higher CP than the other CDRs. Even if they
                were theoretically better choices in some situations, the CP
                padding provided by Rapi is more likely going to have more
                impact.
              </p>
              <p>
                Do note that she lacks one thing—burst gen—so make sure to pair
                her with units that give them. Another problem is the fact that
                her projectile explosions destroy terrain, making it hard for
                you to utilize them for burst gen.
              </p>
              <h5>
                Bossing B1/B3 - <strong className="sss">SSS</strong>/
                <strong className="sss">SSS</strong>
              </h5>
              <p>
                Both forms of Rapi deserve SSS in the Bossing category as well
                due to her stellar performance, but likewise, there are pros &
                cons of using her versus other units.
              </p>
              <p>
                Let's talk about her B1 first. Against some bosses and in some
                comps, she has proven to edge out Liter in terms of total team
                damage thanks to her personal damage output and instant CDR. The
                instant CDR helps a lot in securing more time for the final Full
                Burst or giving you one more Full Burst, which will notably
                grant more damage than just outright buffing. The problem? She
                is fully offensive, and she does not provide the team any
                survivability bonuses (except for the fact that she helps clear
                dangerous projectiles and mobs nearby). There is no cover repair
                like Liter's or healing like Rouge's. You need to make good use
                of your resources to make sure your team lasts a full run.
              </p>
              <p>
                Rapi in her B3 form is an absolute menace. She just deals a lot
                of damage, rivaling Alice. Not only that, she does that to two
                elements, Wind and Electric. She is like Alice and Red Hood
                amalgamated into one, a perfect combination for newer players
                who do not have access to materials necessary for upgrading
                both. Even though her damage would spike with the presence of
                core, since a substantial sum of her damage is from skill
                damage, her damage is quite stable and she still fares well
                against core-less enemies, unlike Red Hood. Her explosions can
                also cleave, hitting multiple parts at the same time,
                effectively doubling or tripling the damage or even more
                depending on how many parts are hit.
              </p>
              <p>
                Since Rapi is considered a Fire AND an Iron unit, she can deal
                damage to enemies even behind Electric shields and destroy
                Electric QTEs meant for Iron units. This allows her to handle
                Ultra and Indivilia for people without an extensive roster.
                However, as mentioned earlier, she lacks burst gen, so make sure
                not to desiccate your team by not having any burst gen unit at
                all. For instance, Liter Crown Asuka Rapi Naga is slow, but
                Liter Crown Rapi Alice Naga is fast because Alice is a good
                generator. You can also use Grave to compensate in low-burst gen
                teams.
              </p>
              <h5>
                PVP B1/B3 - <strong className="c">C</strong>/
                <strong className="a">A</strong>
              </h5>
              <p>
                C for Champions, A for Arena. We probably mention champions
                arena more than the number of discussions Shift Up has had for
                it. Anyways, back to the review. First of all, let's just start
                with the elephant in the room. Rapi has no burst gen, feeds the
                enemy team immensely, and while you may think her ST damage is
                high, PVP has a unique trait: 30 FPS! It means Rapi will shoot
                50% slower no matter how strong your device is, therefore this
                lowers her potential damage by at least 50% too. At least she
                still has decent AoE during her Burst if she is used as a B3,
                therefore opening up some interesting cheese. She is able to hit
                all 5 units of she Bursts and can kill the enemy team faster
                than the likes of Emilia if the enemy team has little
                survivability. She is an attack oriented Nikke, and very weak on
                the defending side due to her feed and lack of burst gen. That’s
                her B3’s PVP review. As for B1, the question you should be
                asking is, “Why would I even use her as B1 in PvP?”. Just don't
                use her ideally, she's more-so a replacement of you have a
                bare-bone roster. Her only real team is as a B3 alongside Blanc
                if Blanc is able to burst before RRH dies.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                Rapi is an MG unit, so what she needs most is Max Ammo, followed
                by ELE & ATK like most DPS do. At least 3× Max Ammo for smooth
                rotations and ensuring she can fire 600 shots in one Full Burst
                + 300 extra outside FB, with Resilience + Crown (strat: reload
                before every Full Burst). Outside Reload Speed comps, she might
                need 4× + Bastion to last 2 rotations without reloading. That
                aside, she technically gains twice the value of Elemental DMG
                thanks to her double element gimmick.
              </p>
              <ul>
                <li>
                  <strong>Essential:</strong> 3× Max Ammo
                </li>
                <li>
                  <strong>Ideal:</strong> 4× ELE, 4× ATK, 1× Max Ammo
                </li>
                <li>
                  <strong>Passable:</strong>
                </li>
                <li>
                  <strong>Priority:</strong> Meta
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <p>
                Rapi scales well with investments and is worth instant 10/10/10.
                There are no actual breakpoints, but if we were to create one,
                then 7/7/7 → 10/10/10 with no specific ordering. All skills are
                important, but some may be less critical depending on how you
                want to use her primarily. Scaling wise, Skill 2 has the highest
                scaling, followed by Burst Skill, then Skill 1. Supports can
                focus on S1 and Burst Skill, while DPS should focus on
                everything.
              </p>
              <ul>
                <li>
                  <strong>Skill 1: 10</strong>
                </li>
                <ul>
                  <li>
                    This skill stores all the ATK buff she has and the amount of
                    CDR/ATK DMG she grants in Full Burst. Level increases allow
                    for faster rotations, but the increased CDR may be useless
                    if you are not rotating fast enough anyway. ATK buff is good
                    but it's also diluted by external buffs and OL. Lower than
                    regular 1.69x scaling, sitting at 1.4× for CDR and 1.6× for
                    buffs.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 10</strong>
                </li>
                <ul>
                  <li>
                    Where her skill damage multipliers lie. It also contains
                    buffs that also enhance its own attacks, so double the
                    value, making upgrading this skill of a higher priority.
                    More important when dealing with enemies without core
                    because her basic attack will have less impact. This skill
                    warrants the most upgrades because the scaling is 1.60/1.69
                    × 1.77 = ~2.832×/2.99× (albeit dilution from outside buffs),
                    but it only affects her personal damage and not team buff.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill:10</strong>
                </li>
                <ul>
                  <li>
                    The scaling for B1 ATK buff gain is huge, but the scaling
                    for Burst DMG and attachment DMG buff are even bigger.
                    Upgrades also increase explosion range (which is more
                    important for DPS than for support).
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="rapi-red-hood" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_3_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <p>
                In Reload Speed comps (with Crown), Resilience is the better
                choice. Make sure to reload before entering Full Burst to ensure
                10s of continuous shooting. Note that Crown's reload buff
                lingers for 5s after ending Full Burst. Otherwise, Bastion is
                likely the better choice but make sure to do some testing to see
                which works best for your team, rotation, and investments.
              </p>
              <h5>Doll Investments</h5>
              <p>
                Due to her prevalence in PvE content, SR 15 is warranted, but MG
                does not benefit much from MG dolls' weapon-exclusive skill.
                Nevertheless, SR 15 doll still gives a lot of CP to lower
                penalty in CP-oriented contents, and more ATK never hurts.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: Iron Man Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="naga" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                This team is very strong to deal against Iron weak, for example
                Ultra in A.I mode or Stormbringer/Material H in Campaign. You
                can both buff Rapi and gain burst gen using Red Hood, and Liter
                + S.Helm will allow you to reach no CDR faster instead of
                waiting at least 2 Full Burst rotations. May we use this team
                anywhere else? Yes, because they are so versatile (use Naga
                instead of S.Helm in that case)
              </p>
              <h5>Team #2: Fe-Male Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="naga" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                A variation of Iron man team, but this time Rapi will play as
                B1. This team is used when you want to make use of your other
                Iron DPS, be it either Laplace (Treasure), Maxwell or Soda:
                Twinkling Bunny
              </p>
              <h5>Team #3: Firefighter</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="grave" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley"
                      enablePopover
                    />
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="modernia" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                You can go full fire here and have Rei to protect the team with
                her op shield, or relying on Liter and her cover repairing
                trait. Asuka or Rei can buff Modernia + Rapi and still deal good
                damage themselves.
              </p>
              <h5>Team #4: Anti Nvidia</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="alice" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="naga" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                With the release of Rapi, Indivilla is no longer that difficult
                anymore. As a B1, she allows faster rotation and still deals a
                lot of damage to the boss. Because you are running Rapi as B1,
                this team still relies on Alice doing the heavy work, with Asuka
                as both buffer + damage dealer.
              </p>
              <h5>Team #5: Dual B1 with Red Hood</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Both Red Hood(s) working together! Dual B1 has been a popular
                alternative in high-deficit campaigns when extra core damage
                from Naga is not necessary. It allows you to benefit from Red
                Hood’s huge chunk of ATK as well as Rapipi’s ATK DMG/CDR every
                rotation. In this team, Rapi replaces the usual Liter/DWife and
                acts as a secondary DPS for the team while buffing both your B3
                choices simultaneously. Although Rapipi doesn’t get her own ATK
                buff here, she eats RH’s and Crown’s ATK buff (and if you bring
                Asuka and Rei, their buffs too). She can also help the team
                break hitcount shields and projectiles (like Glasses’).
              </p>
              <h5>Team #6: Generic B1</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                Rapi: Red Hood can be said equivalent to Liter or better in some
                comps thanks to her ATK DMG and instant CDR. Crown here is just
                a placeholder, and you can definitely use other options like
                Grave. The FLEX can be Naga if the enemy has core, etc. In this
                team, Rapi acts as a secondary DPS and buffer that buffs the two
                B3 DPS you bring. For example, against Harvester in AI, you can
                use Rapi Crown XLud QuencyEQ Naga. Against Kraken in AI, you can
                use Rapi Crown SBS Alice Naga. Or perhaps you want to be the
                first (probably not) to clear Mirror Container Hard Campaign
                with Rapi Crown Cindy Ein SAnis. There are many variations of
                this team. Experiment which B1 works best for you, but don’t
                forsake survivability completely.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvP Team Compositions" />
              <h5>Zombie Rapi</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                This is a 3 RL zombie Rapi which revolves around heavy buffing
                Rapi and hoping she can kill everything before Indomitable runs
                out. Most of the time, Rapi can trigger her S2 5 times, and
                unless you are fighting heavily stall team (Noah + Noise)
              </p>
              <h5>Stall Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noah" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noise" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                The stall comp featuring Noah + Noise. Problem is, without
                buffer, Rapi will deal pretty lame damage herself due to the
                nature of PVP Mode (locked 30 FPS), hence it won’t be as good as
                Anis: Sparkling Summer or Scarlet: Black Shadow version.
              </p>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Top 2 highest DPS in the game on her release.</li>
                        <li>
                          She has constant AoE. It has a fairly large range and
                          can hit all 5 enemies at the same time in PvP during
                          Burst.
                        </li>
                        <li>
                          Her AoE can cleave and hit multiple boss parts at the
                          same time, boosting her damage.
                        </li>
                        <li>
                          Extremely versatile as she can adapt to B1 even during
                          the match if someone dies.
                        </li>
                        <li>
                          Don’t have weird 4s ~ 2s CDs after the first and
                          second full burst like Liter.
                        </li>
                        <li>
                          Her DPS as a B1 even rivals some mid-high tier B3 DPS
                        </li>
                        <li>
                          Build one, get two because she kills both Iron weak
                          and Fire weak enemies.
                        </li>
                        <li>
                          Managed to do the impossible: SAVED ELYSION TOWER! All
                          it took was 2 years and 2 months, Ingrid.
                        </li>
                        <li>
                          A unit worthy of the name: Red Hood. Competes directly
                          with her for best unit in-game.
                        </li>
                        <li>
                          The cutest ever as a child. The cutest ever as an
                          adult. The cutest ever as a Nikke. Simply, the cutest
                          ever!
                        </li>
                        <li>
                          MG has perfect accuracy and can break hitcount
                          shields/projectiles.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          1% rate like Pilgrims, expect to spend your gold
                          mileage instead.
                        </li>
                        <li>
                          Extremely costly unit, consume too many skill books +
                          Custom Modules for gears.
                        </li>
                        <li>
                          FPS-dependent, hence she won’t perform well on low-end
                          to medium-spec devices.
                        </li>
                        <li>
                          Weaker in PVP due to the natural of that mode (forced
                          30 FPS)
                        </li>
                        <li>Low burst gen.</li>
                        <li>No survivability support as B1.</li>
                        <li>
                          The only real downside is in Campaign, where she
                          cannot be used as a B3 alongside Red Hood solo B1.
                        </li>
                        <li>
                          Account gets bricked in 2 elements if you do not have
                          her.
                        </li>
                        <li>
                          Hold hands and hug, really? Where is the spice in bond
                          story with the Commander!
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesRapi;

export const Head: React.FC = () => (
  <Seo
    title="Rapi: Red Hood Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Rapi: Red Hood in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
