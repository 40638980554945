/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { AKESkills } from '../data/endfield-skills';

interface IProps {
  id: string;
  character_rarity: string;
  type?: string;
}

export const EndfieldSkill: React.FC<IProps> = ({
  id,
  character_rarity,
  type
}) => {
  const [originalskillDesc, setOriginalskillDesc] = useState(
    AKESkills[id].description
  );
  const [skillDesc, setSkillDesc] = useState(originalskillDesc);
  const [currentLevel, setCurrentLevel] = useState([1]);
  const [color, setColor] = useState('#979797');

  useEffect(() => {
    if (character_rarity === '4') {
      setColor('#8A5FCC');
    } else if (character_rarity === '5') {
      setColor('#B9AF28');
    } else if (character_rarity === '6') {
      setColor('#d57224;');
    }
  }, []);

  useEffect(() => {
    const newString = originalskillDesc
      .replaceAll(`Physical DMG`, `<u class="Physical">Physical DMG</u>`)
      .replaceAll(`Physical RES`, `<u class="Physical">Physical RES</u>`)
      .replaceAll(`Electric DMG`, `<u class="Electric">Electric DMG</u>`)
      .replaceAll(`Electric RES`, `<u class="Electric">Electric RES</u>`)
      .replaceAll(
        `Electric Infliction`,
        `<u class="Electric">Electric Infliction</u>`
      )
      .replaceAll(`Cryo DMG`, `<u class="Cryo">Cryo DMG</u>`)
      .replaceAll(`Cryo RES`, `<u class="Cryo">Cryo RES</u>`)
      .replaceAll(`Cryo Infliction`, `<u class="Cryo">Cryo Infliction</u>`)
      .replaceAll(`Nature DMG`, `<u class="Nature">Nature DMG</u>`)
      .replaceAll(`Nature RES`, `<u class="Nature">Nature RES</u>`)
      .replaceAll(
        `Nature Infliction`,
        `<u class="Nature">Nature Infliction</u>`
      )
      .replaceAll(`HP Treatment`, `<u class="Nature">HP Treatment</u>`)
      .replaceAll(`Heat DMG`, `<u class="Heat">Heat DMG</u>`)
      .replaceAll(`Heat RES`, `<u class="Heat">Heat RES</u>`)
      .replaceAll(`Heat Infliction`, `<u class="Heat">Heat Infliction</u>`)
      .replaceAll(`<br/>`, `<br />`);
    setSkillDesc(newString);
  }, []);
  return (
    <>
      <div className="skill-header">
        <div className={`skill-icon rarity-${character_rarity}`}>{type}</div>
        <div className="skill-info">
          <p className="skill-name">{AKESkills[id].name}</p>
          {AKESkills[id].cost && (
            <p className="details">
              SP Cost: <strong>{AKESkills[id].cost}</strong>
            </p>
          )}
          {AKESkills[id].cooldown && (
            <p className="details">
              Cooldown: <strong>{AKESkills[id].cooldown}s</strong>
            </p>
          )}
        </div>
      </div>
      <div
        className={`skill-description`}
        dangerouslySetInnerHTML={{
          __html: skillDesc
        }}
      />
      {AKESkills[id].trigger && (
        <div className={`skill-description no-top-margin`}>
          <p>
            <strong>Trigger:</strong>{' '}
            <span
              dangerouslySetInnerHTML={{
                __html: AKESkills[id].trigger
              }}
            ></span>
          </p>
        </div>
      )}
      {AKESkills[id].multipliers.length > 0 && (
        <Accordion className="accordion-multis">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Multipliers (Rank 12)</Accordion.Header>
            <Accordion.Body>
              <div className="multipliers inside">
                {AKESkills[id].multipliers.map((multi, index) => {
                  return (
                    <div className="multi-single" key={index}>
                      <div className="name">{multi.name && multi.name}</div>
                      <div className="value">
                        {multi.value}
                        {multi.isPercent && '%'}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </>
  );
};
