export const ZZZCharactersDMG = [
  {
    id: 0,
    name: 'Ellen',
    slug: 'ellen',
    disclaimer: '',
    dps: {
      damage: 88446,
      damage_e1: 94078,
      damage_e2: 98474,
      damage_e3: 107543,
      damage_e4: 107543,
      damage_e5: 116611,
      damage_e6: 130810
    },
    dmg: {
      damage: 1250981,
      damage_e1: 1330645,
      damage_e2: 1392822,
      damage_e3: 1521082,
      damage_e4: 1521082,
      damage_e5: 1649342,
      damage_e6: 1850171
    },
    dps_standard: {
      damage: 45132,
      damage_e1: 52330,
      damage_e2: 52330,
      damage_e3: 57175,
      damage_e4: 57175,
      damage_e5: 62020,
      damage_e6: 81377
    },
    dmg_standard: {
      damage: 463059,
      damage_e1: 437476,
      damage_e2: 437476,
      damage_e3: 477981,
      damage_e4: 477981,
      damage_e5: 518487,
      damage_e6: 680310
    },
    time: 14.14,
    time_standard: 10.26,
    time_standard_change: 8.36,
    disk_6: 'ATK%',
    disk_5: 'Ice DMG',
    disk_4: 'CRIT DMG',
    substats: 'ATK (27%), CRIT Rate (26.4%), CRIT DMG (67.2%)',
    set_1: 'Woodpecker Electro',
    set_2: 'Hormone Punk',
    weapon: 'Deep Sea Visitor',
    weapon_S: '1'
  },
  {
    id: 1,
    name: 'Soldier 11',
    slug: 'soldier-11',
    disclaimer: '',
    dps: {
      damage: 82228,
      damage_e1: 82228,
      damage_e2: 86253,
      damage_e3: 93863,
      damage_e4: 93863,
      damage_e5: 101473,
      damage_e6: 111058
    },
    dmg: {
      damage: 1217633,
      damage_e1: 1217633,
      damage_e2: 1277235,
      damage_e3: 1389924,
      damage_e4: 1389924,
      damage_e5: 1502614,
      damage_e6: 1644543
    },
    dps_standard: {
      damage: 41859,
      damage_e1: 41859,
      damage_e2: 47252,
      damage_e3: 51360,
      damage_e4: 51360,
      damage_e5: 55469,
      damage_e6: 68296
    },
    dmg_standard: {
      damage: 232985,
      damage_e1: 232985,
      damage_e2: 263004,
      damage_e3: 285872,
      damage_e4: 285872,
      damage_e5: 308740,
      damage_e6: 380137
    },
    time: 14.81,
    time_standard: 5.57,
    time_standard_change: 0,
    disk_6: 'ATK%',
    disk_5: 'Fire DMG',
    disk_4: 'CRIT DMG',
    substats: 'ATK (18%), CRIT Rate (33.6%), CRIT DMG (67.2%)',
    set_1: 'Inferno Metal',
    set_2: 'Woodpecker Electro',
    weapon: 'The Brimstone',
    weapon_S: '1'
  },
  {
    id: 2,
    name: 'Corin',
    slug: 'corin',
    disclaimer: '',
    dps: {
      damage: 93663,
      damage_e1: 98125,
      damage_e2: 106837,
      damage_e3: 116570,
      damage_e4: 116570,
      damage_e5: 126304,
      damage_e6: 128835
    },
    dmg: {
      damage: 1183240,
      damage_e1: 1239618,
      damage_e2: 1349671,
      damage_e3: 1472634,
      damage_e4: 1472634,
      damage_e5: 1595596,
      damage_e6: 1627578
    },
    dps_standard: {
      damage: 18079,
      damage_e1: 19156,
      damage_e2: 19156,
      damage_e3: 20863,
      damage_e4: 20863,
      damage_e5: 22571,
      damage_e6: 22571
    },
    dmg_standard: {
      damage: 128072,
      damage_e1: 135699,
      damage_e2: 135699,
      damage_e3: 147796,
      damage_e4: 147796,
      damage_e5: 159894,
      damage_e6: 159894
    },
    time: 12.63,
    time_standard: 7.08,
    time_standard_change: 0,
    disk_6: 'ATK%',
    disk_5: 'ATK%',
    disk_4: 'CRIT Rate',
    substats: 'ATK (18%), CRIT Rate (33.6%), CRIT DMG (67.2%)',
    set_1: 'Fanged Metal',
    set_2: 'Woodpecker Electro',
    weapon: 'Steel Cushion',
    weapon_S: '1'
  },
  {
    id: 3,
    name: 'Nekomata',
    slug: 'nekomata',
    disclaimer: '',
    dps: {
      damage: 84055,
      damage_e1: 97364,
      damage_e2: 97364,
      damage_e3: 106211,
      damage_e4: 114049,
      damage_e5: 123577,
      damage_e6: 147463
    },
    dmg: {
      damage: 1101116,
      damage_e1: 1275473,
      damage_e2: 1275473,
      damage_e3: 1391358,
      damage_e4: 1494041,
      damage_e5: 1618860,
      damage_e6: 1931766
    },
    dps_standard: {
      damage: 29475,
      damage_e1: 30198,
      damage_e2: 30198,
      damage_e3: 32937,
      damage_e4: 35958,
      damage_e5: 38960,
      damage_e6: 46553
    },
    dmg_standard: {
      damage: 138564,
      damage_e1: 141963,
      damage_e2: 141963,
      damage_e3: 154836,
      damage_e4: 169040,
      damage_e5: 183152,
      damage_e6: 218848
    },
    time: 13.1,
    time_standard: 4.7,
    time_standard_change: 0,
    disk_6: 'ATK%',
    disk_5: 'ATK%',
    disk_4: 'CRIT DMG',
    substats: 'ATK (18%), CRIT Rate (33.6%), CRIT DMG (67.2%)',
    set_1: 'Fanged Metal',
    set_2: 'Woodpecker Electro',
    weapon: 'Steel Cushion',
    weapon_S: '1'
  },
  {
    id: 4,
    name: 'Grace',
    slug: 'grace-howard',
    disclaimer: '',
    dps: {
      damage: 150370,
      damage_e1: 150370,
      damage_e2: 162751,
      damage_e3: 166285,
      damage_e4: 166285,
      damage_e5: 169819,
      damage_e6: 207522
    },
    dmg: {
      damage: 665689,
      damage_e1: 665689,
      damage_e2: 720498,
      damage_e3: 736144,
      damage_e4: 736144,
      damage_e5: 751790,
      damage_e6: 918701
    },
    dps_standard: {
      damage: 0,
      damage_e1: 0,
      damage_e2: 0,
      damage_e3: 0,
      damage_e4: 0,
      damage_e5: 0,
      damage_e6: 0
    },
    dmg_standard: {
      damage: 0,
      damage_e1: 0,
      damage_e2: 0,
      damage_e3: 0,
      damage_e4: 0,
      damage_e5: 0,
      damage_e6: 0
    },
    time: 4.43,
    time_standard: 0,
    time_standard_change: 0,
    disk_6: 'ATK%',
    disk_5: 'Electric DMG',
    disk_4: 'Anomaly Proficiency',
    substats: 'ATK (42%), Anomaly Proficiency (126), PEN (54)',
    set_1: 'Thunder Metal',
    set_2: 'Freedom Blues',
    weapon: 'Fusion Compiler',
    weapon_S: '1'
  },
  {
    id: 5,
    name: 'Piper',
    slug: 'piper',
    disclaimer: '',
    dps: {
      damage: 72405,
      damage_e1: 80692,
      damage_e2: 85574,
      damage_e3: 87823,
      damage_e4: 87823,
      damage_e5: 90071,
      damage_e6: 90071
    },
    dmg: {
      damage: 707397,
      damage_e1: 788360,
      damage_e2: 836056,
      damage_e3: 858027,
      damage_e4: 858027,
      damage_e5: 879997,
      damage_e6: 879997
    },
    dps_standard: {
      damage: 0,
      damage_e1: 0,
      damage_e2: 0,
      damage_e3: 0,
      damage_e4: 0,
      damage_e5: 0,
      damage_e6: 0
    },
    dmg_standard: {
      damage: 0,
      damage_e1: 0,
      damage_e2: 0,
      damage_e3: 0,
      damage_e4: 0,
      damage_e5: 0,
      damage_e6: 0
    },
    time: 9.77,
    time_standard: 0,
    time_standard_change: 0,
    disk_6: 'Anomaly Mastery',
    disk_5: 'Physical DMG',
    disk_4: 'Anomaly Proficiency',
    substats: 'ATK (42%), Anomaly Proficiency (126), PEN (54)',
    set_1: 'Fanged Metal',
    set_2: 'Freedom Blues',
    weapon: 'Fusion Compiler',
    weapon_S: '1'
  },
  {
    id: 6,
    name: 'Zhu Yuan',
    slug: 'zhu-yuan',
    disclaimer: '',
    dps: {
      damage: 123459,
      damage_e1: 123459,
      damage_e2: 134811,
      damage_e3: 146888,
      damage_e4: 164415,
      damage_e5: 178017,
      damage_e6: 185640
    },
    dmg: {
      damage: 1499537,
      damage_e1: 1499537,
      damage_e2: 1637420,
      damage_e3: 1784098,
      damage_e4: 1996989,
      damage_e5: 2162198,
      damage_e6: 2254786
    },
    dps_standard: {
      damage: 22832,
      damage_e1: 52957,
      damage_e2: 64942,
      damage_e3: 70659,
      damage_e4: 87081,
      damage_e5: 94227,
      damage_e6: 94227
    },
    dmg_standard: {
      damage: 126716,
      damage_e1: 210029,
      damage_e2: 257561,
      damage_e3: 280235,
      damage_e4: 345364,
      damage_e5: 373706,
      damage_e6: 373706
    },
    time: 12.15,
    time_standard: 5.55,
    time_standard_change: 3.97,
    disk_6: 'ATK%',
    disk_5: 'Ether DMG',
    disk_4: 'CRIT Rate',
    substats: 'ATK (42%), CRIT Rate (16.8%), CRIT DMG (67.2%)',
    set_1: 'Chaotic Metal',
    set_2: 'Woodpecker Electro',
    weapon: 'Riot Suppressor Mark VI',
    weapon_S: '1'
  },
  {
    id: 7,
    name: 'Billy Kid',
    slug: 'billy-kid',
    disclaimer: '',
    dps: {
      damage: 71139,
      damage_e1: 71139,
      damage_e2: 71139,
      damage_e3: 77560,
      damage_e4: 82825,
      damage_e5: 89704,
      damage_e6: 101167
    },
    dmg: {
      damage: 942305,
      damage_e1: 942305,
      damage_e2: 942305,
      damage_e3: 1027358,
      damage_e4: 1097104,
      damage_e5: 1188225,
      damage_e6: 1340053
    },
    dps_standard: {
      damage: 0,
      damage_e1: 0,
      damage_e2: 0,
      damage_e3: 0,
      damage_e4: 0,
      damage_e5: 0,
      damage_e6: 0
    },
    dmg_standard: {
      damage: 0,
      damage_e1: 0,
      damage_e2: 0,
      damage_e3: 0,
      damage_e4: 0,
      damage_e5: 0,
      damage_e6: 0
    },
    time: 13.25,
    time_standard: 0,
    time_standard_change: 0,
    disk_6: 'ATK%',
    disk_5: 'ATK%',
    disk_4: 'CRIT DMG',
    substats: 'ATK (18%), CRIT Rate (33.6%), CRIT DMG (67.2%)',
    set_1: 'Woodpecker Electro',
    set_2: 'Fanged Metal',
    weapon: 'Starlight Engine Replica',
    weapon_S: '5'
  },
  {
    id: 8,
    name: 'Anton',
    slug: 'anton',
    disclaimer: '',
    dps: {
      damage: 74664,
      damage_e1: 74664,
      damage_e2: 74664,
      damage_e3: 80991,
      damage_e4: 85262,
      damage_e5: 91961,
      damage_e6: 97557
    },
    dmg: {
      damage: 1025429,
      damage_e1: 1025429,
      damage_e2: 1025429,
      damage_e3: 1112335,
      damage_e4: 1170988,
      damage_e5: 1262996,
      damage_e6: 1339854
    },
    dps_standard: {
      damage: 0,
      damage_e1: 0,
      damage_e2: 0,
      damage_e3: 0,
      damage_e4: 0,
      damage_e5: 0,
      damage_e6: 0
    },
    dmg_standard: {
      damage: 0,
      damage_e1: 0,
      damage_e2: 0,
      damage_e3: 0,
      damage_e4: 0,
      damage_e5: 0,
      damage_e6: 0
    },
    time: 13.73,
    time_standard: 0,
    time_standard_change: 0,
    disk_6: 'ATK%',
    disk_5: 'ATK%',
    disk_4: 'CRIT Rate',
    substats: 'ATK (18%), CRIT Rate (33.6%), CRIT DMG (67.2%)',
    set_1: 'Thunder Metal',
    set_2: 'Woodpecker Electro',
    weapon: 'The Brimstone',
    weapon_S: '1'
  },
  {
    id: 9,
    name: 'Jane Doe',
    slug: 'jane-doe',
    disclaimer: '',
    dps: {
      damage: 144141,
      damage_e1: 172199,
      damage_e2: 239020,
      damage_e3: 242281,
      damage_e4: 257577,
      damage_e5: 260838,
      damage_e6: 273231
    },
    dmg: {
      damage: 2186037,
      damage_e1: 2611564,
      damage_e2: 3624981,
      damage_e3: 3674433,
      damage_e4: 3906415,
      damage_e5: 3955868,
      damage_e6: 4143816
    },
    dps_standard: {
      damage: 58874,
      damage_e1: 66546,
      damage_e2: 92409,
      damage_e3: 93613,
      damage_e4: 96080,
      damage_e5: 97284,
      damage_e6: 120140
    },
    dmg_standard: {
      damage: 681001,
      damage_e1: 834021,
      damage_e2: 1158163,
      damage_e3: 1173255,
      damage_e4: 1204165,
      damage_e5: 1219256,
      damage_e6: 1371638
    },
    time: 15.17,
    time_standard: 11.57,
    time_standard_change: 0,
    disk_6: 'Anomaly Mastery',
    disk_5: 'Physical DMG',
    disk_4: 'Anomaly Proficiency',
    substats: 'ATK (42%), Anomaly Proficiency (126), PEN (54)',
    set_1: 'Fanged Metal',
    set_2: 'Freedom Blues',
    weapon: 'Sharpened Stinger',
    weapon_S: '1'
  },
  {
    id: 10,
    name: 'Burnice',
    slug: 'burnice',
    disclaimer: '',
    dps: {
      damage: 270926,
      damage_e1: 286171,
      damage_e2: 314781,
      damage_e3: 321420,
      damage_e4: 332369,
      damage_e5: 339298,
      damage_e6: 439053
    },
    dmg: {
      damage: 1480882,
      damage_e1: 1564211,
      damage_e2: 1720595,
      damage_e3: 1756880,
      damage_e4: 1816732,
      damage_e5: 1854603,
      damage_e6: 2399862
    },
    dps_standard: {
      damage: 236809,
      damage_e1: 264130,
      damage_e2: 296548,
      damage_e3: 299627,
      damage_e4: 319251,
      damage_e5: 322850,
      damage_e6: 497359
    },
    dmg_standard: {
      damage: 722268,
      damage_e1: 805597,
      damage_e2: 904471,
      damage_e3: 913863,
      damage_e4: 973715,
      damage_e5: 984694,
      damage_e6: 1516944
    },
    time: 5.47,
    time_standard: 3.05,
    time_standard_change: 0,
    disk_6: 'ATK%',
    disk_5: 'Fire DMG',
    disk_4: 'Anomaly Proficiency',
    substats: 'ATK (42%), Anomaly Proficiency (126), PEN (54)',
    set_1: 'Chaos Jazz',
    set_2: 'Inferno Metal',
    weapon: 'Flamemaker Shaker',
    weapon_S: '1'
  },
  {
    id: 11,
    name: 'Yanagi',
    slug: 'yanagi',
    disclaimer: '',
    dps: {
      damage: 261507,
      damage_e1: 311766,
      damage_e2: 346945,
      damage_e3: 357324,
      damage_e4: 394366,
      damage_e5: 405821,
      damage_e6: 453207
    },
    dmg: {
      damage: 2833168,
      damage_e1: 3377671,
      damage_e2: 4567883,
      damage_e3: 4704532,
      damage_e4: 5192225,
      damage_e5: 5343040,
      damage_e6: 7023794
    },
    dps_standard: {
      damage: 79149,
      damage_e1: 90809,
      damage_e2: 90809,
      damage_e3: 91818,
      damage_e4: 101337,
      damage_e5: 102450,
      damage_e6: 102450
    },
    dmg_standard: {
      damage: 639765,
      damage_e1: 734012,
      damage_e2: 734012,
      damage_e3: 742167,
      damage_e4: 819103,
      damage_e5: 828104,
      damage_e6: 828104
    },
    time: 10.83,
    time_standard: 8.08,
    time_standard_change: 0,
    disk_6: 'ATK%',
    disk_5: 'Electric DMG',
    disk_4: 'Anomaly Proficiency',
    substats: 'ATK (42%), Anomaly Proficiency (126), PEN (54)',
    set_1: 'Chaos Jazz',
    set_2: 'Freedom Blues',
    weapon: 'Timeweaver',
    weapon_S: '1'
  },
  {
    id: 12,
    name: 'Miyabi',
    slug: 'miyabi',
    disclaimer: '',
    dps: {
      damage: 289816,
      damage_e1: 318943,
      damage_e2: 395161,
      damage_e3: 419934,
      damage_e4: 424598,
      damage_e5: 449371,
      damage_e6: 624589
    },
    dmg: {
      damage: 3605604,
      damage_e1: 3967964,
      damage_e2: 4290261,
      damage_e3: 4559221,
      damage_e4: 4609860,
      damage_e5: 4878820,
      damage_e6: 6781168
    },
    dps_standard: {
      damage: 0,
      damage_e1: 0,
      damage_e2: 0,
      damage_e3: 0,
      damage_e4: 0,
      damage_e5: 0,
      damage_e6: 0
    },
    dmg_standard: {
      damage: 0,
      damage_e1: 0,
      damage_e2: 0,
      damage_e3: 0,
      damage_e4: 0,
      damage_e5: 0,
      damage_e6: 0
    },
    time: 12.44,
    time_standard: 0,
    time_standard_change: 0,
    disk_6: 'ATK%',
    disk_5: 'Ice DMG',
    disk_4: 'CRIT Rate',
    substats: 'ATK (42%), CRIT Rate (28.8%), CRIT DMG (62.4%)',
    set_1: 'Branch & Blade Song',
    set_2: 'Polar Metal',
    weapon: 'Hailstorm Shrine',
    weapon_S: '1'
  },
  {
    id: 13,
    name: 'Harumasa',
    slug: 'harumasa',
    disclaimer: '',
    dps: {
      damage: 121809,
      damage_e1: 132162,
      damage_e2: 143461,
      damage_e3: 156151,
      damage_e4: 156151,
      damage_e5: 168449,
      damage_e6: 230355
    },
    dmg: {
      damage: 1531145,
      damage_e1: 2190324,
      damage_e2: 2377573,
      damage_e3: 2587885,
      damage_e4: 2587885,
      damage_e5: 2791708,
      damage_e6: 3817678
    },
    dps_standard: {
      damage: 0,
      damage_e1: 0,
      damage_e2: 0,
      damage_e3: 0,
      damage_e4: 0,
      damage_e5: 0,
      damage_e6: 0
    },
    dmg_standard: {
      damage: 0,
      damage_e1: 0,
      damage_e2: 0,
      damage_e3: 0,
      damage_e4: 0,
      damage_e5: 0,
      damage_e6: 0
    },
    time: 12.57,
    time_standard: 0,
    time_standard_change: 0,
    disk_6: 'ATK%',
    disk_5: 'Electric DMG',
    disk_4: 'CRIT DMG',
    substats: 'ATK (42%), CRIT Rate (26.4%), CRIT DMG (43.2%)',
    set_1: 'Thunder Metal',
    set_2: 'Woodpecker Electro',
    weapon: 'Zanshin Herb Case',
    weapon_S: '1'
  }
];
