import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';
import { Col, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { GFLCharacter } from '../../../modules/exilium/components/exilium-character';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const ExiliumGuidesProg: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page gfl-guide'} game="exilium">
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/gfl-exilium/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Characters & Progression</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/exilium/categories/category_progression.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Characters & Progression</h1>
          <h2>
            How to upgrade your characters in GFL2 and make them strong -
            various systems explained.
          </h2>
          <p>
            Last updated: <strong>30/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Character" />
        <StaticImage
          src="../../../images/exilium/generic/character.webp"
          alt="Guides"
        />
        <p>
          T-Dolls are playable characters in Girls Frontline 2: Exilium, with
          each of them having a unique design and personality. Still, there's a
          lot of systems that allow you to customize the characters and make
          them stronger and this guide will help you explore them all and learn
          how to use them to your advantage.{' '}
        </p>
        <SectionHeader title="Classes" />
        <p>
          In Girls Frontline 2: Exilium, T-dolls are now organized into a
          “Class” system that consists of 4 classes:
        </p>
        <ul>
          <li>
            <strong>Bulwark Class</strong>: The Bulwark class is your standard
            “Tank” that acts as a frontline and provides various Defensive Buffs
            to your team.
          </li>
          <li>
            <strong>Sentinel Class</strong>: The Sentinel class is your standard
            “DPS” that provides damage for your team.
          </li>
          <li>
            <strong>Vanguard Class</strong>: The Vanguard class is your standard
            “Scout” that acts as a sub-DPS to your team as well as having the
            most mobility out of all the classes to perform flanking maneuvers.
          </li>
          <li>
            <strong>Support Class</strong>: The Support class is your standard
            “Healer/Buffer” that provides healing and various Buffs to your
            team.
          </li>
        </ul>
        <SectionHeader title="Levels" />
        <p>
          Leveling the character is the simplest way to increase their power and
          you can do it using <strong>Combat Reports</strong>. The maximum level
          of the character is defined by your Commander Level and furthermore,
          every 10 levels starting from 20, you will need to{' '}
          <strong>'limit break'</strong> them before you can continue leveling
          them.
        </p>
        <SectionHeader title="Stats" />
        <p>Each character has 7 primary stats:</p>
        <ul className="with-image">
          <li>
            <div className="category">
              <StaticImage
                src="../../../images/exilium/icons/stat_atk.png"
                alt="Stat"
              />
              <strong>Attack (ATK)</strong>
            </div>{' '}
            - Increases the damage the character deals as their abilities scale
            with ATK (usually),
          </li>
          <li>
            <div className="category">
              <StaticImage
                src="../../../images/exilium/icons/stat_def.png"
                alt="Stat"
              />
              <strong>Defense (DEF)</strong>
            </div>{' '}
            - Increases the character 'tankiness' by providing damage reduction,
          </li>
          <li>
            <div className="category">
              <StaticImage
                src="../../../images/exilium/icons/stat_hp.png"
                alt="Stat"
              />
              <strong>Health (HP)</strong>
            </div>{' '}
            - Shows the maximum amount of Health the character has. When Health
            reaches zero, the unit will be defeated,
          </li>
          <li>
            <div className="category">
              <StaticImage
                src="../../../images/exilium/icons/stat_crit.png"
                alt="Stat"
              />
              <strong>Critical Hit</strong>
            </div>{' '}
            - Shows the base chance for an attack to do Critical Damage,
          </li>
          <li>
            <div className="category">
              <StaticImage
                src="../../../images/exilium/icons/stat_critdmg.png"
                alt="Stat"
              />
              <strong>Critical Damage</strong>
            </div>{' '}
            - Shows by how much a Critical Hit will increase the damage,
          </li>
          <li>
            <div className="category">
              <StaticImage
                src="../../../images/exilium/icons/stat_stability.png"
                alt="Stat"
              />
              <strong>Stability Index</strong>
            </div>{' '}
            - Shows the maximum amount of Stability Index. Stability provides
            additional 60% Damage Reduction (DR) when near Cover that is removed
            when it reaches 0,
          </li>
          <li>
            <div className="category">
              <StaticImage
                src="../../../images/exilium/icons/stat_mobility.png"
                alt="Stat"
              />
              <strong>Action Points</strong>
            </div>{' '}
            - Show the number of tiles a character can traverse during their
            turn.
          </li>
        </ul>
        <SectionHeader title="Skills" />
        <p>
          Each T-Doll has five skills that define their playstyle and role on
          the battlefield:
        </p>
        <ul>
          <li>
            <strong>Basic Attack</strong> - usually a simple, one-target attack,
          </li>
          <li>
            <strong>Active Skill x2</strong> - an attack or supportive ability
            (or both) that can be used on cooldown,
          </li>
          <li>
            <strong>Ultimate Skill</strong> - an attack or supportive ability
            that costs Confectance Points to use. Confectance Points are
            basically Skill Points,
          </li>
          <li>
            <strong>Passive Skill</strong> - a passive effect that is always
            active and provides a variety of effects.
          </li>
        </ul>
        <SectionHeader title="Elements" />
        <StaticImage
          src="../../../images/exilium/generic/weakness.webp"
          alt="Guides"
        />
        <p>
          Each character is weak to one{' '}
          <strong>Elemental Type and one Ammo Type</strong>. For example{' '}
          <GFLCharacter slug="littara" mode="inline" enablePopover showLabel />{' '}
          is weak to Light Ammo and Burn (Fire). When a character's weakness is
          attacked, they lose more Stability which is further amplified if an
          attack matches both Ammo and Elemental weakness of a character.
        </p>
        <p>
          What is <strong>Stability</strong>? Stability provides characters with{' '}
          <strong>60% Damage Reduction (DR)</strong> when it's above 0 and they
          are next to cover, but once it's lost, the DR bonus is removed,
        </p>
        <p>
          The same Weakness system affects enemies you fight, so you can use it
          to your advantage!
        </p>
        <SectionHeader title="Neural Helix (Keys)" />
        <StaticImage
          src="../../../images/exilium/generic/keys.webp"
          alt="Guides"
        />
        <p>
          <strong>Neural Helix</strong> is basically a 'skill tree' that you
          need to upgrade to unlock additional{' '}
          <strong>
            stats that benefit the characters and Keys that provide passive
            effects
          </strong>
          . The Keys allows you to customize the character to your needs, better
          focusing on the type of role you want them to fulfill in the battle.
        </p>
        <p>Six Keys can be equipped on a character:</p>
        <ul>
          <li>
            <strong>3 Fixed Keys</strong> that come from Neural Helix
            progression (left side) and those are locked to the character,
          </li>
          <li>
            <strong>3 Common Keys</strong> that also come from Neural Helix
            progression, but from other characters (right side). To obtain a
            Common Key, you have to fully upgrade the Neural Helix tree of a
            character.
          </li>
        </ul>
        <p>
          If you're not sure what Keys you should equip on your characters,
          there's a system in game (Growth recommendation) that provides you
          tips about the most common builds.
        </p>
        <h5>Mod (Expansion) Key</h5>
        <StaticImage
          src="../../../images/exilium/generic/keys_mod.webp"
          alt="Guides"
        />
        <ul>
          <li>
            Mod Key is a special Key that further amplifies the character kit.
            It can only be unlocked after you get your Common Key and spend
            additional Cores.
          </li>
          <li>
            The Mod Key doesn't take any of the slots mentioned above and
            instead sits in the middle of the Neural Helix.
          </li>
          <li>
            Only some characters have access to Mod Key and more are being added
            with various updates.
          </li>
        </ul>
        <p>List of characters that have Mod Key available to them:</p>
        <ul>
          <li>
            <GFLCharacter slug="groza" mode="inline" enablePopover showLabel />
          </li>
          <li>
            <GFLCharacter
              slug="jiangyu"
              mode="inline"
              enablePopover
              showLabel
            />
          </li>
          <li>
            <GFLCharacter
              slug="makiatto"
              mode="inline"
              enablePopover
              showLabel
            />
          </li>
          <li>
            <GFLCharacter slug="nagant" mode="inline" enablePopover showLabel />
          </li>
          <li>
            <GFLCharacter
              slug="qiongjiu"
              mode="inline"
              enablePopover
              showLabel
            />
          </li>
          <li>
            <GFLCharacter slug="ullrid" mode="inline" enablePopover showLabel />
          </li>
          <li>
            <GFLCharacter
              slug="zhaohui"
              mode="inline"
              enablePopover
              showLabel
            />
          </li>
          <li>
            <GFLCharacter slug="tololo" mode="inline" enablePopover showLabel />
          </li>
          <li>
            <GFLCharacter
              slug="sabrina"
              mode="inline"
              enablePopover
              showLabel
            />
          </li>
          <li>
            <GFLCharacter
              slug="colphne"
              mode="inline"
              enablePopover
              showLabel
            />
          </li>
          <li>
            <GFLCharacter slug="ksenia" mode="inline" enablePopover showLabel />
          </li>
          <li>
            <GFLCharacter
              slug="centaureissi"
              mode="inline"
              enablePopover
              showLabel
            />
          </li>
        </ul>
        <SectionHeader title="Fortification (Dupes)" />
        <StaticImage
          src="../../../images/exilium/generic/character_dupes.webp"
          alt="Guides"
        />
        <p>
          In Girls’ Frontline 2: Exilium, the “Skill Training” System has now
          changed to a Hoyoverse (Genshin Impact/HSR etc.){' '}
          <strong>“Fortification”</strong> system where T-Doll dupes now
          increase the T-Doll’s skills. Dupes no longer give new skills as all
          skills are now unlocked at base level, and they simply function now to
          increase and enhance various skills of the T-Doll.
        </p>
        <p>
          To easily showcase the amount of dupes you have on the character, it's
          common to use the symbols <strong>V0, V1 etc up to V6</strong> - where
          V0 means the character doesn't have any Fortification passives
          unlocked.
        </p>
        <SectionHeader title="Affinity" />
        <StaticImage
          src="../../../images/exilium/generic/aff_1.webp"
          alt="Guides"
        />
        <p>
          Girls’ Frontline 2: Exilium features an Affinity system called
          <strong>“Implicit Feedback”</strong> that allows you to bond with
          T-Dolls and receive various rewards such as materials and cosmetics.
          Reaching Feedback Level 5 will grant you the option to form a special
          bond with the T-Doll called{' '}
          <strong>“Covenant” which is the game’s “Marriage” mechanic</strong>.
          Affinity Levels are also used to upgrade the{' '}
          <strong>Dispatch Room</strong> to provide passive stats and improve
          the production rewards.
        </p>
        <p>
          To learn more about the Affinity system, check out our other guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Affinity & Dispatch"
            link="/gfl-exilium/guides/affinity-and-dispatch"
            image={
              <StaticImage
                src="../../../images/exilium/categories/category_affinity.webp"
                alt="Affinity & Dispatch"
              />
            }
          />
        </Row>
        <SectionHeader title="Weapons" />
        <StaticImage
          src="../../../images/exilium/generic/weapons.webp"
          alt="Guides"
        />
        <p>
          Another way to improve your character performance is to equip them
          with a Weapon. Each T-Doll can use one of{' '}
          <strong>7 weapon types</strong> (there's also an ammo type attached to
          each weapon type):
        </p>
        <ul>
          <li>
            <strong>Assault Rifle</strong> (Medium Ammo)
          </li>
          <li>
            <strong>Handgun</strong> (Light Ammo)
          </li>
          <li>
            <strong>Machine Gun</strong> (Heavy Ammo)
          </li>
          <li>
            <strong>Submachine Gun</strong> (Light Ammo)
          </li>
          <li>
            <strong>Shotgun</strong> (Shotgun Ammo)
          </li>
          <li>
            <strong>Rifle</strong> (Heavy Ammo)
          </li>
          <li>
            <strong>Blade</strong> (Melee)
          </li>
        </ul>
        <h5>Weapon Rarity</h5>
        <p>
          Weapons also come in 3 rarities - <strong>R, SR and SSR</strong> -
          that affect their stats, traits, effects. The higher rarity Weapon,
          the better it will perform.
        </p>
        <h5>Weapon Stats</h5>
        <StaticImage
          src="../../../images/exilium/generic/weapon_view2.webp"
          alt="Guides"
        />
        <p>
          Weapons come with up to <strong>2 main stats</strong> - lower rarity
          (R and SR) weapons only have flat ATK stat while the SSR rarity
          weapons also posses a secondary main stat that is percentage instead
          (for example ATK%, Crit DMG%, Crit Rate% etc.).
        </p>
        <p>
          By leveling the weapon, the{' '}
          <strong>main stats value will increase</strong> - and as it's usually
          the case in gacha games - higher rarity weapons will have higher stat
          ranges.
        </p>
        <p>To learn more about Weapons & Attachments, check our other guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Weapons & Attachments"
            link="/gfl-exilium/guides/weapons-and-attachments"
            image={
              <StaticImage
                src="../../../images/exilium/categories/category_attachment.webp"
                alt="Attachments"
              />
            }
          />
        </Row>
        <SectionHeader title="Credits" />
        <p>Some of the images used in this guide come from this video:</p>
        <Row className="video">
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="IjwBaUxywnc" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default ExiliumGuidesProg;

export const Head: React.FC = () => (
  <Seo
    title="Characters & Progression | Girls' Frontline 2: Exilium | Prydwen Institute"
    description="How to upgrade your characters in GFL2 and make them strong - various systems explained."
    game="exilium"
  />
);
