export const PFCurrentStage4Side1 = [
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 6.66,
    rank: 1,
    avg_round: 36216,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 4.05,
    rank: 2,
    avg_round: 34793,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Aventurine',
    app_rate: 3.34,
    rank: 3,
    avg_round: 32877,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 3.31,
    rank: 4,
    avg_round: 37167,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 2.82,
    rank: 5,
    avg_round: 36912,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 2.82,
    rank: 5,
    avg_round: 35812,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 2.51,
    rank: 7,
    avg_round: 36304,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 2.42,
    rank: 8,
    avg_round: 32711,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 1.98,
    rank: 9,
    avg_round: 38012,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 1.76,
    rank: 10,
    avg_round: 35449,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Huohuo',
    app_rate: 1.68,
    rank: 11,
    avg_round: 31900,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 1.6,
    rank: 12,
    avg_round: 27630,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 1.4,
    rank: 13,
    avg_round: 27545,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 1.18,
    rank: 14,
    avg_round: 35663,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.98,
    rank: 15,
    avg_round: 27265,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.96,
    rank: 16,
    avg_round: 36231,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.93,
    rank: 17,
    avg_round: 39716,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.9,
    rank: 18,
    avg_round: 32118,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.85,
    rank: 19,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.84,
    rank: 20,
    avg_round: 28170,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Gallagher',
    app_rate: 0.84,
    rank: 20,
    avg_round: 33333,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.82,
    rank: 22,
    avg_round: 33069,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.8,
    rank: 23,
    avg_round: 34047,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.77,
    rank: 24,
    avg_round: 31478,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.77,
    rank: 24,
    avg_round: 35307,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.74,
    rank: 26,
    avg_round: 34076,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.73,
    rank: 27,
    avg_round: 37199,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.69,
    rank: 28,
    avg_round: 27820,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.67,
    rank: 29,
    avg_round: 37331,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.67,
    rank: 29,
    avg_round: 28458,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.66,
    rank: 31,
    avg_round: 36498,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.66,
    rank: 31,
    avg_round: 37542,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Luocha',
    app_rate: 0.64,
    rank: 33,
    avg_round: 32979,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.62,
    rank: 34,
    avg_round: 34261,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.54,
    rank: 35,
    avg_round: 36913,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.53,
    rank: 36,
    avg_round: 28840,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.53,
    rank: 36,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.51,
    rank: 38,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.51,
    rank: 38,
    avg_round: 35862,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.5,
    rank: 40,
    avg_round: 32303,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.47,
    rank: 41,
    avg_round: 34998,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.47,
    rank: 41,
    avg_round: 34403,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.47,
    rank: 41,
    avg_round: 33097,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.43,
    rank: 44,
    avg_round: 33392,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.43,
    rank: 44,
    avg_round: 32897,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.42,
    rank: 46,
    avg_round: 30484,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.41,
    rank: 47,
    avg_round: 33920,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.39,
    rank: 48,
    avg_round: 34114,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.37,
    rank: 49,
    avg_round: 38483,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Gepard',
    app_rate: 0.37,
    rank: 49,
    avg_round: 32821,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.36,
    rank: 51,
    avg_round: 36923,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.36,
    rank: 51,
    avg_round: 33722,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.35,
    rank: 53,
    avg_round: 34680,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.34,
    rank: 54,
    avg_round: 30024,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.33,
    rank: 55,
    avg_round: 39667,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.33,
    rank: 55,
    avg_round: 27904,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.32,
    rank: 57,
    avg_round: 31019,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.32,
    rank: 57,
    avg_round: 34335,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.31,
    rank: 59,
    avg_round: 26871,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.3,
    rank: 60,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.29,
    rank: 61,
    avg_round: 25340,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.28,
    rank: 62,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.27,
    rank: 63,
    avg_round: 26980,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Lingsha',
    app_rate: 0.26,
    rank: 64,
    avg_round: 33636,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.26,
    rank: 64,
    avg_round: 31900,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.25,
    rank: 66,
    avg_round: 36021,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.25,
    rank: 66,
    avg_round: 30617,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.25,
    rank: 66,
    avg_round: 27920,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.25,
    rank: 66,
    avg_round: 29080,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.22,
    rank: 70,
    avg_round: 35782,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.22,
    rank: 70,
    avg_round: 34168,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.21,
    rank: 72,
    avg_round: 36093,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.21,
    rank: 72,
    avg_round: 29806,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.2,
    rank: 74,
    avg_round: 30818,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.2,
    rank: 74,
    avg_round: 33440,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.2,
    rank: 74,
    avg_round: 37098,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.2,
    rank: 74,
    avg_round: 33140,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.2,
    rank: 74,
    avg_round: 30324,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.2,
    rank: 74,
    avg_round: 39920,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.19,
    rank: 80,
    avg_round: 39870,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Tingyun',
    app_rate: 0.18,
    rank: 81,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.18,
    rank: 81,
    avg_round: 33333,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Bailu',
    app_rate: 0.18,
    rank: 81,
    avg_round: 31584,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.18,
    rank: 81,
    avg_round: 38977,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.18,
    rank: 81,
    avg_round: 35431,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.17,
    rank: 86,
    avg_round: 33523,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.17,
    rank: 86,
    avg_round: 37223,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.17,
    rank: 86,
    avg_round: 35940,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.17,
    rank: 86,
    avg_round: 35061,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'ruan-mei',
    app_rate: 0.17,
    rank: 86,
    avg_round: 36413,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.16,
    rank: 91,
    avg_round: 36171,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.16,
    rank: 91,
    avg_round: 35364,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.16,
    rank: 91,
    avg_round: 36128,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.16,
    rank: 91,
    avg_round: 36352,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.15,
    rank: 95,
    avg_round: 37347,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.15,
    rank: 95,
    avg_round: 32712,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.15,
    rank: 95,
    avg_round: 34760,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.15,
    rank: 95,
    avg_round: 32354,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.15,
    rank: 95,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.15,
    rank: 95,
    avg_round: 29597,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.15,
    rank: 95,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.15,
    rank: 95,
    avg_round: 26640,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.15,
    rank: 95,
    avg_round: 35437,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.14,
    rank: 104,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.14,
    rank: 104,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.14,
    rank: 104,
    avg_round: 38733,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Robin',
    app_rate: 0.14,
    rank: 104,
    avg_round: 32133,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.14,
    rank: 104,
    avg_round: 27260,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.14,
    rank: 104,
    avg_round: 38556,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.14,
    rank: 104,
    avg_round: 26880,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.14,
    rank: 104,
    avg_round: 31224,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.14,
    rank: 104,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.14,
    rank: 104,
    avg_round: 28755,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.14,
    rank: 104,
    avg_round: 39760,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.14,
    rank: 104,
    avg_round: 34696,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 104,
    avg_round: 29500,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.14,
    rank: 104,
    avg_round: 34924,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.13,
    rank: 118,
    avg_round: 39664,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.13,
    rank: 118,
    avg_round: 33960,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.13,
    rank: 118,
    avg_round: 39750,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.13,
    rank: 118,
    avg_round: 27093,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Sunday',
    app_rate: 0.13,
    rank: 118,
    avg_round: 36136,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 118,
    avg_round: 34776,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.12,
    rank: 124,
    avg_round: 26120,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.12,
    rank: 124,
    avg_round: 28545,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.12,
    rank: 124,
    avg_round: 27753,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.12,
    rank: 124,
    avg_round: 28430,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.12,
    rank: 124,
    avg_round: 26720,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 124,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Sunday',
    app_rate: 0.12,
    rank: 124,
    avg_round: 38340,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.12,
    rank: 124,
    avg_round: 36550,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.11,
    rank: 132,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.11,
    rank: 132,
    avg_round: 31780,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.11,
    rank: 132,
    avg_round: 29950,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 132,
    avg_round: 35990,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.11,
    rank: 132,
    avg_round: 35507,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.11,
    rank: 132,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.1,
    rank: 138,
    avg_round: 39410,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.1,
    rank: 138,
    avg_round: 35624,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.1,
    rank: 138,
    avg_round: 38080,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.1,
    rank: 138,
    avg_round: 35660,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.1,
    rank: 138,
    avg_round: 35310,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.1,
    rank: 138,
    avg_round: 34280,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.1,
    rank: 138,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 138,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.1,
    rank: 138,
    avg_round: 35330,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.1,
    rank: 138,
    avg_round: 31360,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.1,
    rank: 138,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 149,
    avg_round: 25880,
    star_num: '4'
  },
  {
    char_one: 'tingyun-fugue',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.09,
    rank: 149,
    avg_round: 35467,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 149,
    avg_round: 34800,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 149,
    avg_round: 31420,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 149,
    avg_round: 33053,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 149,
    avg_round: 28040,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 149,
    avg_round: 35507,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 149,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 149,
    avg_round: 29333,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 149,
    avg_round: 35809,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 149,
    avg_round: 25280,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 149,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 149,
    avg_round: 32063,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.09,
    rank: 149,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 149,
    avg_round: 33274,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 149,
    avg_round: 39580,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 149,
    avg_round: 30952,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 149,
    avg_round: 39780,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 149,
    avg_round: 39987,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.09,
    rank: 149,
    avg_round: 39990,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.09,
    rank: 149,
    avg_round: 38097,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 170,
    avg_round: 39627,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 170,
    avg_round: 21240,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 170,
    avg_round: 27753,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 170,
    avg_round: 36696,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 170,
    avg_round: 39240,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 170,
    avg_round: 30400,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 170,
    avg_round: 29820,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 170,
    avg_round: 27760,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 170,
    avg_round: 36208,
    star_num: '4'
  },
  {
    char_one: 'Serval',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 170,
    avg_round: 30680,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 180,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 180,
    avg_round: 34600,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 180,
    avg_round: 28592,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.07,
    rank: 180,
    avg_round: 28120,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.07,
    rank: 180,
    avg_round: 37440,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 180,
    avg_round: 30780,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Sunday',
    app_rate: 0.07,
    rank: 180,
    avg_round: 34720,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.07,
    rank: 180,
    avg_round: 30440,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Bronya',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 180,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.07,
    rank: 180,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.07,
    rank: 180,
    avg_round: 38160,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 180,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 180,
    avg_round: 35787,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 180,
    avg_round: 27380,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 180,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 180,
    avg_round: 24780,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Yunli',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 180,
    avg_round: 32693,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 180,
    avg_round: 29337,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Jiaoqiu',
    app_rate: 0.06,
    rank: 198,
    avg_round: 32400,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Tingyun',
    app_rate: 0.06,
    rank: 198,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.06,
    rank: 198,
    avg_round: 32387,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Sunday',
    app_rate: 0.06,
    rank: 198,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.06,
    rank: 198,
    avg_round: 29567,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Sunday',
    app_rate: 0.06,
    rank: 198,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 198,
    avg_round: 35493,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Herta',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 198,
    avg_round: 30376,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 198,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Clara',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 198,
    avg_round: 30860,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 198,
    avg_round: 38460,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 198,
    avg_round: 32060,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 198,
    avg_round: 26640,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 198,
    avg_round: 24080,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 198,
    avg_round: 28253,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 198,
    avg_round: 36968,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 198,
    avg_round: 32600,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 198,
    avg_round: 29000,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 198,
    avg_round: 30340,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 198,
    avg_round: 24280,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.06,
    rank: 198,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 198,
    avg_round: 36640,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 198,
    avg_round: 33200,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 198,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 198,
    avg_round: 32520,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 198,
    avg_round: 30600,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 224,
    avg_round: 32150,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Gepard',
    app_rate: 0.05,
    rank: 224,
    avg_round: 27900,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 224,
    avg_round: 32520,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 224,
    avg_round: 39380,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 224,
    avg_round: 34533,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 224,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'tingyun-fugue',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 224,
    avg_round: 29648,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 224,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 224,
    avg_round: 37160,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 224,
    avg_round: 25000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 224,
    avg_round: 33013,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 224,
    avg_round: 39600,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.05,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 224,
    avg_round: 33647,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 224,
    avg_round: 25792,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Sparkle',
    app_rate: 0.05,
    rank: 224,
    avg_round: 32480,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 224,
    avg_round: 26530,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Bailu',
    app_rate: 0.05,
    rank: 224,
    avg_round: 31640,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Gepard',
    app_rate: 0.05,
    rank: 224,
    avg_round: 28704,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Sunday',
    app_rate: 0.05,
    rank: 224,
    avg_round: 32560,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 224,
    avg_round: 33600,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 248,
    avg_round: 31640,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 248,
    avg_round: 28120,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 248,
    avg_round: 30467,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 248,
    avg_round: 26320,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Sunday',
    app_rate: 0.04,
    rank: 248,
    avg_round: 35693,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 248,
    avg_round: 34660,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 248,
    avg_round: 29640,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Clara',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 248,
    avg_round: 28440,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 248,
    avg_round: 29493,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 248,
    avg_round: 25700,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 248,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 248,
    avg_round: 24240,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 248,
    avg_round: 29693,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 248,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.04,
    rank: 248,
    avg_round: 37627,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 248,
    avg_round: 34440,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Sunday',
    app_rate: 0.04,
    rank: 248,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.04,
    rank: 248,
    avg_round: 29380,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 248,
    avg_round: 28860,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 248,
    avg_round: 29320,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 248,
    avg_round: 27910,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 248,
    avg_round: 31800,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 248,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'Tingyun',
    app_rate: 0.04,
    rank: 248,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.04,
    rank: 248,
    avg_round: 34740,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 248,
    avg_round: 26800,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 248,
    avg_round: 33453,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 248,
    avg_round: 29240,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Argenti',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 248,
    avg_round: 31573,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 248,
    avg_round: 34440,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 248,
    avg_round: 34060,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 248,
    avg_round: 27360,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Sunday',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 32640,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 280,
    avg_round: 29600,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'tingyun-fugue',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 39680,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Moze',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 32080,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 280,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Herta',
    char_four: 'Robin',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 29040,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 280,
    avg_round: 37180,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 32460,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 33080,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Boothill',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 280,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 280,
    avg_round: 29980,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 27180,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Tingyun',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 28080,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Asta',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 26360,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Sunday',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 33580,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 280,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Robin',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 36400,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 39160,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 280,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 31000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Welt',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 27060,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 34260,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 280,
    avg_round: 39040,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'tingyun-fugue',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 280,
    avg_round: 30600,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 32147,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 31140,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 280,
    avg_round: 27480,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 35680,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 34520,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 280,
    avg_round: 33640,
    star_num: '4'
  },
  {
    char_one: 'Serval',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 31480,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 280,
    avg_round: 30947,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Serval',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 280,
    avg_round: 37560,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 30220,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 37720,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 35040,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 280,
    avg_round: 32213,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Sunday',
    app_rate: 0.03,
    rank: 280,
    avg_round: 34520,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 32720,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Welt',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 31420,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 25260,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 24400,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Acheron',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 36000,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Yunli',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 280,
    avg_round: 38930,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Bronya',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'tingyun-fugue',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 280,
    avg_round: 33700,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.03,
    rank: 280,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'trailblazer-preservation',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 32040,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 30480,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 280,
    avg_round: 29280,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Sunday',
    app_rate: 0.03,
    rank: 280,
    avg_round: 35140,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 280,
    avg_round: 36920,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 24720,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 24800,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 36540,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 29280,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 29760,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Herta',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 29320,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 280,
    avg_round: 25340,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 36880,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 30160,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 280,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Bailu',
    app_rate: 0.03,
    rank: 280,
    avg_round: 24160,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 280,
    avg_round: 27280,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 280,
    avg_round: 29107,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 29880,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 280,
    avg_round: 32280,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 36340,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'march-7th-swordmaster',
    char_four: 'Robin',
    app_rate: 0.03,
    rank: 280,
    avg_round: 27260,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Sunday',
    app_rate: 0.03,
    rank: 280,
    avg_round: 32700,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Herta',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 280,
    avg_round: 37500,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 280,
    avg_round: 31307,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-harmony',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 30880,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 280,
    avg_round: 33000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 39960,
    star_num: '4'
  },
  {
    char_one: 'Serval',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 30380,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 38907,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 31413,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 27280,
    star_num: '4'
  },
  {
    char_one: 'Serval',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 29460,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 38640,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 39240,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 34320,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'trailblazer-harmony',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 30200,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 280,
    avg_round: 38820,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 39440,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Sunday',
    app_rate: 0.03,
    rank: 280,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 30940,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'march-7th',
    app_rate: 0.03,
    rank: 280,
    avg_round: 32860,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 280,
    avg_round: 25240,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 280,
    avg_round: 36340,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Sunday',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 30980,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 280,
    avg_round: 34760,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 280,
    avg_round: 26440,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 25700,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'tingyun-fugue',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 36307,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 280,
    avg_round: 28480,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 280,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'tingyun-fugue',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Acheron',
    char_three: 'Jiaoqiu',
    char_four: 'Pela',
    app_rate: 0.02,
    rank: 428,
    avg_round: 30840,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Sunday',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 34120,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Kafka',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Sunday',
    app_rate: 0.02,
    rank: 428,
    avg_round: 33200,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 36500,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Herta',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 33520,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'tingyun-fugue',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Serval',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Pela',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 36040,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 428,
    avg_round: 31800,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 428,
    avg_round: 29700,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 428,
    avg_round: 25580,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 37720,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 30400,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Acheron',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Clara',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 428,
    avg_round: 29560,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 24240,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 428,
    avg_round: 25840,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 428,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 30240,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 25440,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 27320,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'tingyun-fugue',
    char_four: 'trailblazer-harmony',
    app_rate: 0.02,
    rank: 428,
    avg_round: 35600,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Sampo',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 27880,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 33400,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sampo',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sampo',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 428,
    avg_round: 34800,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Serval',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Sunday',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 428,
    avg_round: 27320,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'tingyun-fugue',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 428,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Sunday',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Acheron',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Serval',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 428,
    avg_round: 29120,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 31100,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Welt',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 23720,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 33360,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 25560,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'Robin',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 31520,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 26700,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 28120,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 428,
    avg_round: 31840,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 28760,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Pela',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 428,
    avg_round: 33920,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 35340,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 35760,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 428,
    avg_round: 26160,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Robin',
    char_four: 'Sunday',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Acheron',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 428,
    avg_round: 35960,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 29840,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 28040,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Jiaoqiu',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Serval',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 428,
    avg_round: 31720,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Bronya',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Hanya',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 428,
    avg_round: 32400,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Sunday',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 428,
    avg_round: 30040,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 28440,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Pela',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 34840,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 428,
    avg_round: 36440,
    star_num: '4'
  },
  {
    char_one: 'Serval',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 22320,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Yunli',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Welt',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 428,
    avg_round: 30360,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 29560,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'topaz',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 29880,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 36840,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 32440,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'Robin',
    app_rate: 0.02,
    rank: 428,
    avg_round: 39720,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 28200,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Sparkle',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 39440,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Argenti',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 31260,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 428,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 33880,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 31400,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Welt',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 24320,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Sunday',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 26040,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'tingyun-fugue',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Sunday',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 428,
    avg_round: 36560,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 428,
    avg_round: 32600,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Acheron',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 31400,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Himeko',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 27960,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 29240,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Sunday',
    app_rate: 0.02,
    rank: 428,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'tingyun-fugue',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 428,
    avg_round: 29600,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Kafka',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 24600,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'trailblazer-harmony',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 428,
    avg_round: 30760,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'tingyun-fugue',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Pela',
    char_four: 'Sunday',
    app_rate: 0.02,
    rank: 428,
    avg_round: 37240,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-harmony',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 33520,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 428,
    avg_round: 35800,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 428,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Serval',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 31460,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 30480,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 428,
    avg_round: 35640,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 428,
    avg_round: 35520,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 428,
    avg_round: 33940,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 428,
    avg_round: 28500,
    star_num: '4'
  },
  {
    char_one: 'Serval',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 27120,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 32640,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 36280,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Acheron',
    char_three: 'Welt',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 26040,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 428,
    avg_round: 30560,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 29100,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Tingyun',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 428,
    avg_round: 33160,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 30320,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 428,
    avg_round: 24960,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Himeko',
    char_three: 'tingyun-fugue',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 428,
    avg_round: 26640,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Serval',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 27260,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 24180,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 35120,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 428,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 428,
    avg_round: 30920,
    star_num: '4'
  }
];
