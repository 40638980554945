export const ShiyuDuos = [
  {
    char: 'Miyabi',
    round: 72,
    char_1: 'Soukaku',
    app_rate_1: 42.44,
    avg_round_1: 77,
    app_flat_1: 0,
    char_2: 'Yanagi',
    app_rate_2: 36.63,
    avg_round_2: 75,
    app_flat_2: 0,
    char_3: 'Lucy',
    app_rate_3: 29.91,
    avg_round_3: 71,
    app_flat_3: 0,
    char_4: 'Lycaon',
    app_rate_4: 23.08,
    avg_round_4: 74,
    app_flat_4: 0,
    char_5: 'Rina',
    app_rate_5: 18.0,
    avg_round_5: 78,
    app_flat_5: 0,
    char_6: 'Caesar',
    app_rate_6: 17.85,
    avg_round_6: 73,
    app_flat_6: 0,
    char_7: 'Burnice',
    app_rate_7: 16.28,
    avg_round_7: 69,
    app_flat_7: 0,
    char_8: 'Lighter',
    app_rate_8: 8.62,
    avg_round_8: 73,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 5.29,
    avg_round_9: 85,
    app_flat_9: 0,
    char_10: 'Piper',
    app_rate_10: 4.12,
    avg_round_10: 81,
    app_flat_10: 0,
    char_11: 'grace-howard',
    app_rate_11: 2.24,
    avg_round_11: 94,
    app_flat_11: 0,
    char_12: 'Harumasa',
    app_rate_12: 2.24,
    avg_round_12: 107,
    app_flat_12: 0,
    char_13: 'Seth',
    app_rate_13: 1.82,
    avg_round_13: 96,
    app_flat_13: 0,
    char_14: 'Qingyi',
    app_rate_14: 1.75,
    avg_round_14: 92,
    app_flat_14: 0,
    char_15: 'jane-doe',
    app_rate_15: 1.56,
    avg_round_15: 85,
    app_flat_15: 0,
    char_16: 'Ellen',
    app_rate_16: 1.41,
    avg_round_16: 91,
    app_flat_16: 0,
    char_17: 'anby-demara',
    app_rate_17: 1.09,
    avg_round_17: 95,
    app_flat_17: 0,
    char_18: 'Koleda',
    app_rate_18: 0.91,
    avg_round_18: 97,
    app_flat_18: 0,
    char_19: 'zhu-yuan',
    app_rate_19: 0.7,
    avg_round_19: 89,
    app_flat_19: 0,
    char_20: 'soldier-11',
    app_rate_20: 0.1,
    avg_round_20: 119,
    app_flat_20: 0,
    char_21: 'Ben',
    app_rate_21: 0.05,
    avg_round_21: 86,
    app_flat_21: 0,
    char_22: 'Corin',
    app_rate_22: 0.03,
    avg_round_22: 146,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Soukaku',
    round: 90,
    char_1: 'Miyabi',
    app_rate_1: 78.32,
    avg_round_1: 77,
    app_flat_1: 0,
    char_2: 'Lycaon',
    app_rate_2: 54.18,
    avg_round_2: 93,
    app_flat_2: 0,
    char_3: 'Ellen',
    app_rate_3: 31.59,
    avg_round_3: 131,
    app_flat_3: 0,
    char_4: 'Caesar',
    app_rate_4: 11.39,
    avg_round_4: 95,
    app_flat_4: 0,
    char_5: 'Yanagi',
    app_rate_5: 10.43,
    avg_round_5: 79,
    app_flat_5: 0,
    char_6: 'Qingyi',
    app_rate_6: 5.05,
    avg_round_6: 131,
    app_flat_6: 0,
    char_7: 'Lighter',
    app_rate_7: 4.18,
    avg_round_7: 120,
    app_flat_7: 0,
    char_8: 'Burnice',
    app_rate_8: 3.51,
    avg_round_8: 73,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 3.37,
    avg_round_9: 98,
    app_flat_9: 0,
    char_10: 'Harumasa',
    app_rate_10: 2.02,
    avg_round_10: 142,
    app_flat_10: 0,
    char_11: 'Lucy',
    app_rate_11: 1.97,
    avg_round_11: 83,
    app_flat_11: 0,
    char_12: 'Rina',
    app_rate_12: 1.68,
    avg_round_12: 115,
    app_flat_12: 0,
    char_13: 'Koleda',
    app_rate_13: 1.59,
    avg_round_13: 127,
    app_flat_13: 0,
    char_14: 'anby-demara',
    app_rate_14: 1.49,
    avg_round_14: 138,
    app_flat_14: 0,
    char_15: 'jane-doe',
    app_rate_15: 1.3,
    avg_round_15: 83,
    app_flat_15: 0,
    char_16: 'grace-howard',
    app_rate_16: 1.01,
    avg_round_16: 92,
    app_flat_16: 0,
    char_17: 'Piper',
    app_rate_17: 1.01,
    avg_round_17: 88,
    app_flat_17: 0,
    char_18: 'Seth',
    app_rate_18: 1.01,
    avg_round_18: 119,
    app_flat_18: 0,
    char_19: 'zhu-yuan',
    app_rate_19: 0.34,
    avg_round_19: 101,
    app_flat_19: 0,
    char_20: 'Corin',
    app_rate_20: 0.1,
    avg_round_20: 87,
    app_flat_20: 0,
    char_21: 'billy-kid',
    app_rate_21: 0.05,
    avg_round_21: 182,
    app_flat_21: 0,
    char_22: 'soldier-11',
    app_rate_22: 0.05,
    avg_round_22: 600,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Lucy',
    round: 91,
    char_1: 'Miyabi',
    app_rate_1: 68.78,
    avg_round_1: 71,
    app_flat_1: 0,
    char_2: 'Burnice',
    app_rate_2: 49.31,
    avg_round_2: 91,
    app_flat_2: 0,
    char_3: 'Yanagi',
    app_rate_3: 19.11,
    avg_round_3: 97,
    app_flat_3: 0,
    char_4: 'Lighter',
    app_rate_4: 19.11,
    avg_round_4: 80,
    app_flat_4: 0,
    char_5: 'Piper',
    app_rate_5: 13.72,
    avg_round_5: 108,
    app_flat_5: 0,
    char_6: 'jane-doe',
    app_rate_6: 11.32,
    avg_round_6: 136,
    app_flat_6: 0,
    char_7: 'Caesar',
    app_rate_7: 8.15,
    avg_round_7: 116,
    app_flat_7: 0,
    char_8: 'Seth',
    app_rate_8: 4.97,
    avg_round_8: 163,
    app_flat_8: 0,
    char_9: 'Harumasa',
    app_rate_9: 3.71,
    avg_round_9: 166,
    app_flat_9: 0,
    char_10: 'soldier-11',
    app_rate_10: 3.42,
    avg_round_10: 167,
    app_flat_10: 0,
    char_11: 'Soukaku',
    app_rate_11: 2.46,
    avg_round_11: 83,
    app_flat_11: 0,
    char_12: 'Qingyi',
    app_rate_12: 2.28,
    avg_round_12: 167,
    app_flat_12: 0,
    char_13: 'Koleda',
    app_rate_13: 1.8,
    avg_round_13: 152,
    app_flat_13: 0,
    char_14: 'Lycaon',
    app_rate_14: 1.32,
    avg_round_14: 92,
    app_flat_14: 0,
    char_15: 'nicole-demara',
    app_rate_15: 1.26,
    avg_round_15: 128,
    app_flat_15: 0,
    char_16: 'Ellen',
    app_rate_16: 0.78,
    avg_round_16: 153,
    app_flat_16: 0,
    char_17: 'anby-demara',
    app_rate_17: 0.66,
    avg_round_17: 167,
    app_flat_17: 0,
    char_18: 'Ben',
    app_rate_18: 0.6,
    avg_round_18: 150,
    app_flat_18: 0,
    char_19: 'grace-howard',
    app_rate_19: 0.6,
    avg_round_19: 172,
    app_flat_19: 0,
    char_20: 'Rina',
    app_rate_20: 0.54,
    avg_round_20: 107,
    app_flat_20: 0,
    char_21: 'Nekomata',
    app_rate_21: 0.36,
    avg_round_21: 170,
    app_flat_21: 0,
    char_22: 'Corin',
    app_rate_22: 0.3,
    avg_round_22: 154,
    app_flat_22: 0,
    char_23: 'billy-kid',
    app_rate_23: 0.24,
    avg_round_23: 122,
    app_flat_23: 0,
    char_24: 'zhu-yuan',
    app_rate_24: 0.24,
    avg_round_24: 66,
    app_flat_24: 0,
    char_25: 'Anton',
    app_rate_25: 0.06,
    avg_round_25: 221,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Yanagi',
    round: 93,
    char_1: 'Miyabi',
    app_rate_1: 55.93,
    avg_round_1: 75,
    app_flat_1: 0,
    char_2: 'Rina',
    app_rate_2: 39.14,
    avg_round_2: 97,
    app_flat_2: 0,
    char_3: 'Caesar',
    app_rate_3: 30.55,
    avg_round_3: 93,
    app_flat_3: 0,
    char_4: 'Burnice',
    app_rate_4: 25.06,
    avg_round_4: 100,
    app_flat_4: 0,
    char_5: 'Seth',
    app_rate_5: 15.0,
    avg_round_5: 129,
    app_flat_5: 0,
    char_6: 'Lucy',
    app_rate_6: 12.69,
    avg_round_6: 97,
    app_flat_6: 0,
    char_7: 'Soukaku',
    app_rate_7: 8.63,
    avg_round_7: 79,
    app_flat_7: 0,
    char_8: 'Qingyi',
    app_rate_8: 6.52,
    avg_round_8: 147,
    app_flat_8: 0,
    char_9: 'Harumasa',
    app_rate_9: 4.02,
    avg_round_9: 153,
    app_flat_9: 0,
    char_10: 'nicole-demara',
    app_rate_10: 2.63,
    avg_round_10: 100,
    app_flat_10: 0,
    char_11: 'anby-demara',
    app_rate_11: 2.11,
    avg_round_11: 149,
    app_flat_11: 0,
    char_12: 'jane-doe',
    app_rate_12: 1.79,
    avg_round_12: 141,
    app_flat_12: 0,
    char_13: 'grace-howard',
    app_rate_13: 1.55,
    avg_round_13: 142,
    app_flat_13: 0,
    char_14: 'Piper',
    app_rate_14: 1.31,
    avg_round_14: 131,
    app_flat_14: 0,
    char_15: 'Lycaon',
    app_rate_15: 1.15,
    avg_round_15: 80,
    app_flat_15: 0,
    char_16: 'Lighter',
    app_rate_16: 0.6,
    avg_round_16: 142,
    app_flat_16: 0,
    char_17: 'Koleda',
    app_rate_17: 0.24,
    avg_round_17: 145,
    app_flat_17: 0,
    char_18: 'zhu-yuan',
    app_rate_18: 0.2,
    avg_round_18: 165,
    app_flat_18: 0,
    char_19: 'Anton',
    app_rate_19: 0.16,
    avg_round_19: 168,
    app_flat_19: 0,
    char_20: 'Ellen',
    app_rate_20: 0.16,
    avg_round_20: 176,
    app_flat_20: 0,
    char_21: 'soldier-11',
    app_rate_21: 0.12,
    avg_round_21: 174,
    app_flat_21: 0,
    char_22: 'Ben',
    app_rate_22: 0.04,
    avg_round_22: 107,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Lycaon',
    round: 93,
    char_1: 'Soukaku',
    app_rate_1: 85.83,
    avg_round_1: 93,
    app_flat_1: 0,
    char_2: 'Miyabi',
    app_rate_2: 67.48,
    avg_round_2: 74,
    app_flat_2: 0,
    char_3: 'Ellen',
    app_rate_3: 39.3,
    avg_round_3: 128,
    app_flat_3: 0,
    char_4: 'Caesar',
    app_rate_4: 6.93,
    avg_round_4: 119,
    app_flat_4: 0,
    char_5: 'Rina',
    app_rate_5: 4.95,
    avg_round_5: 120,
    app_flat_5: 0,
    char_6: 'Lighter',
    app_rate_6: 4.11,
    avg_round_6: 115,
    app_flat_6: 0,
    char_7: 'nicole-demara',
    app_rate_7: 4.11,
    avg_round_7: 113,
    app_flat_7: 0,
    char_8: 'Yanagi',
    app_rate_8: 2.21,
    avg_round_8: 80,
    app_flat_8: 0,
    char_9: 'Lucy',
    app_rate_9: 1.68,
    avg_round_9: 92,
    app_flat_9: 0,
    char_10: 'Harumasa',
    app_rate_10: 1.52,
    avg_round_10: 181,
    app_flat_10: 0,
    char_11: 'zhu-yuan',
    app_rate_11: 0.99,
    avg_round_11: 154,
    app_flat_11: 0,
    char_12: 'Burnice',
    app_rate_12: 0.3,
    avg_round_12: 130,
    app_flat_12: 0,
    char_13: 'grace-howard',
    app_rate_13: 0.15,
    avg_round_13: 218,
    app_flat_13: 0,
    char_14: 'jane-doe',
    app_rate_14: 0.15,
    avg_round_14: 131,
    app_flat_14: 0,
    char_15: 'Corin',
    app_rate_15: 0.08,
    avg_round_15: 124,
    app_flat_15: 0,
    char_16: 'Qingyi',
    app_rate_16: 0.08,
    avg_round_16: 185,
    app_flat_16: 0,
    char_17: 'Nekomata',
    app_rate_17: 0.08,
    avg_round_17: 172,
    app_flat_17: 0,
    char_18: 'billy-kid',
    app_rate_18: 0.08,
    avg_round_18: 182,
    app_flat_18: 0,
    char_19: 'Seth',
    app_rate_19: 0.08,
    avg_round_19: 600,
    app_flat_19: 0,
    char_20: 'Koleda',
    app_rate_20: 0.08,
    avg_round_20: 116,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Lighter',
    round: 97,
    char_1: 'Miyabi',
    app_rate_1: 63.65,
    avg_round_1: 73,
    app_flat_1: 0,
    char_2: 'Lucy',
    app_rate_2: 61.35,
    avg_round_2: 80,
    app_flat_2: 0,
    char_3: 'Ellen',
    app_rate_3: 24.42,
    avg_round_3: 125,
    app_flat_3: 0,
    char_4: 'Soukaku',
    app_rate_4: 16.73,
    avg_round_4: 120,
    app_flat_4: 0,
    char_5: 'Lycaon',
    app_rate_5: 10.38,
    avg_round_5: 115,
    app_flat_5: 0,
    char_6: 'soldier-11',
    app_rate_6: 9.42,
    avg_round_6: 157,
    app_flat_6: 0,
    char_7: 'Caesar',
    app_rate_7: 7.88,
    avg_round_7: 163,
    app_flat_7: 0,
    char_8: 'Burnice',
    app_rate_8: 7.12,
    avg_round_8: 124,
    app_flat_8: 0,
    char_9: 'Harumasa',
    app_rate_9: 5.38,
    avg_round_9: 175,
    app_flat_9: 0,
    char_10: 'Yanagi',
    app_rate_10: 2.88,
    avg_round_10: 142,
    app_flat_10: 0,
    char_11: 'Rina',
    app_rate_11: 2.31,
    avg_round_11: 177,
    app_flat_11: 0,
    char_12: 'nicole-demara',
    app_rate_12: 2.12,
    avg_round_12: 136,
    app_flat_12: 0,
    char_13: 'zhu-yuan',
    app_rate_13: 1.54,
    avg_round_13: 135,
    app_flat_13: 0,
    char_14: 'Ben',
    app_rate_14: 1.35,
    avg_round_14: 145,
    app_flat_14: 0,
    char_15: 'Piper',
    app_rate_15: 0.77,
    avg_round_15: 174,
    app_flat_15: 0,
    char_16: 'jane-doe',
    app_rate_16: 0.58,
    avg_round_16: 165,
    app_flat_16: 0,
    char_17: 'grace-howard',
    app_rate_17: 0.38,
    avg_round_17: 244,
    app_flat_17: 0,
    char_18: 'billy-kid',
    app_rate_18: 0.19,
    avg_round_18: 153,
    app_flat_18: 0,
    char_19: 'Corin',
    app_rate_19: 0.19,
    avg_round_19: 146,
    app_flat_19: 0,
    char_20: 'Seth',
    app_rate_20: 0.19,
    avg_round_20: 600,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Burnice',
    round: 98,
    char_1: 'Lucy',
    app_rate_1: 50.09,
    avg_round_1: 91,
    app_flat_1: 0,
    char_2: 'Yanagi',
    app_rate_2: 38.34,
    avg_round_2: 100,
    app_flat_2: 0,
    char_3: 'Miyabi',
    app_rate_3: 38.04,
    avg_round_3: 69,
    app_flat_3: 0,
    char_4: 'Caesar',
    app_rate_4: 22.15,
    avg_round_4: 114,
    app_flat_4: 0,
    char_5: 'jane-doe',
    app_rate_5: 17.65,
    avg_round_5: 134,
    app_flat_5: 0,
    char_6: 'Rina',
    app_rate_6: 9.07,
    avg_round_6: 105,
    app_flat_6: 0,
    char_7: 'Seth',
    app_rate_7: 8.89,
    avg_round_7: 142,
    app_flat_7: 0,
    char_8: 'Piper',
    app_rate_8: 5.36,
    avg_round_8: 132,
    app_flat_8: 0,
    char_9: 'Soukaku',
    app_rate_9: 4.44,
    avg_round_9: 73,
    app_flat_9: 0,
    char_10: 'grace-howard',
    app_rate_10: 2.5,
    avg_round_10: 155,
    app_flat_10: 0,
    char_11: 'Harumasa',
    app_rate_11: 2.5,
    avg_round_11: 155,
    app_flat_11: 0,
    char_12: 'Lighter',
    app_rate_12: 2.25,
    avg_round_12: 124,
    app_flat_12: 0,
    char_13: 'Qingyi',
    app_rate_13: 1.95,
    avg_round_13: 152,
    app_flat_13: 0,
    char_14: 'nicole-demara',
    app_rate_14: 1.03,
    avg_round_14: 104,
    app_flat_14: 0,
    char_15: 'soldier-11',
    app_rate_15: 0.85,
    avg_round_15: 179,
    app_flat_15: 0,
    char_16: 'Koleda',
    app_rate_16: 0.67,
    avg_round_16: 159,
    app_flat_16: 0,
    char_17: 'anby-demara',
    app_rate_17: 0.49,
    avg_round_17: 153,
    app_flat_17: 0,
    char_18: 'Ellen',
    app_rate_18: 0.43,
    avg_round_18: 171,
    app_flat_18: 0,
    char_19: 'Lycaon',
    app_rate_19: 0.24,
    avg_round_19: 130,
    app_flat_19: 0,
    char_20: 'Nekomata',
    app_rate_20: 0.18,
    avg_round_20: 188,
    app_flat_20: 0,
    char_21: 'Ben',
    app_rate_21: 0.12,
    avg_round_21: 118,
    app_flat_21: 0,
    char_22: 'zhu-yuan',
    app_rate_22: 0.12,
    avg_round_22: 90,
    app_flat_22: 0,
    char_23: 'Anton',
    app_rate_23: 0.06,
    avg_round_23: 107,
    app_flat_23: 0,
    char_24: 'billy-kid',
    app_rate_24: 0.06,
    avg_round_24: 153,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Caesar',
    round: 105,
    char_1: 'Yanagi',
    app_rate_1: 45.07,
    avg_round_1: 93,
    app_flat_1: 0,
    char_2: 'Miyabi',
    app_rate_2: 40.2,
    avg_round_2: 73,
    app_flat_2: 0,
    char_3: 'Burnice',
    app_rate_3: 21.36,
    avg_round_3: 114,
    app_flat_3: 0,
    char_4: 'Soukaku',
    app_rate_4: 13.91,
    avg_round_4: 95,
    app_flat_4: 0,
    char_5: 'jane-doe',
    app_rate_5: 13.15,
    avg_round_5: 144,
    app_flat_5: 0,
    char_6: 'Ellen',
    app_rate_6: 11.27,
    avg_round_6: 128,
    app_flat_6: 0,
    char_7: 'Harumasa',
    app_rate_7: 9.1,
    avg_round_7: 157,
    app_flat_7: 0,
    char_8: 'Lucy',
    app_rate_8: 7.98,
    avg_round_8: 116,
    app_flat_8: 0,
    char_9: 'Qingyi',
    app_rate_9: 7.92,
    avg_round_9: 150,
    app_flat_9: 0,
    char_10: 'Rina',
    app_rate_10: 7.28,
    avg_round_10: 105,
    app_flat_10: 0,
    char_11: 'nicole-demara',
    app_rate_11: 6.81,
    avg_round_11: 113,
    app_flat_11: 0,
    char_12: 'Seth',
    app_rate_12: 6.81,
    avg_round_12: 158,
    app_flat_12: 0,
    char_13: 'zhu-yuan',
    app_rate_13: 5.52,
    avg_round_13: 118,
    app_flat_13: 0,
    char_14: 'Lycaon',
    app_rate_14: 5.34,
    avg_round_14: 119,
    app_flat_14: 0,
    char_15: 'Piper',
    app_rate_15: 3.87,
    avg_round_15: 133,
    app_flat_15: 0,
    char_16: 'Lighter',
    app_rate_16: 2.41,
    avg_round_16: 163,
    app_flat_16: 0,
    char_17: 'grace-howard',
    app_rate_17: 1.64,
    avg_round_17: 164,
    app_flat_17: 0,
    char_18: 'soldier-11',
    app_rate_18: 1.47,
    avg_round_18: 173,
    app_flat_18: 0,
    char_19: 'anby-demara',
    app_rate_19: 1.17,
    avg_round_19: 182,
    app_flat_19: 0,
    char_20: 'Nekomata',
    app_rate_20: 0.82,
    avg_round_20: 138,
    app_flat_20: 0,
    char_21: 'billy-kid',
    app_rate_21: 0.76,
    avg_round_21: 137,
    app_flat_21: 0,
    char_22: 'Corin',
    app_rate_22: 0.47,
    avg_round_22: 168,
    app_flat_22: 0,
    char_23: 'Koleda',
    app_rate_23: 0.18,
    avg_round_23: 167,
    app_flat_23: 0,
    char_24: 'Ben',
    app_rate_24: 0.12,
    avg_round_24: 144,
    app_flat_24: 0,
    char_25: 'Anton',
    app_rate_25: 0.12,
    avg_round_25: 156,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Piper',
    round: 109,
    char_1: 'Lucy',
    app_rate_1: 74.11,
    avg_round_1: 108,
    app_flat_1: 0,
    char_2: 'Miyabi',
    app_rate_2: 51.13,
    avg_round_2: 81,
    app_flat_2: 0,
    char_3: 'Burnice',
    app_rate_3: 28.48,
    avg_round_3: 132,
    app_flat_3: 0,
    char_4: 'Caesar',
    app_rate_4: 21.36,
    avg_round_4: 133,
    app_flat_4: 0,
    char_5: 'Yanagi',
    app_rate_5: 10.68,
    avg_round_5: 131,
    app_flat_5: 0,
    char_6: 'Soukaku',
    app_rate_6: 6.8,
    avg_round_6: 88,
    app_flat_6: 0,
    char_7: 'jane-doe',
    app_rate_7: 4.85,
    avg_round_7: 185,
    app_flat_7: 0,
    char_8: 'nicole-demara',
    app_rate_8: 2.59,
    avg_round_8: 112,
    app_flat_8: 0,
    char_9: 'Seth',
    app_rate_9: 2.27,
    avg_round_9: 174,
    app_flat_9: 0,
    char_10: 'Nekomata',
    app_rate_10: 1.94,
    avg_round_10: 170,
    app_flat_10: 0,
    char_11: 'Rina',
    app_rate_11: 1.29,
    avg_round_11: 172,
    app_flat_11: 0,
    char_12: 'Corin',
    app_rate_12: 1.29,
    avg_round_12: 138,
    app_flat_12: 0,
    char_13: 'Lighter',
    app_rate_13: 1.29,
    avg_round_13: 174,
    app_flat_13: 0,
    char_14: 'grace-howard',
    app_rate_14: 0.97,
    avg_round_14: 210,
    app_flat_14: 0,
    char_15: 'Harumasa',
    app_rate_15: 0.97,
    avg_round_15: 161,
    app_flat_15: 0,
    char_16: 'Qingyi',
    app_rate_16: 0.97,
    avg_round_16: 154,
    app_flat_16: 0,
    char_17: 'Koleda',
    app_rate_17: 0.32,
    avg_round_17: 127,
    app_flat_17: 0,
    char_18: 'billy-kid',
    app_rate_18: 0.32,
    avg_round_18: 17,
    app_flat_18: 0,
    char_19: 'Ben',
    app_rate_19: 0.32,
    avg_round_19: 149,
    app_flat_19: 0,
    char_20: 'Ellen',
    app_rate_20: 0.32,
    avg_round_20: 600,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Rina',
    round: 114,
    char_1: 'Yanagi',
    app_rate_1: 60.26,
    avg_round_1: 97,
    app_flat_1: 0,
    char_2: 'Miyabi',
    app_rate_2: 42.31,
    avg_round_2: 78,
    app_flat_2: 0,
    char_3: 'Harumasa',
    app_rate_3: 27.99,
    avg_round_3: 162,
    app_flat_3: 0,
    char_4: 'Qingyi',
    app_rate_4: 24.13,
    avg_round_4: 152,
    app_flat_4: 0,
    char_5: 'Seth',
    app_rate_5: 11.64,
    avg_round_5: 137,
    app_flat_5: 0,
    char_6: 'Burnice',
    app_rate_6: 9.12,
    avg_round_6: 105,
    app_flat_6: 0,
    char_7: 'Caesar',
    app_rate_7: 7.59,
    avg_round_7: 105,
    app_flat_7: 0,
    char_8: 'grace-howard',
    app_rate_8: 7.47,
    avg_round_8: 137,
    app_flat_8: 0,
    char_9: 'anby-demara',
    app_rate_9: 6.67,
    avg_round_9: 180,
    app_flat_9: 0,
    char_10: 'Lycaon',
    app_rate_10: 3.98,
    avg_round_10: 120,
    app_flat_10: 0,
    char_11: 'jane-doe',
    app_rate_11: 3.0,
    avg_round_11: 184,
    app_flat_11: 0,
    char_12: 'Ellen',
    app_rate_12: 2.88,
    avg_round_12: 152,
    app_flat_12: 0,
    char_13: 'Soukaku',
    app_rate_13: 2.14,
    avg_round_13: 115,
    app_flat_13: 0,
    char_14: 'Anton',
    app_rate_14: 1.47,
    avg_round_14: 176,
    app_flat_14: 0,
    char_15: 'Lighter',
    app_rate_15: 0.73,
    avg_round_15: 177,
    app_flat_15: 0,
    char_16: 'Corin',
    app_rate_16: 0.55,
    avg_round_16: 167,
    app_flat_16: 0,
    char_17: 'Lucy',
    app_rate_17: 0.55,
    avg_round_17: 107,
    app_flat_17: 0,
    char_18: 'nicole-demara',
    app_rate_18: 0.37,
    avg_round_18: 95,
    app_flat_18: 0,
    char_19: 'Koleda',
    app_rate_19: 0.37,
    avg_round_19: 216,
    app_flat_19: 0,
    char_20: 'Piper',
    app_rate_20: 0.24,
    avg_round_20: 172,
    app_flat_20: 0,
    char_21: 'zhu-yuan',
    app_rate_21: 0.12,
    avg_round_21: 105,
    app_flat_21: 0,
    char_22: 'Ben',
    app_rate_22: 0.06,
    avg_round_22: 231,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'zhu-yuan',
    round: 123,
    char_1: 'nicole-demara',
    app_rate_1: 99.49,
    avg_round_1: 126,
    app_flat_1: 0,
    char_2: 'Qingyi',
    app_rate_2: 76.17,
    avg_round_2: 123,
    app_flat_2: 0,
    char_3: 'anby-demara',
    app_rate_3: 12.21,
    avg_round_3: 145,
    app_flat_3: 0,
    char_4: 'Caesar',
    app_rate_4: 7.97,
    avg_round_4: 118,
    app_flat_4: 0,
    char_5: 'Koleda',
    app_rate_5: 2.29,
    avg_round_5: 154,
    app_flat_5: 0,
    char_6: 'Miyabi',
    app_rate_6: 2.29,
    avg_round_6: 89,
    app_flat_6: 0,
    char_7: 'Lycaon',
    app_rate_7: 1.1,
    avg_round_7: 154,
    app_flat_7: 0,
    char_8: 'Lighter',
    app_rate_8: 0.68,
    avg_round_8: 135,
    app_flat_8: 0,
    char_9: 'jane-doe',
    app_rate_9: 0.68,
    avg_round_9: 159,
    app_flat_9: 0,
    char_10: 'Soukaku',
    app_rate_10: 0.59,
    avg_round_10: 101,
    app_flat_10: 0,
    char_11: 'Yanagi',
    app_rate_11: 0.42,
    avg_round_11: 165,
    app_flat_11: 0,
    char_12: 'Lucy',
    app_rate_12: 0.34,
    avg_round_12: 66,
    app_flat_12: 0,
    char_13: 'Ellen',
    app_rate_13: 0.25,
    avg_round_13: 239,
    app_flat_13: 0,
    char_14: 'grace-howard',
    app_rate_14: 0.25,
    avg_round_14: 179,
    app_flat_14: 0,
    char_15: 'Harumasa',
    app_rate_15: 0.17,
    avg_round_15: 244,
    app_flat_15: 0,
    char_16: 'Burnice',
    app_rate_16: 0.17,
    avg_round_16: 90,
    app_flat_16: 0,
    char_17: 'Rina',
    app_rate_17: 0.17,
    avg_round_17: 105,
    app_flat_17: 0,
    char_18: 'Seth',
    app_rate_18: 0.08,
    avg_round_18: 184,
    app_flat_18: 0,
    char_19: 'Ben',
    app_rate_19: 0.08,
    avg_round_19: 247,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'nicole-demara',
    round: 124,
    char_1: 'zhu-yuan',
    app_rate_1: 69.24,
    avg_round_1: 126,
    app_flat_1: 0,
    char_2: 'Qingyi',
    app_rate_2: 62.34,
    avg_round_2: 126,
    app_flat_2: 0,
    char_3: 'anby-demara',
    app_rate_3: 15.23,
    avg_round_3: 158,
    app_flat_3: 0,
    char_4: 'Harumasa',
    app_rate_4: 13.58,
    avg_round_4: 165,
    app_flat_4: 0,
    char_5: 'Miyabi',
    app_rate_5: 11.98,
    avg_round_5: 85,
    app_flat_5: 0,
    char_6: 'Caesar',
    app_rate_6: 6.85,
    avg_round_6: 113,
    app_flat_6: 0,
    char_7: 'Soukaku',
    app_rate_7: 4.13,
    avg_round_7: 98,
    app_flat_7: 0,
    char_8: 'Yanagi',
    app_rate_8: 3.9,
    avg_round_8: 100,
    app_flat_8: 0,
    char_9: 'Lycaon',
    app_rate_9: 3.19,
    avg_round_9: 113,
    app_flat_9: 0,
    char_10: 'Seth',
    app_rate_10: 1.83,
    avg_round_10: 147,
    app_flat_10: 0,
    char_11: 'Ellen',
    app_rate_11: 1.77,
    avg_round_11: 137,
    app_flat_11: 0,
    char_12: 'Koleda',
    app_rate_12: 1.71,
    avg_round_12: 155,
    app_flat_12: 0,
    char_13: 'jane-doe',
    app_rate_13: 1.71,
    avg_round_13: 171,
    app_flat_13: 0,
    char_14: 'Nekomata',
    app_rate_14: 1.42,
    avg_round_14: 134,
    app_flat_14: 0,
    char_15: 'Lucy',
    app_rate_15: 1.24,
    avg_round_15: 128,
    app_flat_15: 0,
    char_16: 'billy-kid',
    app_rate_16: 1.0,
    avg_round_16: 132,
    app_flat_16: 0,
    char_17: 'Burnice',
    app_rate_17: 1.0,
    avg_round_17: 104,
    app_flat_17: 0,
    char_18: 'Lighter',
    app_rate_18: 0.65,
    avg_round_18: 136,
    app_flat_18: 0,
    char_19: 'Anton',
    app_rate_19: 0.47,
    avg_round_19: 122,
    app_flat_19: 0,
    char_20: 'Piper',
    app_rate_20: 0.47,
    avg_round_20: 112,
    app_flat_20: 0,
    char_21: 'grace-howard',
    app_rate_21: 0.41,
    avg_round_21: 170,
    app_flat_21: 0,
    char_22: 'Rina',
    app_rate_22: 0.35,
    avg_round_22: 95,
    app_flat_22: 0,
    char_23: 'Corin',
    app_rate_23: 0.24,
    avg_round_23: 168,
    app_flat_23: 0,
    char_24: 'soldier-11',
    app_rate_24: 0.18,
    avg_round_24: 100,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Ellen',
    round: 129,
    char_1: 'Soukaku',
    app_rate_1: 78.49,
    avg_round_1: 131,
    app_flat_1: 0,
    char_2: 'Lycaon',
    app_rate_2: 61.65,
    avg_round_2: 128,
    app_flat_2: 0,
    char_3: 'Caesar',
    app_rate_3: 22.94,
    avg_round_3: 128,
    app_flat_3: 0,
    char_4: 'Lighter',
    app_rate_4: 15.17,
    avg_round_4: 125,
    app_flat_4: 0,
    char_5: 'Qingyi',
    app_rate_5: 9.8,
    avg_round_5: 149,
    app_flat_5: 0,
    char_6: 'Miyabi',
    app_rate_6: 6.45,
    avg_round_6: 91,
    app_flat_6: 0,
    char_7: 'Rina',
    app_rate_7: 5.62,
    avg_round_7: 152,
    app_flat_7: 0,
    char_8: 'nicole-demara',
    app_rate_8: 3.58,
    avg_round_8: 137,
    app_flat_8: 0,
    char_9: 'Koleda',
    app_rate_9: 2.51,
    avg_round_9: 157,
    app_flat_9: 0,
    char_10: 'anby-demara',
    app_rate_10: 2.27,
    avg_round_10: 162,
    app_flat_10: 0,
    char_11: 'Lucy',
    app_rate_11: 1.55,
    avg_round_11: 153,
    app_flat_11: 0,
    char_12: 'Burnice',
    app_rate_12: 0.84,
    avg_round_12: 171,
    app_flat_12: 0,
    char_13: 'jane-doe',
    app_rate_13: 0.72,
    avg_round_13: 220,
    app_flat_13: 0,
    char_14: 'Yanagi',
    app_rate_14: 0.48,
    avg_round_14: 176,
    app_flat_14: 0,
    char_15: 'zhu-yuan',
    app_rate_15: 0.36,
    avg_round_15: 239,
    app_flat_15: 0,
    char_16: 'Harumasa',
    app_rate_16: 0.36,
    avg_round_16: 260,
    app_flat_16: 0,
    char_17: 'Seth',
    app_rate_17: 0.36,
    avg_round_17: 235,
    app_flat_17: 0,
    char_18: 'Corin',
    app_rate_18: 0.24,
    avg_round_18: 87,
    app_flat_18: 0,
    char_19: 'soldier-11',
    app_rate_19: 0.12,
    avg_round_19: 600,
    app_flat_19: 0,
    char_20: 'Piper',
    app_rate_20: 0.12,
    avg_round_20: 600,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Qingyi',
    round: 133,
    char_1: 'nicole-demara',
    app_rate_1: 57.36,
    avg_round_1: 126,
    app_flat_1: 0,
    char_2: 'zhu-yuan',
    app_rate_2: 48.78,
    avg_round_2: 123,
    app_flat_2: 0,
    char_3: 'Harumasa',
    app_rate_3: 33.79,
    avg_round_3: 156,
    app_flat_3: 0,
    char_4: 'Rina',
    app_rate_4: 21.4,
    avg_round_4: 152,
    app_flat_4: 0,
    char_5: 'Yanagi',
    app_rate_5: 8.91,
    avg_round_5: 147,
    app_flat_5: 0,
    char_6: 'Caesar',
    app_rate_6: 7.33,
    avg_round_6: 150,
    app_flat_6: 0,
    char_7: 'Soukaku',
    app_rate_7: 5.7,
    avg_round_7: 131,
    app_flat_7: 0,
    char_8: 'Ellen',
    app_rate_8: 4.45,
    avg_round_8: 149,
    app_flat_8: 0,
    char_9: 'Miyabi',
    app_rate_9: 3.64,
    avg_round_9: 92,
    app_flat_9: 0,
    char_10: 'Seth',
    app_rate_10: 3.1,
    avg_round_10: 179,
    app_flat_10: 0,
    char_11: 'jane-doe',
    app_rate_11: 2.5,
    avg_round_11: 185,
    app_flat_11: 0,
    char_12: 'Lucy',
    app_rate_12: 2.06,
    avg_round_12: 167,
    app_flat_12: 0,
    char_13: 'Burnice',
    app_rate_13: 1.74,
    avg_round_13: 152,
    app_flat_13: 0,
    char_14: 'grace-howard',
    app_rate_14: 1.41,
    avg_round_14: 185,
    app_flat_14: 0,
    char_15: 'Anton',
    app_rate_15: 1.25,
    avg_round_15: 161,
    app_flat_15: 0,
    char_16: 'Corin',
    app_rate_16: 0.76,
    avg_round_16: 166,
    app_flat_16: 0,
    char_17: 'Nekomata',
    app_rate_17: 0.65,
    avg_round_17: 159,
    app_flat_17: 0,
    char_18: 'soldier-11',
    app_rate_18: 0.43,
    avg_round_18: 182,
    app_flat_18: 0,
    char_19: 'billy-kid',
    app_rate_19: 0.33,
    avg_round_19: 172,
    app_flat_19: 0,
    char_20: 'Piper',
    app_rate_20: 0.16,
    avg_round_20: 154,
    app_flat_20: 0,
    char_21: 'anby-demara',
    app_rate_21: 0.16,
    avg_round_21: 202,
    app_flat_21: 0,
    char_22: 'Koleda',
    app_rate_22: 0.16,
    avg_round_22: 229,
    app_flat_22: 0,
    char_23: 'Ben',
    app_rate_23: 0.11,
    avg_round_23: 238,
    app_flat_23: 0,
    char_24: 'Lycaon',
    app_rate_24: 0.05,
    avg_round_24: 185,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'billy-kid',
    round: 142,
    char_1: 'nicole-demara',
    app_rate_1: 70.83,
    avg_round_1: 132,
    app_flat_1: 0,
    char_2: 'Caesar',
    app_rate_2: 54.17,
    avg_round_2: 137,
    app_flat_2: 0,
    char_3: 'Qingyi',
    app_rate_3: 25.0,
    avg_round_3: 172,
    app_flat_3: 0,
    char_4: 'Lucy',
    app_rate_4: 16.67,
    avg_round_4: 122,
    app_flat_4: 0,
    char_5: 'anby-demara',
    app_rate_5: 12.5,
    avg_round_5: 146,
    app_flat_5: 0,
    char_6: 'Lighter',
    app_rate_6: 4.17,
    avg_round_6: 153,
    app_flat_6: 0,
    char_7: 'Koleda',
    app_rate_7: 4.17,
    avg_round_7: 178,
    app_flat_7: 0,
    char_8: 'Piper',
    app_rate_8: 4.17,
    avg_round_8: 17,
    app_flat_8: 0,
    char_9: 'Lycaon',
    app_rate_9: 4.17,
    avg_round_9: 182,
    app_flat_9: 0,
    char_10: 'Soukaku',
    app_rate_10: 4.17,
    avg_round_10: 182,
    app_flat_10: 0,
    char_11: 'Burnice',
    app_rate_11: 4.17,
    avg_round_11: 153,
    app_flat_11: 0,
    char_12: 'Nekomata',
    app_rate_12: 4.17,
    avg_round_12: 325,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'jane-doe',
    round: 144,
    char_1: 'Seth',
    app_rate_1: 48.31,
    avg_round_1: 177,
    app_flat_1: 0,
    char_2: 'Burnice',
    app_rate_2: 44.48,
    avg_round_2: 134,
    app_flat_2: 0,
    char_3: 'Caesar',
    app_rate_3: 34.36,
    avg_round_3: 144,
    app_flat_3: 0,
    char_4: 'Lucy',
    app_rate_4: 28.99,
    avg_round_4: 136,
    app_flat_4: 0,
    char_5: 'Miyabi',
    app_rate_5: 9.2,
    avg_round_5: 85,
    app_flat_5: 0,
    char_6: 'Rina',
    app_rate_6: 7.52,
    avg_round_6: 184,
    app_flat_6: 0,
    char_7: 'Qingyi',
    app_rate_7: 7.06,
    avg_round_7: 185,
    app_flat_7: 0,
    char_8: 'Yanagi',
    app_rate_8: 6.9,
    avg_round_8: 141,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 4.45,
    avg_round_9: 171,
    app_flat_9: 0,
    char_10: 'Soukaku',
    app_rate_10: 4.14,
    avg_round_10: 83,
    app_flat_10: 0,
    char_11: 'grace-howard',
    app_rate_11: 3.83,
    avg_round_11: 210,
    app_flat_11: 0,
    char_12: 'Piper',
    app_rate_12: 2.3,
    avg_round_12: 185,
    app_flat_12: 0,
    char_13: 'zhu-yuan',
    app_rate_13: 1.23,
    avg_round_13: 159,
    app_flat_13: 0,
    char_14: 'anby-demara',
    app_rate_14: 1.07,
    avg_round_14: 233,
    app_flat_14: 0,
    char_15: 'Ellen',
    app_rate_15: 0.92,
    avg_round_15: 220,
    app_flat_15: 0,
    char_16: 'Harumasa',
    app_rate_16: 0.77,
    avg_round_16: 225,
    app_flat_16: 0,
    char_17: 'Koleda',
    app_rate_17: 0.61,
    avg_round_17: 210,
    app_flat_17: 0,
    char_18: 'Nekomata',
    app_rate_18: 0.46,
    avg_round_18: 196,
    app_flat_18: 0,
    char_19: 'Lighter',
    app_rate_19: 0.46,
    avg_round_19: 165,
    app_flat_19: 0,
    char_20: 'Ben',
    app_rate_20: 0.31,
    avg_round_20: 284,
    app_flat_20: 0,
    char_21: 'Lycaon',
    app_rate_21: 0.31,
    avg_round_21: 131,
    app_flat_21: 0,
    char_22: 'Anton',
    app_rate_22: 0.15,
    avg_round_22: 600,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Seth',
    round: 145,
    char_1: 'Yanagi',
    app_rate_1: 50.81,
    avg_round_1: 129,
    app_flat_1: 0,
    char_2: 'jane-doe',
    app_rate_2: 42.45,
    avg_round_2: 177,
    app_flat_2: 0,
    char_3: 'Rina',
    app_rate_3: 25.61,
    avg_round_3: 137,
    app_flat_3: 0,
    char_4: 'Burnice',
    app_rate_4: 19.68,
    avg_round_4: 142,
    app_flat_4: 0,
    char_5: 'Caesar',
    app_rate_5: 15.63,
    avg_round_5: 158,
    app_flat_5: 0,
    char_6: 'Lucy',
    app_rate_6: 11.19,
    avg_round_6: 163,
    app_flat_6: 0,
    char_7: 'Miyabi',
    app_rate_7: 9.43,
    avg_round_7: 96,
    app_flat_7: 0,
    char_8: 'Qingyi',
    app_rate_8: 7.68,
    avg_round_8: 179,
    app_flat_8: 0,
    char_9: 'grace-howard',
    app_rate_9: 6.06,
    avg_round_9: 188,
    app_flat_9: 0,
    char_10: 'Harumasa',
    app_rate_10: 5.39,
    avg_round_10: 210,
    app_flat_10: 0,
    char_11: 'nicole-demara',
    app_rate_11: 4.18,
    avg_round_11: 147,
    app_flat_11: 0,
    char_12: 'Soukaku',
    app_rate_12: 2.83,
    avg_round_12: 119,
    app_flat_12: 0,
    char_13: 'anby-demara',
    app_rate_13: 1.89,
    avg_round_13: 184,
    app_flat_13: 0,
    char_14: 'Piper',
    app_rate_14: 0.94,
    avg_round_14: 174,
    app_flat_14: 0,
    char_15: 'Anton',
    app_rate_15: 0.54,
    avg_round_15: 186,
    app_flat_15: 0,
    char_16: 'Ellen',
    app_rate_16: 0.4,
    avg_round_16: 235,
    app_flat_16: 0,
    char_17: 'Koleda',
    app_rate_17: 0.4,
    avg_round_17: 184,
    app_flat_17: 0,
    char_18: 'zhu-yuan',
    app_rate_18: 0.13,
    avg_round_18: 184,
    app_flat_18: 0,
    char_19: 'Nekomata',
    app_rate_19: 0.13,
    avg_round_19: 225,
    app_flat_19: 0,
    char_20: 'Lighter',
    app_rate_20: 0.13,
    avg_round_20: 600,
    app_flat_20: 0,
    char_21: 'Lycaon',
    app_rate_21: 0.13,
    avg_round_21: 600,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Koleda',
    round: 147,
    char_1: 'Miyabi',
    app_rate_1: 29.91,
    avg_round_1: 97,
    app_flat_1: 0,
    char_2: 'Soukaku',
    app_rate_2: 28.21,
    avg_round_2: 127,
    app_flat_2: 0,
    char_3: 'Lucy',
    app_rate_3: 25.64,
    avg_round_3: 152,
    app_flat_3: 0,
    char_4: 'nicole-demara',
    app_rate_4: 24.79,
    avg_round_4: 155,
    app_flat_4: 0,
    char_5: 'zhu-yuan',
    app_rate_5: 23.08,
    avg_round_5: 154,
    app_flat_5: 0,
    char_6: 'Ellen',
    app_rate_6: 17.95,
    avg_round_6: 157,
    app_flat_6: 0,
    char_7: 'Harumasa',
    app_rate_7: 9.4,
    avg_round_7: 199,
    app_flat_7: 0,
    char_8: 'Burnice',
    app_rate_8: 9.4,
    avg_round_8: 159,
    app_flat_8: 0,
    char_9: 'soldier-11',
    app_rate_9: 5.98,
    avg_round_9: 183,
    app_flat_9: 0,
    char_10: 'Yanagi',
    app_rate_10: 5.13,
    avg_round_10: 145,
    app_flat_10: 0,
    char_11: 'Rina',
    app_rate_11: 5.13,
    avg_round_11: 216,
    app_flat_11: 0,
    char_12: 'grace-howard',
    app_rate_12: 4.27,
    avg_round_12: 202,
    app_flat_12: 0,
    char_13: 'jane-doe',
    app_rate_13: 3.42,
    avg_round_13: 210,
    app_flat_13: 0,
    char_14: 'Seth',
    app_rate_14: 2.56,
    avg_round_14: 184,
    app_flat_14: 0,
    char_15: 'Anton',
    app_rate_15: 2.56,
    avg_round_15: 195,
    app_flat_15: 0,
    char_16: 'Caesar',
    app_rate_16: 2.56,
    avg_round_16: 167,
    app_flat_16: 0,
    char_17: 'Qingyi',
    app_rate_17: 2.56,
    avg_round_17: 229,
    app_flat_17: 0,
    char_18: 'Piper',
    app_rate_18: 0.85,
    avg_round_18: 127,
    app_flat_18: 0,
    char_19: 'billy-kid',
    app_rate_19: 0.85,
    avg_round_19: 178,
    app_flat_19: 0,
    char_20: 'anby-demara',
    app_rate_20: 0.85,
    avg_round_20: 218,
    app_flat_20: 0,
    char_21: 'Ben',
    app_rate_21: 0.85,
    avg_round_21: 284,
    app_flat_21: 0,
    char_22: 'Lycaon',
    app_rate_22: 0.85,
    avg_round_22: 116,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'grace-howard',
    round: 148,
    char_1: 'Rina',
    app_rate_1: 43.57,
    avg_round_1: 137,
    app_flat_1: 0,
    char_2: 'Harumasa',
    app_rate_2: 32.14,
    avg_round_2: 176,
    app_flat_2: 0,
    char_3: 'Miyabi',
    app_rate_3: 30.71,
    avg_round_3: 94,
    app_flat_3: 0,
    char_4: 'Seth',
    app_rate_4: 16.07,
    avg_round_4: 188,
    app_flat_4: 0,
    char_5: 'Burnice',
    app_rate_5: 14.64,
    avg_round_5: 155,
    app_flat_5: 0,
    char_6: 'Yanagi',
    app_rate_6: 13.93,
    avg_round_6: 142,
    app_flat_6: 0,
    char_7: 'Caesar',
    app_rate_7: 10.0,
    avg_round_7: 164,
    app_flat_7: 0,
    char_8: 'Qingyi',
    app_rate_8: 9.29,
    avg_round_8: 185,
    app_flat_8: 0,
    char_9: 'jane-doe',
    app_rate_9: 8.93,
    avg_round_9: 210,
    app_flat_9: 0,
    char_10: 'Soukaku',
    app_rate_10: 7.5,
    avg_round_10: 92,
    app_flat_10: 0,
    char_11: 'anby-demara',
    app_rate_11: 7.14,
    avg_round_11: 213,
    app_flat_11: 0,
    char_12: 'Lucy',
    app_rate_12: 3.57,
    avg_round_12: 172,
    app_flat_12: 0,
    char_13: 'Anton',
    app_rate_13: 3.57,
    avg_round_13: 166,
    app_flat_13: 0,
    char_14: 'nicole-demara',
    app_rate_14: 2.5,
    avg_round_14: 170,
    app_flat_14: 0,
    char_15: 'Koleda',
    app_rate_15: 1.79,
    avg_round_15: 202,
    app_flat_15: 0,
    char_16: 'Piper',
    app_rate_16: 1.07,
    avg_round_16: 210,
    app_flat_16: 0,
    char_17: 'zhu-yuan',
    app_rate_17: 1.07,
    avg_round_17: 179,
    app_flat_17: 0,
    char_18: 'Lighter',
    app_rate_18: 0.71,
    avg_round_18: 244,
    app_flat_18: 0,
    char_19: 'Lycaon',
    app_rate_19: 0.71,
    avg_round_19: 218,
    app_flat_19: 0,
    char_20: 'soldier-11',
    app_rate_20: 0.71,
    avg_round_20: 268,
    app_flat_20: 0,
    char_21: 'Ben',
    app_rate_21: 0.36,
    avg_round_21: 143,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Corin',
    round: 149,
    char_1: 'Qingyi',
    app_rate_1: 53.85,
    avg_round_1: 166,
    app_flat_1: 0,
    char_2: 'Rina',
    app_rate_2: 34.62,
    avg_round_2: 167,
    app_flat_2: 0,
    char_3: 'Caesar',
    app_rate_3: 30.77,
    avg_round_3: 168,
    app_flat_3: 0,
    char_4: 'Lucy',
    app_rate_4: 19.23,
    avg_round_4: 154,
    app_flat_4: 0,
    char_5: 'Piper',
    app_rate_5: 15.38,
    avg_round_5: 138,
    app_flat_5: 0,
    char_6: 'nicole-demara',
    app_rate_6: 15.38,
    avg_round_6: 168,
    app_flat_6: 0,
    char_7: 'Ellen',
    app_rate_7: 7.69,
    avg_round_7: 87,
    app_flat_7: 0,
    char_8: 'Soukaku',
    app_rate_8: 7.69,
    avg_round_8: 87,
    app_flat_8: 0,
    char_9: 'Ben',
    app_rate_9: 3.85,
    avg_round_9: 232,
    app_flat_9: 0,
    char_10: 'Lycaon',
    app_rate_10: 3.85,
    avg_round_10: 124,
    app_flat_10: 0,
    char_11: 'Miyabi',
    app_rate_11: 3.85,
    avg_round_11: 146,
    app_flat_11: 0,
    char_12: 'Lighter',
    app_rate_12: 3.85,
    avg_round_12: 146,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Nekomata',
    round: 154,
    char_1: 'nicole-demara',
    app_rate_1: 64.86,
    avg_round_1: 134,
    app_flat_1: 0,
    char_2: 'Caesar',
    app_rate_2: 37.84,
    avg_round_2: 138,
    app_flat_2: 0,
    char_3: 'Qingyi',
    app_rate_3: 32.43,
    avg_round_3: 159,
    app_flat_3: 0,
    char_4: 'Lucy',
    app_rate_4: 16.22,
    avg_round_4: 170,
    app_flat_4: 0,
    char_5: 'Piper',
    app_rate_5: 16.22,
    avg_round_5: 170,
    app_flat_5: 0,
    char_6: 'anby-demara',
    app_rate_6: 8.11,
    avg_round_6: 116,
    app_flat_6: 0,
    char_7: 'jane-doe',
    app_rate_7: 8.11,
    avg_round_7: 196,
    app_flat_7: 0,
    char_8: 'Burnice',
    app_rate_8: 8.11,
    avg_round_8: 188,
    app_flat_8: 0,
    char_9: 'Lycaon',
    app_rate_9: 2.7,
    avg_round_9: 172,
    app_flat_9: 0,
    char_10: 'Seth',
    app_rate_10: 2.7,
    avg_round_10: 225,
    app_flat_10: 0,
    char_11: 'billy-kid',
    app_rate_11: 2.7,
    avg_round_11: 325,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Harumasa',
    round: 156,
    char_1: 'Qingyi',
    app_rate_1: 58.68,
    avg_round_1: 156,
    app_flat_1: 0,
    char_2: 'Rina',
    app_rate_2: 43.11,
    avg_round_2: 162,
    app_flat_2: 0,
    char_3: 'nicole-demara',
    app_rate_3: 21.7,
    avg_round_3: 165,
    app_flat_3: 0,
    char_4: 'anby-demara',
    app_rate_4: 19.34,
    avg_round_4: 195,
    app_flat_4: 0,
    char_5: 'Caesar',
    app_rate_5: 14.62,
    avg_round_5: 157,
    app_flat_5: 0,
    char_6: 'Yanagi',
    app_rate_6: 9.53,
    avg_round_6: 153,
    app_flat_6: 0,
    char_7: 'grace-howard',
    app_rate_7: 8.49,
    avg_round_7: 176,
    app_flat_7: 0,
    char_8: 'Miyabi',
    app_rate_8: 8.11,
    avg_round_8: 107,
    app_flat_8: 0,
    char_9: 'Lucy',
    app_rate_9: 5.85,
    avg_round_9: 166,
    app_flat_9: 0,
    char_10: 'Soukaku',
    app_rate_10: 3.96,
    avg_round_10: 142,
    app_flat_10: 0,
    char_11: 'Burnice',
    app_rate_11: 3.87,
    avg_round_11: 155,
    app_flat_11: 0,
    char_12: 'Seth',
    app_rate_12: 3.77,
    avg_round_12: 210,
    app_flat_12: 0,
    char_13: 'Lighter',
    app_rate_13: 2.64,
    avg_round_13: 175,
    app_flat_13: 0,
    char_14: 'Lycaon',
    app_rate_14: 1.89,
    avg_round_14: 181,
    app_flat_14: 0,
    char_15: 'Koleda',
    app_rate_15: 1.04,
    avg_round_15: 199,
    app_flat_15: 0,
    char_16: 'jane-doe',
    app_rate_16: 0.47,
    avg_round_16: 225,
    app_flat_16: 0,
    char_17: 'Ellen',
    app_rate_17: 0.28,
    avg_round_17: 260,
    app_flat_17: 0,
    char_18: 'Piper',
    app_rate_18: 0.28,
    avg_round_18: 161,
    app_flat_18: 0,
    char_19: 'zhu-yuan',
    app_rate_19: 0.19,
    avg_round_19: 244,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'anby-demara',
    round: 162,
    char_1: 'nicole-demara',
    app_rate_1: 55.13,
    avg_round_1: 158,
    app_flat_1: 0,
    char_2: 'Harumasa',
    app_rate_2: 43.8,
    avg_round_2: 195,
    app_flat_2: 0,
    char_3: 'zhu-yuan',
    app_rate_3: 30.77,
    avg_round_3: 145,
    app_flat_3: 0,
    char_4: 'Rina',
    app_rate_4: 23.29,
    avg_round_4: 180,
    app_flat_4: 0,
    char_5: 'Yanagi',
    app_rate_5: 11.32,
    avg_round_5: 149,
    app_flat_5: 0,
    char_6: 'Miyabi',
    app_rate_6: 8.97,
    avg_round_6: 95,
    app_flat_6: 0,
    char_7: 'Soukaku',
    app_rate_7: 6.62,
    avg_round_7: 138,
    app_flat_7: 0,
    char_8: 'grace-howard',
    app_rate_8: 4.27,
    avg_round_8: 213,
    app_flat_8: 0,
    char_9: 'Caesar',
    app_rate_9: 4.27,
    avg_round_9: 182,
    app_flat_9: 0,
    char_10: 'Ellen',
    app_rate_10: 4.06,
    avg_round_10: 162,
    app_flat_10: 0,
    char_11: 'Seth',
    app_rate_11: 2.99,
    avg_round_11: 184,
    app_flat_11: 0,
    char_12: 'Lucy',
    app_rate_12: 2.35,
    avg_round_12: 167,
    app_flat_12: 0,
    char_13: 'Anton',
    app_rate_13: 2.35,
    avg_round_13: 162,
    app_flat_13: 0,
    char_14: 'Burnice',
    app_rate_14: 1.71,
    avg_round_14: 153,
    app_flat_14: 0,
    char_15: 'jane-doe',
    app_rate_15: 1.5,
    avg_round_15: 233,
    app_flat_15: 0,
    char_16: 'billy-kid',
    app_rate_16: 0.64,
    avg_round_16: 146,
    app_flat_16: 0,
    char_17: 'Nekomata',
    app_rate_17: 0.64,
    avg_round_17: 116,
    app_flat_17: 0,
    char_18: 'Qingyi',
    app_rate_18: 0.64,
    avg_round_18: 202,
    app_flat_18: 0,
    char_19: 'soldier-11',
    app_rate_19: 0.43,
    avg_round_19: 236,
    app_flat_19: 0,
    char_20: 'Koleda',
    app_rate_20: 0.21,
    avg_round_20: 218,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'soldier-11',
    round: 163,
    char_1: 'Lucy',
    app_rate_1: 65.52,
    avg_round_1: 167,
    app_flat_1: 0,
    char_2: 'Lighter',
    app_rate_2: 56.32,
    avg_round_2: 157,
    app_flat_2: 0,
    char_3: 'Caesar',
    app_rate_3: 28.74,
    avg_round_3: 173,
    app_flat_3: 0,
    char_4: 'Burnice',
    app_rate_4: 16.09,
    avg_round_4: 179,
    app_flat_4: 0,
    char_5: 'Qingyi',
    app_rate_5: 9.2,
    avg_round_5: 182,
    app_flat_5: 0,
    char_6: 'Koleda',
    app_rate_6: 8.05,
    avg_round_6: 183,
    app_flat_6: 0,
    char_7: 'Ben',
    app_rate_7: 6.9,
    avg_round_7: 180,
    app_flat_7: 0,
    char_8: 'Miyabi',
    app_rate_8: 4.6,
    avg_round_8: 119,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 3.45,
    avg_round_9: 100,
    app_flat_9: 0,
    char_10: 'Yanagi',
    app_rate_10: 3.45,
    avg_round_10: 174,
    app_flat_10: 0,
    char_11: 'anby-demara',
    app_rate_11: 2.3,
    avg_round_11: 236,
    app_flat_11: 0,
    char_12: 'grace-howard',
    app_rate_12: 2.3,
    avg_round_12: 268,
    app_flat_12: 0,
    char_13: 'Ellen',
    app_rate_13: 1.15,
    avg_round_13: 600,
    app_flat_13: 0,
    char_14: 'Soukaku',
    app_rate_14: 1.15,
    avg_round_14: 600,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Anton',
    round: 165,
    char_1: 'Rina',
    app_rate_1: 52.17,
    avg_round_1: 176,
    app_flat_1: 0,
    char_2: 'Qingyi',
    app_rate_2: 50.0,
    avg_round_2: 161,
    app_flat_2: 0,
    char_3: 'anby-demara',
    app_rate_3: 23.91,
    avg_round_3: 162,
    app_flat_3: 0,
    char_4: 'grace-howard',
    app_rate_4: 21.74,
    avg_round_4: 166,
    app_flat_4: 0,
    char_5: 'nicole-demara',
    app_rate_5: 17.39,
    avg_round_5: 122,
    app_flat_5: 0,
    char_6: 'Seth',
    app_rate_6: 8.7,
    avg_round_6: 186,
    app_flat_6: 0,
    char_7: 'Yanagi',
    app_rate_7: 8.7,
    avg_round_7: 168,
    app_flat_7: 0,
    char_8: 'Koleda',
    app_rate_8: 6.52,
    avg_round_8: 195,
    app_flat_8: 0,
    char_9: 'Ben',
    app_rate_9: 4.35,
    avg_round_9: 187,
    app_flat_9: 0,
    char_10: 'Caesar',
    app_rate_10: 4.35,
    avg_round_10: 156,
    app_flat_10: 0,
    char_11: 'Burnice',
    app_rate_11: 2.17,
    avg_round_11: 107,
    app_flat_11: 0,
    char_12: 'jane-doe',
    app_rate_12: 2.17,
    avg_round_12: 600,
    app_flat_12: 0,
    char_13: 'Lucy',
    app_rate_13: 2.17,
    avg_round_13: 221,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Ben',
    round: 166,
    char_1: 'Lucy',
    app_rate_1: 47.62,
    avg_round_1: 150,
    app_flat_1: 0,
    char_2: 'Lighter',
    app_rate_2: 33.33,
    avg_round_2: 145,
    app_flat_2: 0,
    char_3: 'soldier-11',
    app_rate_3: 28.57,
    avg_round_3: 180,
    app_flat_3: 0,
    char_4: 'Burnice',
    app_rate_4: 9.52,
    avg_round_4: 118,
    app_flat_4: 0,
    char_5: 'Anton',
    app_rate_5: 9.52,
    avg_round_5: 187,
    app_flat_5: 0,
    char_6: 'Caesar',
    app_rate_6: 9.52,
    avg_round_6: 144,
    app_flat_6: 0,
    char_7: 'jane-doe',
    app_rate_7: 9.52,
    avg_round_7: 284,
    app_flat_7: 0,
    char_8: 'Miyabi',
    app_rate_8: 9.52,
    avg_round_8: 86,
    app_flat_8: 0,
    char_9: 'Qingyi',
    app_rate_9: 9.52,
    avg_round_9: 238,
    app_flat_9: 0,
    char_10: 'Corin',
    app_rate_10: 4.76,
    avg_round_10: 232,
    app_flat_10: 0,
    char_11: 'grace-howard',
    app_rate_11: 4.76,
    avg_round_11: 143,
    app_flat_11: 0,
    char_12: 'Rina',
    app_rate_12: 4.76,
    avg_round_12: 231,
    app_flat_12: 0,
    char_13: 'zhu-yuan',
    app_rate_13: 4.76,
    avg_round_13: 247,
    app_flat_13: 0,
    char_14: 'Piper',
    app_rate_14: 4.76,
    avg_round_14: 149,
    app_flat_14: 0,
    char_15: 'Koleda',
    app_rate_15: 4.76,
    avg_round_15: 284,
    app_flat_15: 0,
    char_16: 'Yanagi',
    app_rate_16: 4.76,
    avg_round_16: 107,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
