import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';

import './exilium-weapon-db.scss';
import { IGFLWeapon } from '../../common/model/graphql-types';

interface IProps {
  weapon: IGFLWeapon;
}

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

export const GFLWeaponBox: React.FC<IProps> = ({ weapon }) => {
  const image = weapon.image
    ? getImage(weapon.image.localFile.childImageSharp)
    : null;
  return (
    <>
      <div className="gfl-weapon-box box">
        <div className="gfl-weapon-header">
          <div className={`gfl-weapon-image rarity-${weapon.rarity}`}>
            {image && (
              <GatsbyImage
                className="disable-transition"
                image={image}
                alt={`${weapon.name}`}
              />
            )}
          </div>
          <div className="gfl-weapon-data">
            <h4>
              {weapon.name}{' '}
              {weapon.cnOnly && <span className="tag cn">CN</span>}
            </h4>
            <div className="gfl-weapon-info">
              <p>
                Rarity:{' '}
                <strong className={`rarity-hsr rarity-${weapon.rarity}`}>
                  {weapon.rarity}
                </strong>
              </p>
              <p>
                Type: <strong>{weapon.weapon}</strong>
              </p>
              <p>
                Source: <strong>{weapon.source ? weapon.source : '-'}</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="gfl-weapon-content">
          <p className="section-name">Trait:</p>
          <div className={`skill-with-coloring first`}>
            {renderRichText(weapon.trait, options)}
          </div>
          <p className="section-name">Effect:</p>
          <div className={`skill-with-coloring`}>
            {renderRichText(weapon.description, options)}
          </div>
        </div>
        <div className="gfl-weapon-stats">
          <div className="gfl-weapon-stat-single">
            ATK:&nbsp;<strong>{weapon.stats.atk}</strong>
          </div>
          {weapon.stats.stat != '' && (
            <div className="gfl-weapon-stat-single">
              {weapon.stats.stat === 'Attack %' && 'ATK'}
              {weapon.stats.stat === 'Confectance %' && 'Confectance'}
              {weapon.stats.stat === 'Critical Rate %' && 'CRIT Rate'}
              {weapon.stats.stat === 'Critical Damage %' && 'CRIT DMG'}:&nbsp;
              <strong>{weapon.stats.stat_value}%</strong>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
