/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useReducer, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import './shiyu-defense.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  FloatingLabel,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Tab,
  Tabs
} from 'react-bootstrap';
import { Box } from '../../modules/common/components/box';
import {
  faArrowDown,
  faArrowUp,
  faCircleQuestion,
  faFilter
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ZZZCharacter } from '../../modules/zzz/common/components/zzz-character';
import Switch from 'react-switch';
import { ZZZDeadlyChars as listCharacters } from '../../modules/zzz/deadly/characters';
import { ZZZAssault_2 } from '../../modules/zzz/deadly/boss-2';
import { ZZZAssault_1 } from '../../modules/zzz/deadly/boss-1';
import { ZZZAssault_3 } from '../../modules/zzz/deadly/boss-3';
import { ZZZAssault_0 } from '../../modules/zzz/deadly/boss-all';
import { ZZZDeadlyCharData } from '../../modules/zzz/deadly/stats';

// enum to replace strings. Not necessary, but good practice.
enum CharacterState {
  include,
  exclude
}

enum FilterMode {
  includeAny,
  includeAll
}

// interface for grouping together character use state information
interface ICharacterUseStateInfo {
  name: string;
  state: CharacterState;
  setFunction: (CharacterState: CharacterState) => void;
}

const ZZZShiyu: React.FC = () => {
  const [characterListCombined, setCharacterListCombined] = useState(false);
  const [characterList, setCharacterList] = useState(ZZZDeadlyCharData);
  const [currentPhase, setCurrentPhase] = useState('1.4.2');
  const [currentTotalUsers, setCurrentTotalUsers] = useState('4761');
  const [currentSelfUsers, setCurrentSelfUsers] = useState('1247');
  const [currentRandomUsers, setCurrentRandomUsers] = useState('3514');
  const [updateDate, setUpdateDate] = useState('18/01/2024');

  const [enableCycle, setEnableCycle] = useState(false);
  const [currentTeamStageConfig, setCurrentTeamStageConfig] =
    useState('stage_split');
  const [currentTeamStage, setCurrentTeamStage] = useState(ZZZAssault_1);
  const [currentTeamStage2, setCurrentTeamStage2] = useState(ZZZAssault_2);
  const [currentTeamStage3, setCurrentTeamStage3] = useState(ZZZAssault_3);
  const [currentTeamStageFiltered, setCurrentTeamStageFiltered] =
    useState(ZZZAssault_1);
  const [currentTeamStage2Filtered, setCurrentTeamStage2Filtered] =
    useState(ZZZAssault_2);
  const [currentTeamStage3Filtered, setCurrentTeamStage3Filtered] =
    useState(ZZZAssault_3);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  // filter mode
  const [currentFilterMode, setFilterMode] = useState(FilterMode.includeAny);

  // create all supported character icon states
  const listCharacterUseStates: Array<ICharacterUseStateInfo> = [];
  // just the states in a list
  const listCharacterStates: Array<CharacterState> = [];
  listCharacters.map((entry) => {
    const [filterCharacterMode, setFilterCharacterMode] = useState(
      CharacterState.include
    );
    listCharacterUseStates.push({
      name: entry['value'],
      state: filterCharacterMode,
      setFunction: setFilterCharacterMode
    });
    listCharacterStates.push(filterCharacterMode);
  });

  const [open, setOpen] = useState(true);
  const [currentShow, setCurrentShow] = useState(10);

  const handleShowMore = () => {
    setCurrentShow(50);
  };

  const handleShowLess = () => {
    setCurrentShow(10);
  };

  useEffect(() => {
    if (currentTeamStageConfig === 'stage_split') {
      setCurrentTeamStage(ZZZAssault_1);
      setCurrentTeamStage2(ZZZAssault_2);
      setCurrentTeamStage3(ZZZAssault_3);
      setCurrentTeamStageFiltered(ZZZAssault_1);
      setCurrentTeamStage2Filtered(ZZZAssault_2);
      setCurrentTeamStage3Filtered(ZZZAssault_3);
    } else if (currentTeamStageConfig === 'all') {
      setCurrentTeamStage(ZZZAssault_0);
      setCurrentTeamStage2(ZZZAssault_0);
      setCurrentTeamStage3(ZZZAssault_0);
      setCurrentTeamStageFiltered(ZZZAssault_0);
      setCurrentTeamStage2Filtered(ZZZAssault_0);
      setCurrentTeamStage3Filtered(ZZZAssault_0);
    }

    setEnableCycle(false);
    // set all filters on all characters to 'include'
    const currentDefaultCharState: CharacterState = CharacterState.include;
    listCharacterUseStates.map((entry) => {
      entry.setFunction(currentDefaultCharState);
    });
  }, [currentTeamStageConfig]);

  useEffect(() => {
    if (enableCycle === true) {
      currentTeamStageFiltered.sort((a, b) =>
        b.avg_round > a.avg_round ? 1 : -1
      );
      currentTeamStage2Filtered.sort((a, b) =>
        b.avg_round > a.avg_round ? 1 : -1
      );
      currentTeamStage3Filtered.sort((a, b) =>
        b.avg_round > a.avg_round ? 1 : -1
      );
    } else {
      currentTeamStageFiltered.sort((a, b) =>
        b.app_rate > a.app_rate ? 1 : -1
      );
      currentTeamStage2Filtered.sort((a, b) =>
        b.app_rate > a.app_rate ? 1 : -1
      );
      currentTeamStage3Filtered.sort((a, b) =>
        b.app_rate > a.app_rate ? 1 : -1
      );
    }
    forceUpdate();
  }, [enableCycle]);

  useEffect(() => {
    const filtered = currentTeamStage;
    const filtered2 = currentTeamStage2;
    const filtered3 = currentTeamStage3;
    const listFiltered = [filtered, filtered2, filtered3];

    // filter function. Filters both stages for duplicate code reduction
    // the lists within listFiltered will be updated. Function has void return type.
    // note: functionFilter should take and return an object of the same type as the elements of listFiltered
    const functionFilterAll = (listFiltered, functionFilter) => {
      for (let i = 0; i < listFiltered.length; i++) {
        listFiltered[i] = functionFilter(listFiltered[i]);
      }
    };

    // filter mode based on toggle
    // block to restrict dictFilter scope. Unnecessary.
    {
      const dictFilter: { [name: string]: ICharacterUseStateInfo } = {};
      let dictLength = 0;
      switch (currentFilterMode) {
        case FilterMode.includeAny:
          // teams can be made of of any combination of selected units. Filter based on the existence of unselected units.
          // create filter dict of unselected units for faster access
          listCharacterUseStates.map((entry) => {
            if (entry.state === CharacterState.exclude) {
              dictFilter[entry.name] = entry;
            }
          });

          functionFilterAll(listFiltered, (filtered) => {
            return filtered.filter(
              (emp) =>
                // logical not in front of what is expected to be undefined (falsey value)
                !dictFilter[emp.char_one.toLowerCase()] &&
                !dictFilter[emp.char_two.toLowerCase()] &&
                !dictFilter[emp.char_three.toLowerCase()]
            );
          });
          break;
        case FilterMode.includeAll:
          // teams must have all selected units. Filter based on the existence of selected units.
          // create filter dict of selected units for faster access. Track filter length.
          listCharacterUseStates.map((entry) => {
            if (entry.state === CharacterState.include) {
              dictFilter[entry.name] = entry;
              dictLength++;
            }
          });

          // When the selection is empty, set lists to empty
          if (dictLength == 0) {
            listFiltered[0] = [];
            listFiltered[1] = [];
            listFiltered[2] = [];
          } else {
            functionFilterAll(listFiltered, (filtered) => {
              return filtered.filter((emp) => {
                // filter based on how many remaining wild cards there are
                let selectedCount = 0;
                if (dictFilter[emp.char_one.toLowerCase()]) {
                  selectedCount++;
                }
                if (dictFilter[emp.char_two.toLowerCase()]) {
                  selectedCount++;
                }
                if (dictFilter[emp.char_three.toLowerCase()]) {
                  selectedCount++;
                }

                // if the two numbers match, then all selected chars are in the team.
                // selectedCount should never be greater than dictLength, but it probably should behave this way if it did.
                if (selectedCount >= dictLength) {
                  return true;
                }
                return false;
              });
            });
          }

          break;

        default:
          // should never be reached
          break;
      }
    }

    setEnableCycle(false);
    setCurrentTeamStageFiltered(listFiltered[0]);
    setCurrentTeamStage2Filtered(listFiltered[1]);
    setCurrentTeamStage3Filtered(listFiltered[2]);
  }, [...listCharacterStates, currentFilterMode]);

  const resetStuff: () => void = () => {
    listCharacterUseStates.map((entry) =>
      entry.setFunction(CharacterState.include)
    );
  };

  // deselect everything
  const unsetStuff: () => void = () => {
    listCharacterUseStates.map((entry) =>
      entry.setFunction(CharacterState.exclude)
    );
  };

  // toggle filter mode.
  // any -> all deselects everything
  // all -> any does not change selection
  const toggleFilterMode: () => void = () => {
    switch (currentFilterMode) {
      case FilterMode.includeAny:
        listCharacterUseStates.map((entry) =>
          entry.setFunction(CharacterState.exclude)
        );
        setFilterMode(FilterMode.includeAll);
        break;
      case FilterMode.includeAll:
        setFilterMode(FilterMode.includeAny);
        break;
      default:
        // unreachable in theory
        break;
    }
  };

  const items = currentTeamStageFiltered
    .slice(0, currentShow)
    .map((item, index) => (
      <div className="team-row" key={index}>
        <div className="column info">
          <p className="rank">Rank {item.rank}</p>
          <p className="usage">App. rate: {item.app_rate}%</p>
          <p className="rounds">
            Avg. Score: {item.avg_round != 99.99 ? item.avg_round : '-'}
          </p>
        </div>
        <div className="column characters">
          <ZZZCharacter
            slug={item.char_one.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
          <ZZZCharacter
            slug={item.char_two.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
          <ZZZCharacter
            slug={item.char_three.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
        </div>
      </div>
    ));

  const items2 = currentTeamStage2Filtered
    .slice(0, currentShow)
    .map((item, index) => (
      <div className="team-row" key={index}>
        <div className="column info">
          <p className="rank">Rank {item.rank}</p>
          <p className="usage">App. rate: {item.app_rate}%</p>
          <p className="rounds">
            Avg. Score: {item.avg_round != 99.99 ? item.avg_round : '-'}
          </p>
        </div>
        <div className="column characters">
          <ZZZCharacter
            slug={item.char_one.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
          <ZZZCharacter
            slug={item.char_two.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
          <ZZZCharacter
            slug={item.char_three.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
        </div>
      </div>
    ));

  const items3 = currentTeamStage3Filtered
    .slice(0, currentShow)
    .map((item, index) => (
      <div className="team-row" key={index}>
        <div className="column info">
          <p className="rank">Rank {item.rank}</p>
          <p className="usage">App. rate: {item.app_rate}%</p>
          <p className="rounds">
            Avg. Score: {item.avg_round != 99.99 ? item.avg_round : '-'}
          </p>
        </div>
        <div className="column characters">
          <ZZZCharacter
            slug={item.char_one.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
          <ZZZCharacter
            slug={item.char_two.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
          <ZZZCharacter
            slug={item.char_three.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
        </div>
      </div>
    ));

  useEffect(() => {
    setCurrentTeamStage(ZZZAssault_1);
    setCurrentTeamStage2(ZZZAssault_2);
    setCurrentTeamStage3(ZZZAssault_3);
    setCurrentTeamStageFiltered(ZZZAssault_1);
    setCurrentTeamStage2Filtered(ZZZAssault_2);
    setCurrentTeamStage3Filtered(ZZZAssault_3);
  }, []);

  // creates include/exclude character icons for filtering
  const createFilterCharacterIcons: React.FC = () => {
    // only calculate selected count if in includeAll filter mode
    let currentSelectedCount = 0;
    if (currentFilterMode === FilterMode.includeAll) {
      listCharacterUseStates.map((entry) => {
        if (entry.state === CharacterState.include) {
          currentSelectedCount++;
        }
      });
    }

    return (
      <div className={`employees-container`}>
        {listCharacterUseStates.map((entry, index) => {
          const isSelected = entry.state === CharacterState.include;
          return (
            <span key={index}>
              {isSelected ||
              !(
                currentFilterMode === FilterMode.includeAll &&
                currentSelectedCount >= 4
              ) ? (
                <Card
                  onClick={() => {
                    switch (entry.state) {
                      case CharacterState.include:
                        entry.setFunction(CharacterState.exclude);
                        break;
                      case CharacterState.exclude:
                        entry.setFunction(CharacterState.include);
                        break;
                      default:
                        break;
                    }
                  }}
                  className={`avatar-card ${isSelected ? 'selected' : ''}`}
                >
                  <ZZZCharacter
                    slug={entry.name}
                    mode="icon-no-link"
                    showIcon
                  />
                </Card>
              ) : (
                <Card className="avatar-card">
                  <ZZZCharacter
                    slug={entry.name}
                    mode="icon-no-link"
                    showIcon
                  />
                </Card>
              )}
            </span>
          );
        })}
      </div>
    );
  };

  const popoverStrict = (
    <Popover id="popover-strict">
      <Popover.Header as="h3">Strict Mode</Popover.Header>
      <Popover.Body>
        <strong>Strict Mode</strong> will show teams that contain only the
        characters you have selected (limit up to 4 characters).
      </Popover.Body>
    </Popover>
  );

  return (
    <DashboardLayout className={'generic-page shiyu-defense'} game="zzz">
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>Deadly Assault Analytics</li>
      </ul>
      <div className="page-header ">
        <div className="page-icon">
          <StaticImage
            src="../../images/zzz/categories/category_deadly.jpg"
            alt="Deadly Assault Analytics"
          />
        </div>
        <div className="page-details">
          <h1>Zenless Zone Zero Deadly Assault Analytics</h1>
          <h2>
            Information and analytics about Deadly Assault phases in Zenless
            Zone Zero!
          </h2>
          <p>
            Last updated: <strong>{updateDate}</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="page-content">
        <SectionHeader title="Deadly Assault Analytics" />
        <p>
          The Deadly Assault Analytics is the fruit of collaboration between
          Prydwen and{' '}
          <strong>
            Spiral Stats who create beautiful infographics with character and
            team usage for Genshin Impact and Honkai: Star Rail.
          </strong>{' '}
          You can check their Honkai: Star Rail works by going here:
        </p>
        <Button
          variant="primary"
          href="https://www.reddit.com/user/LvlUrArti/comments/155o3wi/compilation_of_my_honkai_star_rail_infographics/"
          target="_blank"
          rel="noreferrer"
          className="custom-button"
        >
          Spiral Stats
        </Button>
        <p>
          If you have any suggestions about the contents of this page, contact
          the Spiral Stats team on Discord (username: lvlurarti) or Reddit
          (username: LvlUrArti). Also, if you want to see the raw data we have
          gathered, you can find them on our GitHub account,{' '}
          <a
            href="https://github.com/piedorr/MocStats"
            target="_blank"
            rel="noreferrer"
          >
            here.
          </a>{' '}
        </p>
        <SectionHeader title="Help us!" />
        <div className="top-partner-intro zzz">
          <Box>
            <Row>
              <Col>
                <div className="inner">
                  <h2>Help us make the Analytics better!</h2>
                  <p>
                    You just need to share your UID to help us. It's so simple!
                  </p>
                </div>
              </Col>
            </Row>
          </Box>
        </div>
        <p>
          If you want to share your Deadly Assault clear information with us,
          you simply need to fill the form below and{' '}
          <strong>
            provide us with your UID and make your Hoyo profile public - we will
            automatically parse the SD clear information from your profile after
            that
          </strong>
          ! The more people join the project, the better and more accurate data
          we can provide!
        </p>
        <Button
          variant="primary"
          href="https://bit.ly/shiyustats"
          target="_blank"
          rel="noreferrer"
          className="custom-button"
        >
          Deadly Assault Stats Form
        </Button>
        <SectionHeader title={`Best Scores (${currentPhase} phase)`} />
        <Alert variant="primary">
          <p>
            <strong>Disclaimer:</strong> Don't use the data to compare the pull
            value between characters. For more accurate assessment, check our
            tier list and the character reviews.
          </p>
        </Alert>
        <h6>Additional information</h6>
        <ul>
          <li>
            The data has been last updated on <strong>{updateDate}</strong>.
          </li>
          <li>
            <strong>{currentTotalUsers} players</strong> shared their{' '}
            <strong>{currentPhase}</strong> Deadly Assault clear data with us (
            <strong>{currentSelfUsers}</strong> of the sample are self-reported
            players that filled our form, <strong>{currentRandomUsers}</strong>{' '}
            are from random UIDs that we scanned).
          </li>
          <li>
            The average score reflects the one shown in the game after you
            finish the stage,
          </li>
          <li>
            If a team used a <strong>M1+</strong>{' '}
            <strong className="s-rank">S-Rank</strong> character, it was
            excluded from the data below.{' '}
            <strong className="a-rank">A-Rank</strong> characters aren't
            restricted when it comes to Mindscapes, so it includes M0-M6.
          </li>
        </ul>
        <h5>All characters</h5>
        <Tabs
          defaultActiveKey="all"
          transition={false}
          id="deadly-assault-score"
          className="nav-tabs"
        >
          <Tab eventKey="all" title={<>Combined Data</>} className="with-bg">
            {/* <div className="configuration">
              <div className="custom-switch">
                <Switch
                  checked={characterListCombined}
                  onChange={(value) => setCharacterListCombined(value)}
                  onColor="#009EEC"
                  offColor="#484950"
                  className="switch"
                />
                Show M1+
              </div>
            </div> */}
            <div className={`char-view simple`}>
              <>
                {characterList
                  .sort((a, b) => (a.current_score < b.current_score ? 1 : -1))
                  .map((character, index) => {
                    return (
                      <div key={index} className="char-box">
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={character.char}
                            mode="icon"
                            enablePopover
                            showIcon
                          />
                        </Card>
                        <div className="column percentage">
                          <p>{character.current_score}</p>
                          <p className="difference">
                            {character.current_score - character.prev_score ===
                            0 ? (
                              <>
                                {character.char === 'test' ? <>New!</> : <>-</>}
                              </>
                            ) : (
                              <>
                                {character.current_score -
                                  character.prev_score >
                                0 ? (
                                  <span className="green">
                                    <FontAwesomeIcon
                                      icon={faArrowUp}
                                      width="18"
                                      className="arrow-green"
                                    />
                                    {character.current_score -
                                      character.prev_score}
                                  </span>
                                ) : (
                                  <span className="red">
                                    <FontAwesomeIcon
                                      icon={faArrowDown}
                                      width="18"
                                      className="arrow-red"
                                    />
                                    {character.current_score -
                                      character.prev_score}
                                  </span>
                                )}{' '}
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </>
            </div>
          </Tab>
          <Tab
            eventKey="boss-1"
            title={<>Boss 1 - Sacrifice Bringer</>}
            className="with-bg"
          >
            <div className={`char-view simple`}>
              <>
                {characterList
                  .sort((a, b) => (a.boss_1_score < b.boss_1_score ? 1 : -1))
                  .map((character, index) => {
                    return (
                      <div key={index} className="char-box">
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={character.char}
                            mode="icon"
                            enablePopover
                            showIcon
                          />
                        </Card>
                        <div className="column percentage">
                          <p>{character.boss_1_score}</p>
                        </div>
                      </div>
                    );
                  })}
              </>
            </div>
          </Tab>
          <Tab
            eventKey="boss-2"
            title={<>Boss 2 - Typhon Destroyer</>}
            className="with-bg"
          >
            <div className={`char-view simple`}>
              <>
                {characterList
                  .sort((a, b) => (a.boss_2_score < b.boss_2_score ? 1 : -1))
                  .map((character, index) => {
                    return (
                      <div key={index} className="char-box">
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={character.char}
                            mode="icon"
                            enablePopover
                            showIcon
                          />
                        </Card>
                        <div className="column percentage">
                          <p>{character.boss_2_score}</p>
                        </div>
                      </div>
                    );
                  })}
              </>
            </div>
          </Tab>
          <Tab
            eventKey="boss-3"
            title={<>Boss 3 - Dead End Butcher</>}
            className="with-bg"
          >
            <div className={`char-view simple`}>
              <>
                {characterList
                  .sort((a, b) => (a.boss_3_score < b.boss_3_score ? 1 : -1))
                  .map((character, index) => {
                    return (
                      <div key={index} className="char-box">
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={character.char}
                            mode="icon"
                            enablePopover
                            showIcon
                          />
                        </Card>
                        <div className="column percentage">
                          <p>{character.boss_3_score}</p>
                        </div>
                      </div>
                    );
                  })}
              </>
            </div>
          </Tab>
        </Tabs>
        <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
        <SectionHeader title={`Appearance rates (${currentPhase} phase)`} />
        <Alert variant="primary">
          <p>
            <strong>Disclaimer:</strong> Don't use the data to compare the pull
            value between characters. For more accurate assessment, check our
            tier list and the character reviews.
          </p>
        </Alert>
        <h6>Additional information</h6>
        <ul>
          <li>
            The data has been last updated on <strong>{updateDate}.</strong>
          </li>
          <li>
            <strong>{currentTotalUsers} players</strong> shared their{' '}
            <strong>{currentPhase}</strong> Deadly Assault clear data with us (
            <strong>{currentSelfUsers}</strong> of the sample are self-reported
            players that filled our form, <strong>{currentRandomUsers}</strong>{' '}
            are from random UIDs that we scanned).
          </li>
          <li>
            Characters are ranked with appearance rate, which is how often a
            character was used by all players, regardless of whether they own
            the character or not.{' '}
            <strong> Ownership rate currently cannot be obtained.</strong>
          </li>
          <li>
            If a team used a <strong>M1+</strong>{' '}
            <strong className="s-rank">S-Rank</strong> character, it was
            excluded from the data below.{' '}
            <strong className="a-rank">A-Rank</strong> characters aren't
            restricted when it comes to Mindscapes, so it includes M0-M6.
          </li>
        </ul>
        <Tabs
          defaultActiveKey="all"
          transition={false}
          id="deadly-assault-score"
          className="nav-tabs"
        >
          <Tab eventKey="all" title={<>Combined Data</>} className="with-bg">
            <div className={`char-view simple`}>
              <>
                {characterList
                  .sort((a, b) => (a.current_usage < b.current_usage ? 1 : -1))
                  .map((character, index) => {
                    return (
                      <div key={index} className="char-box">
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={character.char}
                            mode="icon"
                            enablePopover
                            showIcon
                          />
                        </Card>
                        <div className="column percentage">
                          <p>{character.current_usage}%</p>
                        </div>
                      </div>
                    );
                  })}
              </>
            </div>
          </Tab>
          <Tab
            eventKey="boss-1"
            title={<>Boss 1 - Sacrifice Bringer</>}
            className="with-bg"
          >
            <div className={`char-view simple`}>
              <>
                {characterList
                  .sort((a, b) => (a.boss_1_usage < b.boss_1_usage ? 1 : -1))
                  .map((character, index) => {
                    return (
                      <div key={index} className="char-box">
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={character.char}
                            mode="icon"
                            enablePopover
                            showIcon
                          />
                        </Card>
                        <div className="column percentage">
                          <p>{character.boss_1_usage}%</p>
                        </div>
                      </div>
                    );
                  })}
              </>
            </div>
          </Tab>
          <Tab
            eventKey="boss-2"
            title={<>Boss 2 - Corrupted Overlord</>}
            className="with-bg"
          >
            <div className={`char-view simple`}>
              <>
                {characterList
                  .sort((a, b) => (a.boss_2_usage < b.boss_2_usage ? 1 : -1))
                  .map((character, index) => {
                    return (
                      <div key={index} className="char-box">
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={character.char}
                            mode="icon"
                            enablePopover
                            showIcon
                          />
                        </Card>
                        <div className="column percentage">
                          <p>{character.boss_2_usage}%</p>
                        </div>
                      </div>
                    );
                  })}
              </>
            </div>
          </Tab>
          <Tab
            eventKey="boss-3"
            title={<>Boss 3 - Notorious Marionette</>}
            className="with-bg"
          >
            <div className={`char-view simple`}>
              <>
                {characterList
                  .sort((a, b) => (a.boss_3_usage < b.boss_3_usage ? 1 : -1))
                  .map((character, index) => {
                    return (
                      <div key={index} className="char-box">
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={character.char}
                            mode="icon"
                            enablePopover
                            showIcon
                          />
                        </Card>
                        <div className="column percentage">
                          <p>{character.boss_3_usage}%</p>
                        </div>
                      </div>
                    );
                  })}
              </>
            </div>
          </Tab>
        </Tabs>
        <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
        <SectionHeader title={`Team usage (${currentPhase} phase)`} />
        <Alert variant="primary">
          <p>
            <strong>Disclaimer:</strong> Don't use the data to compare the pull
            value between characters. For more accurate assessment, check our
            tier list and the character reviews.
          </p>
        </Alert>
        <h6>Additional information</h6>
        <ul>
          <li>
            The data has been last updated on <strong>{updateDate}.</strong>
          </li>
          <li>
            <strong>{currentTotalUsers} players</strong> shared their{' '}
            <strong>{currentPhase}</strong> Deadly Assault clear data with us (
            <strong>{currentSelfUsers}</strong> of the sample are self-reported
            players that filled our form, <strong>{currentRandomUsers}</strong>{' '}
            are from random UIDs that we scanned).
          </li>
          <li>
            If a team used a <strong>M1+</strong>{' '}
            <strong className="s-rank">S-Rank</strong> character, it was
            excluded from the data below.{' '}
            <strong className="a-rank">A-Rank</strong> characters aren't
            restricted when it comes to Mindscapes, so it includes M0-M6.
          </li>
          <li>
            <strong>
              The % number below the Rank are calculated per stage
            </strong>
            .
          </li>
        </ul>
        <div className="filters">
          <FloatingLabel controlId="customTarget" label="Stage">
            <Form.Select
              aria-label="selected-role"
              value={currentTeamStageConfig}
              onChange={(event) =>
                setCurrentTeamStageConfig(event.target.value)
              }
            >
              <option value="stage_1">{currentPhase} - Per boss</option>
              <option value="all">{currentPhase} - Combined</option>
            </Form.Select>
          </FloatingLabel>
          <Button
            onClick={() => setOpen(!open)}
            aria-controls="character-filter"
            aria-expanded={open}
          >
            <FontAwesomeIcon icon={faFilter} width="16" />
            {open ? 'Hide' : 'Show'} character filters
          </Button>
        </div>
        <Collapse in={open}>
          <div id="character-filter">
            <div className="character-filters">
              <h6>Character filter</h6>
              <p>
                Select the characters below that you own to show teams
                containing them (by default, all are selected). You can also
                enable the <strong>Strict Mode</strong> to search for a specific
                team composition.
              </p>
              <div className="filters-container">
                {createFilterCharacterIcons(null)}
              </div>
              <div className="options">
                <Button
                  onClick={resetStuff}
                  variant="primary"
                  className="reset-button"
                  disabled={
                    currentFilterMode === FilterMode.includeAll ? true : false
                  }
                >
                  Select all
                </Button>
                <Button
                  onClick={unsetStuff}
                  variant="primary"
                  className="reset-button"
                >
                  Deselect all
                </Button>
                <div className="custom-switch">
                  <Switch
                    checked={currentFilterMode === FilterMode.includeAll}
                    onChange={toggleFilterMode}
                    onColor="#009EEC"
                    offColor="#484950"
                    className="switch"
                  />
                  <span className="text">Strict mode</span>
                </div>
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="auto-start"
                  overlay={popoverStrict}
                >
                  <span>
                    <FontAwesomeIcon
                      icon={faCircleQuestion}
                      className="arrow"
                      width="18"
                    />
                  </span>
                </OverlayTrigger>
                <div className="custom-switch">
                  <Switch
                    checked={enableCycle}
                    onChange={() => setEnableCycle(!enableCycle)}
                    onColor="#009EEC"
                    offColor="#484950"
                    className="switch"
                  />
                  <span className="text">Sort by Score</span>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
        <div className="teams-details-custom deadly">
          <div
            className={`main ${
              currentTeamStageConfig === 'all' ? 'full' : 'split'
            }`}
          >
            <div className="box">
              <h5>
                {currentTeamStageConfig === 'all'
                  ? 'All Bosses'
                  : 'Boss 1 - Sacrifice Bringer'}
              </h5>
              <p className="team-number">
                There's <strong>{currentTeamStageFiltered.length}</strong>{' '}
                team(s) in the database matching the criteria.
              </p>
              {items}
              {items.length === 0 && (
                <div className="no-results">
                  <StaticImage
                    src="../../images/zzz/no_results.webp"
                    alt="No results"
                  />
                  <p>No teams found. Try selecting more characters.</p>
                </div>
              )}
            </div>
          </div>
          <div
            className={`not-main ${
              currentTeamStageConfig === 'all' ? 'hidden' : 'visible'
            }`}
          >
            <div className="box">
              <h5>Boss 2 - Typhon Destroyer</h5>
              {currentTeamStageConfig === 'all' ? (
                <>
                  <p>
                    The all stages data aren't split into stages, but instead
                    show overall team usage across all of them. Pick a specific
                    stage to see the teams used in each sub-stage.
                  </p>
                </>
              ) : (
                <>
                  <p className="team-number">
                    There's <strong>{currentTeamStage2Filtered.length}</strong>{' '}
                    team(s) in the database matching the criteria.
                  </p>
                  {items2}
                  {items2.length === 0 && (
                    <div className="no-results">
                      <StaticImage
                        src="../../images/zzz/no_results.webp"
                        alt="No results"
                      />
                      <p>No teams found. Try selecting more characters.</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div
            className={`not-main ${
              currentTeamStageConfig === 'all' ? 'hidden' : 'visible'
            }`}
          >
            <div className="box">
              <h5>Boss 3 - Dead End Butcher</h5>
              {currentTeamStageConfig === 'all' ? (
                <>
                  <p>
                    The all stages data aren't split into stages, but instead
                    show overall team usage across all of them. Pick a specific
                    stage to see the teams used in each sub-stage.
                  </p>
                </>
              ) : (
                <>
                  <p className="team-number">
                    There's <strong>{currentTeamStage3Filtered.length}</strong>{' '}
                    team(s) in the database matching the criteria.
                  </p>
                  {items3}
                  {items3.length === 0 && (
                    <div className="no-results">
                      <StaticImage
                        src="../../images/zzz/no_results.webp"
                        alt="No results"
                      />
                      <p>No teams found. Try selecting more characters.</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="show-more-section">
          {currentShow === 10 ? (
            <>
              <button className="btn btn-primary" onClick={handleShowMore}>
                Show more teams
              </button>
            </>
          ) : (
            <>
              <button className="btn btn-primary" onClick={handleShowLess}>
                Show less teams
              </button>
            </>
          )}
        </div>
        <br /> <br />
        <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      </div>
    </DashboardLayout>
  );
};

export default ZZZShiyu;

export const Head: React.FC = () => (
  <Seo
    title="Deadly Assault Analytics | Zenless Zone Zero | Prydwen Institute"
    description="Information and analytics about Deadly Assault phases in Zenless Zone Zero!"
    game="zzz"
  />
);
