import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { HSRCharacter } from '../../../modules/hsr/common/components/hsr-character';
import { Card, Table } from 'react-bootstrap';

const HSRGuidesFreecharsPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page hsr-guide'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Free characters</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_freechars.png"
            alt="Free characters"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail - all free characters</h1>
          <h2>All characters you can get for free in Honkai: Star Rail.</h2>
          <p>
            Last updated: <strong>18/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Information" />
        <p>
          As you progress through the story in Honkai: Star Rail you will obtain
          quite a lot of free 4★ characters. This guide will list all the free
          characters and also let you know when you will be able to obtain them
          - so you can easier plan your early game progression team!
        </p>
        <p>
          Also keep in mind that a lot of the 4★ characters are actually really
          strong and can be the backbone of your team for quite a long time.
        </p>
        <SectionHeader title="Free characters" />
        <Table striped bordered responsive className="reroll-table-hsr">
          <thead>
            <tr>
              <th>Character</th>
              <th>Rarity</th>
              <th>Role</th>
              <th>Source</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="trailblazer-destruction"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="gold">5★</strong>
              </td>
              <td>
                <strong className="dps">DPS</strong>
              </td>
              <td>Starter character</td>
              <td>
                <p>
                  The first free character that you will obtain during the
                  Prologue is the main character - Trailblazer. You will for
                  sure him/her in your team for a long time as the Prologue and
                  Jarilo-VI (first planet) are filled with enemies weak to the
                  Physical element. Also, the Traiblazer is 5★ rarity which
                  translates to higher stats, but also the ability to get free
                  Eidolon to make her/him even stronger!
                </p>
                <p>
                  Sadly, outside of the early game, the Physical Trailblazer
                  falls behind most of the other damage dealers in the game, and
                  you will rarely use him - so don't invest into him too much.
                </p>
                <p>
                  <strong>Worth investing?</strong>{' '}
                  <strong className="red">No!</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="march-7th"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="arrow-purple">4★</strong>
              </td>
              <td>
                <strong className="sustain">Sustain</strong>
              </td>
              <td>Tutorial</td>
              <td>
                <p>
                  Right after you obtain the Trailblazer character, you will
                  meet March 7th and Dan Heng and they will join your team!
                </p>
                <p>
                  March 7th is a support character that can provide allies with
                  Shields and freeze enemies. A bit good combo that makes March
                  7th great in a variety of content, especially Simulation.
                  She's a decent choice for the early game, but you will quickly
                  replace her when you manage to get a proper healer - while
                  March 7th shields are strong, she can only target one ally at
                  a time, which isn't enough later on to keep the team alive.
                </p>
                <p>
                  <strong>Worth investing?</strong>{' '}
                  <strong className="red">No!</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="dan-heng"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="arrow-purple">4★</strong>
              </td>
              <td>
                <strong className="dps">DPS</strong>
              </td>
              <td>Tutorial</td>
              <td>
                <p>
                  Right after you obtain the Trailblazer character, you will
                  meet March 7th and Dan Heng and they will join your team!
                </p>
                <p>
                  Dan Heng is a damage dealer who specializes in murdering elite
                  enemies and will help you a lot early in the game against
                  them. Sadly, he's struggling against multiple enemies and
                  because of that it's easier to use someone else as the main
                  carry since you always fight 2 and more enemies at the same
                  time.
                </p>
                <p>
                  <strong>Worth investing?</strong>{' '}
                  <strong className="red">No!</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="asta"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="arrow-purple">4★</strong>
              </td>
              <td>
                <strong className="amplifier">Amplifier</strong>
              </td>
              <td>Gacha (first summon)</td>
              <td>
                <p>
                  Asta is a great support that can increase Speed and ATK of her
                  allies but she's also a great Toughness breaker. You will use
                  her a lot if you lack limited Harmony characters, but once you
                  obtain some, she will barely be used as she has no niche
                  anymore.
                </p>
                <p>
                  <strong>Worth investing?</strong>{' '}
                  <strong className="red">No!</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="serval"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="arrow-purple">4★</strong>
              </td>
              <td>
                <strong className="dps">DPS</strong>
              </td>
              <td>New player reward (mail)</td>
              <td>
                <p>
                  Serval is a decent damage dealer who focuses on dealing AOE
                  damage, but she still holds her ground against a single enemy.
                  While she can be used as sole DPS, her main home nowadays is
                  beside The Herta as a battery.
                </p>
                <p>
                  <strong>Worth investing?</strong>{' '}
                  <strong className="green">Yes!</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="herta"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="arrow-purple">4★</strong>
              </td>
              <td>
                <strong className="dps">DPS</strong>
              </td>
              <td>Clear Simulated Universe World 1</td>
              <td>
                <p>
                  Herta is a AoE Sub-DPS who is one of the best damage dealers
                  you can bring to Pure Fiction, but struggles against lone
                  enemies. You can also get 5 Eidolons for her from Simulated
                  Universe.
                </p>
                <p>
                  <strong>Worth investing?</strong>{' '}
                  <strong className="green">Yes!</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="natasha"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="arrow-purple">4★</strong>
              </td>
              <td>
                <strong className="sustain">Sustain</strong>
              </td>
              <td>Clear Jarilo-VI main story (Lying in Rust)</td>
              <td>
                <p>
                  Natasha is the first real healer every player will receive and
                  she will do a decent job at keeping your team alive, but the
                  moment you obtain someone else - for example Lynx or
                  Gallagher, she will lose her value. So don't overinvest into
                  her.
                </p>
                <p>
                  <strong>Worth investing?</strong>{' '}
                  <strong className="red">No</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="qingque"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="arrow-purple">4★</strong>
              </td>
              <td>
                <strong className="dps">DPS</strong>
              </td>
              <td>Clear Forgotten Hall - Memory 3</td>
              <td>
                <p>
                  Qingque is a decent 4★ DPS that can be used once you obtain
                  some of her dupes. Especially if you like RNG characters as
                  she's one of those. Though, nowadays her multipliers aren't
                  good enough anymore and she falls behind limited DPS.
                </p>
                <p>
                  <strong>Worth investing?</strong>{' '}
                  <strong className="green">Yes!</strong> (only if you like
                  gambling)
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="trailblazer-preservation"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="gold">5★</strong>
              </td>
              <td>
                <strong className="sustain">Sustain</strong>
              </td>
              <td>
                Clear Jarilo-VI main story (Roads to the Past Have Long Been
                Closed)
              </td>
              <td>
                <p>
                  Once you finish the Jarilo-VI main story, you will unlock Fire
                  specialization for your Trailblazer which is superior to his
                  Physical one. While back in the day, the Fire Trailblazer was
                  used as a Sustain character, nowadays there's a lot of better
                  options in that role and he pivoted into a Breaker role - so a
                  damage dealer who specializes in dealing with enemies of the
                  same element.
                </p>
                <p>
                  <strong>Worth investing?</strong>{' '}
                  <strong className="red">No!</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="trailblazer-harmony"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="gold">5★</strong>
              </td>
              <td>
                <strong className="amplifier">Amplifier</strong>
              </td>
              <td>
                Clear Jarilo-VI main story (Roads to the Past Have Long Been
                Closed)
              </td>
              <td>
                <p>
                  An important member of the Break meta team who possesses the
                  Super Break mechanic. The third and the best variant for the
                  MC character that you should invest into as priority.
                </p>
                <p>
                  <strong>Worth investing?</strong>{' '}
                  <strong className="green">Yes!</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="march-7th-swordmaster"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="gold">4★</strong>
              </td>
              <td>
                <strong className="dps">Support DPS</strong>
              </td>
              <td>
                Clear Trailblaze Continuance mission (March to Mastery: A Star
                is Born)
              </td>
              <td>
                <p>
                  March’s Xianzhou-styled form is a powerful and versatile
                  support and sub DPS hybrid Character. She primarily is used in
                  FUA teams, but can be slotted into a Break team or Crit team
                  as well.
                </p>
                <p>
                  <strong>Worth investing?</strong>{' '}
                  <strong className="green">Yes!</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="trailblazer-remembrance"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="gold">5★</strong>
              </td>
              <td>
                <strong className="amplifier">Amplifier</strong>
              </td>
              <td>
                Clear Amphoreus main story (Night Veil, Shroud the Silent Past)
              </td>
              <td>
                <p>
                  The fourth variant of the MC character and a strong support
                  for Characters with Memosprite summons. The Remembrance
                  Trailblazer also is a flexible support that can be slotted
                  into a variety of teams as well due to the ability to provide
                  general buffs like Crit DMG and 100% Action Advance.
                </p>
                <p>
                  <strong>Worth investing?</strong>{' '}
                  <strong className="green">Yes!</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="yukong"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="arrow-purple">4★</strong>
              </td>
              <td>
                <strong className="amplifier">Amplifier</strong>
              </td>
              <td>Clear Forgotten Hall - Memory of Xianzhou 1</td>
              <td>
                <p>
                  Yukong is a buffer who is hard to use and master, but when you
                  do, she will server you really well. Still, it will be really
                  hard to make use of her early in the game as you need to
                  properly speed tune your team which can be only achieved later
                  down the line when you start farming Relics.
                </p>
                <p>
                  <strong>Worth investing?</strong>{' '}
                  <strong className="red">No!</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="lynx"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="arrow-purple">4★</strong>
              </td>
              <td>
                <strong className="sustain">Sustain</strong>
              </td>
              <td>
                Clear Pure Fiction stage 2 (TL 34 required to unlock the mode)
              </td>
              <td>
                <p>
                  Lynx is a great 4★ healer who is easy to use and also works
                  really well with multiple damage dealers. Her only issue is
                  that it will take you roughly a week of playing to obtain her
                  for free as you need to be reach the second planet and
                  Trailblaze Level 34.
                </p>
                <p>
                  <strong>Worth investing?</strong>{' '}
                  <strong className="green">Yes</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="xueyi"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="arrow-purple">4★</strong>
              </td>
              <td>
                <strong className="sustain">DPS</strong>
              </td>
              <td>Clear Apocalyptic Shadow stage 2</td>
              <td>
                <p>
                  A great damage dealer who is a Break/Crit DPS hybrid. She
                  shines in content with Quantum weak enemies, but struggles
                  otherwise.
                </p>
                <p>
                  <strong>Worth investing?</strong>{' '}
                  <strong className="red">No</strong>
                </p>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesFreecharsPage;

export const Head: React.FC = () => (
  <Seo
    title="All free characters | Honkai: Star Rail | Prydwen Institute"
    description="All characters you can get for free in Honkai: Star Rail."
  />
);
