import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

interface IProps {
  name: string;
}

export const ZZZDiskImage: React.FC<IProps> = ({ name }) => {
  return (
    <>
      {name === 'Astral Voice' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_astral.webp"
          alt={name}
        />
      )}
      {name === 'Branch & Blade Song' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_branch.webp"
          alt={name}
        />
      )}
      {name === 'Woodpecker Electro' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_woodpecker.webp"
          alt={name}
        />
      )}
      {name === 'Puffer Electro' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_puffer.webp"
          alt={name}
        />
      )}
      {name === 'Shockstar Disco' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_shockstar.webp"
          alt={name}
        />
      )}
      {name === 'Freedom Blues' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_freedom.webp"
          alt={name}
        />
      )}
      {name === 'Hormone Punk' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_hormone.webp"
          alt={name}
        />
      )}
      {name === 'Soul Rock' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_soul.webp"
          alt={name}
        />
      )}
      {name === 'Inferno Metal' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_inferno.webp"
          alt={name}
        />
      )}
      {name === 'Chaotic Metal' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_chaotic.webp"
          alt={name}
        />
      )}
      {name === 'Thunder Metal' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_thunder.webp"
          alt={name}
        />
      )}
      {name === 'Polar Metal' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_polar.webp"
          alt={name}
        />
      )}
      {name === 'Fanged Metal' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_fanged.webp"
          alt={name}
        />
      )}
      {name === 'Swing Jazz' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_swing.webp"
          alt={name}
        />
      )}
      {name === 'Proto Punk' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_proto.webp"
          alt={name}
        />
      )}
      {name === 'Chaos Jazz' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_chaos.webp"
          alt={name}
        />
      )}
    </>
  );
};
