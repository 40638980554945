import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import ScrollspyNav from 'react-scrollspy-nav';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Accordion, Tab, Tabs } from 'react-bootstrap';
import { AshCharacter } from '../../../modules/ash/components/ash-character';
import { AshTrace } from '../../../modules/ash/components/ash-trace';

const AshGuidesBossParty: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Joint Training: Shriek Party</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_party.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Joint Training: Shriek Party</h1>
          <h2>
            A dedicated guide for the Joint Training: Shriek Party in Ash
            Echoes.
          </h2>
          <p>
            Last updated: <strong>02/01/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Introduction" />
              <StaticImage
                src="../../../images/ash/event/party_1.webp"
                alt="Guides"
              />
              <p>
                Second Boss to appear in Joint Training and deadliest rock band
                to tour Senlo, the Shriek Party (it’s really just one guy who
                does it all) brings a whole bunch of new mechanics making the
                fight feel fresh and fairly original.
              </p>
              <p>
                There aren’t any mechanics designed to specifically counter
                certain team comps, so most of the teams can be used to great
                effect. Take note however that continuous damage is dealt to
                your Terminal so avoid stall teams.
              </p>
              <p>
                The guide focuses mostly on the 300% difficulty, as the 200%
                version is more of a relaxed and beginner friendly version of
                the 300% difficulty.
              </p>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Major Mechanics" />
              <p>
                There are 4 main mechanics for this boss, and most modifiers
                play into one or two of those: the damage to the Terminal and
                your Echomancers, the Summons, and the Buffs and Debuffs to your
                team.
              </p>
              <Accordion defaultActiveKey={['0']} alwaysOpen>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Terminal Damage</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      The Boss has multiple ways to periodically deal damage
                      both to your Echomancers and to your Terminal, some of
                      which cannot be mitigated. Make sure to bring a Healer to
                      keep your Team topped up, and aim for speed more than
                      safety if you play with low TRM;
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>The Minion Rush</Accordion.Header>
                  <Accordion.Body>
                    <StaticImage
                      src="../../../images/ash/event/party_2.webp"
                      alt="Guides"
                    />
                    <p>
                      Whether in Guitarist or in Drummer modes, the Boss
                      frequently summons add-ons. These can either disable the
                      Spotlights and rob you of some Damage reduction, or it can
                      be a swarm of both ground and flying enemies. While they
                      don’t pose a threat to you in small numbers, a pack of
                      them can take out in one volley nearly any Echomancer
                      except for Tanks;
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Spotlights</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Throughout the fight, different Spotlights are going to be
                      highlighted, and placing an Echomancer on it will grant
                      passive Damage reduction to ALL your active Echomancers so
                      long as they stand under a Spotlight.
                    </p>
                    <p>
                      Note that Echomancers can be positioned across the entire
                      battlefield right from the start unlike usual stages,
                      however movement from one set of tiles to another can feel
                      fairly restricted.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Metronomes</Accordion.Header>
                  <Accordion.Body>
                    <StaticImage
                      src="../../../images/ash/event/party_3.webp"
                      alt="Guides"
                    />
                    <p>
                      Once past the first phase, a Metronome is regularly
                      summoned in front of your Terminal, and it’s going to
                      either Buff or Debuff your damage by up to 50% for a time,
                      with the buff only lasting half of the duration of the
                      debuff. Exploit this short Buff window to deal extreme
                      amounts of damage to the Boss, then immediately destroy
                      the Metronome afterwards to skip the debuff phase.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Team Examples" />
              <p>
                The danger in this encounter comes from either taking too long
                to beat the Boss, or being overwhelmed by the add-ons and their
                volleys of attacks. For those reasons, there is no need to
                overly focus on defensive stats and/or skills, so you can opt
                for the classic “hard-hitters”.
              </p>
              <Tabs
                defaultActiveKey="Team1"
                transition={false}
                id="event-teams"
                className="nav-tabs"
              >
                <Tab
                  eventKey="Team1"
                  title={<>Team #1 - The Trusty Summoner Team</>}
                  className="with-bg"
                >
                  <h5>Team #1 - The Trusty Summoner Team</h5>
                  <StaticImage
                    src="../../../images/ash/event/party_4.webp"
                    alt="Guides"
                  />
                  <p>
                    <strong>Characters:</strong>{' '}
                    <AshCharacter
                      slug="cen-ying"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="lorelle"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="boss"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="freda"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="boreas"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="luke"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="skylar"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    .
                  </p>
                  <p>
                    The Summoner team has gotten stronger yet again, with the
                    addition of Boreas further amplifying Cen Ying’s damage. The
                    fight is perfectly doable without Boreas and/or Luke, but
                    their ability to buff both offense and defense is top notch.
                  </p>
                  <p>
                    On the side of Memory Traces,{' '}
                    <AshTrace name="Growth" mode="data" onProfile /> goes up in
                    value once more since Boreas’ teamwide shield triggers its
                    skills, and although{' '}
                    <AshTrace name="Lucky Find" mode="data" onProfile /> is an
                    excellent Inspire Trace in any team running multiple
                    Tacticians/Supports, any other source of Inspire does the
                    trick. The advantage here comes from the speed to stack but
                    also it being an ATK Trace, allowing for an easier stacking
                    of ATK during Nexus.
                  </p>
                  <p>
                    Alternatively,{' '}
                    <AshTrace
                      name="My Vision Is Clear!"
                      mode="data"
                      onProfile
                    />{' '}
                    users can also abuse this Trace in the Summoner team by
                    bringing in Yuqi or Mars instead of Lorelle depending on
                    your needs in terms of tanking. The resulting burst damage
                    is even greater which makes this build ideal when facing
                    Bosses with relatively low damage output like this one.
                  </p>
                </Tab>
                <Tab
                  eventKey="Team2"
                  title={<>Team #2 - Corrosion, the Ol’ reliable</>}
                  className="with-bg"
                >
                  <h5>Team #2 - Corrosion, the Ol’ reliable</h5>
                  <StaticImage
                    src="../../../images/ash/event/party_5.webp"
                    alt="Guides"
                  />
                  <p>
                    <strong>Characters:</strong>{' '}
                    <AshCharacter
                      slug="caroline"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="lorelle"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="gina"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="di-yan"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="boss"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="freda"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="begonia"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    .
                  </p>
                  <p>
                    Do you feel like some things never change? Yeah, Corrosion
                    is one of those. It always finds a way to be useful, and
                    this time around there’s nothing to hold it back.
                  </p>
                  <p>
                    With the recent Nexus paths added to the game, getting 20
                    Inspire stacks with 2 Traces has become very consistent
                    which makes it even easier to improve your Corrosion Team’s
                    damage. Here,{' '}
                    <AshTrace name="Dusk Radiance" mode="data" onProfile /> is
                    played only because Lorelle is D3, otherwise it is necessary
                    to replace it by another Trace, preferably a VIT one like{' '}
                    <AshTrace
                      name="Behind The Curtains"
                      mode="data"
                      onProfile
                    />{' '}
                    to give some survivability to all the squishies you’re
                    running.
                  </p>
                </Tab>
                <Tab
                  eventKey="Team3"
                  title={<>Team #3 - Pure Fire, ‘cause who needs Water Zones</>}
                  className="with-bg"
                >
                  <h5>Team #3 - Pure Fire, ‘cause who needs Water Zones</h5>
                  <StaticImage
                    src="../../../images/ash/event/party_6.webp"
                    alt="Guides"
                  />
                  <p>
                    <strong>Characters:</strong>{' '}
                    <AshCharacter
                      slug="cyros"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="baili-tusu"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="boss"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="mo-hongxiu"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="freda"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="begonia"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="skylar"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    .
                  </p>
                  <p>
                    The classic Fire Team works just fine here too, with its
                    natural mix of offense and defense there isn’t much that can
                    threaten your Strikers. The things you’d have to worry about
                    are too low of a TRM rating putting you in a tougher race
                    against the clock, and the Flying enemies (even though Baili
                    Tusu can deal with them once transformed), so we bring
                    Skylar or any other Anti-Air/Projectile.
                  </p>
                  <p>
                    Here too,{' '}
                    <AshTrace name="Dusk Radiance" mode="data" onProfile /> is
                    only usable thanks to Mo Hongxiu’s D1, otherwise look for
                    another VIT Trace like
                    <AshTrace
                      name="Festive Night"
                      mode="data"
                      onProfile
                    /> or{' '}
                    <AshTrace
                      name="Behind The Curtains"
                      mode="data"
                      onProfile
                    />
                    .
                  </p>
                </Tab>
                <Tab
                  eventKey="Team4"
                  title={<>Team #4 - Electroconduct, that’s what</>}
                  className="with-bg"
                >
                  <h5>Team #4 - Electroconduct, that’s what</h5>
                  <StaticImage
                    src="../../../images/ash/event/party_7.webp"
                    alt="Guides"
                  />
                  <p>
                    <strong>Characters:</strong>{' '}
                    <AshCharacter
                      slug="freda"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="mars"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="boss"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="skylar"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="lydia"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="boreas"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="lorelle"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    .
                  </p>
                  <p>
                    What better time to play Electroconduct than on a stage with
                    free Water Zones? Skylar has excellent control, lightning
                    res debuff, AoE clearing, fast Inspire stacking (with{' '}
                    <AshTrace name="Lucky Find" mode="data" onProfile />) on top
                    of reasonable personal DPS. Lydia’s the main damage dealer,
                    once she’s ramped up her Active Skill is stronger than most
                    other units’ Ultimates. Mars is the centerpiece of this
                    team, as he triggers both{' '}
                    <AshTrace name="Dusk Radiance" mode="data" onProfile /> and{' '}
                    <AshTrace
                      name="My Vision Is Clear!"
                      mode="data"
                      onProfile
                    />{' '}
                    for some very juicy Burst windows.
                  </p>
                  <p>
                    The weakest link of that team is the Leader, with no
                    dedicated Lightning Leader as of now (or they do not stack
                    with MVIC).
                  </p>
                </Tab>
                <Tab
                  eventKey="Team5"
                  title={<>Team #5 - Lydia, what else?</>}
                  className="with-bg"
                >
                  <h5>Team #5 - Lydia, what else?</h5>
                  <StaticImage
                    src="../../../images/ash/event/party_8.webp"
                    alt="Guides"
                  />
                  <p>
                    <strong>Characters:</strong>{' '}
                    <AshCharacter
                      slug="freda"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="mars"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="lorelle"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="lydia"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="yuqi"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="tian-ruo"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="shu"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    .
                  </p>
                  <p>
                    The current Lydia-centric Electroconduct Team can also be
                    run with the SR Memory Trace{' '}
                    <AshTrace name="Starlit Memories" mode="data" onProfile />{' '}
                    and its ‘Life Anthem’ skill, which starts to show
                    significant power at level 2 and up. As ‘Life Anthem’ is a
                    skill belonging only to this Trace and another of R rarity,{' '}
                    <AshTrace
                      name="Dream Of Cherry Blossoms"
                      mode="data"
                      onProfile
                    />
                    , you can try to use the new Inheritance system to secure
                    one extra level of the skill and potentially drop the R
                    Trace for another combo of Traces such as{' '}
                    <AshTrace
                      name="Memories Of The Past"
                      mode="data"
                      onProfile
                    />{' '}
                    or <AshTrace name="Growth" mode="data" onProfile />, then go
                    for another Inspire Trace instead of
                    <AshTrace name="Secret Melody" mode="data" onProfile />.
                  </p>
                  <p>
                    With this build, you’ll want to add as many Square
                    Echomancers as you can in order to rotate them in and out
                    (or forcefully put them out of commission) and superbuff
                    Lydia who you’ll place right next to the Boss (to give her
                    the lowest HP percentage) to guarantee she’s the one
                    receiving the MST buff. The more room you have to throw your
                    Square Echomancers under the bus or shelf them, the more
                    appealing it becomes to increase the level of Life Anthem.
                  </p>
                  <p>
                    For that reason, it’s preferred to opt for Inspire Traces
                    that do not force you to bring Echomancers of other classes:
                    <AshTrace name="Lucky Find" mode="data" onProfile /> is
                    excellent in that sense as it and{' '}
                    <AshTrace name="Born From Ashes" mode="data" onProfile />{' '}
                    can both be stacked by Freda on her own. Otherwise,
                    <AshTrace
                      name="Codename Diversity"
                      mode="data"
                      onProfile
                    />{' '}
                    or <AshTrace name="Nightmare" mode="data" onProfile /> are
                    great options if you’re using Mo Hongxiu or Tian Ruo
                    respectively as one of your Square Echomancers.
                  </p>
                  <p>
                    Lastly, the very high reliance on MST in this build makes it
                    extremely flexible, as you do not need to focus on any other
                    remaining stat, letting you pick them as you wish based on
                    the encounter(s) you’re building for.
                  </p>
                </Tab>
                <Tab
                  eventKey="Team6"
                  title={<>Team #6 - Scarlett & Friends</>}
                  className="with-bg"
                >
                  <h5>Team #6 - Scarlett & Friends</h5>
                  <StaticImage
                    src="../../../images/ash/event/party_9.webp"
                    alt="Guides"
                  />
                  <p>
                    <strong>Characters:</strong>{' '}
                    <AshCharacter
                      slug="scarlett"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="shiratori-azusa"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="boss"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="freda"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="su-xiao"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="boreas"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    ,
                    <AshCharacter
                      slug="mars"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    .
                  </p>
                  <p>
                    Very classic take on the Scarlett Team with{' '}
                    <AshTrace
                      name="My Vision Is Clear!"
                      mode="data"
                      onProfile
                    />{' '}
                    added on top in case you want some extra burst once the
                    Water Zones appear, however your initial burst damage is so
                    high that you might not even need it.
                  </p>
                  <p>
                    The team has extremely low VIT, so Boreas shields can come
                    in clutch.
                  </p>
                </Tab>
              </Tabs>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={['section-0', 'section-1', 'section-2']}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h5>Jump to</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Major Mechanics</a>
                </li>
                <li>
                  <a href="#section-2">Team Examples</a>
                </li>
              </ul>
            </ScrollspyNav>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesBossParty;

export const Head: React.FC = () => (
  <Seo
    title="Joint Training: Shriek Party | Ash Echoes | Prydwen Institute"
    description="A dedicated guide for the Joint Training: Shriek Party in Ash Echoes."
    game="ash"
  />
);
