import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';

const NikkeGuidesViper: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide '} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Viper (Treasure) guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_viper_tr.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Viper (Treasure) guide & review</h1>
          <h2>
            A guide & review for Viper (Treasure) in NIKKE: Goddess of Victory.
          </h2>
          <p>
            Last updated: <strong>18/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Viper (Treasure)" />
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon-tier"
                  slug="viper"
                  enablePopover
                  tierID={134}
                />
              </div>
              <p>
                Snakes are ultimately scary, especially when you know they can
                be venomous like vipers usually are. However, there are some
                people that seem to be able to charm snakes... and are commonly
                known as Snake Charmers. In Nikke, there is only one known
                'Viper Charmer', and he is commonly known as the Commander!
                Viper, while initially a unit that wasn't well received in the
                meta, has been a fan favorite for a long long time due to her
                personality and role in the main storyline. With her newly
                gained 'Sustained Damage' skills due to her love for her 'Honey'
                in this new Favorite Item system (which was released along with
                her 60$ Gacha Skin, duh), can she push through and become
                relevant? Let's fine out!
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Viper's Favorite Item improves her Base Damage by a lot. With
                high uptime and good value Hit Rate buffs, she is able to deal
                core hits with more efficiency than before. Alongside this, she
                gains the ability to deal DoT with her Sustained Damage ability
                in Burst Skill, and buffs sustained damage in her Skill 1. This
                improves her previously mediocre base damage by a decent margin,
                but comes with it's own problem that will be discussed below. On
                top of all of this, she also gains a 'Re-Enter Burst Stage II'
                skill in Phase 3, which can be a good thing and a bad thing for
                her. This allows her to be a pair alongside key Burst II units
                which may want to be paired with Viper like Leona and S.Rosanna,
                which are the the 2 B2s you will probably see alongside Viper.
              </p>
              <p>
                Now, while her Kit has indeed improved her damage, how does she
                compare in damage dealt to other DPS? In PvE, You can think of
                Viper as a Guilty but for Water Element. The answer is quite
                disappointing in retrospect for a unit that was given a Favorite
                Item. We think that the key factor missing in Viper's kit is the
                ability to buff her own shotgun's damage with high uptime and
                value ATK/Attack damage buffs, etc. which is something present
                in all other top DPS kits. It would help Viper gain a better
                footing in the current meta. While Tove is an amazing buffer for
                shotguns, and can make Viper relevant on some scenarios, in
                general Viper's performance will be more or less the same as one
                would expect Guilty's to be if she was a water code unit. Viper
                is definitely the weakest of all treasure units as of yet. In
                PvP, Viper still displays similar performance as her normal
                version does.
              </p>
              <h5>Should I get the Favorite Item for this unit?</h5>
              <p>
                Before we get into this, it is recommended to upgrade other
                Favorite Items before getting Viper's treasure. However, you can
                definitely think of use-case scenarios for which Viper can be a
                choice to upgrade. This comes down to a simple question. Are you
                competitive in Raids and PvP? Viper has a chance of being used
                against Water Weak Solo Bosses, and will fit in teams just like
                Guilty fits in teams against Wind Weak Solo Raid bosses
                occasionally. Another use case is definitely in PvP. If you are
                using her somewhere, especially in PVP as a Burst Gen filler
                unit, then upgrading her can be a choice as she will still do
                whatever she's supposed to do, but deal more damage thanks to
                Favorite Item.
              </p>
              <p>
                One thing to note is the fact that her Re-Enter Burst Stage II
                skill is a good thing for PvE, but a bad thing for PvP. In PvE,
                you would rather prefer to Burst with someone else alongside
                Viper since Viper's Burst Skill is a very selfish skill, whereas
                meta B2s tend to buff the teams damage. Hence, for PvE content,
                you would want Phase 3 Favorite Item.
              </p>
              <p>
                However, in some team comps in PvP, Viper is used as a solo
                Burst 2 unit, and in those cases you DO NOT want to upgrade her
                Favorite Item to Phase 3 since it will break team composition.
                Viper is also used as a sacrifice in some strats, for example in
                teams alongside xAnne and/or Rosanna, where there are two
                caveats: Firstly, Viper bursting would delay the Burst speed of
                the team. Secondly, Viper not dying due to the extra tankiness
                provided by SR dolls can lead to the whole team comp not working
                anyways. Re-Enter Burst Stage units are not considered as ideal
                units in PvP.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <h5>Phase 1 - Skill 1</h5>
              <blockquote>
                <p>■ Activates when the target appears. Affects all allies.</p>
                <p>ATK ▲ 25.98% for 10 sec.</p>
                <p>Hit Rate ▲ 11.13% for 10 sec.</p>
                <p>
                  ■ Only activates when attacking in Vamp status. Affects self.
                </p>
                <p>
                  Sustained Damage ▲ 4.4%, stacks up to 10 time(s) and lasts for
                  10 sec.
                </p>
                <p>
                  Hit Rate ▲ 1.84%, stacks up to 10 time(s) and lasts for 10
                  sec.{' '}
                </p>
              </blockquote>
              <p>
                This phase changes Viper's Skill 1. It is unlocked once you have
                Viper's bond at lv 30, SR Doll SG version lv 15 equipped on her
                and you finish some very doki doki missions to unlock Viper
                Favorite version. Let's summarize the upgrade:
              </p>
              <ul>
                <li>
                  Good effect which lasts 10s only in a fight =&gt; Remain
                  useless.
                </li>
                <li>
                  S1 now gives herself Hit Rate and DoT damage up when she's in
                  Full Burst.
                </li>
              </ul>
              <p>
                Hit Rate is always welcome on any SG, because the more hit rate,
                the less spread of SG shot. Problem is this effect requires 10
                shot to reach max potential, and let's be honest, the effect
                itself is not even meaningful. Even a 90% Hit Rate SG (which
                means 100% core shot) is mid these days without self steroids.
                Look at B.Soda and Maid Privaty, the 2 strongest Shotgun
                currently, you will notice that not only they have Hit Rate
                buff, but also more damage modifier/ATK buff some ways.
              </p>
              <p>
                A serious problem she has in her kit is that she will lose those
                effect immediately when she loses Vamp effect, which is either
                by being hit or Full Burst ends. The reason they make her kit
                like due to the change in Phase 3 that we will explain later
                below.
              </p>
              <p>
                Her DoT damage up is ehhh because she has no DoT at this
                point... What were they cooking with this kit?
              </p>
              <h5>Phase 2 - Burst Skill</h5>
              <blockquote>
                <p>■ Affects 1 designated enemy unit(s).</p>
                <p>Deals 1029.6% of final ATK as damage.</p>
                <p>
                  ■ Activates when the designated enemy unit(s) include the
                  stage target. Affects the same enemy unit(s).
                </p>
                <p>DEF ▼ 19.83% for x sec.</p>
                <p>
                  Deals 105.3% of final ATK as sustained damage every 1 sec for
                  10 sec.{' '}
                </p>
              </blockquote>
              <p>
                The change in Phase 2 applies to her Burst. This change will
                increase her burst damage by 5 times!
              </p>
              <ul>
                <li>Burst initial damage is increased by 2 times.</li>
                <li>
                  Viper now deals DoT damage on enemy, which is also increased
                  by S1 buff.
                </li>
              </ul>
              <p>
                Now you know where her DoT is. You are forced to spend 50 Old
                Phone Charger to upgrade her Favorite to Phase 2 to unlock her
                full potential. Now the damage is not so bad considering Viper
                is an Attacker. Problem is, Viper as a B2 won't have Full Burst
                bonus, and most of the B3/Full Burst buffs too, so her damage
                won't be that impressive.{' '}
              </p>
              <p>
                Not to mention, if the one is hit by this skill is not stage
                target (Solo Boss, or End stage enemy), then she won't deal DoT
                (aka PVP). Oopsie moment.
              </p>
              <h5>Phase 3 - Skill 2</h5>
              <blockquote>
                <p>■ Affects self.</p>
                <p>Hit Rate ▲ 21.96% continuously.</p>
                <p>■ Activates when entering Full Burst. Affects self.</p>
                <p>
                  Vamp: Prevents self from being the target of single-target
                  attacks continuously. Loses effect when the caster takes
                  damage.
                </p>
                <p>Invulnerable for 1 sec.</p>
                <p>■ Activates when using Burst Skill. Affects all allies.</p>
                <p>Re-enter Burst Skill Stage 2. </p>
              </blockquote>
              <p>
                The most expensive phase, also the most controversial change.
              </p>
              <ul>
                <li>Her Vamp now lasts forever instead of 10s.</li>
                <li>If you use her burst, she will cause Re-Enter Burst 2.</li>
                <li>She gains a tremendous amount of Hit Rate.</li>
              </ul>
              <p>
                The Hit Rate is also nice here. If you combine both her S1 and
                S2, she will now have 40% Hit Rate self buff already. With 50%
                more Hit Rate from OL, she can shoot core easily like any MG.
                But as we stated above on her Phase 1, Viper's Shotgun herself
                doesn't deal good enough damage to consider meta due to lack of
                self buffs.
              </p>
              <p>
                The Vamp lasting forever makes her S1 complete, because her S1
                will no longer expire right after burst ends. We guess you can
                also abuse some niche cases like Vamp vs single target attack of
                Boss. (Remember when putting Rosanna in the middle so Chatterbox
                never spawned Missiles?)
              </p>
              <p>
                Now the final change, Re-Enter B2. This means if (and only if)
                you use her Burst, you are forced to use another B2 or you will
                never be able to use any Burst 3. You are now forced to use B1 -
                B2 - Viper - B3 - B3 comp, with the B2 being a 20 second
                cooldown.
              </p>
              <p>
                Problem is, this comp competes directly with standard B1 - B2 -
                B3 - B3 - B3 with the last B3 is off burst DPS. Or B1 - B2 - B3
                - B3 - Flex with the Flex spot is non burst healer, protector,
                buffer etc.
              </p>
              <p>
                By using Viper Favorite Phase 3, you lose 1 slot of either
                Healer, Buffer or Flex DPS. Since Viper doesn't "buff" or "heal"
                anything except your soul, she now competes with Flex DPS. And
                sadly, she loses to every single meta non burst DPS on neutral
                grounds, like Guillotine and Scarlet. At least if boss/enemy has
                core, she's better than Guilty.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Tier List" />
              <h5>
                Campaign (LD) - <strong className="b">B</strong> | Campaign (HD)
                - <strong className="b">B</strong>
              </h5>
              <p>
                {' '}
                Due to the Hit Rate buff, Viper can deal pretty great damage
                against most normal mobs because core exists. She should be at
                least on par, if not better than Guilty here. However, shotguns
                are still not entirely viable in campaign due to various units
                like Red Hood, Modernia, the two Scarlets and Alice just raising
                the bar too high for DPS units to be able to compete with them.
                Crown, Naga, Blanc, and S.Rosanna also have created a big gap
                between the meta and non meta units, of which Viper does not
                even come close to since she does not buff her allies.
              </p>
              <h5>
                Boss (Solo) - <strong className="s">S</strong> | Boss (Adds) -
                <strong className="b">B</strong>
              </h5>
              <p>
                {' '}
                The water Guilty, just waiting for a close range Water Weak boss
                which can allow her to be run with no healers. Otherwise, it
                might be tough to add in Viper to the team... Tove is her best
                friend. Tove can allow Viper to outDPS Scarlet against Water
                Weak Bosses, but the ultimate factor remains to be that Viper
                can only fit in if the damage dealt by the boss can be split
                between B.Soda and Maid Privaty in the shotgun comp of: Tove
                ViperPhase3 M.Priv B.Soda Leona, allowing the team to live 3
                minutes. Otherwise, Viper can find it difficult to fit in. She
                does not fit in well in the True Damage comp as there are units
                that deal higher damage that can use those slots alongside
                S.Rosanna.
              </p>
              <StaticImage
                src="../../../images/nikke/reviews/viper_treasure_2.webp"
                alt="Kit"
              />
              <p>
                <i>
                  {' '}
                  Water Weak Solo Boss, all 10/10/10, 2 ATK. Viper has 2 ele as
                  well.
                </i>
              </p>
              <p>
                As you can see, Viper can perform exceptionally well against
                Water Weak bosses, outdamaging the likes of B.Soda even! Hence,
                she can definitely be a choice in your roster against Water weak
                bosses. The major problem might just be healing, but that
                depends on how Shift Up design the boss.
              </p>
              <h5>
                PVP - <strong className="s">S</strong>
              </h5>
              <p>
                {' '}
                Beware of the Re-Enter Burst Stage II mechanic as it can
                complicate things for Viper. While we do note recommend getting
                Phase 3, she does appreciate Phase 2. Phase 2 benefits her
                single target nuke, which can deal an astonishing 1029.6% of
                final ATK, which can be buffed or assisted by a Burst 1 unit. If
                one understands how targeting works well, Viper can assist
                Rosanna in cleaning up an opponent in a fast enough team. Hence,
                Viper can be used to deal Single Target Nuke DPS as well now,
                which is something her old self did not have. but it comes at
                the expense of being the weakest element you can be in PvP:
                Water. This is due to the fact that most good DPS in PvP are
                Electric, with the top 2 DPS being Scarlet and S.Anis. Scarlet
                especially is a big barrier for SG units to come across, and
                being water SG unit just makes it harder.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <div className="nikke-skills">
                <div className="nikke-skills-row over header">
                  <div className="column character">Character</div>
                  <div className="column suggestions essential">Essential</div>
                  <div className="column suggestions ideal">Ideal</div>
                  <div className="column suggestions passable">Passable</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-row">
                  <div className="nikke-skills-row over data">
                    <div className="column character">
                      <NikkeCharacter
                        slug="viper"
                        mode="icon-tier"
                        showLabel
                        enablePopover
                        onlyName
                        tierID={134}
                      />
                    </div>
                    <div className="column suggestions pve">
                      <p className="on-mobile">Essential</p>
                      <p>
                        <strong>-</strong>
                      </p>
                    </div>
                    <div className="column suggestions pvp">
                      <p className="on-mobile">Ideal</p>
                      <p>
                        <strong>4x Hit Rate</strong>
                      </p>
                      <p>
                        <strong>4x Attack</strong>
                      </p>
                      <p>
                        <strong>4x Elemental Damage</strong>
                      </p>
                    </div>
                    <div className="column suggestions pvp">
                      <p className="on-mobile">Passable</p>
                      <p>
                        <strong>4x Ammo</strong>
                      </p>
                    </div>
                    <div className="column priority">
                      <p className="on-mobile">Priority</p>
                      <p className={`prio prio-5`}>Low (PvE)</p>
                    </div>
                  </div>
                  <div className={`explanation`}>
                    <p className="on-mobile">Notes</p>
                    <p>
                      Because Viper has a lot of Hit Rate already, OL Hit Rate
                      will help her reach 90% breakpoint easier and make her
                      deal damage like a MG unit. ATK and Ele are standard for
                      any DPS.
                    </p>
                  </div>
                </div>
              </div>
              <br></br>
              <h5>Skill Investments</h5>
              <ul>
                <li>
                  <strong>Skill 1: 4/7/10</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    Low impact, skill effects are fixed or too weak even at max
                    level. However, her sustained damage buff is present in this
                    skill. To max out her DPS, this is the skill you need to
                    upgrade.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 4</strong>
                </li>
                <ul>
                  <li>
                    Low impact, skill effects are fixed or too weak even at max
                    level. Upgrade it to higher levels only if you care about
                    hitrate.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill: 4/7/10</strong>
                </li>
                <ul>
                  <li>
                    Low impact, skill effects are fixed or too strong even at
                    low level. However, her nuke and Sustained Damage are
                    present in this skills. To max out her DPS, this is the
                    skill you need to upgrade
                  </li>
                </ul>
              </ul>
              <br></br>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="viper" />
                </div>
              </div>
              <br />
              <br></br>
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <p>
                For general DPS, you can slap Bastion on her so she reloads less
                during the entire fight.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: The Shotgun Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="tove" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon-tier"
                      slug="viper"
                      enablePopover
                      tierID={134}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter slug="leona" enablePopover mode="icon" />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="privaty-unkind-maid"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="ein" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="soda-twinkling-bunny"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="volume" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                {' '}
                The standard Shotgun team. Depends on your Viper's Favorite
                progression, you can burst with her or not. Ideally, Viper is
                Phase 3 and you burst with her.
              </p>
              <h5>Team #2: The DoT Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon-tier"
                      slug="viper"
                      enablePopover
                      tierID={134}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rosanna-chic-ocean"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      slug="sakura-bloom-in-summer"
                      enablePopover
                      mode="icon"
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                {' '}
                This team is worse for viper than the sg team, since Tove is
                just a better buffer for SGs, and Viper does not deal all thst
                much DoT for S.Rosanna to make a big difference. This team makes
                the most out of Damage over time buff from S.Rosanna, featuring
                S.Sakura and ViperTr. To run this team, your Viper Favorite
                progression MUST REACH Phase 3, because there's no reason you
                should use Viper burst over S.Rosanna burst.
              </p>
              <h5>Team #3: insert Genji saying I need healing</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="tove" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon-tier"
                      slug="viper"
                      enablePopover
                      tierID={134}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      slug="soda-twinkling-bunny"
                      enablePopover
                      mode="icon"
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noir" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                {' '}
                Phase 3 Viper needed. If you need healing, this is the way to go
                probably. Definitely an option one can think about for against
                Water Weak bosses.
              </p>
              <h5>Team #4: Crown the GOAT</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="tove" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon-tier"
                      slug="viper"
                      enablePopover
                      tierID={134}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      slug="privaty-unkind-maid"
                      enablePopover
                      mode="icon"
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      slug="soda-twinkling-bunny"
                      enablePopover
                      mode="icon"
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                {' '}
                If the SG team is extremely relevant, they might be able to fit
                in the Crown team, where Viper can take advantage of the fact
                that she can use Crown's buffs to units that burst every round
                of burst, and gain double the amount of buffs from Crown as
                compared to her ally SGs. This helps her deal immense DPS which
                can come close to what B.Soda and M.Priv are dishing out!
              </p>
              <div className="section-scroll" id="section-5">
                <SectionHeader title="PvP Team Compositions" />
                <h5>Funneling Rosanna</h5>
                <div className="nikke-team-showcase">
                  <div className="team-slot">
                    <h5 className="burst-2">Burst 2</h5>
                    <div className="employee-container for-nikke">
                      <NikkeCharacter
                        mode="icon-tier"
                        slug="viper"
                        enablePopover
                        tierID={134}
                      />
                    </div>
                  </div>
                  <div className="team-slot">
                    <h5 className="burst-1">Burst 1</h5>
                    <div className="employee-container for-nikke">
                      <NikkeCharacter
                        mode="icon"
                        slug="miracle-fairy-anne"
                        enablePopover
                      />
                    </div>
                  </div>
                  <div className="team-slot">
                    <h5 className="burst-1">Burst 1</h5>
                    <div className="employee-container for-nikke">
                      <NikkeCharacter
                        mode="icon"
                        slug="rosanna"
                        enablePopover
                      />
                    </div>
                  </div>
                  <div className="team-slot">
                    <h5 className="burst-1">Burst 1</h5>
                    <div className="employee-container for-nikke">
                      <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                    </div>
                  </div>
                  <div className="team-slot">
                    <h5 className="burst-3">Burst 3</h5>
                    <div className="employee-container for-nikke">
                      <NikkeCharacter
                        mode="icon"
                        slug="scarlet"
                        enablePopover
                      />
                    </div>
                  </div>
                </div>
                <p>
                  Assumes Phase 2 or Phase 3 Viper. You just sacrifice Viper so
                  she triggers Rosanna S2, which allows your team to reach Burst
                  1 immediately at 2 RL. Note that by Favorite Viper, you also
                  give her 17% damage reduction, which can lead to her not dying
                  in time.
                </p>
                <h5>SG Mayhem</h5>
                <div className="nikke-team-showcase">
                  <div className="team-slot">
                    <h5 className="burst-3">Burst 3</h5>
                    <div className="employee-container for-nikke">
                      <NikkeCharacter
                        mode="icon"
                        slug="privaty-unkind-maid"
                        enablePopover
                      />
                    </div>
                  </div>
                  <div className="team-slot">
                    <h5 className="burst-2">Burst 2</h5>
                    <div className="employee-container for-nikke">
                      <NikkeCharacter
                        mode="icon-tier"
                        slug="viper"
                        enablePopover
                        tierID={134}
                      />
                    </div>
                  </div>
                  <div className="team-slot">
                    <h5 className="burst-1">Burst 1</h5>
                    <div className="employee-container for-nikke">
                      <NikkeCharacter mode="icon" slug="pepper" enablePopover />
                    </div>
                  </div>
                  <div className="team-slot">
                    <h5 className="burst-3">Burst 3</h5>
                    <div className="employee-container for-nikke">
                      <NikkeCharacter
                        mode="icon"
                        slug="soda-twinkling-bunny"
                        enablePopover
                      />
                    </div>
                  </div>
                  <div className="team-slot">
                    <h5 className="burst-3">Burst 3</h5>
                    <div className="employee-container for-nikke">
                      <NikkeCharacter mode="icon" slug="drake" enablePopover />
                      <NikkeCharacter mode="icon" slug="noir" enablePopover />
                    </div>
                  </div>
                </div>
                <p>
                  This team puts a lot of pressure on P1/P5 (depend on if it's
                  an attack/defense team). If you can put SR lv 15 dolls on all
                  5, they should kill anything (except Defender with Biscuit or
                  Makima or Scarlet with Jackal's link.) by 2 RL.
                </p>
              </div>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Unique Re-Enter Burst Stage II trait.</li>
                        <li>
                          Gains a lot of advantage than other SG against bosses
                          with core due to self Hit Rate buffs.
                        </li>
                        <li>DoT DPS with self Sustained Damage buffs.</li>
                        <li>
                          High value single target nuke when bursting, good for
                          PvP.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Skill 1 ATK buff still has very low uptime. No innate
                          ATK buffs apart from the first 15 seconds.
                        </li>
                        <li>
                          Expensive. Favorite Item needs to be Phase 3 for PvE.
                        </li>
                        <li>
                          Phase 3 breaks most of the PvP Team Comps due to
                          Re-Enter Burst Stage II skill.
                        </li>
                        <li>
                          Split decision to choose Phase 2 for PvP or Phase 3
                          for PvE. Once you go to Phase 3, you cannot revert
                          back to Phase 2.
                        </li>
                        <li>
                          Highly situational unit, strict use in Water-Weak Solo
                          Raid Bosses, in which there are situations where she
                          may not be used anyways.
                        </li>
                        <li>
                          The gain is horrible compared to the cost since she is
                          still a mid tier DPS.
                        </li>
                        <li>
                          You're forced to run a 1-2-2 formation due to her
                          Re-Enter Burst Stage II skill.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesViper;

export const Head: React.FC = () => (
  <Seo
    title="Viper (Treasure) guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Viper (Treasure) in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
