import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Button, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

import '../../generic-page.scss';

const TOGGuidesPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="tog">
      <ul className="breadcrumb">
        <li>
          <Link to="/tog/">Tower of God: New World</Link>
        </li>
        <li className="divider">/</li>
        <li>Guides</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/tog/categories/category_guides.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Guides</h1>
          <h2>Our guides for Tower of God: New World</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Conowen's Sheet" />
        <p>
          For the most up to date information about the game, check this sheet:
        </p>
        <Button
          variant="primary"
          href="https://docs.google.com/spreadsheets/u/0/d/1paOxiwoKOzzR0E5W_r1SYLVuliJomnz8BkNQt8vNhQU/htmlview#"
          target="_blank"
          rel="noreferrer"
        >
          Conowen's ToG Sheet
        </Button>
        <SectionHeader title="Newbie zone" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Introduction to the game"
            link="/tog/guides/introduction-to-the-game"
            image={
              <StaticImage
                src="../../../images/tog/categories/category_intro.png"
                alt="Introduction to the game"
              />
            }
          />
          <CategoryCard
            title="Reroll guide"
            link="/tog/guides/reroll"
            image={
              <StaticImage
                src="../../../images/tog/categories/category_reroll.png"
                alt="Reroll guide"
              />
            }
          />
          <CategoryCard
            title="Beginner guide"
            link="/tog/guides/beginner-guide"
            image={
              <StaticImage
                src="../../../images/tog/categories/category_beginner.png"
                alt="Beginner guide"
              />
            }
          />
          <CategoryCard
            title="Early team progression"
            link="/tog/guides/early-team-progression"
            image={
              <StaticImage
                src="../../../images/tog/categories/category_team.png"
                alt="Early team progression"
              />
            }
          />
          <CategoryCard
            title="Wishlist tips"
            link="/tog/guides/wishlist-tips"
            image={
              <StaticImage
                src="../../../images/tog/categories/category_wishlist.png"
                alt="Wishlist tips"
              />
            }
            updated
          />
          <CategoryCard
            title="Meta teams (Story)"
            link="/tog/guides/meta-teams"
            image={
              <StaticImage
                src="../../../images/tog/categories/category_meta.jpg"
                alt="Meta teams"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default TOGGuidesPage;

export const Head: React.FC = () => (
  <Seo
    title="Guides | Tower of God: New World | Prydwen Institute"
    description="Guides for Tower of God: New World gacha game."
    game="tog"
  />
);
