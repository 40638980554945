export const ZZZAssault_3 = [
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 22.23,
    rank: 1,
    avg_round: 23566,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 15.02,
    rank: 2,
    avg_round: 18408,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 5.01,
    rank: 3,
    avg_round: 17674,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 4.11,
    rank: 4,
    avg_round: 23168,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 3.72,
    rank: 5,
    avg_round: 20065,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 3.49,
    rank: 6,
    avg_round: 20091,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 2.96,
    rank: 7,
    avg_round: 23285,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 2.3,
    rank: 8,
    avg_round: 21406,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 2.14,
    rank: 9,
    avg_round: 22754,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'lighter',
    app_rate: 2.0,
    rank: 10,
    avg_round: 20188,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 1.54,
    rank: 11,
    avg_round: 16767,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 1.47,
    rank: 12,
    avg_round: 16649,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 1.42,
    rank: 13,
    avg_round: 21154,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 1.17,
    rank: 14,
    avg_round: 19956,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 1.08,
    rank: 15,
    avg_round: 19413,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 1.01,
    rank: 16,
    avg_round: 18324,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 0.92,
    rank: 17,
    avg_round: 18053,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.87,
    rank: 18,
    avg_round: 16193,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'lucy',
    app_rate: 0.87,
    rank: 18,
    avg_round: 17267,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.85,
    rank: 20,
    avg_round: 13766,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'koleda',
    char_three: 'soukaku',
    app_rate: 0.8,
    rank: 21,
    avg_round: 12909,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.78,
    rank: 22,
    avg_round: 20675,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.78,
    rank: 22,
    avg_round: 18171,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.6,
    rank: 24,
    avg_round: 16322,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.6,
    rank: 24,
    avg_round: 13054,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.6,
    rank: 24,
    avg_round: 28634,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'lighter',
    char_three: 'nicole-demara',
    app_rate: 0.55,
    rank: 27,
    avg_round: 19572,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.46,
    rank: 28,
    avg_round: 18303,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.44,
    rank: 29,
    avg_round: 18900,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.44,
    rank: 29,
    avg_round: 16070,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.41,
    rank: 31,
    avg_round: 20097,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.34,
    rank: 32,
    avg_round: 18598,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.32,
    rank: 33,
    avg_round: 17859,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.32,
    rank: 33,
    avg_round: 21453,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.32,
    rank: 33,
    avg_round: 22774,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.3,
    rank: 36,
    avg_round: 14522,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.3,
    rank: 36,
    avg_round: 17763,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.28,
    rank: 38,
    avg_round: 18479,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.28,
    rank: 38,
    avg_round: 16523,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.28,
    rank: 38,
    avg_round: 10230,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.25,
    rank: 41,
    avg_round: 26902,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 0.23,
    rank: 42,
    avg_round: 20388,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'soukaku',
    app_rate: 0.23,
    rank: 42,
    avg_round: 22317,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.21,
    rank: 44,
    avg_round: 15058,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.21,
    rank: 44,
    avg_round: 20636,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.21,
    rank: 44,
    avg_round: 13358,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.21,
    rank: 44,
    avg_round: 12578,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.21,
    rank: 44,
    avg_round: 13146,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.18,
    rank: 49,
    avg_round: 15200,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.18,
    rank: 49,
    avg_round: 15472,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.18,
    rank: 49,
    avg_round: 18781,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.18,
    rank: 49,
    avg_round: 15535,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.16,
    rank: 53,
    avg_round: 22325,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.16,
    rank: 53,
    avg_round: 22350,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.16,
    rank: 53,
    avg_round: 30978,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.16,
    rank: 53,
    avg_round: 18249,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.14,
    rank: 57,
    avg_round: 11754,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.14,
    rank: 57,
    avg_round: 21588,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.14,
    rank: 57,
    avg_round: 19799,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'rina',
    app_rate: 0.14,
    rank: 57,
    avg_round: 24690,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.14,
    rank: 57,
    avg_round: 13494,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'soukaku',
    app_rate: 0.14,
    rank: 57,
    avg_round: 34302,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.11,
    rank: 63,
    avg_round: 22184,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.11,
    rank: 63,
    avg_round: 15812,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.11,
    rank: 63,
    avg_round: 22616,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.11,
    rank: 63,
    avg_round: 21568,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.11,
    rank: 63,
    avg_round: 16347,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 63,
    avg_round: 23713,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 63,
    avg_round: 11913,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.11,
    rank: 63,
    avg_round: 17437,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.11,
    rank: 63,
    avg_round: 19208,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.11,
    rank: 63,
    avg_round: 12151,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 63,
    avg_round: 12829,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'lycaon',
    app_rate: 0.11,
    rank: 63,
    avg_round: 15759,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.11,
    rank: 63,
    avg_round: 36908,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.11,
    rank: 63,
    avg_round: 23103,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 77,
    avg_round: 16390,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 77,
    avg_round: 16898,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.09,
    rank: 77,
    avg_round: 14947,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 77,
    avg_round: 21956,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 77,
    avg_round: 14673,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.09,
    rank: 77,
    avg_round: 18540,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.09,
    rank: 77,
    avg_round: 42629,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 77,
    avg_round: 15889,
    star_num: '4'
  },
  {
    char_one: 'lycaon',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 77,
    avg_round: 19004,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 77,
    avg_round: 19041,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 0.09,
    rank: 77,
    avg_round: 32043,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'ellen',
    char_three: 'soukaku',
    app_rate: 0.09,
    rank: 77,
    avg_round: 11828,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'jane-doe',
    char_three: 'lycaon',
    app_rate: 0.09,
    rank: 77,
    avg_round: 17923,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'koleda',
    app_rate: 0.09,
    rank: 77,
    avg_round: 16980,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'ellen',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 77,
    avg_round: 16552,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 77,
    avg_round: 30316,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'soukaku',
    app_rate: 0.09,
    rank: 77,
    avg_round: 17538,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.09,
    rank: 77,
    avg_round: 16869,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.09,
    rank: 77,
    avg_round: 12960,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'corin',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 77,
    avg_round: 14401,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.09,
    rank: 77,
    avg_round: 28564,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.07,
    rank: 98,
    avg_round: 16000,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'piper',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 98,
    avg_round: 15220,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 98,
    avg_round: 20652,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 98,
    avg_round: 21612,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 98,
    avg_round: 12010,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'burnice',
    char_three: 'lycaon',
    app_rate: 0.07,
    rank: 98,
    avg_round: 9554,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.07,
    rank: 98,
    avg_round: 17902,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 98,
    avg_round: 33255,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'seth',
    app_rate: 0.07,
    rank: 98,
    avg_round: 9487,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 98,
    avg_round: 15666,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 98,
    avg_round: 18938,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 98,
    avg_round: 18385,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 98,
    avg_round: 20970,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.07,
    rank: 98,
    avg_round: 20906,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 98,
    avg_round: 13554,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 98,
    avg_round: 17003,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'koleda',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 98,
    avg_round: 11187,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'soukaku',
    char_three: 'seth',
    app_rate: 0.07,
    rank: 98,
    avg_round: 13391,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 98,
    avg_round: 28350,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 98,
    avg_round: 10292,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'burnice',
    char_three: 'soukaku',
    app_rate: 0.07,
    rank: 98,
    avg_round: 12272,
    star_num: '4'
  },
  {
    char_one: 'soukaku',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.05,
    rank: 119,
    avg_round: 16708,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 119,
    avg_round: 12634,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'koleda',
    char_three: 'seth',
    app_rate: 0.05,
    rank: 119,
    avg_round: 16598,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'ben',
    app_rate: 0.05,
    rank: 119,
    avg_round: 18392,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 119,
    avg_round: 16075,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 119,
    avg_round: 20146,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 119,
    avg_round: 18509,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 119,
    avg_round: 17816,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 119,
    avg_round: 8950,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 119,
    avg_round: 18750,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 119,
    avg_round: 17442,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 119,
    avg_round: 17722,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 119,
    avg_round: 14430,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 119,
    avg_round: 21386,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.05,
    rank: 119,
    avg_round: 14458,
    star_num: '4'
  },
  {
    char_one: 'lycaon',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 119,
    avg_round: 27374,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 119,
    avg_round: 33338,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 119,
    avg_round: 17831,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 119,
    avg_round: 20604,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'ben',
    app_rate: 0.05,
    rank: 119,
    avg_round: 15718,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 119,
    avg_round: 7947,
    star_num: '4'
  },
  {
    char_one: 'lycaon',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 119,
    avg_round: 17314,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'jane-doe',
    char_three: 'soukaku',
    app_rate: 0.05,
    rank: 119,
    avg_round: 12050,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 119,
    avg_round: 24702,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.05,
    rank: 119,
    avg_round: 30006,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 119,
    avg_round: 20725,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 119,
    avg_round: 23212,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 119,
    avg_round: 21282,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 119,
    avg_round: 13468,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'yanagi',
    app_rate: 0.05,
    rank: 119,
    avg_round: 11263,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.05,
    rank: 119,
    avg_round: 16832,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 119,
    avg_round: 20969,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 119,
    avg_round: 15354,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'soukaku',
    app_rate: 0.05,
    rank: 119,
    avg_round: 21183,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'koleda',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 119,
    avg_round: 12684,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'qingyi',
    app_rate: 0.05,
    rank: 119,
    avg_round: 25436,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 119,
    avg_round: 16342,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 119,
    avg_round: 12406,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 119,
    avg_round: 10068,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 119,
    avg_round: 18889,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'ben',
    app_rate: 0.05,
    rank: 119,
    avg_round: 8382,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'seth',
    app_rate: 0.05,
    rank: 119,
    avg_round: 15351,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 119,
    avg_round: 15055,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 119,
    avg_round: 17676,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 119,
    avg_round: 18312,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'lycaon',
    app_rate: 0.05,
    rank: 119,
    avg_round: 22734,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 119,
    avg_round: 32958,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'corin',
    char_three: 'qingyi',
    app_rate: 0.05,
    rank: 119,
    avg_round: 7972,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'harumasa',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 119,
    avg_round: 13656,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 119,
    avg_round: 14596,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nekomata',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 119,
    avg_round: 10750,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 119,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 119,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 119,
    avg_round: 12511,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'soukaku',
    app_rate: 0.05,
    rank: 119,
    avg_round: 20876,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 0.05,
    rank: 119,
    avg_round: 29116,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 119,
    avg_round: 15099,
    star_num: '4'
  }
];
