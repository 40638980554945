import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Col, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const NikkeGuidesMetaTeamsPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Meta teams (Story)</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_beginner.png"
            alt="Early and late meta teams"
          />
        </div>
        <div className="page-details">
          <h1>Meta teams (Story)</h1>
          <h2>
            A list of early and late game meta teams that will help you progress
            though the story in NIKKE.
          </h2>
          <p>
            Last updated: <strong>06/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          This guide is dedicated for modes that uses Stage Penalty system (or
          deficit). Currently, the Deficit system is applied in Campaign, Event,
          Tribe Tower, Lost Sector. You can learn more about stage penalty here:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Stage penalty"
            link="/nikke/guides/stage-penalty"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_penalty.png"
                alt="Stage penalty"
              />
            }
          />
        </Row>
        <p>
          <strong>
            This guide is served for new players, so they can understand the
            cards in their hand, and prepare for later stages of the game. If
            you are a veteran, chances are you have already known what is best
            for you.
          </strong>
        </p>
        <p>
          Now if you just roll a (bunch) of character(s), and you want to know
          whether you can form a good hand of 5 Nikkes, follow me down the
          rabbit’s hole. The deeper it is, the less teams will be available to
          push through, mind you!
        </p>
        <SectionHeader title="Before u go" />
        <p>
          If you are new players, chances are you won’t have all of these
          characters to fill in your roster, even at low deficit. Just remember
          those rules:
        </p>
        <ul className="bigger-margin">
          <li>
            If you don’t have any <strong>Burst I</strong> listed below, use{' '}
            <NikkeCharacter mode="inline" slug="n102" enablePopover />
          </li>
          <li>
            If you don’t have any <strong>Burst II</strong> listed below, use{' '}
            <NikkeCharacter mode="inline" slug="anis" enablePopover />
          </li>
          <li>
            If you need another <strong>Burst III</strong> to form at least 2
            Burst III team, use{' '}
            <NikkeCharacter mode="inline" slug="rapi" enablePopover />
          </li>
        </ul>
        <p>
          To understand the terms: Burst I, Burst II, Burst III, we highly
          recommend you to take a look at this Team Building Guide first:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Team building"
            link="/nikke/guides/team-building"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_8.png"
                alt="Team building"
              />
            }
          />
        </Row>
        <SectionHeader title="Surface" subtitle="0 - 5% Deficit" />
        <p>
          This is the “easy mode”, and mostly only new players, whale or veteran
          can enjoy. All of your Nikke will have stat reduction which ranges
          from 0 ~ 25% based on the deficit.{' '}
          <strong>Most, if not all teams work in this deficit.</strong> As long
          as you can put a proper team with at least 1 Burst I, 1 Burst II and
          one Burst III, you are good to go. Because there are too many teams
          that can be used here, let's dig further.
        </p>
        <SectionHeader title="First Dive" subtitle="5 - 10% Deficit" />
        <p>
          This is when it starts getting a little bit hot, but if you have the
          right setup, sometimes even ONE character, you can still push through
          them easily.
        </p>
        <h4>1. One-Nikke-army</h4>
        <p>
          These teams only require 1 CORE Nikke, and you can fill the 4 rest
          slots with your strongest characters in the{' '}
          <Link to="/nikke/tier-list/">tier list</Link>. The structure will be X
          - X - Core Nikke - X - X, for example:{' '}
        </p>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="n102" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapi" enablePopover />
            </div>
          </div>
        </div>
        <h5>Rapi: Red Hood</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="rapi-red-hood" enablePopover />
        </div>
        <p>
          The SSR version of our female protagonist brings shame to all current
          Nikkes ingame. She excels in both single target and AoE wiping, just
          like SBS (below) without the distributing damage fallback. She can
          even help you as a decent B1 CDR when you lack a proper team and just
          have her + another One Nikke Army below. Disadvantage? Require your
          device to run at 60 FPS consistently.
        </p>
        <h5>Modernia</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="modernia" enablePopover />
        </div>
        <p>
          If you can run your device (phone/PC) at 60 FPS consistently, Modernia
          is one of the best Story pushers for you. At this level of penalty,
          you can pretty much use any B1, B2 and Modernia carries everything
          with her sheer power alone.
        </p>
        <h5>Red Hood</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
        </div>
        <p>
          Same as Modernia, Red Hood is also one-Nikke-army on her own. The best
          part? She doesn’t even require 60 FPS. Throw whatever B1/B2 in this
          team and you are good at this level of penalty.
        </p>
        <h5>Scarlet: Black Shadow</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter
            mode="icon"
            slug="scarlet-black-shadow"
            enablePopover
          />
        </div>
        <p>
          SBS is one of the best screen wipers + single target DPS in the game
          at a low level of penalty like this. Best part? She can hit a target
          and everyone else on the screen just dies. Completely automatically,
          perfect!
        </p>
        <h5>Cinderella</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="cinderella" enablePopover />
        </div>
        <p>
          A Defender who is both tanky and dealing huge damage on her own. She
          is literally, a tank. Her only disadvantage is Cindy’s damage drops
          really quick when she’s not in full burst.
        </p>
        <h4>2. Tia + Naga</h4>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="tia" enablePopover />
          <NikkeCharacter mode="icon" slug="naga" enablePopover />
        </div>
        <p>
          The famous school girls of Missilis, known for their absurd “power”
          (whatever meaning you want). They are so strong that you can use any
          DPS with them and it still works wonderfully. Problem with them is,
          you need another Burst I here: Tia - Burst I - Naga - Burst III -
          Burst III.
        </p>
        <p>Example team:</p>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
        </div>
        <h4>3. Blanc + Noir</h4>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="blanc" enablePopover />
          <NikkeCharacter mode="icon" slug="noir" enablePopover />
        </div>
        <p>
          If Missilis has their duo, so does Tetra! In fact, Bunnies are the
          first duo introduced in Nikke. They heal more than Tia-Naga but
          provide less offensive potential. You may need to swap around these 2
          duo a lot later on, so if you have both pairs, raise them four!
        </p>
        <p>Example team:</p>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
        </div>
        <h4>4. Crown</h4>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="crown" enablePopover />
        </div>
        <p>
          Crown is the better Tia. Overall, her team looks exactly like the
          Tia-Naga team.
        </p>
        <p>Example team:</p>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="crown" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapi-red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Last time to auto" subtitle="10-20% Deficit" />
        <p>
          If you are a new player, you can still play Nikke automatically till
          around chapter 7 with this much deficit. Once you pass that point, you
          will find yourself losing A LOT by playing auto at this level of
          penalty (35 ~ 47% stat reduction).
        </p>
        <p>
          At this point, you won’t be able to use the “one-Nikke-army” teams
          anymore but need to draft proper ones. We will go through each of
          them.
        </p>
        <h4>1. Auto team</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapi-red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
            </div>
          </div>
        </div>
        <p>
          At this level, this team brings a lot to the table, and unless it’s a
          very nasty stage where a lot of oneshot mobs are spawned, you can use
          this team to play automatically. It’s balanced and well-rounded with
          heal, offensive, buffs.
        </p>
        <p>
          There are a few replacements for{' '}
          <NikkeCharacter mode="inline" slug="red-hood" enablePopover /> and{' '}
          <NikkeCharacter mode="inline" slug="rapi-red-hood" enablePopover />{' '}
          here:
        </p>
        <ul className="bigger-margin">
          <li>
            <NikkeCharacter mode="inline" slug="modernia" enablePopover /> is
            clapped hard by Rapi, both in single target and AoE, but if you
            don’t have Rapi, Modernia is still very good.
          </li>
          <li>
            <NikkeCharacter mode="inline" slug="cinderella" enablePopover /> is
            the better Scarlet. Huge wipe, strong DPS, and Blanc gives her
            everything she wants too (HP + Damage increase)
          </li>
          <li>
            <NikkeCharacter
              mode="inline"
              slug="scarlet-black-shadow"
              enablePopover
            />{' '}
            is a great choice, but when the penalty starts breathing on her
            neck, her clearing power is not as impressive as at lower penalty.
          </li>
        </ul>
        <p>
          <NikkeCharacter mode="inline" slug="liter" enablePopover /> can also
          be replaced if you don’t have her, of course:
        </p>
        <ul className="bigger-margin">
          <li>
            <NikkeCharacter mode="inline" slug="pepper" enablePopover /> is a
            decent healer with high offensive power. She doesn’t reduce
            cooldown, but she heals a lot with her burst.
          </li>
          <li>
            <NikkeCharacter mode="inline" slug="d-killer-wife" enablePopover />{' '}
            / <NikkeCharacter mode="inline" slug="rouge" enablePopover /> also
            provide Cooldown Reduction (CDR) just like Liter, except their other
            buffs feel pale compared to Liter.
          </li>
          <li>
            <NikkeCharacter mode="inline" slug="dorothy" enablePopover /> brings
            both CDR and offensive power on her own. Definitely a good
            alternative even if Noir holds her back a bit. Note that Dorothy is
            a Pilgrim so it’s not easy to roll her.
          </li>
          <li>
            <NikkeCharacter mode="inline" slug="n102" enablePopover /> is your
            go-to when you don’t have any of the above Nikke, go N102.
          </li>
        </ul>
        <h4>2. Crown Naga team</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="crown" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapi-red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This team is less comfy than Bunnies team above, but they can deal way
          more damage. By using this team, you may want to turn off “Auto Burst”
          and control burst time yourself, because these Nikkes have very
          impactful bursts that need to be used at proper time to make the most
          out of them.
        </p>
        <p>
          Same as above,
          <NikkeCharacter mode="inline" slug="red-hood" enablePopover />,{' '}
          <NikkeCharacter mode="inline" slug="rapi-red-hood" enablePopover />{' '}
          and <NikkeCharacter mode="inline" slug="liter" enablePopover /> can be
          replaced with above listed Nikkes. And if you don’t have Crown,
          there’s always a Tia, waiting behind…
        </p>
        <h4>3. Old “God team”</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
            </div>
          </div>
        </div>
        <p>
          They used to be the most braindead team in Nikke until Blanc-Noir were
          released. This team has wipe, strong DPS, CDR, a shield that blocks
          most instant death attacks once in a while, and a nice passive heal
          from <NikkeCharacter mode="inline" slug="rapunzel" enablePopover />{' '}
          that doesn’t even require her bursting. Rapunzel can even come in
          clutch with her revival burst, saving your run from a disaster.
          Heavily out-classed by the Bunnies team, but if your Bunnies are not
          completely, you can go this route.
        </p>
        <h4>4. Manual play with lots of clicking</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This is the traditional team pushing story. They have semi wipe with{' '}
          <NikkeCharacter mode="inline" slug="privaty" enablePopover /> (mostly,
          just stun), boss killing with{' '}
          <NikkeCharacter mode="inline" slug="alice" enablePopover /> (requires
          extremely high investment but she’s worth), and damage buffers with{' '}
          <NikkeCharacter mode="inline" slug="maxwell" enablePopover /> +{' '}
          <NikkeCharacter mode="inline" slug="liter" enablePopover /> +{' '}
          <NikkeCharacter mode="inline" slug="privaty" enablePopover />. Problem
          is, they require you to “aim” manually, and it’s not a good experience
          aiming those small flying Raptures from afar.{' '}
          <strong color="red">Highly NOT recommended</strong>, just putting them
          here as a tribute to the past.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Manual all the way" subtitle="20 ~ 30% Deficit" />
        <p>
          This is where most auto players have to stop. Enemies feel so tanky,
          enemies hit so hard, if you don’t cover and learn more advanced
          techniques, you are done for. Only a few teams can make their way to
          this deep, and they are:
        </p>
        <h4>1. Bunnies team</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapi-red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
            </div>
          </div>
        </div>
        <p>
          It all depends on the absolute number of Combat Power requirements. On
          low requirements like below 100k, you can still replace{' '}
          <NikkeCharacter mode="inline" slug="liter" enablePopover /> with a
          Good B1, but after that, Liter is very essential for the team unless
          you can somewhat lower the deficit.
        </p>
        <p>
          For <NikkeCharacter mode="inline" slug="red-hood" enablePopover /> and{' '}
          <NikkeCharacter mode="inline" slug="rapi-red-hood" enablePopover />,
          they are also set in stone. You only swap{' '}
          <NikkeCharacter mode="inline" slug="scarlet" enablePopover /> /{' '}
          <NikkeCharacter
            mode="inline"
            slug="scarlet-black-shadow"
            enablePopover
          />{' '}
          in certain stages where you need to wipe enemies very fast.{' '}
          <NikkeCharacter mode="inline" slug="red-hood" enablePopover /> can be
          replaced with{' '}
          <NikkeCharacter mode="inline" slug="alice" enablePopover />. At this
          point, you need to work on{' '}
          <NikkeCharacter mode="inline" slug="alice" enablePopover /> (10/4/10).
          She is not as versatile as{' '}
          <NikkeCharacter mode="inline" slug="red-hood" enablePopover /> but she
          is still one of the best DPSs on fully invested.{' '}
        </p>
        <h4>2. School Girls team</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
              <NikkeCharacter mode="icon" slug="crown" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapi-red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Same as above with more offensive power. School girls team is pretty
          much meta in all Story stages later on, with a few exceptions on the
          high healing demand stages, like against Bronchus. This team has a
          high risk variation, which will be mentioned later on.
        </p>
        <h4>3. Alice Maxwell team</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
        </div>
        <p>
          At this point, you will feel suffocated with this team. They tolerate
          no mistake because you can’t even wipe anything with{' '}
          <NikkeCharacter mode="inline" slug="privaty" enablePopover />. You
          only have a small window to use{' '}
          <NikkeCharacter mode="inline" slug="alice" enablePopover /> to shoot
          everything down, or they chew you alive. Most of the try hard veterans
          will have to retry a lot here for a good run.
        </p>
        <SectionHeader title="Pure Evil" subtitle="30 ~ 35% Deficit" />
        <p>
          Everything kills you. You take forever to kill enemies. Yes, welcome
          to the malicious level of penalty!
        </p>
        <h4>1. School Girls team</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
              <NikkeCharacter mode="icon" slug="crown" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapi-red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Surprisingly, this is where you start relying heavily on{' '}
          <NikkeCharacter mode="inline" slug="tia" enablePopover /> or{' '}
          <NikkeCharacter mode="inline" slug="crown" enablePopover /> and{' '}
          <NikkeCharacter mode="inline" slug="naga" enablePopover /> instead.
          Their offensive capabilities allow you to “cheese” through a lot of
          stages by killing enemies before they can kill you. And Crown even has
          a cheese mechanic that allows her to soak a lot of damage during a
          certain window (When she taunts, she’s immortal. Time the taunt well
          will reward you nicely)
        </p>
        <Row className="video">
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="EUw47er_HcA" />
          </Col>
        </Row>
        <p>
          Notice how he timed the Crown’s taunt to avoid the instant wipe phase
        </p>
        <h4>2. Improvised Alice Maxwell team</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="crown" enablePopover />
              <NikkeCharacter mode="icon" slug="grave" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
        </div>
        <p>
          It’s pretty much the old Alice Maxwell team, but this time, we
          replaced Centi with a stronger B2 buffer which is Crown or Grave. You
          will still have to aim one by one the same, so take care of your
          wrist.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Hell" subtitle="35 ~ 45% Deficit" />
        <p>
          At 35% deficit, you start questioning yourself, why do you keep doing
          this? What’s the reward for torturing yourself like this? If you still
          want to push, then there’s technically only 1 team left to use. It has
          a lot of variations so be sure to stick around for all of them.
        </p>
        <h4>1. No more Tia team - God Comp #1</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="crown" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
              <NikkeCharacter mode="icon" slug="d-killer-wife" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapi-red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
        </div>
        <p>
          To push at this level, not only you have to fully invest in these
          Nikkes, but also you have to rely on good Overload roll, and Elemental
          advantage.
        </p>
        <p>
          And of course, your own SKILLS! This is where you can brag among all
          other commanders how good you are, because you are pushing under a
          penalty that feels impossible.
        </p>
        <p>
          This Team is the best replacement (yes, replacement) for the
          Not-So-Normal Crown Team, and outperforms it (sometimes) on Defense
          and Base Defense Stages, or some particular stages where it feels
          necessary to need more than 2-3 Bursts, or it feels necessary to get
          the 3rd Burst faster. This team might also come into play when you
          want to use Red Hood as Burst 3 in Iron Element Weak Stages.
        </p>
        <p>
          Notice that we introduce D:Killer Wife here. It’s because after
          Chapter 23, you will notice using Liter will leave you with some odd
          4s cool-down after first burst to second burst. These 4s will, most of
          the time, force you to restart the fight because the game leaves you
          literally no room to wait between first and second burst.
        </p>
        <p>Example gameplay</p>
        <Row className="video">
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="3QxtSsOfVSQ" />
          </Col>
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="zOacKhNRVVM" />
          </Col>
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="-Pd125xxKP3c" />
          </Col>
        </Row>
        <h4>2. Not-So-Normal Crown Team - God Comp #2</h4>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="crown" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
        </div>
        <p>Key Point to Note:</p>
        <ul>
          <li>
            Red Hood is the Solo Burst I Unit, and DO NOT USE RAPI: The Hood
            here because she will be B1 version
          </li>
        </ul>
        <p>
          This team can deal with stages by relying on 2, or at maximum 3 full
          bursts to pass a stage.
        </p>
        <p>
          When you use{' '}
          <NikkeCharacter mode="inline" slug="red-hood" enablePopover /> as
          burst 1 only, you have an insanely high amount of attack buffs over
          10s, and you can even do it twice before she goes on cooldown. It
          allows you to bring 2 DPS, which will a lot of times be{' '}
          <NikkeCharacter mode="inline" slug="modernia" enablePopover /> + Nikke
          that matches the “Weak Element” of the Stage. Ideally, the B3 choices
          depend on the stage, and aren’t as easy as - this stage is Wind Weak,
          so I will pick{' '}
          <NikkeCharacter
            mode="inline"
            slug="scarlet-black-shadow"
            enablePopover
          />
        </p>
        <p>
          You want to study the stage step by step and see what best helps you
          progress from one wave to the other wave, and what the next wave
          requires. At times, you might need to i-frame to dodge Remnant
          Raptures, or hold your burst while your Modernia kills off Raptures
          outside of Burst.
        </p>
        <p>
          <strong>How to Use</strong>
        </p>
        <p>
          In a 2-Burst Win, you shouldn’t feel much different from using your
          usual teams. You might need to be more patient between your 2 bursts,
          and may need to kill raptures outside of Burst.{' '}
          <strong>
            In this, you use the first burst to clear waves, and the 2nd burst
            to focus the stage boss and kill it fast.
          </strong>{' '}
          A 2-burst may be used when the player wants the same Nikke to burst 2
          times, while the other Nikkes provide support damage.
        </p>
        <p>
          In a 3-Burst Clear, you use the first 2 Bursts for wave clearing, and
          then the 3rd burst to rush down and kill the boss. You should easily
          be able to do 2 bursts without any major hindrance. But after that, if
          you want to burst a 3rd time, you’ll face the trouble of
          <NikkeCharacter mode="inline" slug="red-hood" enablePopover />{' '}
          cooldown. This is something most people should be unfamiliar with if
          they’ve been using{' '}
          <NikkeCharacter mode="inline" slug="liter" enablePopover /> or other
          B1-CDR units as B1. TTo reach your 3rd burst, you will have to save
          one rapture from a wave by killing all enemies but one, and then
          covering all your Nikkes.
        </p>
        <p>
          Ideally, this should be a harmless rapture like a healer, buffer or
          elite that doesn’t do high damage, so that you can wait out 30 seconds
          after full burst ends, to have your burst ready again. Now we can
          uncover our Nikkes and kill the one rapture we left alive, and when
          the boss drops, we burst again and focus on the boss!
        </p>
        <h5>Point to note</h5>
        <p>
          Make sure that your{' '}
          <NikkeCharacter mode="inline" slug="red-hood" enablePopover /> does
          not have super high ATK on her overload gear! Otherwise she might
          steal <NikkeCharacter mode="inline" slug="naga" enablePopover /> skill
          2 from your main DPS when they need that extra 40% core hit bonus! If
          your
          <NikkeCharacter mode="inline" slug="red-hood" enablePopover /> happens
          to have too many ATK rolls, you might need to use specific Nikkes that
          boost their own ATK during burst so that they are able to benefit from
          <NikkeCharacter mode="inline" slug="naga" enablePopover /> CCore Hit
          buffs to the fullest! Just make sure that she doesn’t steal that core
          bonus from our beloved
          <NikkeCharacter mode="inline" slug="modernia" enablePopover />!
        </p>
        <p> Example of 3 Burst Stage Clear </p>
        <Row className="video">
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="Ko77VOTVf_Y" />
          </Col>
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="IBwZaX9IJDs" />
          </Col>
        </Row>
        <p>Example of how to use Crown’s Taunt effectively:</p>
        <Row className="video">
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="G89PKSH5d3s" />
          </Col>
        </Row>
        <h4>3. B3 choices for both God Comps</h4>
        <h5>B3 Spotlight: Modernia</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="modernia" enablePopover />
        </div>
        <p>
          <NikkeCharacter mode="inline" slug="crown" enablePopover /> and{' '}
          <NikkeCharacter mode="inline" slug="naga" enablePopover /> are core
          hit buffers, and
          <NikkeCharacter mode="inline" slug="modernia" enablePopover /> is one
          of the biggest benefitter of Naga's Core Hit Buffs out of Burst. Since
          she is a Machine Gun, she can hit the core of enemies with pin-point
          accuracy. Not only is her Burst getting{' '}
          <NikkeCharacter mode="inline" slug="red-hood" enablePopover /> ATK
          buff for 10s compared to{' '}
          <NikkeCharacter mode="inline" slug="liter" enablePopover /> 5s, her
          constant AoE during Burst targets the cores of all raptures it
          auto-locks on, hence benefitting from Tia and Naga to its fullest!
        </p>
        <p>
          When the boss drops, you will often notice that players do not burst
          with Modernia, and use the other Nikke’s Burst. This is because
          Modernia will provide the absolute best support damage to finish off a
          boss! This does not mean that Modernia will have a permanent spot as
          B3, but you will definitely see her being used 99% of the time!
        </p>
        <h5>General B3 Choices</h5>
        <ul className="bigger-margin">
          <li>
            <NikkeCharacter mode="inline" slug="scarlet" enablePopover /> : AoE
            wipe is a unique utility, the quality of which no other Nikke can
            match. Additionally, she has amazing DPS output, notably outside of
            Burst due to her constant self ATK Buff.
          </li>
          <li>
            <NikkeCharacter mode="inline" slug="alice" enablePopover /> : Alice
            provides high value to the squad, as her high DPS output Burst can
            be used for Boss Killing, as well as taking care of mob waves that
            are clumped together since she has pierce.
          </li>
          <li>
            <NikkeCharacter
              mode="inline"
              slug="asuka-shikinami-langley"
              enablePopover
            />{' '}
            : Asuka is a weaker Alice but she has an extremely big pierce AoE.
            We can even say that on cleaning mobs, Asuka should do better than
            Alice. And she also gives core damage to Fire DPS too, so at one
            point, you may remove Naga for Asuka instead. Collab Limited.
          </li>
          <li>
            <NikkeCharacter mode="inline" slug="rei-ayanami" enablePopover /> :
            The Scarlet version of Fire Element. She deals pretty good damage,
            sometimes matching with Modernia if Rei can burst. She acts both as
            DPS and buffer for Alice + Modernia, just like Asuka above. Also
            Collab Limited.
          </li>
          <li>
            <NikkeCharacter
              mode="inline"
              slug="scarlet-black-shadow"
              enablePopover
            />{' '}
            works well as a support screen-wipe, but her main focus will be to
            focus on nuking the stage boss “target”, after someone clears the
            mobs for her. The lower your penalty, the better she will perform
            for you. This does not mean, however, that she is incapable of
            wiping waves. If the waves come in numbers, but they drop slow
            enough and in smaller groups, SBS can perform majestically well.
          </li>
          <li>
            <NikkeCharacter mode="inline" slug="a2" enablePopover /> Her
            constant wave clearing ability is not to be underestimated. A2 is a
            unique unit that will also keep her buffs outside of Full Burst, and
            will be able to hit each rapture with her full strength, clearing
            the screen due to her huge splash radius. Collab Limited.
          </li>
        </ul>
        <h5>Situational Use B3's</h5>
        <ul className="bigger-margin">
          <li>
            <NikkeCharacter mode="inline" slug="snow-white" enablePopover /> is
            used to either nuke the boss instantly when it drops, or used to
            nuke a group of high HP elites that would otherwise do too much
            damage in a small amount of time.
          </li>
          <li>
            <NikkeCharacter mode="inline" slug="privaty" enablePopover /> is
            used for her unique Stun mechanic, while she also buffs ally ATK.
          </li>
          <li>
            <NikkeCharacter mode="inline" slug="maxwell" enablePopover /> is
            mostly used for her Buffs, but her high damage nuke can also take
            care of elites, or can even be used to nuke the boss.
          </li>
          <li>
            <NikkeCharacter
              mode="inline"
              slug="ludmilla-winter-owner"
              enablePopover
            />{' '}
            being an MG, can constantly hit the core of raptures, allowing her
            to enjoy Naga’s core benefits to the fullest.
          </li>
          <li>
            <NikkeCharacter mode="inline" slug="cinderella" enablePopover /> use
            case is very niche here, because as you can see from all the videos,
            players stalled a lot after 2nd Full Burst to wait for the 3rd one.
            During that window, Cinderella won’t have her S1 ẠTK Buff, which
            makes her deal 1 damage to enemies most of the time.
          </li>
          <li>
            <NikkeCharacter mode="inline" slug="red-hood" enablePopover /> may
            also be used as a Burst 3! But in very specific situations. This
            confirms the limit of your Bursts in the stage to 2. The use of this
            depends on the stage. You will sometimes find this useful in iron
            weak stages. Due to the nature of this suggestion, the following
            video reference should help understand how it works:
          </li>
        </ul>
        <Row className="video">
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="gpxWtuwo_bo" />
          </Col>
        </Row>
        <h5>Elemental Based Choices</h5>
        <h6>Against Iron weak stage:</h6>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="maxwell" enablePopover /> /
          <NikkeCharacter mode="icon" slug="snow-white" enablePopover /> /
          <NikkeCharacter mode="icon" slug="red-hood" enablePopover /> /
          <NikkeCharacter mode="icon" slug="modernia" enablePopover />
        </div>
        <h6>Against Fire weak stage:</h6>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="modernia" enablePopover /> /
          <NikkeCharacter mode="icon" slug="alice" enablePopover /> /
          <NikkeCharacter mode="icon" slug="a2" enablePopover /> /
          <NikkeCharacter
            mode="icon"
            slug="asuka-shikinami-langley"
            enablePopover
          />
        </div>
        <h6>Against Electric weak stage:</h6>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="modernia" enablePopover /> +{' '}
          <NikkeCharacter mode="icon" slug="ein" enablePopover /> /
          <NikkeCharacter mode="icon" slug="cinderella" enablePopover /> /
          <NikkeCharacter
            mode="icon"
            slug="sparkling-summer-anis"
            enablePopover
          />
        </div>
        <h6>Against Water weak stage:</h6>
        <div className="employee-container for-nikke">
          <NikkeCharacter
            mode="icon"
            slug="ludmilla-winter-owner"
            enablePopover
          />{' '}
          /
          <NikkeCharacter mode="icon" slug="modernia" enablePopover /> /
          <NikkeCharacter
            mode="icon"
            slug="quency-escape-queen"
            enablePopover
          />
        </div>
        <h6>Against Wind weak stage::</h6>
        <div className="employee-container for-nikke">
          <NikkeCharacter
            mode="icon"
            slug="scarlet-black-shadow"
            enablePopover
          />{' '}
          +
          <NikkeCharacter mode="icon" slug="alice" enablePopover /> /
          <NikkeCharacter mode="icon" slug="modernia" enablePopover />
        </div>
        <p>
          Anything beyond 45% is currently thought to be impossible, and at 50%,
          all of your stats are reduced by 99%, preventing you from going
          further. Congratulations, you have reached the bottom pit of hell!
          Give yourself a pat on the back, you deserve it.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesMetaTeamsPage;

export const Head: React.FC = () => (
  <Seo
    title="Meta teams (Story) | NIKKE | Prydwen Institute"
    description="A list of early and late game meta teams that will help you progress though the story in NIKKE."
    game="nikke"
  />
);
