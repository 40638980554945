/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { AKESkills } from '../data/endfield-skills';

interface IProps {
  id: string;
  character_rarity: string;
  type?: string;
}

export const EndfieldBasic: React.FC<IProps> = ({
  id,
  character_rarity,
  type
}) => {
  const [originalskillDesc, setOriginalskillDesc] = useState(
    AKESkills[id].description_basic
  );
  const [skillDesc, setSkillDesc] = useState(originalskillDesc);
  const [originalskillDesc2, setOriginalskillDesc2] = useState(
    AKESkills[id].description_dive
  );
  const [skillDesc2, setSkillDesc2] = useState(originalskillDesc);
  const [originalskillDesc3, setOriginalskillDesc3] = useState(
    AKESkills[id].description_dodge
  );
  const [skillDesc3, setSkillDesc3] = useState(originalskillDesc);
  const [originalskillDesc4, setOriginalskillDesc4] = useState(
    AKESkills[id].description_finisher
  );
  const [skillDesc4, setSkillDesc4] = useState(originalskillDesc);
  const [currentLevel, setCurrentLevel] = useState([1]);
  const [color, setColor] = useState('#979797');

  useEffect(() => {
    if (character_rarity === '4') {
      setColor('#8A5FCC');
    } else if (character_rarity === '5') {
      setColor('#B9AF28');
    } else if (character_rarity === '6') {
      setColor('#d57224;');
    }
  }, []);

  useEffect(() => {
    const newString = originalskillDesc
      .replaceAll(`Physical DMG`, `<u class="Physical">Physical DMG</u>`)
      .replaceAll(`Physical RES`, `<u class="Physical">Physical RES</u>`)
      .replaceAll(`Electric DMG`, `<u class="Electric">Electric DMG</u>`)
      .replaceAll(`Electric RES`, `<u class="Electric">Electric RES</u>`)
      .replaceAll(
        `Electric Infliction`,
        `<u class="Electric">Electric Infliction</u>`
      )
      .replaceAll(`Cryo DMG`, `<u class="Cryo">Cryo DMG</u>`)
      .replaceAll(`Cryo RES`, `<u class="Cryo">Cryo RES</u>`)
      .replaceAll(`Cryo Infliction`, `<u class="Cryo">Cryo Infliction</u>`)
      .replaceAll(`Nature DMG`, `<u class="Nature">Nature DMG</u>`)
      .replaceAll(`Nature RES`, `<u class="Nature">Nature RES</u>`)
      .replaceAll(
        `Nature Infliction`,
        `<u class="Nature">Nature Infliction</u>`
      )
      .replaceAll(`HP Treatment`, `<u class="Nature">HP Treatment</u>`)
      .replaceAll(`Heat DMG`, `<u class="Heat">Heat DMG</u>`)
      .replaceAll(`Heat RES`, `<u class="Heat">Heat RES</u>`)
      .replaceAll(`Heat Infliction`, `<u class="Heat">Heat Infliction</u>`)
      .replaceAll(`<br/>`, `<br />`);
    setSkillDesc(newString);

    const newString2 = originalskillDesc2
      .replaceAll(`Physical DMG`, `<u class="Physical">Physical DMG</u>`)
      .replaceAll(`Physical RES`, `<u class="Physical">Physical RES</u>`)
      .replaceAll(`Electric DMG`, `<u class="Electric">Electric DMG</u>`)
      .replaceAll(`Electric RES`, `<u class="Electric">Electric RES</u>`)
      .replaceAll(
        `Electric Infliction`,
        `<u class="Electric">Electric Infliction</u>`
      )
      .replaceAll(`Cryo DMG`, `<u class="Cryo">Cryo DMG</u>`)
      .replaceAll(`Cryo RES`, `<u class="Cryo">Cryo RES</u>`)
      .replaceAll(`Cryo Infliction`, `<u class="Cryo">Cryo Infliction</u>`)
      .replaceAll(`Nature DMG`, `<u class="Nature">Nature DMG</u>`)
      .replaceAll(`Nature RES`, `<u class="Nature">Nature RES</u>`)
      .replaceAll(
        `Nature Infliction`,
        `<u class="Nature">Nature Infliction</u>`
      )
      .replaceAll(`HP Treatment`, `<u class="Nature">HP Treatment</u>`)
      .replaceAll(`Heat DMG`, `<u class="Heat">Heat DMG</u>`)
      .replaceAll(`Heat RES`, `<u class="Heat">Heat RES</u>`)
      .replaceAll(`Heat Infliction`, `<u class="Heat">Heat Infliction</u>`)
      .replaceAll(`<br/>`, `<br />`);
    setSkillDesc2(newString2);

    const newString3 = originalskillDesc3
      .replaceAll(`Physical DMG`, `<u class="Physical">Physical DMG</u>`)
      .replaceAll(`Physical RES`, `<u class="Physical">Physical RES</u>`)
      .replaceAll(`Electric DMG`, `<u class="Electric">Electric DMG</u>`)
      .replaceAll(`Electric RES`, `<u class="Electric">Electric RES</u>`)
      .replaceAll(
        `Electric Infliction`,
        `<u class="Electric">Electric Infliction</u>`
      )
      .replaceAll(`Cryo DMG`, `<u class="Cryo">Cryo DMG</u>`)
      .replaceAll(`Cryo RES`, `<u class="Cryo">Cryo RES</u>`)
      .replaceAll(`Cryo Infliction`, `<u class="Cryo">Cryo Infliction</u>`)
      .replaceAll(`Nature DMG`, `<u class="Nature">Nature DMG</u>`)
      .replaceAll(`Nature RES`, `<u class="Nature">Nature RES</u>`)
      .replaceAll(
        `Nature Infliction`,
        `<u class="Nature">Nature Infliction</u>`
      )
      .replaceAll(`HP Treatment`, `<u class="Nature">HP Treatment</u>`)
      .replaceAll(`Heat DMG`, `<u class="Heat">Heat DMG</u>`)
      .replaceAll(`Heat RES`, `<u class="Heat">Heat RES</u>`)
      .replaceAll(`Heat Infliction`, `<u class="Heat">Heat Infliction</u>`)
      .replaceAll(`<br/>`, `<br />`);
    setSkillDesc3(newString3);

    const newString4 = originalskillDesc4
      .replaceAll(`Physical DMG`, `<u class="Physical">Physical DMG</u>`)
      .replaceAll(`Physical RES`, `<u class="Physical">Physical RES</u>`)
      .replaceAll(`Electric DMG`, `<u class="Electric">Electric DMG</u>`)
      .replaceAll(`Electric RES`, `<u class="Electric">Electric RES</u>`)
      .replaceAll(
        `Electric Infliction`,
        `<u class="Electric">Electric Infliction</u>`
      )
      .replaceAll(`Cryo DMG`, `<u class="Cryo">Cryo DMG</u>`)
      .replaceAll(`Cryo RES`, `<u class="Cryo">Cryo RES</u>`)
      .replaceAll(`Cryo Infliction`, `<u class="Cryo">Cryo Infliction</u>`)
      .replaceAll(`Nature DMG`, `<u class="Nature">Nature DMG</u>`)
      .replaceAll(`Nature RES`, `<u class="Nature">Nature RES</u>`)
      .replaceAll(
        `Nature Infliction`,
        `<u class="Nature">Nature Infliction</u>`
      )
      .replaceAll(`HP Treatment`, `<u class="Nature">HP Treatment</u>`)
      .replaceAll(`Heat DMG`, `<u class="Heat">Heat DMG</u>`)
      .replaceAll(`Heat RES`, `<u class="Heat">Heat RES</u>`)
      .replaceAll(`Heat Infliction`, `<u class="Heat">Heat Infliction</u>`)
      .replaceAll(`<br/>`, `<br />`);
    setSkillDesc4(newString4);
  }, []);
  return (
    <>
      <div className="skill-header">
        <div className={`skill-icon rarity-${character_rarity}`}>{type}</div>
        <div className="skill-info">
          <p className="skill-name">{AKESkills[id].name}</p>
        </div>
      </div>
      <div className={`skill-description`}>
        <h6 className="no-margin">BASIC ATTACK:</h6>
        <p
          dangerouslySetInnerHTML={{
            __html: skillDesc
          }}
        />
        <h6>DIVE ATTACK:</h6>
        <p
          dangerouslySetInnerHTML={{
            __html: skillDesc2
          }}
        />
        <h6>DODGE ATTACK:</h6>
        <p
          dangerouslySetInnerHTML={{
            __html: skillDesc3
          }}
        />
        <h6>FINISHER:</h6>
        <p
          dangerouslySetInnerHTML={{
            __html: skillDesc4
          }}
        />
      </div>
      {AKESkills[id].multi_basic.length > 0 && (
        <Accordion className="accordion-multis">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Multipliers (Rank 12)</Accordion.Header>
            <Accordion.Body>
              <div className="multipliers inside">
                {AKESkills[id].multi_basic.map((multi, index) => {
                  return (
                    <div className="multi-single" key={index}>
                      <div className="name">
                        BATK SEQ {index + 1} Multiplier
                      </div>
                      <div className="value">{multi}%</div>
                    </div>
                  );
                })}
                <div className="multi-single">
                  <div className="name">Dodge ATK Multiplier</div>
                  <div className="value">{AKESkills[id].multi_dodge}%</div>
                </div>
                <div className="multi-single">
                  <div className="name">Finisher ATK Multiplier</div>
                  <div className="value">{AKESkills[id].multi_finisher}%</div>
                </div>
                <div className="multi-single">
                  <div className="name">Dive ATK Multiplier</div>
                  <div className="value">{AKESkills[id].multi_dive}%</div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </>
  );
};
