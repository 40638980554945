export const AKETalents = {
  End_Combat_Talent_1: {
    name: 'The Guide',
    description:
      'Hitting at least <b>2</b> targets with the battle skill or ultimate shortens combo skill CD by <b>1.5/3s</b>.'
  },
  End_Combat_Talent_2: {
    name: 'Stasis Sequence',
    description:
      'Enemies sealed by Originium crystals take <b>10/20%</b> more Physical DMG.'
  },
  Ember_Combat_Talent_1: {
    name: 'Northern Tactics',
    description:
      'At above <b>50%</b> HP, ATK <b>+20/40%</b>. At below <b>50%</b> HP, DEF <b>+20/40%</b>.'
  },
  Ember_Combat_Talent_2: {
    name: 'Grievous Hatred',
    description:
      'When combo skill hits enemies, the affected targets have a <b>100%</b> chance to suffer DEF <b>-10/20%</b> for 8s.'
  },
  Ember_Base_Talent_1: {
    name: 'Factory Pioneer',
    description:
      'When assigned to the Manufacturing Cabin, increase its base production efficiency by <b>9/12%</b>.'
  },
  Ember_Base_Talent_2: {
    name: 'Expert MFG',
    description:
      'When assigned to the Manufacturing Cabin, slow Mood Drop of all operators in it by <b>14/18%</b>.'
  },
  Gilberta_Combat_Talent_1: {
    name: 'Fly High!',
    description:
      'Enemies successfully Lifted by Gilberta take <b>+15/30%</b> DMG for next <b>5s</b>.'
  },
  Gilberta_Combat_Talent_2: {
    name: 'Miss Navigator',
    description:
      'During battle, channeling the battle skill also treats nearby teammates for (<b>19/31</b> + Intellect × <b>0.3/0.5</b>) HP per second.'
  },
  Gilberta_Base_Talent_1: {
    name: 'Expert MFG',
    description:
      'When assigned to the Manufacturing Cabin, slow Mood Drop of all operators in it by <b>14/18%</b>.'
  },
  Gilberta_Base_Talent_2: {
    name: 'Factory Pioneer',
    description:
      'When assigned to the Manufacturing Cabin, increase its base production efficiency by <b>9/12%</b>.'
  },
  Lifeng_Combat_Talent_1: {
    name: 'Youthful Nature',
    description:
      'Each Intellect and Will grants another ATK <b>+0.10/0.15%</b> to the operator.'
  },
  Lifeng_Combat_Talent_2: {
    name: 'Intern of the Month',
    description:
      'Combo skill hits on the enemy restore another <b>4/8%</b> Ultimate Energy to the operator.'
  },
  Lifeng_Base_Talent_1: {
    name: 'Mycophile',
    description:
      'When assigned to the Growth Chamber, increase fungi growth rate by <b>12/16%</b>.'
  },
  Lifeng_Base_Talent_2: {
    name: 'Vitrophile',
    description:
      'When assigned to the Growth Chamber, increase vitros growth rate by <b>12/16%</b>.'
  },
  Wulfgard_Combat_Talent_1: {
    name: 'Scorching Rounds',
    description:
      'DMG dealt to Combusted enemies <b>+10/20%</b> + Agility × <b>0.05/0.10%</b>.'
  },
  Wulfgard_Combat_Talent_2: {
    name: 'Relentless Predator',
    description:
      'Every enemy defeated by Wulfgard shortens combo skill CD by <b>10/16%</b>.'
  },
  Wulfgard_Base_Talent_1: {
    name: 'Factory Pioneer',
    description:
      'When assigned to the Manufacturing Cabin, increase its base production efficiency by <b>6/9%</b>.'
  },
  Wulfgard_Base_Talent_2: {
    name: 'Expert Grower',
    description:
      'When assigned to the Growth Chamber, slow Mood Drop of all operators in it by <b>14/18%</b>.'
  },
  Xaihi_Combat_Talent_1: {
    name: 'Execute Process',
    description:
      'Upon entering battle, the first casting of battle skill returns <b>20/35</b> SP.'
  },
  Xaihi_Combat_Talent_2: {
    name: 'Freeze Protocol',
    description:
      "When Xaihi inflicts Solidification, further reduce the Solidified target's Physical Res by <b>8/15%</b>."
  },
  Xaihi_Base_Talent_1: {
    name: 'Inspire',
    description:
      'When assigned to the Control Nexus, increase all operators Mood Regen by <b>18/24%</b>.'
  },
  Xaihi_Base_Talent_2: {
    name: 'Vitrophile',
    description:
      'When assigned to the Growth Chamber, increase vitros growth rate by <b>12/16%</b>.'
  },
  Chen_Combat_Talent_1: {
    name: 'Slashing edge',
    description:
      'Every enemy hit with skills gives ATK <b>+4/8%</b> for <b>10s</b>. Max stacks: <b>5</b>.'
  },
  Chen_Combat_Talent_2: {
    name: 'Momentum Breaker',
    description:
      "Interrupting the enemy's charging with skills deals another <u>[10 Stagger]</u>."
  },
  Chen_Base_Talent_1: {
    name: 'Expert MFG  ',
    description:
      'When assigned to the Manufacturing Cabin, slow Mood Drop of all operators in it by <b>10/14%</b>.'
  },
  Chen_Base_Talent_2: {
    name: 'Mycophile',
    description:
      'When assigned to the Growth Chamber, increase fungi growth rate by <b>12/16%</b>.'
  },
  Arclight_Combat_Talent_1: {
    name: 'Wildland Trekker',
    description:
      'Every <b>15</b> Agility grants <b>+0.5/1.0%</b> Physical DMG dealt. Every <b>15</b> Intellect grants <b>+0.5/1.0%</b> Electric DMG dealt.'
  },
  Arclight_Combat_Talent_2: {
    name: 'Fiannæ Wisdom',
    description:
      'When restoring SP with her own skills, every teammate gains ATK <b>+8/15%</b> for <b>10s</b>. Max stacks: <b>1</b>.'
  },
  Arclight_Base_Talent_1: {
    name: 'Inspire',
    description:
      'When assigned to the Control Nexus, increase all operators Mood Regen by <b>18/24%</b>.'
  },
  Arclight_Base_Talent_2: {
    name: 'Mineralphile',
    description:
      'When assigned to the Growth Chamber, increase mineral growth rate by <b>12/16%</b>.'
  },
  Panda_Combat_Talent_1: {
    name: 'Reduce and Thicken',
    description: 'DMG dealt to enemies with ongoing Physical Status +20/30%.'
  },
  Panda_Combat_Talent_2: {
    name: 'Salty or Mild',
    description:
      'Hitting enemies with Final Strike restores 2/3% Max HP to the operator.'
  },
  Panda_Base_Talent_1: {
    name: 'Vitrophile',
    description:
      'When assigned to the Growth Chamber, increase vitros growth rate by <b>8/12%</b>.'
  },
  Panda_Base_Talent_2: {
    name: 'Factory Pioneer',
    description:
      'When assigned to the Manufacturing Cabin, increase its base production efficiency by <b>9/12%</b>.'
  },
  Snowshine_Combat_Talent_1: {
    name: 'Polar Survival',
    description:
      'When Snowshine is present, for every surviving Defender in the team, team gains DEF <b>+5/10%</b>.'
  },
  Snowshine_Combat_Talent_2: {
    name: 'SAR Professional',
    description:
      'For targets below <b>50%</b> HP, Snowshine gains Treatment Effect <b>+25/50%</b>.'
  },
  Snowshine_Base_Talent_1: {
    name: 'Factory Pioneer',
    description:
      'When assigned to the Manufacturing Cabin, increase its base production efficiency by <b>6/9%</b>.'
  },
  Snowshine_Base_Talent_2: {
    name: 'Inspire',
    description:
      'When assigned to the Control Nexus, increase all operators Mood Regen by <b>24/30%</b>.'
  },
  Perlica_Combat_Talent_1: {
    name: 'Obliteration Protocol',
    description: 'Deals <b>20/30%</b> bonus DMH to Staggered enemies.'
  },
  Perlica_Combat_Talent_2: {
    name: 'Cycle Protocol',
    description:
      'Combo skill hits on the enemy restore another <b>10/15</b> SP.'
  },
  Perlica_Base_Talent_1: {
    name: 'Inspire',
    description:
      'When assigned to the Control Nexus, increase all operators Mood Regen by <b>18/24%</b>.'
  },
  Perlica_Base_Talent_2: {
    name: 'Mycophile',
    description:
      'When assigned to the Growth Chamber, increase fungi growth rate by <b>12/16%</b>.'
  },
  Laevatain_Combat_Talent_1: {
    name: 'Liquefy Armor',
    description: 'DMG dealt ignores <b>10/15%</b> Heat RES of the enemy.'
  },
  Laevatain_Combat_Talent_2: {
    name: 'Volatile Burst',
    description:
      'After the operator triggers a Heat Burst, she gains Heat Burst DMG <b>+10/15%</b> for <b>10s</b>. (Max Stacks: <b>5</b>)'
  },
  Laevatain_Base_Talent_1: {
    name: '  Expert Grower',
    description:
      'When assigned to the Growth Chamber, slow Mood Drop of all operators in it by <b>14/18%</b>.'
  },
  Laevatain_Base_Talent_2: {
    name: 'Mineralphile',
    description:
      'When assigned to the Growth Chamber, increase mineral growth rate by <b>12/16%</b>.'
  },
  Avywenna_Combat_Talent_1: {
    name: 'Armed Messenger',
    description:
      'When any operator in the team performs a finisher on a target, the target also suffers Electric RES <b>-6/12%</b> for <b>15s</b>.'
  },
  Avywenna_Combat_Talent_2: {
    name: 'Occupational Legacy',
    description:
      'When battle skill retrieves a Thunderlance that struck the enemy, operator gains <b>3/5</b> SP. The Thunderlance EX generated by the ultimate gains a <b>3x</b> multiplier to its effect.'
  },
  Avywenna_Base_Talent_1: {
    name: 'Vitrophile',
    description:
      'When assigned to the Growth Chamber, increase vitros growth rate by <b>8/12%</b>.'
  },
  Avywenna_Base_Talent_2: {
    name: 'Inspire',
    description:
      'When assigned to the Control Nexus, increase all operators Mood Regen by <b>24/30%</b>.'
  },
  Yvonne_Combat_Talent_1: {
    name: 'Freezing Point',
    description:
      'Deals <b>+10/20%</b> DMG to enemies with active Cryo Infliction. Effect is doubled against Solidified enemies.'
  },
  Yvonne_Combat_Talent_2: {
    name: 'Novel Technology',
    description:
      'When Yvonne is present, all Casters in the team gain Critical DMG <b>+10/20%</b>.'
  },
  Yvonne_Base_Talent_1: {
    name: 'Mineralphile',
    description:
      'When assigned to the Growth Chamber, increase mineral growth rate by <b>12/16%</b>.'
  },
  Yvonne_Base_Talent_2: {
    name: 'Expert MFG',
    description:
      'When assigned to the Manufacturing Cabin, slow Mood Drop of all operators in it by <b>14/18%</b>.'
  }
};
