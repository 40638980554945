import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { Row, Col } from 'react-bootstrap';

const AFKGuidesTalents: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Talents - Season 3</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_talents.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Talents - Season 3</h1>
          <h2>
            An explanation for the Season 3 Talents system in AFK Journey.
          </h2>
          <p>
            Last updated: <strong>16/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Faction Talents" />
        <StaticImage
          src="../../../images/afk/generic/talents_2.webp"
          alt="Guides"
        />
        <p>
          Faction Talents are special buffs replacing Faction Bonuses in certain
          game modes. They provide stat boosts to all heroes and create Talent
          Buff Tiles, turning placed heroes into Prime Carriers with additional
          skills.{' '}
          <strong>
            At least 3 heroes from the same faction are needed to activate the
            Talent bonus
          </strong>
          , but Celestial and Hypogeans heroes don't count as they have their
          own Faction Talent tree - so you're not able to bypass the
          requirements like with the normal Faction bonuses.
        </p>
        <h5>How to upgrade Talents?</h5>
        <p>
          As you advance through your Season Resonance Levels and AFK Stages,
          you earn <strong>Talent Orbs and Talent Essences</strong>. The first
          two upgrades of each Faction Talent are free - then Stage 1 and 2
          require Talent Orbs, and Stage 3 and 4 need Talent Essences.
        </p>
        <p>
          To unlock the next Stage, you must accumulatively activate a set
          number of Talents from all faction groups - this means that you can't
          just hyper-invest into a single faction and need to split your focus
          between them.
        </p>
        <h5>Are the Talents active everywhere?</h5>
        <p>No. The new Talent system can be used only in those modes:</p>
        <ul>
          <li>Talent Challenge AFK Stages,</li>
          <li>Legend Trials,</li>
          <li>Supreme Arena,</li>
          <li>Dura Trials,</li>
          <li>Battle Drill.</li>
        </ul>
        <p>
          So Arena and Dream Realm still use the old talent system, not the new
          one.
        </p>
        <h5>Can I change the Prime Carrier slot tile?</h5>
        <StaticImage
          src="../../../images/afk/generic/talents_1.webp"
          alt="Guides"
        />
        <p>
          No. The Prime Carrier slot always appears in the same slot (check
          picture above for a backline placement example).
        </p>
        <h5>So only the Prime Carrier benefits from the tree buffs?</h5>
        <p>
          All team members benefit from the 'smaller' nodes bonuses that give
          passive stats like Haste or ASPD, but only the Prime Carrier can make
          use of the 'big' talent.
        </p>
        <p>
          Also, at later Stages, you will unlock a secondary Prime Carrier slot,
          allowing two characters to benefit from the 'big' talent.
        </p>
        <h5>Can I reset the tree if I make an mistake?</h5>
        <p>
          Yes! You can reset Faction Talents for free every 7 days or use a
          Talent Reshaper and it will refund all of your spent Talent Orbs and
          Talent Essences used.
        </p>
        <SectionHeader title="Big Faction Talents - Season 3" />
        <div className="afk-factions-table talents">
          <div className="faction-div Lightbearer">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_light.png"
                alt="Lightbearers"
                className="faction-icon"
              />{' '}
              Lightbearers
            </h5>
            <div className="info">
              <p>
                <strong>Big Talent:</strong> When a battle starts, Hogan
                descends from the sky and lands in front of the frontmost enemy,
                dealing damage equal to 170% of his ATK to enemies within 2
                tiles and stunning them for 1.5s. Hogan inherits 10% of Team ATK
                and 100% of Team HP, along with an extra boost equal to 25% of
                the prime carrier's base ATK and 70% of base max HP.
              </p>
              <p>Upgrades:</p>
              <ul>
                <li>
                  <strong>Stage 1:</strong> Heals Hogan by an amount equal to
                  90% of the damage dealt by the prime carrier.
                </li>
                <li>
                  <strong>Stage 2:</strong> Reduces Hogan's damage taken by 40%
                  during battle, and the prime carrier recovers Energy equal to
                  25% of Hogan's Energy on Hit.
                </li>
                <li>
                  <strong>Stage 3:</strong> Hogan activates Epic Domination,
                  increasing ATK by 35% for himself and the prime carrier for
                  12s.
                </li>
              </ul>
            </div>
          </div>
          <div className="faction-div Mauler">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_mauler.png"
                alt="Mauler"
                className="faction-icon"
              />{' '}
              Maulers
            </h5>
            <div className="info">
              <p>
                <strong>Big Talent:</strong> 4s into a battle, allows the prime
                carrier to mark the enemy that has dealt the most cumulative
                damage as Prey. The prime carrier then teleports next to their
                Prey, knocking them down while dealing 220% physical damage.
                This strike also knocks back enemies within tile of the Prey by
                tile. The prime carrier takes 40% less damage from attacks by
                their Prey.
              </p>
              <p>Upgrades:</p>
              <ul>
                <li>
                  <strong>Stage 1</strong>: Increases the prime carrier's Life
                  Drain by 8. Increases their Life Drain by an extra 5 for every
                  10% HP lost.
                </li>
                <li>
                  <strong>Stage 2</strong>: Deals 50% physical damage to
                  adjacent enemies each time the prime carrier takes cumulative
                  base damage from an enemy, equal to 400% of the enemy's ATK.
                </li>
                <li>
                  <strong>Stage 3</strong>: Increases all allies' ATK by 15% and
                  their Haste by 30 for 15s whenever a prime carrier's Prey is
                  defeated. This effect is active as long as the prime carrier
                  remains in battle.
                </li>
              </ul>
            </div>
          </div>
          <div className="faction-div Wilder">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_wilder.png"
                alt="Wilder"
                className="faction-icon"
              />{' '}
              Wilders
            </h5>
            <div className="info">
              <p>
                <strong>Big Talent:</strong> Allows the prime carrier to enter
                the Concealed state when a battle starts. While Concealed, the
                prime carrier becomes Invincible and deals 18% increased damage
                with non-Ultimate skills, lasting for 8s. Each time a
                non-summoned ally is defeated, the Concealed state duration is
                reduced by 3s.
              </p>
              <p>Upgrades:</p>
              <ul>
                <li>
                  <strong>Stage 1</strong>: Allows the prime carrier to enter
                  Concealed state for 3s after assisting or defeating a
                  non-summoned enemy for the first time.
                </li>
                <li>
                  <strong>Stage 2</strong>: After Concealed state ends, grants
                  the prime carrier a shield equal to 22% of the damage dealt
                  while in Concealed state.
                </li>
                <li>
                  <strong>Stage 3</strong>: Allows the prime carrier's attacks
                  to trigger special effects while in Concealed state. When
                  triggered, the prime carrier's attack deals an extra 80% true
                  damage and can stun the enemy for 0.5s. This effect can be
                  triggered once every 4s on each prime carrier.
                </li>
              </ul>
            </div>
          </div>
          <div className="faction-div Graveborn">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_grave.png"
                alt="Graveborn"
                className="faction-icon"
              />{' '}
              Graveborn
            </h5>
            <div className="info">
              <p>
                <strong>Big Talent:</strong> When a battle starts, seizes the
                soul of the rearmost non-summoned enemy for the prime carrier,
                instantly causing the enemy to lose 45% HP (up to 800% of the
                prime carrier's ATK) and stealing 6% of the enemy's ATK. Over
                the next 15s, the enemy gradually recovers, restoring their lost
                HP and eventually regaining their stolen ATK.
              </p>
              <p>Upgrades:</p>
              <ul>
                <li>
                  <strong>Stage 1</strong>: Allows the prime carrier to deal 18%
                  more damage to the enemy whose soul they have seized, until
                  the enemy fully reclaims their soul.
                </li>
                <li>
                  <strong>Stage 2</strong>: If an enemy whose soul was seized is
                  defeated, triggers a soul explosion that deals magic damage to
                  surrounding enemies equal to 280% of the prime carrier.
                </li>
                <li>
                  <strong>Stage 3</strong>: When seizing the soul of an enemy,
                  grants the prime carrier a shield equal to 100% of the HP lost
                  by that enemy. The shield value gradually decreases over 15s
                  and stops decreasing if that enemy is defeated.
                </li>
              </ul>
            </div>
          </div>
          <div className="faction-div Celestial">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_cele.png"
                alt="Celestial"
                className="faction-icon"
              />{' '}
              Celestials +&nbsp;{' '}
              <StaticImage
                src="../../../images/afk/icons/faction_hypo.png"
                alt="Hypogean"
                className="faction-icon"
              />{' '}
              Hypogeans
            </h5>
            <div className="info">
              <p>
                <strong>Big Talent:</strong> When a battle starts, grants the
                prime carrier the oracle's blessing, restoring their Energy by
                320. After allies cast a total of 4 Ultimates, grants the prime
                carrier with divine power, increasing their ATK by 10% and max
                HP by 20%.
              </p>
              <p>Upgrades:</p>
              <ul>
                <li>
                  <strong>Stage 1</strong>: When a battle starts, restores 100
                  Energy to all allies who were placed within 1 tile of the
                  prime carrier during battle preparation.
                </li>
                <li>
                  <strong>Stage 2</strong>: Increases the damage of the prime
                  carrier's first Ultimate by 25%.
                </li>
                <li>
                  <strong>Stage 3</strong>: Grants the prime carrier chaotic
                  power. When they cast their first Ultimate, triggers a dark
                  explosion in an 2-tile area with the most enemies, dealing
                  250% magic damage and causing the enemies hit to lose 200
                  Energy.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <SectionHeader title="Season 3 Talents Reviewed" />
        <div className="faction-div Lightbearer">
          <h5>
            <StaticImage
              src="../../../images/afk/icons/faction_light.png"
              alt="Lightbearers"
              className="faction-icon"
            />{' '}
            Lightbearers
          </h5>
          <div className="info">
            <Row className="pros-cons">
              <Col sm="12" md="6">
                <div className="box pros">
                  <h5>Pros</h5>
                  <hr />
                  <div className="raw list">
                    <ul>
                      <li>Hogan is a reliable tank for taking damage.</li>
                      <li>Prime Carrier damage can heal Hogan.</li>
                      <li>Stun from Hogan’s descent can disrupt frontline.</li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col sm="12" md="6">
                <div className="box cons">
                  <h5>Cons</h5>
                  <hr />
                  <div className="raw list">
                    <ul>
                      <li>Hogan doesn’t deal that much damage.</li>
                      <li>
                        Hogan doesn’t provide any utility other than his descent
                        and tankiness.
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="heroes">
            <h5>Best Used For</h5>
            <p>Talent Trials and Supreme Arena</p>
            <h5>Best Characters for this Talent</h5>
            <div className="employees-container afk-cards">
              <AFKCharacter
                mode="card"
                slug="odie"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="sonja"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="tasi"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="talene"
                showLabel
                showTags
                showIcon
                enablePopover
              />
            </div>
          </div>
          <br />
          <h5>Example team</h5>
          <StaticImage
            src="../../../images/afk/generic/tal_1.webp"
            alt="Guides"
          />
        </div>
        <hr />
        <div className="faction-div Mauler">
          <h5>
            <StaticImage
              src="../../../images/afk/icons/faction_mauler.png"
              alt="Mauler"
              className="faction-icon"
            />{' '}
            Maulers
          </h5>
          <div className="info">
            <Row className="pros-cons">
              <Col sm="12" md="6">
                <div className="box pros">
                  <h5>Pros</h5>
                  <hr />
                  <div className="raw list">
                    <ul>
                      <li>
                        Greatly boosts survivability for one frontline
                        character.
                      </li>
                      <li>
                        Prime Carrier deals damage to nearby enemies when taking
                        damage.
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col sm="12" md="6">
                <div className="box cons">
                  <h5>Cons</h5>
                  <hr />
                  <div className="raw list">
                    <ul>
                      <li>
                        Most of the time it doesn't do what its intended
                        purpose, which is to have a character go to a DPS enemy
                        and distract it.
                      </li>
                      <li>
                        Life Drain is not that amazing of a stat to have
                        especially when you have dedicated healers.
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="heroes">
            <h5>Best Used For</h5>
            <p>Supreme Arena</p>
            <h5>Best Characters for this Talent</h5>
            <div className="employees-container afk-cards">
              <AFKCharacter
                mode="card"
                slug="phraesto"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="brutus"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="lumont"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="antandra"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="lucca"
                showLabel
                showTags
                showIcon
                enablePopover
              />
            </div>
          </div>
          <br />
          <h5>Example team</h5>
          <StaticImage
            src="../../../images/afk/generic/tal_2.webp"
            alt="Guides"
          />
        </div>
        <hr />
        <div className="faction-div Wilder">
          <h5>
            <StaticImage
              src="../../../images/afk/icons/faction_wilder.png"
              alt="Wilder"
              className="faction-icon"
            />{' '}
            Wilders
          </h5>
          <div className="info">
            <Row className="pros-cons">
              <Col sm="12" md="6">
                <div className="box pros">
                  <h5>Pros</h5>
                  <hr />
                  <div className="raw list">
                    <ul>
                      <li>
                        Can be used to protect a DPS or Support from being
                        bursted down.
                      </li>
                      <li>
                        Can be used to to safely DPS whilst charging ultimate.
                      </li>
                      <li>Boosts non-Ultimate damage.</li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col sm="12" md="6">
                <div className="box cons">
                  <h5>Cons</h5>
                  <hr />
                  <div className="raw list">
                    <ul>
                      <li>
                        Doesn’t provide that much defensive effects other than
                        giving the Prime Carrier a shield.
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="heroes">
            <h5>Best Used For</h5>
            <p>Talent Trials and Supreme Arena</p>
            <h5>Best Characters for this Talent</h5>
            <div className="employees-container afk-cards">
              <AFKCharacter
                mode="card"
                slug="carolina"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="bonnie"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="arden"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="lorsan"
                showLabel
                showTags
                showIcon
                enablePopover
              />
            </div>
          </div>
          <br />
          <h5>Example team</h5>
          <StaticImage
            src="../../../images/afk/generic/tal_3.webp"
            alt="Guides"
          />
        </div>
        <hr />
        <div className="faction-div Graveborn">
          <h5>
            <StaticImage
              src="../../../images/afk/icons/faction_grave.png"
              alt="Graveborn"
              className="faction-icon"
            />{' '}
            Graveborn
          </h5>
          <div className="info">
            <Row className="pros-cons">
              <Col sm="12" md="6">
                <div className="box pros">
                  <h5>Pros</h5>
                  <hr />
                  <div className="raw list">
                    <ul>
                      <li>
                        Greatly increases Prime Carrier’s DPS by stealing a
                        backline enemy’s HP and ATK whilst boosting the damage
                        done to it.
                      </li>
                      <li>
                        Creates an explosion if the marked enemy dies that deals
                        damage to other nearby enemies.
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col sm="12" md="6">
                <div className="box cons">
                  <h5>Cons</h5>
                  <hr />
                  <div className="raw list">
                    <ul>
                      <li>
                        Not that good for pushing AFK stages, as there are
                        better options for boosting damage and utility.
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="heroes">
            <h5>Best Used For</h5>
            <p>Supreme Arena</p>
            <h5>Best Characters for this Talent</h5>
            <div className="employees-container afk-cards">
              <AFKCharacter
                mode="card"
                slug="nara"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="harak"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="bonnie"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="carolina"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="lily-may"
                showLabel
                showTags
                showIcon
                enablePopover
              />
            </div>
          </div>
          <br />
          <h5>Example team</h5>
          <StaticImage
            src="../../../images/afk/generic/tal_4.webp"
            alt="Guides"
          />
        </div>
        <hr />
        <div className="faction-div Celestial">
          <h5>
            <StaticImage
              src="../../../images/afk/icons/faction_cele.png"
              alt="Celestial"
              className="faction-icon"
            />{' '}
            Celestials +&nbsp;{' '}
            <StaticImage
              src="../../../images/afk/icons/faction_hypo.png"
              alt="Hypogean"
              className="faction-icon"
            />{' '}
            Hypogeans
          </h5>
          <div className="info">
            <Row className="pros-cons">
              <Col sm="12" md="6">
                <div className="box pros">
                  <h5>Pros</h5>
                  <hr />
                  <div className="raw list">
                    <ul>
                      <li>
                        Power charges 1/2 allies with Energy, ATK and extra Max
                        HP.
                      </li>
                      <li>
                        Prime Carrier/s can charge energy for allies close to
                        them, which should always be Supports or DPS heroes.
                      </li>
                      <li>
                        Enhancing Prime Carrier/s Ultimate Damage and giving the
                        ability to reduce enemy energy which is very useful for
                        Supreme Arena.
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col sm="12" md="6">
                <div className="box cons">
                  <h5>Cons</h5>
                  <hr />
                  <div className="raw list">
                    <ul>
                      <li>
                        Not that useful in PVE content as there are better
                        options for utility.
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="heroes">
            <h5>Best Used For</h5>
            <p>Supreme Arena</p>
            <h5>Best Characters for this Talent</h5>
            <div className="employees-container afk-cards">
              <AFKCharacter
                mode="card"
                slug="talene"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="harak"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="tasi"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="carolina"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="arden"
                showLabel
                showTags
                showIcon
                enablePopover
              />
            </div>
          </div>
          <br />
          <h5>Example team</h5>
          <StaticImage
            src="../../../images/afk/generic/tal_5.webp"
            alt="Guides"
          />
        </div>
        <SectionHeader title="Which Talent Should Be Leveled First?" />
        <h5>PVE</h5>
        <p>
          Investing in <strong>Lightbearer Faction Talent</strong> is worthwhile
          as Hogan acts as a huge wall for your allies, so you can easily take
          huge hits from enemies in high deficit AFK Stages and other PVE
          content. <strong>Wilder Faction Talent</strong> is also another great
          pick as you can protect a hero from being targeted or even damaged
          early while letting them deal damage to enemies.{' '}
          <strong>Celestial/Hypogean Faction Talent</strong> gets an honorable
          mention on stages which you want to reduce enemy energy to lock them
          from using their ultimates.
        </p>
        <h5>PVP</h5>
        <p>
          The main Faction Talent to go for in the Supreme Arena is the{' '}
          <strong>Celestial/Hypogean Faction Talent</strong> as having to charge
          ally energy whilst reducing the enemy’s is very valuable so that you
          get the advantage of using ultimate skills faster. For F2P/Low
          Spenders who cannot get hold on these Celestial/Hypogean heroes for
          Supreme Arena, the <strong>Wilder Faction Talent</strong> is a great
          alternative for 1 or 2 heroes in the team that can receive
          invincibility from enemy attacks early on then it can reset on assist
          or kill from an enemy.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesTalents;

export const Head: React.FC = () => (
  <Seo
    title="Talents - Season 3 | AFK Journey | Prydwen Institute"
    description="An explanation for the Season 3 Talents system in AFK Journey."
    game="afk"
  />
);
