export const ZZZAssault_1 = [
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 14.49,
    rank: 1,
    avg_round: 31867,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 14.33,
    rank: 2,
    avg_round: 23883,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 7.1,
    rank: 3,
    avg_round: 28804,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 5.77,
    rank: 4,
    avg_round: 28256,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 4.45,
    rank: 5,
    avg_round: 26831,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 3.46,
    rank: 6,
    avg_round: 21713,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 2.92,
    rank: 7,
    avg_round: 26824,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'soukaku',
    app_rate: 2.81,
    rank: 8,
    avg_round: 25597,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 2.4,
    rank: 9,
    avg_round: 29117,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 1.5,
    rank: 10,
    avg_round: 21790,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 1.5,
    rank: 10,
    avg_round: 22631,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 1.39,
    rank: 12,
    avg_round: 24278,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 1.35,
    rank: 13,
    avg_round: 23578,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 1.1,
    rank: 14,
    avg_round: 13037,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'soukaku',
    app_rate: 0.92,
    rank: 15,
    avg_round: 22527,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'burnice',
    app_rate: 0.9,
    rank: 16,
    avg_round: 28898,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'soukaku',
    app_rate: 0.83,
    rank: 17,
    avg_round: 17921,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.81,
    rank: 18,
    avg_round: 20617,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.74,
    rank: 19,
    avg_round: 22027,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'lucy',
    app_rate: 0.72,
    rank: 20,
    avg_round: 22052,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.7,
    rank: 21,
    avg_round: 21572,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'soukaku',
    app_rate: 0.63,
    rank: 22,
    avg_round: 16520,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'soukaku',
    app_rate: 0.61,
    rank: 23,
    avg_round: 22675,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.56,
    rank: 24,
    avg_round: 29810,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'seth',
    app_rate: 0.54,
    rank: 25,
    avg_round: 20822,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 0.49,
    rank: 26,
    avg_round: 16016,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.47,
    rank: 27,
    avg_round: 33404,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'seth',
    app_rate: 0.45,
    rank: 28,
    avg_round: 19516,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.43,
    rank: 29,
    avg_round: 24225,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lycaon',
    app_rate: 0.43,
    rank: 29,
    avg_round: 24993,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.4,
    rank: 31,
    avg_round: 22422,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.38,
    rank: 32,
    avg_round: 14625,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'lighter',
    app_rate: 0.38,
    rank: 32,
    avg_round: 17327,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.36,
    rank: 34,
    avg_round: 23552,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.36,
    rank: 34,
    avg_round: 18096,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 0.36,
    rank: 34,
    avg_round: 15268,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.36,
    rank: 34,
    avg_round: 23855,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.34,
    rank: 38,
    avg_round: 27286,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.34,
    rank: 38,
    avg_round: 19251,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.34,
    rank: 38,
    avg_round: 22738,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.31,
    rank: 41,
    avg_round: 22033,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.29,
    rank: 42,
    avg_round: 15454,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.29,
    rank: 42,
    avg_round: 16698,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.27,
    rank: 44,
    avg_round: 17731,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'soukaku',
    app_rate: 0.25,
    rank: 45,
    avg_round: 20875,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.25,
    rank: 45,
    avg_round: 21759,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'rina',
    app_rate: 0.25,
    rank: 45,
    avg_round: 20146,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.25,
    rank: 45,
    avg_round: 21522,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'miyabi',
    char_three: 'lucy',
    app_rate: 0.25,
    rank: 45,
    avg_round: 20392,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.25,
    rank: 45,
    avg_round: 22089,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'yanagi',
    app_rate: 0.25,
    rank: 45,
    avg_round: 16505,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.22,
    rank: 52,
    avg_round: 21898,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'soukaku',
    app_rate: 0.22,
    rank: 52,
    avg_round: 19301,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.22,
    rank: 52,
    avg_round: 26511,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'lucy',
    app_rate: 0.2,
    rank: 55,
    avg_round: 17166,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'grace-howard',
    app_rate: 0.18,
    rank: 56,
    avg_round: 22072,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.18,
    rank: 56,
    avg_round: 11724,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'lucy',
    app_rate: 0.18,
    rank: 56,
    avg_round: 19921,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.16,
    rank: 59,
    avg_round: 21638,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.16,
    rank: 59,
    avg_round: 14429,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 61,
    avg_round: 22451,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'lucy',
    app_rate: 0.13,
    rank: 61,
    avg_round: 16052,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 0.13,
    rank: 61,
    avg_round: 19360,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'caesar',
    app_rate: 0.13,
    rank: 61,
    avg_round: 19034,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 0.13,
    rank: 61,
    avg_round: 11898,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'rina',
    app_rate: 0.13,
    rank: 61,
    avg_round: 25363,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'miyabi',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 67,
    avg_round: 22553,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 67,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'qingyi',
    app_rate: 0.11,
    rank: 67,
    avg_round: 17348,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'seth',
    app_rate: 0.11,
    rank: 67,
    avg_round: 17053,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.11,
    rank: 67,
    avg_round: 20851,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'koleda',
    char_three: 'soukaku',
    app_rate: 0.09,
    rank: 72,
    avg_round: 10908,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'corin',
    char_three: 'rina',
    app_rate: 0.09,
    rank: 72,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.09,
    rank: 72,
    avg_round: 18773,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.09,
    rank: 72,
    avg_round: 22192,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lycaon',
    app_rate: 0.09,
    rank: 72,
    avg_round: 15988,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'burnice',
    app_rate: 0.09,
    rank: 72,
    avg_round: 25794,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 72,
    avg_round: 22127,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 72,
    avg_round: 18150,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'lycaon',
    app_rate: 0.09,
    rank: 72,
    avg_round: 10465,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'grace-howard',
    app_rate: 0.09,
    rank: 72,
    avg_round: 14370,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'anby-demara',
    app_rate: 0.07,
    rank: 82,
    avg_round: 20931,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 82,
    avg_round: 22519,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'koleda',
    app_rate: 0.07,
    rank: 82,
    avg_round: 21233,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 82,
    avg_round: 10899,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 82,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'lycaon',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 82,
    avg_round: 19572,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'rina',
    app_rate: 0.07,
    rank: 82,
    avg_round: 16299,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 82,
    avg_round: 25556,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 82,
    avg_round: 9623,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 82,
    avg_round: 18614,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.07,
    rank: 82,
    avg_round: 23737,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 82,
    avg_round: 17057,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 82,
    avg_round: 23913,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 82,
    avg_round: 13735,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 82,
    avg_round: 16467,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.07,
    rank: 82,
    avg_round: 21226,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 0.07,
    rank: 82,
    avg_round: 15296,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 82,
    avg_round: 23854,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 82,
    avg_round: 21307,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.07,
    rank: 82,
    avg_round: 9943,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 0.04,
    rank: 102,
    avg_round: 13913,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'grace-howard',
    app_rate: 0.04,
    rank: 102,
    avg_round: 20562,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.04,
    rank: 102,
    avg_round: 13792,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'piper',
    app_rate: 0.04,
    rank: 102,
    avg_round: 20727,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 102,
    avg_round: 21517,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 102,
    avg_round: 20862,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'lighter',
    app_rate: 0.04,
    rank: 102,
    avg_round: 14696,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.04,
    rank: 102,
    avg_round: 8294,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 102,
    avg_round: 18566,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 102,
    avg_round: 20037,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 102,
    avg_round: 18731,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'ben',
    app_rate: 0.04,
    rank: 102,
    avg_round: 21811,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.04,
    rank: 102,
    avg_round: 12374,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'miyabi',
    char_three: 'soukaku',
    app_rate: 0.04,
    rank: 102,
    avg_round: 20401,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.04,
    rank: 102,
    avg_round: 22415,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.04,
    rank: 102,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'burnice',
    char_three: 'lycaon',
    app_rate: 0.04,
    rank: 102,
    avg_round: 9394,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'billy-kid',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 102,
    avg_round: 9783,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'caesar',
    app_rate: 0.04,
    rank: 102,
    avg_round: 11026,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.04,
    rank: 102,
    avg_round: 8434,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.04,
    rank: 102,
    avg_round: 14793,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.04,
    rank: 102,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'lycaon',
    app_rate: 0.04,
    rank: 102,
    avg_round: 14376,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'koleda',
    app_rate: 0.04,
    rank: 102,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.04,
    rank: 102,
    avg_round: 7023,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 0.04,
    rank: 102,
    avg_round: 17656,
    star_num: '4'
  }
];
