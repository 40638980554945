export const ZZZAssault_0 = [
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 25.88,
    rank: 1,
    avg_round: 23278,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 15.31,
    rank: 2,
    avg_round: 18046,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 14.07,
    rank: 3,
    avg_round: 24109,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 13.71,
    rank: 4,
    avg_round: 31967,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 6.96,
    rank: 5,
    avg_round: 28994,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 5.84,
    rank: 6,
    avg_round: 19298,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 5.57,
    rank: 7,
    avg_round: 22332,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 5.53,
    rank: 8,
    avg_round: 28263,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 5.32,
    rank: 9,
    avg_round: 17943,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 4.24,
    rank: 10,
    avg_round: 26864,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 3.94,
    rank: 11,
    avg_round: 22171,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 3.94,
    rank: 11,
    avg_round: 19690,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 3.69,
    rank: 13,
    avg_round: 19775,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 3.61,
    rank: 14,
    avg_round: 20296,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 3.61,
    rank: 14,
    avg_round: 21495,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 3.44,
    rank: 16,
    avg_round: 22186,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 3.42,
    rank: 17,
    avg_round: 27963,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 3.23,
    rank: 18,
    avg_round: 19101,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 3.12,
    rank: 19,
    avg_round: 16853,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 3.06,
    rank: 20,
    avg_round: 23067,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 2.89,
    rank: 21,
    avg_round: 26708,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'soukaku',
    app_rate: 2.76,
    rank: 22,
    avg_round: 26141,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 2.74,
    rank: 23,
    avg_round: 19974,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 2.51,
    rank: 24,
    avg_round: 17060,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 2.4,
    rank: 25,
    avg_round: 18684,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 2.36,
    rank: 26,
    avg_round: 29259,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 2.32,
    rank: 27,
    avg_round: 15288,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 2.3,
    rank: 28,
    avg_round: 24646,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'lighter',
    app_rate: 2.3,
    rank: 28,
    avg_round: 19862,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 2.28,
    rank: 30,
    avg_round: 24883,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 2.24,
    rank: 31,
    avg_round: 14703,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 1.77,
    rank: 32,
    avg_round: 21009,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 1.77,
    rank: 32,
    avg_round: 19997,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 1.75,
    rank: 34,
    avg_round: 16034,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 1.52,
    rank: 35,
    avg_round: 25203,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 1.48,
    rank: 36,
    avg_round: 23390,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 1.46,
    rank: 37,
    avg_round: 21648,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 1.35,
    rank: 38,
    avg_round: 23892,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 1.35,
    rank: 38,
    avg_round: 24204,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 1.33,
    rank: 40,
    avg_round: 19492,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 1.31,
    rank: 41,
    avg_round: 14054,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 1.31,
    rank: 41,
    avg_round: 22067,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 1.29,
    rank: 43,
    avg_round: 17498,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'rina',
    app_rate: 1.18,
    rank: 44,
    avg_round: 24196,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 1.16,
    rank: 45,
    avg_round: 24511,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 1.14,
    rank: 46,
    avg_round: 19757,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'caesar',
    app_rate: 1.08,
    rank: 47,
    avg_round: 23112,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 1.01,
    rank: 48,
    avg_round: 18763,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'soukaku',
    app_rate: 0.99,
    rank: 49,
    avg_round: 18914,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.97,
    rank: 50,
    avg_round: 19382,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.95,
    rank: 51,
    avg_round: 18387,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'soukaku',
    app_rate: 0.93,
    rank: 52,
    avg_round: 22515,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'koleda',
    char_three: 'soukaku',
    app_rate: 0.91,
    rank: 53,
    avg_round: 12744,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'caesar',
    app_rate: 0.91,
    rank: 53,
    avg_round: 17552,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.91,
    rank: 53,
    avg_round: 13357,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 0.89,
    rank: 56,
    avg_round: 22697,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.89,
    rank: 56,
    avg_round: 22839,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.86,
    rank: 58,
    avg_round: 15869,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'burnice',
    app_rate: 0.86,
    rank: 58,
    avg_round: 28624,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'lucy',
    app_rate: 0.84,
    rank: 60,
    avg_round: 17241,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.8,
    rank: 61,
    avg_round: 21041,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.78,
    rank: 62,
    avg_round: 22817,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 0.74,
    rank: 63,
    avg_round: 19731,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'lucy',
    app_rate: 0.72,
    rank: 64,
    avg_round: 22003,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'soukaku',
    app_rate: 0.67,
    rank: 65,
    avg_round: 16592,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.67,
    rank: 65,
    avg_round: 21704,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.65,
    rank: 67,
    avg_round: 12501,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.65,
    rank: 67,
    avg_round: 17655,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.61,
    rank: 69,
    avg_round: 21763,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'soukaku',
    app_rate: 0.61,
    rank: 69,
    avg_round: 22504,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.59,
    rank: 71,
    avg_round: 19807,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.59,
    rank: 71,
    avg_round: 16889,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.59,
    rank: 71,
    avg_round: 19118,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.57,
    rank: 74,
    avg_round: 16586,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'lighter',
    char_three: 'nicole-demara',
    app_rate: 0.53,
    rank: 75,
    avg_round: 19650,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.53,
    rank: 75,
    avg_round: 29810,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.53,
    rank: 75,
    avg_round: 20287,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'seth',
    app_rate: 0.53,
    rank: 75,
    avg_round: 20216,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.51,
    rank: 79,
    avg_round: 23926,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.51,
    rank: 79,
    avg_round: 12337,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 0.49,
    rank: 81,
    avg_round: 16375,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.49,
    rank: 81,
    avg_round: 33392,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.49,
    rank: 81,
    avg_round: 17152,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.44,
    rank: 84,
    avg_round: 19369,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'seth',
    app_rate: 0.42,
    rank: 85,
    avg_round: 19516,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 0.42,
    rank: 85,
    avg_round: 21137,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lycaon',
    app_rate: 0.42,
    rank: 85,
    avg_round: 25898,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.42,
    rank: 85,
    avg_round: 18589,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.38,
    rank: 89,
    avg_round: 20294,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.38,
    rank: 89,
    avg_round: 23018,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.38,
    rank: 89,
    avg_round: 20174,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.38,
    rank: 89,
    avg_round: 16198,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'lucy',
    app_rate: 0.38,
    rank: 89,
    avg_round: 15773,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.38,
    rank: 89,
    avg_round: 17328,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.36,
    rank: 95,
    avg_round: 20049,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.36,
    rank: 95,
    avg_round: 26806,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.36,
    rank: 95,
    avg_round: 19490,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.36,
    rank: 95,
    avg_round: 17103,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.34,
    rank: 99,
    avg_round: 17085,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.34,
    rank: 99,
    avg_round: 19251,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.34,
    rank: 99,
    avg_round: 19359,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.34,
    rank: 99,
    avg_round: 20170,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.34,
    rank: 99,
    avg_round: 23855,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.34,
    rank: 99,
    avg_round: 9960,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.34,
    rank: 99,
    avg_round: 13265,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.32,
    rank: 106,
    avg_round: 13700,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.32,
    rank: 106,
    avg_round: 22670,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.32,
    rank: 106,
    avg_round: 20492,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'rina',
    app_rate: 0.32,
    rank: 106,
    avg_round: 21797,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.32,
    rank: 106,
    avg_round: 9853,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.32,
    rank: 106,
    avg_round: 22883,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.32,
    rank: 106,
    avg_round: 22738,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'seth',
    app_rate: 0.32,
    rank: 106,
    avg_round: 9895,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.32,
    rank: 106,
    avg_round: 18132,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.3,
    rank: 115,
    avg_round: 14280,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'seth',
    app_rate: 0.3,
    rank: 115,
    avg_round: 11384,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.3,
    rank: 115,
    avg_round: 16179,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.3,
    rank: 115,
    avg_round: 22661,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.3,
    rank: 115,
    avg_round: 22033,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'yanagi',
    app_rate: 0.3,
    rank: 115,
    avg_round: 16456,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 0.27,
    rank: 121,
    avg_round: 20388,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.27,
    rank: 121,
    avg_round: 19497,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.27,
    rank: 121,
    avg_round: 20128,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.27,
    rank: 121,
    avg_round: 17260,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'seth',
    app_rate: 0.27,
    rank: 121,
    avg_round: 12487,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.27,
    rank: 121,
    avg_round: 21432,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'soukaku',
    app_rate: 0.25,
    rank: 127,
    avg_round: 20875,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'rina',
    app_rate: 0.25,
    rank: 127,
    avg_round: 21396,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.25,
    rank: 127,
    avg_round: 15845,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.25,
    rank: 127,
    avg_round: 31557,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.25,
    rank: 127,
    avg_round: 10230,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.25,
    rank: 127,
    avg_round: 14777,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.23,
    rank: 133,
    avg_round: 14860,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.23,
    rank: 133,
    avg_round: 21759,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.23,
    rank: 133,
    avg_round: 21522,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.23,
    rank: 133,
    avg_round: 24986,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'miyabi',
    char_three: 'lucy',
    app_rate: 0.23,
    rank: 133,
    avg_round: 20392,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'soukaku',
    app_rate: 0.23,
    rank: 133,
    avg_round: 19301,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'caesar',
    app_rate: 0.23,
    rank: 133,
    avg_round: 17853,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.23,
    rank: 133,
    avg_round: 26511,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.23,
    rank: 133,
    avg_round: 11621,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.23,
    rank: 133,
    avg_round: 10787,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.21,
    rank: 143,
    avg_round: 20438,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'qingyi',
    app_rate: 0.21,
    rank: 143,
    avg_round: 16544,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.21,
    rank: 143,
    avg_round: 15119,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.21,
    rank: 143,
    avg_round: 12862,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.21,
    rank: 143,
    avg_round: 15535,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.19,
    rank: 149,
    avg_round: 20423,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'koleda',
    char_three: 'rina',
    app_rate: 0.19,
    rank: 149,
    avg_round: 14042,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.19,
    rank: 149,
    avg_round: 17898,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'lucy',
    app_rate: 0.19,
    rank: 149,
    avg_round: 19921,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.19,
    rank: 149,
    avg_round: 17588,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'grace-howard',
    app_rate: 0.17,
    rank: 154,
    avg_round: 22072,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.17,
    rank: 154,
    avg_round: 22156,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.17,
    rank: 154,
    avg_round: 17710,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.17,
    rank: 154,
    avg_round: 18267,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.17,
    rank: 154,
    avg_round: 17006,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.17,
    rank: 154,
    avg_round: 10735,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'anby-demara',
    app_rate: 0.17,
    rank: 154,
    avg_round: 16554,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.17,
    rank: 154,
    avg_round: 21905,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.17,
    rank: 154,
    avg_round: 13421,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.17,
    rank: 154,
    avg_round: 16999,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.17,
    rank: 154,
    avg_round: 15472,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.17,
    rank: 154,
    avg_round: 16860,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.15,
    rank: 166,
    avg_round: 22451,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.15,
    rank: 166,
    avg_round: 24528,
    star_num: '4'
  },
  {
    char_one: 'lycaon',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.15,
    rank: 166,
    avg_round: 19248,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.15,
    rank: 166,
    avg_round: 22640,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.15,
    rank: 166,
    avg_round: 22325,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.15,
    rank: 166,
    avg_round: 25937,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'rina',
    app_rate: 0.15,
    rank: 166,
    avg_round: 24985,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.15,
    rank: 166,
    avg_round: 26678,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.15,
    rank: 166,
    avg_round: 15487,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.15,
    rank: 166,
    avg_round: 15538,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.15,
    rank: 166,
    avg_round: 19754,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.15,
    rank: 166,
    avg_round: 21638,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.15,
    rank: 166,
    avg_round: 14429,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'qingyi',
    app_rate: 0.15,
    rank: 166,
    avg_round: 15917,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'soukaku',
    char_three: 'seth',
    app_rate: 0.15,
    rank: 166,
    avg_round: 13382,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'qingyi',
    app_rate: 0.15,
    rank: 166,
    avg_round: 19600,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.15,
    rank: 166,
    avg_round: 12590,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.13,
    rank: 183,
    avg_round: 22045,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.13,
    rank: 183,
    avg_round: 12597,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.13,
    rank: 183,
    avg_round: 15532,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 183,
    avg_round: 15670,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'seth',
    app_rate: 0.13,
    rank: 183,
    avg_round: 13910,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.13,
    rank: 183,
    avg_round: 21228,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'lucy',
    app_rate: 0.13,
    rank: 183,
    avg_round: 16052,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.13,
    rank: 183,
    avg_round: 17927,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.13,
    rank: 183,
    avg_round: 13465,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.13,
    rank: 183,
    avg_round: 21627,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 183,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 183,
    avg_round: 22248,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.13,
    rank: 183,
    avg_round: 19799,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'caesar',
    app_rate: 0.13,
    rank: 183,
    avg_round: 19034,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.13,
    rank: 183,
    avg_round: 19290,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'qingyi',
    app_rate: 0.13,
    rank: 183,
    avg_round: 17348,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'soukaku',
    char_three: 'seth',
    app_rate: 0.13,
    rank: 183,
    avg_round: 19101,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'koleda',
    app_rate: 0.13,
    rank: 183,
    avg_round: 14254,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'rina',
    app_rate: 0.13,
    rank: 183,
    avg_round: 24690,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.13,
    rank: 183,
    avg_round: 10927,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'burnice',
    app_rate: 0.13,
    rank: 183,
    avg_round: 19984,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'grace-howard',
    char_three: 'lucy',
    app_rate: 0.11,
    rank: 204,
    avg_round: 14507,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'piper',
    char_three: 'caesar',
    app_rate: 0.11,
    rank: 204,
    avg_round: 17041,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'grace-howard',
    char_three: 'lucy',
    app_rate: 0.11,
    rank: 204,
    avg_round: 13686,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'burnice',
    char_three: 'lycaon',
    app_rate: 0.11,
    rank: 204,
    avg_round: 9474,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.11,
    rank: 204,
    avg_round: 12301,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.11,
    rank: 204,
    avg_round: 17427,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.11,
    rank: 204,
    avg_round: 16591,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.11,
    rank: 204,
    avg_round: 18234,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 204,
    avg_round: 25123,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lycaon',
    app_rate: 0.11,
    rank: 204,
    avg_round: 15927,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 204,
    avg_round: 11913,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.11,
    rank: 204,
    avg_round: 19417,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'seth',
    app_rate: 0.11,
    rank: 204,
    avg_round: 13815,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.11,
    rank: 204,
    avg_round: 14941,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.11,
    rank: 204,
    avg_round: 21546,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 204,
    avg_round: 18150,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'koleda',
    app_rate: 0.11,
    rank: 204,
    avg_round: 12102,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 204,
    avg_round: 14967,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.11,
    rank: 204,
    avg_round: 19208,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'lycaon',
    app_rate: 0.11,
    rank: 204,
    avg_round: 13155,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.11,
    rank: 204,
    avg_round: 7407,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lighter',
    char_three: 'rina',
    app_rate: 0.11,
    rank: 204,
    avg_round: 14482,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.11,
    rank: 204,
    avg_round: 20851,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.11,
    rank: 204,
    avg_round: 14058,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.11,
    rank: 204,
    avg_round: 9310,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 232,
    avg_round: 21061,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 232,
    avg_round: 17199,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'corin',
    char_three: 'rina',
    app_rate: 0.08,
    rank: 232,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'piper',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 232,
    avg_round: 15097,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 232,
    avg_round: 16390,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 232,
    avg_round: 17115,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.08,
    rank: 232,
    avg_round: 22514,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'jane-doe',
    app_rate: 0.08,
    rank: 232,
    avg_round: 19970,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 232,
    avg_round: 14947,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.08,
    rank: 232,
    avg_round: 22564,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.08,
    rank: 232,
    avg_round: 23420,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 232,
    avg_round: 21956,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.08,
    rank: 232,
    avg_round: 16422,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'piper',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 232,
    avg_round: 14645,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'burnice',
    app_rate: 0.08,
    rank: 232,
    avg_round: 25794,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 232,
    avg_round: 17074,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'piper',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 232,
    avg_round: 10914,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 232,
    avg_round: 12421,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'ellen',
    char_three: 'soukaku',
    app_rate: 0.08,
    rank: 232,
    avg_round: 11828,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 232,
    avg_round: 12394,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.08,
    rank: 232,
    avg_round: 22032,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'jane-doe',
    char_three: 'lycaon',
    app_rate: 0.08,
    rank: 232,
    avg_round: 17923,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'burnice',
    char_three: 'soukaku',
    app_rate: 0.08,
    rank: 232,
    avg_round: 13234,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.08,
    rank: 232,
    avg_round: 21546,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'ellen',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 232,
    avg_round: 16552,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.08,
    rank: 232,
    avg_round: 13971,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 232,
    avg_round: 15832,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 232,
    avg_round: 13115,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 232,
    avg_round: 20544,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'qingyi',
    app_rate: 0.08,
    rank: 232,
    avg_round: 10572,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 232,
    avg_round: 9430,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'lycaon',
    app_rate: 0.08,
    rank: 232,
    avg_round: 17162,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'corin',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 232,
    avg_round: 14401,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 0.08,
    rank: 232,
    avg_round: 15828,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'anby-demara',
    app_rate: 0.06,
    rank: 268,
    avg_round: 20931,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 268,
    avg_round: 22519,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 268,
    avg_round: 18686,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'koleda',
    app_rate: 0.06,
    rank: 268,
    avg_round: 21233,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'koleda',
    char_three: 'seth',
    app_rate: 0.06,
    rank: 268,
    avg_round: 16422,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'soukaku',
    app_rate: 0.06,
    rank: 268,
    avg_round: 23871,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 268,
    avg_round: 20652,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 268,
    avg_round: 16299,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 268,
    avg_round: 19200,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 268,
    avg_round: 12010,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.06,
    rank: 268,
    avg_round: 17902,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 268,
    avg_round: 25556,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 268,
    avg_round: 18481,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'ben',
    app_rate: 0.06,
    rank: 268,
    avg_round: 15122,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lighter',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 268,
    avg_round: 17385,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'jane-doe',
    char_three: 'anby-demara',
    app_rate: 0.06,
    rank: 268,
    avg_round: 10567,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.06,
    rank: 268,
    avg_round: 13483,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'anby-demara',
    app_rate: 0.06,
    rank: 268,
    avg_round: 13786,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 268,
    avg_round: 15380,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 268,
    avg_round: 18938,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 268,
    avg_round: 18385,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.06,
    rank: 268,
    avg_round: 20079,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 268,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 268,
    avg_round: 15793,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 268,
    avg_round: 15878,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 268,
    avg_round: 16020,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 268,
    avg_round: 13735,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.06,
    rank: 268,
    avg_round: 11174,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 268,
    avg_round: 20906,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'lighter',
    app_rate: 0.06,
    rank: 268,
    avg_round: 14696,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 268,
    avg_round: 18608,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.06,
    rank: 268,
    avg_round: 14103,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'yanagi',
    app_rate: 0.06,
    rank: 268,
    avg_round: 10232,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'qingyi',
    app_rate: 0.06,
    rank: 268,
    avg_round: 14664,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.06,
    rank: 268,
    avg_round: 22778,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.06,
    rank: 268,
    avg_round: 16798,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 268,
    avg_round: 17003,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 268,
    avg_round: 16263,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'koleda',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 268,
    avg_round: 10563,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'koleda',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 268,
    avg_round: 11187,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 268,
    avg_round: 23244,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 268,
    avg_round: 15135,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'corin',
    char_three: 'qingyi',
    app_rate: 0.06,
    rank: 268,
    avg_round: 8639,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 268,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'soukaku',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.04,
    rank: 317,
    avg_round: 16708,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 317,
    avg_round: 12634,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.04,
    rank: 317,
    avg_round: 10196,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.04,
    rank: 317,
    avg_round: 7662,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lycaon',
    char_three: 'seth',
    app_rate: 0.04,
    rank: 317,
    avg_round: 8600,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 317,
    avg_round: 25499,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'ben',
    app_rate: 0.04,
    rank: 317,
    avg_round: 18392,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 317,
    avg_round: 21700,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.04,
    rank: 317,
    avg_round: 12544,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.04,
    rank: 317,
    avg_round: 21947,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.04,
    rank: 317,
    avg_round: 20222,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'lycaon',
    char_three: 'anby-demara',
    app_rate: 0.04,
    rank: 317,
    avg_round: 13740,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.04,
    rank: 317,
    avg_round: 19456,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.04,
    rank: 317,
    avg_round: 23236,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.04,
    rank: 317,
    avg_round: 18750,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.04,
    rank: 317,
    avg_round: 20202,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.04,
    rank: 317,
    avg_round: 17722,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.04,
    rank: 317,
    avg_round: 14430,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.04,
    rank: 317,
    avg_round: 20604,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 0.04,
    rank: 317,
    avg_round: 19552,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 317,
    avg_round: 20862,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'anby-demara',
    app_rate: 0.04,
    rank: 317,
    avg_round: 21345,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'soukaku',
    app_rate: 0.04,
    rank: 317,
    avg_round: 17824,
    star_num: '4'
  },
  {
    char_one: 'lycaon',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.04,
    rank: 317,
    avg_round: 17314,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.04,
    rank: 317,
    avg_round: 19402,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'jane-doe',
    char_three: 'soukaku',
    app_rate: 0.04,
    rank: 317,
    avg_round: 12050,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.04,
    rank: 317,
    avg_round: 18894,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'anby-demara',
    char_three: 'lucy',
    app_rate: 0.04,
    rank: 317,
    avg_round: 9858,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'anby-demara',
    app_rate: 0.04,
    rank: 317,
    avg_round: 12714,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.04,
    rank: 317,
    avg_round: 16832,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.04,
    rank: 317,
    avg_round: 20969,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.04,
    rank: 317,
    avg_round: 14602,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'soukaku',
    app_rate: 0.04,
    rank: 317,
    avg_round: 13409,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.04,
    rank: 317,
    avg_round: 10111,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.04,
    rank: 317,
    avg_round: 17056,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.04,
    rank: 317,
    avg_round: 9434,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.04,
    rank: 317,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.04,
    rank: 317,
    avg_round: 12730,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'miyabi',
    char_three: 'soukaku',
    app_rate: 0.04,
    rank: 317,
    avg_round: 20401,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.04,
    rank: 317,
    avg_round: 12406,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'piper',
    char_three: 'qingyi',
    app_rate: 0.04,
    rank: 317,
    avg_round: 10650,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 317,
    avg_round: 10068,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'ben',
    app_rate: 0.04,
    rank: 317,
    avg_round: 8382,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 317,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 317,
    avg_round: 15055,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.04,
    rank: 317,
    avg_round: 18312,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.04,
    rank: 317,
    avg_round: 14084,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'harumasa',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 317,
    avg_round: 13656,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.04,
    rank: 317,
    avg_round: 14596,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nekomata',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 317,
    avg_round: 10750,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.04,
    rank: 317,
    avg_round: 20818,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'koleda',
    app_rate: 0.04,
    rank: 317,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'rina',
    app_rate: 0.04,
    rank: 317,
    avg_round: 13643,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'ellen',
    char_three: 'soukaku',
    app_rate: 0.04,
    rank: 317,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'burnice',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'anby-demara',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'qingyi',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'ben',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'ben',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'rina',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'koleda',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'jane-doe',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'qingyi',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'ben',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'anby-demara',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'yanagi',
    char_three: 'grace-howard',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'burnice',
    char_three: 'qingyi',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'seth',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'koleda',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'qingyi',
    char_three: 'ben',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'ben',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'qingyi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'miyabi',
    char_three: 'harumasa',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'koleda',
    char_three: 'anby-demara',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'piper',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'burnice',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'rina',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'jane-doe',
    char_three: 'qingyi',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'miyabi',
    char_three: 'lucy',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'miyabi',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'ellen',
    char_three: 'lycaon',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'qingyi',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'billy-kid',
    char_three: 'qingyi',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nekomata',
    char_three: 'soukaku',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'miyabi',
    char_three: 'grace-howard',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'miyabi',
    char_three: 'soukaku',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'lycaon',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'harumasa',
    char_three: 'qingyi',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lycaon',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'ben',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'koleda',
    char_three: 'ben',
    app_rate: 0.02,
    rank: 398,
    avg_round: 0,
    star_num: '4'
  }
];
