import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import './tier-list.scss';
import '../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import lodash from 'lodash';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Card } from 'react-bootstrap';
import { ISoloCharacter } from '../../modules/common/model/graphql-types';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { SoloCharacter } from '../../modules/solo/common/components/solo-character';

interface ISoloCharacterNodes {
  nodes: ISoloCharacter[];
}

interface ISoloCharacterEntry {
  allCharacters: ISoloCharacterNodes;
}

interface IProps {
  data: ISoloCharacterEntry;
}

const SoloTierList: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: 'SR', value: 'SR' },
          { label: 'SSR', value: 'SSR' }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Water',
            tooltip: 'Water',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_water.webp"
                width={24}
                alt="Water"
              />
            )
          },
          {
            value: 'Wind',
            tooltip: 'Wind',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_wind.webp"
                width={24}
                alt="Wind"
              />
            )
          },
          {
            value: 'Fire',
            tooltip: 'Fire',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_fire.webp"
                width={24}
                alt="Fire"
              />
            )
          },
          {
            value: 'Light',
            tooltip: 'Light',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_light.webp"
                width={24}
                alt="Light"
              />
            )
          },
          {
            value: 'Dark',
            tooltip: 'Dark',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_darkness.webp"
                width={24}
                alt="Dark"
              />
            )
          }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Tank',
            tooltip: 'Tank',
            image: (
              <StaticImage
                src="../../images/solo/icons/class_tank.webp"
                width={24}
                alt="Tank"
              />
            )
          },
          {
            value: 'Fighter',
            tooltip: 'Fighter',
            image: (
              <StaticImage
                src="../../images/solo/icons/class_fighter.webp"
                width={24}
                alt="Fighter"
              />
            )
          },
          {
            value: 'Mage',
            tooltip: 'Mage',
            image: (
              <StaticImage
                src="../../images/solo/icons/class_mage.webp"
                width={24}
                alt="Mage"
              />
            )
          },
          {
            value: 'Assassin',
            tooltip: 'Assassin',
            image: (
              <StaticImage
                src="../../images/solo/icons/class_assassin.webp"
                width={24}
                alt="Assassin"
              />
            )
          },
          {
            value: 'Ranger',
            tooltip: 'Ranger',
            image: (
              <StaticImage
                src="../../images/solo/icons/class_ranger.webp"
                width={24}
                alt="Ranger"
              />
            )
          },
          {
            value: 'Healer',
            tooltip: 'Healer',
            image: (
              <StaticImage
                src="../../images/solo/icons/class_healer.webp"
                width={24}
                alt="Healer"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.element.toLowerCase() === activeFilters.element.toLowerCase()
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [{ value: 'pve', label: 'PVE' }];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    // eslint-disable-next-line prefer-destructuring
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout className={'generic-page solo solo-tier'} game="solo">
      <ul className="breadcrumb">
        <li>
          <Link to="/solo-leveling/">Solo Leveling: Arise</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/solo/categories/category_tier.jpg"
            alt="tier"
          />
        </div>
        <div className="page-details">
          <h1>Solo Leveling Arise Tier List</h1>
          <h2>
            Our Solo Leveling Arise tier list rates all characters by their
            performance and potential. Create the best team with our tier list!
          </h2>
          <p>
            Last updated: <strong>21/01/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      <div className="tier-list-page nikke">
        <SectionHeader title="Solo Leveling: Arise Tier List" />
        <Accordion className="tier-list-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>About the tier list</Accordion.Header>
            <Accordion.Body>
              <p>
                The tier list rates characters based on their performance across
                all PVE content - it tries to average how they perform against
                bosses and in normal stages. Also,{' '}
                <strong>please compare characters within their Element</strong>.
                Solo Leveling: Arise gives a massive bonus to damage when you
                counter the enemy element - you deal{' '}
                <strong>a whooping 150% more damage</strong>. And the opposite
                is also true, if you attack enemies who counter your character
                element, you will only deal 50% damage.
              </p>
              <p>Special markers:</p>
              <ul>
                <li>
                  <strong>High Investment</strong> characters are marked with{' '}
                  <strong className="inv">$</strong> - their power is massively
                  increased with investment and allows them to be considered a
                  tier higher than the tier list shows.
                </li>
                <li>
                  As for the characters that have{' '}
                  <strong className="marks">A?</strong> shown in the top right
                  corner of their icons, it shows the suggested Ascension level
                  for that character to be considered the tier we placed them in
                  - if you have less dupes than indicated, you can drop them
                  down a tier. If there's no special indicator, then the
                  character is rated at A0 so with 0 dupes.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Ratings explained</Accordion.Header>
            <Accordion.Body>
              <ul className="no-margin">
                <li>
                  <strong>SS</strong> - the gods among mortals. Those characters
                  are broken and trivialize the game.
                </li>
                <li>
                  <strong>S+</strong> - characters that are among the best of
                  the best - super strong and versatile.
                </li>
                <li>
                  <strong>S</strong> - powerful characters that are slightly
                  inferior to the S+ ones with some things holding them back.
                </li>
                <li>
                  <strong>A</strong> - strong character that perform well that
                  still have place in the meta, but require a bit more work to
                  shine.
                </li>
                <li>
                  <strong>B</strong> - average characters that have big flaws
                  and only work as some niche replacements when you don't have
                  anything else.
                </li>
                <li>
                  <strong>C</strong> - below average character that are
                  struggling to find their place in the meta.
                </li>
                <li>
                  <strong>D</strong> - underwhelming characters that aren't
                  worth using.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              Changelog <span className="tag updated">Updated!</span>
            </Accordion.Header>
            <Accordion.Body>
              <h5>21.01.2025</h5>
              <ul>
                <li>
                  <strong>[Cha Hae-In]</strong> SS &gt; S+ tier - While Cha
                  remains an amazing unit, she doesn't compare to the power of
                  Thomas Andre. We'll be moving her down to S+ for the time
                  being.
                </li>
              </ul>
              <h5>19.01.2025</h5>
              <ul>
                <li>
                  Added <strong>[Esil]</strong> to the tier list.
                </li>
              </ul>
              <h5>20.12.2024</h5>
              <ul>
                <li>
                  Added <strong>[Shimizu Akari]</strong> to the tier list.
                </li>
              </ul>
              <h5>29.11.2024</h5>
              <ul>
                <li>
                  Added <strong>[Thomas Andre]</strong> to the tier list.
                </li>
              </ul>
              <h5>06.11.2024</h5>
              <ul>
                <li>
                  Added <strong>[Isla Wright]</strong> to the tier list.
                </li>
              </ul>
              <h5>13.10.2024</h5>
              <ul>
                <li>
                  Added <strong>[Gina]</strong> to the tier list.
                </li>
              </ul>
              <h5>20.09.2024</h5>
              <ul>
                <li>
                  Added <strong>[Charlotte]</strong> and{' '}
                  <strong>[Harper]</strong> to the tier list.
                </li>
                <li>
                  <strong>[Go Gunhee]</strong> S+ &gt; SS tier. We initially did
                  overlook just how powerful Gunhee would end up being, defining
                  a lot of the current meta and rivaling the dps of Cha while
                  elevating her own personal damage. As the best breaker in the
                  game - and overall, best character in the game as of now - we
                  are moving him up to SS tier.
                </li>
                <li>
                  <strong>[Kang Taeshik]</strong> S &gt; A tier. As we are
                  getting better damage dealers in dark, we felt it's fair to
                  downgrade Kang a bit.
                </li>
              </ul>
              <h5>28.08.2024</h5>
              <ul>
                <li>
                  Added <strong>[Go Gunhee]</strong> to the tier list and made
                  quite a lot of changes. Check them all on our{' '}
                  <a
                    href="https://blog.prydwen.gg/2024/08/28/solo-leveling-arise-tierlist-update/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    blog
                  </a>
                  .
                </li>
              </ul>
              <h5>22.08.2024</h5>
              <ul>
                <li>
                  Added <strong>[Han Se-Mi]</strong> to the tier list.
                </li>
              </ul>
              <h5>08.08.2024</h5>
              <ul>
                <li>
                  Raised <strong>[Woo Jinchul]</strong> from S to S+ tier.
                </li>
              </ul>
              <h5>01.08.2024</h5>
              <ul>
                <li>
                  Added <strong>[Amamiya Mirei]</strong> to the tier list.
                </li>
              </ul>
              <h5>15.07.2024</h5>
              <ul>
                <li>
                  Fully revamped the tier list and added A? indicators to
                  several characters. Check the 'About the tier list' tab for an
                  explanation about it.
                </li>
              </ul>
              <h5>12.07.2024</h5>
              <ul>
                <li>
                  Added <strong>[Yoo Soohyun]</strong> to the tier list.
                </li>
              </ul>
              <h5>20.06.2024</h5>
              <ul>
                <li>
                  Added <strong>[Meilin Fisher]</strong> to the tier list.
                </li>
                <ul>
                  <li>
                    Initial testings shows she is an amazing support for Alicia
                    and core attackers. For general usage Min is better but for
                    Water teams it's hard to deny her power. Being able to
                    remove shields is also a HUGE plus. She doesn't buff Seo as
                    much as she buffs Alicia, but she still gives her 8% CRIT
                    rate so that helps a Little.
                  </li>
                </ul>
                <li>
                  <strong>[Alicia]</strong> had her rating increased from S+ to
                  SS. With the addition of Meilin and Tusk, Alicia received yet
                  another power boost - on top of being amazing for the Vulcan
                  fight.
                </li>
              </ul>
              <h5>30.05.2024</h5>
              <ul>
                <li>
                  Our tier list generally evaluates unit performance at A0, with
                  some mentions of power spikes for early dupes. However, some
                  characters are built in a way that their power is massively
                  increased when they hit A5. We'll call these characters{' '}
                  <strong>high investment characters</strong>. As we get more
                  characters, we may rate the same unit in a separate way
                  depending on their investment level, unless the difference is
                  complete night and day,
                </li>
                <ul>
                  <li>
                    <strong>[Alicia]</strong> - The Ice Witch is already a very
                    powerful water attacker at A0 but she is a character that
                    becomes MUCH stronger when she gets to A5, giving her core
                    attack a massive 50% damage boost on top of a free [Freeze]
                    when the core attack hits. Her weapon buffs both core attack
                    and the water damage buff from Water Armor, buffing both her
                    and her partners.
                  </li>
                  <li>
                    <strong>[Seo Jiwoo]</strong> - While Seo is already a very
                    powerful bruiser and breaker at A0, every dupe increases her
                    overall power in a significant way by granting her extra
                    damage, more break potency, MP regen, HP and finally 32%
                    skill damage/crit rate to her skills and ultimate. Her
                    weapon when maxed will increase her critical damage by 140%
                    when fully stacked.
                  </li>
                </ul>
                <li>Other changes:</li>
                <ul>
                  <li>
                    We hade added <strong>[Alicia]</strong> to the tier list,
                  </li>
                  <li>
                    We have raised several characters to the S+ tier as due to
                    the recent buffs their performance has been improved. As a
                    result of the changes and movements, the D tier has been
                    removed and now C tier is the lowest one.
                  </li>
                </ul>
              </ul>
              <h5>09.05.2024</h5>
              <p>
                After further testing, we can safely say Cha Hae-In is much
                better than any character in the game, so we created a tier just
                for her - just to better display the difference between her and
                others.
              </p>
              <h5>08.05.2024</h5>
              <ul>
                <li>
                  <strong>Cha Hae-In</strong> has been added to the tier list
                  with <strong>S+</strong> rating. Cha Hae-In is a crit-stacking
                  monster damage dealer. Her damage output is simply insane,
                  beating most - if not all - all other damage dealers in the
                  game. She also can prevent the enemy from healing itself,
                  which is a great utility to possess in some battles.
                </li>
              </ul>
              <h5>23.04.2024</h5>
              <p>Initial release of the tier list.</p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {/* <div className="banner solo-promo">
          <h4>Play Solo Leveling: Arise on PC</h4>
          <OutboundLink
            href="https://www.ldplayer.net/games/com-netmarble-sololv-on-pc.html?n=82774712#utm_source=aff&utm_medium=aff&utm_campaign=aff82774712"
            target="_blank"
          >
            <Button variant="primary">Play now on LDPlayer</Button>
          </OutboundLink>
        </div> */}
        <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
        <div className="tier-list-header">
          <p className="title">
            Tier List <span>(PVE)</span>
          </p>
          <p className="sub-title">
            You're currently viewing the <strong>PVE</strong> tier list. It
            shows ow a character performs in Story in both boss oriented and
            generic content.
          </p>
        </div>
        <div className="employees-filter-bar solo">
          <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
        </div>
        <div className="custom-tier-list-solo">
          <div className="custom-tier-header">
            <div className="tier-rating">
              <span>&nbsp;</span>
            </div>
            <div className="custom-tier-container">
              <div className="custom-header Light">
                <span>Light</span>
              </div>
              <div className="custom-header Dark">
                <span>Dark</span>
              </div>
              <div className="custom-header Fire">
                <span>Fire</span>
              </div>
              <div className="custom-header Water">
                <span>Water</span>
              </div>
              <div className="custom-header Wind">
                <span>Wind</span>
              </div>
            </div>
          </div>
          <div className="custom-tier tier-ss first">
            <div className="tier-rating ss">
              <span>SS</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile Light">
                <span>Light</span>
              </div>
              <div className="custom-tier-burst Light">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 10)
                  .filter((emp) => emp.element === 'Light')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Dark">
                <span>Dark</span>
              </div>
              <div className="custom-tier-burst Dark">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 10)
                  .filter((emp) => emp.element === 'Dark')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Fire">
                <span>Fire</span>
              </div>
              <div className="custom-tier-burst Fire">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 10)
                  .filter((emp) => emp.element === 'Fire')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Water">
                <span>Water</span>
              </div>
              <div className="custom-tier-burst Water">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 10)
                  .filter((emp) => emp.element === 'Water')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Wind">
                <span>Wind</span>
              </div>
              <div className="custom-tier-burst Wind">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 10)
                  .filter((emp) => emp.element === 'Wind')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-s-plus">
            <div className="tier-rating s-plus">
              <span>S+</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile Light">
                <span>Light</span>
              </div>
              <div className="custom-tier-burst Light">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9.5)
                  .filter((emp) => emp.element === 'Light')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Dark">
                <span>Dark</span>
              </div>
              <div className="custom-tier-burst Dark">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9.5)
                  .filter((emp) => emp.element === 'Dark')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Fire">
                <span>Fire</span>
              </div>
              <div className="custom-tier-burst Fire">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9.5)
                  .filter((emp) => emp.element === 'Fire')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Water">
                <span>Water</span>
              </div>
              <div className="custom-tier-burst Water">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9.5)
                  .filter((emp) => emp.element === 'Water')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Wind">
                <span>Wind</span>
              </div>
              <div className="custom-tier-burst Wind">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9.5)
                  .filter((emp) => emp.element === 'Wind')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-s">
            <div className="tier-rating s">
              <span>S</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile Light">
                <span>Light</span>
              </div>
              <div className="custom-tier-burst Light">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9)
                  .filter((emp) => emp.element === 'Light')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Dark">
                <span>Dark</span>
              </div>
              <div className="custom-tier-burst Dark">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9)
                  .filter((emp) => emp.element === 'Dark')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Fire">
                <span>Fire</span>
              </div>
              <div className="custom-tier-burst Fire">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9)
                  .filter((emp) => emp.element === 'Fire')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Water">
                <span>Water</span>
              </div>
              <div className="custom-tier-burst Water">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9)
                  .filter((emp) => emp.element === 'Water')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Wind">
                <span>Wind</span>
              </div>
              <div className="custom-tier-burst Wind">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9)
                  .filter((emp) => emp.element === 'Wind')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
          <div className="custom-tier tier-a">
            <div className="tier-rating a">
              <span>A</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile Light">
                <span>Light</span>
              </div>
              <div className="custom-tier-burst Light">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 8)
                  .filter((emp) => emp.element === 'Light')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Dark">
                <span>Dark</span>
              </div>
              <div className="custom-tier-burst Dark">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 8)
                  .filter((emp) => emp.element === 'Dark')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Fire">
                <span>Fire</span>
              </div>
              <div className="custom-tier-burst Fire">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 8)
                  .filter((emp) => emp.element === 'Fire')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Water">
                <span>Water</span>
              </div>
              <div className="custom-tier-burst Water">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 8)
                  .filter((emp) => emp.element === 'Water')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Wind">
                <span>Wind</span>
              </div>
              <div className="custom-tier-burst Wind">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 8)
                  .filter((emp) => emp.element === 'Wind')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-b">
            <div className="tier-rating b">
              <span>B</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile Light">
                <span>Light</span>
              </div>
              <div className="custom-tier-burst Light">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 7)
                  .filter((emp) => emp.element === 'Light')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Dark">
                <span>Dark</span>
              </div>
              <div className="custom-tier-burst Dark">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 7)
                  .filter((emp) => emp.element === 'Dark')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Fire">
                <span>Fire</span>
              </div>
              <div className="custom-tier-burst Fire">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 7)
                  .filter((emp) => emp.element === 'Fire')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Water">
                <span>Water</span>
              </div>
              <div className="custom-tier-burst Water">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 7)
                  .filter((emp) => emp.element === 'Water')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Wind">
                <span>Wind</span>
              </div>
              <div className="custom-tier-burst Wind">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 7)
                  .filter((emp) => emp.element === 'Wind')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-c">
            <div className="tier-rating c">
              <span>C</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile Light">
                <span>Light</span>
              </div>
              <div className="custom-tier-burst Light">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 6)
                  .filter((emp) => emp.element === 'Light')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Dark">
                <span>Dark</span>
              </div>
              <div className="custom-tier-burst Dark">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 6)
                  .filter((emp) => emp.element === 'Dark')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Fire">
                <span>Fire</span>
              </div>
              <div className="custom-tier-burst Fire">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 6)
                  .filter((emp) => emp.element === 'Fire')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Water">
                <span>Water</span>
              </div>
              <div className="custom-tier-burst Water">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 6)
                  .filter((emp) => emp.element === 'Water')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile Wind">
                <span>Wind</span>
              </div>
              <div className="custom-tier-burst Wind">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 6)
                  .filter((emp) => emp.element === 'Wind')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <SoloCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            enablePopover
                            showSpecial
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
    </DashboardLayout>
  );
};

export default SoloTierList;

export const Head: React.FC = () => (
  <Seo
    title="Tier List | Solo Leveling: Arise | Prydwen Institute"
    description="Our Solo Leveling: Arise tier list rates all characters by their performance and potential. Create the best team with our tier list!"
    game="solo"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulSoloCharacter(
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        rarity
        investment
        element
        class
        ratings {
          pve
        }
      }
    }
  }
`;
