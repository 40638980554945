import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';

const NikkeGuidesAsuka: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide '} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Asuka Shikinami Langley guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_asuka.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Asuka Shikinami Langley Guide & Review</h1>
          <h2>
            A guide & review for Asuka Shikinami Langley in NIKKE: Goddess of
            Victory.
          </h2>
          <p>
            Last updated: <strong>25/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Asuka Shikinami Langley" />
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon"
                  slug="asuka-shikinami-langley"
                  enablePopover
                />
              </div>
              <p>
                Nothing beats a red-headed girl in an iconic dauntless red suit.
                Asuka Langley visits this world with her comrades, popping the
                champagne of collaboration between Shift Up and EVA. Asuka is a
                B3 Attacker from the Abnormal faction, armed with a phenomenal
                skillset that can greatly boost her own ATK, Elemental DMG, ATK
                DMG, as well as Hit Rate. Not only that, she can also buff the
                Core Damage of Fire Code allies. All these, on top of her
                built-in Pierce and Lifesteal, will make her a formidable foe, a
                worthy contender to the once reigning drunken queen.
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Simply put, Asuka is a resilient character with both offense and
                support capabilities. When it comes to DPS, she has a decent
                output, ranging between 75~95% of Alice (4 shots/s) depending on
                what supports she is paired with. This is because different DPS
                favor different supports. She is relatively independent since
                she has already provided herself with a multitude of buffs, but
                at the same time she is less scalable than most DPS due to her
                damage coming from enhancements rather than multiplier. She
                shines brighter against Wind enemies and enemies with a
                persistent core. However, ironically, her Core Damage buff is
                more profitable for her teammates, since she herself does not
                have perfect accuracy 24/7, which brings us to...
              </p>
              <p>
                One more thing that sets her miles ahead of her competitors is
                her capability of strengthening the Core Damage of Fire Code
                allies, albeit a bit niché since Shift Up has lately been stingy
                on the endurance of cores. It is still one rare buff with barely
                any dilution and one of the best buffs available in the market
                right now. Nikkes like Alice and Modernia (and Rei) will
                rejoice, since the buff automatically activates for everyone
                upon entering Full Burst!Her supportive side! Which we believe
                is what makes Asuka, Asuka. It is the star of the show and what
                sets her miles ahead of her competitors. Sure, the effectiveness
                of Core Damage buff hinges on whether the enemy has a persistent
                core, but it does not change the fact that it is a rare buff
                with barely any dilution. Nikkes like Alice, Modernia, and Rei
                can finally rejoice, since it automatically activates for Fire
                Code allies upon entering Full Burst!
              </p>
              <p>
                For Asuka to achieve optimal performance, you may want to pair
                her with an external Individual Shielder when battling Wind
                bosses (you want 24/7 uptime on her Elemental DMG). Besides
                that, since her ATK buff only activates when receiving a
                recovery effect, bursting with her first is almost always
                mandatory, except when you bring a healer. Without a healer, and
                especially against bosses outside Campaign, you are expected to
                bring a CDR to make sure the ATK buff remains online for as long
                as possible.
              </p>
              <p>
                In conclusion, she is a great all-rounder, an exceptional unit
                that everyone must have. The SAnis with pierce of Fire Team.
              </p>
              <h5>Should I pull this unit?</h5>
              <p>
                We are certain that she will dominate the meta not only in this
                next Raid but also in future ones as long as the boss has a
                persistent core, so she is a Must Have. That said, she will be
                valuable for Campaign and has been verified by top pushers. When
                Wind enemies start springing up, she would be a great
                alternative. Her synergy with Alice and Modernia is simply
                indispensable. You do not want to miss such a powerful Nikke,
                and since she is limited, pulling for extra copies is
                encouraged.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <p>
                Asuka overloads herself with boatloads of enhancements—too many
                in fact that we would say every common buff would feel diluted
                for her. They still boost her damage, though, with Damage Taken
                and Elemental DMG being way superior. Basic attacks determine
                her output. If infinite ammo is assumed and buffs are
                discounted, she would deal ~225% of final ATK as damage every
                second. Sure, this is not as glass-breaking as Scarlet's ~325%,
                but her personal buffs make up for it.
              </p>
              <Alert variant="primary">
                <p>
                  Asuka's multipliers are on the lower side and thus are
                  somewhat less scalable (and picky about buffs). You can still
                  maximize her multipliers by ensuring that she keeps firing
                  nonstop. Provide sufficient Max Ammo OL and buffers and pray
                  for miracles! More on "Gear Investments" section.
                </p>
              </Alert>
              <h5>You Can (NOT) Shield</h5>
              <blockquote>
                <p>■ Activates at the start of the battle. Affects self.</p>
                <p>Damage dealt to Shield ▲ 601.01% continuously.</p>
                <p>
                  (It only affects the damage dealt to the shield, not to the
                  Rapture itself)
                </p>
                <p>■ Activates when recovery takes effect. Affects self.</p>
                <p>ATK ▲ 96.98% for 25 sec.</p>
              </blockquote>
              <p>
                Asuka gains shieldbreaking capacity upon entering battle,
                increasing damage dealt to shields up to ~600%. This does not
                affect Hit Count shields nor increase damage against Raptures if
                pierce is present. While we still do not know how the next Solo
                Raid will play out, we are certain that this would be invaluable
                against that boss. Specific bosses such as the Rebuild(s) and
                Land Eater and mobs who erect Shield/Barrier will also be
                undermined.
              </p>

              <p>
                The second component is what generates her damage. As we all
                know, every unit needs at least some ATK of any form before they
                can deal decent damage, and{' '}
                <strong>a nearly permanent ~97%</strong> is no joke. Use
                Modernia or Scarlet as reference (both dependent on basic
                attacks). When deployed alone without any healer, Asuka will
                trigger this buff once via Burst Skill at the beginning, which
                will persist over the next 25 seconds. Without CDR, this is
                close to 62.5% uptime, but with CDR, it is closer to 80~95%. Not
                faultless, but you can choose to pair her with a healer for
                maximum uptime. Any healing counts as a recovery effect, but{' '}
                <strong>
                  the ones with duration are only registered once at the
                  beginning.
                </strong>
              </p>
              <p>
                Without a healer, Asuka will NOT have ATK buff until she uses
                Burst Skill. This prompts using her Burst Skill first before
                anyone else's, making Alice a less than ideal choice of pairing
                (but if doing so results in higher overall team damage, then the
                sacrifice is worthy). On the other hand, Modernia perfectly
                synergizes well with her since she does not need to use Burst
                Skill at all. In shorter fights where the first few seconds are
                crucial such as PvP, you will almost always want to bring a
                healer to preemptively trigger the effect.
              </p>

              <Alert variant="primary">
                <p>
                  Pay attention to positioning. Healers with less than 5 targets
                  will still 'heal' units placed leftmost if everyone's HP is
                  full both in PvE and PvP.
                </p>
              </Alert>
              <h5>You Can (NOT) Resist</h5>
              <blockquote>
                <p>
                  ■ Activates when entering Full Burst. Affects self when in
                  Shield status.
                </p>
                <p>Damage as strong element ▲ 30.02% for 10 sec.</p>
                <p>
                  ■ Activates when entering Full Burst. Affects all Fire Code
                  allies.
                </p>
                <p>Damage dealt when attacking core ▲ 60.07% for 10 sec.</p>
              </blockquote>
              <p>
                Asuka gains Elemental DMG whenever team enters Full Burst and
                she has an Individual Shield. Independent Shield is different
                from generic shield (Centi, Blanc) that every unit is given
                their own shield and the shield HP is not shared by the entire
                team. Individual Shield is only provided by select units, such
                as Rei, Folkwang, Crown, Tia, and Ram. Not many optimal choices
                for her. Thankfully, this part of the skill only gives Elemental
                DMG, which means it is only necessary against Wind enemies. For
                enemies of other elements, you can forget about the Shield
                requirement.
              </p>
              <Alert variant="primary">
                <p>
                  Rei only grants shield once during her Burst Skill, which
                  means 10s of uptime for Asuka's Elemental DMG. This is not
                  enough. You will want to pair her with an additional Shielder.
                </p>
              </Alert>
              <p>
                Asuka also grants Fire Code allies with Core Damage bonus. This
                is very impactful, as such a rarely diluted buff will massively
                boost the output of Nikkes that can regularly hit cores, such as
                Modernia and Alice (and Rei). At the same time, it is also
                useless if the enemy does not present any core, but unless it is
                one of the recent bosses that Shift Up makes hiding cores and
                promoting the SG team, then there is nothing to worry about.
                Most mobs and Campaign have cores too.
              </p>
              <Alert variant="primary">
                <p>
                  To emphasize, Asuka's Hit Rate buff from Burst Skill only
                  lasts 10 seconds, which means this Core Damage buff actually
                  influences Alice & Modernia more than herself. What does this
                  mean? Well, her DPS is relatively stronger when boss does not
                  have a persistent core. When boss has a persistent core, she
                  will be an amazing support. However, if Asuka can consistently
                  hit the core, then this gap is basically nonexistent.
                </p>
              </Alert>
              <p>
                Beware of dilution from Naga, who already provides up to ~125%
                of Core Damage. Overall, given a persistent core, she increases
                the damage of perfect Fire core-hitters by 27~29% when used
                alone and 15~17% when used with Naga. While this is trivial and
                pairing them both together is in no way detrimental—in fact, it
                could be the opposite—it might be an important consideration for
                minmaxing. Her supportive strength also declines significantly
                when Core is absent (important for Raids).{' '}
              </p>
              <h5>You Can (NOT) Block</h5>
              <blockquote>
                <p>■ Affects self.</p>
                <p>Gain Pierce for 25 sec.</p>
                <p>Attack damage ▲ 150.04% for 10 sec.</p>
                <p>Recovers 3.16% of attack damage as HP over 10 sec.</p>
                <p>Hit Rate ▲ 101. 37% for 10 sec.</p>
              </blockquote>
              <p>
                Asuka greatly empowers herself, gaining Pierce for 25 seconds as
                well as improving her survivability and offensive capabilities
                through increased ATK DMG, Lifesteal, and Hit Rate. This is
                where her damage spikes because that{' '}
                <strong>ATK DMG is ~150%</strong> and Hit Rate will allow her to
                leverage her S2 better. Safe to assume that, at maximum level,
                that ~101.37% Hit Rate translates into
                <strong>perfect accuracy</strong>, which means all shots will be
                guaranteed core hits. This is not something that Scarlet can
                brag about.
              </p>
              <Alert variant="primary">
                <p>
                  Asuka's DPS is divided into two windows. Her Burst covers 60%
                  of her total damage, whereas the attacks done outside amount
                  to the rest 40%. This goes up or down depending on Core
                  Hitting Accuracy outside Burst.
                </p>
              </Alert>
              <p>
                Let's talk about that Pierce. It is potent against bosses with
                Shield that are not immune to damage when Shield is up, and it
                is also good for hitting multiple mobs or boss parts at the same
                time, effectively doubling or tripling your damage (or more!).
                But, one underrated side effect is that it also allows Asuka to
                utilize Pierce Damage buffs, like the one given by Zwei or Mari.
              </p>
              <p>
                Do note that Attack Damage, Pierce Damage, and most other
                enhancements that end with "Damage" (except for Core &
                Distributed) all belong in the same group, which means they
                dilute each other. Fortunately, the Pierce lasts 25 seconds,
                while that huge ATK DMG buff only lasts for 10 seconds, so there
                is that—the window of opportunity! Likewise, similar to her ATK
                buff, this should have 80~95% overall uptime with CDR.
              </p>
              <Alert variant="primary">
                <p>
                  Since the Pierce lasts 25 seconds, she can use this to
                  kickstart the Burst Energy Generation in the following
                  rotation. Aim where she can hit multiple entities at once!
                </p>
              </Alert>
              <p>
                The Lifesteal looks weak on paper, but given that damage spike,
                it may be enough to fully heal her. However, don't go expect to
                see her tanking all sorts of damage and sustaining injuries that
                would have killed a Noise. However, it is still useful for
                tanking attacks targeting the Nikke with the highest ATK, since
                she does have a lot of ATK herself. To err on the safe side,
                treat as a tool to activate her S1.
              </p>
              <div className="section-scroll" id="section-2">
                <SectionHeader title="DPS vs Alice" />
                <p>Maths is done with these assumptions:</p>
                <ul>
                  <li>
                    Optimum smooth gameplay with no chokes. Campaign is not free
                    from chokes, and players make mistakes.
                  </li>
                  <li>
                    Alice shoots 4 times per second, which is the average
                    tapping speed. Most players shoot somewhere between 3.5-4/s,
                    while tryhards go up to 4.5/s.
                  </li>
                  <li>Asuka has 40% Core Accuracy outside her Burst.</li>
                  <li>
                    Asuka's S2 effect on Alice is discounted from the
                    experiment.
                  </li>
                  <li>Alice & Asuka both enjoy Damage Distance bonus.</li>
                  <li>Both have 12 most optimal OL lines.</li>
                </ul>
                <p>Using these configurations, we discovered that:</p>
                <ul>
                  <li>Asuka's DPS is 90% of Alice with Tia Naga.</li>
                  <li>Asuka's DPS is 95% of Alice with Blanc Noir.</li>
                  <li>Asuka's DPS is 93% of Alice with Crown Privaty.</li>
                  <li>Asuka's DPS is 75% of Alice with Crown Naga.</li>
                </ul>
                <p>
                  Running Asuka and Alice in the same team to compare their
                  damage is not an ideal experiment. One will burst 7 times,
                  whereas the other 6 times. Asuka will also indirectly buff
                  Alice and create a deceptive impression that she herself is
                  weak. A fair testing ground must also be considered (i.e.
                  Indivilia is biased toward SR/MG). Furthermore, if you do
                  4.4-4.5/s on Alice like the elites, that widens the gap above
                  even further.
                </p>
                <p>
                  Take note that Alice also suffers from OL Pressure. She needs
                  2× Charge Speed to function, but those do not really buff her
                  damage beyond that. Meanwhile, Asuka does not suffer from OL
                  Pressure.
                </p>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Usage Analysis and Tier List" />
              <h5>
                Campaign (LD) - <strong className="ss">SS</strong> | Campaign
                (HD) - <strong className="ss">SS</strong>
              </h5>
              <p>
                Nothing beats the old-fashioned way of stacking up as much ATK
                buff as possible. It is obviously the only way to counterbalance
                the stat penalties of CP deficit and make mobs with high DEF
                less of a hassle (Mirror Container go brrr...). However, she has
                no wipe, so if you expect her to clean the screen, you might be
                disappointed. You might as well wait for Rei for that. Asuka's
                playstyle is similar to Alice's, in which her damage spikes upon
                using Burst Skill. She gains Pierce and can decimate multiple
                targets at once, and she will also help accelerate the next
                burst rotation thanks to her long-lasting Pierce.
              </p>
              <p>
                With that said, do note that Asuka may not always be the one
                bursting first. When that happens, she will not have her S1
                active till her rotation (if you do not bring any healer).
                Sometimes, we also do not bring CDR (if using RH B1), so make
                sure to track the downtime.
              </p>
              <p>
                While we do not expect her to outclass Alice or Red Hood, she
                can easily overrun Fire-weak stages thanks to her built-in
                Elemental Damage. Her synergy with Crown will also make that
                Elemental DMG permanent! Together with Rei and Alice/Modernia,
                she can make these stages look like a child's play. Alice and
                Modernia are meta campaign pushers, and Asuka's Core Damage
                boost will overdrive them. We believe they can also be
                force-used for other elements too, but we will see more into
                that after Rei drops.
              </p>
              <h5>
                Boss (Solo) - <strong className="sss">SSS</strong> | Boss (Adds)
                - <strong className="sss">SSS</strong>
              </h5>
              <p>
                Otherworldly offensive and supportive capabilities that raise
                her to another level, which shoots up further against Wind
                enemies. Still an overall versatile profile that should work
                against most bosses with persistent cores.
              </p>
              <p>
                Her damage is about 75~95% of Alice for most players (4
                shots/s). Excellent synergy with Alice and Modernia, making her
                the SAnis of Fire Team (and Rei herself does that too to some
                extent). Great synergy with Crown/Tia against Wind bosses to
                achieve 100% uptime on Elemental DMG, but there is dilution
                happening, so it might not be ideal for minmaxing.
              </p>
              <p>
                Bosses that allow Pierce to shine through annoying shields and
                clustered parts/hitboxes will also elevate her damage. Lifesteal
                also allows her to somewhat tank attacks that target Nikkes of
                the highest ATK.
              </p>
              <h5>
                PVP - <strong className="a">A</strong>
              </h5>
              <p>
                In PvP, she is a mediocre DPS. Her basic attacks hit hard,
                especially against the countered element (w/ ELE DMG OL). You
                can theoretically use her to punish unprotected Blanc, SBS, or
                Noah in P1 before enemy uses Burst Skill. Her performance is
                most similar to Alice. You can bring a healer as well to trigger
                her ATK buff early.
              </p>
              <p>
                Her damage is contained within her Burst Skill, during which she
                forces the activation of S1 and gains ATK DMG. This will make
                her function similarly to Alice that she will continue thrashing
                the targeted unit, but this also means she is countered the same
                way (taunters + immune/indom). One drawback is that she wields
                an AR, so she will feed Jackal/Scarlet, unlike Alice, but this
                might not be that of big deal since not every team has Jackal.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                She scales best with Max Ammo OL alone, but this effect is less
                apparent with Reload Speed buffers (like Crown) in team. With 20
                base ammo and without cube, she spends 1.667s to expend a full
                clip and takes 1.367s second to reload (animation + cover delay
                factored in). This is ~55% uptime, which is pretty low. One Max
                Ammo of 68.93% uplifts this to ~67.4%, which translates to an
                average of 22.5% DPS increase! Imagine what you could get with
                more Max Ammo.
              </p>
              <p>
                After Max Ammo, prioritize Elemental DMG and ATK. Elemental DMG
                is preferred due to the diluted nature of ATK and higher CP
                gain, but as usual, ATK is more universal.
              </p>
              <p>
                The suggestion below assumes the usage of Bastion Lv 7, and
                according to our maths, 2-3× Max Ammo have the highest gain, and
                it falls off slightly after that. You would still want to get
                2-3× even with Reload Speed buffers + Resilience for
                convenience.
              </p>

              <ul>
                <li>
                  <strong>Essential:</strong> 2-3× Max Ammo
                </li>
                <li>
                  <strong>Ideal:</strong> 4× Elemental DMG, 1-2× Max Ammo, 4×
                  ATK
                </li>
                <li>
                  <strong>Passable:</strong> -
                </li>
                <li>
                  <strong>Priority:</strong> Meta
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <ul>
                <li>
                  <strong>Skill 1: 10</strong>
                </li>
                <ul>
                  <li>
                    The bane of it all. This is where her only source of ATK
                    buff is located. Reminiscent of Scarlet. Focus on maxing
                    this first as DPS, but do not abandon the others.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 10</strong>
                </li>
                <ul>
                  <li>
                    Level up if against Wind enemies, more so if the team has a
                    Shielder besides Rei (since Rei only gives 10s). Core Damage
                    is influential and works in any content where core is
                    present. Upgrade to witness teamwide damage boost (Fire Code
                    allies), especially if enemy core is persistent. Focus on
                    maxing this first as Support.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill: 7~10</strong>
                </li>
                <ul>
                  <li>
                    Upon using Burst Skill, she gains Pierce, which is needed to
                    accelerate Burst Rotation and to synergize with Rei. Pierce
                    lasts 15s default, 20s at Level 4, and 25s at Level 7. You
                    also want to reach Level 6+ as soon as possible because that
                    is also the breakpoint for perfect accuracy too. Upgrade
                    further if more damage is desired. Steep scaling on ATK DMG
                    (2.36× as opposed to the regular 1.69x), so maximum level is
                    encouraged.
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="asuka-shikinami-langley" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_3_sm.png"
                alt="Guide"
              />
              <p>
                With Reload Speed buffers, opt for Resilience. Without, use
                Bastion (Lv 7+ highly recommended).
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: Crown Pushing the Limits</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="alice" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="privaty" enablePopover />
                    <NikkeCharacter mode="icon" slug="naga" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Well, we talked earlier that Asuka and Rei are both amazing
                buffers and even considered them the SAnis(es) of Fire Team.
                While Rei's kit does not fully synergize with Alice's playstyle,
                Asuka does, and her Core Damage boost is just insane. No, it's
                not better than Naga's, but you can save Naga for other teams.
                In this team, Crown + Privaty will ensure infinite ammo, while
                D:Wife and Liter will enable smooth rotation. Unless you have a
                good Alice, using Rei to buff Asuka is also a viable strategy.
                If minmaxing is not a concern, you can also use Naga instead of
                Privaty to spice things up!
              </p>
              <h5>Team #2: Dream Alice + Modernia Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="alice" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="modernia" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                One of the nicest Campaign teams at the moment. A variant of the
                OG Naga team, where we barter Naga's Core Damage for Asuka.
                Asuka takes Alice and Modernia to greater heights, and she
                herself has incredible damage. Having 3 DPS is better than 2,
                no? No?
              </p>
              <h5>Team #3: Dream Modernia Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="rosanna-chic-ocean"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="modernia" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                This will help Modernia shine. Both Asuka and Rei need to
                activate their respective Burst Skill(s) to achieve peak
                supportive power. Meanwhile, the only Fire unit currently that
                should not use Burst Skill is Modernia. This makes her the most
                ideal third DPS for this team. Modernia does not have too much
                ATK buff her in her kit, so she will definitely benefit from
                Rei's. Modernia also scales very well with Rei's ATK DMG and
                Asuka's Core DMG. Strong for Campaign and Bossing too! You can
                also choose to use RH B1 as an alternative in Campaign.
              </p>
              <p>
                While Crown is the best buffer, the dilution of ATK DMG is a bit
                too high, and she might be better paired with other units. In
                case of this, S. Rosanna would be a viable alternative, due to
                providing an undiluted Damage Taken debuff.
              </p>
              <h5>Team #4: Bunny Power</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noir" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="modernia" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Ammo Support is crucial to some units in this game, and the OG
                Bunny Duo of Blanc and Noir is the best way to mitigate it.
                Noir's ammo buffs suit all of the 3 units: Rei, Modernia, and
                especially Asuka. If you lack Max Ammo rolls, using Bunnies is a
                viable strat. Similar to S. Rosanna, Blanc's Damage Taken debuff
                is a separate undiluted multiplier and hence a major driver for
                Asuka's DPS. However, Blanc's shield is NOT individual.
              </p>
              <h5>Team #5: That Dolla/SHelm Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="soda" enablePopover />
                    <NikkeCharacter mode="icon" slug="pepper" enablePopover />
                    <NikkeCharacter mode="icon" slug="zwei" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="modernia" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                A healer with a B2 CDR is some standard P4-P5 configuration.
                When against Iron-weak bosses, using SHelm in this team might
                prove feasible. Pepper can continuously heal the team and
                activate Asuka's ATK buff, whereas Zwei can repair cover and
                buff Asuka (with Pierce DMG). Still, Soda as a Fire MG Unit
                might be the better choice here since she benefits from Asuka's
                & Rei's supportive buffs. She also heals the team and triggers
                Asuka's ATK buff!
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="PvP Team Compositions" />
              <h5>Demolish Unprotected P1 Wind Unit</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noah" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="drake" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                A sample setup to quickly kill an unprotected Blanc, Noah, or
                SBS in the first spot. Only Asuka and Rapunzel are essential in
                the team. The rest are replaceable. Needs Elemental DMG OL to
                work.
              </p>
              <h5>Asuka Blanc Variant</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="anis" enablePopover />
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Basic speedy Blanc + Red Hood (Burst 1) team. Lifesteal should
                increase her odds of winning in most 1v1 indomitability
                standoffs.
              </p>
            </div>
            <div className="section-scroll" id="section-7">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Shieldbreak is situationally useful.</li>
                        <li>
                          Very reliable DPS/support when enemy has a persistent
                          core. Has 75~95% of Alice's DMG.
                        </li>
                        <li>
                          Great against Fire-weak enemies because she has
                          Elemental DMG built in.
                        </li>
                        <li>
                          Core Damage buff is rarely diluted and applies to all
                          Fire Code allies, which happen to love cores too!
                          Truly the SAnis of Fire Team!
                        </li>
                        <li>
                          Decent lifesteal allows her to act like a semi-tank in
                          cases where unit of the highest ATK is targeted.
                        </li>
                        <li>
                          Has Pierce, which helps increase burst generation,
                          allows her to manage multiple mobs, and multiplies her
                          damage against bosses with clustered hitboxes/shield.
                        </li>
                        <li>Strong synergy with Rei, Modernia and Alice.</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Limited collab character. Now or never. Can't get
                          copies in the future.
                        </li>
                        <li>Extremely hungry for skill books :(</li>
                        <li>
                          Some bosses do not have persistent cores, and
                          sometimes they are missing too, sabotaging her damage
                          and her team's.
                        </li>
                        <li>
                          Unfortunately, she is picky about buffs and has low
                          scalability due to her damage coming from "too many
                          buffs". Only Damage Taken debuffs scale well for her
                          (and Reload Speed to some extent).
                        </li>
                        <li>
                          Supportive buffs only apply to Fire Code allies.
                        </li>
                        <li>
                          Requires an external Individual Shielder to achieve
                          permanent uptime on Elemental DMG.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6',
                'section-7'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">DPS vs Alice</a>
                </li>
                <li>
                  <a href="#section-3">Tier List</a>
                </li>
                <li>
                  <a href="#section-4">Investments</a>
                </li>
                <li>
                  <a href="#section-5">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-7">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesAsuka;

export const Head: React.FC = () => (
  <Seo
    title="Asuka Shikinami Langley Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Asuka Shikinami Langley in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
