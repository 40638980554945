import React from 'react';
import { GFLCharacter } from '../components/exilium-character';

interface IProps {
  name: string;
}

export const GFLProfileTeams: React.FC<IProps> = ({ name }) => {
  return (
    <>
      {name === 'colphne' && (
        <>
          <p>
            Colphne is a universal Sustain that can be used in any team and she
            only has specific synergy:
          </p>
          <ul>
            <li>
              <GFLCharacter
                slug="tololo"
                mode="inline"
                enablePopover
                showLabel
              />
              - being a Hydro character, Colphne can be used for the purpose of
              stacking Tololo's Lightspike passive.
            </li>
          </ul>
        </>
      )}
      {name === 'cheeta' && (
        <>
          <p>
            Cheeta doesn't have any specific synergies and she can be paired
            with anyone.
          </p>
        </>
      )}
      {name === 'groza' && (
        <>
          <ul>
            <li>
              <GFLCharacter
                slug="qiongjiu"
                mode="inline"
                enablePopover
                showLabel
              />
              - her Counters can trigger Qiongjiu Action Support, helping her
              with reaching the 3/4 cap per turn.
            </li>
          </ul>
        </>
      )}
      {name === 'krolik' && (
        <>
          <ul>
            <li>
              <GFLCharacter
                slug="sharkry"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - Krolik can apply Overburn to enemies with her S2 and Passive
              which helps Sharkry with triggering her Action Support
              consistently and grants other bonuses.
            </li>
          </ul>
        </>
      )}
      {name === 'ksenia' && (
        <>
          <h6>Global Synergies:</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="sharkry"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - starting from V1, Ksenia can apply Overburn debuff to enemies,
              which helps Sharkry with triggering her Action Support
              consistently,
            </li>
            <li>
              <GFLCharacter
                slug="qiongjiu"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - Ksenia can apply Flammable Items debuff to enemies that
              amplifies Burn damage,
            </li>
            <li>
              <GFLCharacter
                slug="makiatto"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              <GFLCharacter
                slug="mosin-nagant"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              & other single-target DPS - they can trigger Ksenia’s Action
              Support and gain ATK buffs in return.
            </li>
          </ul>
          <h6>Additional CN Synergies:</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="klukay"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - the ATK buff is great for Klukay, since she already has a high
              base ATK.
            </li>
          </ul>
        </>
      )}
      {name === 'littara' && (
        <>
          <h6>Global Synergies:</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="peritya"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              Littara is an AoE partner for Peritya in Global - there’s no
              synergy between them outside of the fact that she can deal AoE
              damage to help Peritya trigger her Action Support.
            </li>
          </ul>
        </>
      )}
      {name === 'lotta' && (
        <>
          <h6>Global Synergies:</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="peritya"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              Lotta is an AoE partner for Peritya in Global - there’s no synergy
              between them outside of the fact that she can deal AoE damage to
              help Peritya trigger her Action Support.
            </li>
          </ul>
        </>
      )}
      {name === 'makiatto' && (
        <>
          <h6>Global Synergies:</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="qiongjiu"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - Makiatto's Ambush can trigger QJ's Action Support attacks,
              increasing the team's overall damage output. QJ can also apply DEF
              Down, helping with increasing Makiatto's DPS.
            </li>
            <li>
              <GFLCharacter
                slug="suomi"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              can apply Freeze weakness, increasing Makiatto's damage output and
              also Suomi's shield has higher scaling than the one Makiatto can
              apply to herself resulting in a stronger shield, so you don't have
              to worry that it will break and you will lose the ability to
              Ambush. If you have Suomi Signature Weapon or her V2, Makiatto's
              damage output will increase further,
            </li>
            <li>
              <GFLCharacter
                slug="ksenia"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              is a great ATK buffer - something that the Freeze team lacks as
              neither Suomi or Dush can apply that buff.
            </li>
          </ul>
          <h6>Additional CN Synergies:</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="dushevnaya"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              passive and Signature weapon provide buffs to Freeze allies, so
              Makiatto can benefit from them and her Ultimate is another small
              buff. Sadly, that’s all you will get at V0 and you will need to
              get V6 for more which makes Dush a very expensive investment.
            </li>
          </ul>
        </>
      )}
      {name === 'mosin-nagant' && (
        <>
          <h6>Global Synergies:</h6>
          <p>There are no specific synergies available in Global.</p>
          <h6>Additional CN Synergies:</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="andoris"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              can inflict Negative Charge on enemies and Mosin-Nagant can
              trigger it easily since she deals Electric damage,
            </li>
            <li>
              <GFLCharacter
                slug="belka"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              same reasoning as for Andoris,
            </li>
            <li>
              <GFLCharacter
                slug="lenna"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              can inflict Electric weakness on enemies - that instantly makes
              her a good ‘support’ for Mosin-Nagant.
            </li>
          </ul>
        </>
      )}
      {name === 'nagant' && (
        <>
          <p>
            Nagant doesn't really have any synergies and she's used as a flex
            pick for DEF Down applications.
          </p>
        </>
      )}
      {name === 'nemesis' && (
        <>
          <p>
            Nemesis doesn't really have any synergies and she's used as a flex
            pick for DEF Down applications.
          </p>
        </>
      )}
      {name === 'papasha' && (
        <>
          <p>
            Papasha is mainly used in Guild Boss content due to her ability to
            cheese some of its mechanics. Outside of that, she has one dedicated
            synergy:
          </p>
          <ul>
            <li>
              <GFLCharacter
                slug="qiongjiu"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - the summon can trigger Qiongjiu Action Support attacks, helping
              with reaching the 3/4 cap per turn.
            </li>
          </ul>
        </>
      )}
      {name === 'peritya' && (
        <>
          <h6>Global Synergies:</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="littara"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              &{' '}
              <GFLCharacter
                slug="lotta"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              are the two AoE partners for Peritya in Global - there’s no
              synergy between them outside of the fact that they can deal AoE
              damage to help Peritya trigger her Action Support,
            </li>
            <li>
              <GFLCharacter
                slug="ksenia"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              &{' '}
              <GFLCharacter
                slug="cheeta"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - are generic ATK buffers as the Corrosion element lacks ATK buffs
              in their teams. Cheeta also has an AoE attack available in her kit
              to help with the AoE requirements of Peritya’s passive.
            </li>
          </ul>
          <h6>Additional CN Synergies:</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="mechty"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              converts Peritya’s Action Support attacks to Corrosion and
              provides additional buffs and debuffs that amplify Corrosion
              damage taken by the enemies,
            </li>
            <li>
              <GFLCharacter
                slug="klukay"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              is the pair made in heaven - and it’s a two-way street. All
              Klukay’s attacks are AoE, so it helps with Peritya Action Support,
              but to make things even better, Klukay can trigger all 6 (at V0)
              of them every turn (in AoE situation) and up to 3 against a single
              enemy (boss). In return, Peritya can trigger Klukay’s passive and
              help her reduce her cooldowns much faster than other characters.
            </li>
          </ul>
        </>
      )}
      {name === 'sabrina' && (
        <>
          <h6>Global Synergies:</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="qiongjiu"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - Sabrina counters work with QJ’s Action Support, giving her more
              activations per turn,
            </li>
            <li>
              <GFLCharacter
                slug="tololo"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - Sabrina can apply Hydro weakness to improve Tololo’s damage, but
              she also helps her with stacking the Lightspike buff faster,
            </li>
          </ul>
          <h6>Additional CN Synergies:</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="springfield"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              is the premium support for Hydro characters who can provide
              overheal and damage buffs, but also restore Confectance Index
              points (with V1).
            </li>
          </ul>
        </>
      )}
      {name === 'sharkry' && (
        <>
          <h6>Global Synergies:</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="qiongjiu"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              at V0 can apply Overburn to enemies with her S2, but also apply
              DEF Down with her Action Supports, helping Sharkry with her DPS.
              Once Qiongjiu gets her V3 unlocked, the synergy is drastically
              amplified as now she can apply Overburn on her Action Support too,
            </li>
            <li>
              <GFLCharacter
                slug="krolik"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              can apply Overburn to enemies with her S2 and Passive which helps
              Sharkry with triggering her Action Support consistently and grants
              other bonuses,
            </li>
            <li>
              <GFLCharacter
                slug="ksenia"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              can apply Overburn debuff which helps Sharkry with triggering her
              Action Support consistently and grants other bonuses.
            </li>
          </ul>
          <h6>Additional CN Synergies:</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="vector"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              is the premium support for Burn teams who not only can apply
              Overburn, but also gives a plethora of buffs to help Sharkry,
            </li>
            <li>
              <GFLCharacter
                slug="centaureissi"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              is the premium sustain for Burn teams, providing the team with
              Overburn and other buffs that amplify Burn DPS damage output.
            </li>
          </ul>
        </>
      )}
      {name === 'suomi' && (
        <>
          <p>
            Suomi is very versatile and can be slotted into any team when you
            need a Support, but if you're looking for any specific synergies,
            here's what is available to her:
          </p>
          <ul>
            <li>
              <GFLCharacter
                slug="makiatto"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - Suomi can apply Freeze weakness, increasing Makiatto's damage
              output and also Suomi's shield has higher scaling than the one
              Makiatto can apply to herself resulting in a stronger shield, so
              you don't have to worry that it will break and you will lose the
              ability to Ambush. If you have Suomi Signature Weapon or her V2,
              Makiatto's damage output will increase further
            </li>
          </ul>
        </>
      )}
      {name === 'tololo' && (
        <>
          <h6>Global Synergies:</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="qiongjiu"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - Tololo can attack multiple times per turn, triggering Qiongjiu
              Action Support,
            </li>
            <li>
              <GFLCharacter
                slug="sabrina"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              can apply Hydro weakness to improve Tololo’s damage, but she also
              helps her with stacking the Lightspike buff faster,
            </li>
            <li>
              <GFLCharacter
                slug="colphne"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              helps with stacking the Lightspike buff - our options for Hydro
              characters in Global are quite limited.
            </li>
          </ul>
          <h6>Additional CN Synergies:</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="springfield"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              is the premium support for Hydro characters who can provide
              overheal and damage buffs, but also restore Confectance Index
              points (with V1).
            </li>
          </ul>
        </>
      )}
      {name === 'qiongjiu' && (
        <>
          <h6>Global Synergies:</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="sharkry"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - Qiongjiu at V0 can apply Overburn to enemies with her S2, but
              also apply DEF Down with her Action Supports, helping Sharkry with
              her DPS. Once Qiongjiu gets her V3 unlocked, the synergy is
              drastically amplified as now she can apply Overburn on her Action
              Support too,
            </li>
            <li>
              <GFLCharacter
                slug="makiatto"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              Ambush can trigger QJ's Action Support attacks, increasing the
              team's overall damage output. QJ can also apply DEF Down, helping
              with increasing Makiatto's DPS,
            </li>
            <li>
              <GFLCharacter
                slug="tololo"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              &{' '}
              <GFLCharacter
                slug="ullrid"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              are two great multi-attackers who can help Qiongjiu with
              triggering her Action Support more often. In return, she provides
              them with DEF Down debuff,
            </li>
            <li>
              <GFLCharacter
                slug="sabrina"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              &{' '}
              <GFLCharacter
                slug="groza"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - their Counters can trigger Qiongjiu Action Support.
            </li>
          </ul>
          <h6>Additional CN Synergies:</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="vector"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              is the premium support for Burn teams, giving increased Burn
              damage and Burn weakness, but most importantly she increases the
              number of Action Supports Qiongjiu can do every turn.
            </li>
            <li>
              <GFLCharacter
                slug="centaureissi"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              is the premium sustain for Burn teams, providing the team with
              Overburn and other buffs that amplify Burn DPS damage output.
            </li>
          </ul>
        </>
      )}
      {name === 'ullrid' && (
        <>
          <h6>Global Synergies:</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="qiongjiu"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - Ullrid can attack multiple times per turn which helps QJ with
              consistently triggering her Action Support and in return, the
              enemy gets DEF Down, improving her own damage.
            </li>
          </ul>
          <h6>Additional CN Synergies:</h6>
          <ul>
            <li>
              <GFLCharacter slug="faye" mode="inline" enablePopover showLabel />{' '}
              provides Physical DMG taken debuff.
            </li>
          </ul>
        </>
      )}
      {name === 'vepley' && (
        <>
          <p>
            Vepley doesn't really have any synergies that make her or her
            partner stronger - she's mainly used in Bossing for her 20% Damage
            Taken debuff, so she can be slotted anywhere you need it.
          </p>
        </>
      )}
      {name === 'daiyan' && (
        <>
          <h6>Global Synergies:</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="qiongjiu"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - Daiyan can attack multiple times per turn which helps QJ with
              consistently triggering her Action Support and in return, the
              enemy gets DEF Down, improving her own damage.
            </li>
          </ul>
        </>
      )}
    </>
  );
};
