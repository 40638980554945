export const ZZZDeadlyChars = [
  {
    value: 'anby-demara',
    text: 'Anby'
  },
  {
    value: 'anton',
    text: 'Anton'
  },
  {
    value: 'ben',
    text: 'Ben'
  },
  {
    value: 'billy-kid',
    text: 'Billy'
  },
  {
    value: 'burnice',
    text: 'Burnice'
  },
  {
    value: 'caesar',
    text: 'Caesar'
  },
  {
    value: 'corin',
    text: 'Corin'
  },
  {
    value: 'ellen',
    text: 'Ellen'
  },
  {
    value: 'grace-howard',
    text: 'Grace'
  },
  {
    value: 'harumasa',
    text: 'Harumasa'
  },
  {
    value: 'jane-doe',
    text: 'Jane Doe'
  },
  {
    value: 'koleda',
    text: 'Koleda'
  },
  {
    value: 'lighter',
    text: 'Lighter'
  },
  {
    value: 'lucy',
    text: 'Lucy'
  },
  {
    value: 'lycaon',
    text: 'Lycaon'
  },
  {
    value: 'miyabi',
    text: 'Miyabi'
  },
  {
    value: 'nekomata',
    text: 'Nekomata'
  },
  {
    value: 'nicole-demara',
    text: 'Nicole'
  },
  {
    value: 'piper',
    text: 'Piper'
  },
  {
    value: 'qingyi',
    text: 'Qingyi'
  },
  {
    value: 'rina',
    text: 'Rina'
  },
  {
    value: 'seth',
    text: 'Seth'
  },
  {
    value: 'soldier-11',
    text: 'Soldier 11'
  },
  {
    value: 'soukaku',
    text: 'Soukaku'
  },
  {
    value: 'yanagi',
    text: 'Yanagi'
  },
  {
    value: 'zhu-yuan',
    text: 'Zhu Yuan'
  }
];
