import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import './hsr-guides.scss';
import { ProfileMCvsFugue } from '../../../modules/hsr/profile/mc-fugue';

const HSRGuidesFugue: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page hsr-guide spa-bro'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Harmony MC vs Fugue - who is better?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_fumc.webp"
            alt="Harmony MC vs Fugue"
          />
        </div>
        <div className="page-details">
          <h1>Harmony MC vs Fugue - who is better?</h1>
          <h2>
            A comparison between Harmony MC and Fugue showing who is better at
            being the Super Break support!
          </h2>
          <p>
            Last updated: <strong>25/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Harmony MC vs Fugue?" />
        <ProfileMCvsFugue />
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesFugue;

export const Head: React.FC = () => (
  <Seo
    title="Harmony MC vs Fugue - who is better? | Honkai: Star Rail | Prydwen Institute"
    description="A comparison between Harmony MC and Fugue showing who is better at being the Super Break support!"
  />
);
