export const ZZZCharStatsFullJSON = [
  {
    character: 'nicole-demara',
    slug: 'Nicole',
    hp: 8146,
    def: 623,
    atk: 649,
    impact: 88,
    critRate: 5,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.56,
    ano_proficiency: 90,
    ano_mastery: 93
  },
  {
    character: 'ben',
    slug: 'Ben',
    hp: 8578,
    def: 724,
    atk: 653,
    impact: 95,
    critRate: 5,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.56,
    ano_proficiency: 86,
    ano_mastery: 90
  },
  {
    character: 'anby-demara',
    slug: 'Anby',
    hp: 7501,
    def: 613,
    atk: 659,
    impact: 136,
    critRate: 5,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.2,
    ano_proficiency: 94,
    ano_mastery: 93
  },
  {
    character: 'lucy',
    slug: 'Lucy',
    hp: 8026,
    def: 613,
    atk: 659,
    impact: 86,
    critRate: 5,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.56,
    ano_proficiency: 94,
    ano_mastery: 93
  },
  {
    character: 'soukaku',
    slug: 'Soukaku',
    hp: 8026,
    def: 598,
    atk: 666,
    impact: 86,
    critRate: 5,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.56,
    ano_proficiency: 93,
    ano_mastery: 96
  },
  {
    character: 'rina',
    slug: 'Rina',
    hp: 8609,
    def: 601,
    atk: 717,
    impact: 83,
    critRate: 5,
    critDmg: 50,
    penRatio: 14.4,
    energyRegen: 1.2,
    ano_proficiency: 93,
    ano_mastery: 92
  },
  {
    character: 'lycaon',
    slug: 'Lycaon',
    hp: 8416,
    def: 607,
    atk: 729,
    impact: 137,
    critRate: 5,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.2,
    ano_proficiency: 91,
    ano_mastery: 90
  },
  {
    character: 'koleda',
    slug: 'Koleda',
    hp: 8127,
    def: 595,
    atk: 736,
    impact: 134,
    critRate: 5,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.2,
    ano_proficiency: 97,
    ano_mastery: 96
  },
  {
    character: 'piper',
    slug: 'Piper',
    hp: 6977,
    def: 613,
    atk: 758,
    impact: 86,
    critRate: 5,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.56,
    ano_proficiency: 116,
    ano_mastery: 118
  },
  {
    character: 'billy-kid',
    slug: 'Billy',
    hp: 6907,
    def: 607,
    atk: 787,
    impact: 91,
    critRate: 19.4,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.2,
    ano_proficiency: 92,
    ano_mastery: 91
  },
  {
    character: 'anton',
    slug: 'Anton',
    hp: 7219,
    def: 623,
    atk: 792,
    impact: 95,
    critRate: 19.4,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.2,
    ano_proficiency: 86,
    ano_mastery: 90
  },
  {
    character: 'corin',
    slug: 'Corin',
    hp: 6977,
    def: 605,
    atk: 807,
    impact: 93,
    critRate: 5,
    critDmg: 78.8,
    penRatio: 0,
    energyRegen: 1.2,
    ano_proficiency: 93,
    ano_mastery: 96
  },
  {
    character: 'grace-howard',
    slug: 'Grace',
    hp: 7483,
    def: 601,
    atk: 826,
    impact: 83,
    critRate: 5,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.2,
    ano_proficiency: 115,
    ano_mastery: 152
  },
  {
    character: 'soldier-11',
    slug: 'Soldier 11',
    hp: 7674,
    def: 613,
    atk: 889,
    impact: 93,
    critRate: 19.4,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.2,
    ano_proficiency: 94,
    ano_mastery: 93
  },
  {
    character: 'nekomata',
    slug: 'Nekomata',
    hp: 7560,
    def: 588,
    atk: 911,
    impact: 92,
    critRate: 19.4,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.2,
    ano_proficiency: 97,
    ano_mastery: 96
  },
  {
    character: 'zhu-yuan',
    slug: 'Zhu Yuan',
    hp: 7483,
    def: 601,
    atk: 919,
    impact: 90,
    critRate: 5,
    critDmg: 78.8,
    penRatio: 0,
    energyRegen: 1.2,
    ano_proficiency: 93,
    ano_mastery: 92
  },
  {
    character: 'ellen',
    slug: 'Ellen',
    hp: 7674,
    def: 607,
    atk: 938,
    impact: 93,
    critRate: 19.4,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.2,
    ano_proficiency: 94,
    ano_mastery: 93
  },
  {
    character: 'qingyi',
    slug: 'Qingyi',
    hp: 8251,
    def: 613,
    atk: 758,
    impact: 136,
    critRate: 5,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.2,
    ano_proficiency: 93,
    ano_mastery: 94
  },
  {
    character: 'jane-doe',
    slug: 'Jane Doe',
    hp: 7789,
    def: 607,
    atk: 881,
    impact: 86,
    critRate: 5,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.2,
    ano_proficiency: 112,
    ano_mastery: 150
  },
  {
    character: 'seth',
    slug: 'Seth',
    hp: 8701,
    def: 746,
    atk: 643,
    impact: 94,
    critRate: 5,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.56,
    ano_proficiency: 86,
    ano_mastery: 90
  },
  {
    character: 'caesar',
    slug: 'Caesar',
    hp: 9526,
    def: 754,
    atk: 712,
    impact: 123,
    critRate: 5,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.2,
    ano_proficiency: 90,
    ano_mastery: 87
  },
  {
    character: 'burnice',
    slug: 'Burnice',
    hp: 7368,
    def: 601,
    atk: 863,
    impact: 83,
    critRate: 5,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.56,
    ano_proficiency: 120,
    ano_mastery: 118
  },
  {
    character: 'yanagi',
    slug: 'Yanagi',
    hp: 7789,
    def: 613,
    atk: 873,
    impact: 86,
    critRate: 5,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.2,
    ano_proficiency: 114,
    ano_mastery: 148
  },
  {
    character: 'lighter',
    slug: 'Lighter',
    hp: 8253,
    def: 612,
    atk: 797,
    impact: 137,
    critRate: 5,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.2,
    ano_proficiency: 90,
    ano_mastery: 91
  },
  {
    character: 'miyabi',
    slug: 'Miyabi',
    hp: 7673,
    def: 606,
    atk: 880,
    impact: 86,
    critRate: 5,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.2,
    ano_proficiency: 238,
    ano_mastery: 116
  },
  {
    character: 'harumasa',
    slug: 'Harumasa',
    hp: 7405,
    def: 600,
    atk: 915,
    impact: 90,
    critRate: 19.4,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.2,
    ano_proficiency: 95,
    ano_mastery: 80
  },
  {
    character: 'astra-yao',
    slug: 'Astra Yao',
    hp: 8609,
    def: 600,
    atk: 715,
    impact: 83,
    critRate: 5,
    critDmg: 50,
    penRatio: 0,
    energyRegen: 1.56,
    ano_proficiency: 92,
    ano_mastery: 93
  }
];
