import React, { useEffect, useReducer, useState } from 'react';
import { Link, graphql } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { Alert, Table } from 'react-bootstrap';
import { IAFKCharacter } from '../../../modules/common/model/graphql-types';
import { faAsterisk, faSortAlphaUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lodash from 'lodash';
import { FilterBar } from '../../../modules/cs/common/components/filter-bar/filter-bar';

interface IAfkCharacterNodes {
  nodes: IAFKCharacter[];
}

interface IAfkCharacterEntry {
  allCharacters: IAfkCharacterNodes;
}

interface IProps {
  data: IAfkCharacterEntry;
}

const AFKGuidesEX: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'A',
            tooltip: 'A Rarity',
            image: (
              <StaticImage
                src="../../../images/afk/icons/rarity_a.png"
                width={24}
                alt="A"
              />
            )
          },
          {
            value: 'S',
            tooltip: 'S Rarity',
            image: (
              <StaticImage
                src="../../../images/afk/icons/rarity_s.png"
                width={24}
                alt="S"
              />
            )
          }
        ]
      },
      {
        key: 'faction',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Graveborn',
            tooltip: 'Graveborn',
            image: (
              <StaticImage
                src="../../../images/afk/icons/faction_grave.png"
                width={24}
                alt="Graveborn"
              />
            )
          },
          {
            value: 'Lightbearer',
            tooltip: 'Lightbearer',
            image: (
              <StaticImage
                src="../../../images/afk/icons/faction_light.png"
                width={24}
                alt="Lightbearer"
              />
            )
          },
          {
            value: 'Mauler',
            tooltip: 'Mauler',
            image: (
              <StaticImage
                src="../../../images/afk/icons/faction_mauler.png"
                width={24}
                alt="Mauler"
              />
            )
          },
          {
            value: 'Wilder',
            tooltip: 'Wilder',
            image: (
              <StaticImage
                src="../../../images/afk/icons/faction_wilder.png"
                width={24}
                alt="Wilder"
              />
            )
          },
          {
            value: 'Celestial',
            tooltip: 'Celestial',
            image: (
              <StaticImage
                src="../../../images/afk/icons/faction_cele.png"
                width={24}
                alt="Celestial"
              />
            )
          },
          {
            value: 'Hypogean',
            tooltip: 'Hypogean',
            image: (
              <StaticImage
                src="../../../images/afk/icons/faction_hypo.png"
                width={24}
                alt="Hypogean"
              />
            )
          }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Mage',
            tooltip: 'Mage',
            image: (
              <StaticImage
                src="../../../images/afk/icons/class_mage.png"
                width={24}
                alt="Mage"
              />
            )
          },
          {
            value: 'Marksman',
            tooltip: 'Marksman',
            image: (
              <StaticImage
                src="../../../images/afk/icons/class_mark.png"
                width={24}
                alt="Marksman"
              />
            )
          },
          {
            value: 'Rogue',
            tooltip: 'Rogue',
            image: (
              <StaticImage
                src="../../../images/afk/icons/class_rogue.png"
                width={24}
                alt="Rogue"
              />
            )
          },
          {
            value: 'Support',
            tooltip: 'Support',
            image: (
              <StaticImage
                src="../../../images/afk/icons/class_support.png"
                width={24}
                alt="Support"
              />
            )
          },
          {
            value: 'Tank',
            tooltip: 'Tank',
            image: (
              <StaticImage
                src="../../../images/afk/icons/class_tank.png"
                width={24}
                alt="Tank"
              />
            )
          },
          {
            value: 'Warrior',
            tooltip: 'Warrior',
            image: (
              <StaticImage
                src="../../../images/afk/icons/class_warrior.png"
                width={24}
                alt="Warrior"
              />
            )
          }
        ]
      },
      {
        key: 'damage',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Magic',
            tooltip: 'Magic',
            image: (
              <StaticImage
                src="../../../images/afk/icons/damage_magic.png"
                width={24}
                alt="Magic"
              />
            )
          },
          {
            value: 'Physical',
            tooltip: 'Physical',
            image: (
              <StaticImage
                src="../../../images/afk/icons/damage_phys.png"
                width={24}
                alt="Physical"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.rarity.indexOf(activeFilters.rarity) > -1
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.damage && activeFilters.damage !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.damageType.toLowerCase() === activeFilters.damage.toLowerCase()
        );
      }
      if (activeFilters.range && activeFilters.range !== 'all') {
        filtered = filtered.filter((emp) => emp.range === activeFilters.range);
      }
      if (activeFilters.faction && activeFilters.faction !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.faction &&
            emp.faction.length > 0 &&
            emp.faction.indexOf(activeFilters.faction) > -1
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    } else if (sortOption === 'priority') {
      filtered = filtered.sort((a, b) =>
        a.exPriority < b.exPriority ? 1 : -1
      );
    }

    setVisibleCharacters(filtered);
    forceUpdate();
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: 'priority', label: 'Sort by Priority' },
    { value: '+fullName', label: 'Sort by A-Z' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Exclusive Weapon Priority List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_ex.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Exclusive Weapon Priority List</h1>
          <h2>
            Which AFK Journey characters need their Exclusive Weapon and how far
            should you upgrade them.
          </h2>
          <p>
            Last updated: <strong>09/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="About Exclusive Weapons" />
        <StaticImage
          src="../../../images/afk/generic/beginner_9.webp"
          alt="Guides"
        />
        <p>
          <strong>
            When you upgrade a character to their{' '}
            <strong className="mythic">Mythic+</strong> tier, their Exclusive
            Item becomes available to unlock and upgrade
          </strong>
          . This Exclusive Item grants a set of stats tailored towards the
          character it is for and{' '}
          <strong>unlocks a 4th ability in their kit</strong> (known as an EX or
          Exclusive Skill).
        </p>
        <Table striped bordered responsive className="afk-ex">
          <thead>
            <tr>
              <th>EX Levels</th>
              <th>New passive?</th>
              <th>Stone (total)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>EX+1 to EX+5</td>
              <td>
                <strong className="green">Yes</strong>
              </td>
              <td>
                <div className="combined">
                  150x <AFKItem name="Tidal Essence" />
                </div>
              </td>
            </tr>
            <tr>
              <td>EX+6 to EX+10</td>
              <td>
                <strong className="green">Yes</strong>
              </td>
              <td>
                <div className="combined">
                  75x <AFKItem name="Temporal Essence" />
                </div>
              </td>
            </tr>
            <tr>
              <td>EX+11 to EX+15</td>
              <td>
                <strong className="green">Yes</strong>
              </td>
              <td>
                <div className="combined">
                  200x <AFKItem name="Temporal Essence" />
                </div>
              </td>
            </tr>
            <tr>
              <td>EX+16 to EX+20</td>
              <td>
                <strong className="red">No</strong>
              </td>
              <td>
                <div className="combined">
                  125x <AFKItem name="Twilight Essence" />
                </div>
              </td>
            </tr>
            <tr>
              <td>EX+21 to EX+25</td>
              <td>
                <strong className="red">No</strong>
              </td>
              <td>
                <div className="combined">
                  500x <AFKItem name="Twilight Essence" />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Exclusive Weapon Priority" />
        <Alert variant="primary">
          <p className="intro">
            This guide was created with the help of <strong>Seas0n</strong>, the
            creator of the{' '}
            <StaticImage
              src="../../../images/afk/icons/afkanalytica.png"
              alt="AFK Analytica"
            />{' '}
            <strong className="yellow">AFK Analytica</strong> community that
            supports AFK Arena and AFK Journey.
          </p>
          <p>
            You can find their website{' '}
            <a
              href="https://www.afkanalytica.com/"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{' '}
            and their discord{' '}
            <a
              href="https://www.discord.gg/analytica"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </p>
        </Alert>
        <p>
          Since the <AFKItem name="Temporal Essence" /> and{' '}
          <AFKItem name="Twilight Essence" /> are very hard to obtain, you have
          to be really careful with the using them to upgrade the EX weapon. And
          in this guide we{' '}
          <strong>
            ordered all characters by the priority when it comes to how much
            they need investment
          </strong>{' '}
          in their EX weapon to work well and where should you stop to get the
          best bang for your buck.
        </p>
        <p>
          <strong>Why does the +8 breakpoint appear so often?</strong>
        </p>
        <p>
          Because the raw stats you get from upgrading the EX weapon help you
          more than the passive in some cases. Also, to upgrade the EX from +5
          to +8 you need only 30x <AFKItem name="Temporal Essence" />, while the
          remaining two levels require additional 45x{' '}
          <AFKItem name="Temporal Essence" />. So you get the best bang for your
          buck stopping at +8.
        </p>
        <p>
          <strong>Color explanation:</strong>
        </p>
        <ul>
          <li>
            <strong>+0</strong> - the base investment for the character,
          </li>
          <li>
            <strong className="min">+5/8</strong> - the next step that you
            should do when investing into that character,
          </li>
          <li>
            <strong className="rec">+10/15</strong> - the final step you should
            do when investing into that character - you can go beyond that later
            though once you have the Essences to spare.
          </li>
        </ul>
        <hr />
        <div className="employees-filter-bar afk">
          <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
        </div>
        <div className="afk-ex-prio">
          <div className="afk-ex-row header">
            <div
              className="column character"
              onClick={() => setSortOption('+fullName')}
            >
              Character
              {sortOption && sortOption === '+fullName' && (
                <span className="icon">
                  <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                </span>
              )}
            </div>
            <div
              className="column priority"
              onClick={() => setSortOption('priority')}
            >
              Priority{' '}
              {sortOption && sortOption === 'priority' && (
                <span className="icon">
                  <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                </span>
              )}
            </div>
            <div className="column top-ex">PVE investment</div>
            <div className="column top-ex">PVP investment</div>
          </div>
          {visibleCharacters.map((emp, index) => {
            return (
              <div className="afk-ex-row data" key={index}>
                <div className="column character">
                  <AFKCharacter
                    slug={emp.slug}
                    mode="inline"
                    showLabel
                    enablePopover
                  />
                </div>
                <div className="column priority">
                  <p className="on-mobile">Priority</p>
                  <p className={`prio-${emp.exPriority ? emp.exPriority : ''}`}>
                    {emp.exPriority ? (
                      <>
                        {emp.exPriority === '1' && <>Low</>}
                        {emp.exPriority === '2' && <>Medium</>}
                        {emp.exPriority === '3' && <>High</>}
                        {emp.exPriority === '4' && <>Very High</>}
                      </>
                    ) : (
                      <>-</>
                    )}
                  </p>
                </div>
                <div className="column ex">
                  <p className="on-mobile">PVE investment</p>
                  {emp.exNew ? (
                    <p>
                      <strong className="base">+{emp.exNew.base}</strong>{' '}
                      {emp.exNew.pve_early != 0 && (
                        <>
                          &rarr;{' '}
                          <strong className="low">
                            +{emp.exNew.pve_early}
                          </strong>
                        </>
                      )}{' '}
                      {emp.exNew.pve_late != 0 && (
                        <>
                          &rarr;{' '}
                          <strong className="end">+{emp.exNew.pve_late}</strong>
                        </>
                      )}
                    </p>
                  ) : (
                    '-'
                  )}
                </div>
                <div className="column ex">
                  <p className="on-mobile">PVP investment</p>
                  {emp.exNew ? (
                    <p>
                      {emp.slug === 'cassadee' ||
                      emp.slug === 'korin' ||
                      emp.slug === 'marilee' ||
                      emp.slug === 'kruger' ? (
                        <strong className="base">+0 </strong>
                      ) : (
                        <strong className="base">+{emp.exNew.base} </strong>
                      )}
                      {emp.exNew.pvp_early != 0 && (
                        <>
                          &rarr;{' '}
                          <strong className="low">
                            +{emp.exNew.pvp_early}
                          </strong>
                        </>
                      )}{' '}
                      {emp.exNew.pvp_late != 0 && (
                        <>
                          &rarr;{' '}
                          <strong className="end">+{emp.exNew.pvp_late}</strong>
                        </>
                      )}
                    </p>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesEX;

export const Head: React.FC = () => (
  <Seo
    title="Exclusive Weapon Priority List | AFK Journey | Prydwen Institute"
    description="Which AFK Journey characters need their Exclusive Weapon and how far should you upgrade them."
    game="afk"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulAfkCharacter(
      sort: { fields: exPriority, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        rarity
        class
        faction
        damageType
        range
        exPriority
        exStop
        exNew {
          base
          pve_late
          pvp_late
          pve_early
          pvp_early
        }
      }
    }
  }
`;
