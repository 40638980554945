export const MoCCurrent12_1 = [
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 6.62,
    rank: 1,
    avg_round: 8.77,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 4.53,
    rank: 2,
    avg_round: 6.42,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 3.69,
    rank: 3,
    avg_round: 8.84,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 3.23,
    rank: 4,
    avg_round: 9.22,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 3.13,
    rank: 5,
    avg_round: 9.15,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 3.08,
    rank: 6,
    avg_round: 8.79,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 2.7,
    rank: 7,
    avg_round: 8.4,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 2.59,
    rank: 8,
    avg_round: 8.3,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 2.35,
    rank: 9,
    avg_round: 8.23,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 2.34,
    rank: 10,
    avg_round: 9.36,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 2.29,
    rank: 11,
    avg_round: 9.03,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 2.27,
    rank: 12,
    avg_round: 8.42,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'aventurine',
    app_rate: 1.95,
    rank: 13,
    avg_round: 9.44,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 1.85,
    rank: 14,
    avg_round: 8.8,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 1.77,
    rank: 15,
    avg_round: 8.95,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 1.57,
    rank: 16,
    avg_round: 7.63,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 1.5,
    rank: 17,
    avg_round: 8.19,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 1.42,
    rank: 18,
    avg_round: 9.14,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'huohuo',
    app_rate: 1.39,
    rank: 19,
    avg_round: 9.52,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 1.38,
    rank: 20,
    avg_round: 8.05,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 1.16,
    rank: 21,
    avg_round: 9.38,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 1.14,
    rank: 22,
    avg_round: 8.46,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 1.05,
    rank: 23,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.96,
    rank: 24,
    avg_round: 9.57,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.95,
    rank: 25,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.79,
    rank: 26,
    avg_round: 9.44,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.77,
    rank: 27,
    avg_round: 8.81,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.72,
    rank: 28,
    avg_round: 8.69,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'gallagher',
    app_rate: 0.67,
    rank: 29,
    avg_round: 8.84,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.65,
    rank: 30,
    avg_round: 9.1,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.63,
    rank: 31,
    avg_round: 8.64,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.55,
    rank: 32,
    avg_round: 8.1,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.51,
    rank: 33,
    avg_round: 8.39,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.51,
    rank: 33,
    avg_round: 8.86,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.49,
    rank: 35,
    avg_round: 7.85,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.49,
    rank: 35,
    avg_round: 8.89,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.48,
    rank: 37,
    avg_round: 9.56,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.48,
    rank: 37,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.45,
    rank: 39,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.44,
    rank: 40,
    avg_round: 8.57,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.43,
    rank: 41,
    avg_round: 7.7,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.42,
    rank: 42,
    avg_round: 9.09,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.41,
    rank: 43,
    avg_round: 7.81,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.41,
    rank: 43,
    avg_round: 7.43,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.4,
    rank: 45,
    avg_round: 8.77,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.4,
    rank: 45,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.4,
    rank: 45,
    avg_round: 9.09,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.38,
    rank: 48,
    avg_round: 8.88,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.38,
    rank: 48,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'luocha',
    app_rate: 0.38,
    rank: 48,
    avg_round: 9.07,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.37,
    rank: 51,
    avg_round: 8.43,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.35,
    rank: 52,
    avg_round: 8.6,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.34,
    rank: 53,
    avg_round: 9.29,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.31,
    rank: 54,
    avg_round: 9.14,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.3,
    rank: 55,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'robin',
    app_rate: 0.3,
    rank: 55,
    avg_round: 1.67,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.3,
    rank: 55,
    avg_round: 8.45,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.29,
    rank: 58,
    avg_round: 9.25,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.29,
    rank: 58,
    avg_round: 8.09,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.29,
    rank: 58,
    avg_round: 9.06,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.28,
    rank: 61,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'lingsha',
    app_rate: 0.28,
    rank: 61,
    avg_round: 8.17,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'gepard',
    app_rate: 0.28,
    rank: 61,
    avg_round: 8.83,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.27,
    rank: 64,
    avg_round: 9.11,
    star_num: '4'
  },
  {
    char_one: 'tingyun-fugue',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.27,
    rank: 64,
    avg_round: 7.73,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.26,
    rank: 66,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.26,
    rank: 66,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.26,
    rank: 66,
    avg_round: 8.86,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.25,
    rank: 69,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.25,
    rank: 69,
    avg_round: 8.0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.25,
    rank: 69,
    avg_round: 9.43,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.25,
    rank: 69,
    avg_round: 8.62,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'huohuo',
    app_rate: 0.25,
    rank: 69,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.24,
    rank: 74,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.24,
    rank: 74,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.24,
    rank: 74,
    avg_round: 8.86,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.23,
    rank: 77,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.22,
    rank: 78,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.22,
    rank: 78,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.21,
    rank: 80,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'huohuo',
    app_rate: 0.21,
    rank: 80,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.2,
    rank: 82,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.2,
    rank: 82,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.2,
    rank: 82,
    avg_round: 4.86,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.2,
    rank: 82,
    avg_round: 9.25,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.19,
    rank: 86,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.19,
    rank: 86,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.17,
    rank: 88,
    avg_round: 7.6,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.17,
    rank: 88,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.16,
    rank: 90,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.16,
    rank: 90,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.16,
    rank: 90,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.15,
    rank: 93,
    avg_round: 8.83,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.15,
    rank: 93,
    avg_round: 8.45,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.15,
    rank: 93,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.15,
    rank: 93,
    avg_round: 7.9,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 97,
    avg_round: 7.6,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.14,
    rank: 97,
    avg_round: 6.71,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 97,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.13,
    rank: 100,
    avg_round: 6.33,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.13,
    rank: 100,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'bailu',
    app_rate: 0.13,
    rank: 100,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.13,
    rank: 100,
    avg_round: 8.44,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.13,
    rank: 100,
    avg_round: 9.2,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.13,
    rank: 100,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.13,
    rank: 100,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.13,
    rank: 100,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 108,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.12,
    rank: 108,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 108,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.12,
    rank: 108,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.12,
    rank: 108,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'guinaifen',
    char_four: 'huohuo',
    app_rate: 0.12,
    rank: 108,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.12,
    rank: 108,
    avg_round: 8.88,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 108,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'bailu',
    app_rate: 0.11,
    rank: 116,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 116,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.11,
    rank: 116,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.11,
    rank: 116,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.11,
    rank: 116,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'gepard',
    app_rate: 0.11,
    rank: 116,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'bronya',
    char_four: 'robin',
    app_rate: 0.11,
    rank: 116,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.11,
    rank: 116,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 116,
    avg_round: 8.0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.1,
    rank: 125,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.1,
    rank: 125,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'guinaifen',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 125,
    avg_round: 9.75,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'guinaifen',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.1,
    rank: 125,
    avg_round: 7.75,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.1,
    rank: 125,
    avg_round: 7.83,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 125,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 125,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 132,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 132,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 132,
    avg_round: 7.25,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 132,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.09,
    rank: 132,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 132,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 132,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.09,
    rank: 132,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.09,
    rank: 132,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 132,
    avg_round: 8.6,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 132,
    avg_round: 9.17,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 132,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 132,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.08,
    rank: 145,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'asta',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 145,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 145,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 145,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 145,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.08,
    rank: 145,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 145,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 145,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 145,
    avg_round: 9.25,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 145,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 155,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 155,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 155,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'sampo',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 155,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.07,
    rank: 155,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'bailu',
    app_rate: 0.07,
    rank: 155,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 155,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 155,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.07,
    rank: 155,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.07,
    rank: 155,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 155,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 155,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 155,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.07,
    rank: 155,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 155,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.07,
    rank: 155,
    avg_round: 8.2,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 171,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'tingyun-fugue',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 171,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 171,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 171,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 171,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 171,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 171,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.06,
    rank: 171,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.06,
    rank: 171,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 171,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 171,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 171,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 171,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 171,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 171,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 171,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 171,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 171,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 171,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 171,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 171,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'sparkle',
    app_rate: 0.06,
    rank: 171,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'bronya',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 171,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.06,
    rank: 171,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 171,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.06,
    rank: 171,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'bronya',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 197,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 197,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 197,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 197,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'robin',
    app_rate: 0.05,
    rank: 197,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 197,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.05,
    rank: 197,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 197,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'acheron',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 197,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'tingyun-fugue',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 197,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 197,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 197,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 197,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 197,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'sampo',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 197,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 197,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 197,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 197,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 197,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.05,
    rank: 197,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.05,
    rank: 197,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'guinaifen',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 197,
    avg_round: 9.2,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 197,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'gepard',
    app_rate: 0.05,
    rank: 197,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 197,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 197,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 223,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'bronya',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 223,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'gepard',
    app_rate: 0.04,
    rank: 223,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 223,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 223,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 223,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 223,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'silver-wolf',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 223,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 223,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 223,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 223,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 223,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 223,
    avg_round: 7.25,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 223,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 223,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 223,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 223,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 223,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 223,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'bronya',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 223,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'bailu',
    app_rate: 0.04,
    rank: 223,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 223,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 223,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 223,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 223,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 223,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 223,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'bailu',
    app_rate: 0.04,
    rank: 223,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 223,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'bronya',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 252,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'sampo',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'tingyun-fugue',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 252,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 252,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 252,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 252,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 252,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 252,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 252,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 252,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 252,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 252,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'bailu',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 252,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'guinaifen',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'guinaifen',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'guinaifen',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 252,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 252,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 252,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 252,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 252,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'guinaifen',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'moze',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 252,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 252,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 252,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'sampo',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 252,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 252,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 252,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 252,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'guinaifen',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 252,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'acheron',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 252,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'tingyun-fugue',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 252,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 252,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'sampo',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'sampo',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'ruan-mei',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 252,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'bronya',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 252,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 252,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 252,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 252,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 318,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 318,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 318,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 318,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 318,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'guinaifen',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'sampo',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 318,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'robin',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 318,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'guinaifen',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 318,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'bronya',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'guinaifen',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 318,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'bronya',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'acheron',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'bronya',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'welt',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'welt',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'sampo',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'welt',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'silver-wolf',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 318,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'guinaifen',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 318,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'hanya',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'sampo',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'asta',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'welt',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'guinaifen',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'acheron',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'tingyun-fugue',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 318,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'hanya',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 318,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'robin',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'trailblazer-harmony',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 318,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'hanya',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'topaz',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'feixiao',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 318,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'bronya',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 318,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'sunday',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 318,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'bronya',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 318,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'silver-wolf',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 318,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 318,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 318,
    avg_round: 10,
    star_num: '4'
  }
];
