import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const HSRGuidesPFPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Pure Fiction </li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_pf.jpg"
            alt="Pure Fiction "
          />
        </div>
        <div className="page-details">
          <h1>Pure Fiction</h1>
          <h2>A guide for the Pure Fiction mode in Honkai: Star Rail.</h2>
          <p>
            Last updated: <strong>21/01/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Overview" />
        <p>
          Pure Fiction is a game mode in which you will assemble 2 different
          teams to fight against respawning wave of enemies and try to defeat as
          many of them as possible within the time limit of 4 turn cycles.
          Completing this game mode will give you rewards such as Stellar Jades
          and Jade Feathers, the currency used in the Jokes Come True shop.
        </p>
        <p>
          This game mode unlocks after completing the Youci's Clever Decor
          Adventure Mission, after which, it can be accessed via the Interastral
          Peace Guide or by heading to the Pure Fiction marker in the Exalting
          Sanctum area located in the Xianzhou Luofu map.
        </p>
        <StaticImage
          src="../../../images/starrail/generic/pf_3.jpg"
          alt="Guide"
        />
        <p>
          There are a total of 4 stages that you can challenge. In each stage,
          your 2 teams will be fighting 2 separate battles with the first team
          fighting one battle and the second team fighting the other battle.
          Each team will have a set number of turn cycles to fight three
          different waves of enemies with each wave respawning enemies that have
          been defeated. Note that the number of enemies in each wave is set at
          a specific amount. After all the enemies in the first wave have been
          defeated, you will transition to fighting the second wave and the
          process repeats until you have defeated all three waves. Once you have
          defeated all three waves or you run out of turn cycles, you will
          transition to the second team and repeat the process above. Dealing
          damage and defeating enemies will award you points. The number of
          points that you have at the end of the two battles will determine the
          star rating for the stage (0-3) as well as the reward that you get.
          The higher your points and star rating, the more rewards you will get.
        </p>
        <StaticImage
          src="../../../images/starrail/generic/pf_2.jpg"
          alt="Guide"
        />
        <p>
          You will have a total of 4 turn cycles to defeat as many enemies as
          you can. As such, it is recommended to bring teams that can deal out
          significant AoE damage to this game mode. It is also important to pay
          attention to the Whimsicality and Cacophony that are in effect.
          Whimsicality is a global passive effect that is active for the entire
          battle which typically will encourage the use of a certain type of
          team. Cacophony is a set of 3 different passive effects that you can
          select during the team building phase. You will be able to choose 1 of
          the 3 Cacophonies available for your 2 teams, though you will need to
          choose a different one for each team. Effective use of the
          Whimsicality and Cacophony effects will provide a great boost to your
          team’s power in this game mode.
        </p>
        <StaticImage
          src="../../../images/starrail/generic/pf_1.jpg"
          alt="Guide"
        />
        <p>
          Similar to the Memory of Chaos game mode, Pure Fiction stages will
          reset periodically. After each reset, your progress in the game mode
          will reset back to the beginning and you will be able to challenge the
          stages to obtain the Stellar Jades and Jade Feather rewards again.
          Each reset of Pure Fiction will also change the Whimsicality and the
          Cacophony for the duration of that cycle.
        </p>
        <SectionHeader title="Analytics" />
        <p>
          If you want to see how the characters perform in the mode, check our
          Pure Fiction Analytics:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Pure Fiction Analytics"
            link="/star-rail/pure-fiction"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_pf.jpg"
                alt="Pure Fiction"
              />
            }
          />
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesPFPage;

export const Head: React.FC = () => (
  <Seo
    title="Pure Fiction | Honkai: Star Rail | Prydwen Institute"
    description="A guide for the Pure Fiction mode in Honkai: Star Rail."
  />
);
