import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';
import { Row } from 'react-bootstrap';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { CategoryCard } from '../../modules/cs/main/components/category-card';

const WWIndexPage: React.FC = () => {
  return (
    <DashboardLayout className={'home-page wuthering '} game="ww">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Wuthering Waves wiki and tier list</h1>
        <h2>
          Prydwen.gg is a wiki for Wuthering Waves. Check our guides, tier lists
          and reviews for characters available in the game.
        </h2>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/wuthering-waves/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/ww/categories/category_intro.jpg"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Reroll Guide"
          link="/wuthering-waves/guides/reroll"
          image={
            <StaticImage
              src="../../images/ww/categories/category_reroll.jpg"
              alt="Reroll guide"
            />
          }
        />
        <CategoryCard
          title="Beginner Guide"
          link="/wuthering-waves/guides/beginner-guide"
          image={
            <StaticImage
              src="../../images/ww/categories/category_beginner.jpg"
              alt="Beginner guide"
            />
          }
        />
        <CategoryCard
          title="Tier List"
          link="/wuthering-waves/tier-list"
          image={
            <StaticImage
              src="../../images/ww/categories/category_tier.jpg"
              alt="Tier List"
            />
          }
        />
        <CategoryCard
          title="Tips and tricks"
          link="/wuthering-waves/guides/tips-and-tricks"
          image={
            <StaticImage
              src="../../images/ww/categories/category_tips.png"
              alt="Tips and tricks"
            />
          }
        />
        <CategoryCard
          title="Beginner events and rewards"
          link="/wuthering-waves/guides/beginner-events"
          image={
            <StaticImage
              src="../../images/ww/categories/category_events.jpg"
              alt="Beginner events and rewards"
            />
          }
        />
        <CategoryCard
          title="Free characters"
          link="/wuthering-waves/guides/free-characters"
          image={
            <StaticImage
              src="../../images/ww/categories/category_freechars.jpg"
              alt="Free characters"
            />
          }
        />
        <CategoryCard
          title="Gacha System"
          link="/wuthering-waves/guides/gacha"
          image={
            <StaticImage
              src="../../images/ww/categories/category_gacha.jpg"
              alt="Gacha system"
            />
          }
        />
        <CategoryCard
          title="Interactive Map"
          link="https://www.ghzs666.com/wutheringwaves-map#/"
          image={
            <StaticImage
              src="../../images/ww/categories/category_map.jpg"
              alt="Map"
            />
          }
          outsideLink
        />
        <CategoryCard
          title="Characters"
          link="/wuthering-waves/characters"
          image={
            <StaticImage
              src="../../images/ww/categories/category_characters.jpg"
              alt="Characters"
            />
          }
        />
        <CategoryCard
          title="Characters Stats"
          link="/wuthering-waves/characters-stats"
          image={
            <StaticImage
              src="../../images/ww/categories/category_charstats.jpg"
              alt="Characters Stats"
            />
          }
        />
        <CategoryCard
          title="Echo System"
          link="/wuthering-waves/guides/echoes-explained"
          image={
            <StaticImage
              src="../../images/ww/categories/category_echo.png"
              alt="Echo system explained"
            />
          }
        />
        <CategoryCard
          title="Echo Sets"
          link="/wuthering-waves/guides/echo-sets"
          image={
            <StaticImage
              src="../../images/ww/categories/category_echo4.png"
              alt="Echo sets"
            />
          }
        />
        <CategoryCard
          title="Echo Stats"
          link="/wuthering-waves/guides/echo-stats"
          image={
            <StaticImage
              src="../../images/ww/categories/category_echo3.png"
              alt="Echo stats"
            />
          }
        />
        <CategoryCard
          title="Echo List"
          link="/wuthering-waves/echoes"
          image={
            <StaticImage
              src="../../images/ww/categories/category_echo2.png"
              alt="Echo list"
            />
          }
        />
        <CategoryCard
          title="Weapons list"
          link="/wuthering-waves/weapons"
          image={
            <StaticImage
              src="../../images/ww/categories/category_weapons.png"
              alt="Characters"
            />
          }
        />
        <CategoryCard
          title="Battle Pass Weapons"
          link="/wuthering-waves/guides/battle-pass-weapons"
          image={
            <StaticImage
              src="../../images/ww/categories/category_bp.jpg"
              alt="Battle Pass Weapons"
            />
          }
          isWIP
        />
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default WWIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Wuthering Waves Wiki & Database | Prydwen Institute"
    description="Prydwen.gg is a wiki for Wuthering Waves. Check our guides, tier lists and reviews for characters available in the game."
    game="ww"
  />
);
