export const MoCCharacterData = [
  {
    char: 'acheron',
    name: 'Acheron',
    rarity: 'Limited 5*',
    current_app_rate: 55.93,
    prev_app_rate: 48.75,
    round_eidolons: 6.74,
    current_avg_round: 8.88,
    prev_avg_round: 8.5
  },
  {
    char: 'argenti',
    name: 'Argenti',
    rarity: 'Limited 5*',
    current_app_rate: 0.21,
    prev_app_rate: 0.04,
    round_eidolons: 8.18,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'arlan',
    name: 'Arlan',
    rarity: '4*',
    current_app_rate: 0.03,
    prev_app_rate: 0,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'asta',
    name: 'Asta',
    rarity: '4*',
    current_app_rate: 0.22,
    prev_app_rate: 0.51,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 9.9
  },
  {
    char: 'aventurine',
    name: 'Aventurine',
    rarity: 'Limited 5*',
    current_app_rate: 51.96,
    prev_app_rate: 57.72,
    round_eidolons: 6.79,
    current_avg_round: 8.95,
    prev_avg_round: 8.16
  },
  {
    char: 'bailu',
    name: 'Bailu',
    rarity: 'Standard 5*',
    current_app_rate: 0.89,
    prev_app_rate: 0.89,
    round_eidolons: 8.67,
    current_avg_round: 9.62,
    prev_avg_round: 9.35
  },
  {
    char: 'black-swan',
    name: 'Black Swan',
    rarity: 'Limited 5*',
    current_app_rate: 24.74,
    prev_app_rate: 22.41,
    round_eidolons: 8.01,
    current_avg_round: 9.18,
    prev_avg_round: 8.83
  },
  {
    char: 'blade',
    name: 'Blade',
    rarity: 'Limited 5*',
    current_app_rate: 1.44,
    prev_app_rate: 1.28,
    round_eidolons: 8.06,
    current_avg_round: 9.85,
    prev_avg_round: 10
  },
  {
    char: 'boothill',
    name: 'Boothill',
    rarity: 'Limited 5*',
    current_app_rate: 2.05,
    prev_app_rate: 1.14,
    round_eidolons: 6.57,
    current_avg_round: 9.29,
    prev_avg_round: 9.17
  },
  {
    char: 'bronya',
    name: 'Bronya',
    rarity: 'Standard 5*',
    current_app_rate: 6.25,
    prev_app_rate: 6.6,
    round_eidolons: 6.11,
    current_avg_round: 9.21,
    prev_avg_round: 9.08
  },
  {
    char: 'clara',
    name: 'Clara',
    rarity: 'Standard 5*',
    current_app_rate: 0.76,
    prev_app_rate: 0.59,
    round_eidolons: 6.96,
    current_avg_round: 9.18,
    prev_avg_round: 9.56
  },
  {
    char: 'dan-heng',
    name: 'Dan Heng',
    rarity: '4*',
    current_app_rate: 0.02,
    prev_app_rate: 0.04,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'dr-ratio',
    name: 'Dr. Ratio',
    rarity: 'Limited 5*',
    current_app_rate: 1.06,
    prev_app_rate: 2.33,
    round_eidolons: 8.51,
    current_avg_round: 9.5,
    prev_avg_round: 9.52
  },
  {
    char: 'feixiao',
    name: 'Feixiao',
    rarity: 'Limited 5*',
    current_app_rate: 22.49,
    prev_app_rate: 44.32,
    round_eidolons: 6.03,
    current_avg_round: 8.8,
    prev_avg_round: 7.69
  },
  {
    char: 'firefly',
    name: 'Firefly',
    rarity: 'Limited 5*',
    current_app_rate: 50.55,
    prev_app_rate: 60.52,
    round_eidolons: 6.47,
    current_avg_round: 9.35,
    prev_avg_round: 8.43
  },
  {
    char: 'fu-xuan',
    name: 'Fu Xuan',
    rarity: 'Limited 5*',
    current_app_rate: 25.22,
    prev_app_rate: 23.54,
    round_eidolons: 7.24,
    current_avg_round: 8.92,
    prev_avg_round: 8.32
  },
  {
    char: 'tingyun-fugue',
    name: 'Fugue',
    rarity: 'Limited 5*',
    current_app_rate: 47.79,
    prev_app_rate: 47.79,
    round_eidolons: 5.85,
    current_avg_round: 8.74,
    prev_avg_round: 8.74
  },
  {
    char: 'gallagher',
    name: 'Gallagher',
    rarity: '4*',
    current_app_rate: 38.89,
    prev_app_rate: 53.48,
    round_eidolons: 7.94,
    current_avg_round: 9.08,
    prev_avg_round: 8.44
  },
  {
    char: 'gepard',
    name: 'Gepard',
    rarity: 'Standard 5*',
    current_app_rate: 1.74,
    prev_app_rate: 1.15,
    round_eidolons: 7.26,
    current_avg_round: 8.54,
    prev_avg_round: 8.77
  },
  {
    char: 'guinaifen',
    name: 'Guinaifen',
    rarity: '4*',
    current_app_rate: 0.8,
    prev_app_rate: 1.37,
    round_eidolons: 9.06,
    current_avg_round: 9.57,
    prev_avg_round: 9.36
  },
  {
    char: 'hanya',
    name: 'Hanya',
    rarity: '4*',
    current_app_rate: 0.09,
    prev_app_rate: 0.1,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'herta',
    name: 'Herta',
    rarity: '4*',
    current_app_rate: 2.14,
    prev_app_rate: 0.14,
    round_eidolons: 8.26,
    current_avg_round: 9.52,
    prev_avg_round: 99.99
  },
  {
    char: 'himeko',
    name: 'Himeko',
    rarity: 'Standard 5*',
    current_app_rate: 0.66,
    prev_app_rate: 5,
    round_eidolons: 8.05,
    current_avg_round: 9.1,
    prev_avg_round: 9.36
  },
  {
    char: 'hook',
    name: 'Hook',
    rarity: '4*',
    current_app_rate: 0.01,
    prev_app_rate: 0.03,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'huohuo',
    name: 'Huohuo',
    rarity: 'Limited 5*',
    current_app_rate: 22.65,
    prev_app_rate: 15.22,
    round_eidolons: 6.8,
    current_avg_round: 8.85,
    prev_avg_round: 8.57
  },
  {
    char: 'imbibitor-lunae',
    name: 'Imbibitor Lunae',
    rarity: 'Limited 5*',
    current_app_rate: 6.85,
    prev_app_rate: 2.99,
    round_eidolons: 7.44,
    current_avg_round: 9.43,
    prev_avg_round: 9.5
  },
  {
    char: 'jade',
    name: 'Jade',
    rarity: 'Limited 5*',
    current_app_rate: 4.53,
    prev_app_rate: 1.77,
    round_eidolons: 7.35,
    current_avg_round: 9.46,
    prev_avg_round: 9
  },
  {
    char: 'jiaoqiu',
    name: 'Jiaoqiu',
    rarity: 'Limited 5*',
    current_app_rate: 30.18,
    prev_app_rate: 26.4,
    round_eidolons: 5.86,
    current_avg_round: 8.44,
    prev_avg_round: 7.83
  },
  {
    char: 'jing-yuan',
    name: 'Jing Yuan',
    rarity: 'Limited 5*',
    current_app_rate: 11.89,
    prev_app_rate: 1.64,
    round_eidolons: 7.74,
    current_avg_round: 8.96,
    prev_avg_round: 9.25
  },
  {
    char: 'jingliu',
    name: 'Jingliu',
    rarity: 'Limited 5*',
    current_app_rate: 4.97,
    prev_app_rate: 0.35,
    round_eidolons: 6.82,
    current_avg_round: 8.79,
    prev_avg_round: 99.99
  },
  {
    char: 'kafka',
    name: 'Kafka',
    rarity: 'Limited 5*',
    current_app_rate: 20.58,
    prev_app_rate: 18.52,
    round_eidolons: 7.98,
    current_avg_round: 9.23,
    prev_avg_round: 8.94
  },
  {
    char: 'lingsha',
    name: 'Lingsha',
    rarity: 'Limited 5*',
    current_app_rate: 32.22,
    prev_app_rate: 36.63,
    round_eidolons: 6.28,
    current_avg_round: 8.7,
    prev_avg_round: 7.7
  },
  {
    char: 'luka',
    name: 'Luka',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'luocha',
    name: 'Luocha',
    rarity: 'Limited 5*',
    current_app_rate: 10.12,
    prev_app_rate: 6.76,
    round_eidolons: 8.09,
    current_avg_round: 9.18,
    prev_avg_round: 8.91
  },
  {
    char: 'lynx',
    name: 'Lynx',
    rarity: '4*',
    current_app_rate: 0.36,
    prev_app_rate: 0.37,
    round_eidolons: 9,
    current_avg_round: 9.4,
    prev_avg_round: 8.7
  },
  {
    char: 'march-7th',
    name: 'March 7th',
    rarity: '4*',
    current_app_rate: 0.1,
    prev_app_rate: 0.08,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'march-7th-swordmaster',
    name: 'March 7th - The Hunt',
    rarity: '4*',
    current_app_rate: 9.31,
    prev_app_rate: 17.2,
    round_eidolons: 6.43,
    current_avg_round: 8.86,
    prev_avg_round: 7.92
  },
  {
    char: 'misha',
    name: 'Misha',
    rarity: '4*',
    current_app_rate: 0.05,
    prev_app_rate: 0.01,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'moze',
    name: 'Moze',
    rarity: '4*',
    current_app_rate: 1.13,
    prev_app_rate: 6.11,
    round_eidolons: 7.57,
    current_avg_round: 8.76,
    prev_avg_round: 7.72
  },
  {
    char: 'natasha',
    name: 'Natasha',
    rarity: '4*',
    current_app_rate: 0.06,
    prev_app_rate: 0.03,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'pela',
    name: 'Pela',
    rarity: '4*',
    current_app_rate: 22.4,
    prev_app_rate: 19.09,
    round_eidolons: 7.83,
    current_avg_round: 8.86,
    prev_avg_round: 8.32
  },
  {
    char: 'qingque',
    name: 'Qingque',
    rarity: '4*',
    current_app_rate: 0.76,
    prev_app_rate: 0.31,
    round_eidolons: 7.21,
    current_avg_round: 9,
    prev_avg_round: 99.99
  },
  {
    char: 'rappa',
    name: 'Rappa',
    rarity: 'Limited 5*',
    current_app_rate: 21.94,
    prev_app_rate: 19.32,
    round_eidolons: 6.22,
    current_avg_round: 8.63,
    prev_avg_round: 7.62
  },
  {
    char: 'robin',
    name: 'Robin',
    rarity: 'Limited 5*',
    current_app_rate: 46.68,
    prev_app_rate: 56.13,
    round_eidolons: 6.19,
    current_avg_round: 8.79,
    prev_avg_round: 7.73
  },
  {
    char: 'ruan-mei',
    name: 'Ruan Mei',
    rarity: 'Limited 5*',
    current_app_rate: 82.28,
    prev_app_rate: 89.32,
    round_eidolons: 6.54,
    current_avg_round: 9.02,
    prev_avg_round: 8.32
  },
  {
    char: 'sampo',
    name: 'Sampo',
    rarity: '4*',
    current_app_rate: 0.58,
    prev_app_rate: 0.73,
    round_eidolons: 9.46,
    current_avg_round: 9.21,
    prev_avg_round: 9.75
  },
  {
    char: 'seele',
    name: 'Seele',
    rarity: 'Limited 5*',
    current_app_rate: 0.69,
    prev_app_rate: 0.34,
    round_eidolons: 6.4,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'serval',
    name: 'Serval',
    rarity: '4*',
    current_app_rate: 0.26,
    prev_app_rate: 0.18,
    round_eidolons: 8.56,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'silver-wolf',
    name: 'Silver Wolf',
    rarity: 'Limited 5*',
    current_app_rate: 9.21,
    prev_app_rate: 9.1,
    round_eidolons: 7.76,
    current_avg_round: 9.03,
    prev_avg_round: 8.63
  },
  {
    char: 'sparkle',
    name: 'Sparkle',
    rarity: 'Limited 5*',
    current_app_rate: 25.47,
    prev_app_rate: 23.17,
    round_eidolons: 6.18,
    current_avg_round: 9.16,
    prev_avg_round: 8.97
  },
  {
    char: 'sunday',
    name: 'Sunday',
    rarity: 'Limited 5*',
    current_app_rate: 31.84,
    prev_app_rate: 31.84,
    round_eidolons: 6.65,
    current_avg_round: 8.87,
    prev_avg_round: 8.87
  },
  {
    char: 'sushang',
    name: 'Sushang',
    rarity: '4*',
    current_app_rate: 0.01,
    prev_app_rate: 0.02,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'tingyun',
    name: 'Tingyun',
    rarity: '4*',
    current_app_rate: 4.49,
    prev_app_rate: 4.05,
    round_eidolons: 7.91,
    current_avg_round: 8.86,
    prev_avg_round: 9.16
  },
  {
    char: 'topaz',
    name: 'Topaz & Numby',
    rarity: 'Limited 5*',
    current_app_rate: 11.49,
    prev_app_rate: 24.47,
    round_eidolons: 5.99,
    current_avg_round: 8.7,
    prev_avg_round: 7.6
  },
  {
    char: 'trailblazer-destruction',
    name: 'Trailblazer - Destruction',
    rarity: '4*',
    current_app_rate: 0.03,
    prev_app_rate: 0,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'trailblazer-harmony',
    name: 'Trailblazer - Harmony',
    rarity: '4*',
    current_app_rate: 46.55,
    prev_app_rate: 83.73,
    round_eidolons: 6.51,
    current_avg_round: 9.19,
    prev_avg_round: 8.34
  },
  {
    char: 'trailblazer-preservation',
    name: 'Trailblazer - Preservation',
    rarity: '4*',
    current_app_rate: 0.01,
    prev_app_rate: 0,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'welt',
    name: 'Welt',
    rarity: 'Standard 5*',
    current_app_rate: 0.38,
    prev_app_rate: 0.57,
    round_eidolons: 8.75,
    current_avg_round: 9.83,
    prev_avg_round: 99.99
  },
  {
    char: 'xueyi',
    name: 'Xueyi',
    rarity: '4*',
    current_app_rate: 0.19,
    prev_app_rate: 0.04,
    round_eidolons: 8.43,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'yanqing',
    name: 'Yanqing',
    rarity: 'Standard 5*',
    current_app_rate: 0.05,
    prev_app_rate: 0.01,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'yukong',
    name: 'Yukong',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0.04,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'yunli',
    name: 'Yunli',
    rarity: 'Limited 5*',
    current_app_rate: 3.59,
    prev_app_rate: 3.2,
    round_eidolons: 6.18,
    current_avg_round: 8.35,
    prev_avg_round: 9
  }
];
