import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';

const EndfieldGuidesDamage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page endfield '} game="endfield">
      <ul className="breadcrumb">
        <li>
          <Link to="/arknights-endfield/">Arknights: Endfield</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/arknights-endfield/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Damage Formula</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/endfield/categories/category_damage.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Damage Formula</h1>
          <h2>
            How the damage formula works in Arknights: Endfield and how to best
            scale it.
          </h2>
          <p>
            Last updated: <strong>23/01/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Damage Formula" />
        <p>Our Damage Formula guide is currently being created! Stay tuned!</p>
        <SectionHeader title="Work in progress" />
        <div className="banner countdown">
          <div className="cta">
            <h4>
              Our Arknights: Endfield Database is currently being created!
            </h4>
            <p>
              We're gathering information on the current CBT now and the site
              will be updated soon!
            </p>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default EndfieldGuidesDamage;

export const Head: React.FC = () => (
  <Seo
    title="Damage Formula | Arknights: Endfield | Prydwen Institute"
    description="How the damage formula works in Arknights: Endfield and how to best scale it."
    game="endfield"
  />
);
