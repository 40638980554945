export const ZZZBuilds = [
  {
    char: 'Miyabi',
    app_rate: 82.7,
    avg_round: 72,
    std_dev_round: 22,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: 10.76,
    diff_rounds: -24,
    weapon_1: 'Hailstorm Shrine',
    weapon_1_app: 91.58,
    weapon_1_round: 70,
    weapon_2: 'Rainforest Gourmet',
    weapon_2_app: 2.27,
    weapon_2_round: 98,
    weapon_3: 'Fusion Compiler',
    weapon_3_app: 2.06,
    weapon_3_round: 86,
    weapon_4: 'Weeping Gemini',
    weapon_4_app: 1.82,
    weapon_4_round: 95,
    weapon_5: 'Roaring Ride',
    weapon_5_app: 0.7,
    weapon_5_round: 95,
    weapon_6: 'Electro-Lip Gloss',
    weapon_6_app: 0.52,
    weapon_6_round: 97,
    weapon_7: 'Steel Cushion',
    weapon_7_app: 0.34,
    weapon_7_round: 87,
    weapon_8: 'Deep Sea Visitor',
    weapon_8_app: 0.34,
    weapon_8_round: 77,
    weapon_9: 'Marcato Desire',
    weapon_9_app: 0.18,
    weapon_9_round: 109,
    weapon_10: 'Cannon Rotor',
    weapon_10_app: 0.05,
    weapon_10_round: 80,
    artifact_1: 'Branch & Blade Song, Woodpecker Electro',
    artifact_1_1: 'Branch & Blade Song',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 76.97,
    artifact_1_round: 72,
    artifact_2: 'Branch & Blade Song, Polar Metal',
    artifact_2_1: 'Branch & Blade Song',
    artifact_2_2: 'Polar Metal',
    artifact_2_3: '',
    artifact_2_app: 14.02,
    artifact_2_round: 72,
    artifact_3: 'Branch & Blade Song, Astral Voice',
    artifact_3_1: 'Branch & Blade Song',
    artifact_3_2: 'Astral Voice',
    artifact_3_3: '',
    artifact_3_app: 3.83,
    artifact_3_round: 65,
    artifact_4: 'Branch & Blade Song, Hormone Punk',
    artifact_4_1: 'Branch & Blade Song',
    artifact_4_2: 'Hormone Punk',
    artifact_4_3: '',
    artifact_4_app: 1.54,
    artifact_4_round: 59,
    artifact_5: 'Branch & Blade Song, Puffer Electro',
    artifact_5_1: 'Branch & Blade Song',
    artifact_5_2: 'Puffer Electro',
    artifact_5_3: '',
    artifact_5_app: 1.12,
    artifact_5_round: 63,
    artifact_6: 'Branch & Blade Song, Flex',
    artifact_6_1: 'Branch & Blade Song',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 0.6,
    artifact_6_round: 83,
    artifact_7: 'Branch & Blade Song, Freedom Blues',
    artifact_7_1: 'Branch & Blade Song',
    artifact_7_2: 'Freedom Blues',
    artifact_7_3: '',
    artifact_7_app: 0.42,
    artifact_7_round: 77,
    artifact_8: 'Polar Metal, Woodpecker Electro',
    artifact_8_1: 'Polar Metal',
    artifact_8_2: 'Woodpecker Electro',
    artifact_8_3: '',
    artifact_8_app: 0.23,
    artifact_8_round: 84,
    artifact_9: 'Woodpecker Electro, Polar Metal',
    artifact_9_1: 'Woodpecker Electro',
    artifact_9_2: 'Polar Metal',
    artifact_9_3: '',
    artifact_9_app: 0.16,
    artifact_9_round: 70,
    artifact_10: 'Branch & Blade Song, Swing Jazz',
    artifact_10_1: 'Branch & Blade Song',
    artifact_10_2: 'Swing Jazz',
    artifact_10_3: '',
    artifact_10_app: 0.13,
    artifact_10_round: 64,
    app_0: 68.92,
    round_0: 72,
    app_1: 12.12,
    round_1: 70,
    app_2: 18.97,
    round_2: 59,
    app_3: 0.0,
    round_3: 600,
    app_4: 0.0,
    round_4: 600,
    app_5: 0.0,
    round_5: 600,
    app_6: 0.0,
    round_6: 600,
    cons_avg: 0.5,
    sample: 3838,
    sample_app_flat: 3838
  },
  {
    char: 'Soukaku',
    app_rate: 44.82,
    avg_round: 90,
    std_dev_round: 38,
    role: 'Support',
    rarity: 'A',
    diff: -0.71,
    diff_rounds: -31,
    weapon_1: 'Bashful Demon',
    weapon_1_app: 95.29,
    weapon_1_round: 90,
    weapon_2: 'Kaboom the Cannon',
    weapon_2_app: 1.68,
    weapon_2_round: 76,
    weapon_3: 'Slice of Time',
    weapon_3_app: 1.01,
    weapon_3_round: 92,
    weapon_4: 'Weeping Cradle',
    weapon_4_app: 0.62,
    weapon_4_round: 89,
    weapon_5: '[Reverb] Mark II',
    weapon_5_app: 0.38,
    weapon_5_round: 126,
    weapon_6: 'Unfettered Game Ball',
    weapon_6_app: 0.34,
    weapon_6_round: 76,
    weapon_7: 'Starlight Engine',
    weapon_7_app: 0.1,
    weapon_7_round: 80,
    weapon_8: 'Marcato Desire',
    weapon_8_app: 0.05,
    weapon_8_round: 61,
    weapon_9: '[Reverb] Mark III',
    weapon_9_app: 0.05,
    weapon_9_round: 103,
    weapon_10: 'The Brimstone',
    weapon_10_app: 0.05,
    weapon_10_round: 212,
    artifact_1: 'Swing Jazz, Hormone Punk',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Hormone Punk',
    artifact_1_3: '',
    artifact_1_app: 47.5,
    artifact_1_round: 91,
    artifact_2: 'Swing Jazz, Polar Metal',
    artifact_2_1: 'Swing Jazz',
    artifact_2_2: 'Polar Metal',
    artifact_2_3: '',
    artifact_2_app: 7.79,
    artifact_2_round: 94,
    artifact_3: 'Astral Voice, Swing Jazz',
    artifact_3_1: 'Astral Voice',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 5.91,
    artifact_3_round: 83,
    artifact_4: 'Freedom Blues, Swing Jazz',
    artifact_4_1: 'Freedom Blues',
    artifact_4_2: 'Swing Jazz',
    artifact_4_3: '',
    artifact_4_app: 4.33,
    artifact_4_round: 77,
    artifact_5: 'Swing Jazz, Flex',
    artifact_5_1: 'Swing Jazz',
    artifact_5_2: 'Flex',
    artifact_5_3: '',
    artifact_5_app: 2.5,
    artifact_5_round: 88,
    artifact_6: 'Swing Jazz, Freedom Blues',
    artifact_6_1: 'Swing Jazz',
    artifact_6_2: 'Freedom Blues',
    artifact_6_3: '',
    artifact_6_app: 2.45,
    artifact_6_round: 84,
    artifact_7: 'Swing Jazz, Woodpecker Electro',
    artifact_7_1: 'Swing Jazz',
    artifact_7_2: 'Woodpecker Electro',
    artifact_7_3: '',
    artifact_7_app: 2.36,
    artifact_7_round: 84,
    artifact_8: 'Hormone Punk, Polar Metal',
    artifact_8_1: 'Hormone Punk',
    artifact_8_2: 'Polar Metal',
    artifact_8_3: '',
    artifact_8_app: 2.31,
    artifact_8_round: 97,
    artifact_9: 'Swing Jazz, Astral Voice',
    artifact_9_1: 'Swing Jazz',
    artifact_9_2: 'Astral Voice',
    artifact_9_3: '',
    artifact_9_app: 1.78,
    artifact_9_round: 87,
    artifact_10: 'Freedom Blues, Hormone Punk',
    artifact_10_1: 'Freedom Blues',
    artifact_10_2: 'Hormone Punk',
    artifact_10_3: '',
    artifact_10_app: 1.59,
    artifact_10_round: 86,
    app_0: 0.0,
    round_0: 600,
    app_1: 0.14,
    round_1: 105,
    app_2: 0.19,
    round_2: 126,
    app_3: 0.38,
    round_3: 86,
    app_4: 0.96,
    round_4: 105,
    app_5: 1.63,
    round_5: 102,
    app_6: 96.68,
    round_6: 90,
    cons_avg: 5.94,
    sample: 2080,
    sample_app_flat: 2080
  },
  {
    char: 'Lucy',
    app_rate: 35.96,
    avg_round: 91,
    std_dev_round: 44,
    role: 'Support',
    rarity: 'A',
    diff: -16.25,
    diff_rounds: -17,
    weapon_1: 'Kaboom the Cannon',
    weapon_1_app: 89.81,
    weapon_1_round: 90,
    weapon_2: 'Slice of Time',
    weapon_2_app: 3.12,
    weapon_2_round: 104,
    weapon_3: 'Weeping Cradle',
    weapon_3_app: 2.34,
    weapon_3_round: 93,
    weapon_4: '[Reverb] Mark II',
    weapon_4_app: 2.16,
    weapon_4_round: 94,
    weapon_5: 'Bashful Demon',
    weapon_5_app: 0.6,
    weapon_5_round: 103,
    weapon_6: 'The Vault',
    weapon_6_app: 0.54,
    weapon_6_round: 104,
    weapon_7: 'Unfettered Game Ball',
    weapon_7_app: 0.48,
    weapon_7_round: 116,
    weapon_8: '[Reverb] Mark III',
    weapon_8_app: 0.36,
    weapon_8_round: 130,
    weapon_9: '[Reverb] Mark I',
    weapon_9_app: 0.18,
    weapon_9_round: 181,
    weapon_10: 'Housekeeper',
    weapon_10_app: 0.06,
    weapon_10_round: 58,
    artifact_1: 'Swing Jazz, Hormone Punk',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Hormone Punk',
    artifact_1_3: '',
    artifact_1_app: 50.15,
    artifact_1_round: 93,
    artifact_2: 'Astral Voice, Swing Jazz',
    artifact_2_1: 'Astral Voice',
    artifact_2_2: 'Swing Jazz',
    artifact_2_3: '',
    artifact_2_app: 10.67,
    artifact_2_round: 78,
    artifact_3: 'Swing Jazz, Woodpecker Electro',
    artifact_3_1: 'Swing Jazz',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 4.97,
    artifact_3_round: 86,
    artifact_4: 'Swing Jazz, Freedom Blues',
    artifact_4_1: 'Swing Jazz',
    artifact_4_2: 'Freedom Blues',
    artifact_4_3: '',
    artifact_4_app: 4.55,
    artifact_4_round: 79,
    artifact_5: 'Swing Jazz, Flex',
    artifact_5_1: 'Swing Jazz',
    artifact_5_2: 'Flex',
    artifact_5_3: '',
    artifact_5_app: 2.76,
    artifact_5_round: 107,
    artifact_6: 'Hormone Punk, Swing Jazz',
    artifact_6_1: 'Hormone Punk',
    artifact_6_2: 'Swing Jazz',
    artifact_6_3: '',
    artifact_6_app: 2.76,
    artifact_6_round: 97,
    artifact_7: 'Freedom Blues, Swing Jazz',
    artifact_7_1: 'Freedom Blues',
    artifact_7_2: 'Swing Jazz',
    artifact_7_3: '',
    artifact_7_app: 2.4,
    artifact_7_round: 87,
    artifact_8: 'Swing Jazz, Inferno Metal',
    artifact_8_1: 'Swing Jazz',
    artifact_8_2: 'Inferno Metal',
    artifact_8_3: '',
    artifact_8_app: 1.8,
    artifact_8_round: 94,
    artifact_9: 'Astral Voice, Hormone Punk',
    artifact_9_1: 'Astral Voice',
    artifact_9_2: 'Hormone Punk',
    artifact_9_3: '',
    artifact_9_app: 1.8,
    artifact_9_round: 97,
    artifact_10: 'Swing Jazz, Astral Voice',
    artifact_10_1: 'Swing Jazz',
    artifact_10_2: 'Astral Voice',
    artifact_10_3: '',
    artifact_10_app: 1.44,
    artifact_10_round: 90,
    app_0: 0.3,
    round_0: 108,
    app_1: 1.2,
    round_1: 121,
    app_2: 3.18,
    round_2: 103,
    app_3: 4.55,
    round_3: 111,
    app_4: 6.53,
    round_4: 99,
    app_5: 6.47,
    round_5: 99,
    app_6: 77.77,
    round_6: 87,
    cons_avg: 5.46,
    sample: 1669,
    sample_app_flat: 1669
  },
  {
    char: 'Lycaon',
    app_rate: 28.29,
    avg_round: 93,
    std_dev_round: 40,
    role: 'Stun',
    rarity: 'Standard S',
    diff: 3.28,
    diff_rounds: -32,
    weapon_1: 'The Restrained',
    weapon_1_app: 37.01,
    weapon_1_round: 96,
    weapon_2: 'Steam Oven',
    weapon_2_app: 25.29,
    weapon_2_round: 94,
    weapon_3: 'Precious Fossilized Core',
    weapon_3_app: 15.84,
    weapon_3_round: 92,
    weapon_4: 'Hellfire Gears',
    weapon_4_app: 14.7,
    weapon_4_round: 87,
    weapon_5: 'Six Shooter',
    weapon_5_app: 3.88,
    weapon_5_round: 92,
    weapon_6: '[Vortex] Arrow',
    weapon_6_app: 1.07,
    weapon_6_round: 101,
    weapon_7: 'Demara Battery Mark II',
    weapon_7_app: 0.99,
    weapon_7_round: 103,
    weapon_8: 'Blazing Laurel',
    weapon_8_app: 0.61,
    weapon_8_round: 63,
    weapon_9: 'Ice-Jade Teapot',
    weapon_9_app: 0.08,
    weapon_9_round: 127,
    weapon_10: 'Original Transmorpher',
    weapon_10_app: 0.08,
    weapon_10_round: 157,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 40.52,
    artifact_1_round: 93,
    artifact_2: 'Shockstar Disco, Polar Metal',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Polar Metal',
    artifact_2_3: '',
    artifact_2_app: 35.26,
    artifact_2_round: 96,
    artifact_3: 'Shockstar Disco, Woodpecker Electro',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 5.48,
    artifact_3_round: 92,
    artifact_4: 'Shockstar Disco, Flex',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 2.67,
    artifact_4_round: 109,
    artifact_5: 'Freedom Blues, Shockstar Disco',
    artifact_5_1: 'Freedom Blues',
    artifact_5_2: 'Shockstar Disco',
    artifact_5_3: '',
    artifact_5_app: 2.13,
    artifact_5_round: 80,
    artifact_6: 'Shockstar Disco, Freedom Blues',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Freedom Blues',
    artifact_6_3: '',
    artifact_6_app: 1.98,
    artifact_6_round: 106,
    artifact_7: 'Proto Punk, Shockstar Disco',
    artifact_7_1: 'Proto Punk',
    artifact_7_2: 'Shockstar Disco',
    artifact_7_3: '',
    artifact_7_app: 1.29,
    artifact_7_round: 91,
    artifact_8: 'Shockstar Disco, Hormone Punk',
    artifact_8_1: 'Shockstar Disco',
    artifact_8_2: 'Hormone Punk',
    artifact_8_3: '',
    artifact_8_app: 1.14,
    artifact_8_round: 95,
    artifact_9: 'Swing Jazz, Shockstar Disco',
    artifact_9_1: 'Swing Jazz',
    artifact_9_2: 'Shockstar Disco',
    artifact_9_3: '',
    artifact_9_app: 1.14,
    artifact_9_round: 78,
    artifact_10: 'Polar Metal, Shockstar Disco',
    artifact_10_1: 'Polar Metal',
    artifact_10_2: 'Shockstar Disco',
    artifact_10_3: '',
    artifact_10_app: 1.14,
    artifact_10_round: 108,
    app_0: 54.23,
    round_0: 93,
    app_1: 33.89,
    round_1: 93,
    app_2: 8.83,
    round_2: 96,
    app_3: 2.51,
    round_3: 82,
    app_4: 0.46,
    round_4: 145,
    app_5: 0.08,
    round_5: 101,
    app_6: 0.0,
    round_6: 600,
    cons_avg: 0.61,
    sample: 1313,
    sample_app_flat: 1313
  },
  {
    char: 'Yanagi',
    app_rate: 54.17,
    avg_round: 93,
    std_dev_round: 36,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: 9.5,
    diff_rounds: -5,
    weapon_1: 'Timeweaver',
    weapon_1_app: 41.89,
    weapon_1_round: 86,
    weapon_2: 'Weeping Gemini',
    weapon_2_app: 25.42,
    weapon_2_round: 98,
    weapon_3: 'Fusion Compiler',
    weapon_3_app: 17.94,
    weapon_3_round: 91,
    weapon_4: 'Electro-Lip Gloss',
    weapon_4_app: 9.47,
    weapon_4_round: 99,
    weapon_5: 'Rainforest Gourmet',
    weapon_5_app: 4.06,
    weapon_5_round: 107,
    weapon_6: 'Roaring Ride',
    weapon_6_app: 0.84,
    weapon_6_round: 110,
    weapon_7: '[Magnetic Storm] Alpha',
    weapon_7_app: 0.08,
    weapon_7_round: 178,
    weapon_8: 'The Vault',
    weapon_8_app: 0.04,
    weapon_8_round: 84,
    weapon_9: 'Kaboom the Cannon',
    weapon_9_app: 0.04,
    weapon_9_round: 57,
    weapon_10: 'Sharpened Stinger',
    weapon_10_app: 0.04,
    weapon_10_round: 117,
    artifact_1: 'Chaos Jazz, Freedom Blues',
    artifact_1_1: 'Chaos Jazz',
    artifact_1_2: 'Freedom Blues',
    artifact_1_3: '',
    artifact_1_app: 71.08,
    artifact_1_round: 92,
    artifact_2: 'Thunder Metal, Freedom Blues',
    artifact_2_1: 'Thunder Metal',
    artifact_2_2: 'Freedom Blues',
    artifact_2_3: '',
    artifact_2_app: 7.76,
    artifact_2_round: 99,
    artifact_3: 'Thunder Metal, Chaos Jazz',
    artifact_3_1: 'Thunder Metal',
    artifact_3_2: 'Chaos Jazz',
    artifact_3_3: '',
    artifact_3_app: 5.97,
    artifact_3_round: 99,
    artifact_4: 'Chaos Jazz, Thunder Metal',
    artifact_4_1: 'Chaos Jazz',
    artifact_4_2: 'Thunder Metal',
    artifact_4_3: '',
    artifact_4_app: 4.49,
    artifact_4_round: 89,
    artifact_5: 'Freedom Blues, Chaos Jazz',
    artifact_5_1: 'Freedom Blues',
    artifact_5_2: 'Chaos Jazz',
    artifact_5_3: '',
    artifact_5_app: 1.91,
    artifact_5_round: 89,
    artifact_6: 'Chaos Jazz, Puffer Electro',
    artifact_6_1: 'Chaos Jazz',
    artifact_6_2: 'Puffer Electro',
    artifact_6_3: '',
    artifact_6_app: 1.87,
    artifact_6_round: 79,
    artifact_7: 'Chaos Jazz, Swing Jazz',
    artifact_7_1: 'Chaos Jazz',
    artifact_7_2: 'Swing Jazz',
    artifact_7_3: '',
    artifact_7_app: 1.31,
    artifact_7_round: 77,
    artifact_8: 'AP +30, AP +30, Thunder Metal',
    artifact_8_1: 'AP +30',
    artifact_8_2: 'AP +30',
    artifact_8_3: 'Thunder Metal',
    artifact_8_app: 0.8,
    artifact_8_round: 103,
    artifact_9: 'Chaos Jazz, Hormone Punk',
    artifact_9_1: 'Chaos Jazz',
    artifact_9_2: 'Hormone Punk',
    artifact_9_3: '',
    artifact_9_app: 0.72,
    artifact_9_round: 97,
    artifact_10: 'AP +30, AP +30, Puffer Electro',
    artifact_10_1: 'AP +30',
    artifact_10_2: 'AP +30',
    artifact_10_3: 'Puffer Electro',
    artifact_10_app: 0.64,
    artifact_10_round: 92,
    app_0: 91.41,
    round_0: 93,
    app_1: 5.21,
    round_1: 82,
    app_2: 3.38,
    round_2: 83,
    app_3: 0.0,
    round_3: 600,
    app_4: 0.0,
    round_4: 600,
    app_5: 0.0,
    round_5: 600,
    app_6: 0.0,
    round_6: 600,
    cons_avg: 0.12,
    sample: 2514,
    sample_app_flat: 2514
  },
  {
    char: 'Lighter',
    app_rate: 11.2,
    avg_round: 97,
    std_dev_round: 45,
    role: 'Stun',
    rarity: 'Limited S',
    diff: -1.38,
    diff_rounds: -28,
    weapon_1: 'Blazing Laurel',
    weapon_1_app: 45.77,
    weapon_1_round: 96,
    weapon_2: 'Hellfire Gears',
    weapon_2_app: 15.58,
    weapon_2_round: 95,
    weapon_3: 'Steam Oven',
    weapon_3_app: 12.88,
    weapon_3_round: 93,
    weapon_4: 'Precious Fossilized Core',
    weapon_4_app: 11.35,
    weapon_4_round: 100,
    weapon_5: 'The Restrained',
    weapon_5_app: 9.04,
    weapon_5_round: 104,
    weapon_6: 'Six Shooter',
    weapon_6_app: 2.31,
    weapon_6_round: 137,
    weapon_7: 'Ice-Jade Teapot',
    weapon_7_app: 1.92,
    weapon_7_round: 73,
    weapon_8: 'Demara Battery Mark II',
    weapon_8_app: 0.77,
    weapon_8_round: 109,
    weapon_9: '[Vortex] Arrow',
    weapon_9_app: 0.19,
    weapon_9_round: 110,
    weapon_10: 'Steel Cushion',
    weapon_10_app: 0.19,
    weapon_10_round: 148,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 60.58,
    artifact_1_round: 97,
    artifact_2: 'Shockstar Disco, Inferno Metal',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Inferno Metal',
    artifact_2_3: '',
    artifact_2_app: 14.62,
    artifact_2_round: 106,
    artifact_3: 'Shockstar Disco, Woodpecker Electro',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 7.31,
    artifact_3_round: 93,
    artifact_4: 'Inferno Metal, Shockstar Disco',
    artifact_4_1: 'Inferno Metal',
    artifact_4_2: 'Shockstar Disco',
    artifact_4_3: '',
    artifact_4_app: 2.5,
    artifact_4_round: 86,
    artifact_5: 'Proto Punk, Shockstar Disco',
    artifact_5_1: 'Proto Punk',
    artifact_5_2: 'Shockstar Disco',
    artifact_5_3: '',
    artifact_5_app: 2.5,
    artifact_5_round: 80,
    artifact_6: 'Shockstar Disco, Flex',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 2.12,
    artifact_6_round: 125,
    artifact_7: 'Swing Jazz, Shockstar Disco',
    artifact_7_1: 'Swing Jazz',
    artifact_7_2: 'Shockstar Disco',
    artifact_7_3: '',
    artifact_7_app: 1.54,
    artifact_7_round: 93,
    artifact_8: 'Shockstar Disco, Hormone Punk',
    artifact_8_1: 'Shockstar Disco',
    artifact_8_2: 'Hormone Punk',
    artifact_8_3: '',
    artifact_8_app: 1.35,
    artifact_8_round: 95,
    artifact_9: 'Shockstar Disco, Freedom Blues',
    artifact_9_1: 'Shockstar Disco',
    artifact_9_2: 'Freedom Blues',
    artifact_9_3: '',
    artifact_9_app: 1.15,
    artifact_9_round: 99,
    artifact_10: 'Woodpecker Electro, Shockstar Disco',
    artifact_10_1: 'Woodpecker Electro',
    artifact_10_2: 'Shockstar Disco',
    artifact_10_3: '',
    artifact_10_app: 0.96,
    artifact_10_round: 82,
    app_0: 92.12,
    round_0: 97,
    app_1: 5.58,
    round_1: 95,
    app_2: 2.31,
    round_2: 97,
    app_3: 0.0,
    round_3: 600,
    app_4: 0.0,
    round_4: 600,
    app_5: 0.0,
    round_5: 600,
    app_6: 0.0,
    round_6: 600,
    cons_avg: 0.1,
    sample: 520,
    sample_app_flat: 520
  },
  {
    char: 'Burnice',
    app_rate: 35.4,
    avg_round: 98,
    std_dev_round: 39,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: -19.16,
    diff_rounds: 3,
    weapon_1: 'Flamemaker Shaker',
    weapon_1_app: 36.28,
    weapon_1_round: 95,
    weapon_2: 'Weeping Gemini',
    weapon_2_app: 23.55,
    weapon_2_round: 98,
    weapon_3: 'Electro-Lip Gloss',
    weapon_3_app: 15.64,
    weapon_3_round: 98,
    weapon_4: 'Roaring Ride',
    weapon_4_app: 8.83,
    weapon_4_round: 103,
    weapon_5: 'Fusion Compiler',
    weapon_5_app: 8.16,
    weapon_5_round: 96,
    weapon_6: 'Rainforest Gourmet',
    weapon_6_app: 7.24,
    weapon_6_round: 110,
    weapon_7: '[Magnetic Storm] Alpha',
    weapon_7_app: 0.06,
    weapon_7_round: 118,
    weapon_8: 'Hailstorm Shrine',
    weapon_8_app: 0.06,
    weapon_8_round: 80,
    weapon_9: 'Gilded Blossom',
    weapon_9_app: 0.06,
    weapon_9_round: 119,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 600,
    artifact_1: 'Chaos Jazz, Freedom Blues',
    artifact_1_1: 'Chaos Jazz',
    artifact_1_2: 'Freedom Blues',
    artifact_1_3: '',
    artifact_1_app: 54.05,
    artifact_1_round: 98,
    artifact_2: 'Chaos Jazz, Inferno Metal',
    artifact_2_1: 'Chaos Jazz',
    artifact_2_2: 'Inferno Metal',
    artifact_2_3: '',
    artifact_2_app: 20.57,
    artifact_2_round: 101,
    artifact_3: 'Chaos Jazz, Swing Jazz',
    artifact_3_1: 'Chaos Jazz',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 18.87,
    artifact_3_round: 95,
    artifact_4: 'Chaos Jazz, Hormone Punk',
    artifact_4_1: 'Chaos Jazz',
    artifact_4_2: 'Hormone Punk',
    artifact_4_3: '',
    artifact_4_app: 2.01,
    artifact_4_round: 107,
    artifact_5: 'Chaos Jazz, Puffer Electro',
    artifact_5_1: 'Chaos Jazz',
    artifact_5_2: 'Puffer Electro',
    artifact_5_3: '',
    artifact_5_app: 1.03,
    artifact_5_round: 85,
    artifact_6: 'Chaos Jazz, Flex',
    artifact_6_1: 'Chaos Jazz',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 0.73,
    artifact_6_round: 90,
    artifact_7: 'Freedom Blues, Chaos Jazz',
    artifact_7_1: 'Freedom Blues',
    artifact_7_2: 'Chaos Jazz',
    artifact_7_3: '',
    artifact_7_app: 0.37,
    artifact_7_round: 119,
    artifact_8: 'Chaos Jazz, Astral Voice',
    artifact_8_1: 'Chaos Jazz',
    artifact_8_2: 'Astral Voice',
    artifact_8_3: '',
    artifact_8_app: 0.3,
    artifact_8_round: 100,
    artifact_9: 'Inferno Metal, Woodpecker Electro',
    artifact_9_1: 'Inferno Metal',
    artifact_9_2: 'Woodpecker Electro',
    artifact_9_3: '',
    artifact_9_app: 0.3,
    artifact_9_round: 81,
    artifact_10: 'Freedom Blues, Inferno Metal',
    artifact_10_1: 'Freedom Blues',
    artifact_10_2: 'Inferno Metal',
    artifact_10_3: '',
    artifact_10_app: 0.24,
    artifact_10_round: 87,
    app_0: 92.03,
    round_0: 98,
    app_1: 6.33,
    round_1: 89,
    app_2: 1.64,
    round_2: 78,
    app_3: 0.0,
    round_3: 600,
    app_4: 0.0,
    round_4: 600,
    app_5: 0.0,
    round_5: 600,
    app_6: 0.0,
    round_6: 600,
    cons_avg: 0.1,
    sample: 1643,
    sample_app_flat: 1643
  },
  {
    char: 'Caesar',
    app_rate: 36.72,
    avg_round: 105,
    std_dev_round: 44,
    role: 'Support',
    rarity: 'Limited S',
    diff: -12.24,
    diff_rounds: -1,
    weapon_1: 'Tusks of Fury',
    weapon_1_app: 45.19,
    weapon_1_round: 100,
    weapon_2: 'Original Transmorpher',
    weapon_2_app: 22.48,
    weapon_2_round: 117,
    weapon_3: 'Demara Battery Mark II',
    weapon_3_app: 9.51,
    weapon_3_round: 104,
    weapon_4: 'Precious Fossilized Core',
    weapon_4_app: 6.63,
    weapon_4_round: 100,
    weapon_5: 'Hellfire Gears',
    weapon_5_app: 4.81,
    weapon_5_round: 99,
    weapon_6: 'The Restrained',
    weapon_6_app: 4.28,
    weapon_6_round: 101,
    weapon_7: 'Spring Embrace',
    weapon_7_app: 2.76,
    weapon_7_round: 112,
    weapon_8: 'Peacekeeper - Specialized',
    weapon_8_app: 1.82,
    weapon_8_round: 92,
    weapon_9: 'Big Cylinder',
    weapon_9_app: 0.7,
    weapon_9_round: 136,
    weapon_10: 'Six Shooter',
    weapon_10_app: 0.59,
    weapon_10_round: 71,
    artifact_1: 'Proto Punk, Shockstar Disco',
    artifact_1_1: 'Proto Punk',
    artifact_1_2: 'Shockstar Disco',
    artifact_1_3: '',
    artifact_1_app: 85.27,
    artifact_1_round: 104,
    artifact_2: 'Proto Punk, Swing Jazz',
    artifact_2_1: 'Proto Punk',
    artifact_2_2: 'Swing Jazz',
    artifact_2_3: '',
    artifact_2_app: 6.98,
    artifact_2_round: 100,
    artifact_3: 'Freedom Blues, Shockstar Disco',
    artifact_3_1: 'Freedom Blues',
    artifact_3_2: 'Shockstar Disco',
    artifact_3_3: '',
    artifact_3_app: 1.0,
    artifact_3_round: 97,
    artifact_4: 'Proto Punk, Fanged Metal',
    artifact_4_1: 'Proto Punk',
    artifact_4_2: 'Fanged Metal',
    artifact_4_3: '',
    artifact_4_app: 0.76,
    artifact_4_round: 127,
    artifact_5: 'Proto Punk, Flex',
    artifact_5_1: 'Proto Punk',
    artifact_5_2: 'Flex',
    artifact_5_3: '',
    artifact_5_app: 0.7,
    artifact_5_round: 126,
    artifact_6: 'Swing Jazz, Shockstar Disco',
    artifact_6_1: 'Swing Jazz',
    artifact_6_2: 'Shockstar Disco',
    artifact_6_3: '',
    artifact_6_app: 0.53,
    artifact_6_round: 110,
    artifact_7: 'Proto Punk, Woodpecker Electro',
    artifact_7_1: 'Proto Punk',
    artifact_7_2: 'Woodpecker Electro',
    artifact_7_3: '',
    artifact_7_app: 0.41,
    artifact_7_round: 122,
    artifact_8: 'Shockstar Disco, Proto Punk',
    artifact_8_1: 'Shockstar Disco',
    artifact_8_2: 'Proto Punk',
    artifact_8_3: '',
    artifact_8_app: 0.41,
    artifact_8_round: 80,
    artifact_9: 'Proto Punk, Freedom Blues',
    artifact_9_1: 'Proto Punk',
    artifact_9_2: 'Freedom Blues',
    artifact_9_3: '',
    artifact_9_app: 0.35,
    artifact_9_round: 107,
    artifact_10: 'Freedom Blues, Proto Punk',
    artifact_10_1: 'Freedom Blues',
    artifact_10_2: 'Proto Punk',
    artifact_10_3: '',
    artifact_10_app: 0.35,
    artifact_10_round: 125,
    app_0: 91.9,
    round_0: 105,
    app_1: 6.1,
    round_1: 98,
    app_2: 2.0,
    round_2: 77,
    app_3: 0.0,
    round_3: 600,
    app_4: 0.0,
    round_4: 600,
    app_5: 0.0,
    round_5: 600,
    app_6: 0.0,
    round_6: 600,
    cons_avg: 0.1,
    sample: 1704,
    sample_app_flat: 1704
  },
  {
    char: 'Piper',
    app_rate: 6.66,
    avg_round: 109,
    std_dev_round: 42,
    role: 'Damage Dealer',
    rarity: 'A',
    diff: -11.13,
    diff_rounds: -5,
    weapon_1: 'Roaring Ride',
    weapon_1_app: 88.67,
    weapon_1_round: 110,
    weapon_2: 'Rainforest Gourmet',
    weapon_2_app: 4.53,
    weapon_2_round: 115,
    weapon_3: 'Fusion Compiler',
    weapon_3_app: 3.56,
    weapon_3_round: 101,
    weapon_4: 'Weeping Gemini',
    weapon_4_app: 1.29,
    weapon_4_round: 106,
    weapon_5: 'Electro-Lip Gloss',
    weapon_5_app: 0.65,
    weapon_5_round: 90,
    weapon_6: 'Sharpened Stinger',
    weapon_6_app: 0.65,
    weapon_6_round: 66,
    weapon_7: '',
    weapon_7_app: 0.0,
    weapon_7_round: 600,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 600,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 600,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 600,
    artifact_1: 'Freedom Blues, Fanged Metal',
    artifact_1_1: 'Freedom Blues',
    artifact_1_2: 'Fanged Metal',
    artifact_1_3: '',
    artifact_1_app: 30.1,
    artifact_1_round: 111,
    artifact_2: 'Fanged Metal, Freedom Blues',
    artifact_2_1: 'Fanged Metal',
    artifact_2_2: 'Freedom Blues',
    artifact_2_3: '',
    artifact_2_app: 26.21,
    artifact_2_round: 108,
    artifact_3: 'Freedom Blues, Swing Jazz',
    artifact_3_1: 'Freedom Blues',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 16.83,
    artifact_3_round: 104,
    artifact_4: 'Fanged Metal, Chaos Jazz',
    artifact_4_1: 'Fanged Metal',
    artifact_4_2: 'Chaos Jazz',
    artifact_4_3: '',
    artifact_4_app: 6.15,
    artifact_4_round: 113,
    artifact_5: 'Freedom Blues, Chaos Jazz',
    artifact_5_1: 'Freedom Blues',
    artifact_5_2: 'Chaos Jazz',
    artifact_5_3: '',
    artifact_5_app: 3.24,
    artifact_5_round: 124,
    artifact_6: 'AP +30, AP +30, Fanged Metal',
    artifact_6_1: 'AP +30',
    artifact_6_2: 'AP +30',
    artifact_6_3: 'Fanged Metal',
    artifact_6_app: 2.27,
    artifact_6_round: 95,
    artifact_7: 'Fanged Metal, Swing Jazz',
    artifact_7_1: 'Fanged Metal',
    artifact_7_2: 'Swing Jazz',
    artifact_7_3: '',
    artifact_7_app: 1.94,
    artifact_7_round: 156,
    artifact_8: 'Freedom Blues, Flex',
    artifact_8_1: 'Freedom Blues',
    artifact_8_2: 'Flex',
    artifact_8_3: '',
    artifact_8_app: 1.94,
    artifact_8_round: 110,
    artifact_9: 'Freedom Blues, Hormone Punk',
    artifact_9_1: 'Freedom Blues',
    artifact_9_2: 'Hormone Punk',
    artifact_9_3: '',
    artifact_9_app: 1.94,
    artifact_9_round: 99,
    artifact_10: 'Fanged Metal, AP +30, Flex',
    artifact_10_1: 'Fanged Metal',
    artifact_10_2: 'AP +30',
    artifact_10_3: 'Flex',
    artifact_10_app: 1.62,
    artifact_10_round: 90,
    app_0: 0.97,
    round_0: 109,
    app_1: 0.97,
    round_1: 121,
    app_2: 1.29,
    round_2: 146,
    app_3: 1.94,
    round_3: 143,
    app_4: 5.5,
    round_4: 116,
    app_5: 2.27,
    round_5: 120,
    app_6: 87.06,
    round_6: 107,
    cons_avg: 5.65,
    sample: 309,
    sample_app_flat: 309
  },
  {
    char: 'Rina',
    app_rate: 35.19,
    avg_round: 114,
    std_dev_round: 50,
    role: 'Support',
    rarity: 'Standard S',
    diff: 14.24,
    diff_rounds: 7,
    weapon_1: 'Slice of Time',
    weapon_1_app: 48.93,
    weapon_1_round: 118,
    weapon_2: 'Weeping Cradle',
    weapon_2_app: 44.27,
    weapon_2_round: 109,
    weapon_3: 'Kaboom the Cannon',
    weapon_3_app: 3.8,
    weapon_3_round: 110,
    weapon_4: '[Reverb] Mark II',
    weapon_4_app: 1.04,
    weapon_4_round: 128,
    weapon_5: 'Unfettered Game Ball',
    weapon_5_app: 0.92,
    weapon_5_round: 114,
    weapon_6: 'Fusion Compiler',
    weapon_6_app: 0.31,
    weapon_6_round: 139,
    weapon_7: 'The Vault',
    weapon_7_app: 0.24,
    weapon_7_round: 132,
    weapon_8: '[Reverb] Mark III',
    weapon_8_app: 0.06,
    weapon_8_round: 119,
    weapon_9: '[Reverb] Mark I',
    weapon_9_app: 0.06,
    weapon_9_round: 141,
    weapon_10: '[Magnetic Storm] Charlie',
    weapon_10_app: 0.06,
    weapon_10_round: 50,
    artifact_1: 'Swing Jazz, Puffer Electro',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Puffer Electro',
    artifact_1_3: '',
    artifact_1_app: 53.64,
    artifact_1_round: 116,
    artifact_2: 'Freedom Blues, Puffer Electro',
    artifact_2_1: 'Freedom Blues',
    artifact_2_2: 'Puffer Electro',
    artifact_2_3: '',
    artifact_2_app: 13.35,
    artifact_2_round: 98,
    artifact_3: 'Astral Voice, Swing Jazz',
    artifact_3_1: 'Astral Voice',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 7.35,
    artifact_3_round: 102,
    artifact_4: 'Astral Voice, Puffer Electro',
    artifact_4_1: 'Astral Voice',
    artifact_4_2: 'Puffer Electro',
    artifact_4_3: '',
    artifact_4_app: 5.21,
    artifact_4_round: 104,
    artifact_5: 'Freedom Blues, Swing Jazz',
    artifact_5_1: 'Freedom Blues',
    artifact_5_2: 'Swing Jazz',
    artifact_5_3: '',
    artifact_5_app: 3.92,
    artifact_5_round: 111,
    artifact_6: 'Swing Jazz, Freedom Blues',
    artifact_6_1: 'Swing Jazz',
    artifact_6_2: 'Freedom Blues',
    artifact_6_3: '',
    artifact_6_app: 2.94,
    artifact_6_round: 122,
    artifact_7: 'Swing Jazz, Flex',
    artifact_7_1: 'Swing Jazz',
    artifact_7_2: 'Flex',
    artifact_7_3: '',
    artifact_7_app: 1.29,
    artifact_7_round: 144,
    artifact_8: 'Puffer Electro, Swing Jazz, Flex',
    artifact_8_1: 'Puffer Electro',
    artifact_8_2: 'Swing Jazz',
    artifact_8_3: 'Flex',
    artifact_8_app: 0.98,
    artifact_8_round: 127,
    artifact_9: 'Freedom Blues, Thunder Metal',
    artifact_9_1: 'Freedom Blues',
    artifact_9_2: 'Thunder Metal',
    artifact_9_3: '',
    artifact_9_app: 0.98,
    artifact_9_round: 139,
    artifact_10: 'Swing Jazz, Thunder Metal',
    artifact_10_1: 'Swing Jazz',
    artifact_10_2: 'Thunder Metal',
    artifact_10_3: '',
    artifact_10_app: 0.8,
    artifact_10_round: 130,
    app_0: 47.76,
    round_0: 119,
    app_1: 37.23,
    round_1: 108,
    app_2: 11.39,
    round_2: 115,
    app_3: 2.82,
    round_3: 108,
    app_4: 0.67,
    round_4: 91,
    app_5: 0.06,
    round_5: 104,
    app_6: 0.06,
    round_6: 53,
    cons_avg: 0.72,
    sample: 1633,
    sample_app_flat: 1633
  },
  {
    char: 'zhu-yuan',
    app_rate: 25.4,
    avg_round: 123,
    std_dev_round: 35,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: 6.57,
    diff_rounds: -19,
    weapon_1: 'Riot Suppressor Mark VI',
    weapon_1_app: 43.6,
    weapon_1_round: 118,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 30.11,
    weapon_2_round: 128,
    weapon_3: 'The Brimstone',
    weapon_3_app: 13.06,
    weapon_3_round: 118,
    weapon_4: 'Marcato Desire',
    weapon_4_app: 3.65,
    weapon_4_round: 123,
    weapon_5: 'Gilded Blossom',
    weapon_5_app: 3.65,
    weapon_5_round: 150,
    weapon_6: 'Cannon Rotor',
    weapon_6_app: 3.39,
    weapon_6_round: 142,
    weapon_7: 'Steel Cushion',
    weapon_7_app: 1.36,
    weapon_7_round: 121,
    weapon_8: 'Street Superstar',
    weapon_8_app: 0.68,
    weapon_8_round: 150,
    weapon_9: 'Deep Sea Visitor',
    weapon_9_app: 0.17,
    weapon_9_round: 148,
    weapon_10: 'Kaboom the Cannon',
    weapon_10_app: 0.08,
    weapon_10_round: 76,
    artifact_1: 'Chaotic Metal, Woodpecker Electro',
    artifact_1_1: 'Chaotic Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 80.07,
    artifact_1_round: 125,
    artifact_2: 'Chaotic Metal, Hormone Punk',
    artifact_2_1: 'Chaotic Metal',
    artifact_2_2: 'Hormone Punk',
    artifact_2_3: '',
    artifact_2_app: 4.75,
    artifact_2_round: 114,
    artifact_3: 'Chaotic Metal, Branch & Blade Song',
    artifact_3_1: 'Chaotic Metal',
    artifact_3_2: 'Branch & Blade Song',
    artifact_3_3: '',
    artifact_3_app: 3.05,
    artifact_3_round: 115,
    artifact_4: 'Chaotic Metal, Swing Jazz',
    artifact_4_1: 'Chaotic Metal',
    artifact_4_2: 'Swing Jazz',
    artifact_4_3: '',
    artifact_4_app: 2.54,
    artifact_4_round: 114,
    artifact_5: 'Woodpecker Electro, Chaotic Metal',
    artifact_5_1: 'Woodpecker Electro',
    artifact_5_2: 'Chaotic Metal',
    artifact_5_3: '',
    artifact_5_app: 2.46,
    artifact_5_round: 121,
    artifact_6: 'Chaotic Metal, Flex',
    artifact_6_1: 'Chaotic Metal',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 2.21,
    artifact_6_round: 135,
    artifact_7: 'Chaotic Metal, Puffer Electro',
    artifact_7_1: 'Chaotic Metal',
    artifact_7_2: 'Puffer Electro',
    artifact_7_3: '',
    artifact_7_app: 1.44,
    artifact_7_round: 113,
    artifact_8: 'Hormone Punk, Woodpecker Electro',
    artifact_8_1: 'Hormone Punk',
    artifact_8_2: 'Woodpecker Electro',
    artifact_8_3: '',
    artifact_8_app: 0.68,
    artifact_8_round: 102,
    artifact_9: 'Chaotic Metal, Astral Voice',
    artifact_9_1: 'Chaotic Metal',
    artifact_9_2: 'Astral Voice',
    artifact_9_3: '',
    artifact_9_app: 0.59,
    artifact_9_round: 106,
    artifact_10: 'Hormone Punk, Chaotic Metal',
    artifact_10_1: 'Hormone Punk',
    artifact_10_2: 'Chaotic Metal',
    artifact_10_3: '',
    artifact_10_app: 0.59,
    artifact_10_round: 78,
    app_0: 84.14,
    round_0: 123,
    app_1: 13.23,
    round_1: 112,
    app_2: 2.63,
    round_2: 107,
    app_3: 0.0,
    round_3: 600,
    app_4: 0.0,
    round_4: 600,
    app_5: 0.0,
    round_5: 600,
    app_6: 0.0,
    round_6: 600,
    cons_avg: 0.18,
    sample: 1179,
    sample_app_flat: 1179
  },
  {
    char: 'nicole-demara',
    app_rate: 36.5,
    avg_round: 124,
    std_dev_round: 41,
    role: 'Support',
    rarity: 'A',
    diff: 10.21,
    diff_rounds: -13,
    weapon_1: 'The Vault',
    weapon_1_app: 90.73,
    weapon_1_round: 123,
    weapon_2: 'Weeping Cradle',
    weapon_2_app: 5.19,
    weapon_2_round: 124,
    weapon_3: 'Kaboom the Cannon',
    weapon_3_app: 1.53,
    weapon_3_round: 123,
    weapon_4: 'Slice of Time',
    weapon_4_app: 1.06,
    weapon_4_round: 149,
    weapon_5: '[Reverb] Mark II',
    weapon_5_app: 0.53,
    weapon_5_round: 155,
    weapon_6: 'Unfettered Game Ball',
    weapon_6_app: 0.35,
    weapon_6_round: 156,
    weapon_7: '[Reverb] Mark III',
    weapon_7_app: 0.18,
    weapon_7_round: 144,
    weapon_8: 'The Brimstone',
    weapon_8_app: 0.06,
    weapon_8_round: 166,
    weapon_9: '[Reverb] Mark I',
    weapon_9_app: 0.06,
    weapon_9_round: 123,
    weapon_10: 'Bashful Demon',
    weapon_10_app: 0.06,
    weapon_10_round: 158,
    artifact_1: 'Swing Jazz, Chaotic Metal',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Chaotic Metal',
    artifact_1_3: '',
    artifact_1_app: 42.56,
    artifact_1_round: 126,
    artifact_2: 'Swing Jazz, Freedom Blues',
    artifact_2_1: 'Swing Jazz',
    artifact_2_2: 'Freedom Blues',
    artifact_2_3: '',
    artifact_2_app: 24.09,
    artifact_2_round: 123,
    artifact_3: 'Astral Voice, Swing Jazz',
    artifact_3_1: 'Astral Voice',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 5.9,
    artifact_3_round: 115,
    artifact_4: 'Swing Jazz, Woodpecker Electro',
    artifact_4_1: 'Swing Jazz',
    artifact_4_2: 'Woodpecker Electro',
    artifact_4_3: '',
    artifact_4_app: 4.13,
    artifact_4_round: 117,
    artifact_5: 'Freedom Blues, Swing Jazz',
    artifact_5_1: 'Freedom Blues',
    artifact_5_2: 'Swing Jazz',
    artifact_5_3: '',
    artifact_5_app: 3.31,
    artifact_5_round: 124,
    artifact_6: 'Swing Jazz, Flex',
    artifact_6_1: 'Swing Jazz',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 3.25,
    artifact_6_round: 124,
    artifact_7: 'Chaotic Metal, Swing Jazz',
    artifact_7_1: 'Chaotic Metal',
    artifact_7_2: 'Swing Jazz',
    artifact_7_3: '',
    artifact_7_app: 2.72,
    artifact_7_round: 134,
    artifact_8: 'Swing Jazz, Chaos Jazz',
    artifact_8_1: 'Swing Jazz',
    artifact_8_2: 'Chaos Jazz',
    artifact_8_3: '',
    artifact_8_app: 2.42,
    artifact_8_round: 109,
    artifact_9: 'Swing Jazz, Hormone Punk',
    artifact_9_1: 'Swing Jazz',
    artifact_9_2: 'Hormone Punk',
    artifact_9_3: '',
    artifact_9_app: 1.77,
    artifact_9_round: 118,
    artifact_10: 'Swing Jazz, Shockstar Disco',
    artifact_10_1: 'Swing Jazz',
    artifact_10_2: 'Shockstar Disco',
    artifact_10_3: '',
    artifact_10_app: 1.36,
    artifact_10_round: 122,
    app_0: 0.06,
    round_0: 156,
    app_1: 0.24,
    round_1: 131,
    app_2: 0.71,
    round_2: 163,
    app_3: 2.01,
    round_3: 129,
    app_4: 1.71,
    round_4: 142,
    app_5: 4.25,
    round_5: 135,
    app_6: 91.03,
    round_6: 122,
    cons_avg: 5.82,
    sample: 1694,
    sample_app_flat: 1694
  },
  {
    char: 'Ellen',
    app_rate: 18.03,
    avg_round: 129,
    std_dev_round: 37,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: 0.18,
    diff_rounds: -31,
    weapon_1: 'Deep Sea Visitor',
    weapon_1_app: 69.06,
    weapon_1_round: 124,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 8.6,
    weapon_2_round: 148,
    weapon_3: 'The Brimstone',
    weapon_3_app: 5.02,
    weapon_3_round: 129,
    weapon_4: 'Cannon Rotor',
    weapon_4_app: 4.42,
    weapon_4_round: 137,
    weapon_5: 'Marcato Desire',
    weapon_5_app: 3.94,
    weapon_5_round: 147,
    weapon_6: 'Steel Cushion',
    weapon_6_app: 3.46,
    weapon_6_round: 136,
    weapon_7: 'Gilded Blossom',
    weapon_7_app: 3.35,
    weapon_7_round: 141,
    weapon_8: 'Street Superstar',
    weapon_8_app: 1.31,
    weapon_8_round: 140,
    weapon_9: 'Riot Suppressor Mark VI',
    weapon_9_app: 0.6,
    weapon_9_round: 121,
    weapon_10: 'Zanshin Herb Case',
    weapon_10_app: 0.12,
    weapon_10_round: 99,
    artifact_1: 'Polar Metal, Woodpecker Electro',
    artifact_1_1: 'Polar Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 53.76,
    artifact_1_round: 134,
    artifact_2: 'Woodpecker Electro, Polar Metal',
    artifact_2_1: 'Woodpecker Electro',
    artifact_2_2: 'Polar Metal',
    artifact_2_3: '',
    artifact_2_app: 9.08,
    artifact_2_round: 125,
    artifact_3: 'Woodpecker Electro, Puffer Electro',
    artifact_3_1: 'Woodpecker Electro',
    artifact_3_2: 'Puffer Electro',
    artifact_3_3: '',
    artifact_3_app: 8.84,
    artifact_3_round: 125,
    artifact_4: 'Polar Metal, Puffer Electro',
    artifact_4_1: 'Polar Metal',
    artifact_4_2: 'Puffer Electro',
    artifact_4_3: '',
    artifact_4_app: 7.29,
    artifact_4_round: 112,
    artifact_5: 'Polar Metal, Branch & Blade Song',
    artifact_5_1: 'Polar Metal',
    artifact_5_2: 'Branch & Blade Song',
    artifact_5_3: '',
    artifact_5_app: 5.26,
    artifact_5_round: 125,
    artifact_6: 'Polar Metal, Hormone Punk',
    artifact_6_1: 'Polar Metal',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: '',
    artifact_6_app: 4.78,
    artifact_6_round: 129,
    artifact_7: 'Polar Metal, Flex',
    artifact_7_1: 'Polar Metal',
    artifact_7_2: 'Flex',
    artifact_7_3: '',
    artifact_7_app: 2.03,
    artifact_7_round: 145,
    artifact_8: 'Woodpecker Electro, Branch & Blade Song',
    artifact_8_1: 'Woodpecker Electro',
    artifact_8_2: 'Branch & Blade Song',
    artifact_8_3: '',
    artifact_8_app: 1.79,
    artifact_8_round: 115,
    artifact_9: 'Woodpecker Electro, Hormone Punk',
    artifact_9_1: 'Woodpecker Electro',
    artifact_9_2: 'Hormone Punk',
    artifact_9_3: '',
    artifact_9_app: 1.67,
    artifact_9_round: 118,
    artifact_10: 'Puffer Electro, Woodpecker Electro',
    artifact_10_1: 'Puffer Electro',
    artifact_10_2: 'Woodpecker Electro',
    artifact_10_3: '',
    artifact_10_app: 1.08,
    artifact_10_round: 130,
    app_0: 75.27,
    round_0: 129,
    app_1: 17.44,
    round_1: 126,
    app_2: 7.29,
    round_2: 114,
    app_3: 0.0,
    round_3: 600,
    app_4: 0.0,
    round_4: 600,
    app_5: 0.0,
    round_5: 600,
    app_6: 0.0,
    round_6: 600,
    cons_avg: 0.32,
    sample: 837,
    sample_app_flat: 837
  },
  {
    char: 'Qingyi',
    app_rate: 39.67,
    avg_round: 133,
    std_dev_round: 40,
    role: 'Stun',
    rarity: 'Limited S',
    diff: 15.04,
    diff_rounds: -8,
    weapon_1: 'Ice-Jade Teapot',
    weapon_1_app: 31.94,
    weapon_1_round: 125,
    weapon_2: 'Steam Oven',
    weapon_2_app: 22.6,
    weapon_2_round: 138,
    weapon_3: 'The Restrained',
    weapon_3_app: 15.81,
    weapon_3_round: 129,
    weapon_4: 'Demara Battery Mark II',
    weapon_4_app: 14.99,
    weapon_4_round: 143,
    weapon_5: 'Hellfire Gears',
    weapon_5_app: 8.58,
    weapon_5_round: 134,
    weapon_6: 'Precious Fossilized Core',
    weapon_6_app: 4.78,
    weapon_6_round: 140,
    weapon_7: 'Six Shooter',
    weapon_7_app: 0.87,
    weapon_7_round: 146,
    weapon_8: '[Vortex] Arrow',
    weapon_8_app: 0.16,
    weapon_8_round: 93,
    weapon_9: '[Vortex] Revolver',
    weapon_9_app: 0.05,
    weapon_9_round: 124,
    weapon_10: 'Blazing Laurel',
    weapon_10_app: 0.05,
    weapon_10_round: 104,
    artifact_1: 'Shockstar Disco, Thunder Metal',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Thunder Metal',
    artifact_1_3: '',
    artifact_1_app: 40.85,
    artifact_1_round: 138,
    artifact_2: 'Shockstar Disco, Swing Jazz',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Swing Jazz',
    artifact_2_3: '',
    artifact_2_app: 38.29,
    artifact_2_round: 130,
    artifact_3: 'Shockstar Disco, Woodpecker Electro',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 11.84,
    artifact_3_round: 122,
    artifact_4: 'Shockstar Disco, Flex',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 1.79,
    artifact_4_round: 138,
    artifact_5: 'Shockstar Disco, Hormone Punk',
    artifact_5_1: 'Shockstar Disco',
    artifact_5_2: 'Hormone Punk',
    artifact_5_3: '',
    artifact_5_app: 1.52,
    artifact_5_round: 136,
    artifact_6: 'Shockstar Disco, Puffer Electro',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Puffer Electro',
    artifact_6_3: '',
    artifact_6_app: 1.14,
    artifact_6_round: 146,
    artifact_7: 'Shockstar Disco, Freedom Blues',
    artifact_7_1: 'Shockstar Disco',
    artifact_7_2: 'Freedom Blues',
    artifact_7_3: '',
    artifact_7_app: 1.09,
    artifact_7_round: 137,
    artifact_8: 'Swing Jazz, Shockstar Disco',
    artifact_8_1: 'Swing Jazz',
    artifact_8_2: 'Shockstar Disco',
    artifact_8_3: '',
    artifact_8_app: 0.38,
    artifact_8_round: 84,
    artifact_9: 'Thunder Metal, Shockstar Disco',
    artifact_9_1: 'Thunder Metal',
    artifact_9_2: 'Shockstar Disco',
    artifact_9_3: '',
    artifact_9_app: 0.33,
    artifact_9_round: 160,
    artifact_10: 'Shockstar Disco, Thunder Metal, Flex',
    artifact_10_1: 'Shockstar Disco',
    artifact_10_2: 'Thunder Metal',
    artifact_10_3: 'Flex',
    artifact_10_app: 0.27,
    artifact_10_round: 174,
    app_0: 89.9,
    round_0: 133,
    app_1: 8.31,
    round_1: 113,
    app_2: 1.79,
    round_2: 95,
    app_3: 0.0,
    round_3: 600,
    app_4: 0.0,
    round_4: 600,
    app_5: 0.0,
    round_5: 600,
    app_6: 0.0,
    round_6: 600,
    cons_avg: 0.12,
    sample: 1841,
    sample_app_flat: 1841
  },
  {
    char: 'billy-kid',
    app_rate: 0.52,
    avg_round: 142,
    std_dev_round: 53,
    role: 'Damage Dealer',
    rarity: 'A',
    diff: -0.46,
    diff_rounds: -4,
    weapon_1: 'Starlight Engine Replica',
    weapon_1_app: 41.67,
    weapon_1_round: 144,
    weapon_2: 'The Brimstone',
    weapon_2_app: 16.67,
    weapon_2_round: 105,
    weapon_3: 'Steel Cushion',
    weapon_3_app: 12.5,
    weapon_3_round: 203,
    weapon_4: 'Marcato Desire',
    weapon_4_app: 8.33,
    weapon_4_round: 138,
    weapon_5: 'Cannon Rotor',
    weapon_5_app: 8.33,
    weapon_5_round: 146,
    weapon_6: 'Starlight Engine',
    weapon_6_app: 8.33,
    weapon_6_round: 140,
    weapon_7: 'Zanshin Herb Case',
    weapon_7_app: 4.17,
    weapon_7_round: 83,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 600,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 600,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 600,
    artifact_1: 'Fanged Metal, Woodpecker Electro',
    artifact_1_1: 'Fanged Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 20.83,
    artifact_1_round: 159,
    artifact_2: 'Woodpecker Electro, Puffer Electro',
    artifact_2_1: 'Woodpecker Electro',
    artifact_2_2: 'Puffer Electro',
    artifact_2_3: '',
    artifact_2_app: 16.67,
    artifact_2_round: 111,
    artifact_3: 'Woodpecker Electro, Fanged Metal',
    artifact_3_1: 'Woodpecker Electro',
    artifact_3_2: 'Fanged Metal',
    artifact_3_3: '',
    artifact_3_app: 16.67,
    artifact_3_round: 124,
    artifact_4: 'Puffer Electro, Woodpecker Electro',
    artifact_4_1: 'Puffer Electro',
    artifact_4_2: 'Woodpecker Electro',
    artifact_4_3: '',
    artifact_4_app: 12.5,
    artifact_4_round: 137,
    artifact_5: 'Woodpecker Electro, Hormone Punk',
    artifact_5_1: 'Woodpecker Electro',
    artifact_5_2: 'Hormone Punk',
    artifact_5_3: '',
    artifact_5_app: 8.33,
    artifact_5_round: 247,
    artifact_6: 'Puffer Electro, Flex',
    artifact_6_1: 'Puffer Electro',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 4.17,
    artifact_6_round: 146,
    artifact_7: 'Fanged Metal, Shockstar Disco, Woodpecker Electro',
    artifact_7_1: 'Fanged Metal',
    artifact_7_2: 'Shockstar Disco',
    artifact_7_3: 'Woodpecker Electro',
    artifact_7_app: 4.17,
    artifact_7_round: 38,
    artifact_8: 'Puffer Electro, Fanged Metal',
    artifact_8_1: 'Puffer Electro',
    artifact_8_2: 'Fanged Metal',
    artifact_8_3: '',
    artifact_8_app: 4.17,
    artifact_8_round: 131,
    artifact_9: 'Woodpecker Electro, Astral Voice',
    artifact_9_1: 'Woodpecker Electro',
    artifact_9_2: 'Astral Voice',
    artifact_9_3: '',
    artifact_9_app: 4.17,
    artifact_9_round: 103,
    artifact_10: 'Woodpecker Electro, Branch & Blade Song',
    artifact_10_1: 'Woodpecker Electro',
    artifact_10_2: 'Branch & Blade Song',
    artifact_10_3: '',
    artifact_10_app: 4.17,
    artifact_10_round: 166,
    app_0: 0.0,
    round_0: 600,
    app_1: 0.0,
    round_1: 600,
    app_2: 4.17,
    round_2: 153,
    app_3: 0.0,
    round_3: 600,
    app_4: 4.17,
    round_4: 182,
    app_5: 0.0,
    round_5: 600,
    app_6: 91.67,
    round_6: 140,
    cons_avg: 5.75,
    sample: 24,
    sample_app_flat: 24
  },
  {
    char: 'jane-doe',
    app_rate: 14.05,
    avg_round: 144,
    std_dev_round: 47,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: -38.82,
    diff_rounds: 41,
    weapon_1: 'Sharpened Stinger',
    weapon_1_app: 63.04,
    weapon_1_round: 140,
    weapon_2: 'Weeping Gemini',
    weapon_2_app: 13.8,
    weapon_2_round: 146,
    weapon_3: 'Rainforest Gourmet',
    weapon_3_app: 11.81,
    weapon_3_round: 151,
    weapon_4: 'Electro-Lip Gloss',
    weapon_4_app: 8.44,
    weapon_4_round: 155,
    weapon_5: 'Fusion Compiler',
    weapon_5_app: 1.99,
    weapon_5_round: 169,
    weapon_6: 'Roaring Ride',
    weapon_6_app: 0.77,
    weapon_6_round: 161,
    weapon_7: 'Kaboom the Cannon',
    weapon_7_app: 0.15,
    weapon_7_round: 130,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 600,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 600,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 600,
    artifact_1: 'Fanged Metal, Freedom Blues',
    artifact_1_1: 'Fanged Metal',
    artifact_1_2: 'Freedom Blues',
    artifact_1_3: '',
    artifact_1_app: 86.81,
    artifact_1_round: 145,
    artifact_2: 'Fanged Metal, Chaos Jazz',
    artifact_2_1: 'Fanged Metal',
    artifact_2_2: 'Chaos Jazz',
    artifact_2_3: '',
    artifact_2_app: 5.52,
    artifact_2_round: 135,
    artifact_3: 'Fanged Metal, Hormone Punk',
    artifact_3_1: 'Fanged Metal',
    artifact_3_2: 'Hormone Punk',
    artifact_3_3: '',
    artifact_3_app: 2.91,
    artifact_3_round: 144,
    artifact_4: 'Freedom Blues, Fanged Metal',
    artifact_4_1: 'Freedom Blues',
    artifact_4_2: 'Fanged Metal',
    artifact_4_3: '',
    artifact_4_app: 1.07,
    artifact_4_round: 140,
    artifact_5: 'Freedom Blues, Chaos Jazz',
    artifact_5_1: 'Freedom Blues',
    artifact_5_2: 'Chaos Jazz',
    artifact_5_3: '',
    artifact_5_app: 0.92,
    artifact_5_round: 120,
    artifact_6: 'AP +30, AP +30, Fanged Metal',
    artifact_6_1: 'AP +30',
    artifact_6_2: 'AP +30',
    artifact_6_3: 'Fanged Metal',
    artifact_6_app: 0.46,
    artifact_6_round: 102,
    artifact_7: 'Fanged Metal, Flex',
    artifact_7_1: 'Fanged Metal',
    artifact_7_2: 'Flex',
    artifact_7_3: '',
    artifact_7_app: 0.46,
    artifact_7_round: 161,
    artifact_8: 'Fanged Metal, Woodpecker Electro',
    artifact_8_1: 'Fanged Metal',
    artifact_8_2: 'Woodpecker Electro',
    artifact_8_3: '',
    artifact_8_app: 0.46,
    artifact_8_round: 191,
    artifact_9: 'Freedom Blues, Hormone Punk',
    artifact_9_1: 'Freedom Blues',
    artifact_9_2: 'Hormone Punk',
    artifact_9_3: '',
    artifact_9_app: 0.46,
    artifact_9_round: 191,
    artifact_10: 'Fanged Metal, Flex',
    artifact_10_1: 'Fanged Metal',
    artifact_10_2: 'Flex',
    artifact_10_3: '',
    artifact_10_app: 0.15,
    artifact_10_round: 87,
    app_0: 78.07,
    round_0: 144,
    app_1: 11.35,
    round_1: 137,
    app_2: 10.58,
    round_2: 117,
    app_3: 0.0,
    round_3: 600,
    app_4: 0.0,
    round_4: 600,
    app_5: 0.0,
    round_5: 600,
    app_6: 0.0,
    round_6: 600,
    cons_avg: 0.33,
    sample: 652,
    sample_app_flat: 652
  },
  {
    char: 'Seth',
    app_rate: 15.99,
    avg_round: 145,
    std_dev_round: 46,
    role: 'Support',
    rarity: 'A',
    diff: -10.61,
    diff_rounds: 30,
    weapon_1: 'Peacekeeper - Specialized',
    weapon_1_app: 85.98,
    weapon_1_round: 145,
    weapon_2: 'Bunny Band',
    weapon_2_app: 4.18,
    weapon_2_round: 142,
    weapon_3: 'Spring Embrace',
    weapon_3_app: 3.64,
    weapon_3_round: 139,
    weapon_4: 'Original Transmorpher',
    weapon_4_app: 2.02,
    weapon_4_round: 151,
    weapon_5: 'Big Cylinder',
    weapon_5_app: 1.62,
    weapon_5_round: 162,
    weapon_6: 'Steam Oven',
    weapon_6_app: 0.27,
    weapon_6_round: 128,
    weapon_7: '[Identity] Base',
    weapon_7_app: 0.27,
    weapon_7_round: 153,
    weapon_8: 'Starlight Engine',
    weapon_8_app: 0.27,
    weapon_8_round: 190,
    weapon_9: 'Tusks of Fury',
    weapon_9_app: 0.27,
    weapon_9_round: 126,
    weapon_10: 'Steel Cushion',
    weapon_10_app: 0.13,
    weapon_10_round: 153,
    artifact_1: 'Swing Jazz, Hormone Punk',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Hormone Punk',
    artifact_1_3: '',
    artifact_1_app: 31.54,
    artifact_1_round: 151,
    artifact_2: 'Proto Punk, Hormone Punk',
    artifact_2_1: 'Proto Punk',
    artifact_2_2: 'Hormone Punk',
    artifact_2_3: '',
    artifact_2_app: 13.48,
    artifact_2_round: 136,
    artifact_3: 'Proto Punk, Swing Jazz',
    artifact_3_1: 'Proto Punk',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 7.01,
    artifact_3_round: 129,
    artifact_4: 'Swing Jazz, Freedom Blues',
    artifact_4_1: 'Swing Jazz',
    artifact_4_2: 'Freedom Blues',
    artifact_4_3: '',
    artifact_4_app: 5.8,
    artifact_4_round: 150,
    artifact_5: 'Freedom Blues, Swing Jazz',
    artifact_5_1: 'Freedom Blues',
    artifact_5_2: 'Swing Jazz',
    artifact_5_3: '',
    artifact_5_app: 3.5,
    artifact_5_round: 142,
    artifact_6: 'Proto Punk, Shockstar Disco',
    artifact_6_1: 'Proto Punk',
    artifact_6_2: 'Shockstar Disco',
    artifact_6_3: '',
    artifact_6_app: 2.7,
    artifact_6_round: 114,
    artifact_7: 'Astral Voice, Swing Jazz',
    artifact_7_1: 'Astral Voice',
    artifact_7_2: 'Swing Jazz',
    artifact_7_3: '',
    artifact_7_app: 2.56,
    artifact_7_round: 149,
    artifact_8: 'Freedom Blues, Hormone Punk',
    artifact_8_1: 'Freedom Blues',
    artifact_8_2: 'Hormone Punk',
    artifact_8_3: '',
    artifact_8_app: 2.43,
    artifact_8_round: 138,
    artifact_9: 'ATK +10%, Swing Jazz, Flex',
    artifact_9_1: 'ATK +10%',
    artifact_9_2: 'Swing Jazz',
    artifact_9_3: 'Flex',
    artifact_9_app: 2.43,
    artifact_9_round: 154,
    artifact_10: 'Proto Punk, Freedom Blues',
    artifact_10_1: 'Proto Punk',
    artifact_10_2: 'Freedom Blues',
    artifact_10_3: '',
    artifact_10_app: 2.29,
    artifact_10_round: 146,
    app_0: 2.83,
    round_0: 167,
    app_1: 6.6,
    round_1: 156,
    app_2: 10.65,
    round_2: 152,
    app_3: 11.05,
    round_3: 150,
    app_4: 12.94,
    round_4: 152,
    app_5: 10.51,
    round_5: 143,
    app_6: 45.42,
    round_6: 137,
    cons_avg: 4.38,
    sample: 742,
    sample_app_flat: 742
  },
  {
    char: 'Koleda',
    app_rate: 2.52,
    avg_round: 147,
    std_dev_round: 49,
    role: 'Stun',
    rarity: 'Standard S',
    diff: -1.64,
    diff_rounds: -14,
    weapon_1: 'Hellfire Gears',
    weapon_1_app: 47.86,
    weapon_1_round: 143,
    weapon_2: 'Steam Oven',
    weapon_2_app: 29.91,
    weapon_2_round: 159,
    weapon_3: 'Precious Fossilized Core',
    weapon_3_app: 11.97,
    weapon_3_round: 140,
    weapon_4: 'Six Shooter',
    weapon_4_app: 3.42,
    weapon_4_round: 141,
    weapon_5: 'The Restrained',
    weapon_5_app: 2.56,
    weapon_5_round: 153,
    weapon_6: 'Demara Battery Mark II',
    weapon_6_app: 1.71,
    weapon_6_round: 92,
    weapon_7: '[Vortex] Arrow',
    weapon_7_app: 0.85,
    weapon_7_round: 186,
    weapon_8: '[Vortex] Revolver',
    weapon_8_app: 0.85,
    weapon_8_round: 90,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 600,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 600,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 68.38,
    artifact_1_round: 150,
    artifact_2: 'Shockstar Disco, Inferno Metal',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Inferno Metal',
    artifact_2_3: '',
    artifact_2_app: 7.69,
    artifact_2_round: 155,
    artifact_3: 'Shockstar Disco, Flex',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Flex',
    artifact_3_3: '',
    artifact_3_app: 6.84,
    artifact_3_round: 124,
    artifact_4: 'Shockstar Disco, Woodpecker Electro',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Woodpecker Electro',
    artifact_4_3: '',
    artifact_4_app: 5.13,
    artifact_4_round: 149,
    artifact_5: 'Shockstar Disco, Freedom Blues',
    artifact_5_1: 'Shockstar Disco',
    artifact_5_2: 'Freedom Blues',
    artifact_5_3: '',
    artifact_5_app: 5.13,
    artifact_5_round: 120,
    artifact_6: 'Shockstar Disco, Astral Voice',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Astral Voice',
    artifact_6_3: '',
    artifact_6_app: 1.71,
    artifact_6_round: 166,
    artifact_7: 'Proto Punk, Shockstar Disco',
    artifact_7_1: 'Proto Punk',
    artifact_7_2: 'Shockstar Disco',
    artifact_7_3: '',
    artifact_7_app: 0.85,
    artifact_7_round: 127,
    artifact_8: 'Shockstar Disco, Polar Metal',
    artifact_8_1: 'Shockstar Disco',
    artifact_8_2: 'Polar Metal',
    artifact_8_3: '',
    artifact_8_app: 0.85,
    artifact_8_round: 192,
    artifact_9: 'Shockstar Disco, Swing Jazz, Flex',
    artifact_9_1: 'Shockstar Disco',
    artifact_9_2: 'Swing Jazz',
    artifact_9_3: 'Flex',
    artifact_9_app: 0.85,
    artifact_9_round: 90,
    artifact_10: 'Puffer Electro, Shockstar Disco, Swing Jazz',
    artifact_10_1: 'Puffer Electro',
    artifact_10_2: 'Shockstar Disco',
    artifact_10_3: 'Swing Jazz',
    artifact_10_app: 0.85,
    artifact_10_round: 124,
    app_0: 48.72,
    round_0: 150,
    app_1: 28.21,
    round_1: 146,
    app_2: 17.09,
    round_2: 142,
    app_3: 5.13,
    round_3: 150,
    app_4: 0.85,
    round_4: 69,
    app_5: 0.0,
    round_5: 600,
    app_6: 0.0,
    round_6: 600,
    cons_avg: 0.81,
    sample: 117,
    sample_app_flat: 117
  },
  {
    char: 'grace-howard',
    app_rate: 6.03,
    avg_round: 148,
    std_dev_round: 56,
    role: 'Damage Dealer',
    rarity: 'Standard S',
    diff: 2.41,
    diff_rounds: 14,
    weapon_1: 'Fusion Compiler',
    weapon_1_app: 41.43,
    weapon_1_round: 148,
    weapon_2: 'Weeping Gemini',
    weapon_2_app: 24.64,
    weapon_2_round: 154,
    weapon_3: 'Rainforest Gourmet',
    weapon_3_app: 16.07,
    weapon_3_round: 147,
    weapon_4: 'Electro-Lip Gloss',
    weapon_4_app: 9.64,
    weapon_4_round: 136,
    weapon_5: 'Roaring Ride',
    weapon_5_app: 2.86,
    weapon_5_round: 144,
    weapon_6: 'Timeweaver',
    weapon_6_app: 2.5,
    weapon_6_round: 149,
    weapon_7: '[Magnetic Storm] Bravo',
    weapon_7_app: 1.07,
    weapon_7_round: 175,
    weapon_8: 'The Brimstone',
    weapon_8_app: 0.36,
    weapon_8_round: 284,
    weapon_9: 'Gilded Blossom',
    weapon_9_app: 0.36,
    weapon_9_round: 167,
    weapon_10: 'Slice of Time',
    weapon_10_app: 0.36,
    weapon_10_round: 130,
    artifact_1: 'Freedom Blues, Thunder Metal',
    artifact_1_1: 'Freedom Blues',
    artifact_1_2: 'Thunder Metal',
    artifact_1_3: '',
    artifact_1_app: 36.43,
    artifact_1_round: 157,
    artifact_2: 'Thunder Metal, Freedom Blues',
    artifact_2_1: 'Thunder Metal',
    artifact_2_2: 'Freedom Blues',
    artifact_2_3: '',
    artifact_2_app: 20.0,
    artifact_2_round: 158,
    artifact_3: 'Freedom Blues, Swing Jazz',
    artifact_3_1: 'Freedom Blues',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 7.5,
    artifact_3_round: 148,
    artifact_4: 'Chaos Jazz, Freedom Blues',
    artifact_4_1: 'Chaos Jazz',
    artifact_4_2: 'Freedom Blues',
    artifact_4_3: '',
    artifact_4_app: 6.79,
    artifact_4_round: 127,
    artifact_5: 'Freedom Blues, Chaos Jazz',
    artifact_5_1: 'Freedom Blues',
    artifact_5_2: 'Chaos Jazz',
    artifact_5_3: '',
    artifact_5_app: 6.07,
    artifact_5_round: 130,
    artifact_6: 'Thunder Metal, Chaos Jazz',
    artifact_6_1: 'Thunder Metal',
    artifact_6_2: 'Chaos Jazz',
    artifact_6_3: '',
    artifact_6_app: 2.86,
    artifact_6_round: 154,
    artifact_7: 'Chaos Jazz, Thunder Metal',
    artifact_7_1: 'Chaos Jazz',
    artifact_7_2: 'Thunder Metal',
    artifact_7_3: '',
    artifact_7_app: 2.5,
    artifact_7_round: 134,
    artifact_8: 'Freedom Blues, Puffer Electro',
    artifact_8_1: 'Freedom Blues',
    artifact_8_2: 'Puffer Electro',
    artifact_8_3: '',
    artifact_8_app: 2.14,
    artifact_8_round: 133,
    artifact_9: 'Chaos Jazz, Puffer Electro',
    artifact_9_1: 'Chaos Jazz',
    artifact_9_2: 'Puffer Electro',
    artifact_9_3: '',
    artifact_9_app: 1.79,
    artifact_9_round: 108,
    artifact_10: 'Thunder Metal, Swing Jazz',
    artifact_10_1: 'Thunder Metal',
    artifact_10_2: 'Swing Jazz',
    artifact_10_3: '',
    artifact_10_app: 1.43,
    artifact_10_round: 150,
    app_0: 51.43,
    round_0: 151,
    app_1: 35.36,
    round_1: 153,
    app_2: 10.0,
    round_2: 132,
    app_3: 2.86,
    round_3: 123,
    app_4: 0.36,
    round_4: 85,
    app_5: 0.0,
    round_5: 600,
    app_6: 0.0,
    round_6: 600,
    cons_avg: 0.65,
    sample: 280,
    sample_app_flat: 280
  },
  {
    char: 'Corin',
    app_rate: 0.56,
    avg_round: 149,
    std_dev_round: 42,
    role: 'Damage Dealer',
    rarity: 'A',
    diff: -0.82,
    diff_rounds: 2,
    weapon_1: 'Housekeeper',
    weapon_1_app: 57.69,
    weapon_1_round: 154,
    weapon_2: 'Steel Cushion',
    weapon_2_app: 26.92,
    weapon_2_round: 143,
    weapon_3: 'Starlight Engine',
    weapon_3_app: 7.69,
    weapon_3_round: 218,
    weapon_4: 'Gilded Blossom',
    weapon_4_app: 3.85,
    weapon_4_round: 110,
    weapon_5: '[Lunar] Decrescent',
    weapon_5_app: 3.85,
    weapon_5_round: 29,
    weapon_6: '',
    weapon_6_app: 0.0,
    weapon_6_round: 600,
    weapon_7: '',
    weapon_7_app: 0.0,
    weapon_7_round: 600,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 600,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 600,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 600,
    artifact_1: 'Fanged Metal, Woodpecker Electro',
    artifact_1_1: 'Fanged Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 30.77,
    artifact_1_round: 166,
    artifact_2: 'Hormone Punk, Woodpecker Electro',
    artifact_2_1: 'Hormone Punk',
    artifact_2_2: 'Woodpecker Electro',
    artifact_2_3: '',
    artifact_2_app: 15.38,
    artifact_2_round: 110,
    artifact_3: 'Woodpecker Electro, Fanged Metal',
    artifact_3_1: 'Woodpecker Electro',
    artifact_3_2: 'Fanged Metal',
    artifact_3_3: '',
    artifact_3_app: 15.38,
    artifact_3_round: 148,
    artifact_4: 'Hormone Punk, Fanged Metal',
    artifact_4_1: 'Hormone Punk',
    artifact_4_2: 'Fanged Metal',
    artifact_4_3: '',
    artifact_4_app: 11.54,
    artifact_4_round: 164,
    artifact_5: 'Puffer Electro, Woodpecker Electro',
    artifact_5_1: 'Puffer Electro',
    artifact_5_2: 'Woodpecker Electro',
    artifact_5_3: '',
    artifact_5_app: 3.85,
    artifact_5_round: 232,
    artifact_6: 'Woodpecker Electro, Puffer Electro',
    artifact_6_1: 'Woodpecker Electro',
    artifact_6_2: 'Puffer Electro',
    artifact_6_3: '',
    artifact_6_app: 3.85,
    artifact_6_round: 139,
    artifact_7: 'Fanged Metal, Puffer Electro, Woodpecker Electro',
    artifact_7_1: 'Fanged Metal',
    artifact_7_2: 'Puffer Electro',
    artifact_7_3: 'Woodpecker Electro',
    artifact_7_app: 3.85,
    artifact_7_round: 142,
    artifact_8: 'Fanged Metal, Hormone Punk',
    artifact_8_1: 'Fanged Metal',
    artifact_8_2: 'Hormone Punk',
    artifact_8_3: '',
    artifact_8_app: 3.85,
    artifact_8_round: 146,
    artifact_9: 'Puffer Electro, Hormone Punk',
    artifact_9_1: 'Puffer Electro',
    artifact_9_2: 'Hormone Punk',
    artifact_9_3: '',
    artifact_9_app: 3.85,
    artifact_9_round: 148,
    artifact_10: 'Woodpecker Electro, Hormone Punk',
    artifact_10_1: 'Woodpecker Electro',
    artifact_10_2: 'Hormone Punk',
    artifact_10_3: '',
    artifact_10_app: 3.85,
    artifact_10_round: 196,
    app_0: 3.85,
    round_0: 232,
    app_1: 0.0,
    round_1: 600,
    app_2: 0.0,
    round_2: 600,
    app_3: 0.0,
    round_3: 600,
    app_4: 3.85,
    round_4: 29,
    app_5: 0.0,
    round_5: 600,
    app_6: 92.31,
    round_6: 151,
    cons_avg: 5.69,
    sample: 26,
    sample_app_flat: 26
  },
  {
    char: 'Nekomata',
    app_rate: 0.8,
    avg_round: 154,
    std_dev_round: 56,
    role: 'Damage Dealer',
    rarity: 'Standard S',
    diff: -1.56,
    diff_rounds: 2,
    weapon_1: 'Steel Cushion',
    weapon_1_app: 59.46,
    weapon_1_round: 134,
    weapon_2: 'The Brimstone',
    weapon_2_app: 13.51,
    weapon_2_round: 150,
    weapon_3: 'Gilded Blossom',
    weapon_3_app: 8.11,
    weapon_3_round: 275,
    weapon_4: 'Starlight Engine',
    weapon_4_app: 5.41,
    weapon_4_round: 148,
    weapon_5: 'Cannon Rotor',
    weapon_5_app: 5.41,
    weapon_5_round: 196,
    weapon_6: 'Marcato Desire',
    weapon_6_app: 5.41,
    weapon_6_round: 144,
    weapon_7: 'Street Superstar',
    weapon_7_app: 2.7,
    weapon_7_round: 198,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 600,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 600,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 600,
    artifact_1: 'Fanged Metal, Woodpecker Electro',
    artifact_1_1: 'Fanged Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 40.54,
    artifact_1_round: 177,
    artifact_2: 'Woodpecker Electro, Fanged Metal',
    artifact_2_1: 'Woodpecker Electro',
    artifact_2_2: 'Fanged Metal',
    artifact_2_3: '',
    artifact_2_app: 16.22,
    artifact_2_round: 143,
    artifact_3: 'Fanged Metal, Hormone Punk',
    artifact_3_1: 'Fanged Metal',
    artifact_3_2: 'Hormone Punk',
    artifact_3_3: '',
    artifact_3_app: 13.51,
    artifact_3_round: 161,
    artifact_4: 'Hormone Punk, Fanged Metal',
    artifact_4_1: 'Hormone Punk',
    artifact_4_2: 'Fanged Metal',
    artifact_4_3: '',
    artifact_4_app: 8.11,
    artifact_4_round: 129,
    artifact_5: 'Woodpecker Electro, Hormone Punk',
    artifact_5_1: 'Woodpecker Electro',
    artifact_5_2: 'Hormone Punk',
    artifact_5_3: '',
    artifact_5_app: 5.41,
    artifact_5_round: 76,
    artifact_6: 'Branch & Blade Song, Fanged Metal, Woodpecker Electro',
    artifact_6_1: 'Branch & Blade Song',
    artifact_6_2: 'Fanged Metal',
    artifact_6_3: 'Woodpecker Electro',
    artifact_6_app: 2.7,
    artifact_6_round: 127,
    artifact_7: 'Fanged Metal, Branch & Blade Song',
    artifact_7_1: 'Fanged Metal',
    artifact_7_2: 'Branch & Blade Song',
    artifact_7_3: '',
    artifact_7_app: 2.7,
    artifact_7_round: 168,
    artifact_8: 'Woodpecker Electro, Puffer Electro',
    artifact_8_1: 'Woodpecker Electro',
    artifact_8_2: 'Puffer Electro',
    artifact_8_3: '',
    artifact_8_app: 2.7,
    artifact_8_round: 120,
    artifact_9: 'Puffer Electro, Astral Voice',
    artifact_9_1: 'Puffer Electro',
    artifact_9_2: 'Astral Voice',
    artifact_9_3: '',
    artifact_9_app: 2.7,
    artifact_9_round: 130,
    artifact_10: 'ATK +10%, Fanged Metal, Woodpecker Electro',
    artifact_10_1: 'ATK +10%',
    artifact_10_2: 'Fanged Metal',
    artifact_10_3: 'Woodpecker Electro',
    artifact_10_app: 2.7,
    artifact_10_round: 150,
    app_0: 43.24,
    round_0: 149,
    app_1: 21.62,
    round_1: 165,
    app_2: 24.32,
    round_2: 161,
    app_3: 10.81,
    round_3: 136,
    app_4: 0.0,
    round_4: 600,
    app_5: 0.0,
    round_5: 600,
    app_6: 0.0,
    round_6: 600,
    cons_avg: 1.03,
    sample: 37,
    sample_app_flat: 37
  },
  {
    char: 'Harumasa',
    app_rate: 22.84,
    avg_round: 156,
    std_dev_round: 49,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: 19.59,
    diff_rounds: -12,
    weapon_1: 'Marcato Desire',
    weapon_1_app: 37.08,
    weapon_1_round: 164,
    weapon_2: 'Zanshin Herb Case',
    weapon_2_app: 31.98,
    weapon_2_round: 141,
    weapon_3: 'The Brimstone',
    weapon_3_app: 11.98,
    weapon_3_round: 158,
    weapon_4: 'Cannon Rotor',
    weapon_4_app: 4.43,
    weapon_4_round: 172,
    weapon_5: 'Starlight Engine',
    weapon_5_app: 4.15,
    weapon_5_round: 158,
    weapon_6: 'Drill Rig - Red Axis',
    weapon_6_app: 3.77,
    weapon_6_round: 170,
    weapon_7: 'Gilded Blossom',
    weapon_7_app: 2.74,
    weapon_7_round: 181,
    weapon_8: 'Steel Cushion',
    weapon_8_app: 1.51,
    weapon_8_round: 166,
    weapon_9: 'Deep Sea Visitor',
    weapon_9_app: 0.38,
    weapon_9_round: 112,
    weapon_10: 'Riot Suppressor Mark VI',
    weapon_10_app: 0.38,
    weapon_10_round: 137,
    artifact_1: 'Thunder Metal, Woodpecker Electro',
    artifact_1_1: 'Thunder Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 53.49,
    artifact_1_round: 159,
    artifact_2: 'Thunder Metal, Branch & Blade Song',
    artifact_2_1: 'Thunder Metal',
    artifact_2_2: 'Branch & Blade Song',
    artifact_2_3: '',
    artifact_2_app: 7.64,
    artifact_2_round: 147,
    artifact_3: 'Woodpecker Electro, Thunder Metal',
    artifact_3_1: 'Woodpecker Electro',
    artifact_3_2: 'Thunder Metal',
    artifact_3_3: '',
    artifact_3_app: 7.08,
    artifact_3_round: 154,
    artifact_4: 'Hormone Punk, Thunder Metal',
    artifact_4_1: 'Hormone Punk',
    artifact_4_2: 'Thunder Metal',
    artifact_4_3: '',
    artifact_4_app: 4.81,
    artifact_4_round: 172,
    artifact_5: 'Thunder Metal, Hormone Punk',
    artifact_5_1: 'Thunder Metal',
    artifact_5_2: 'Hormone Punk',
    artifact_5_3: '',
    artifact_5_app: 3.58,
    artifact_5_round: 157,
    artifact_6: 'Woodpecker Electro, Branch & Blade Song',
    artifact_6_1: 'Woodpecker Electro',
    artifact_6_2: 'Branch & Blade Song',
    artifact_6_3: '',
    artifact_6_app: 3.3,
    artifact_6_round: 151,
    artifact_7: 'Thunder Metal, Puffer Electro',
    artifact_7_1: 'Thunder Metal',
    artifact_7_2: 'Puffer Electro',
    artifact_7_3: '',
    artifact_7_app: 2.45,
    artifact_7_round: 133,
    artifact_8: 'Hormone Punk, Woodpecker Electro',
    artifact_8_1: 'Hormone Punk',
    artifact_8_2: 'Woodpecker Electro',
    artifact_8_3: '',
    artifact_8_app: 2.08,
    artifact_8_round: 153,
    artifact_9: 'Thunder Metal, Flex',
    artifact_9_1: 'Thunder Metal',
    artifact_9_2: 'Flex',
    artifact_9_3: '',
    artifact_9_app: 1.98,
    artifact_9_round: 159,
    artifact_10: 'Branch & Blade Song, Thunder Metal, Woodpecker Electro',
    artifact_10_1: 'Branch & Blade Song',
    artifact_10_2: 'Thunder Metal',
    artifact_10_3: 'Woodpecker Electro',
    artifact_10_app: 1.79,
    artifact_10_round: 153,
    app_0: 91.6,
    round_0: 156,
    app_1: 6.13,
    round_1: 123,
    app_2: 2.26,
    round_2: 118,
    app_3: 0.0,
    round_3: 600,
    app_4: 0.0,
    round_4: 600,
    app_5: 0.0,
    round_5: 600,
    app_6: 0.0,
    round_6: 600,
    cons_avg: 0.11,
    sample: 1060,
    sample_app_flat: 1060
  },
  {
    char: 'anby-demara',
    app_rate: 10.08,
    avg_round: 162,
    std_dev_round: 51,
    role: 'Stun',
    rarity: 'A',
    diff: 4.71,
    diff_rounds: -4,
    weapon_1: 'Demara Battery Mark II',
    weapon_1_app: 66.67,
    weapon_1_round: 165,
    weapon_2: 'Steam Oven',
    weapon_2_app: 11.54,
    weapon_2_round: 150,
    weapon_3: 'Hellfire Gears',
    weapon_3_app: 9.62,
    weapon_3_round: 157,
    weapon_4: 'The Restrained',
    weapon_4_app: 8.12,
    weapon_4_round: 154,
    weapon_5: 'Precious Fossilized Core',
    weapon_5_app: 3.21,
    weapon_5_round: 184,
    weapon_6: 'Six Shooter',
    weapon_6_app: 0.43,
    weapon_6_round: 159,
    weapon_7: 'Ice-Jade Teapot',
    weapon_7_app: 0.21,
    weapon_7_round: 147,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 600,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 600,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 600,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 52.99,
    artifact_1_round: 157,
    artifact_2: 'Shockstar Disco, Thunder Metal',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Thunder Metal',
    artifact_2_3: '',
    artifact_2_app: 28.42,
    artifact_2_round: 171,
    artifact_3: 'Shockstar Disco, Woodpecker Electro',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 4.7,
    artifact_3_round: 167,
    artifact_4: 'Shockstar Disco, Flex',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 3.63,
    artifact_4_round: 173,
    artifact_5: 'Shockstar Disco, Hormone Punk',
    artifact_5_1: 'Shockstar Disco',
    artifact_5_2: 'Hormone Punk',
    artifact_5_3: '',
    artifact_5_app: 1.28,
    artifact_5_round: 134,
    artifact_6: 'Shockstar Disco, Freedom Blues',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Freedom Blues',
    artifact_6_3: '',
    artifact_6_app: 1.07,
    artifact_6_round: 166,
    artifact_7: 'Shockstar Disco, Swing Jazz, Flex',
    artifact_7_1: 'Shockstar Disco',
    artifact_7_2: 'Swing Jazz',
    artifact_7_3: 'Flex',
    artifact_7_app: 1.07,
    artifact_7_round: 169,
    artifact_8: 'Woodpecker Electro, Flex',
    artifact_8_1: 'Woodpecker Electro',
    artifact_8_2: 'Flex',
    artifact_8_3: '',
    artifact_8_app: 0.43,
    artifact_8_round: 102,
    artifact_9: 'Shockstar Disco, Thunder Metal, Flex',
    artifact_9_1: 'Shockstar Disco',
    artifact_9_2: 'Thunder Metal',
    artifact_9_3: 'Flex',
    artifact_9_app: 0.43,
    artifact_9_round: 165,
    artifact_10: 'Shockstar Disco, Flex',
    artifact_10_1: 'Shockstar Disco',
    artifact_10_2: 'Flex',
    artifact_10_3: '',
    artifact_10_app: 0.43,
    artifact_10_round: 280,
    app_0: 0.43,
    round_0: 164,
    app_1: 1.71,
    round_1: 161,
    app_2: 4.06,
    round_2: 160,
    app_3: 4.27,
    round_3: 150,
    app_4: 6.2,
    round_4: 148,
    app_5: 7.48,
    round_5: 178,
    app_6: 75.85,
    round_6: 162,
    cons_avg: 5.4,
    sample: 468,
    sample_app_flat: 468
  },
  {
    char: 'soldier-11',
    app_rate: 1.87,
    avg_round: 163,
    std_dev_round: 46,
    role: 'Damage Dealer',
    rarity: 'Standard S',
    diff: -7.88,
    diff_rounds: 18,
    weapon_1: 'The Brimstone',
    weapon_1_app: 63.22,
    weapon_1_round: 160,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 17.24,
    weapon_2_round: 161,
    weapon_3: 'Marcato Desire',
    weapon_3_app: 9.2,
    weapon_3_round: 165,
    weapon_4: 'Gilded Blossom',
    weapon_4_app: 5.75,
    weapon_4_round: 181,
    weapon_5: 'Cannon Rotor',
    weapon_5_app: 2.3,
    weapon_5_round: 201,
    weapon_6: 'Steel Cushion',
    weapon_6_app: 1.15,
    weapon_6_round: 148,
    weapon_7: '',
    weapon_7_app: 0.0,
    weapon_7_round: 600,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 600,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 600,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 600,
    artifact_1: 'Inferno Metal, Woodpecker Electro',
    artifact_1_1: 'Inferno Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 75.86,
    artifact_1_round: 174,
    artifact_2: 'Inferno Metal, Puffer Electro',
    artifact_2_1: 'Inferno Metal',
    artifact_2_2: 'Puffer Electro',
    artifact_2_3: '',
    artifact_2_app: 8.05,
    artifact_2_round: 122,
    artifact_3: 'Inferno Metal, Branch & Blade Song',
    artifact_3_1: 'Inferno Metal',
    artifact_3_2: 'Branch & Blade Song',
    artifact_3_3: '',
    artifact_3_app: 5.75,
    artifact_3_round: 104,
    artifact_4: 'Inferno Metal, Hormone Punk',
    artifact_4_1: 'Inferno Metal',
    artifact_4_2: 'Hormone Punk',
    artifact_4_3: '',
    artifact_4_app: 3.45,
    artifact_4_round: 148,
    artifact_5: 'Woodpecker Electro, Inferno Metal',
    artifact_5_1: 'Woodpecker Electro',
    artifact_5_2: 'Inferno Metal',
    artifact_5_3: '',
    artifact_5_app: 2.3,
    artifact_5_round: 122,
    artifact_6: 'Freedom Blues, Swing Jazz',
    artifact_6_1: 'Freedom Blues',
    artifact_6_2: 'Swing Jazz',
    artifact_6_3: '',
    artifact_6_app: 1.15,
    artifact_6_round: 104,
    artifact_7: 'Woodpecker Electro, Branch & Blade Song',
    artifact_7_1: 'Woodpecker Electro',
    artifact_7_2: 'Branch & Blade Song',
    artifact_7_3: '',
    artifact_7_app: 1.15,
    artifact_7_round: 153,
    artifact_8: 'Inferno Metal, Flex',
    artifact_8_1: 'Inferno Metal',
    artifact_8_2: 'Flex',
    artifact_8_3: '',
    artifact_8_app: 1.15,
    artifact_8_round: 216,
    artifact_9: 'Inferno Metal, Woodpecker Electro, Flex',
    artifact_9_1: 'Inferno Metal',
    artifact_9_2: 'Woodpecker Electro',
    artifact_9_3: 'Flex',
    artifact_9_app: 1.15,
    artifact_9_round: 134,
    artifact_10: '',
    artifact_10_1: '',
    artifact_10_2: '',
    artifact_10_3: '',
    artifact_10_app: 0.0,
    artifact_10_round: 600,
    app_0: 44.83,
    round_0: 166,
    app_1: 35.63,
    round_1: 169,
    app_2: 13.79,
    round_2: 148,
    app_3: 5.75,
    round_3: 141,
    app_4: 0.0,
    round_4: 600,
    app_5: 0.0,
    round_5: 600,
    app_6: 0.0,
    round_6: 600,
    cons_avg: 0.8,
    sample: 87,
    sample_app_flat: 87
  },
  {
    char: 'Anton',
    app_rate: 0.99,
    avg_round: 165,
    std_dev_round: 41,
    role: 'Damage Dealer',
    rarity: 'A',
    diff: 0.75,
    diff_rounds: -435,
    weapon_1: 'Drill Rig - Red Axis',
    weapon_1_app: 69.57,
    weapon_1_round: 177,
    weapon_2: 'The Brimstone',
    weapon_2_app: 10.87,
    weapon_2_round: 134,
    weapon_3: 'Starlight Engine',
    weapon_3_app: 8.7,
    weapon_3_round: 126,
    weapon_4: 'Marcato Desire',
    weapon_4_app: 4.35,
    weapon_4_round: 146,
    weapon_5: 'Cannon Rotor',
    weapon_5_app: 4.35,
    weapon_5_round: 164,
    weapon_6: 'Zanshin Herb Case',
    weapon_6_app: 2.17,
    weapon_6_round: 125,
    weapon_7: '',
    weapon_7_app: 0.0,
    weapon_7_round: 600,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 600,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 600,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 600,
    artifact_1: 'Thunder Metal, Woodpecker Electro',
    artifact_1_1: 'Thunder Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 69.57,
    artifact_1_round: 162,
    artifact_2: 'Thunder Metal, Hormone Punk',
    artifact_2_1: 'Thunder Metal',
    artifact_2_2: 'Hormone Punk',
    artifact_2_3: '',
    artifact_2_app: 6.52,
    artifact_2_round: 160,
    artifact_3: 'Thunder Metal, Woodpecker Electro, Flex',
    artifact_3_1: 'Thunder Metal',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: 'Flex',
    artifact_3_app: 4.35,
    artifact_3_round: 172,
    artifact_4: 'Woodpecker Electro, Branch & Blade Song',
    artifact_4_1: 'Woodpecker Electro',
    artifact_4_2: 'Branch & Blade Song',
    artifact_4_3: '',
    artifact_4_app: 2.17,
    artifact_4_round: 127,
    artifact_5: 'Thunder Metal, Branch & Blade Song',
    artifact_5_1: 'Thunder Metal',
    artifact_5_2: 'Branch & Blade Song',
    artifact_5_3: '',
    artifact_5_app: 2.17,
    artifact_5_round: 162,
    artifact_6: 'Puffer Electro, Hormone Punk',
    artifact_6_1: 'Puffer Electro',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: '',
    artifact_6_app: 2.17,
    artifact_6_round: 125,
    artifact_7: 'Thunder Metal, Swing Jazz',
    artifact_7_1: 'Thunder Metal',
    artifact_7_2: 'Swing Jazz',
    artifact_7_3: '',
    artifact_7_app: 2.17,
    artifact_7_round: 153,
    artifact_8: 'Branch & Blade Song, Thunder Metal, Puffer Electro',
    artifact_8_1: 'Branch & Blade Song',
    artifact_8_2: 'Thunder Metal',
    artifact_8_3: 'Puffer Electro',
    artifact_8_app: 2.17,
    artifact_8_round: 230,
    artifact_9: 'Thunder Metal, Puffer Electro',
    artifact_9_1: 'Thunder Metal',
    artifact_9_2: 'Puffer Electro',
    artifact_9_3: '',
    artifact_9_app: 2.17,
    artifact_9_round: 162,
    artifact_10: 'Woodpecker Electro, Thunder Metal',
    artifact_10_1: 'Woodpecker Electro',
    artifact_10_2: 'Thunder Metal',
    artifact_10_3: '',
    artifact_10_app: 2.17,
    artifact_10_round: 221,
    app_0: 0.0,
    round_0: 600,
    app_1: 0.0,
    round_1: 600,
    app_2: 2.17,
    round_2: 241,
    app_3: 4.35,
    round_3: 129,
    app_4: 4.35,
    round_4: 175,
    app_5: 2.17,
    round_5: 182,
    app_6: 86.96,
    round_6: 164,
    cons_avg: 5.67,
    sample: 46,
    sample_app_flat: 46
  },
  {
    char: 'Ben',
    app_rate: 0.45,
    avg_round: 166,
    std_dev_round: 56,
    role: 'Support',
    rarity: 'A',
    diff: -0.8,
    diff_rounds: -1,
    weapon_1: 'Big Cylinder',
    weapon_1_app: 61.9,
    weapon_1_round: 163,
    weapon_2: 'Tusks of Fury',
    weapon_2_app: 14.29,
    weapon_2_round: 143,
    weapon_3: 'Bunny Band',
    weapon_3_app: 9.52,
    weapon_3_round: 183,
    weapon_4: 'Hailstorm Shrine',
    weapon_4_app: 4.76,
    weapon_4_round: 128,
    weapon_5: 'Peacekeeper - Specialized',
    weapon_5_app: 4.76,
    weapon_5_round: 208,
    weapon_6: 'Original Transmorpher',
    weapon_6_app: 4.76,
    weapon_6_round: 228,
    weapon_7: '',
    weapon_7_app: 0.0,
    weapon_7_round: 600,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 600,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 600,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 600,
    artifact_1: 'Swing Jazz, Soul Rock',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Soul Rock',
    artifact_1_3: '',
    artifact_1_app: 14.29,
    artifact_1_round: 113,
    artifact_2: 'Inferno Metal, Puffer Electro',
    artifact_2_1: 'Inferno Metal',
    artifact_2_2: 'Puffer Electro',
    artifact_2_3: '',
    artifact_2_app: 9.52,
    artifact_2_round: 168,
    artifact_3: 'Shockstar Disco, Swing Jazz',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 9.52,
    artifact_3_round: 187,
    artifact_4: 'Proto Punk, Woodpecker Electro',
    artifact_4_1: 'Proto Punk',
    artifact_4_2: 'Woodpecker Electro',
    artifact_4_3: '',
    artifact_4_app: 4.76,
    artifact_4_round: 232,
    artifact_5: 'ATK +10%, Swing Jazz, Flex',
    artifact_5_1: 'ATK +10%',
    artifact_5_2: 'Swing Jazz',
    artifact_5_3: 'Flex',
    artifact_5_app: 4.76,
    artifact_5_round: 153,
    artifact_6: 'Swing Jazz, Proto Punk',
    artifact_6_1: 'Swing Jazz',
    artifact_6_2: 'Proto Punk',
    artifact_6_3: '',
    artifact_6_app: 4.76,
    artifact_6_round: 134,
    artifact_7: 'Proto Punk, Shockstar Disco',
    artifact_7_1: 'Proto Punk',
    artifact_7_2: 'Shockstar Disco',
    artifact_7_3: '',
    artifact_7_app: 4.76,
    artifact_7_round: 199,
    artifact_8: 'Proto Punk, Swing Jazz',
    artifact_8_1: 'Proto Punk',
    artifact_8_2: 'Swing Jazz',
    artifact_8_3: '',
    artifact_8_app: 4.76,
    artifact_8_round: 124,
    artifact_9: 'Shockstar Disco, Soul Rock, Swing Jazz',
    artifact_9_1: 'Shockstar Disco',
    artifact_9_2: 'Soul Rock',
    artifact_9_3: 'Swing Jazz',
    artifact_9_app: 4.76,
    artifact_9_round: 143,
    artifact_10: 'Woodpecker Electro, Branch & Blade Song',
    artifact_10_1: 'Woodpecker Electro',
    artifact_10_2: 'Branch & Blade Song',
    artifact_10_3: '',
    artifact_10_app: 4.76,
    artifact_10_round: 146,
    app_0: 4.76,
    round_0: 232,
    app_1: 0.0,
    round_1: 600,
    app_2: 0.0,
    round_2: 600,
    app_3: 0.0,
    round_3: 600,
    app_4: 0.0,
    round_4: 600,
    app_5: 0.0,
    round_5: 600,
    app_6: 95.24,
    round_6: 162,
    cons_avg: 5.71,
    sample: 21,
    sample_app_flat: 21
  }
];
