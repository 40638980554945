import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const NikkeGuidesClay: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Clay guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_clay.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Clay guide & review</h1>
          <h2>A guide & review for Clay in NIKKE: Goddess of Victory.</h2>
          <p>
            Last updated: <strong>20/06/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Clay" />
              <div className="employee-container for-nikke">
                <NikkeCharacter mode="icon" slug="clay" enablePopover />
              </div>
              <p>
                There's a reason that a Rewind - a scandalous cheering group
                couldn't recruit anyone but Clay. We expect Clay to be
                substandard but damn!
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                She is hot. She is sexy. But she is bad. It's balanced, as
                everything should be. To begin, her buffs are barely impactful,
                and her Burst Skill having a 40s CD just exacerbates things. The
                buff True Damage mimics the buff Attack Damage (i.e. from Crown
                or Tia) but is only applied to true damage. However, the only
                true damage dealers we have right now are Clay herself, FrimaTr,
                and LaplaceTr. Wrong Nikke, wrong timing.
              </p>
              <Alert variant="primary">
                <p>
                  If we append the suffix -Tr to a Nikke's name, that means we
                  are referring to their Favorite/Treasure version.
                </p>
              </Alert>
              <h5>Should I pull this unit?</h5>
              <p>
                No. Summer is at Bay and don't let the sin of Lust get the best
                of you.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <h5>Don't Miss</h5>
              <p>
                Although this kit's named Don't Miss, you can safely miss her
                lol. Currently, we have a trend that whenever a Nikke is bad,
                Shift Up will slap "▲ Damage to Projectile", and guess who has
                it? That's right! Clay.
              </p>
              <p>
                Her S1 will give True Damage buff to <s>all allies</s> herself,
                LaplaceTr, and FrimaTr during any Full Burst. This sounds nice
                on paper, but due to her being SMG, she will take{' '}
                <strong>at least</strong> 6s to give 2 stacks of S1, and the
                last stack will end at exactly 10th second (mostly won't happen
                due to reload, as well as targeting and cover animation).
              </p>
              <StaticImage
                src="../../../images/nikke/reviews/clay_1.webp"
                alt="Kit"
              />
              <p>
                To make her S1 give 3 stacks consistently, you will need either
                Reload Buff (Resilience or from other allies) or Max Ammo OL.
                She doesn't need much. Even a 50% Max Ammo increase is doable.
              </p>
              <StaticImage
                src="../../../images/nikke/reviews/clay_2.webp"
                alt="Kit"
              />
              <Alert variant="primary">
                <p>
                  The problem is her S1 is currently not worth all the
                  investments required for her to work. It offers merely{' '}
                  <strong>▲12.9% ~ 19.35% True Damage</strong> for just 3
                  Nikkes. Compared to Frima who can casually give 49% ~ 78% True
                  Damage buff without any overhead, they're not even in the same
                  league anymore! Right, she is B1, whereas Clay is B2, but can
                  she even beat Rem (if you plan to use LaplaceTr)? Not to
                  mention the 40s CD. The only brightside of this skill is she
                  can trigger it on any Full Burst, no need to burst herself.
                </p>
              </Alert>
              <h5>Wake Up!</h5>
              <p>
                Yes, that's right, Commander, wake up! She's not worth your
                hard-earned gems. Jokes aside, her S2 is actually interesting,
                because she gives Debuff Immunity every time you enter B1 stage
                (15s interval with CDR). Debuff Immunity is quite niché, but
                when it is needed, it can really shine, especially in Raids.
                Debuff Immunity has been covered within our Ade's guide, you can
                revisit if you want to know more about this mechanic.
              </p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={3}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Ade review"
                  link="/nikke/guides/guide-ade"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_ade.jpg"
                      alt="Ade review"
                    />
                  }
                />
              </Row>
              <p>
                The second part of her skill is, whenever Clay has 1 or more
                stacks of S1, she will immediately grant allies ATK buff. This
                starts 3s after Full Burst and lasts until FB ends (for a total
                of 7s FB + leftover).
              </p>
              <Alert variant="primary">
                <p>
                  Buff duration is okay, but the value is very underwhelming.
                  Because Clay is a supporter, her base ATK is lower than DPS by
                  17%, which means the perceived effect is just 16.72%.
                </p>
              </Alert>
              <p>
                Also, S2 will reapply Debuff Immunity (max 1 stack) if you use
                Re-Enter B1 Nikke like Tia, XRupee or B.Alice.
              </p>
              <h5>Go! Go! Victory!</h5>
              <p>
                This is actually the most interesting part of her kit, until we
                see that 40s CD. Dead on arrival for sure.
              </p>
              <p>
                Her burst value is roughly 25% of FrimaTr's Burst Skill. And
                being Supporter SMG means, even with Pierce, she deals no
                damage, not to mention weaker buffs like True Damage conversion.
                She would be have disrupted the meta if her Burst Skill had
                converted all allies' damage into True Damage, hence enabling
                FrimaTr's buff.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Tier List" />
              <h5>
                Campaign (LD) - <strong className="c">C</strong> | Campaign (HD)
                - <strong className="d">D</strong>
              </h5>
              <p>
                It's almost impossible to use Clay in campaign due to the lack
                of True Damage dealers. Her other buffs, which are ▲ATK and
                Debuff Immunity, are kinda lackluster too.
              </p>
              <h5>
                Boss (Solo) - <strong className="b">B</strong> | Boss (Adds) -{' '}
                <strong className="b">B</strong>
              </h5>
              <p>
                Against bosses, she may have her place when you rely on True
                Damage DPS like LaplaceTr, or when the boss has a very nasty
                debuff like Sunbather (-60% ATK debuff). Otherwise, she's just
                another Ade.
              </p>
              <h5>
                PVP - <strong className="c">C</strong>
              </h5>
              <p>
                Bringing SMG to PvP is always a bad idea unless you know exactly
                what you are doing. And let's be honest, her buff is never worth
                the risk of bring SMG to PVP fight. But, hey, you can use the
                Debuff Immunity to block Blanc's Burst, I suppose, but you need
                to be faster, and she is the opposite of that.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Overload
                  Guide Announcement
                </h6>
                <p>
                  We have published our very own Overload (OL) Guide on our
                  website, featuring up-to-date recommendations with priority
                  (PvE/PvP) and order of acquisition. We also included a reroll
                  guide for you who wish for better lines but are puzzled as to
                  whether you should lock your existing lines. Now, you can know
                  what is mathematically more economical: locking a line &
                  rerolling, or just rerolling all 3 lines! Everything is backed
                  up with maths, and we assure you, this is the most complete
                  and reliable Overload (OL) Guide. It might be the wordiest (we
                  are sorry for that), but in our perspective, it's better to
                  elaborate everything in detail than oversummarize stuff!
                </p>
                <p>
                  While we unfortunately took an unreasonably long time to
                  finalize the guide, we are humans, and humans make mistakes.
                  We would love to hear your feedback. Should you spot any
                  mistakes, let us know, and we'll fix them immediately.
                </p>
              </blockquote>
              <p>Links to the guides:</p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={2}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="OL Gear - Intro"
                  link="/nikke/guides/overload-gear-intro"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_ol.webp"
                      alt="Overload Gear - Intro"
                    />
                  }
                />
                <CategoryCard
                  title="OL Gear - Recommendations"
                  link="/nikke/guides/overload-gear-recommendations"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_olrec.jpg"
                      alt="OL Gear - Recommendations "
                    />
                  }
                />
                <CategoryCard
                  title="OL Gear - Rerolling"
                  link="/nikke/guides/overload-gear-reroll"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_olreroll.jpg"
                      alt="OL Gear - Rerolling "
                    />
                  }
                />
              </Row>
              <p>
                As discussed above, Clay needs an average roll of Max Ammo OL on
                her to give 3 stacks of S1 consistently. Otherwise, she needs
                nothing else because her scaling on OL is poor, and she herself
                is not worth CP padding.
              </p>
              <ul>
                <li>
                  <strong>Essential:</strong> -
                </li>
                <li>
                  <strong>Ideal:</strong> 1-2× Max Ammo
                </li>
                <li>
                  <strong>Passable:</strong> -
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <ul>
                <li>
                  <strong>Skill 1: 1~4</strong>
                </li>
                <ul>
                  <li>Low scaling, low impact, low investment.</li>
                </ul>
                <li>
                  <strong>Skill 2: 1~4</strong>
                </li>
                <ul>
                  <li>Just like above.</li>
                </ul>
                <li>
                  <strong>Burst Skill: 1~4</strong>
                </li>
                <ul>
                  <li>
                    We feel like a broken repeater here, but it's the same.
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="clay" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <p>
                Depends. Without Max Ammo OL, Resilience is better than Bastion
                because that will enable her to land 180 shots within 10s during
                Full Burst (for 3 stacks of S1), but there is barely any leeway.
                Bastion would only work if she never reloads during Full Burst
                at all, which requires Max Ammo and preemptive reloading.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: The True Damage Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="frima" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="clay" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="laplace" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
              </div>
              <p>
                This team is built around LaplaceTr and FrimaTr for obvious
                reasons stated above. Frima is actually a decent healer, so you
                won't have to worry much about healing here. Even then, if you
                have Rem, Rem is just better. Period. Exile Clay.
              </p>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvP Team Compositions" />
              <p>
                This character isn't good in PVP, so there are no PVP team
                compositions available. Just don't use her.
              </p>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Long buff durations.</li>
                        <li>Good against debuff stages.</li>
                        <li>She's the 2nd Nikke giving ▲True Damage.</li>
                        <li>Her appearance is top-notch.</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>40s CD B2.</li>
                        <li>Her kit is interesting; her value isn't.</li>
                        <li>
                          The lack of True Damage dealers renders her
                          ineffectual in the current meta.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesClay;

export const Head: React.FC = () => (
  <Seo
    title="Clay guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Clay in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
