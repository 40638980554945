export const HSRMocHisto = [
  {
    slug: 'acheron',
    score: 8.45,
    usage: 43.49,
    role: 'dps'
  },
  {
    slug: 'argenti',
    score: 11,
    usage: 0.11,
    role: 'dps'
  },
  {
    slug: 'arlan',
    score: 11,
    usage: 0.01,
    role: 'dps'
  },
  {
    slug: 'asta',
    score: 9.94,
    usage: 0.71,
    role: 'amplifier'
  },
  {
    slug: 'aventurine',
    score: 7.73,
    usage: 51.01,
    role: 'sustain'
  },
  {
    slug: 'bailu',
    score: 9.02,
    usage: 0.97,
    role: 'sustain'
  },
  {
    slug: 'black-swan',
    score: 8.96,
    usage: 17.85,
    role: 'specialist'
  },
  {
    slug: 'blade',
    score: 9.73,
    usage: 1.38,
    role: 'dps'
  },
  {
    slug: 'boothill',
    score: 8.68,
    usage: 2.71,
    role: 'specialist'
  },
  {
    slug: 'bronya',
    score: 8.63,
    usage: 6.92,
    role: 'amplifier'
  },
  {
    slug: 'clara',
    score: 9.08,
    usage: 1.98,
    role: 'dps'
  },
  {
    slug: 'dan-heng',
    score: 11,
    usage: 0.03,
    role: 'dps'
  },
  {
    slug: 'dr-ratio',
    score: 9.15,
    usage: 2.11,
    role: 'dps'
  },
  {
    slug: 'feixiao',
    score: 7.5,
    usage: 42.15,
    role: 'dps'
  },
  {
    slug: 'firefly',
    score: 8.11,
    usage: 62.17,
    role: 'specialist'
  },
  {
    slug: 'fu-xuan',
    score: 8.08,
    usage: 25.45,
    role: 'sustain'
  },
  {
    slug: 'tingyun-fugue',
    score: 8.74,
    usage: 47.79,
    role: 'amplifier',
    new: true
  },
  {
    slug: 'gallagher',
    score: 8.12,
    usage: 52.31,
    role: 'sustain'
  },
  {
    slug: 'gepard',
    score: 8.57,
    usage: 1.42,
    role: 'sustain'
  },
  {
    slug: 'guinaifen',
    score: 9.41,
    usage: 1.35,
    role: 'amplifier'
  },
  {
    slug: 'hanya',
    score: 11,
    usage: 0.14,
    role: 'amplifier'
  },
  {
    slug: 'herta',
    score: 10.51,
    usage: 0.77,
    role: 'dps'
  },
  {
    slug: 'himeko',
    score: 8.57,
    usage: 6.14,
    role: 'dps'
  },
  {
    slug: 'hook',
    score: 11,
    usage: 0.06,
    role: 'dps'
  },
  {
    slug: 'huohuo',
    score: 8.25,
    usage: 17.92,
    role: 'sustain'
  },
  {
    slug: 'imbibitor-lunae',
    score: 9.4,
    usage: 4.26,
    role: 'dps'
  },
  {
    slug: 'jade',
    score: 9.82,
    usage: 2.48,
    role: 'specialist'
  },
  {
    slug: 'jiaoqiu',
    score: 7.98,
    usage: 25.07,
    role: 'amplifier'
  },
  {
    slug: 'jing-yuan',
    score: 9.11,
    usage: 4.7,
    role: 'dps'
  },
  {
    slug: 'jingliu',
    score: 10.26,
    usage: 1.9,
    role: 'dps'
  },
  {
    slug: 'kafka',
    score: 9.07,
    usage: 14.85,
    role: 'specialist'
  },
  {
    slug: 'lingsha',
    score: 7.52,
    usage: 33.29,
    role: 'sustain'
  },
  {
    slug: 'luka',
    score: 11,
    usage: 0.01,
    role: 'specialist'
  },
  {
    slug: 'luocha',
    score: 8.56,
    usage: 8.73,
    role: 'sustain'
  },
  {
    slug: 'lynx',
    score: 8.77,
    usage: 0.46,
    role: 'sustain'
  },
  {
    slug: 'march-7th',
    score: 11,
    usage: 0.09,
    role: 'sustain'
  },
  {
    slug: 'march-7th-swordmaster',
    score: 7.8,
    usage: 17.3,
    role: 'specialist'
  },
  {
    slug: 'misha',
    score: 11,
    usage: 0.02,
    role: 'dps'
  },
  {
    slug: 'moze',
    score: 7.59,
    usage: 4.08,
    role: 'specialist'
  },
  {
    slug: 'natasha',
    score: 11,
    usage: 0.07,
    role: 'sustain'
  },
  {
    slug: 'pela',
    score: 8.36,
    usage: 16.95,
    role: 'amplifier'
  },
  {
    slug: 'qingque',
    score: 10.33,
    usage: 0.42,
    role: 'dps'
  },
  {
    slug: 'rappa',
    score: 8.13,
    usage: 19.32,
    role: 'specialist'
  },
  {
    slug: 'robin',
    score: 7.54,
    usage: 57.99,
    role: 'amplifier'
  },
  {
    slug: 'ruan-mei',
    score: 8.02,
    usage: 87.47,
    role: 'amplifier'
  },
  {
    slug: 'sampo',
    score: 9.99,
    usage: 0.5,
    role: 'specialist'
  },
  {
    slug: 'seele',
    score: 11,
    usage: 0.48,
    role: 'dps'
  },
  {
    slug: 'serval',
    score: 11,
    usage: 0.15,
    role: 'dps'
  },
  {
    slug: 'silver-wolf',
    score: 8.71,
    usage: 8.39,
    role: 'amplifier'
  },
  {
    slug: 'sparkle',
    score: 8.66,
    usage: 22.66,
    role: 'amplifier'
  },
  {
    slug: 'sunday',
    score: 8.87,
    usage: 31.84,
    role: 'amplifier',
    new: true
  },
  {
    slug: 'sushang',
    score: 11,
    usage: 0.03,
    role: 'specialist'
  },
  {
    slug: 'tingyun',
    score: 8.54,
    usage: 5.02,
    role: 'amplifier'
  },
  {
    slug: 'topaz',
    score: 7.38,
    usage: 24.51,
    role: 'specialist'
  },
  {
    slug: 'trailblazer-preservation',
    score: 11,
    usage: 0.02,
    role: 'sustain'
  },
  {
    slug: 'trailblazer-harmony',
    score: 8.08,
    usage: 72.27,
    role: 'amplifier'
  },
  {
    slug: 'trailblazer-destruction',
    score: 11,
    usage: 0.0,
    role: 'dps'
  },
  {
    slug: 'welt',
    score: 10.02,
    usage: 0.48,
    role: 'specialist'
  },
  {
    slug: 'xueyi',
    score: 11,
    usage: 0.11,
    role: 'specialist'
  },
  {
    slug: 'yanqing',
    score: 11,
    usage: 0.02,
    role: 'dps'
  },
  {
    slug: 'yukong',
    score: 11,
    usage: 0.03,
    role: 'amplifier'
  },
  {
    slug: 'yunli',
    score: 8.17,
    usage: 5.52,
    role: 'dps'
  }
];
