import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const HSRGuidesEdiolonPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Eidolon for Trailblazer</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_shadows.png"
            alt="Eidolon for Trailblazer"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Eidolon for Trailblazer</h1>
          <h2>
            This guide will help you find the items needed to upgrade your
            Trailblazer Eidolon ranks.
          </h2>
          <p>
            Last updated: <strong>21/01/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="What are Eidolon?" />
        <p>
          Eidolons enhance Character’s abilities or provide them with new
          passive bonuses. Each Character has 6 Eidolon upgrades with each
          Eidolon upgrade providing a different bonus.
        </p>
        <p>
          While for the majority of characters the only way to get your hands on
          their Eidolon is to obtain their dupes (via Warps), there are a few
          exceptions with the main character - Trailblazer - being one of them.
          In case of the MC, instead of pulling his/her dupes you need to obtain
          some special items:
        </p>
        <ul>
          <li>
            <strong>Shadow of Destruction</strong> - for Physical Trailblazer
          </li>
          <li>
            <strong>Shadow of Preservation</strong> - for Fire Trailblazer
          </li>
          <li>
            <strong>Shadow of Harmony</strong> - for Imaginary Trailblazer
          </li>
          <li>
            <strong>Shadow of Remembrance</strong> for Ice Trailblazer
          </li>
        </ul>
        <SectionHeader title="Shadow of Destruction" />
        <p>
          Here's a list of places where you can find all 6 Shadows of
          Destruction for your Physical Trailblazer:
        </p>
        <ol>
          <li>Herta Space Station - Prologue reward,</li>
          <li>Jarilo-VI - Main Story reward,</li>
          <li>Trailblazer Rank 19,</li>
          <li>Trailblazer Rank 30,</li>
          <li>Internal Purchase Officer on Herta Space Station,</li>
          <li>Underground Shop on Jarilo-VI.</li>
        </ol>
        <p>
          Overall, since all of those items are available quite early in the
          game, you will be able to max your Physical MC Eidolon within the
          first few days of playing.
        </p>
        <SectionHeader title="Shadow of Preservation" />
        <p>
          Here's a list of places where you can find all 6 Shadows of
          Destruction for your Fire Trailblazer:
        </p>
        <ol>
          <li>
            Jeweler's Pagoda on The Xianzhou Luofu (you can get 4 Eidolons from
            there).
          </li>
          <li>
            Main Story on The Xianzhou Luofu (you can get 2 Eidolons from
            there).
          </li>
        </ol>
        <p>Below's video shows the location of the shop:</p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="TYiMn8iDPZM" />
          </Col>
        </Row>
        <SectionHeader title="Shadow of Harmony" />
        <p>
          Here's a list of places where you can find all 6 Shadows of
          Remembrance for your Imaginary Trailblazer:
        </p>
        <ol>
          <li>Penacony - Main Story reward</li>
          <li>
            Clockie Statue in Penacony (You can obtain 4 Eidolons by upgrading
            the statue. You get 1 Eidolon at Level 40 and Level 45. The
            remaining 2 are obtained at Level 50.)
          </li>
          <li>
            Event - When Charmony Rings Out (This event is permanent and unlocks
            the Imaginary Trailblazer for players who have not yet reached
            Penacony. If you have already unlocked the Imaginary Trailblazer,
            then this event will give you 1 Eidolon instead. For players who
            unlock the Imaginary Trailblazer via this event, the final Eidolon
            can instead be obtained during the Penacony main story)
          </li>
        </ol>
        <SectionHeader title="Shadow of Remembrance" />
        <p>
          Here's a list of places where you can find all 6 Shadows of
          Remembrance for your Ice Trailblazer:
        </p>
        <ol>
          <li>
            Amphoreus - Main Story reward (You can obtain 2 Eidolons from the
            main story)
          </li>
          <li>
            Tidal Bounty (You can obtain 2 Eidolons through increasing your
            Offering Level in the Tidal Bounty. You get 1 Eidolon at Offering
            Level 6 and another at Offering Level 10.)
          </li>
          <li>
            As I’ve Written feature (You can obtain 2 Eidolons by completing
            sagas in the As I’ve Written feature. You get 1 Eidolon after
            completing 2 sagas and another after completing 6 sagas.)
          </li>
        </ol>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesEdiolonPage;

export const Head: React.FC = () => (
  <Seo
    title="Eidolon for Trailblazer | Honkai: Star Rail | Prydwen Institute"
    description="This guide will help you find the items needed to upgrade your Trailblazer Eidolon ranks."
  />
);
