import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { IAKECharacter } from '../modules/common/model/graphql-types';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import './employee.scss';
import './ake-character-dynamic.scss';
import { Col, Row } from 'react-bootstrap';
import { Seo } from '../modules/common/components/seo';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faCircleInfo,
  faDiagramProject,
  faSquare
} from '@fortawesome/free-solid-svg-icons';
import { EndfieldSkill } from '../modules/endfield/components/endfield-skill';
import { EndfieldBasic } from '../modules/endfield/components/endfield-basic';
import { EndfieldTalent } from '../modules/endfield/components/endfield-talent';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

interface IAKECharacterNodes {
  nodes: IAKECharacter[];
}

interface IAKECharacterEntry {
  currentUnit: IAKECharacterNodes;
}

interface IProps {
  data: IAKECharacterEntry;
}

const AKECharacterDetails: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];
  const [currentTab, setCurrentTab] = useState('Info');

  const profileImage = character.cardImage
    ? getImage(character.cardImage.localFile.childImageSharp)
    : null;

  const fullImage = character.fullImage
    ? getImage(character.fullImage.localFile.childImageSharp)
    : null;

  return (
    <DashboardLayout
      className={'generic-page character-page character-ake'}
      game="endfield"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/arknights-endfield/">Arknights: Endfield</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/arknights-endfield/characters">Characters</Link>
        </li>
        <li className="divider">/</li>
        <li>{character.name}</li>
      </ul>
      <>
        <div className="character-top">
          <div className="left-info">
            <h1>
              <span className="small"></span>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>
              <span className="sub">Build and Guide</span>
            </h1>
          </div>
          <div className="right-image">
            <div
              className={`character-background rarity-${character.rarity} ${character.slug}`}
            ></div>
            <GatsbyImage image={profileImage} alt="Character" />
          </div>
        </div>
        <Row className="intro-section">
          <Col xs={12} xl={12}>
            <div className={`content-header rarity-${character.rarity}`}>
              <FontAwesomeIcon icon={faSquare} width="18" /> Introduction
            </div>
            <div className="character-intro">
              <div className="combined">
                <h2>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  is a{' '}
                  <strong className={`rarity-${character.rarity}`}>
                    {character.rarity}✦
                  </strong>{' '}
                  character from the{' '}
                  {character.class === 'Caster' && (
                    <StaticImage
                      src="../images/endfield/icons/class_caster.webp"
                      alt="Caster"
                      className="faction-icon"
                    />
                  )}
                  {character.class === 'Defender' && (
                    <StaticImage
                      src="../images/endfield/icons/class_defender.webp"
                      alt="Defender"
                      className="faction-icon"
                    />
                  )}
                  {character.class === 'Guard' && (
                    <StaticImage
                      src="../images/endfield/icons/class_guard.webp"
                      alt="Guard"
                      className="faction-icon"
                    />
                  )}
                  {character.class === 'Specialist' && (
                    <StaticImage
                      src="../images/endfield/icons/class_specialist.webp"
                      alt="Specialist"
                      className="faction-icon"
                    />
                  )}
                  {character.class === 'Support' && (
                    <StaticImage
                      src="../images/endfield/icons/class_support.webp"
                      alt="Support"
                      className="faction-icon"
                    />
                  )}{' '}
                  <strong>{character.class}</strong> class who wields{' '}
                  {character.weapon === 'Guns' ? '' : 'a'}{' '}
                  {character.weapon === 'Great Sword' && (
                    <StaticImage
                      src="../images/endfield/icons/weapon_greatsword.webp"
                      alt="Great Sword"
                      className="faction-icon"
                    />
                  )}
                  {character.weapon === 'Sword' && (
                    <StaticImage
                      src="../images/endfield/icons/weapon_sword.webp"
                      alt="Sword"
                      className="faction-icon"
                    />
                  )}
                  {character.weapon === 'Guns' && (
                    <StaticImage
                      src="../images/endfield/icons/weapon_guns.webp"
                      alt="Guns"
                      className="faction-icon"
                    />
                  )}
                  {character.weapon === 'Orbiter' && (
                    <StaticImage
                      src="../images/endfield/icons/weapon_orbiter.webp"
                      alt="Orbiter"
                      className="faction-icon"
                    />
                  )}
                  {character.weapon === 'Polearm' && (
                    <StaticImage
                      src="../images/endfield/icons/weapon_polearm.webp"
                      alt="Polearm"
                      className="faction-icon"
                    />
                  )}{' '}
                  <strong>{character.weapon}</strong> and who has access to the
                  {character.element === 'Physical' && (
                    <StaticImage
                      src="../images/endfield/icons/element_physical.webp"
                      alt="Physical"
                      className="faction-icon"
                    />
                  )}
                  {character.element === 'Heat' && (
                    <StaticImage
                      src="../images/endfield/icons/element_heat.webp"
                      alt="Heat"
                      className="faction-icon"
                    />
                  )}
                  {character.element === 'Nature' && (
                    <StaticImage
                      src="../images/endfield/icons/element_nature.webp"
                      alt="Nature"
                      className="faction-icon"
                    />
                  )}
                  {character.element === 'Electric' && (
                    <StaticImage
                      src="../images/endfield/icons/element_electric.webp"
                      alt="Electric"
                      className="faction-icon"
                    />
                  )}
                  {character.element === 'Cryo' && (
                    <StaticImage
                      src="../images/endfield/icons/element_cryo.webp"
                      alt="Cryo"
                      className="faction-icon"
                    />
                  )}{' '}
                  <strong className={`${character.element}`}>
                    {character.element}
                  </strong>{' '}
                  element.
                </h2>
                <div className="char-intro">
                  {character.intro ? (
                    <>{renderRichText(character.intro, options)}</>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <p className="hide-on-mobile">
                To learn more about{' '}
                <strong className={`rarity-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                check the sections below. <strong>Use the tabs</strong> to
                quickly switch to the kind of information you're looking for.
              </p>
            </div>
          </Col>
        </Row>
        <div
          className="fuse-ad-placeholder bigger"
          data-fuse="22844297232"
        ></div>
        <p className="show-on-mobile">
          To learn more about{' '}
          <strong className={`rarity-${character.rarity}`}>
            {character.name}
          </strong>{' '}
          check the sections below. <strong>Use the tabs</strong> to quickly
          switch to the kind of information you're looking for.
        </p>
        <div className="tabs">
          <div
            className={`single-tab rarity-${character.rarity} ${
              currentTab === 'Info' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Info')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
            </div>
            <p>Profile</p>
          </div>
          <div
            className={`single-tab rarity-${character.rarity} ${
              currentTab === 'Review' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Review')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faBook} width="18" />
            </div>
            <p>Review</p>
          </div>
          <div
            className={`single-tab rarity-${character.rarity} ${
              currentTab === 'Build' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Build')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faDiagramProject} width="18" />
            </div>
            <p>Build and teams</p>
          </div>
        </div>
        <div className={`tab-inside ${currentTab === 'Info' ? 'active' : ''}`}>
          <div className={`mobile-header rarity-${character.rarity}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Profile
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Attributes (level 80)
          </div>
          <p>
            The <strong className="yellow">yellow</strong> color shows the{' '}
            <strong>Primary Attribute</strong> and the{' '}
            <strong className="green">green</strong>{' '}
            <strong>Secondary Attribute</strong>. Both of those attributes, on
            top of their normal bonuses, provide additional ATK to the
            character.
          </p>
          {character.attributes ? (
            <>
              <Row xs={1} xxl={4} className="va-section attributes">
                <Col
                  className={`single-attribute ${
                    character.mainAttribute === 'Strength' ? 'main' : ''
                  } ${
                    character.secondaryAttribute === 'Strength'
                      ? 'secondary'
                      : ''
                  }`}
                >
                  <div className="info-list-row">
                    <div className={`category`}>
                      <StaticImage
                        src="../images/endfield/icons/stat_strength.webp"
                        alt="Strength"
                        className="faction-icon"
                      />{' '}
                      Strength
                    </div>
                    <div className="details">
                      {character.attributes.str
                        ? Math.floor(character.attributes.str)
                        : '-'}
                    </div>
                  </div>
                </Col>
                <Col
                  className={`single-attribute ${
                    character.mainAttribute === 'Agility' ? 'main' : ''
                  } ${
                    character.secondaryAttribute === 'Agility'
                      ? 'secondary'
                      : ''
                  }`}
                >
                  <div className="info-list-row">
                    <div className={`category`}>
                      <StaticImage
                        src="../images/endfield/icons/stat_agility.webp"
                        alt="Agility"
                        className="faction-icon"
                      />{' '}
                      Agility
                    </div>
                    <div className="details">
                      {character.attributes.agi
                        ? Math.floor(character.attributes.agi)
                        : '-'}
                    </div>
                  </div>
                </Col>
                <Col
                  className={`single-attribute ${
                    character.mainAttribute === 'Intellect' ? 'main' : ''
                  } ${
                    character.secondaryAttribute === 'Intellect'
                      ? 'secondary'
                      : ''
                  }`}
                >
                  <div className="info-list-row">
                    <div className={`category`}>
                      <StaticImage
                        src="../images/endfield/icons/stat_intellect.webp"
                        alt="Intellect"
                        className="faction-icon"
                      />{' '}
                      Intellect
                    </div>
                    <div className="details">
                      {character.attributes.int
                        ? Math.floor(character.attributes.int)
                        : '-'}
                    </div>
                  </div>
                </Col>
                <Col
                  className={`single-attribute ${
                    character.mainAttribute === 'Will' ? 'main' : ''
                  } ${
                    character.secondaryAttribute === 'Will' ? 'secondary' : ''
                  }`}
                >
                  <div className="info-list-row">
                    <div className={`category`}>
                      <StaticImage
                        src="../images/endfield/icons/stat_will.webp"
                        alt="Will"
                        className="faction-icon"
                      />{' '}
                      Will
                    </div>
                    <div className="details">
                      {character.attributes.will
                        ? Math.floor(character.attributes.will)
                        : '-'}
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>attributes aren't available yet.</strong> They will be
                  added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Skills
          </div>
          {character.slug != 'test' ? (
            <>
              <div className="skills-v2">
                <div className={`tab-inside active`}>
                  <Row xs={1} xxl={2}>
                    <Col>
                      <div className="box">
                        <EndfieldBasic
                          id={character.skillKey + '_Basic'}
                          character_rarity={character.rarity}
                          type="Basic Attack"
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="box">
                        <EndfieldSkill
                          id={character.skillKey + '_Skill'}
                          character_rarity={character.rarity}
                          type="Battle Skill"
                        />
                      </div>
                      <div className="box">
                        <EndfieldSkill
                          id={character.skillKey + '_Combo'}
                          character_rarity={character.rarity}
                          type="Combo Skill"
                        />
                      </div>
                      <div className="box">
                        <EndfieldSkill
                          id={character.skillKey + '_Ultimate'}
                          character_rarity={character.rarity}
                          type="Ultimate"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>skills aren't available yet.</strong> They will be
                  added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Talents
          </div>
          {character.slug != 'test' ? (
            <>
              <div className="skills-v2">
                <div className={`tab-inside active`}>
                  <Row xs={1} xxl={2}>
                    <Col>
                      <div className="box">
                        <EndfieldTalent
                          id={character.skillKey + '_Combat_Talent_1'}
                          character_rarity={character.rarity}
                          type="Combat Talent"
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="box">
                        <EndfieldTalent
                          id={character.skillKey + '_Combat_Talent_2'}
                          character_rarity={character.rarity}
                          type="Combat Talent"
                        />
                      </div>
                    </Col>
                    {character.slug != 'endministrator' && (
                      <>
                        <Col>
                          <div className="box">
                            <EndfieldTalent
                              id={character.skillKey + '_Base_Talent_1'}
                              character_rarity={character.rarity}
                              type="Base Talent"
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="box">
                            <EndfieldTalent
                              id={character.skillKey + '_Base_Talent_2'}
                              character_rarity={character.rarity}
                              type="Base Talent"
                            />
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>talents aren't available yet.</strong> They will be
                  added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header rarity-${character.rarity} `}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Potential (dupes)
          </div>
          {character.potential ? (
            <div className="talents eidolons">
              <Row xs={1} xxl={2}>
                {character.potential.map((talent, index) => {
                  return (
                    <Col key={index}>
                      <div className="box">
                        <div className="skill-header">
                          <div
                            className={`skill-icon rarity-${character.rarity}`}
                          >
                            {index === 0 && 'P1'}
                            {index === 1 && 'P2'}
                            {index === 2 && 'P3'}
                            {index === 3 && 'P4'}
                            {index === 4 && 'P5'}
                          </div>
                          <div className="skill-info">
                            <p className="skill-name">
                              {talent.name != '' && talent.name != '??' ? (
                                talent.name
                              ) : (
                                <>Name Unknown</>
                              )}
                            </p>
                            <p className="skill-type">
                              Potential {index === 0 && '1'}
                              {index === 1 && '2'}
                              {index === 2 && '3'}
                              {index === 3 && '4'}
                              {index === 4 && '5'}
                            </p>
                          </div>
                        </div>
                        {talent.desc != '' ? (
                          <>
                            <div
                              className={`skill-with-coloring ${character.element}`}
                              dangerouslySetInnerHTML={{
                                __html: talent.desc
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <div
                              className={`skill-with-coloring ${character.element}`}
                            >
                              -
                            </div>
                          </>
                        )}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                <strong>Potential information aren't available yet.</strong>{' '}
                They will be added soon!
              </p>
            </div>
          )}
          <div className={`content-header rarity-${character.rarity} `}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Signature Weapon
          </div>
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>
                Signature Weapon information aren't available yet.
              </strong>{' '}
              They will be added soon!
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Voice Actors
          </div>
          <Row xs={1} xxl={4} className="va-section">
            <Col>
              <div className="info-list-row">
                <div className="category">ENG</div>
                <div className="details">
                  {character.va.en ? character.va.en : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">JPN</div>
                <div className="details">
                  {character.va.jpn ? character.va.jpn : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">CN</div>
                <div className="details">
                  {character.va.cn ? character.va.cn : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">KR</div>
                <div className="details">
                  {character.va.kr ? character.va.kr : '-'}
                </div>
              </div>
            </Col>
          </Row>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Gallery
          </div>
          {fullImage ? (
            <GatsbyImage image={fullImage} alt="" className="full-image" />
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                <strong>images aren't available yet.</strong> They will be added
                when the character is released.
              </p>
            </div>
          )}
        </div>
        <div
          className={`tab-inside ${currentTab === 'Review' ? 'active' : ''}`}
        >
          <div className={`mobile-header rarity-${character.rarity}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Review
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Review
          </div>
          <div className="info-box with-margin">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>review isn't available yet.</strong> It will be added
              soon!
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Pros & Cons
          </div>
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>pros & cons aren't available yet.</strong> They will be
              added when the character is released.
            </p>
          </div>
          <div className={`content-header  rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Ratings
          </div>
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>ratings aren't available yet.</strong> It will be added
              soon!
            </p>
          </div>
        </div>
        <div className={`tab-inside ${currentTab === 'Build' ? 'active' : ''}`}>
          <div className={`mobile-header rarity-${character.rarity}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Build and teams
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Best Weapons
          </div>
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>best Weapons aren't available yet.</strong> They will be
              added soon.
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Best Synergies
          </div>
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>best Synergies aren't available yet.</strong> They will be
              added soon.
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Best Teams
          </div>
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>best Teams aren't available yet.</strong> They will be
              added soon.
            </p>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      </>
    </DashboardLayout>
  );
};

export default AKECharacterDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <Seo
      title={
        character.name + ' Guide and Build | Arknights: Endfield | Prydwen'
      }
      description={
        'Discover the best builds, teams and detailed guides for ' +
        character.name +
        ' in Arknights: Endfield.'
      }
      image={character.cardImage}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulAkeCharacter(
      filter: { id: { eq: $contentfulId } }
    ) {
      nodes {
        ...AkeCharacterFieldsFragment
      }
    }
  }
`;
