/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { Row, Col, Tab, Tabs } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';

const AFKGuidesThas: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Dream Realm - Thalassa</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_thal.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Dream Realm - Thalassa</h1>
          <h2>
            Dream Realm Thalassa guide that explains the boss mechanics and how
            to beat it!
          </h2>
          <p>
            Last updated: <strong>15/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Thalassa" />
        <p>
          <strong>Thalassa</strong> is one of the 8 available Dream Realm bosses
          you will encounter in AFK Journey in Season 3. In this guide we will
          go over the boss mechanics and the best characters and teams to use
          against him.
        </p>
        <SectionHeader title="What’s the meta team?" />
        <p>
          Realistically speaking,{' '}
          <strong>
            your meta team will depend on your specific box, and your account
            focus
          </strong>
          , so use your highest rarity DPS carry characters, along with whoever
          can best boost their damage.{' '}
          <strong className="gold">
            Try running Dual carry, as currently there is no way to boost a
            Hypercarry’s damage high enough to make it worth running only one
          </strong>
          . Keep faction bonus in mind but don’t run suboptimal characters just
          to force it.
        </p>
        <p>
          Still, below you can find a few example teams. Big thanks to{' '}
          <StaticImage
            src="../../../images/afk/icons/afkanalytica.png"
            alt="AFK Analytica"
          />{' '}
          <strong className="yellow">AFK Analytica</strong> for the team
          building tool. You can find their website{' '}
          <a
            href="https://www.afkanalytica.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and their discord{' '}
          <a
            href="https://www.discord.gg/analytica"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <SectionHeader title="Season 3 Teams" />
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-season"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={<>Team #1 - Best Team</>}
            className="with-bg"
          >
            <h5>Team #1 - Best Team (Talene + Reinier)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/thala_1.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>叫我波妮大人</strong>.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="talene" enablePopover /> acts
                as the main carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover /> acts as
                secondary carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer (<strong>Mythic+</strong> required).
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                acts as the healer and buffer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="koko" enablePopover /> acts as
                buffer.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Overcharge Spell" /> - Used for Energy Regen.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team2"
            title={<>Team #2 - Alt Team</>}
            className="with-bg"
          >
            <h5>Team #2 - Alt Team (Reinier)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/thala_2.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Zeeebo </strong> from Youtube.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover /> acts as
                main damage carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover /> as
                secondary damage carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer (<strong>Mythic+</strong> required).
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                acts as the healer and buffer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="koko" enablePopover /> acts as
                buffer.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Starshard Spell" /> - Best Early Season
                  Artifact.
                </li>
              </ul>
            </ul>
          </Tab>
        </Tabs>
        <SectionHeader title="Video" />
        <p>Coming Soon!</p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesThas;

export const Head: React.FC = () => (
  <Seo
    title="Dream Realm - Thalassa | AFK Journey | Prydwen Institute"
    description="Dream Realm Thalassa guide that explains the boss mechanics and how to beat it!"
    game="afk"
  />
);
