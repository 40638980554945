/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import lodash from 'lodash';

import './index.scss';
import '../generic-page.scss';
import './tier-list.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, Link } from 'gatsby';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion } from 'react-bootstrap';
import { IAKECharacter } from '../../modules/common/model/graphql-types';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';

interface IAKECharacterNodes {
  nodes: IAKECharacter[];
}

interface IAKECharacterEntry {
  allCharacters: IAKECharacterNodes;
}

interface IProps {
  data: IAKECharacterEntry;
}

const EndfieldTierPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '4✦', value: '4' },
          { label: '5✦', value: '5' },
          { label: '6✦', value: '6' }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Caster',
            tooltip: 'Caster',
            image: (
              <StaticImage
                src="../../images/endfield/icons/class_caster.webp"
                width={24}
                alt="Caster"
              />
            )
          },
          {
            value: 'Defender',
            tooltip: 'Defender',
            image: (
              <StaticImage
                src="../../images/endfield/icons/class_defender.webp"
                width={24}
                alt="Defender"
              />
            )
          },
          {
            value: 'Guard',
            tooltip: 'Guard',
            image: (
              <StaticImage
                src="../../images/endfield/icons/class_guard.webp"
                width={24}
                alt="Guard"
              />
            )
          },
          {
            value: 'Specialist',
            tooltip: 'Specialist',
            image: (
              <StaticImage
                src="../../images/endfield/icons/class_specialist.webp"
                width={24}
                alt="Specialist"
              />
            )
          },
          {
            value: 'Support',
            tooltip: 'Support',
            image: (
              <StaticImage
                src="../../images/endfield/icons/class_support.webp"
                width={24}
                alt="Support"
              />
            )
          }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Physical',
            tooltip: 'Physical',
            image: (
              <StaticImage
                src="../../images/endfield/icons/element_physical.webp"
                width={24}
                alt="Physical"
              />
            )
          },
          {
            value: 'Cryo',
            tooltip: 'Cryo',
            image: (
              <StaticImage
                src="../../images/endfield/icons/element_cryo.webp"
                width={24}
                alt="Cryo"
              />
            )
          },
          {
            value: 'Electric',
            tooltip: 'Electric',
            image: (
              <StaticImage
                src="../../images/endfield/icons/element_electric.webp"
                width={24}
                alt="Electric"
              />
            )
          },
          {
            value: 'Heat',
            tooltip: 'Heat',
            image: (
              <StaticImage
                src="../../images/endfield/icons/element_heat.webp"
                width={24}
                alt="Heat"
              />
            )
          },
          {
            value: 'Nature',
            tooltip: 'Nature',
            image: (
              <StaticImage
                src="../../images/endfield/icons/element_nature.webp"
                width={24}
                alt="Nature"
              />
            )
          }
        ]
      },
      {
        key: 'weapon',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Great Sword',
            tooltip: 'Great Sword',
            image: (
              <StaticImage
                src="../../images/endfield/icons/weapon_greatsword.webp"
                width={24}
                alt="Great Sword"
              />
            )
          },
          {
            value: 'Guns',
            tooltip: 'Guns',
            image: (
              <StaticImage
                src="../../images/endfield/icons/weapon_guns.webp"
                width={24}
                alt="Great Sword"
              />
            )
          },
          {
            value: 'Orbiters',
            tooltip: 'Orbiters',
            image: (
              <StaticImage
                src="../../images/endfield/icons/weapon_orbiter.webp"
                width={24}
                alt="Orbiters"
              />
            )
          },
          {
            value: 'Polearm',
            tooltip: 'Polearm',
            image: (
              <StaticImage
                src="../../images/endfield/icons/weapon_polearm.webp"
                width={24}
                alt="Polearm"
              />
            )
          },
          {
            value: 'Sword',
            tooltip: 'Sword',
            image: (
              <StaticImage
                src="../../images/endfield/icons/weapon_sword.webp"
                width={24}
                alt="Sword"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.element.toLowerCase() === activeFilters.element.toLowerCase()
        );
      }

      if (activeFilters.weapon && activeFilters.weapon !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.weapon.toLowerCase() === activeFilters.weapon.toLowerCase()
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }
  return (
    <DashboardLayout
      className={'generic-page endfield ake-tier'}
      game="endfield"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/arknights-endfield/">Arknights: Endfield</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/endfield/categories/category_tier.jpg"
            alt="Tier List"
          />
        </div>
        <div className="page-details">
          <h1>Arknights: Endfield Tier List</h1>
          <h2>
            Prydwen's tier list for Arknights: Endfield that rates all currently
            available characters in the game.
          </h2>
          <p>
            Last updated: <strong>18/01/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Tier List" />
      <Accordion defaultActiveKey="0" className="tier-list-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>About the Tier List</Accordion.Header>
          <Accordion.Body>
            <p>
              Our{' '}
              <strong>Arknights: Endfield is currently being created</strong> as
              we're testing all characters available in the current CBT, trying
              to find the ones that stand out. Stay tuned for future updates!
            </p>
            <p>
              If you want to help us by providing information and your
              experiences, join our Discord and visit the AKE section!
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Criteria</Accordion.Header>
          <Accordion.Body>
            <h6>Criteria</h6>
            <p>Coming Soon!</p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="employees-filter-bar ake">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className="custom-tier-list-ake">
        {/* <div className="custom-tier-header">
          <div className="tier-rating">
            <span>&nbsp;</span>
          </div>
          <div className="custom-tier-container">
            <div className="custom-header dps">Damage dealer</div>
            <div className="custom-header specialist">Specialist</div>
            <div className="custom-header buffer">Buffer/Debuffer</div>
            <div className="custom-header survival">Survival</div>
          </div>
        </div> */}
        <div className="custom-tier tier-s-plus first">
          <div className="tier-rating s-plus">
            <span>T0</span>
          </div>
          <div className="custom-tier-container"></div>
        </div>
        <div className="custom-tier tier-s">
          <div className="tier-rating s">
            <span>T0.5</span>
          </div>
          <div className="custom-tier-container"></div>
        </div>
        <div className="custom-tier tier-a">
          <div className="tier-rating a">
            <span>T1</span>
          </div>
          <div className="custom-tier-container"></div>
        </div>
        <div className="custom-tier tier-b">
          <div className="tier-rating b">
            <span>T2</span>
          </div>
          <div className="custom-tier-container"></div>
        </div>
        <div className="custom-tier tier-c">
          <div className="tier-rating c">
            <span>T3</span>
          </div>
          <div className="custom-tier-container"></div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default EndfieldTierPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier List | Arknights: Endfield | Prydwen Institute"
    description="Character are obtainable units in Arknights: Endfield - find every character available in the game below!"
    game="endfield"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulAkeCharacter(
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        rarity
        class
        element
        weapon
      }
    }
  }
`;
