import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const NikkeGuidesAliceBunny: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Alice: Wonderland Bunny guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_alicebunny.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Alice: Wonderland Bunny guide & review</h1>
          <h2>
            A guide & review for Alice: Wonderland Bunny in NIKKE: Goddess of
            Victory.
          </h2>
          <p>
            Last updated: <strong>08/06/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Alice: Wonderland Bunny" />
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon"
                  slug="alice-wonderland-bunny"
                  enablePopover
                />
              </div>
              <p>
                A tribute to all Alice stans, the world's probably most idolized
                princess, Alice decided to shake up the meta once again not as a
                DPS this time but as a support long sought by well-known
                pathfinders that have endured the winding and unpredictable
                rocky terrains. One could envisage that she would be the final
                piece to the fragmented SG team, but to what extent is this
                true?
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Rather below expectation in one aspect and impressive others.
                While she does significantly improve the reliability of SG
                teams, something feels unfulfilling. The exodus is close to
                completion but is not there yet; that's our foresight.
              </p>
              <p>
                Does this mean is mediocre? Not at all. She alleviates 2 out of
                the 3 woes plaguing SG teams: the uptime/consistency of Tove's
                Attack Speed buff and the lack of survivability. However, she
                does little to stabilize Tove's S1, aka the stacks. If you are
                unlucky, you may need to wait a thousand centuries between Full
                Bursts or, worse, spend all your savings just to get her. But,
                overall? The improvement is already paramount even with this
                setback, so it doesn't in any way make her unusable.
              </p>
              <p>
                Although her performance as an SG optimizer is slightly off
                perfection, she as a healer is still superior to many other
                options. Her healing is higher than Rapunzel's on paper. You can
                even use her Burst Skill as a panic button for a massive instant
                teamwide heal, followed by a long period of HP potency boost.
                She would easily fit in many teams, especially Crown's, as she
                needs <i>frequent</i> external healing to regularly activate her
                S2 offensive buff.
              </p>
              <h5>Should I pull this unit?</h5>
              <p>
                Just for her versatility alone, disregarding her potential in
                the SG team, she is worth pulling. We would not say she is a
                must, unless you are planning to build a full-fledged SG team,
                in which case you should get at least one copy. There is no
                reason to MLB, and she is fairly cheap to build without any
                destructive breakpoints gatekeeping you. No matter your choice,
                this cute Rabbity is ready to bestow everlasting joy!
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <p>
                Alice has a superficial kit that synergizes well with multiple
                compositions. Plain character with no jutting characteristics,
                her review will be short, so hang tight!
              </p>
              <h5>Pretty Petals Mask a Thorny Stem</h5>
              <StaticImage
                src="../../../images/nikke/reviews/bunnyalice_comp.webp"
                alt="Kit"
              />
              <p>
                Let's first talk about B. Alice's main intended purpose: fixing
                the SG team. So, we know at a glance that B. Alice refreshes the
                stacks of Water Code allies every 90 shots (they must have
                restricted the element to avoid overbuffing the SG team with
                Tove's permanent Burst Skill). Without Cube, these 90 shots take
                about 4.5s on average, and factoring reload in, 5.52s. That's a
                fairly healthy number considering Tove's stacks last 5 seconds
                each, so they together would make a great duo, right? Well, yes,
                but there is just this one complication we overlooked.
              </p>
              <p>
                The stacks... they are only maintained on Tove (and Leona and B.
                Alice herself). This means B. Soda and PrivM's stacks (or any
                other non-Water characters) would still have the same old
                annoying RNG where you have to pray day and night and spend 30
                hours resetting your Solo Raid runs. Just kidding; that's
                obviously exaggerated. While Tove would have 3 stacks "all the
                time" (and thus provide maximum Attack Speed uptime since that
                one relies on her stacks rather than each unit's stacks), you
                still have to RNG the 3 stacks on your DPS just before you burst
                to get the most ouf Tove's Burst Skill.
              </p>
              <Alert variant="primary">
                <p>
                  We guess ShiftUp is doing this to force us to invest in Tove
                  and Bunny Alice's OL. Getting Max Ammo for Tove improves the
                  RNG for each unit's stacks, whereas getting Max Ammo for Bunny
                  Alice improves the RNG for Tove and other Water units' stacks
                  (for Attack Speed uptime primarily).
                </p>
              </Alert>
              <h5>Carrots in Store, Famine No More</h5>
              <StaticImage
                src="../../../images/nikke/reviews/bunnyalice_healing.webp"
                alt="Kit"
              />
              <p>
                One of Bunny Alice's specialties is healing, a proficient one at
                that. According to our calculation, she easily surpasses other
                healers when it comes to raw regenerative power. She heals
                others in 3-second intervals and can press a literal Panic
                Button (with a 40s cooldown) that immediately recovers a large
                portion of HP to the team as well as boosts received healing for
                some time after that.
              </p>
              <p>
                Her constant healing also makes her a perfect pair to Crown
                because she can continually and reliably trigger her S2
                offensive buff without having to worry about positioning and/or
                who has the lowest HP.
              </p>
              <p>
                Last but not least, the best thing about her is that she does
                not need to use Burst Skill to heal, and her Panic Button has
                the Re-Enter hallmark. Unlike other greedy healers where using
                their Burst Skill(s) would prevent you from activating your
                damage-boosting B1's (like Liter's), Bunny Alice frees you from
                this harmful obligation.
              </p>
              <Alert variant="primary">
                <p>
                  The HP Potency kicks in AFTER the burst heal. It only affects
                  her basic attacks (and healing provided by other characters).
                </p>
              </Alert>
              <p>
                Another thing I would like to mention is that B. Alice might be
                adept at healing the team as a whole from "distributed" damage.
                Healing a single unit who gets harassed continuously is another
                question, however. Beware of slot pressure too! Bringing a 3rd
                DPS might be more favorable! Who knows if you never test?
                Experiment with different combinations to see which one would
                push you the farthest in Raids!
              </p>
              <h5>What Vehicle Does a Bunny Ride? CARrot.</h5>
              <StaticImage
                src="../../../images/nikke/reviews/bunnyalice_noir.webp"
                alt="Kit"
              />
              <p>
                Ok, a terrible pun that we wanted to remove but the author
                wanted us to leave it in place. Anyways, the best vehicle for
                your team is indeed B. Alice's CARrots. The increased Max Ammo
                and Reload on her S2 function similarly to Noir's and is an
                updated, improved version. While the maximum ammo produced is
                inferior to Noir's integer-based buff for low-ammo weapons, it
                is much stronger for everything else, like MGs. It also lasts 5
                seconds longer, not to mention she also provides bonus burst gen
                after Full Burst in addition. It will definitely <i>drive</i>{' '}
                your ammo-dependent characters to madness.
              </p>
              <p>
                The irritating thing about it? It scales rather sharply with
                skill investments, which means it may not be too powerful
                uninvested. This does not mean we recommend leveling it to 10,
                though. Please read the Skill Investment section below for
                further detail.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Tier List" />
              <h5>
                Campaign (LD) - <strong className="ss">SS</strong> | Campaign
                (HD) - <strong className="ss">SS</strong>
              </h5>
              <p>
                You should be able to understand by now that B. Alice works as a
                dual role: Healer + Ammo Support. This helps her settle in quite
                easily when it comes to Campaign-type missions, especially in
                Low Deficit campaign where healing can help survive a ton of
                attacks. It only strengthens B. Alice's spot that she can
                synergize with Crown by activating her Skill 2's attack damage
                with a 100% uptime. She also acts as a magazine size as well as
                ammo refill buffer like Noir, which can help boost overall
                team's damage depending on the DPS. Units like Alice can
                definitely make use of this Ammo buff to full effect, as well as
                MGs with low Ammo on OL gear like Modernia or Scarlet, who want
                to avoid reloading. Oh, and one more, her Burst heal doesn't
                cost you the opportunity to use other B1s' skills!
              </p>
              <h5>
                Boss (Solo) - <strong className="ss">SS</strong> | Boss (Adds) -{' '}
                <strong className="s">S</strong>
              </h5>
              <p>
                B. Alice is versatile, has a lot of uses, and can fit in many
                types of teams. However, she will stand out the brightest in SG
                teams, with Tove mainly. Her interaction with Tove and B. Alice
                like we mentioned previously is the reason for this. Let's also
                not forget to mention her excellent synergy with Crown and units
                who are dependent on uptime (ammo).
              </p>
              <h5>
                PVP - <strong className="c">C</strong>
              </h5>
              <p>
                B. Alice provides little to no value for PvP, wielding an SMG
                (Low Burst Generation) and suffering from the lower FPS cap of
                30, making her heal every 4 seconds instead of 3. This weakens
                her healing capability. Moreover, her massive HP Potency buff is
                locked behind stacks that are impossible to fully accumulate
                before Full Burst (without sabotaging your team's speed). Hence,
                B. Alice is not suited to PvP.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Overload
                  Guide Announcement
                </h6>
                <p>
                  We have been working on an extensive Overload Guide, which
                  should be available in a few weeks if not days. We will be
                  using new terms in our OL rerolling guide and have therefore
                  included their definitions here.
                </p>
                <ul>
                  <li>
                    <strong>Essential</strong>: With all gear combined, your
                    unit must have the roll(s) prescribed as minimum. Example:
                    Charge Speed on Alice.
                  </li>
                  <li>
                    <strong>Ideal</strong>: These rolls will improve your unit's
                    performance significantly. Aim for 2 lines of
                    Essential/Ideal per gear for maximum performance and the
                    best Return on Investment (ROI). If you get 3, you are
                    lucky. You can choose to only have 1 as well to save
                    materials. Example: Elemental Damage on Scarlet.
                  </li>
                  <li>
                    <strong>Passable</strong>: Low-priority line that has
                    limited value or the impact is not as strong as other rolls.
                    Should this show up as a bonus together with a better line,
                    you can choose to keep them. Example: Charge Speed on Red
                    Hood.
                  </li>
                </ul>
              </blockquote>
              <StaticImage
                src="../../../images/nikke/reviews/bunnyalice_uptime.webp"
                alt="Kit"
              />
              <p>Credit to Mefi (mef.i) for sharing this simulation!</p>
              <p>
                As discussed above, Bunny Alice relies on Max Ammo to make
                Tove's personal stacks more consistent (for Attack Speed
                uptime). While none of her skills is based off her base stats,
                you would still want to get several Max Ammo rolls on her. They
                are, however, not mandatory. For the precise amounts, you can
                consult with the data collected by Mefi above. Here, Tove (Solo)
                indicates the uptime of stacks on units that do not have the
                Water element, whereas Tove (+ B. Alice) indicates the uptime of
                stacks on units with the element Water.
              </p>
              <ul>
                <li>
                  <strong>Essential:</strong> -
                </li>
                <li>
                  <strong>Ideal:</strong> 1-4× Max Ammo
                </li>
                <li>
                  <strong>Passable:</strong> -
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <ul>
                <li>
                  <strong>Skill 1: 3-7</strong>
                </li>
                <ul>
                  <li>
                    As needed. Higher level = more healing. There is a jump in
                    blue skill manual consumption from Level 3 ➔ 4 and 5 ➔ 6.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 1~10</strong>
                </li>
                <ul>
                  <ul>
                    <li>
                      The breakpoints are different for each player because Max
                      Ammo is a percentage (%). Some levels will not provide Max
                      Ammo increase, though the Instant Reload effect does
                      become stronger.
                    </li>
                    <li>
                      In general,{' '}
                      <strong>
                        avoid the value (Max Ammo being divisible by 3 +1)
                      </strong>{' '}
                      since it will make your unit reload 4 times instead of 3.
                      To put it simply, having 13, 16, 19, 22 ammo and so on...
                      is bad for your Clip SG. While it may be small, it is
                      still a DPS loss regardless. Be careful when upgrading
                      Bunny Alice's S2!
                    </li>
                    <li>
                      Use{' '}
                      <a
                        href="https://docs.google.com/spreadsheets/d/1d_DvqexG3MeZiSF5TFzXF3kZm_Tw8-b8mGKitdT7c2I/edit#gid=1153210870"
                        target="_blank"
                        rel="noreferrer"
                      >
                        our sheet
                      </a>{' '}
                      featured in our Soda: Twinkling Bunny's guide, input your
                      B. Soda's/PrivM's Max Ammo OL value, then choose one of
                      the Alice's S2 levels that would grant them{' '}
                      <strong>+1 Max Ammo (or more)</strong>.
                    </li>
                    <li>
                      For SG characters without Max Ammo OL, the default
                      breakpoints are <strong>Level 3/7/10</strong>. Even so, if
                      you cannot afford to obtain Max Ammo OL, do not get Level
                      10 because this would make them have 13 ammo. Level 1 & 2
                      also suffer from the same issue.
                    </li>
                    <li>
                      For SR/RL characters without Max Ammo OL, the default
                      breakpoint is <strong>Level 6.</strong>
                    </li>
                  </ul>
                </ul>
                <li>
                  <strong>Burst Skill: 3~7</strong>
                </li>
                <ul>
                  <li>
                    As needed. Higher level = stronger panic healing button.
                    There is a jump in blue burst manual consumption from Level
                    3 ➔ 4 and 5 ➔ 6.
                  </li>
                </ul>
              </ul>
              <Row className="video-row">
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="aKaYD0oibzY" />
                </Col>
              </Row>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="alice-wonderland-bunny" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <p>
                Bastion theoretically has slightly more uptime than Resilience
                because of reload animation delay (built-in on all characters),
                but Resilience will also do just fine.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: The SG Meta Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="alice-wonderland-bunny"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="tove" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="leona" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="soda-twinkling-bunny"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="privaty-unkind-maid"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                The new meta SG comp, ready to compete against other teams for a
                top spot in Raids! B. Alice not only provides the basic
                necessities of healing and Ammo Support for this team, but she
                also provides the very crucially needed stack count up for Tove!
                Not only does B. Alice allow for a permanent uptime of Tove's S2
                for all of the team, she also ensures that Leona gets to keep
                Tove's Burst Skill stacks as well. While this team still has its
                drawbacks, it awaits even stronger SG units to take it to an
                even higher level!
              </p>
              <h5>Team #2: The King's Medic</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="alice-wonderland-bunny"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
              </div>
              <p>
                B. Alice replaces Naga or Rapunzel in this comp and takes the
                role of the pure healer nun. While Naga is still the superior
                choice for Campaign, B.Alice can definitely compete for this
                spot against Rapunzel in Raids. Like Rapunzel, she activates
                Crown's S2 with ease since she heals all units. Hence, the
                requirement of positioning Crown in Slot 1 is not needed with
                B.Alice. Unlike Rapunzel, B.Alice provides ammo support on top
                of her already incredible heals, which makes her better than
                Rapunzel in a lot of situations, since ammo buffs are on the
                offensive!
              </p>
              <h5>Team #3: Back to Basics</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="alice-wonderland-bunny"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="tove" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="placeholder-box">B2</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
              </div>
              <p>
                The basic team structure for when you do not have most meta
                units and want to use B. Alice as purely a Healer + Ammo
                Support. Example teams can be a twist on the old popular
                campaign comps: B. Alice Liter Centi Alice Maxwell / B. Alice D.
                Wife Centi Scarlet Modernia. Tove can be used against bosses,
                but the B3 need to be SG ideally since Tove is an SG buffer.
                Keep in mind that B. Alice is anti-synergistic with Dorothy and
                Summer Anis because of her Max Ammo buff.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvP Team Compositions" />
              <p>
                This character isn't good in PVP, so there are no PVP team
                compositions available. Just don't use her.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Provides decent healing to all allies every 3 seconds.
                        </li>
                        <li>
                          Increases stack count of buffs for Water units, high
                          synergy with Tove where she assists her to make the SG
                          team extremely relevant in Raids.
                        </li>
                        <li>
                          Has mag size buff and ammo refill, allowing for her to
                          be a great support to several ammo-dependent units,
                          especially some SGs, MGs and SRs.
                        </li>
                        <li>
                          Huge HP Potency buff when she bursts, which
                          substantially increases the healing provided to team.
                        </li>
                        <li>Versatile and can fit in many teams.</li>
                        <li>
                          Provides burst gen filling speed, allowing the team to
                          reach the next burst even faster.
                        </li>
                        <li>
                          High flexibility since she does not need to burst to
                          provide her heals, and can burst without hindering
                          Full Burst rotation since she re-enters B1 stage.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          40s cooldown on Burst. In situations where more
                          healing is required, this becomes apparent.
                        </li>
                        <li>
                          You're forced to run a 2-1-2 formation due to her
                          Re-Enter Burst Stage I skill.
                        </li>
                        <li>
                          Stack count buff is only limited to Water Units, hence
                          Tove's stacks will not remain on non-Water SG units.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesAliceBunny;

export const Head: React.FC = () => (
  <Seo
    title="Alice: Wonderland Bunny guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Alice: Wonderland Bunny in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
