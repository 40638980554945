import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const TOGGuidesIntroPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="tog">
      <ul className="breadcrumb">
        <li>
          <Link to="/tog/">Tower of God: New World</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/tog/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Introduction to the game</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/tog/categories/category_intro.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Introduction to the game</h1>
          <h2>
            Everything you need to know about Tower of God: New World gacha
            game.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="About the game" />
        <p>
          Tower of God New World is a mobile game published by Netmarble. It is
          a faithful reaction of the original webtoon, full of action-packed
          combat - Relive exhilarating scenes from the webtoon. Experience Bam
          and his friends' thrilling battles in 3D!
        </p>
        <p>
          While the game belongs to the popular idle gacha game genre, it
          introduces several systems that make it stand out - one of them is the
          Shinsu link which allows you to level up your account, instead of
          leveling the characters one by one. This allows you to quickly adapt
          to the opponents you face, without the need to reset who you're using!
        </p>
        <SectionHeader title="Official links" />
        <ul>
          <li>
            Official forum:{' '}
            <a
              href="https://forum.netmarble.com/towerofgod_en"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official Discord:{' '}
            <a
              href="https://discord.gg/tognewworld"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official YouTube:{' '}
            <a
              href="https://www.youtube.com/@tog_new_world"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Google Play Store:{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.netmarble.tog&hl=en&gl=US"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Apple Store:{' '}
            <a
              href="https://apps.apple.com/us/app/tower-of-god-new-world/id1599435437"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
        </ul>
        <SectionHeader title="Official images" />
        <Row xs={1} xl={2}>
          <Col>
            <StaticImage src="../../../images/tog/promo.webp" alt="Guides" />
          </Col>
          <Col>
            <StaticImage src="../../../images/tog/promo2.webp" alt="Guides" />
          </Col>
          <Col>
            <StaticImage src="../../../images/tog/promo3.webp" alt="Guides" />
          </Col>
          <Col>
            <StaticImage src="../../../images/tog/promo4.webp" alt="Guides" />
          </Col>
        </Row>
        <SectionHeader title="Gameplay" />
        <p>
          If you want to check how the game looks in action, below video shows
          the early game (no commentary, so you can focus on what you see):
        </p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="-spqtyk16ow" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default TOGGuidesIntroPage;

export const Head: React.FC = () => (
  <Seo
    title="Introduction to the game | Tower of God: New World | Prydwen Institute"
    description="Everything you need to know about Tower of God: New World gacha game."
    game="tog"
  />
);
