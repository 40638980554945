export const ESTeams = [
  {
    Id: 1,
    Pos_1: 'wheri',
    Pos_2: 'eve',
    Pos_3: 'larimar',
    Pos_4: 'yuria',
    Pos_5: 'lizelotte',
    Formation: 'Assault',
    Mode: 'IR Choco',
    Treasure: '',
    Explanation: '{20-Jan-2025} Ensure Lizelotte gets Eve Bond. ',
    Subs: 'Ult: Lizelotte',
    Gear: 'Gear: Lizelotte - CDMG, Rest - Speed'
  },
  {
    Id: 2,
    Pos_1: 'eve',
    Pos_2: 'catherine-radiance',
    Pos_3: 'yuria',
    Pos_4: 'larimar',
    Pos_5: 'lizelotte',
    Formation: 'Assault',
    Mode: 'IR Choco',
    Treasure: '',
    Explanation:
      '{20-Jan-2025} Ensure Lizelotte gets Eve Bond. Hyperlevel Lizelotte. Cleared at 415 sync by resetting to 350 sync and making Lize level 500.',
    Subs: 'Ult: Lizelotte',
    Gear: 'Gear: Lizelotte - CDMG, Rest - Speed'
  },
  {
    Id: 3,
    Pos_1: 'yuria',
    Pos_2: 'wheri',
    Pos_3: 'larimar',
    Pos_4: 'linzy-thanatos',
    Pos_5: 'mephistopheles-dawn',
    Formation: 'Defense',
    Mode: 'WR Lighthouse',
    Treasure: '',
    Explanation:
      '{20-Jan-2025} Partners: Yuria = Joanne (Main), Erusha (Sub); Wheri = Naiah (Main), Catherine (Sub); Larimar = Dora (Main), Eve (Sub); Linzy-Thanatos = Adrianne (Main), Garnet (Sub); Mephi (Dawn) = Linzy (Main), Petra (Sub) - If listed subs are low ascension, you can replace them with higher ascension souls that provide the same buff',
    Subs: 'Ult: Larimar around 2:01; Wheri around 1:29; Yuria around 1:09; Mephi (Dawn) around 0:39; Linzy (Thanatos) at 0:30 just before groggy phase ends',
    Gear: 'Gear: Linzy (Thanatos), Mephi (Dawn) - CDMG, Rest - Speed'
  },
  {
    Id: 4,
    Pos_1: 'wheri',
    Pos_2: 'catherine-radiance',
    Pos_3: 'yuria',
    Pos_4: 'larimar',
    Pos_5: 'linzy-thanatos',
    Formation: 'Defense',
    Mode: 'WR Lighthouse',
    Treasure: '',
    Explanation:
      '{07-Dec-2024} Partners: Linzy (Thanatos) = Violette (Main), Petra (Sub); Larimar = Dora (Main), Eve (Sub); Yuria = Linzy (Main), Erusha (Sub); Catherine (Radiance) = Joanne (Main), Vivienne (Sub); Wheri = Naiah (Main), Lute (Sub) - If listed Subs are low ascension, you can replace them with higher ascension souls that provide the same buff',
    Subs: "Ult: Wheri (1:39); Yuria (1:09), Larimar (0:44). Use Linzy's ult during second groggy phase just before Larimar's sentence is about to expire",
    Gear: 'Gear: Linzy (Thantos) - CDMG or ATK, Rest - Speed'
  },
  {
    Id: 5,
    Pos_1: 'catherine-radiance',
    Pos_2: 'violette',
    Pos_3: 'yuria',
    Pos_4: 'linzy-thanatos',
    Pos_5: 'larimar',
    Formation: 'Defense',
    Mode: 'WR Lighthouse',
    Treasure: '',
    Explanation:
      '{Old} Partners: Catherine (Radiance) (Soonie - Main) (Vivienne - Sub) ; Violette (Catherine - Main) (Kanna - Sub) ; Yuria (Linzy - Main) (Lute - Sub) ; Linzy (Thanatos) (Renee - Main) (Petra - Sub) ; Larimar (Velanna - Main) (Eve - Sub). If listed Subs are low ascension, you can replace them with higher ascension souls that provide the same buff',
    Subs: "Ult: Don't use Catherine (Radiance) Ult at all, save Linzy (Thantos) Ult for the end (or as late as possible before dying), save Yuria Ult for survival as necessary.",
    Gear: 'Gear: Linzy (Thantos) - CDMG, Rest - Speed'
  },
  {
    Id: 6,
    Pos_1: 'garnet',
    Pos_2: 'yuria',
    Pos_3: 'lilith',
    Pos_4: 'mephistopheles-dawn',
    Pos_5: 'larimar',
    Formation: 'Basic',
    Mode: 'GR Pluto',
    Treasure: 'Obsidian Arrowhead',
    Explanation:
      "{12-Jan-2025} Main Team, does multiple times damege of other two teams combined. Works fine using full auto, or you can spam Mephi's main & ult for slightly higher score while letting auto handle the others.",
    Subs: 'Ult: Mephistopheles (Dawn) all the way, use Yuria on and off if close to dying\nSub: Garnet can be substituted by Dominique but will significantly reduce damage.  (Same formation)',
    Gear: 'Gear: Mephistopheles (Dawn) - CDMG or ATK, Rest - Speed | Mephi ideally wants Origin Artifact.'
  },
  {
    Id: 7,
    Pos_1: 'ayame',
    Pos_2: 'catherine-radiance',
    Pos_3: 'sigrid',
    Pos_4: 'beleth',
    Pos_5: 'linzy-thanatos',
    Formation: 'Assault',
    Mode: 'GR Pluto',
    Treasure: 'Falcon Hunters Gloves',
    Explanation:
      '{12-Jan-2025} Sigrid ult priority - Swap to Beleth ult at 50s mark (or just after) - After 2 Beleth ults, swap to Sig ult and then CLinzy ult for final hit before beam wipes you out. - Can spam Sigrid ult as it becomes available.',
    Subs: 'Ult: Sigrid, Beleth as needed, CLinzy at the end \nSub: Ayame can be substituted by Eve but witll reduce damage. (Same formation)',
    Gear: 'Gear: Linzy (Thanatos) - ATK, Sigrid- CDMG, Rest - Speed | Ayame Ideally wants Origin Artifact (If you can keep Clinzy highest attack with it)'
  },
  {
    Id: 8,
    Pos_1: 'dominique',
    Pos_2: 'wheri',
    Pos_3: 'onyx',
    Pos_4: 'hazel',
    Pos_5: 'erusha',
    Formation: 'Defense',
    Mode: 'GR Pluto',
    Treasure: 'Glass Cannon',
    Explanation:
      '{12-Jan-2025} Hazel ult first - Erusha ult until 50s mark, then swap to Onyx ult two times between 50s and 25s mark, then swap back to Erusha ult till end of fight. - Can spam Erusha ult as it becomes available. If dying, ignore Erusha ult and only use Onyx ult after the first Hazel ult.',
    Subs: 'Ult: Hazel, Erusha, Onyx as needed\nSub: If using Dominique in Mephi team, can use Kanna in her place here.  (Same formation)',
    Gear: 'Gear: Erusha - CDMG or ATK, Hazel - CDMG, Rest - Speed'
  },
  {
    Id: 9,
    Pos_1: 'beleth',
    Pos_2: 'melfice',
    Pos_3: 'tasha',
    Pos_4: 'sigrid',
    Pos_5: 'linzy-thanatos',
    Formation: 'Basic',
    Mode: 'GR Pluto',
    Treasure: 'Falcon Hunters Gloves',
    Explanation: '{12-Jan-2025} Worse than Ayame & Eve Team.',
    Subs: 'Ult: Sigrid all the way, use Beleth on and off if close to dying. Use Linzy (Thanatos) Ultimate at the end/as late as possible.',
    Gear: 'Gear: Linzy (Thanatos) - ATK, Sigrid- CDMG, Rest - Speed'
  },
  {
    Id: 10,
    Pos_1: 'catherine-radiance',
    Pos_2: 'yuria',
    Pos_3: 'sigrid',
    Pos_4: 'larimar',
    Pos_5: 'linzy-thanatos',
    Formation: 'Defense',
    Mode: 'GR Pluto',
    Treasure: 'Falcon Hunters Gloves',
    Explanation:
      " {Old} Build stacks on Linzy (Thanatos) and unleash huge Ult at the end, making sure Larimar's sentence is active to copy damage.",
    Subs: 'Ult: Sigrid until final rotation, then Linzy (Thantos)',
    Gear: 'Gear: Linzy (Thantos) - CDMG; Rest - Speed'
  },
  {
    Id: 11,
    Pos_1: 'eve',
    Pos_2: 'wheri',
    Pos_3: 'yuria',
    Pos_4: 'catherine-radiance',
    Pos_5: 'lizelotte',
    Formation: 'Assault',
    Mode: 'IR Tabby',
    Treasure: '',
    Explanation: '{12-Jan-2025} Ensure Lizelotte gets Eve Bond.',
    Subs: 'Ult - Lizelotte all the way, if dying can use Yuria/Wheri ult as needed.',
    Gear: 'Gear: Lizelotte - CDMG, Catherine (Radiance) - Attack/Speed, Rest - Speed.'
  },
  {
    Id: 12,
    Pos_1: 'talia',
    Pos_2: 'wheri',
    Pos_3: 'yuria',
    Pos_4: 'eve',
    Pos_5: 'lizelotte',
    Formation: 'Assault',
    Mode: 'IR Tabby',
    Treasure: '',
    Explanation:
      '{12-Jan-2025} Ensure Lizelotte gets Eve Bond. Talia is there for passive Mana Recovery. Credits: Curren_chan from Arca',
    Subs: 'Ult - Lizelotte all the way, if dying can use Yuria/Wheri ult as needed.',
    Gear: 'Gear: Lizelotte - CDMG, Rest - Speed.'
  },
  {
    Id: 13,
    Pos_1: 'yuria',
    Pos_2: 'eve',
    Pos_3: 'beleth',
    Pos_4: 'larimar',
    Pos_5: 'kurumi-tokisaki',
    Formation: 'Assault',
    Mode: 'IR Tabby',
    Treasure: '',
    Explanation: '{12-Jan-2025} Ensure Kurumi gets Eve Bond. ',
    Subs: 'Ult - Kurumi all the way, if dying can use Yuria ult as needed.',
    Gear: 'Gear: Kurumi -  CDMG or ATK , Rest - Speed.'
  },
  {
    Id: 14,
    Pos_1: 'daphne',
    Pos_2: 'yuria',
    Pos_3: 'eve',
    Pos_4: 'flex',
    Pos_5: 'flex',
    Formation: 'Ranged',
    Mode: 'Battlefront',
    Treasure: '',
    Explanation:
      'The standard Hyper Carry Team to bulldoze through 1 Team Content. Best carry (flex 2, pos 5) options: Aki, Lizelotte, Claudia.',
    Subs: 'Substitutions: Replace tank/healer with tank/healer from secondary/tertiary teams. Flex 1: CC/Buffer/SubDPS slot. See tier list for best options.',
    Gear: ''
  },
  {
    Id: 15,
    Pos_1: 'chloe',
    Pos_2: 'beleth',
    Pos_3: 'honglan',
    Pos_4: 'flex',
    Pos_5: 'flex',
    Formation: 'Basic',
    Mode: 'Battlefront',
    Treasure: '',
    Explanation:
      'Secondary/tertiary BF team for 3 Team Content. Best carry options (flex 2, pos 5): same as Team 1 plus Catherine (Radiance), Tasha, Velanna',
    Subs: 'Substitions: Replace tank/healer with next best option from tertiary team. Honglan -> Ayame/Naiah. Flex 1: CC/Buffer/SubDPS slot. See tier list for best filler options.',
    Gear: ''
  },
  {
    Id: 16,
    Pos_1: 'petra',
    Pos_2: 'wheri',
    Pos_3: 'larimar',
    Pos_4: 'flex',
    Pos_5: 'flex',
    Formation: 'Basic',
    Mode: 'Battlefront',
    Treasure: '',
    Explanation:
      'Secondary/tertiary BF team for 3 Team Content. Best carry options (flex 2, pos 5): same as previous, plus Hazel, Ayame, Linzy',
    Subs: 'Substitution tanks: Claire, Soonie. Healers: Onyx, Catherine, Prim. Flex 1: CC/Buffer/SubDPS slot. See tier list for best filler options.',
    Gear: ''
  },
  {
    Id: 17,
    Pos_1: 'jiho',
    Pos_2: 'catherine',
    Pos_3: 'aira',
    Pos_4: 'honglan',
    Pos_5: 'aki',
    Formation: 'Assault',
    Mode: 'Labyrinth Boss',
    Treasure: '',
    Explanation:
      "Your goal is to keep Chamuel's mana from filling. Use Cath ultimate on 2nd & 3rd dash attacks for survival",
    Subs: 'Ult: Honglan, Jiho, or Cath (depends on treasures)',
    Gear: 'Gear: All - Speed'
  },
  {
    Id: 18,
    Pos_1: 'claire',
    Pos_2: 'yuria',
    Pos_3: 'lizelotte',
    Pos_4: 'eve',
    Pos_5: '',
    Formation: 'Ranged',
    Mode: 'Eden Alliance - Gaon',
    Treasure: '',
    Explanation:
      '{01-Dec-2024} Clears max score for this Operation. If enemy Aki uses main skill, Lizelotte must survive or you restart. Level 350~400+ Sync clear.',
    Subs: 'Ult: Lizelotte',
    Gear: 'Gear: Lizelotte - CDMG or Anni Attack, Rest - Speed'
  },
  {
    Id: 19,
    Pos_1: 'eve',
    Pos_2: 'catherine-radiance',
    Pos_3: 'yuria',
    Pos_4: 'larimar',
    Pos_5: 'mephistopheles-dawn',
    Formation: 'Defense',
    Mode: 'ESS Adrianne',
    Treasure: '',
    Explanation:
      '{06-Jan-2025} Ensure Mephi gets Eve Bond, Keep Larimar HP low. Credits to Zigg (Arca) Video Link - https://youtu.be/h1EU35U_ipg',
    Subs: 'Ult: Mephistopheles (Dawn)',
    Gear: 'Gear: Mephistopheles (Dawn) - Attack , Rest - Speed'
  },
  {
    Id: 20,
    Pos_1: 'wheri',
    Pos_2: 'larimar',
    Pos_3: 'eve',
    Pos_4: 'mephistopheles-dawn',
    Pos_5: 'linzy-thanatos',
    Formation: 'Assault',
    Mode: 'ESS Adrianne',
    Treasure: '',
    Explanation:
      '{06-Jan-2025} Ensure Linzy gets Eve Bond. Credits to 맑지않음 (Arca) Video Link - https://youtu.be/ch14oUYJAZE',
    Subs: 'Ult - Wheri, save Linzy (Thantos) Ult for the end (or as late as possible before dying)',
    Gear: 'Gear: Mephistopheles (Dawn) - Attack , Rest - Speed'
  },
  {
    Id: 21,
    Pos_1: 'yuria',
    Pos_2: 'eve',
    Pos_3: 'lairmar',
    Pos_4: 'catherine-radiance',
    Pos_5: 'linzy-thanatos',
    Formation: 'Assault',
    Mode: 'ESS Adrianne',
    Treasure: '',
    Explanation:
      '{06-Jan-2025} Ensure Linzy gets Eve Bond. Credits to ㅇㅇㅋ (Arca) Video Link - https://youtu.be/vjA3RdaX1o8',
    Subs: 'Ult - Eve first, Larimar once-twice, Yuria for suvival and save Linzy (Thantos) Ult for the end (or as late as possible before dying)',
    Gear: 'Gear: Linzy (Thanatos) - CDMG, Rest - Speed'
  },
  {
    Id: 22,
    Pos_1: 'yuria',
    Pos_2: 'eve',
    Pos_3: 'catherine-radiance',
    Pos_4: 'talia',
    Pos_5: 'lizelotte',
    Formation: 'Assault',
    Mode: 'ESS Adrianne',
    Treasure: '',
    Explanation: 'Alternate Adrianne ESS comp',
    Subs: 'Ult: Lizelotte',
    Gear: 'Gear: Lizelotte - CDMG, Rest - Speed'
  },
  {
    Id: 23,
    Pos_1: 'yuria',
    Pos_2: 'beleth',
    Pos_3: 'eve',
    Pos_4: 'lizelotte',
    Pos_5: 'catherine-radiance',
    Formation: 'Defense',
    Mode: 'ESS Adrianne',
    Treasure: '',
    Explanation: 'Best Single Team clear comp for Adrianne ESS.',
    Subs: 'Ult: Lizelotte',
    Gear: 'Gear: Lizelotte - CDMG, Rest - Speed'
  },
  {
    Id: 24,
    Pos_1: 'larimar',
    Pos_2: 'yuria',
    Pos_3: 'mephistopeles',
    Pos_4: 'eve',
    Pos_5: 'lizelotte',
    Formation: 'Assault',
    Mode: 'ESS Aira',
    Treasure: '',
    Explanation: 'Alternate Aira ESS comp',
    Subs: 'Ult: Lizelotte',
    Gear: 'Gear: Lizelotte - CDMG, Rest - Speed'
  },
  {
    Id: 25,
    Pos_1: 'eve',
    Pos_2: 'naiah',
    Pos_3: 'larimar',
    Pos_4: 'yuria',
    Pos_5: 'lizelotte',
    Formation: 'Assault',
    Mode: 'ESS Aira',
    Treasure: '',
    Explanation:
      'Best Single Team clear comp for Aira ESS. Naiah allows you to enter groggy faster',
    Subs: 'Ult - Lizelotte',
    Gear: 'Gear: Lizelotte - CDMG, Rest - Speed'
  },
  {
    Id: 26,
    Pos_1: 'xiaolian',
    Pos_2: 'adrianne',
    Pos_3: 'catherine',
    Pos_4: 'lute',
    Pos_5: 'tasha',
    Formation: 'Assault',
    Mode: 'ESS Aki',
    Treasure: '',
    Explanation: 'Aki ESS Team 1 - Groggy Gauge Build-up composition.',
    Subs: '',
    Gear: 'Gear: Petra - Attack to tank Blood Moon'
  },
  {
    Id: 27,
    Pos_1: 'onyx',
    Pos_2: 'petra',
    Pos_3: 'joanne',
    Pos_4: 'bryce',
    Pos_5: 'melfice',
    Formation: 'Basic',
    Mode: 'ESS Aki',
    Treasure: '',
    Explanation: 'Aki ESS Team 2 - Damage comp.',
    Subs: 'Ult: Bryce, Melfice (alternate between the two)',
    Gear: ''
  },
  {
    Id: 28,
    Pos_1: 'beleth',
    Pos_2: 'yuria',
    Pos_3: 'catherine-radiance',
    Pos_4: 'eve',
    Pos_5: 'sigrid',
    Formation: 'Assault',
    Mode: 'ESS Aki',
    Treasure: '',
    Explanation: 'Aki ESS Team 3 - Clear-up comp.',
    Subs: 'Ult: Sigrid',
    Gear: ''
  },
  {
    Id: 29,
    Pos_1: 'mephistopeles',
    Pos_2: 'yuria',
    Pos_3: 'larimar',
    Pos_4: 'dominique',
    Pos_5: 'linzy-thanatos',
    Formation: 'Assault',
    Mode: 'ESS Ayame',
    Treasure: '',
    Explanation:
      'Alternate Ayame ESS Team without Lilith. Requires decently high investment',
    Subs: 'Ult - Dominique, Clinzy',
    Gear: 'Gear: Linzy (Thantos) & Dominique - CDMG, Rest - Speed'
  },
  {
    Id: 30,
    Pos_1: 'mephistopeles',
    Pos_2: 'yuria',
    Pos_3: 'lilith',
    Pos_4: 'linzy-thanatos',
    Pos_5: 'larimar',
    Formation: 'Basic',
    Mode: 'ESS Ayame',
    Treasure: '',
    Explanation: 'Alternate Ayame ESS comp',
    Subs: 'UIt - Lilith, Clinzy.',
    Gear: 'Gear: Linzy (Thantos) - CDMG, Rest - Speed.'
  },
  {
    Id: 31,
    Pos_1: 'mephistopeles',
    Pos_2: 'lilith',
    Pos_3: 'larimar',
    Pos_4: 'yuria',
    Pos_5: 'dominique',
    Formation: 'Assault',
    Mode: 'ESS Ayame',
    Treasure: '',
    Explanation: 'Best Single Team clear comp for Ayame ESS.',
    Subs: 'Ult - Dominique',
    Gear: 'Gear: Dominique - CDMG, Rest - Speed'
  },
  {
    Id: 32,
    Pos_1: 'haru',
    Pos_2: 'onyx',
    Pos_3: 'wheri',
    Pos_4: 'edith',
    Pos_5: 'sakuyo',
    Formation: 'Defense',
    Mode: 'ESS Catherine',
    Treasure: '',
    Explanation: 'Catherine ESS Team 1',
    Subs: 'Ult: Catherine (Radiance), Beleth',
    Gear: 'Gear: Edith & Sakuyo - CDMG, Rest - Speed'
  },
  {
    Id: 33,
    Pos_1: 'yuria',
    Pos_2: 'lute',
    Pos_3: 'eve',
    Pos_4: 'larimar',
    Pos_5: 'lizelotte',
    Formation: 'Assault',
    Mode: 'ESS Catherine',
    Treasure: '',
    Explanation: 'Catherine ESS Team 2',
    Subs: '',
    Gear: 'Gear: Lizelotte - CDMG, Rest - Speed'
  },
  {
    Id: 34,
    Pos_1: 'eve',
    Pos_2: 'kanna',
    Pos_3: 'larimar',
    Pos_4: 'mephistopeles',
    Pos_5: 'dominique',
    Formation: 'Assault',
    Mode: 'ESS Jacqueline',
    Treasure: '',
    Explanation: 'Best Single Team clear comp for Jacqueline ESS.',
    Subs: 'Ult - Dominique',
    Gear: 'Gear: Dominique - CDMG, Rest - Speed'
  },
  {
    Id: 35,
    Pos_1: 'yuria',
    Pos_2: 'eve',
    Pos_3: 'sigrid',
    Pos_4: 'beleth',
    Pos_5: 'linzy-thanatos',
    Formation: 'Assault',
    Mode: 'ESS Lizelotte',
    Treasure: '',
    Explanation: 'Best Single Team clear comp for Lizelotte ESS.',
    Subs: 'Ult: Sigrid, Clinzy',
    Gear: 'Gear: Linzy (Thantos) & Sigrid - CDMG, Rest - Speed'
  },
  {
    Id: 36,
    Pos_1: 'onyx',
    Pos_2: 'haru',
    Pos_3: 'melfice',
    Pos_4: 'tasha',
    Pos_5: 'bryce',
    Formation: 'Defense',
    Mode: 'ESS Lizelotte',
    Treasure: '',
    Explanation: 'Additional Team for Lizelotte ESS if needed.',
    Subs: 'Ult: Bryce',
    Gear: 'Gear: Onyx - Speed, Rest - CDMG'
  },
  {
    Id: 37,
    Pos_1: 'yuria',
    Pos_2: 'larimar',
    Pos_3: 'dominique',
    Pos_4: 'hazel',
    Pos_5: 'erusha',
    Formation: 'Defense',
    Mode: 'ESS Naiah',
    Treasure: '',
    Explanation:
      "{16-Dec-2024} Take note that this team/explanation is for ESS 501+ and may not work below 501. Larimar must be highest ATK so that she takes the prison when fight starts. Yuria's main will cleanse 15 stress stacks each time, so try to time it so that you keep above 30 but below 70 stress stacks. Aside from that, you basically just spam skills as they come up while making sure you don't cancel Hazel or Erusha's stun subs, or Yuria's sub 2.",
    Subs: 'Utl: Hazel first, then Erusha remainder of fight',
    Gear: 'Gear: Hazel, Erusha, Dominique - CDMG, Yuria - Speed, Larimar - ATK or ATK + HP (must be highest ATK on team)'
  },
  {
    Id: 38,
    Pos_1: 'larimar',
    Pos_2: 'yuria',
    Pos_3: 'hazel',
    Pos_4: 'lilith',
    Pos_5: 'erusha',
    Formation: 'Basic',
    Mode: 'ESS Vivienne',
    Treasure: '',
    Explanation:
      'Best Single Team clear comp for current Vivienne ESS iteration. ',
    Subs: 'Ult: Hazel first, then Erusha for the remainder of fight',
    Gear: 'Gear:Hazel, Erusha - CDMG, Rest - Speed'
  },
  {
    Id: 39,
    Pos_1: 'garnet',
    Pos_2: 'violette',
    Pos_3: 'onyx',
    Pos_4: 'dominique',
    Pos_5: 'erusha',
    Formation: 'Assault',
    Mode: 'ESS Vivienne',
    Treasure: '',
    Explanation:
      'Autoskip 600 team for current Vivienne ESS iteration (Sync Dependent)',
    Subs: 'Ult: Onyx (can use Erusha if sync is high enough)',
    Gear: 'Gera: Erusha, Dominique - CDMG, Rest - Speed'
  },
  {
    Id: 40,
    Pos_1: 'clara',
    Pos_2: 'seeha',
    Pos_3: 'mica',
    Pos_4: 'flex',
    Pos_5: 'honglan',
    Formation: 'Defense',
    Mode: 'Gate Beast',
    Treasure: '',
    Explanation: 'Best team for single team content (Level 1-200 Faction Gate)',
    Subs: 'Beast flex options: Flynn, Haru, Xiaolian, Tasha, Lute',
    Gear: ''
  },
  {
    Id: 41,
    Pos_1: 'soonie',
    Pos_2: 'onyx',
    Pos_3: 'sakuyo',
    Pos_4: 'flex',
    Pos_5: 'flex',
    Formation: 'Basic',
    Mode: 'Gate Beast',
    Treasure: '',
    Explanation: 'Secondary team for Faction Depths.',
    Subs: '',
    Gear: ''
  },
  {
    Id: 42,
    Pos_1: 'daphne',
    Pos_2: 'talia',
    Pos_3: 'flex',
    Pos_4: 'naiah',
    Pos_5: 'dominique',
    Formation: 'Basic',
    Mode: 'Gate Fairy',
    Treasure: '',
    Explanation: 'Best team for single team content (Level 1-200 Faction Gate)',
    Subs: 'Best Fairy 1 team flex options: Vivi, Manon, Nicole',
    Gear: ''
  },
  {
    Id: 43,
    Pos_1: 'chloe',
    Pos_2: 'erika',
    Pos_3: 'vivienne',
    Pos_4: 'flex',
    Pos_5: 'nicole',
    Formation: 'Defense',
    Mode: 'Gate Fairy',
    Treasure: '',
    Explanation: 'Secondary team for Faction Depths.',
    Subs: 'Best Fairy supplementary flex options: Edith, Miriam/Kanna',
    Gear: ''
  },
  {
    Id: 44,
    Pos_1: 'claire',
    Pos_2: 'beleth',
    Pos_3: 'mephistopeles',
    Pos_4: 'flex',
    Pos_5: 'aki',
    Formation: 'Basic',
    Mode: 'Gate Human',
    Treasure: '',
    Explanation: 'Best team for single team content (Level 1-200 Faction Gate)',
    Subs: '',
    Gear: ''
  },
  {
    Id: 45,
    Pos_1: 'linzy',
    Pos_2: 'catherine',
    Pos_3: 'jiho',
    Pos_4: 'dora',
    Pos_5: 'naomi',
    Formation: 'Basic',
    Mode: 'Gate Human',
    Treasure: '',
    Explanation: 'Secondary team for Faction Depths.',
    Subs: '',
    Gear: ''
  },
  {
    Id: 46,
    Pos_1: 'joanne',
    Pos_2: 'bryce',
    Pos_3: 'flex',
    Pos_4: 'rebecca',
    Pos_5: 'sigrid',
    Formation: 'Basic',
    Mode: 'Gate Undead',
    Treasure: '',
    Explanation: 'Secondary team for Faction Depths.',
    Subs: 'Undead flex options: Otoha, Nini',
    Gear: ''
  },
  {
    Id: 47,
    Pos_1: 'petra',
    Pos_2: 'prim',
    Pos_3: 'jacqueline',
    Pos_4: 'garnet',
    Pos_5: 'velanna',
    Formation: 'Basic',
    Mode: 'Gate Undead',
    Treasure: '',
    Explanation: 'Best team for single team content (Level 1-200 Faction Gate)',
    Subs: '',
    Gear: ''
  },
  {
    Id: 48,
    Pos_1: 'dominique',
    Pos_2: 'larimar',
    Pos_3: 'hazel',
    Pos_4: 'yuria',
    Pos_5: 'lilith',
    Formation: 'Basic',
    Mode: 'GR Charite',
    Treasure: 'Lunatic Blade',
    Explanation:
      '#1 Team Comp [Whale] Hazel Hyper Carry Team. Yuria Shields. BiS DPS Team',
    Subs: 'Ult - Hazel first, Lilith after. Dominique can ult if Lilith is low invest. Subs - Mephi can replace Lilith if account has no Lilith.',
    Gear: 'Gear: Hazel - CDMG, Rest - Speed.'
  },
  {
    Id: 49,
    Pos_1: 'catherine-radiance',
    Pos_2: 'yuria',
    Pos_3: 'melfice',
    Pos_4: 'bryce',
    Pos_5: 'tasha',
    Formation: 'Basic',
    Mode: 'GR Charite',
    Treasure: 'Snake Headed Statue',
    Explanation:
      'Melfice/Tasha Dual Carry. Catherine (Radiance)/Yuria Shields. Melfice mana control.',
    Subs: 'Ult - alternate between Melfice and Tasha/Bryce (depending on invest)',
    Gear: 'Gear: Yuria - Speed, ACath - Attack (1st), Rest - CDMG'
  },
  {
    Id: 50,
    Pos_1: 'beleth',
    Pos_2: 'lilith',
    Pos_3: 'dominique',
    Pos_4: 'larimar',
    Pos_5: 'eve',
    Formation: 'Assault',
    Mode: 'GR Charite',
    Treasure: 'Necklace of Electricity',
    Explanation: 'Eve Carry. No shields needed due to insane mana control.',
    Subs: 'Ult - Eve until last ult, then Dominique. Subs - Naomi can replace Eve as main DPS, Ayame can replace Lilith if you lack her, lower damage ceiling however.',
    Gear: 'Gear: Eve - Attack/CDMG, Rest - Speed. Eve needs highest Attack on team.'
  },
  {
    Id: 51,
    Pos_1: 'onyx',
    Pos_2: 'garnet',
    Pos_3: 'joanne',
    Pos_4: 'violette',
    Pos_5: 'erusha',
    Formation: 'Defense',
    Mode: 'GR Charite',
    Treasure: 'Undead Idol',
    Explanation: 'Erusha Carry. Joanne Shields. Joanne mana control.',
    Subs: 'Ult - Joanne first, Rest Erusha. Subs - Nini can replace Violette.',
    Gear: 'Gear: Erusha - CDMG, Rest - Speed.'
  },
  {
    Id: 52,
    Pos_1: 'catherine-radiance',
    Pos_2: 'larimar',
    Pos_3: 'sigrid',
    Pos_4: 'kanna',
    Pos_5: 'yuria',
    Formation: 'Basic',
    Mode: 'GR Dark Knight',
    Treasure: "Falcon Hunter's Gloves",
    Explanation:
      "{01-Dec-2024} As long as you have 4pc speed on Yuria then her shield timings should line up to prevent stun. If it's not lining up, you can use Cath's shield as a backup. Larimar's sentence is not that important here, but her debuff spreading is. If you're playing on manual, make sure you don't cancel her Sub2 ability.",
    Subs: "Ult: Sigrid ult mostly. If you're dying after the first mob wave ends, try using Yuria ult just before that. Swap back to Sig ult afterwards and watch HP as you may need to use Yuria ult again. Subs: Ayame/Nicole instead Larimar.",
    Gear: 'Gear: Sigrid - ATK, Rest - Speed (Sigrid needs higher ATK than Kanna)'
  },
  {
    Id: 53,
    Pos_1: 'onyx',
    Pos_2: 'lilith',
    Pos_3: 'soonie',
    Pos_4: 'erusha',
    Pos_5: 'wheri',
    Formation: 'Defense',
    Mode: 'GR Dark Knight',
    Treasure: 'Glass Cannon',
    Explanation:
      "{01-Dec-2024} Wheri's shield should line up to block the stun after first mob wave. Use Lilith Main before using Erusha Main/Ult if possible.",
    Subs: 'Ult: Erusha only (most cases). You can try using Onyx ult just before each mob wave starts. Increases damage for some people.',
    Gear: 'Gear: Erusha - CDMG, Rest - Speed'
  },
  {
    Id: 54,
    Pos_1: 'melfice',
    Pos_2: 'bryce',
    Pos_3: 'linzy-thanatos',
    Pos_4: 'petra',
    Pos_5: 'garnet',
    Formation: 'Ranged',
    Mode: 'GR Dark Knight',
    Treasure: 'Cursed Sword',
    Explanation:
      "{01-Dec-2024} If playing manually, tick the auto button when it's time for Petra to use her main skill so that you don't throw off her shield timings.",
    Subs: "Ult: Alternate Bryce and Melfice. Use Linzy's ult at the end",
    Gear: "Gear: Linzy (Thantos), Bryce - CDMG, Melfice - HP, Rest - Speed (if Melfice isn't having survival issues then swap to speed)"
  },
  {
    Id: 55,
    Pos_1: 'catherine-radiance',
    Pos_2: 'yuria',
    Pos_3: 'wheri',
    Pos_4: 'linzy-thanatos',
    Pos_5: 'sigrid',
    Formation: 'Basic',
    Mode: 'GR Dark Knight',
    Treasure: 'Cursed Sword',
    Explanation:
      '{01-Dec-2024} No special explanation needed. Basically an auto comp. ',
    Subs: "Ult: Sigrid until the end and then use Linzy. Yuria ult just before first mob wave ends if you're having survival issues.",
    Gear: 'Gear: Sigrid, Linzy (Thanatos) - CDMG, Rest - Speed'
  },
  {
    Id: 56,
    Pos_1: 'onyx',
    Pos_2: 'dominique',
    Pos_3: 'daphne',
    Pos_4: 'erusha',
    Pos_5: 'lilith',
    Formation: 'Defense',
    Mode: 'GR Dark Knight',
    Treasure: 'Glass Cannon',
    Explanation:
      '{01-Dec-2024} Use Lilith Main before using Erusha Main/Ult if possible.',
    Subs: 'Ult: Erusha only (most cases). You can try using Onyx ult just before each mob wave starts. Increases damage for some people. Sub:  Soonie can be used instead Daphne.',
    Gear: 'Gear: Erusha, Dominique - CDMG, Rest - Speed (if your Dominique is having survival issues, switch her to 2pc ATK 2pc CDMG, but make sure either slot 3 or 4 has an Anni ATK  piece in it for lifesteal)'
  },
  {
    Id: 57,
    Pos_1: 'soonie',
    Pos_2: 'claudia',
    Pos_3: 'beleth',
    Pos_4: 'hazel',
    Pos_5: 'eve',
    Formation: 'Basic',
    Mode: 'GR Dark Knight',
    Treasure: "Falcon Hunter's Gloves",
    Explanation:
      "{01-Dec-2024} Hazel must have the highest ATK so that she gets Eve's buff",
    Subs: 'Ult: Hazel Sub: Daphne can be used instead Soonie. Violette can replace Claudia (but lower damage) for pairing with Hazel.',
    Gear: 'Gear: Hazel - ATK, Claudia - CDMG, Rest - Speed (if your Daphne is having survival issues, switch her to 4pc HP or DEF)'
  },
  {
    Id: 58,
    Pos_1: 'catherine-radiance',
    Pos_2: 'ayame',
    Pos_3: 'larimar',
    Pos_4: 'yuria',
    Pos_5: 'sakuyo-inferno',
    Formation: 'Defense',
    Mode: 'GR Gaia',
    Treasure: 'Cursed Sword',
    Explanation:
      '{20-Dec-2024} Catherine must be highest HP to take shackle. Sakuyo must be highest ATK.',
    Subs: "Ult: Sakuyo-Inferno | Use Sakuyo if you don't have Sakuyo-Inferno",
    Gear: 'Gear: Sakuyo (Inferno) - CDMG or ATK, Catherine (Radiance) - Speed or HP, Rest - Speed'
  },
  {
    Id: 59,
    Pos_1: 'cherrie',
    Pos_2: 'beleth',
    Pos_3: 'garnet',
    Pos_4: 'eve',
    Pos_5: 'sigrid',
    Formation: 'Defense',
    Mode: 'GR Gaia',
    Treasure: "Falcon Hunter's Glove",
    Explanation:
      '{20-Dec-2024} Beleth must be highest HP to take shackle. Sigrid must be highest ATK.',
    Subs: 'Ult: Sigrid',
    Gear: 'Sigrid - CDMG, Rest - Speed'
  },
  {
    Id: 60,
    Pos_1: 'onyx',
    Pos_2: 'aira',
    Pos_3: 'nini',
    Pos_4: 'erusha',
    Pos_5: 'dominique',
    Formation: 'Defense',
    Mode: 'GR Gaia',
    Treasure: 'Glass Cannon',
    Explanation: '{20-Dec-2024} Aira must be highest HP to take shackle.',
    Subs: 'Ult: Onyx ult full fight, or alternate between Onyx & Erusha ults',
    Gear: 'Erusha + Dominique - CDMG, Rest - Speed'
  },
  {
    Id: 61,
    Pos_1: 'nini',
    Pos_2: 'beleth',
    Pos_3: 'eve',
    Pos_4: 'hazel',
    Pos_5: 'lilith',
    Formation: 'Defense',
    Mode: 'GR Gaia',
    Treasure: "Falcon Hunter's Glove",
    Explanation:
      '{23-Dec-2024} Beleth must be highest HP to take shackle. Hazel must be highest ATK.',
    Subs: 'Ult: Hazel ult first, then swap to Beleth ults till end',
    Gear: 'Gear: Hazel - CDMG or ATK, Rest - Speed (HP on Beleth if necessary)'
  },
  {
    Id: 62,
    Pos_1: 'joanne',
    Pos_2: 'dominique',
    Pos_3: 'onyx',
    Pos_4: 'garnet',
    Pos_5: 'erusha',
    Formation: 'Defense',
    Mode: 'GR Gaia',
    Treasure: 'Glass Cannon',
    Explanation: '{23-Dec-2024} Onyx must be highest HP to take shackle.',
    Subs: 'Ult: Joanne ult first, then swap to Erusha ults till end. ',
    Gear: 'Gear: Erusha, Dominique - CDMG, Rest - Speed'
  },
  {
    Id: 63,
    Pos_1: 'catherine-radiance',
    Pos_2: 'ayame',
    Pos_3: 'yuria',
    Pos_4: 'lute',
    Pos_5: 'larimar',
    Formation: 'Basic',
    Mode: 'GR Gaia',
    Treasure: "Protector's Shield",
    Explanation:
      "{23-Dec-2024} Sakuyo (Inferno) replacement comp for those who don't have her. Cath (Radiance) must be highest HP to take shackle. Lute must be highest DEF.",
    Subs: 'Ult: Lute',
    Gear: 'Gear: Lute - CDMG, Rest - Speed'
  },
  {
    Id: 64,
    Pos_1: 'eve',
    Pos_2: 'rebecca',
    Pos_3: 'joanne',
    Pos_4: 'garnet',
    Pos_5: 'cherrie',
    Formation: 'Basic',
    Mode: 'GR Gaia',
    Treasure: "Falcon Hunter's Glove",
    Explanation:
      "{23-Dec-2024} Sigrid replacement comp for those who don't have her. Joanne must be highest HP to take shackle. High ascension on Eve artifact may be required for survival.",
    Subs: 'Ult: Joanne first, then swap to Cherrie ults till end.',
    Gear: "Gear: Cherrie - ATK, Rest - Speed (HP on Joanne if she's dying)"
  },
  {
    Id: 65,
    Pos_1: 'garnet',
    Pos_2: 'aira',
    Pos_3: 'vivienne',
    Pos_4: 'dominique',
    Pos_5: 'larimar',
    Formation: 'Defense',
    Mode: 'GR Gaia',
    Treasure: 'Lunatic Blade',
    Explanation:
      '{Old} High investment, no healer team. Aira cage tank. Aira/Larimar mana control, needs Origin arti or at least 2pc Spd to control mana well.',
    Subs: 'Ult: Dominique',
    Gear: 'Gear: Dominique - CDMG, Aira - Speed/HP, Rest - Speed | Investment: Aira Origin artifact (beneficial)'
  },
  {
    Id: 66,
    Pos_1: 'yuria',
    Pos_2: 'catherine-radiance',
    Pos_3: 'lilith',
    Pos_4: 'larimar',
    Pos_5: 'dominique',
    Formation: 'Basic',
    Mode: 'GR Rudra',
    Treasure: 'Lunatic Blade / Snake Headed Statue',
    Explanation:
      '#1 team. Dominique carry. Cleanse - Yuria, Stun - Larimar, Needs Catherine (Radiance) shields for survival.',
    Subs: 'Ult - Dominique.',
    Gear: 'Gear: Dominique - CDMG, Rest - Speed. | Lilith works even at low ascension to make this team do more dmg than other options.'
  },
  {
    Id: 67,
    Pos_1: 'eileen',
    Pos_2: 'haru',
    Pos_3: 'beleth',
    Pos_4: 'eve',
    Pos_5: 'sigrid',
    Formation: 'Assault',
    Mode: 'GR Rudra',
    Treasure: 'Falcon Hunters Gloves',
    Explanation:
      'Sigrid Carry team variant. Sigrid needs highest Attack. Cleanse - Beleth, Stun - Haru.',
    Subs: 'Ult: Sigrid',
    Gear: 'Gear: Sigrid - CDMG (Attack if needed), Haru - CDMG/Spd, Eileen - HP if needed to survive, Rest - Speed.'
  },
  {
    Id: 68,
    Pos_1: 'onyx',
    Pos_2: 'miriam',
    Pos_3: 'kanna',
    Pos_4: 'sakuyo',
    Pos_5: 'edith',
    Formation: 'Assault',
    Mode: 'GR Rudra',
    Treasure: 'Fairy Idol',
    Explanation:
      'Edith Carry Fairy team variant. Cleanse - Onyx, Stun - Onyx/Edith.',
    Subs: 'Ult - Onyx',
    Gear: 'Gear: Edith, Miriam - CDMG, Kanna - HP if needed to survive, Rest - Speed.'
  },
  {
    Id: 69,
    Pos_1: 'daphne',
    Pos_2: 'beleth',
    Pos_3: 'eve',
    Pos_4: 'talia',
    Pos_5: 'lizelotte',
    Formation: 'Assault',
    Mode: 'GR Rudra',
    Treasure: 'Falcon Hunters Gloves',
    Explanation:
      'Standard backup option for lower investment accounts. Cleanse - Beleth & Talia, Stun- Daphne. Lizelotte needs highest Attack.',
    Subs: 'Ult - Lizelotte',
    Gear: 'Gear: Lizelotte - CDMG, Rest - Speed'
  },
  {
    Id: 70,
    Pos_1: 'onyx',
    Pos_2: 'haru',
    Pos_3: 'tasha',
    Pos_4: 'sakuyo',
    Pos_5: 'sigrid',
    Formation: 'Basic',
    Mode: 'GR Rudra',
    Treasure: 'Beast Idol',
    Explanation:
      'Sigrid Carry Beast team variant. Cleanse - Onyx, Stun - Onyx/Haru. Dispel - Tasha/Sigrid',
    Subs: 'Ult - Sigrid',
    Gear: 'Gear: Sigrid, Tasha - CDMG, Sakuyo, Haru - CDMG/Speed, Onyx - Speed.'
  },
  {
    Id: 71,
    Pos_1: 'beleth',
    Pos_2: 'eve',
    Pos_3: 'garnet',
    Pos_4: 'flex',
    Pos_5: 'linzy-thanatos',
    Formation: 'Assault',
    Mode: 'GR Rudra',
    Treasure: 'Falcon Hunters Gloves',
    Explanation:
      'Linzy (Thanatos) Carry team. Linzy (Thanatos) needs highest Attack. Damage potential similar to Edith teams. Cleanse - Beleth, Stun - Daphne/Lute.',
    Subs: 'Ult - Eve, Beleth, Linzy (Thantos) (last Ult phase)',
    Gear: 'Gear: Linzy (Thantos) - CDMG, Rest - Speed. Flex: Daphne/Lute'
  },
  {
    Id: 72,
    Pos_1: 'eve',
    Pos_2: 'wheri',
    Pos_3: 'yuria',
    Pos_4: 'catherine-radiance',
    Pos_5: 'lizelotte',
    Formation: 'Assault',
    Mode: 'IR Cheese',
    Treasure: '',
    Explanation: '{06-Jan-2025} Ensure Lizelotte gets Eve Bond.',
    Subs: 'Ult - Lizelotte all the way, if dying can use Yuria/Wheri ult as needed. Ayame can replace Wheri if invested enough for more DPS',
    Gear: 'Gear: Lizelotte - CDMG, Catherine (Radiance) - Attack/Speed, Rest Speed.'
  },
  {
    Id: 73,
    Pos_1: 'wheri',
    Pos_2: 'sigrid',
    Pos_3: 'larimar',
    Pos_4: 'yuria',
    Pos_5: 'catherine-radiance',
    Formation: 'Assault',
    Mode: 'IR Cheese',
    Treasure: '',
    Explanation:
      '{01-Dec-2024} Team based on current/last iteration of the Boss Buff/Debuffs. Level 300~350 Sync clear.',
    Subs: 'Ult: Catherine (Radiance) and then Sigrid.',
    Gear: 'Gear: Catherine (Radiance) - Anni Attack, Sigrid - CDMG, Rest - Speed'
  },
  {
    Id: 74,
    Pos_1: 'beleth',
    Pos_2: 'yuria',
    Pos_3: 'larimar',
    Pos_4: 'eve',
    Pos_5: 'catherine-radiance',
    Formation: 'Assault',
    Mode: 'IR Cheese',
    Treasure: '',
    Explanation:
      '{01-Dec-2024} Team based on current/last iteration of the Boss Buff/Debuffs. Level 400~ Sync clear. If you are overhealing, can swap out Beleth with Linzy (Thanatos)',
    Subs: 'Ult: Catherine (Radiance) and then Beleth. If using Linzy (Thanatos) Ult: Catherine (Radiance), then Eve once and then Linzy (Thanatos)',
    Gear: 'Gear: Catherine (Radiance) - Anni Attack, Linzy (Thanatos) - CDMG,  Rest - Speed'
  },
  {
    Id: 75,
    Pos_1: 'catherine-radiance',
    Pos_2: 'yuria',
    Pos_3: 'larimar',
    Pos_4: 'sakuyo-inferno',
    Pos_5: 'tasha',
    Formation: 'Defense',
    Mode: 'IR Cookie',
    Treasure: '',
    Explanation:
      '{20-Dec-2024} Team based on current/last iteration of the Boss Buff/Debuffs',
    Subs: 'Ult: Sakuyo-Inferno if invested, otherwise use Tasha',
    Gear: 'Sakuyo (Inferno) + Tasha - CDMG, Rest - Speed'
  },
  {
    Id: 76,
    Pos_1: 'hazel',
    Pos_2: 'onyx',
    Pos_3: 'garnet',
    Pos_4: 'dominique',
    Pos_5: 'erusha',
    Formation: 'Assault',
    Mode: 'IR Tabby',
    Treasure: '',
    Explanation:
      '{07-Dec-2024} Team based on current/last iteration of the Boss Buff/Debuffs',
    Subs: 'Ult: Hazel first, then Erusha for the remainder of fight. Onyx ult for survival if necessary.\nSub: If your Hazel is low investment (below Et+ with L+ artifact), you can sub her out and use Lilith instead, but swap Lilith and Garnet positions.',
    Gear: 'Gear: Erusha - CDMG; Hazel, Dominique - ATK (put 1pc Anni ATK on each one in slot 3/4 for lifesteal); Onyx, Ganet - Speed'
  },
  {
    Id: 77,
    Pos_1: 'yuria',
    Pos_2: 'garnet',
    Pos_3: 'catherine-radiance',
    Pos_4: 'larimar',
    Pos_5: 'linzy-thanatos',
    Formation: 'Defense',
    Mode: 'IR Tabby',
    Treasure: '',
    Explanation:
      '{07-Dec-2024} Team based on current/last iteration of the Boss Buff/Debuffs',
    Subs: "Ult: Yuria (if souls are dying) or Garnet (if souls aren't dying); Linzy ult near the end before final sentence expires",
    Gear: 'Gear: Linzy (Thanatos) - CDMG, Rest - Speed'
  },
  {
    Id: 78,
    Pos_1: 'garnet',
    Pos_2: 'nini',
    Pos_3: 'lilith',
    Pos_4: 'onyx',
    Pos_5: 'erusha',
    Formation: 'Assault',
    Mode: 'IR Tofu',
    Treasure: '',
    Explanation:
      'Team based on current/last iteration of the Boss Buff/Debuffs',
    Subs: 'Ult: Erusha',
    Gear: 'Gear: Erusha - CDMG, Rest - Speed'
  },
  {
    Id: 79,
    Pos_1: 'eve',
    Pos_2: 'yuria',
    Pos_3: 'ayame',
    Pos_4: 'lizelotte',
    Pos_5: 'larimar',
    Formation: 'Defense',
    Mode: 'IR Tofu',
    Treasure: '',
    Explanation:
      'Alternative Team based on current/last iteration of the Boss Buff/Debuffs.',
    Subs: 'Ult: Lizelotte',
    Gear: "Gear: Lize - CDMG, Rest - Speed / Use ATK on Lize if she isn't getting the Eve buff"
  },
  {
    Id: 80,
    Pos_1: 'wheri',
    Pos_2: 'lilith',
    Pos_3: 'onyx',
    Pos_4: 'hazel',
    Pos_5: 'dominique',
    Formation: 'Defense',
    Mode: 'WR Behemoth',
    Treasure: '',
    Explanation:
      'Partners: Wheri (Clara - Main) (Aira - Sub) ; Lilith (Larimar - Main) (Claudia - Sub) ; Onyx (Yuria - Main) (Mephistopheles - Sub) ; Dominique (Naiah - Main) (Kanna - Sub) ; Hazel (Lizelotte - Main) (Vivienne - Sub). If listed Subs are low ascension, you can replace them with higher ascension souls that provide the same buff',
    Subs: 'Ult:',
    Gear: 'Gear: Hazel - CDMG, Rest - Speed'
  },
  {
    Id: 81,
    Pos_1: 'violette',
    Pos_2: 'yuria',
    Pos_3: 'linzy-thanatos',
    Pos_4: 'larimar',
    Pos_5: 'joanne',
    Formation: 'Defense',
    Mode: 'WR Behemoth',
    Treasure: '',
    Explanation:
      'Partners: Linzy (Thanatos) (Otoha - Main) (Nini - Sub) ; Violette (Linzy - Main) (Eve - Sub) ; Yuria (Catherine - Main) (Lute - Sub) ; Joanne (Soonie - Main) (Kanna - Sub) ; Larimar (Naiah - Main) (Erusha - Sub). If listed Subs are low ascension, you can replace them with higher ascension souls that provide the same buff',
    Subs: 'Ult: Joanne Ult (2.:42~), Larimar Ult(1:50~), Violette Ult (0:56~), Yuria (0:37~) and then Linzy (Thanatos)',
    Gear: 'Gear: Linzy (Thantos) - CDMG, Rest - Speed'
  }
];
