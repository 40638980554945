import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { Col, Row, Table } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';
import {
  faShield,
  faHandFist,
  faBriefcaseMedical,
  faVirus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const AFKGuidesBeg: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Beginner guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_beginner.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Beginner guide</h1>
          <h2>
            A beginner guide for AFK Journey that will teach you everything you
            need to know about the game!
          </h2>
          <p>
            Last updated: <strong>09/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Introduction" />
        <StaticImage
          src="../../../images/afk/generic/beginner_0.webp"
          alt="Guides"
        />
        <p>
          AFK Journey is a{' '}
          <strong>top-down open world auto-chess style gacha game</strong> with
          a combat system revolving around activatable Ultimates and
          automatically activated skills. The game focuses heavily on
          teambuilding, team variety, and utilizing interesting character
          combinations to take on the content ahead of you. The game is
          noteworthy for its colorful cast of characters and unique artstyle,
          something that’s been imported from its sister game:{' '}
          <strong>
            AFK Arena, but this time in a 3D setting instead of 2D sprites.
          </strong>
        </p>
        <p>
          AFK Journey also concentrates on making the game more accessible to
          new players, providing multiple ways of getting your hands on the
          characters you want and their dupes to upgrade them further. Even the
          feared Celestial and Hypogean factions, who are usually a rarity above
          other units, are currently obtainable from shops for in-game currency.
        </p>
        <p>
          For the people who are looking for a chill idle game with easy
          progression, surprisingly extensive team building and a more upbeat
          story,{' '}
          <strong>
            AFK Journey appears to push an agenda that revolves around allowing
            the players breathing room{' '}
          </strong>
          and more comfortable pushing of content. With a competitive PVP mode
          and <strong>leaderboard for those who want to flex on others.</strong>
        </p>
        <p>
          <strong>
            To note is that this is not a ‘main game’ as you will be locked out
            of certain parts of progression while you idle your resources
          </strong>
          , and you realistically only have to log-in once or twice a day to do
          your things outside of potential events. Not that this will stop you
          or us from sitting behind the screen all day watching our silly little
          characters beat up a few bandits over and over.
        </p>
        <p>
          If all that’s gotten you interested, you’ve stumbled right into the
          beginner’s guide. Let us take you through the basics of the game.
        </p>
        <SectionHeader title="Characters" />
        <StaticImage
          src="../../../images/afk/generic/beginner_3.webp"
          alt="Guides"
        />
        <p>
          If you've played AFK Arena, you will see a lot of familiar faces in
          AFK Journey, but the character system has quite a lot of differences
          between the games.
        </p>
        <h5>Summary</h5>
        <ul className="more-spacing">
          <li>
            Characters belong to one of 6 factions: <AFKItem name="Maulers" />{' '}
            <AFKItem name="Lightbearers" /> <AFKItem name="Wilders" />{' '}
            <AFKItem name="Graveborn" /> <AFKItem name="Celestial" />{' '}
            <AFKItem name="Hypogean" />,
          </li>
          <ul>
            <li>
              <AFKItem name="Celestial" /> <AFKItem name="Hypogean" /> are the
              'Angel / Devil' faction of the game and they are harder to obtain
              and ascend,
            </li>
          </ul>
          <li>
            Characters belong to one of 6 classes:{' '}
            <StaticImage
              src="../../../images/afk/icons/class_mage.png"
              alt="Mage"
              className="faction-icon"
            />{' '}
            <strong>Mage</strong>{' '}
            <StaticImage
              src="../../../images/afk/icons/class_mark.png"
              alt="Marksman"
              className="faction-icon"
            />{' '}
            <strong>Marksman</strong>{' '}
            <StaticImage
              src="../../../images/afk/icons/class_rogue.png"
              alt="Rogue"
              className="faction-icon"
            />{' '}
            <strong>Rogue</strong>{' '}
            <StaticImage
              src="../../../images/afk/icons/class_support.png"
              alt="Support"
              className="faction-icon"
            />{' '}
            <strong>Support</strong>{' '}
            <StaticImage
              src="../../../images/afk/icons/class_tank.png"
              alt="Tank"
              className="faction-icon"
            />{' '}
            <strong>Tank</strong>{' '}
            <StaticImage
              src="../../../images/afk/icons/class_warrior.png"
              alt="Warrior"
              className="faction-icon"
            />{' '}
            <strong>Warrior</strong>,
          </li>
          <li>
            Characters in this game are split into <AFKItem name="A-Level" />{' '}
            and two <AFKItem name="S-Level" />,
          </li>
          <ul>
            <li>
              <AFKItem name="A-Level" /> characters are easier to obtain from
              gacha and also cost less in various shops compared to{' '}
              <AFKItem name="S-Level" /> ones,
            </li>
            <li>
              But this doesn't make them weaker - among the{' '}
              <AFKItem name="A-Level" /> characters you can actually find some
              of the best characters in the game,
            </li>
            <li>
              Both <AFKItem name="A-Level" /> and <AFKItem name="S-Level" /> can
              be <strong>ascended to the highest tier</strong> (Supreme +),
            </li>
          </ul>
          <li>
            <strong>The fodder system has been removed</strong> and instead, you
            just need dupes of the character you want to ascend or Acorns
            (faction-tied currency that you get from shops and pulls),
          </li>
          <li>
            Each character has 3 skills which will automatically level up
            whenever they reach the required level,
          </li>
          <li>
            A character needs to reach the{' '}
            <strong className="legendary">Legendary+</strong> tier to unlock{' '}
            <strong>Hero Focus</strong> that gives them bonus to one of their
            stats or gives a brand new effect,
          </li>
          <li>
            A character needs to reach the{' '}
            <strong className="mythic">Mythic+</strong> tier to unlock{' '}
            <strong>Exclusive Weapon</strong> which gives them a new passive
            skill (that can be upgraded up by leveling the EX Weapon),
          </li>
          <li>
            A character needs to reach the{' '}
            <strong className="supreme">Supreme+</strong> tier to unlock{' '}
            <strong>Skill Enhancement</strong> that give an additional effect to
            one of the their skills,
          </li>
          <li>
            <strong>
              Characters don't need to be equipped with gear anymore
            </strong>
            , as the system has been replaced by Class-based Equipment system -
            you simply gather gear that the Class can wear and every character
            within the Class will automatically wear it in the battle,
          </li>
          <li>
            Thanks to the <strong>Resonating Hall</strong>, you just need to
            level 5 characters and automatically every other character on your
            account will be leveled up to the lowest level among those 5 - no
            need to reset characters or switch them around with limited spots
            like in AFK Arena,
          </li>
          <li>
            Also at any point, you can{' '}
            <strong>change the top 5 characters in the Resonating Hall</strong>{' '}
            by simply dragging them around.
          </li>
        </ul>
        <h5>Factions</h5>
        <div className="afk-factions-table">
          <div className="faction-div Lightbearer">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_light.png"
                alt="Lightbearers"
                className="faction-icon"
              />{' '}
              Lightbearers
            </h5>
            <div className="info">
              <p>
                <strong>The Lightbearers</strong> are heroes who serve in the
                name of Dura. They mainly fight with Hypogeans, Maulers, and
                Graveborns. Their main goal is to restore the land of Esperia
                from evil.
              </p>
              <div className="heroes">
                <p>Example heroes:</p>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="rowan"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="vala"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="lucius"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="faction-div Mauler">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_mauler.png"
                alt="Mauler"
                className="faction-icon"
              />{' '}
              Maulers
            </h5>
            <div className="info">
              <p>
                <strong>The Maulers</strong> are a warrior culture who believe
                strength is everything, with the worth of something or someone
                judged by might and usability. Though some have fought for the
                Hypogeans according to some Peaks of Time stories, this is
                relatively rare and as a whole are still fighting on the side of
                Dura.
              </p>
              <div className="heroes">
                <p>Example heroes:</p>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="brutus"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="smokey-and-meerky"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="kruger"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="satrana"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="faction-div Wilder">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_wilder.png"
                alt="Wilder"
                className="faction-icon"
              />{' '}
              Wilders
            </h5>
            <div className="info">
              <p>
                <strong>The Wilders</strong> hold a magical connection with the
                forest and with Yggdrasil, which is often exhibited in their
                skills. It is said that Dura planted Yggdrasil, and left the
                spring of life as a parting gift to the Wilders.
              </p>
              <div className="heroes">
                <p>Example heroes:</p>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="eironn"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="hewynn"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="arden"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="parisa"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="faction-div Graveborn">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_grave.png"
                alt="Graveborn"
                className="faction-icon"
              />{' '}
              Graveborn
            </h5>
            <div className="info">
              <p>
                <strong>The Graveborn</strong> are heroes who serve in death,
                revived as the Undead, and who seek immortality through dark
                arts and necromancy. It is not confirmed however if the
                Graveborn serve the Hypogeans.
              </p>
              <div className="heroes">
                <p>Example heroes:</p>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="cecia"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="thoran"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="silvina"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="niru"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="faction-div Celestial">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_cele.png"
                alt="Celestial"
                className="faction-icon"
              />{' '}
              Celestials
            </h5>
            <div className="info">
              <p>
                <strong>The Celestials</strong> are a main faction in AFK Arena,
                and also in Journey. Their main goal is to destroy the Hypogeans
                and any remaining Darkness plaguing Esperia.
              </p>
              <div className="heroes">
                <p>Example heroes:</p>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="dionel"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="scarlita"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="faction-div Hypogean">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_hypo.png"
                alt="Hypogean"
                className="faction-icon"
              />{' '}
              Hypogeans
            </h5>
            <div className="info">
              <p>
                <strong>The Hypogeans</strong> are the main villains of AFK
                Arena, and maybe they serve the same role in Journey. Their main
                goal is to destroy any of Dura's creations by spreading evil all
                across Esperia.
              </p>
              <div className="heroes">
                <p>Example heroes:</p>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="berial"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="reinier"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <p>
          If you want to check all characters that are available in the game, go
          here:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Characters list"
            link="/afk-journey/characters"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_characters.jpg"
                alt="Characters list"
              />
            }
          />
        </Row>
        <SectionHeader title="Resonating Hall" />
        <StaticImage
          src="../../../images/afk/generic/beginner_4.webp"
          alt="Guides"
        />
        <p>
          <strong>
            Resonating Hall takes the best elements of various idle games and
            improves them
          </strong>
          , so the players don't need to waste their time while managing their
          characters in AFK Journey. And you unlock it during the early stage of
          the game, so you can quickly get all the benefits from the system.
          Here's how it works:
        </p>
        <ul className="more-spacing">
          <li>
            Upon unlocking the Resonating Hall, your 5 highest leveled heroes
            will become <strong>Hands of Resonance</strong>,
          </li>
          <ul>
            <li>
              Other heroes who you have leveled before unlocking the Resonating
              Hall will have their level reset to and all used resources will be
              refunded.
            </li>
          </ul>
          <li>
            <strong>
              The Resonance Level is determined by the lowest level member of
              the Hands of the Resonance
            </strong>
            . All Resonance heroes owned by you will be leveled up to this
            level,
          </li>
          <li>Only Hands of Resonance can be leveled up,</li>
          <ul>
            <li>
              But nothing stops you from swapping normal heroes with those in
              the Hand position - you can do it at anytime without any costs
              attached,
            </li>
          </ul>
          <li>
            The level of the Hands of Resonance cannot exceed the Resonance
            Level <strong>by more than 10 levels</strong>,
          </li>
        </ul>
        <p>
          The improved systems means that you don't have to worry about making
          any mistakes with leveling characters as you can always 'fix'
          everything on the fly. Did you level the wrong DPS as your carry? No
          problem! Simply switch it with another one and he will instantly
          become your highest leveled character.
        </p>
        <h5>Leveling up - before 240</h5>
        <ul>
          <li>
            To level up a character you will need <AFKItem name="Gold" /> and{' '}
            <AFKItem name="Experience" /> - both can be obtained from AFK Stages
            and as various rewards,
          </li>
          <li>
            Still, every 10 levels, on top of <AFKItem name="Gold" /> and{' '}
            <AFKItem name="Experience" /> you will also need{' '}
            <AFKItem name="Essence" />. And Essence will usually be the material
            you will lack the most and you will have to 'idle' for hours (and
            later days) to obtain enough of it to push a character through the
            level 10 upgrade.
          </li>
        </ul>
        <h5>Leveling up - post 240</h5>
        <p>
          This system changes once you reach the <strong>240 level cap</strong>{' '}
          on your top 5 characters and now instead of leveling them, you will
          level the Resonance Hall. And the level cap will increase by 5 for
          every <strong className="supreme">Supreme+</strong> character you
          posses. You will also need only <AFKItem name="Essence" /> moving
          forward and on top of that you will obtain <AFKItem name="Diamonds" />{' '}
          every time you level past level 300.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
        <SectionHeader title="Ascension" />
        <StaticImage
          src="../../../images/afk/generic/beginner_8.webp"
          alt="Guides"
        />
        <p>
          As mentioned earlier, in AFK Journey the concept of fodder has been
          removed and the whole{' '}
          <strong>Ascension system has been simplified</strong>. Now, to ascend
          a character you either need to gather their dupes or faction-tied
          Acorns (which can be obtained from various shops or from pulling). The
          table below shows all the currently available tiers in the game and
          the requirements to reach them.
        </p>
        <h5>Ascension requirements</h5>
        <Table striped bordered responsive className="afk-ascension">
          <thead>
            <tr>
              <th>Current Tier</th>
              <th>Target Tier</th>
              <th>
                <AFKItem name="A-Level" />
              </th>
              <th>
                <AFKItem name="S-Level" />
              </th>
              <th>
                <StaticImage
                  src="../../../images/afk/icons/faction_cele.png"
                  alt="Celestial"
                  className="faction-icon"
                />{' '}
                <StaticImage
                  src="../../../images/afk/icons/faction_hypo.png"
                  alt="Hypogean"
                  className="faction-icon"
                />{' '}
                <span>Celestial / Hypogean</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="tier elite">Elite</td>
              <td className="tier elite">Elite+</td>
              <td className="info">
                <div className="combined">
                  <strong>1x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_a.png"
                    alt="A-Level"
                  />
                </div>
              </td>
              <td className="info">-</td>
              <td className="info">-</td>
            </tr>
            <tr>
              <td className="tier elite">Elite+</td>
              <td className="tier epic">Epic</td>
              <td className="info">
                <div className="combined">
                  <strong>3x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_a.png"
                    alt="A-Level"
                  />
                </div>
              </td>
              <td className="info">-</td>
              <td className="info">-</td>
            </tr>
            <tr>
              <td className="tier epic">Epic</td>
              <td className="tier epic">Epic+</td>
              <td className="info">
                <div className="combined">
                  <strong>4x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_a.png"
                    alt="A-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>1x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_s.png"
                    alt="S-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>1x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_c.png"
                    alt="Celestial / Hypogean"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="tier epic">Epic+</td>
              <td className="tier legendary">Legendary</td>
              <td className="info">
                <div className="combined">
                  <strong>5x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_a.png"
                    alt="A-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>50x</strong> <AFKItem name="Acorn" hideName />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>1x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_c.png"
                    alt="Celestial / Hypogean"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="tier legendary">Legendary</td>
              <td className="tier legendary">Legendary+</td>
              <td className="info">
                <div className="combined">
                  <strong>8x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_a.png"
                    alt="A-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>2x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_s.png"
                    alt="S-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>1x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_c.png"
                    alt="Celestial / Hypogean"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="tier legendary">Legendary+</td>
              <td className="tier mythic">Mythic</td>
              <td className="info">
                <div className="combined">
                  <strong>10x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_a.png"
                    alt="A-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>100x</strong> <AFKItem name="Acorn" hideName />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>2x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_c.png"
                    alt="Celestial / Hypogean"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="tier mythic">Mythic</td>
              <td className="tier mythic">Mythic+</td>
              <td className="info">
                <div className="combined">
                  <strong>12x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_a.png"
                    alt="A-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>2x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_s.png"
                    alt="S-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>2x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_c.png"
                    alt="Celestial / Hypogean"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="tier mythic">Mythic+</td>
              <td className="tier supreme">Supreme</td>
              <td className="info">
                <div className="combined">
                  <strong>200x</strong> <AFKItem name="Acorn" hideName />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>200x</strong> <AFKItem name="Acorn" hideName />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>2x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_c.png"
                    alt="Celestial / Hypogean"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="tier supreme">Supreme</td>
              <td className="tier supreme">Supreme+</td>
              <td className="info">
                <div className="combined">
                  <strong>20x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_a.png"
                    alt="A-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>2x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_s.png"
                    alt="S-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>4x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_c.png"
                    alt="Celestial / Hypogean"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="para-info" colSpan={5}>
                To upgrade your characters past{' '}
                <strong className="supreme">Supreme+</strong> you need to obtain
                25 characters of that tier. Also, you no longer get skill
                upgrade or unlocks past this point. Instead, the base stats are
                increased (2.5-3% per Paragon level), but on top of that a new
                type of stats called Rivalry stats are added (they only are
                active in PVP modes, but really strong there).
              </td>
            </tr>
            <tr>
              <td className="tier supreme">Supreme+</td>
              <td className="tier paragon">Paragon 1</td>
              <td className="info">
                <div className="combined">
                  <strong>45x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_a.png"
                    alt="A-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>6x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_s.png"
                    alt="S-Level"
                  />
                </div>
              </td>
              <td className="info">
                <div className="combined">
                  <strong>4x</strong>{' '}
                  <StaticImage
                    src="../../../images/afk/generic/asc_c.png"
                    alt="Celestial / Hypogean"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <p>
          Also, to unlock the ability to tier up a character to the{' '}
          <strong>Paragon 1</strong> tier, you need to posses 25 Supreme+
          heroes. There are also Paragon 2, 3 and 4 levels and to check the
          requirements for them visit the{' '}
          <Link to="/afk-journey/guides/ascension-info">Ascension Info</Link>{' '}
          guide.
        </p>
        <h5>Summary</h5>
        <ul>
          <li>
            To ascend an <AFKItem name="A-Level" /> character Supreme+ tier you
            will need the original copy + <strong>63 dupes</strong> (64 in
            total) and <strong>200 Faction Acorns</strong>,
          </li>
          <li>
            To ascend an <AFKItem name="S-Level" /> character to Supreme+ you
            will need the original copy + <strong>7 dupes</strong> (8 in total)
            and <strong>350 Faction Acorns</strong>.
          </li>
          <li>
            To ascend <AFKItem name="Celestial" /> and{' '}
            <AFKItem name="Hypogean" /> characters to Supreme+ you will need the
            original copy + <strong>13 dupes</strong> (14 in total).
          </li>
        </ul>
        <h5>Unlocks</h5>
        <ul>
          <li>
            A character needs to reach the{' '}
            <strong className="legendary">Legendary+</strong> tier to unlock{' '}
            <strong>Hero Focus</strong> that gives them bonus to one of their
            stats or gives a brand new effect,
          </li>
          <li>
            A character needs to reach the{' '}
            <strong className="mythic">Mythic+</strong> tier to unlock{' '}
            <strong>Exclusive Weapon</strong> which gives them a new passive
            skill (that can be upgraded up by leveling the EX Weapon),
          </li>
          <li>
            A character needs to reach the{' '}
            <strong className="supreme">Supreme+</strong> tier to unlock{' '}
            <strong>Skill Enhancement</strong> that give an additional effect to
            one of the their skills.
          </li>
        </ul>
        <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
        <SectionHeader title="Equipment" />
        <StaticImage
          src="../../../images/afk/generic/beginner_5.webp"
          alt="Guides"
        />
        <p>
          <strong>
            Characters in AFK Journey don't need to equip gear anymore
          </strong>
          , as the system has been replaced by Class-based Equipment system -
          you simply gather gear that the Class can wear and every character
          within the Class will automatically wear it in the battle. This makes
          gear management not only much easier to handle, but also it allows you
          to try different characters and easily swap them before the battle
          when you need their specific skillset.
        </p>
        <Row xs={1} xxl={2}>
          <Col>
            <h5>Equipment pieces</h5>
            <StaticImage
              src="../../../images/afk/generic/beginner_7.webp"
              alt="Guides"
            />
            <p>
              Each class can equip 6 different pieces of equipment:{' '}
              <strong>Weapon, Gloves, Accessory, Helm, Armor, and Boots</strong>
              . Each piece also comes with different stats - some are more
              offensive, some more defensive - and this means that for different
              classes,{' '}
              <strong>
                you want to prioritize upgrading different pieces first
              </strong>
              .
            </p>
          </Col>
          <Col>
            <h5>Equipment upgrading</h5>
            <StaticImage
              src="../../../images/afk/generic/beginner_6.webp"
              alt="Guides"
            />
            <p>
              While you can drop higher item level equipment from the AFK stages
              or obtain them as rewards from quests, you can also{' '}
              <strong>Forge and upgrade</strong> the pieces manually using{' '}
              <strong>Casting Shards</strong> you obtain from Recycling gear you
              no longer need.
            </p>
            <p>
              The level of the equipment you can forge is tied to your Resonance
              level.
            </p>
          </Col>
        </Row>
        <hr />
        <p>
          To find out the best priority of equipment upgrades for each class and
          more information about the Equipment, check our other guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Equipment & upgrade priority"
            link="/afk-journey/guides/equipment-and-priority"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_eq.jpg"
                alt="Characters list"
              />
            }
          />
        </Row>
        <SectionHeader title="Exclusive Equipment" />
        <StaticImage
          src="../../../images/afk/generic/beginner_9.webp"
          alt="Guides"
        />
        <p>
          <strong>
            When you upgrade a character to their{' '}
            <strong className="mythic">Mythic+</strong> tier, their Exclusive
            Item becomes available to unlock and upgrade
          </strong>
          . This Exclusive Item grants a set of stats tailored towards the
          character it is for and{' '}
          <strong>unlocks a 4th ability in their kit</strong> (known as an EX or
          Exclusive Skill).
        </p>
        <Table striped bordered responsive className="afk-ex">
          <thead>
            <tr>
              <th>EX Levels</th>
              <th>New passive?</th>
              <th>Stone (total)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>EX+1 to EX+5</td>
              <td>
                <strong className="green">Yes</strong>
              </td>
              <td>
                <div className="combined">
                  150x <AFKItem name="Tidal Essence" />
                </div>
              </td>
            </tr>
            <tr>
              <td>EX+6 to EX+10</td>
              <td>
                <strong className="green">Yes</strong>
              </td>
              <td>
                <div className="combined">
                  75x <AFKItem name="Temporal Essence" />
                </div>
              </td>
            </tr>
            <tr>
              <td>EX+11 to EX+15</td>
              <td>
                <strong className="green">Yes</strong>
              </td>
              <td>
                <div className="combined">
                  200x <AFKItem name="Temporal Essence" />
                </div>
              </td>
            </tr>
            <tr>
              <td>EX+16 to EX+20</td>
              <td>
                <strong className="red">No</strong>
              </td>
              <td>
                <div className="combined">
                  125x <AFKItem name="Twilight Essence" />
                </div>
              </td>
            </tr>
            <tr>
              <td>EX+21 to EX+25</td>
              <td>
                <strong className="red">No</strong>
              </td>
              <td>
                <div className="combined">
                  500x <AFKItem name="Twilight Essence" />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <p>
          To learn more about EX weapons and which characters should you
          prioritize when it comes to upgrading it, check this guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Exclusive Weapon priority"
            link="/afk-journey/guides/exclusive-weapon-priority"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_ex.jpg"
                alt="Exclusive Weapon priority"
              />
            }
          />
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
        <SectionHeader title="Gacha" />
        <p>
          Now that you know everything about characters, it's time to learn how
          to obtain them. Currently, there are three types of banners available
          in the game and you can find information about them below.
        </p>
        <div className="afk-modes">
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/beginner_10.webp"
              alt="Guides"
            />
            <h5>All-Hero Recruitment</h5>
            <p>
              This is the standard banner of the game where from you can get
              every non-Celestial/Hypogean character available in the game. You
              can pull on this banner using <AFKItem name="Invite Letter" /> and{' '}
              <AFKItem name="Diamonds" />. It costs 300 Diamonds to do a single
              summon, but when you do 10 of them at the same time it costs 2700
              - so you get a discount. So don't do single pulls with Diamonds.
            </p>
            <p>
              After doing 30 pulls, you will unlock the{' '}
              <strong>Wishlist System</strong> that will allow you to ‘favorite’
              two <AFKItem name="S-Level" /> and two <AFKItem name="A-Level" />{' '}
              characters per faction - after filling the wishlist, every
              character you will pull will match those on the wishlist. This
              helps you narrow down the team you want to run.
            </p>
            <p>
              As for the <strong>pity</strong> on this banner. It{' '}
              <strong>starts at 10 pulls</strong> and increases every time you
              pull an <AFKItem name="S-Level" /> character - the pity stops
              increasing at <strong>60 pulls</strong>. Also, if you're doing a
              10-pull, you will always obtain at least one A-level character.
            </p>
            <p>Rates:</p>
            <ul>
              <li>
                <AFKItem name="S-Level" /> hero -{' '}
                <strong className="green">2.05%</strong>
              </li>
              <li>
                <AFKItem name="A-Level" /> hero -{' '}
                <strong className="green">22.5%</strong>
              </li>
              <li>
                Faction Acorn x30 - <strong className="green">0.5%</strong>
              </li>
              <li>
                Faction Acorn x4 - <strong className="green">15%</strong>
              </li>
              <li>
                Faction Acorn x1 - <strong className="green">59.95%</strong>
              </li>
            </ul>
          </div>
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/banner_limited.webp"
              alt="Guides"
            />
            <h5>Rate-up Recruitment</h5>
            <p>
              This is the rate-up banner which will be used to introduce new
              characters to the game (or promote older ones). If the rate-up
              hero is <AFKItem name="S-Level" />, you are guaranteed to obtain
              them whenever you pull an S-level character. And when the rate-up
              hero is <AFKItem name="A-Level" />, you will obtain 8 copies of
              them.
            </p>
            <p>
              In the Rate-up Recruitment, the number of rate-up heroes you can
              obtain is determined by the highest tier of your other heroes.
            </p>
            <p>
              As for the pity, it's set to <strong>40</strong> and it never
              increases after you hit it, so it's very good. The rates for this
              banner also are better compared to the All-Hero Recruitmen:
            </p>
            <ul>
              <li>
                <AFKItem name="S-Level" /> hero -{' '}
                <strong className="green">3%</strong>
              </li>
              <li>
                <AFKItem name="A-Level" /> hero -{' '}
                <strong className="green">10%</strong>
              </li>
              <li>
                Omni Acorn x30 - <strong className="green">1.5%</strong>
              </li>
              <li>
                Omni Acorn x4 - <strong className="green">15%</strong>
              </li>
              <li>
                Omni Acorn x1 - <strong className="green">70.05%</strong>
              </li>
            </ul>
          </div>
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/beginner_11.webp"
              alt="Guides"
            />
            <h5>Epic Recruitment</h5>
            <p>
              This is the second banner which has better rates and a wishlist
              available from the start, but you can only use special{' '}
              <AFKItem name="Epic Invite Letter" /> to pull - you can obtain the
              Letters from shops, various modes and progressing through the main
              story.
            </p>
            <p>
              Compared to the previous banner, on the Wishlist here you can
              place only 5 characters, but it's unlocked from the moment you get
              access to the banner. The <strong>pity also starts at 10</strong>{' '}
              and increases when you pull an S-level character, but it{' '}
              <strong>caps at 30 pulls</strong> here.
            </p>
            <p>The rates for this banner are also way better:</p>
            <ul>
              <li>
                <AFKItem name="S-Level" /> hero -{' '}
                <strong className="green">5.22%</strong>
              </li>
              <li>
                <AFKItem name="A-Level" /> hero -{' '}
                <strong className="green">18.75%</strong>
              </li>
              <li>
                Faction Acorn x30 - <strong className="green">1%</strong>
              </li>
              <li>
                Faction Acorn x4 - <strong className="green">25%</strong>
              </li>
              <li>
                Faction Acorn x1 - <strong className="green">50.03%</strong>
              </li>
            </ul>
          </div>
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/beginner_12.webp"
              alt="Guides"
            />
            <h5>Stargaze</h5>
            <p>
              This is the only banner from which you can pull{' '}
              <AFKItem name="Celestial" /> and <AFKItem name="Hypogean" />{' '}
              characters, but it will take you quite a while to unlock it. As to
              get access to the Stargaze function you need to do{' '}
              <strong>400 pulls on the previous banners</strong>.
            </p>
            <p>
              You can select any <AFKItem name="Celestial" /> and{' '}
              <AFKItem name="Hypogean" /> you want here and you will have{' '}
              <strong className="green">3.25%</strong> chance to obtain them
              with each pull. Other things you can pull from the banner are:
              Diamonds, Dazzling and Ruin Stones (used to upgrade Exclusive
              Equipment) and universal Acorns. The pity is also set at{' '}
              <strong>40</strong> pulls and doesn't increase compared to the
              previous banners.
            </p>
            <p>
              What's important is that{' '}
              <strong className="red">you can't use Diamonds</strong> on this
              banner as you can only pull using{' '}
              <AFKItem name="Stellar Crystal" /> - a rare currency you can buy
              from Guild and Recruitment store (and the amount you can buy per
              month is limited).
            </p>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
        <SectionHeader title="Wishlist" />
        <StaticImage
          src="../../../images/afk/generic/beginner_13.webp"
          alt="Guides"
        />
        <p>
          The wishlist{' '}
          <strong>
            unlocks after doing 30 pulls when it comes to the All-Hero
            Recruitment banner
          </strong>{' '}
          and is available from the start for the Epic Recruitment banner.{' '}
          <strong>
            When you fully fill the wishlist, any character you pull will come
            from the Wishlist
          </strong>
          . This will help you a lot in narrowing down the heroes you want to
          obtain and smoother your progression. That's why properly setting up
          the tier list with strong heroes is very important.
        </p>
        <ul>
          <li>
            For the All-Hero Recruitment banner Wishlist, you can select 2x{' '}
            <StaticImage
              src="../../../images/afk/icons/rarity_a.png"
              alt="A-Level"
              className="faction-icon"
            />{' '}
            <strong>A-level</strong> and 2x{' '}
            <StaticImage
              src="../../../images/afk/icons/rarity_s.png"
              alt="S-Level"
              className="faction-icon"
            />{' '}
            <strong>S-level</strong> characters per faction.{' '}
          </li>
          <li>
            For the Epic Recruitment Banner the Wishlist has only 5 spots, so in
            most cases you will only fill it with{' '}
            <StaticImage
              src="../../../images/afk/icons/rarity_s.png"
              alt="S-Level"
              className="faction-icon"
            />{' '}
            <strong>S-level</strong> characters.
          </li>
          <ul>
            <li>
              Keep in mind that you can select{' '}
              <StaticImage
                src="../../../images/afk/icons/rarity_a.png"
                alt="A-Level"
                className="faction-icon"
              />{' '}
              <strong>A-level</strong> units on this Wishlist too, but you will
              always receive an Epic version of them if you pull them (this
              means that you get 4 soulstones of theirs if you already own the
              unit, a quick way to upgrade them to higher ranks).
            </li>
          </ul>
        </ul>
        <p>
          Now you might ask, what are the best units to put on both Wishlists?
          Check our other guide to find out:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Wishlist tips"
            link="/afk-journey/guides/wishlist-tips"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_wishlist.jpg"
                alt="Wishlist tips"
              />
            }
          />
        </Row>
        <SectionHeader title="Team building" />
        <StaticImage
          src="../../../images/afk/generic/faction_bonus.webp"
          alt="Guides"
        />
        <p>
          While creating a team, it is important to maximize your faction bonus.
          Whenever more than 2 characters of the same faction are put on a team,
          the entire team’s stats are increased by a certain percent. This means
          you’re incentivized to keep your teams to two factions at most, though
          the <AFKItem name="Celestial" /> and <AFKItem name="Hypogean" />{' '}
          factions are here to help mitigate the issue.
        </p>
        <p>
          <AFKItem name="Celestial" /> and <AFKItem name="Hypogean" /> are
          interesting, as they serve as ‘neutral’ characters that can count
          towards any faction. To make it easier to understand: if you run 4
          characters of the <AFKItem name="Wilders" /> faction, then add either
          a Celestial or Hypogean character, it’ll activate the 5 character
          matching faction bonus.
        </p>
        <p>
          <strong>
            Note that it’s not worth it to go for a mono-faction set-up
          </strong>
          . Not only do most factions not have 5 characters that both are good
          and synergize with one another, the extra stats are just not worth it
          when compared to the utility of some of the meta characters. Instead,
          most teams function on a ‘core’,{' '}
          <strong>which is a set of 3 characters of the same faction</strong>{' '}
          that dictate what faction the team will run around. The other two
          units are then added based on synergy or power.
        </p>
        <h5>Standard team setup</h5>
        <p>
          Even though there are many possible teams in this game, more often
          than not, they will follow the same style of building when deciding
          between which characters to use.
        </p>
        <ul>
          <li>
            Pick a{' '}
            <strong className="tank">
              <FontAwesomeIcon icon={faShield} width="18" /> Tank
            </strong>
            . Tanks are the key figures in a team, even if their presence
            doesn’t often decide the other characters that will make up the
            team. Tanks have an exceedingly small roster, so be careful with who
            you pick to build around the faction bonus.
          </li>
          <li>
            Pick 2x{' '}
            <strong className="dps">
              <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
            </strong>
            . At the time of writing this, there is no real reason to run just 1
            DPS. There aren't enough characters that can support that kind of
            playstyle, so most teams opt to run at least 2 DPS.
          </li>
          <li>
            Pick a{' '}
            <strong className="support">
              <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
            </strong>{' '}
            Supports are often the backbone of a team. Though most Supports
            (depending on the character) can provide enough sustain on their
            own, sometimes their kits trade raw healing output for things like
            buffs or debuffs.
          </li>
          <li>
            Flex spot. Pick a{' '}
            <strong className="specialist">
              <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
            </strong>
            , a second{' '}
            <strong className="support">
              <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
            </strong>{' '}
            or a second{' '}
            <strong className="tank">
              <FontAwesomeIcon icon={faShield} width="18" /> Tank
            </strong>
            .
          </li>
          <ul>
            <li>
              Specialists are either characters with quirky kits, or characters
              that focus on multiple roles at the same time. A key observation
              you may have is that the “good” Specialists often provide
              something in terms of buffs, debuffs or enhancements for your
              other characters,
            </li>
            <li>
              You can also run a second Tank to split the agro, allowing the
              frontline to survive long enough for your team to use their
              Ultimates or a second Support - so you get more healing and more
              buffs.{' '}
              <strong>
                There's no one team setup to rule them all and who you choose
                for the flex spot depends a lot on the enemy formation.
              </strong>
            </li>
          </ul>
        </ul>
        <p>To learn more about team building, check our other guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="AFK Stages Teams"
            link="/afk-journey/guides/afk-progression-teams"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_team.jpg"
                alt="AFK Stages Teams"
              />
            }
          />
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
        <SectionHeader title="Game modes" />
        <StaticImage
          src="../../../images/afk/generic/beginner_14.webp"
          alt="Guides"
        />
        <p>
          AFK Journey even on its release has a ton of various gamemodes
          available. In this guide we will only list them all without going into
          too much details, but soon we will release detailed guides for each!
        </p>
        <p>
          The section below gives a brief overview of the mode and the moment
          you unlock it.
        </p>
        <div className="afk-modes">
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/beginner_15.webp"
              alt="Guides"
            />
            <h5>World Map</h5>
            <p>
              Instead of jumping from stage to stage like in AFK Arena, the main
              story progression has been replaced by an{' '}
              <strong>open world experience</strong>. You can roam around it
              while doing quests, kill enemies, find treasure, gather materials,
              solve puzzles etc!
            </p>
            <p>
              The world is still split into areas and chapters and you have to
              finish the quests within each area to progress further.
            </p>
            <p className="unlock">
              Unlocks: <strong>Default</strong>
            </p>
          </div>
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/beginner_16.webp"
              alt="Guides"
            />
            <h5>AFK Stages</h5>
            <p>
              <strong>
                AFK Stages are basically battles against ever-increasing in
                power enemies
              </strong>
              . To unlock most of the other modes or game functionalities you
              have to reach certain stages in this mode first.
            </p>
            <p>
              The stage you've reached affects your{' '}
              <strong>'idle' rewards</strong>, so always try to progress here as
              deep as you can. Thankfully, this mode has an auto-challenge
              feature available which allows you to automatically challenge the
              next stage after beating the current one - so you don't have to
              sit and and constantly press next button for hours if your team is
              strong enough.
            </p>
            <p>
              The current max level stage you can reach on PTR is stage{' '}
              <strong>1125</strong> and by default you have gather the afk loot
              every <strong>12h</strong> (but this can be increased using the
              achievement system).
            </p>
            <p className="unlock">
              Unlocks: <strong>Tutorial</strong>
            </p>
          </div>
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/beginner_17.webp"
              alt="Guides"
            />
            <h5>Dream Realm</h5>
            <p>
              Dream Realm is a <strong>boss rush</strong> mode where you compete
              against every player on your server in who will deal the most
              damage to it.
            </p>
            <ul>
              <li>There are 4 bosses in total and they change every day,</li>
              <li>
                The more damage you do the boss, the better your score and the
                better the rewards you will obtain at the end of the day,
              </li>
              <li>
                Each boss has 6 available difficulties and once you kill him in
                one, you will advance to the next which makes the fight harder,
                but also gives you more points/better rewards,
              </li>
              <li>
                For killing each boss for the first time on any of the available
                difficulties you will obtain{' '}
                <StaticImage
                  src="../../../images/afk/icons/currency_summon.png"
                  alt="Invite Letter"
                />{' '}
                <strong>Invite Letters</strong>
              </li>
              <li>
                When a boss comes back in the next cycle, you will instantly
                start at the highest difficulty you've reached - so you don't
                have to waste time on killing him again on the lower ones.
              </li>
            </ul>
            <p className="unlock">
              Unlocks: <strong>AFK Stage 16</strong>
            </p>
          </div>
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/beginner_18.webp"
              alt="Guides"
            />
            <h5>Honor Duel</h5>
            <p>
              Honor Duel is a <strong>fair play PVP mode</strong> where you can
              challenge other players and fight on even ground - you're not
              using your heroes, but the ones given to you by the game via a
              mode-specific card/shop system.
            </p>
            <p>
              While building your team you have to focus on synergies between
              heroes and who counters who. As here both sides have to 'place'
              the heroes on the battlefield in waves, so you can see what your
              enemy is planning and try to counter him - but watch out as he can
              do the same so don't be too fast with showing your strong hand.
            </p>
            <p>
              The mode has a weekly reward track that gives you rewards for
              achieving wins.
            </p>
            <p className="unlock">
              Unlocks: <strong>AFK Stage 16</strong>
            </p>
          </div>
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/beginner_19.webp"
              alt="Guides"
            />
            <h5>Arena</h5>
            <p>
              The Arena is another PVP mode, but here you use your own roster of
              heroes and fight against defense teams setup by other players.
            </p>
            <p>
              For winning a fight you receive rank points and lose them by
              losing a fight. As you gain points, you will progress through the
              League system, starting from Novice and ending on Champion. The
              higher your league, the bigger daily and weekly rewards you will
              receive.
            </p>
            <p>
              The battles are a team vs team - there's no 3vs3 PVP yet in the
              game.
            </p>
            <p className="unlock">
              Unlocks: <strong>AFK Stage 20</strong>
            </p>
          </div>
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/beginner_20.webp"
              alt="Guides"
            />
            <h5>Arcane Labyrinth</h5>
            <p>
              The good old Labyrinth makes a comeback in AFK Journey but as with
              the rest of the game, they added some improvements to this mode
              too.
            </p>
            <p>
              The gameplay itself also has slightly changed as now you enter a
              room and select one of two other rooms - each will give you a
              different reward for beating the enemy hiding inside.
            </p>
            <p className="unlock">
              Unlocks: <strong>AFK Stage 100</strong>
            </p>
          </div>
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/beginner_21.webp"
              alt="Guides"
            />
            <h5>Battle Drills </h5>
            <p>
              Battle Drills is a competitive available only to Guilds that is
              very similar to the Abyssal Expedition though on a much smaller
              and more manageable scale.
            </p>
            <p>
              The goal of this mode is the progress through the monster-infested
              map by killing scouts, camps and then bosses. You can gather
              various buffs for conquering strongholds that will make your
              heroes stronger, making it easier to push deeper into the enemy
              territory.
            </p>
            <p>
              Coordination is important if you want to achieve the highest
              rankings!
            </p>
            <p className="unlock">
              Unlocks: <strong>AFK Stage 121</strong>
            </p>
          </div>
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/beginner_22.webp"
              alt="Guides"
            />
            <h5>Legend Trial</h5>
            <p>
              Legend Trial is basically renamed Factional Tower system known
              from AFK Arena.
            </p>
            <p>
              Different Trials are opened based on the day of the week and you
              can obtain Acorns, Essence and materials needed to Ascend
              Artifacts from here.
            </p>
            <p className="unlock">
              Unlocks: <strong>AFK Stage 166</strong>
            </p>
          </div>
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/primal.webp"
              alt="Guides"
            />
            <h5>Primal Lord</h5>
            <p>
              Primal Lords are boss monsters that spawn on the map and the whole
              server has to work together to defeat them. The faster you manage
              to do that, the better rewards everyone will obtain. This is a
              cyclical event so various Primal Bosses will spawn from time to
              time on the map.
            </p>
            <p className="unlock">
              Unlocks: <strong>cyclical event</strong>
            </p>
          </div>
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/trial.webp"
              alt="Guides"
            />
            <h5>Trial of Abyss</h5>
            <p>
              Trial of Abyss unlocks once you finish the initial story campaign
              and clear all 1125 AFK Stages, but also the server needs to be at
              least 18 days old. The mode is basically a dungeon you have to
              climb down and beat a team guarding each floor.
            </p>
            <p>
              Here you will encounter teams featuring multiple{' '}
              <AFKItem name="Celestial" /> and <AFKItem name="Hypogean" />{' '}
              characters who will test you.
            </p>
            <p className="unlock">
              Unlocks: <strong>AFK Stage 1125</strong>
            </p>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
        <SectionHeader title="Artifacts" />
        <StaticImage
          src="../../../images/afk/generic/beginner_23.webp"
          alt="Guides"
        />
        <p>
          Artifacts make a return in AFK Journey, but the system has been
          drastically changed compared to the original here. The biggest
          difference is that Artifact's aren't tied to characters anymore and
          instead you select one that's going to be used in the battle - either
          once or periodically throughout the battle.
        </p>
        <p>
          There are 6 Artifacts currently available in the game and you unlock
          them as you progress thought the main story.
        </p>
        <h5>What Artifact is the best pre-Season?</h5>
        <p>
          Confining Spell &gt; Starshard Spell &gt; Enlightening Spell &gt;
          Awakening Spell &gt; Blazing Spell &gt; Ironwall Spell
        </p>
        <p>
          If you want to learn what each of the Artifact is doing and more
          information about them, check our other guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Artifacts"
            link="/afk-journey/guides/artifacts"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_artifacts.jpg"
                alt="Artifacts"
              />
            }
          />
        </Row>
        <SectionHeader title="Shops" />
        <StaticImage
          src="../../../images/afk/generic/beginner_shops.webp"
          alt="Guides"
        />
        <p>
          There's a big variety of shops available in AFK Journey where you can
          purchase <AFKItem name="Invite Letter" /> and other summoning
          currency, <AFKItem name="S-Level" /> and <AFKItem name="A-Level" />{' '}
          character dupes, Acorns and much more.
        </p>
        <p>
          To access the shops, you have to visit the Mystical House and then the
          Emporium. Currently there are 5 available shops:
        </p>
        <ul>
          <li>
            <strong>Recruitment Store</strong> - contains summoning currency for
            Banners and Stargaze, Acorns and Soulstones. Refreshes monthly,
          </li>
          <li>
            <strong>Guild Store</strong> - contains summoning currency for
            Banners and Stargaze, Acorns. You can also buy{' '}
            <AFKItem name="Celestial" /> and <AFKItem name="Hypogean" /> dupes
            from here. Refreshes daily and monthly,
          </li>
          <li>
            <strong>Arena Store</strong> - contains <AFKItem name="S-Level" />{' '}
            character dupes. Refreshes monthly,
          </li>
          <li>
            <strong>Dream Store</strong> - contains <AFKItem name="A-Level" />{' '}
            character dupes. Refreshes monthly,
          </li>
          <li>
            <strong>Friendship Store</strong> - contains Affinity items and
            Gestures. Refreshes daily.
          </li>
        </ul>
        <p>
          To learn what to prioritize in each of those shops, check this guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Shop (Emporium) guide"
            link="/afk-journey/guides/shop-guide"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_shops.jpg"
                alt="Shop (Emporium) guide"
              />
            }
          />
        </Row>
        <SectionHeader title="Guilds" />
        <StaticImage
          src="../../../images/afk/generic/guild_1.webp"
          alt="Guides"
        />
        <p>
          Guilds in AFK Journey allow you to play together with your friends by
          doing challenges and progressing through the game, but you also can
          compete against other guilds in Battle Drills and Glory Expedition.{' '}
          <strong className="red">
            What is super important is that unlike in AFK Arena, in AFK Journey,
            guilds are not cross-server.
          </strong>
          . Which means you have to play on the same server as your friends if
          you all want to join the same guild. We're really hoping this will be
          changed for the release (or shortly after) as trying to recruit anyone
          for an old server after let's say a month will be impossible - nobody
          will join an old server where they can't compete in PVP or Dream
          Realm.
        </p>
        <p>To learn more about Guilds, check our other guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Guilds"
            link="/afk-journey/guides/guilds"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_guilds.jpg"
                alt="Guilds"
              />
            }
          />
        </Row>
        <SectionHeader title="Video" />
        <p>For more team tips, check this video:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="MVHgzSLhN0E" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesBeg;

export const Head: React.FC = () => (
  <Seo
    title="Beginner guide | AFK Journey | Prydwen Institute"
    description="A beginner guide for AFK Journey that will teach you everything you need to know about the game!"
    game="afk"
  />
);
