export const Shiyu7_1 = [
  {
    char_one: 'Miyabi',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 13.27,
    rank: 1,
    avg_round: 68,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 10.28,
    rank: 2,
    avg_round: 111,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 6.61,
    rank: 3,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 5.88,
    rank: 4,
    avg_round: 61,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 4.32,
    rank: 5,
    avg_round: 63,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Caesar',
    app_rate: 3.5,
    rank: 6,
    avg_round: 58,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 2.65,
    rank: 7,
    avg_round: 74,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Rina',
    app_rate: 2.63,
    rank: 8,
    avg_round: 64,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Soukaku',
    app_rate: 2.46,
    rank: 9,
    avg_round: 68,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 2.25,
    rank: 10,
    avg_round: 137,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 2.04,
    rank: 11,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 1.73,
    rank: 12,
    avg_round: 70,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 1.56,
    rank: 13,
    avg_round: 121,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Caesar',
    app_rate: 1.5,
    rank: 14,
    avg_round: 117,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 1.45,
    rank: 15,
    avg_round: 158,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 1.37,
    rank: 16,
    avg_round: 106,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 1.33,
    rank: 17,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 1.28,
    rank: 18,
    avg_round: 136,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 1.26,
    rank: 19,
    avg_round: 64,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Burnice',
    char_three: 'Soukaku',
    app_rate: 1.22,
    rank: 20,
    avg_round: 67,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lighter',
    char_three: 'Soukaku',
    app_rate: 1.16,
    rank: 21,
    avg_round: 127,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Lighter',
    app_rate: 0.97,
    rank: 22,
    avg_round: 111,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.95,
    rank: 23,
    avg_round: 82,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.86,
    rank: 24,
    avg_round: 168,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Lucy',
    app_rate: 0.82,
    rank: 25,
    avg_round: 64,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Seth',
    app_rate: 0.8,
    rank: 26,
    avg_round: 146,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Ellen',
    char_three: 'Soukaku',
    app_rate: 0.78,
    rank: 27,
    avg_round: 84,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.74,
    rank: 28,
    avg_round: 121,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.63,
    rank: 29,
    avg_round: 77,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.55,
    rank: 30,
    avg_round: 81,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'jane-doe',
    char_three: 'Soukaku',
    app_rate: 0.55,
    rank: 30,
    avg_round: 83,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Burnice',
    app_rate: 0.51,
    rank: 32,
    avg_round: 67,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.48,
    rank: 33,
    avg_round: 82,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lighter',
    char_three: 'Soukaku',
    app_rate: 0.46,
    rank: 34,
    avg_round: 97,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.44,
    rank: 35,
    avg_round: 70,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.44,
    rank: 35,
    avg_round: 197,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.42,
    rank: 37,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Lycaon',
    app_rate: 0.4,
    rank: 38,
    avg_round: 64,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.4,
    rank: 38,
    avg_round: 81,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Miyabi',
    char_three: 'nicole-demara',
    app_rate: 0.38,
    rank: 40,
    avg_round: 84,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.36,
    rank: 41,
    avg_round: 72,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Piper',
    char_three: 'Soukaku',
    app_rate: 0.34,
    rank: 42,
    avg_round: 81,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'Soukaku',
    app_rate: 0.34,
    rank: 42,
    avg_round: 101,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Soukaku',
    char_three: 'Seth',
    app_rate: 0.34,
    rank: 42,
    avg_round: 103,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.32,
    rank: 45,
    avg_round: 64,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Seth',
    app_rate: 0.32,
    rank: 45,
    avg_round: 83,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.32,
    rank: 45,
    avg_round: 193,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.32,
    rank: 45,
    avg_round: 131,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.27,
    rank: 49,
    avg_round: 182,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.27,
    rank: 49,
    avg_round: 99,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 0.27,
    rank: 49,
    avg_round: 143,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.25,
    rank: 52,
    avg_round: 78,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.25,
    rank: 52,
    avg_round: 72,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.25,
    rank: 52,
    avg_round: 92,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 0.25,
    rank: 52,
    avg_round: 89,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.25,
    rank: 52,
    avg_round: 75,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.23,
    rank: 57,
    avg_round: 61,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.23,
    rank: 57,
    avg_round: 118,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.23,
    rank: 57,
    avg_round: 71,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.23,
    rank: 57,
    avg_round: 180,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.21,
    rank: 61,
    avg_round: 118,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.21,
    rank: 61,
    avg_round: 150,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'grace-howard',
    char_three: 'Soukaku',
    app_rate: 0.21,
    rank: 61,
    avg_round: 76,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.19,
    rank: 64,
    avg_round: 142,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.17,
    rank: 65,
    avg_round: 72,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.17,
    rank: 65,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Burnice',
    char_three: 'Lighter',
    app_rate: 0.17,
    rank: 65,
    avg_round: 74,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lighter',
    char_three: 'nicole-demara',
    app_rate: 0.15,
    rank: 68,
    avg_round: 133,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.15,
    rank: 68,
    avg_round: 108,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lycaon',
    char_three: 'Caesar',
    app_rate: 0.15,
    rank: 68,
    avg_round: 97,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.15,
    rank: 68,
    avg_round: 115,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.15,
    rank: 68,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.15,
    rank: 68,
    avg_round: 124,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Burnice',
    char_three: 'Rina',
    app_rate: 0.15,
    rank: 68,
    avg_round: 72,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.15,
    rank: 68,
    avg_round: 71,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lighter',
    char_three: 'Caesar',
    app_rate: 0.15,
    rank: 68,
    avg_round: 142,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.13,
    rank: 77,
    avg_round: 183,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Caesar',
    app_rate: 0.13,
    rank: 77,
    avg_round: 157,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Miyabi',
    char_three: 'Soukaku',
    app_rate: 0.13,
    rank: 77,
    avg_round: 101,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.13,
    rank: 77,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 81,
    avg_round: 117,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lighter',
    char_three: 'Caesar',
    app_rate: 0.11,
    rank: 81,
    avg_round: 69,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.11,
    rank: 81,
    avg_round: 77,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 81,
    avg_round: 95,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Burnice',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 81,
    avg_round: 82,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 81,
    avg_round: 92,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.11,
    rank: 81,
    avg_round: 189,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'jane-doe',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 88,
    avg_round: 78,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 88,
    avg_round: 79,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'jane-doe',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 88,
    avg_round: 79,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 88,
    avg_round: 74,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 88,
    avg_round: 86,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 88,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Qingyi',
    app_rate: 0.08,
    rank: 88,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 88,
    avg_round: 137,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'Yanagi',
    app_rate: 0.08,
    rank: 88,
    avg_round: 66,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Ellen',
    char_three: 'Lycaon',
    app_rate: 0.08,
    rank: 88,
    avg_round: 102,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'Lighter',
    app_rate: 0.06,
    rank: 98,
    avg_round: 73,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 98,
    avg_round: 150,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 98,
    avg_round: 146,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Burnice',
    char_three: 'Lycaon',
    app_rate: 0.06,
    rank: 98,
    avg_round: 109,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 98,
    avg_round: 168,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 98,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Piper',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 98,
    avg_round: 84,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Ellen',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 98,
    avg_round: 89,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'Qingyi',
    app_rate: 0.06,
    rank: 98,
    avg_round: 67,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 98,
    avg_round: 157,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 98,
    avg_round: 95,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 98,
    avg_round: 110,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'grace-howard',
    app_rate: 0.06,
    rank: 98,
    avg_round: 70,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 98,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 98,
    avg_round: 195,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 98,
    avg_round: 117,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 98,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 98,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 98,
    avg_round: 176,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 98,
    avg_round: 82,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Yanagi',
    char_three: 'jane-doe',
    app_rate: 0.04,
    rank: 118,
    avg_round: 61,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 118,
    avg_round: 58,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lighter',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 118,
    avg_round: 94,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 118,
    avg_round: 151,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 118,
    avg_round: 107,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 118,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 118,
    avg_round: 77,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lighter',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 118,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 118,
    avg_round: 184,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lighter',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 118,
    avg_round: 180,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 118,
    avg_round: 180,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lucy',
    char_three: 'Ben',
    app_rate: 0.04,
    rank: 118,
    avg_round: 86,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 118,
    avg_round: 151,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Ellen',
    char_three: 'Lighter',
    app_rate: 0.04,
    rank: 118,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Lycaon',
    char_three: 'Lighter',
    app_rate: 0.04,
    rank: 118,
    avg_round: 114,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 118,
    avg_round: 145,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 118,
    avg_round: 275,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 118,
    avg_round: 197,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 118,
    avg_round: 131,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 118,
    avg_round: 147,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Harumasa',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 118,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.04,
    rank: 118,
    avg_round: 117,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 118,
    avg_round: 201,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 118,
    avg_round: 151,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 118,
    avg_round: 147,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Ellen',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 118,
    avg_round: 117,
    star_num: '4'
  },
  {
    char_one: 'Miyabi',
    char_two: 'Burnice',
    char_three: 'Koleda',
    app_rate: 0.04,
    rank: 118,
    avg_round: 82,
    star_num: '4'
  }
];
