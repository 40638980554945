import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const AFKGuidesDreamLone: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Dream Realm - Lone Gaze</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_lone.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Lone Gaze</h1>
          <h2>
            Dream Realm Lone Gaze guide that explains the boss mechanics and how
            to beat it!
          </h2>
          <p>
            Last updated: <strong>15/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Lone Gaze" />
        <StaticImage
          src="../../../images/afk/generic/dream_lone.webp"
          alt="Guides"
        />
        <p>
          <strong>Lone Gaze</strong> is one of the 8 available Dream Realm
          bosses you will encounter in AFK Journey. In this guide we will go
          over the boss mechanics and the best characters and teams to use
          against him.
        </p>
        <SectionHeader title="What’s the meta team?" />
        <p>
          Realistically speaking,{' '}
          <strong>
            your meta team will depend on your specific box, and your account
            focus
          </strong>
          , so use your highest rarity DPS carry characters, along with whoever
          can best boost their damage. Keep faction bonus in mind but don’t run
          suboptimal characters just to force it.
        </p>
        <p>
          Still, below you can find a few example teams. Big thanks to{' '}
          <StaticImage
            src="../../../images/afk/icons/afkanalytica.png"
            alt="AFK Analytica"
          />{' '}
          <strong className="yellow">AFK Analytica</strong> for the team
          building tool. You can find their website{' '}
          <a
            href="https://www.afkanalytica.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and their discord{' '}
          <a
            href="https://www.discord.gg/analytica"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <SectionHeader title="Season 3 Teams (Pre-Endless)" />
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-season"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={<>Team #1 - Best Team</>}
            className="with-bg"
          >
            <h5>Team #1 - Best Team (Talene + Reinier)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/lone3_1.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>叫我波妮大人</strong>.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="talene" enablePopover /> acts
                as main carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> acts
                as secondary damage carry and buffer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer (<strong>Mythic+</strong> required).
              </li>
              <li>
                <AFKCharacter mode="inline" slug="bonnie" enablePopover /> acts
                as secondary damage carry and debuffer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="koko" enablePopover /> acts as
                healer and buffer.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Overcharge Spell" /> - Used for the Energy
                  Regen.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team2"
            title={<>Team #2 - Alt Team</>}
            className="with-bg"
          >
            <h5>Team #2 - Alt Team (Reinier)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/lone3_2.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>梅林大人</strong>.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="sinbad" enablePopover /> is
                the main damage carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> acts
                as secondary damage carry and buffer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="koko" enablePopover /> acts a
                healer and buffer
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer (<strong>Mythic+</strong> required).
              </li>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> acts
                as buffer and healer.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Harmonic Spell" /> - Best overall performance
                  thanks to the stats provided.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team3"
            title={<>Team #3 - Alt Team</>}
            className="with-bg"
          >
            <h5>Team #3 - Alt Team (Reinier)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/lone3_3.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Impaler Messmer</strong>.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="lenya" enablePopover /> is the
                main damage carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="kruger" enablePopover /> acts
                as debuffer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="koko" enablePopover /> acts a
                healer and buffer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer (<strong>Mythic+</strong> required).
              </li>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> acts
                as buffer and healer.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Harmonic Spell" /> - Best overall performance
                  thanks to the stats provided.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team4"
            title={<>Team #4 - No Celehypo</>}
            className="with-bg"
          >
            <h5>Team #4 - No Celehypo (Ludovic)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/lone3_4.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>世界第一超级天才</strong>.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> acts
                as main damage carry and buffer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover /> acts as
                secondary damage carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="koko" enablePopover /> acts a
                healer and buffer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> acts
                as healer and buffer
              </li>
              <li>
                <AFKCharacter mode="inline" slug="shakir" enablePopover /> acts
                as buffer (<strong>EX +10 required</strong>).
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Harmonic Spell" /> - Best overall performance
                  thanks to the stats provided.
                </li>
              </ul>
            </ul>
          </Tab>
        </Tabs>
        <SectionHeader title="Boss Mechanics" />
        <p>
          The Dream Realm version of the Lone Gaze is basically a copy of the
          fight we know from the Primal Lord event. Here are the boss skills:
        </p>
        <h5>Skills</h5>
        <ul>
          <li>
            <strong>Ultimate</strong> - Every 10, 40 and 70 seconds a mist will
            descend on the battlefield. It will shroud the Wolf King, allowing
            him to summon wolf packs to attack mist-covered enemies. The Wolf
            King has a chance to dodge attacks within the mist,
          </li>
          <li>
            <strong>Skill 1</strong> - Charges towards the farthest target and
            strikes. Summons spectral wolves to attack the farthest target when
            the mist exists.
          </li>
          <li>
            <strong>Skill 2</strong> - Deals damage twice to the targets within
            a 1-tile arc. Then deals damage to the enemies within 1 tile and
            inflicts an interruption effect.
          </li>
          <li>
            <strong>Passive</strong> - Gains stats boost when defeating a
            non-summoned enemy.
          </li>
        </ul>
        <SectionHeader title="Tips and tricks" />
        <ul>
          <li>
            <strong>Use a Healer and a Shielder.</strong> The damage the boss
            outputs is the highest and most consistent of all bosses -
            especially the phantom wolves that are summoned periodically. You
            need to ensure that the team is not only as close to full HP as
            possible, but you also need to bring a source of Shields to further
            increase the eHP of the team. And if someone from your team dies,
            the fight will snowball in the favor of the boss due to his passive.
          </li>
          <li>
            Consider using{' '}
            <strong>damage dealers who can keep themselves alive</strong> and
            provide additional utility to the team.
          </li>
          <li>
            <strong>The fight is very RNG heavy</strong>. The phantom wolves can
            one-shot one of your supports at anytime, instantly wiping your
            team. So if your team doesn't perform too well, give it a few tries
            before giving up or looking for a different formation.
          </li>
        </ul>
        <SectionHeader title="Videos" />
        <p>For more team idea, check this video:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="sGeWeSJn6v4" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesDreamLone;

export const Head: React.FC = () => (
  <Seo
    title="Lone Gaze | AFK Journey | Prydwen Institute"
    description="Dream Realm Lone Gaze guide that explains the boss mechanics and how to beat it!"
    game="afk"
  />
);
