export const PFCurrentStage4Side2 = [
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 8.7,
    rank: 1,
    avg_round: 31875,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 7.07,
    rank: 2,
    avg_round: 27610,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 5.99,
    rank: 3,
    avg_round: 32366,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 5.53,
    rank: 4,
    avg_round: 32585,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 3.39,
    rank: 5,
    avg_round: 33401,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 2.67,
    rank: 6,
    avg_round: 36015,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 2.46,
    rank: 7,
    avg_round: 39509,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 2.39,
    rank: 8,
    avg_round: 32833,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 2.2,
    rank: 9,
    avg_round: 29550,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 2.15,
    rank: 10,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 2.03,
    rank: 11,
    avg_round: 34572,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 1.99,
    rank: 12,
    avg_round: 35455,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 1.91,
    rank: 13,
    avg_round: 31054,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 1.81,
    rank: 14,
    avg_round: 29699,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 1.59,
    rank: 15,
    avg_round: 39356,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 1.56,
    rank: 16,
    avg_round: 33710,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 1.41,
    rank: 17,
    avg_round: 34224,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 1.27,
    rank: 18,
    avg_round: 31802,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 1.23,
    rank: 19,
    avg_round: 30796,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 1.04,
    rank: 20,
    avg_round: 38878,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 1.02,
    rank: 21,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 1.0,
    rank: 22,
    avg_round: 38297,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.82,
    rank: 23,
    avg_round: 33680,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.75,
    rank: 24,
    avg_round: 31402,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.69,
    rank: 25,
    avg_round: 37713,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.67,
    rank: 26,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.67,
    rank: 26,
    avg_round: 29714,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.63,
    rank: 28,
    avg_round: 33880,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.55,
    rank: 29,
    avg_round: 39285,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'Aventurine',
    app_rate: 0.54,
    rank: 30,
    avg_round: 33184,
    star_num: '4'
  },
  {
    char_one: 'tingyun-fugue',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.53,
    rank: 31,
    avg_round: 38791,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'tingyun-fugue',
    char_four: 'Gallagher',
    app_rate: 0.52,
    rank: 32,
    avg_round: 33092,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.52,
    rank: 32,
    avg_round: 31866,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'tingyun-fugue',
    char_four: 'Lingsha',
    app_rate: 0.47,
    rank: 34,
    avg_round: 35563,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.47,
    rank: 34,
    avg_round: 31961,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.46,
    rank: 36,
    avg_round: 27537,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.44,
    rank: 37,
    avg_round: 28834,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.44,
    rank: 37,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.43,
    rank: 39,
    avg_round: 31365,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.43,
    rank: 39,
    avg_round: 30310,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.43,
    rank: 39,
    avg_round: 26848,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.41,
    rank: 42,
    avg_round: 39224,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.39,
    rank: 43,
    avg_round: 37440,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.36,
    rank: 44,
    avg_round: 32427,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'Gallagher',
    app_rate: 0.35,
    rank: 45,
    avg_round: 35228,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'Lingsha',
    app_rate: 0.35,
    rank: 45,
    avg_round: 36425,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'ruan-mei',
    app_rate: 0.33,
    rank: 47,
    avg_round: 37883,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.32,
    rank: 48,
    avg_round: 39343,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.32,
    rank: 48,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.28,
    rank: 50,
    avg_round: 27237,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.28,
    rank: 50,
    avg_round: 37589,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.27,
    rank: 52,
    avg_round: 26929,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'Huohuo',
    app_rate: 0.26,
    rank: 53,
    avg_round: 33162,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.24,
    rank: 54,
    avg_round: 33202,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.24,
    rank: 54,
    avg_round: 35461,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.22,
    rank: 56,
    avg_round: 33920,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.22,
    rank: 56,
    avg_round: 30320,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.21,
    rank: 58,
    avg_round: 29176,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.21,
    rank: 58,
    avg_round: 28437,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.21,
    rank: 58,
    avg_round: 32134,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Herta',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.21,
    rank: 58,
    avg_round: 31027,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.2,
    rank: 62,
    avg_round: 31260,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.2,
    rank: 62,
    avg_round: 32800,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.19,
    rank: 64,
    avg_round: 30390,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.19,
    rank: 64,
    avg_round: 30149,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.18,
    rank: 66,
    avg_round: 32665,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.18,
    rank: 66,
    avg_round: 29914,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.17,
    rank: 68,
    avg_round: 36775,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.17,
    rank: 68,
    avg_round: 32215,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Sunday',
    app_rate: 0.16,
    rank: 70,
    avg_round: 30533,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.16,
    rank: 70,
    avg_round: 37710,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.16,
    rank: 70,
    avg_round: 33503,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.15,
    rank: 73,
    avg_round: 33200,
    star_num: '4'
  },
  {
    char_one: 'tingyun-fugue',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.15,
    rank: 73,
    avg_round: 39576,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'tingyun-fugue',
    char_four: 'Aventurine',
    app_rate: 0.15,
    rank: 73,
    avg_round: 32888,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.15,
    rank: 73,
    avg_round: 28076,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Sunday',
    app_rate: 0.14,
    rank: 77,
    avg_round: 32382,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.14,
    rank: 77,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.14,
    rank: 77,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'ruan-mei',
    app_rate: 0.14,
    rank: 77,
    avg_round: 33224,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.14,
    rank: 77,
    avg_round: 30342,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.14,
    rank: 77,
    avg_round: 29640,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'ruan-mei',
    app_rate: 0.14,
    rank: 77,
    avg_round: 34440,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 77,
    avg_round: 37400,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'Robin',
    app_rate: 0.14,
    rank: 77,
    avg_round: 29427,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.14,
    rank: 77,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.14,
    rank: 77,
    avg_round: 27675,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.14,
    rank: 77,
    avg_round: 25740,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'tingyun-fugue',
    char_four: 'Robin',
    app_rate: 0.13,
    rank: 89,
    avg_round: 30526,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.13,
    rank: 89,
    avg_round: 29543,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.13,
    rank: 89,
    avg_round: 29648,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.12,
    rank: 92,
    avg_round: 38250,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'Luocha',
    app_rate: 0.12,
    rank: 92,
    avg_round: 32227,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.12,
    rank: 92,
    avg_round: 29542,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.12,
    rank: 92,
    avg_round: 36537,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.11,
    rank: 96,
    avg_round: 31884,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.11,
    rank: 96,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.1,
    rank: 98,
    avg_round: 29046,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 98,
    avg_round: 29644,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.1,
    rank: 98,
    avg_round: 35587,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.1,
    rank: 98,
    avg_round: 29829,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'tingyun-fugue',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 98,
    avg_round: 28371,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.1,
    rank: 98,
    avg_round: 29480,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.1,
    rank: 98,
    avg_round: 31027,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.1,
    rank: 98,
    avg_round: 30491,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 106,
    avg_round: 39550,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 106,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.09,
    rank: 106,
    avg_round: 38816,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.09,
    rank: 106,
    avg_round: 38085,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 106,
    avg_round: 32540,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 106,
    avg_round: 32380,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'tingyun-fugue',
    char_four: 'Lingsha',
    app_rate: 0.09,
    rank: 106,
    avg_round: 31020,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 106,
    avg_round: 35667,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 106,
    avg_round: 38640,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 106,
    avg_round: 29227,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 106,
    avg_round: 31140,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.09,
    rank: 106,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.09,
    rank: 106,
    avg_round: 23376,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 106,
    avg_round: 30720,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'tingyun-fugue',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 106,
    avg_round: 32387,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 106,
    avg_round: 27744,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.09,
    rank: 106,
    avg_round: 32620,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.09,
    rank: 106,
    avg_round: 31413,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Herta',
    char_four: 'Robin',
    app_rate: 0.08,
    rank: 124,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.08,
    rank: 124,
    avg_round: 35490,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 124,
    avg_round: 31373,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 124,
    avg_round: 29457,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.08,
    rank: 124,
    avg_round: 33370,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 129,
    avg_round: 31853,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Himeko',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.07,
    rank: 129,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.07,
    rank: 129,
    avg_round: 29280,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.06,
    rank: 132,
    avg_round: 36653,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 132,
    avg_round: 36840,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.06,
    rank: 132,
    avg_round: 35507,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.06,
    rank: 132,
    avg_round: 32280,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 132,
    avg_round: 26760,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 132,
    avg_round: 30227,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 132,
    avg_round: 32500,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 132,
    avg_round: 38800,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Sunday',
    app_rate: 0.06,
    rank: 132,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 132,
    avg_round: 29100,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 132,
    avg_round: 36256,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 132,
    avg_round: 32360,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 132,
    avg_round: 29560,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 132,
    avg_round: 24300,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 132,
    avg_round: 38333,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 132,
    avg_round: 39740,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 132,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.06,
    rank: 132,
    avg_round: 30408,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 132,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'tingyun-fugue',
    char_four: 'trailblazer-harmony',
    app_rate: 0.06,
    rank: 132,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 132,
    avg_round: 32720,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 153,
    avg_round: 34587,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 153,
    avg_round: 35627,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 153,
    avg_round: 32880,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.05,
    rank: 153,
    avg_round: 36780,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 153,
    avg_round: 31520,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 153,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 153,
    avg_round: 28976,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 153,
    avg_round: 38680,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 153,
    avg_round: 35240,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 153,
    avg_round: 34900,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Asta',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 153,
    avg_round: 32513,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 153,
    avg_round: 26360,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 153,
    avg_round: 35448,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'Robin',
    app_rate: 0.05,
    rank: 153,
    avg_round: 31920,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 167,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 167,
    avg_round: 35980,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 167,
    avg_round: 30120,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Sunday',
    app_rate: 0.04,
    rank: 167,
    avg_round: 29040,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 167,
    avg_round: 37533,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 167,
    avg_round: 30720,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 167,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 167,
    avg_round: 28413,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 167,
    avg_round: 35060,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 167,
    avg_round: 36590,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'topaz',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 167,
    avg_round: 26600,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 167,
    avg_round: 32573,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Sunday',
    app_rate: 0.04,
    rank: 167,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 167,
    avg_round: 29120,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 167,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 167,
    avg_round: 29600,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 167,
    avg_round: 37800,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 167,
    avg_round: 33704,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 167,
    avg_round: 27760,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 167,
    avg_round: 28040,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 167,
    avg_round: 25760,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.04,
    rank: 167,
    avg_round: 32467,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'topaz',
    char_four: 'Robin',
    app_rate: 0.04,
    rank: 167,
    avg_round: 25880,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'topaz',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 167,
    avg_round: 30853,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 167,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 167,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 167,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 167,
    avg_round: 37680,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 167,
    avg_round: 33700,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.04,
    rank: 167,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'trailblazer-harmony',
    app_rate: 0.04,
    rank: 167,
    avg_round: 27000,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 167,
    avg_round: 22900,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 167,
    avg_round: 33200,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 167,
    avg_round: 38320,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Herta',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 201,
    avg_round: 32320,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 201,
    avg_round: 35360,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'tingyun-fugue',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 201,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 28440,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 201,
    avg_round: 28000,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Asta',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 201,
    avg_round: 38020,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Asta',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 201,
    avg_round: 28220,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 37173,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 201,
    avg_round: 29600,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'tingyun-fugue',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 30720,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Asta',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 29530,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Sunday',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 201,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 25707,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 201,
    avg_round: 38720,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 25280,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 201,
    avg_round: 29680,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 27500,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 36720,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 201,
    avg_round: 36620,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'topaz',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 32560,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 30720,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 31267,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 31100,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Sparkle',
    app_rate: 0.03,
    rank: 201,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 30200,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 27560,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'Robin',
    app_rate: 0.03,
    rank: 201,
    avg_round: 29760,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'tingyun-fugue',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'Robin',
    app_rate: 0.03,
    rank: 201,
    avg_round: 36680,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.03,
    rank: 201,
    avg_round: 29547,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Yunli',
    char_three: 'Herta',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 201,
    avg_round: 29860,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 27060,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Sunday',
    app_rate: 0.03,
    rank: 201,
    avg_round: 33760,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 29640,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 201,
    avg_round: 26520,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 34760,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 201,
    avg_round: 27060,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 201,
    avg_round: 30240,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'tingyun-fugue',
    char_four: 'trailblazer-harmony',
    app_rate: 0.03,
    rank: 201,
    avg_round: 38240,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 27160,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 25480,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 24880,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 33320,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 201,
    avg_round: 26160,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 201,
    avg_round: 27107,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 24320,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 201,
    avg_round: 36480,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 32920,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 31420,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 201,
    avg_round: 29210,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 201,
    avg_round: 27773,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Asta',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 201,
    avg_round: 28520,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 201,
    avg_round: 30440,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 30440,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Herta',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 25760,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 201,
    avg_round: 26000,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Sunday',
    app_rate: 0.03,
    rank: 201,
    avg_round: 31560,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'topaz',
    char_four: 'Robin',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 201,
    avg_round: 25760,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Asta',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 33267,
    star_num: '4'
  },
  {
    char_one: 'tingyun-fugue',
    char_two: 'trailblazer-harmony',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 29720,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'tingyun-fugue',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 201,
    avg_round: 30027,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 31210,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 36500,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 29920,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'topaz',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 201,
    avg_round: 24320,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 31800,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Robin',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 31440,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 37080,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 201,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 201,
    avg_round: 38760,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Herta',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 27520,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 31440,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 201,
    avg_round: 30520,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 31213,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 31500,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 201,
    avg_round: 28680,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 32280,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 201,
    avg_round: 33960,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 31827,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 28120,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Himeko',
    char_three: 'tingyun-fugue',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 33960,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'tingyun-fugue',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 201,
    avg_round: 28920,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 38480,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 201,
    avg_round: 31000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 201,
    avg_round: 27080,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'march-7th',
    app_rate: 0.03,
    rank: 201,
    avg_round: 31640,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 201,
    avg_round: 28480,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 26780,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 36840,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 26200,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 201,
    avg_round: 25280,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 201,
    avg_round: 36620,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 39000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 33613,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 201,
    avg_round: 39080,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 30400,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 201,
    avg_round: 29120,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 34280,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 201,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 26880,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 326,
    avg_round: 29200,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'march-7th-swordmaster',
    char_four: 'Robin',
    app_rate: 0.02,
    rank: 326,
    avg_round: 27000,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 326,
    avg_round: 27520,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 326,
    avg_round: 33840,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'tingyun-fugue',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 27840,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Herta',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 24560,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 326,
    avg_round: 28160,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 29880,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 24600,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 326,
    avg_round: 25400,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 38000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Jiaoqiu',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 326,
    avg_round: 29180,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 36640,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Asta',
    char_four: 'Robin',
    app_rate: 0.02,
    rank: 326,
    avg_round: 31800,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Jiaoqiu',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 32520,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 27960,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 36080,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'march-7th-swordmaster',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 326,
    avg_round: 29100,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 24840,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 31920,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 27680,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 326,
    avg_round: 27600,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 23720,
    star_num: '4'
  },
  {
    char_one: 'trailblazer-harmony',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 31620,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'tingyun-fugue',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 326,
    avg_round: 28880,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 326,
    avg_round: 22240,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 32320,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'tingyun-fugue',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 31280,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'tingyun-fugue',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 33120,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 35040,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 33400,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Yunli',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 29880,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 24680,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Herta',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 35680,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 24160,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 26120,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 326,
    avg_round: 31640,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'tingyun-fugue',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 326,
    avg_round: 26560,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 22720,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 25600,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 326,
    avg_round: 28900,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 326,
    avg_round: 27840,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 326,
    avg_round: 31140,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 26840,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 326,
    avg_round: 26520,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'Pela',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 326,
    avg_round: 36360,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 26600,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Sunday',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 326,
    avg_round: 26380,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 37120,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 326,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 326,
    avg_round: 30020,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 326,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 38360,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 326,
    avg_round: 27640,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 326,
    avg_round: 32640,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 326,
    avg_round: 25920,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 33380,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 36280,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 24160,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'trailblazer-harmony',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 27160,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 29000,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Herta',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 29680,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 30280,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 21080,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 27440,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 30680,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'tingyun-fugue',
    char_three: 'Pela',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 326,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 25160,
    star_num: '4'
  },
  {
    char_one: 'Bronya',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Clara',
    char_three: 'tingyun-fugue',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 31240,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 29240,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 326,
    avg_round: 28880,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 30020,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'topaz',
    char_four: 'Robin',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 326,
    avg_round: 38280,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 32220,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 32480,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 27000,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 29600,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Himeko',
    char_three: 'tingyun-fugue',
    char_four: 'trailblazer-harmony',
    app_rate: 0.02,
    rank: 326,
    avg_round: 39560,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 26720,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Guinaifen',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 25100,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 326,
    avg_round: 26240,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'tingyun-fugue',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 31260,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 34800,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 27200,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Xueyi',
    char_three: 'Herta',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 28120,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 25800,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'Sunday',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 30320,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 26720,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Yunli',
    char_three: 'Herta',
    char_four: 'Robin',
    app_rate: 0.02,
    rank: 326,
    avg_round: 29900,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 326,
    avg_round: 27920,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 26680,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'tingyun-fugue',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 27080,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 326,
    avg_round: 26420,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 326,
    avg_round: 33340,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 27140,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'tingyun-fugue',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 24960,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 34120,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Jingliu',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'march-7th-swordmaster',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 326,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Jingliu',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 326,
    avg_round: 27460,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 326,
    avg_round: 27840,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 326,
    avg_round: 30480,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 326,
    avg_round: 25560,
    star_num: '4'
  }
];
