import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import { Col, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const ExiliumGuidesBeg: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page exilium-page'} game="exilium">
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/gfl-exilium/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Beginner Guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/exilium/categories/category_beg.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Beginner Guide</h1>
          <h2>Beginner's Guide to Girls' Frontline 2: Exilium gacha game.</h2>
          <p>
            Last updated: <strong>15/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Premise" />
        <p>
          Girls’ Frontline 2: Exilium is a sequel to the popular mobile game
          Girls’ Frontline developed by MICA Team. Set 12 years after the events
          of Girls’ Frontline, you once again assume the role of the Commander
          who has left Griffin & Kryuger, the world’s largest Private Military
          Company, after its disbandment and subsequent reorganization. Now
          operating as a Bounty Hunter onboard the Mobile Construction Vehicle
          Elmo, you are joined by a squad of former android (known as T-Dolls)
          G&K operators led by OTs-14, now known as Groza. Groza acts as your
          current adjutant alongside Mayling Shen, who acts as your logistics
          officer and general mechanic of MCV Elmo.
        </p>
        <SectionHeader title="Changes from Girls’ Frontline" />
        <p>
          As a new game in its long series, Girls’ Frontline 2: Exilium
          introduces many changes and differences to its predecessor, as we
          showcase here.
        </p>
        <h5>Changes from Girls’ Frontline “Combat” System</h5>
        <StaticImage
          src="../../../images/exilium/generic/beg_1.webp"
          alt="Guides"
        />
        <p>
          In the previous game, Girls’ Frontline’s combat is a turn-based
          strategy role-playing game that consists of a strategy map and its
          combat nodes. Commanders could command multiple squads consisting of 5
          T-Dolls and move their T-dolls in a 3x3 grid auto battle sequence
          while unit skills could be manually activated or automated.
        </p>
        <p>
          In Girls Frontline’ 2: Exilium, the game is now a turn-based tactical
          role-playing game similar to the modern XCOM series developed by
          Firaxis Games, also known as “XCOM-lite”. You command a Squad of 4 to
          5 T-Dolls to achieve various objectives on the map such as reaching a
          certain objective, extracting out of the combat zone, or eliminating
          all enemies on the map. To learn more about the new combat system,
          please see our Combat Guide.
        </p>
        <h5>Changes from Girls’ Frontline “Type” System</h5>
        <StaticImage
          src="../../../images/exilium/generic/beg_2.webp"
          alt="Guides"
        />
        <p>
          In the previous game, all T-Dolls were categorized under a “Type”
          system based on their weapon imprint that determined how they
          functioned in combat. There were 6 weapon Types in the previous game;{' '}
          <strong>HG, SMG, AR, RF, MG, SG</strong>.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/beg_3.webp"
          alt="Guides"
        />
        <p>
          In Girls Frontline 2: Exilium, T-dolls are now organized into a
          “Class” system that consists of 4 classes.
        </p>
        <ul>
          <li>
            <strong>Bulwark Class</strong>: The Bulwark class is your standard
            “Tank” that acts as a frontline and provides various Defensive Buffs
            to your team.
          </li>
          <li>
            <strong>Sentinel Class</strong>: The Sentinel class is your standard
            “DPS” that provides damage for your team.
          </li>
          <li>
            <strong>Vanguard Class</strong>: The Vanguard class is your standard
            “Scout” that acts as a sub-DPS to your team as well as having the
            most mobility out of all the classes to perform flanking maneuvers.
          </li>
          <li>
            <strong>Support Class</strong>: The Support class is your standard
            “Healer/Buffer” that provides healing and various Buffs to your
            team.
          </li>
        </ul>
        <h5>Changes from Girls Frontline “Production” System </h5>
        <StaticImage
          src="../../../images/exilium/generic/beg_4.webp"
          alt="Guides"
        />
        <p>
          In the previous game, the method of acquiring new T-dolls was through
          a “Production” system, where you used various materials to produce new
          T-Dolls. You could either choose to use Normal or Heavy Production to
          produce various types.
        </p>
        <p>
          In Girls’ Frontline 2: Exilium, the <strong>“Production”</strong>{' '}
          system has now changed to a standard <strong>“Gacha”</strong> system
          seen in many other mobile games. There are now 2 banners in which
          T-dolls can be obtained from; <strong>Standard and Limited</strong>.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/beg_5.webp"
          alt="Guides"
        />
        <p>
          <strong>Standard Procurement</strong> is your standard permanent
          banner that provides 6 base SSR T-dolls and their equivalent SSR
          weapons. They are Mosin-Nagant, Peritya, Qionjiu, Sabrina, Tololo, and
          Vepley. Every 80 accesses (rolls) will guarantee an SSR pity of either
          a character or a weapon. There is also a one-time SSR T-doll selection
          pity at 260 accesses.{' '}
          <strong>
            The standard banner does not update to include newly released
            T-Dolls and will only have the base 6 SSRs at all times.
          </strong>
        </p>
        <StaticImage
          src="../../../images/exilium/generic/beg_6.webp"
          alt="Guides"
        />
        <p>
          <strong>Access Permission</strong> are tickets you can use to pull on
          Standard Procurement, which may be obtained through events,
          objectives, and shops.
        </p>
        <p>
          <strong>Limited Procurement</strong> is your limited banner that
          provides new T-Dolls introduced into the game as well as reruns of
          previously introduced T-Dolls. The Limited Banner is split into 2
          banners, a character banner and a weapon banner, where you may obtain
          the featured T-Doll and their respective SSR weapon.
        </p>
        <p>
          Both Standard Procurement and Limited Procurement (Character) share
          the same rates below:
        </p>
        <ul>
          <li>SSR</li>
          <ul>
            <li>
              <strong>0.6%</strong> base drop rate for T-Dolls and Weapons.
            </li>
            <li>
              <strong>0.3%</strong> base drop rate for T-Dolls
            </li>
            <li>
              <strong>0.3%</strong> base drop rate for Weapons
            </li>
            <li>
              <strong>1.89%</strong> overall drop rate for T-Dolls and Weapons.
            </li>
          </ul>
          <li>SR</li>
          <ul>
            <li>
              <strong>6%</strong> base drop rate for T-Dolls and Weapons.
            </li>
            <li>
              <strong>3%</strong> base drop rate for T-Dolls
            </li>
            <li>
              <strong>3%</strong> base drop rate for Weapons
            </li>
            <li>
              <strong>13%</strong> overall drop rate for T-Dolls and Weapons.
            </li>
          </ul>
        </ul>
        <p>Limited Procurement (Weapon) has its own rate below: </p>

        <ul>
          <li>SSR</li>
          <ul>
            <li>
              <strong>0.7%</strong> base drop rate for Weapon.
            </li>
            <li>
              <strong>2.18%</strong> overall drop rate for Weapons.
            </li>
          </ul>
          <li>SR</li>
          <ul>
            <li>
              <strong>7%</strong> base drop rate for Weapon.
            </li>
            <li>
              <strong>13.57%</strong> overall drop rate for Weapons.
            </li>
          </ul>
        </ul>
        <p>
          To learn more about the Banners and rates in GFL 2, check our other
          guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Gacha explained"
            link="/gfl-exilium/guides/gacha-explained"
            image={
              <StaticImage
                src="../../../images/exilium/categories/category_gacha.webp"
                alt="Gacha explained"
              />
            }
          />
        </Row>
        <h5>Changes from Girls’ Frontline “Skill Training” System</h5>
        <StaticImage
          src="../../../images/exilium/generic/beg_7.webp"
          alt="Guides"
        />
        <p>
          In the previous game, the <strong>“Skill Training”</strong> System to
          upgrade T-Dolls skills was done using skill materials through a timed
          AFK method or could be completed immediately using a Quick Training
          Contract. New “MOD” skills could be unlocked through limit breaking
          T-Dolls with their dupes.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/beg_8.webp"
          alt="Guides"
        />
        <p>
          In Girls’ Frontline 2: Exilium, the “Skill Training” System has now
          changed to a Hoyoverse (Genshin Impact/HSR etc.){' '}
          <strong>“Fortification”</strong> system where T-Doll dupes now
          increase the T-Doll’s skills. Dupes no longer give new skills as all
          skills are now unlocked at base level, and they simply function now to
          increase and enhance various skills of the T-Doll.
        </p>
        <p>
          To learn more about the character system in GFL 2, check our other
          guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Characters & Progression"
            link="/gfl-exilium/guides/characters-and-progression"
            image={
              <StaticImage
                src="../../../images/exilium/categories/category_progression.webp"
                alt="Characters & Progression"
              />
            }
            isNew
          />
        </Row>
        <h5>Changes from Girls Frontline “Skin” System</h5>
        <StaticImage
          src="../../../images/exilium/generic/beg_9.webp"
          alt="Guides"
        />
        <p>
          In the previous game, character skins of various T-Dolls were acquired
          through a standard “Gacha” system, while some skins could be purchased
          from the Cash shop.
        </p>
        <p>
          In Girls’ Frontline 2: Exilium, character skins are now acquired
          directly from the Cash shop, while some skins are given out for free
          through events. There are also weapon skins available which can be
          acquired through events, Cash shop, and oathing a T-Doll. Character
          Skins can be used in combat and in the new Dormitory system, indicated
          by the Pistol and/or Sofa icon. Not all character skins can be used in
          the dormitory, so be sure to check the icons shown before buying a
          character skin.
        </p>
        <SectionHeader title="Girls’ Frontline 2: Exilium Features" />
        <p>
          In Girls’ Frontline 2: Exilium, the gameplay aspect is categorized
          into 3 different categories:
        </p>
        <StaticImage
          src="../../../images/exilium/generic/beg_10.webp"
          alt="Guides"
        />
        <p>
          <strong>Story Campaign</strong> consists of the Main Story Chapters,
          currently up to Chapter 8 as of CN version. You can challenge the Main
          Story in Normal or Hard mode as well as challenge the{' '}
          <strong>“Hunter Assessment”</strong> section which acts as an advanced
          tutorial for Commanders. Hard Mode unlocks at Chapter 5-5.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/beg_11.webp"
          alt="Guides"
        />
        <p>
          <strong>Supply Missions</strong> consist of Material sections that
          provide various materials to upgrade your T-Dolls. While most sections
          can be cleared indefinitely, <strong>Standardizing Sync</strong> can
          only be cleared twice per day.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/beg_12.webp"
          alt="Guides"
        />
        <p>
          The <strong>Combat Simulations</strong> category features various
          challenges for commanders to clear.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/beg_13.webp"
          alt="Guides"
        />
        <p>
          <strong>Neural Survey</strong>, unlocked at Commander Level 15,
          features various chapters which can be cleared to obtain materials
          needed to unlock new Neural Helix nodes for your T-Dolls.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/beg_14.webp"
          alt="Guides"
        />
        <p>
          <strong>Boss Fight</strong>, unlocked at Commander Level 27, is a
          weekly Raid Boss challenge mode split between{' '}
          <strong>Standard Battle</strong> and{' '}
          <strong>Crossroad Challenge</strong>.
        </p>
        <p>
          <strong>Standard Battle</strong> mode features 8 tier levels,
          resetting weekly and can be challenged up to 3 times per week.
          Clearing all challenge ratings per tier will raise the tier in the
          next week, and rewards you with <strong>Segment Marks</strong> that
          you may use for the Boss Fight Shop. Rewards include Sabrina shards
          for Fortification, tickets, materials, and weapon attachment boxes.{' '}
          <strong>Crossroad Challenge</strong> mode is an endgame boss battle
          mode that you may challenge weekly.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/beg_15.webp"
          alt="Guides"
        />
        <p>
          <strong>Combat Exercise</strong>, unlocked at Commander Level 30, is
          an offline PvP mode where you may challenge other players controlled
          by AI and rise through the PvP ranks. Reaching Adept V will unlock{' '}
          <strong>Military Simulation</strong>, which is the online PvP mode.{' '}
          <strong>Combat Exercise</strong> resets weekly.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/beg_16.webp"
          alt="Guides"
        />
        <p>
          <strong>Peak Value Assessment</strong> is a weekly “Tower” based mode
          where you can obtain various rewards as you progress through the
          groups. Each group contains 5 stages for you to clear.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/beg_17.webp"
          alt="Guides"
        />
        <p>
          <strong>Expansion Drills</strong> is a bi-weekly challenge mode which
          features up to 10 stages. Depending on your commander level, up to 6
          stages at max will unlock allowing you to challenge the highest stage
          possible. Challenging the highest stage possible will give you the
          previous stage rewards, so you do not have to clear each stage.
          Clearing Expansion Drills reward Precision Boost Chip, which is needed
          to further boost stats on your weapon attachments.
        </p>
        <SectionHeader title="Stamina System" />
        <StaticImage
          src="../../../images/exilium/generic/stamina.webp"
          alt="Guides"
        />
        <p>
          Much like the predecessor, Girls Frontline 2: Exilium uses a stamina
          system called <strong>Intelligence Puzzle</strong> that refreshes
          every at 0500 UTC-5 (pending). At start, the maximum amount of
          Intelligence Puzzle available is <strong>180</strong> and increases
          every Commander level up to the maximum amount of <strong>240</strong>{' '}
          (in CN - Global cap stays at 180 for now) .
        </p>
        <p>You can refresh the stamina via 2 methods:</p>
        <ul>
          <li>1 Access Key can be used to refresh 60 Intelligence Puzzle.</li>
          <li>
            20 Collapse Piece can be used to refresh 60 Intelligence Puzzle,
            which increases Collapse Piece and can be done up to 9 times to a
            total of 200 Collapse Piece.
          </li>
        </ul>
        <p>
          Any excess Intelligence Puzzle that goes beyond the maximum amount
          will be stored in{' '}
          <strong>Intelligence Storage located in the Dispatch Room</strong>.
          Increasing the Dispatch Room level will increase the amount of excess
          Intelligence Puzzle that can be stored.
        </p>
        <SectionHeader title="Currency System" />
        <p>
          Currency in Girls Frontline 2: Exilium is split into 3 currencies.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/category_collapse.webp"
          alt="Guides"
        />
        <h6>Collapse Piece</h6>
        <p>
          Collapse Piece is the “Gem” non-premium currency used to purchase
          items Intelligence Puzzle, items from Shop, and used to purchase
          summon tickets. They can be earned through Achievements, Events,
          Battlepass, and weekly challenges such as PvP and Boss Battle mode.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/currency_gold.webp"
          alt="Guides"
        />
        <h6>Sardis Gold</h6>
        <p>
          Sardis Gold is the “Gold” currency used to upgrade T-Dolls and their
          weapons. They can be farmed through Standardizing Sync twice (4 per
          current CN ver.) daily. Standardizing Sync in Supply Missions, Daily
          Missions, various challenges, challenge modes, Battlepass, and events
          can also reward Sardis Gold.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/currency_credits.webp"
          alt="Guides"
        />
        <h6>Credit Tokens</h6>
        <p>
          Credit Tokens is the “Premium” currency used to purchase Premium packs
          and costumes in the Cash Shop. They can be exchanged 1 to 1 to
          Collapse Piece, but it is not recommended unless you wish to do more
          summons.
        </p>
        <SectionHeader title="Commander Level" />
        <p>
          Commander Level is your account level that{' '}
          <strong>
            determines the highest level your T-Dolls and weapons can level up
            to
          </strong>
          . It is also used to unlock various level requirements for Story
          Missions as well as various modes that are locked in the progression
          tree.{' '}
          <strong>Commander Level can go up to a maximum of Level 60</strong>.
          Commander EXP can be obtained by clearing
        </p>
        <ul>
          <li>
            Story Campaigns which reward 10 EXP per every repeat while one time
            clear awards 120 EXP for first time clear.
          </li>
          <li>
            Supply missions and Neural Survey give 1 EXP per 1 Stamina spent: 10
            to 30 EXP depending on mode.
          </li>
          <li>
            Daily Missions can give 75 EXP each - up to 300 EXP daily for all
            four.
          </li>
        </ul>
        <p>
          Early on, you{' '}
          <strong>should use your Stamina on upgrading your characters</strong>{' '}
          as this will make progressing through the game easier (so focus on
          Character EXP, Weapon EXP and Limit Break materials). Even if it's not
          really optimal as those modes give less Commander EXP, your characters
          will get stronger that way faster. Later on, around Commander level
          50, you can switch to Targeted Study in Supply Missions to farm
          Attachments (and this will be your endgame grind).
        </p>
        <SectionHeader title="Progression & Unlocks" />
        <StaticImage
          src="../../../images/exilium/generic/progression.webp"
          alt="Guides"
        />
        <p>
          Various Modes are unlocked through Commander Level and completing
          certain story stages. Online PvP mode can be obtained by reaching a
          certain rank in Offline PvP (Combat Evaluation).
        </p>
        <h6>Modes unlocked through Commander Level</h6>
        <ul>
          <li>
            Neural Helix - Commander Level <strong>15</strong>
          </li>
          <li>
            Neural Survey - Commander Level <strong>15</strong>
          </li>
          <li>
            Cognitive Configuration - Commander Level <strong>20</strong>
          </li>
          <li>
            Expansion Drills - Commander Level <strong>23</strong>
          </li>
          <li>
            Targeted Study - Commander Level <strong>23</strong>
          </li>
          <li>
            Standardizing Sync - Commander Level <strong>24</strong>
          </li>
          <li>
            Boss Fight - Commander Level <strong>27</strong>
          </li>
          <li>
            Combat Evaluation - Commander Level <strong>30</strong>
          </li>
          <li>
            Peak Value Assessment - Commander Level <strong>30</strong>
          </li>
        </ul>
        <h6>Modes unlocked through Story Stages</h6>
        <ul>
          <li>Recruitment - Story chapter SL 1-2</li>
          <li>Dormitory - Story chapter 1-5</li>
          <li>Event - Story chapter 1-9</li>
          <li>Public Area - Story chapter 1-9</li>
          <li>Skin - Story chapter 1-9</li>
          <li>Weapon - Story chapter 1-9</li>
          <li>Commission - Story chapter SL 2-2</li>
          <li>Supply Missions - Story chapter SL 2-2</li>
        </ul>
        <SectionHeader title="Tips & Tricks" />
        <p>
          <strong>This section is WIP - more tips will be added soon!</strong>
        </p>
        <ul>
          <li>
            Make sure to <strong>do your Daily Missions</strong> as they give a
            lot experience.
          </li>
          <li>
            If you think you made an mistake during combat, use the{' '}
            <strong>
              Rewind feature to take you back a few turns and try again
            </strong>
            . Rewind allows you to turn back to a previous turn phase up to 3
            times per battle.
          </li>
          <li>
            <strong>Manual Play is recommended</strong> if you are at the same
            level or under level as Auto-Play AI isn’t smart and will make easy
            mistakes. Auto-Play is generally recommended when you are
            overleveled.
          </li>
          <li>
            Also don't forget about the <strong>Sweep function</strong>! If you
            fully star a mission, you can just sweep it with your stamina
            instead of manually playing it again.
          </li>
          <li>
            <strong>
              Your character and weapon max level is tied to Commander Level
            </strong>{' '}
            - so you need to focus on leveling that as priority.
          </li>
          <li>
            Early on, you should use{' '}
            <strong>your Stamina on upgrading your characters</strong> as this
            will make progressing through the game easier (focus on Character
            EXP, Weapon EXP and Limit Break materials).
          </li>
          <li>
            Also don't forget about <strong>Memory Cores</strong> - they are
            important as they unlock Neural Helix Keys needed to enhance various
            skills for T-Dolls.{' '}
            <strong>
              Make sure to choose carefully which T-dolls to spend them on as
              they are not easily farmable.
            </strong>
          </li>
          <li>
            Switch to farming{' '}
            <strong>Attachments after Account Level 43</strong> (with 58 having
            the most optimal drop rates, but we all know it waiting for that
            long will be hard to do for most), but you can use some stamina
            there early to grab some Purple rarity attachments and get some nice
            boost - just don't overdo it.
          </li>
          <li>
            Somewhere around the mid-point of the game, the AOE characters that
            carried you in the early game, start to struggle as chunkier enemies
            will become more common. That's the moment where the single-target
            specialist become stronger, so don't invest everything into just AOE
            characters or you will get yourself stuck.
          </li>
        </ul>
        <SectionHeader title="Video" />
        <p>If you want to learn more check this video:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="7p36RwivUs8" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default ExiliumGuidesBeg;

export const Head: React.FC = () => (
  <Seo
    title="Beginner Guide | Girls' Frontline 2: Exilium | Prydwen Institute"
    description="Beginner's Guide to Girls' Frontline 2: Exilium gacha game."
    game="exilium"
  />
);
