import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { IZZZBangboo } from '../../../common/model/graphql-types';

interface IProps {
  employee: IZZZBangboo;
  showLabel?: boolean;
}

export const ZZZBangbooCard: React.FC<IProps> = ({ employee, showLabel }) => {
  const image = employee.cardImage
    ? getImage(employee.cardImage.localFile.childImageSharp)
    : null;
  return (
    <Link to={'/zenless/bangboo/' + employee.slug}>
      <div
        className={`avatar card zzz rarity-${employee.rarity} ${
          showLabel ? showLabel : ''
        }`}
      >
        {image && (
          <GatsbyImage
            className="disable-transition"
            image={image}
            alt={`${employee.name}`}
          />
        )}
        {showLabel && <span className="emp-name">{employee.name}</span>}
        {/* {showLabel && employee.slug === 'agent-gulliver' && (
          <span className="tag new">Trial</span>
        )} */}
      </div>
    </Link>
  );
};
